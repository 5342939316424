import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import Spinner from 'shared/lib/components/Spinner';
import ChubbPlansTable from '../ChubbPlansTable';
import { suspendPromise } from 'shared/lib/utils';
import * as analytics from 'shared/lib/analytics';
import './CarSafePlansView.css';

const propTypes = {
  carSafePlans: PropTypes.array,
  carSafeSelectedPlan: PropTypes.object,
  carSafeSettings: PropTypes.object,
};

const defaultProps = {
  carSafePlans: [],
  carSafeSelectedPlan: {},
  carSafeSettings: {
    currency: {
      code: 'ZAR',
      symbol: 'R',
    },
  },
};

class CarSafePlansView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    analytics.page('CarSafe - Plans');
    this.setState({ isLoading: true });
    suspendPromise(this.props.getCarSafePlans('ZA'), 1000)
      .then(() => {
        analytics.track('CarSafe - Plans Loaded');
        this.setState({ isLoading: false });
      })
      .catch(error => {
        analytics.track('CarSafe - Plans Loading Failed');
        this.setState({ isLoading: false });
        this.props.onError(error);
      });
  }

  handleSelect = planId => {
    analytics.track('CarSafe - Plan Selected', {
      label: planId,
    });
    this.props.selectCarSafePlan(planId);
    this.props.navTo('/vehicle-contents-insurance/policy-holder');
  };

  render() {
    const { carSafePlans, carSafeSettings, disclaimer } = this.props;
    const plansTableHeaders = [
      {
        key: 'personal_possession_theft',
        content: (
          <Fragment>
            Personal Possesion Theft
            <br />
            (Wallet, handbag, etc.)
          </Fragment>
        ),
      },
      {
        key: 'electronics_theft',
        content: (
          <Fragment>
            Electronics Theft
            <br />
            (Laptop, phone, etc.),
          </Fragment>
        ),
      },
      {
        key: 'home_lock_and_key',
        content: 'Home Lock and Key Replacement',
      },
      {
        key: 'identity_theft',
        content: 'Identity Theft',
      },
      {
        key: 'accidental_ddd',
        content:
          'Accidental Death, Dismemberment & Disability (Motor vehicle accident only)',
      },
    ];

    return this.state.isLoading ? (
      <div className="CarSafePlansView Container is-loading">
        <Spinner />
        Getting available plans...
      </div>
    ) : (
      <div className="CarSafePlansView Container">
        <h1 className="CarSafePlansView-heading">
          Select a Vehicle Contents Insurance plan below:
        </h1>
        <ChubbPlansTable
          headerTitleNote="(Motor Vehicle only)*"
          headers={plansTableHeaders}
          plans={carSafePlans}
          currencyCode={get(carSafeSettings, 'currency.code')}
          onSelect={this.handleSelect}
        />
        <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
      </div>
    );
  }
}

CarSafePlansView.propTypes = propTypes;
CarSafePlansView.defaultProps = defaultProps;

export default CarSafePlansView;
