'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEDDING_BASE_PATH = exports.weddingRoutesCreator = undefined;
var _jsxFileName = 'src/apps/WeddingApp/routes.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var WEDDING_BASE_PATH = 'wedding-protection';

var weddingRoutesCreator = function weddingRoutesCreator(_ref) {
  var container = _ref.container,
      landingComponent = _ref.landingComponent;
  return _react2.default.createElement(_reactRouter.Route, {
    path: WEDDING_BASE_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 7
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 8
    },
    __self: undefined
  }));
};

exports.weddingRoutesCreator = weddingRoutesCreator;
exports.WEDDING_BASE_PATH = WEDDING_BASE_PATH;