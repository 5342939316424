'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PaymentLayoutNew/PaymentLayoutNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _Select = require('shared/lib/components/Select');

var _Select2 = _interopRequireDefault(_Select);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _startCase = require('lodash/startCase');

var _startCase2 = _interopRequireDefault(_startCase);

var _LineItemsList = require('../LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapperNew = require('../SectionWrapperNew');

var _SectionWrapperNew2 = _interopRequireDefault(_SectionWrapperNew);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

require('./PaymentLayoutNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  acknowledgmentHtml: _propTypes2.default.string,
  currencyCode: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  hideBillingSummary: _propTypes2.default.bool,
  icon: _propTypes2.default.string,
  isPurchaseInProgress: _propTypes2.default.bool,
  notice: _propTypes2.default.string,
  onSubmit: _propTypes2.default.func,
  paymentCadences: _propTypes2.default.object,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  billingCycle: _propTypes2.default.string,
  policyDocUrl: _propTypes2.default.string,
  pricePerCycle: _propTypes2.default.number,
  quote: _propTypes2.default.object,
  selectedPaymentCadence: _propTypes2.default.string,
  title: _propTypes2.default.string,
  totals: _propTypes2.default.object
};
var defaultProps = {
  acknowledgmentHtml: null,
  currencyCode: 'USD',
  disclaimer: null,
  hideBillingSummary: false,
  icon: 'attach_money',
  isPurchaseInProgress: false,
  notice: null,
  paymentCadences: {},
  policyStartDate: null,
  billingCycle: null,
  policyDocUrl: null,
  pricePerCycle: null,
  quote: {},
  selectedPaymentCadence: null
};

var PaymentLayoutNew = function (_PureComponent) {
  _inherits(PaymentLayoutNew, _PureComponent);

  function PaymentLayoutNew() {
    _classCallCheck(this, PaymentLayoutNew);

    return _possibleConstructorReturn(this, (PaymentLayoutNew.__proto__ || Object.getPrototypeOf(PaymentLayoutNew)).apply(this, arguments));
  }

  _createClass(PaymentLayoutNew, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          currencyCode = _props.currencyCode,
          notice = _props.notice,
          onUpdatePaymentCadence = _props.onUpdatePaymentCadence,
          policyStartDate = _props.policyStartDate,
          selectedPaymentCadence = _props.selectedPaymentCadence,
          totals = _props.totals;
      return _react2.default.createElement('div', {
        className: 'PaymentLayoutNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 141
        },
        __self: this
      }, notice && _react2.default.createElement('p', {
        className: 'PaymentLayoutNew-notice',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 142
        },
        __self: this
      }, notice), this.pricingHeading, this.hasPaymentOptions && _react2.default.createElement('div', {
        className: 'Payment-paymentOptions',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 147
        },
        __self: this
      }, _react2.default.createElement(_Select2.default, {
        name: 'payment_cadence',
        value: selectedPaymentCadence,
        onChange: onUpdatePaymentCadence,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 148
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'annual',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 153
        },
        __self: this
      }, 'Annual (1 payment)'), _react2.default.createElement('option', {
        value: 'eleven_pay',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 154
        },
        __self: this
      }, 'Monthly (11 payments)'))), _react2.default.createElement('h3', {
        className: 'PaymentLayoutNew-subtitle',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 159
        },
        __self: this
      }, 'Your Summary'), _react2.default.createElement('hr', {
        className: 'PaymentLayoutNew-headingDivider',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 161
        },
        __self: this
      }), _react2.default.createElement(_SectionWrapperNew2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 163
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [[this.policyName, _currencyFormatter2.default.format((0, _get3.default)(this.props.totals, 'subtotal') || (0, _get3.default)(this.props.totals, 'grandtotal'), {
          code: currencyCode
        })]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 164
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapperNew2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 180
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [this.billingCycle, ['Next billing date', (0, _momentTimezone2.default)(policyStartDate).add(1, 'year').format('MM/DD/YYYY')]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 181
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapperNew2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 194
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        className: 'PaymentLayoutNew-total',
        rows: [['Total:', _currencyFormatter2.default.format((0, _get3.default)(totals, 'grandtotal'), {
          code: currencyCode
        })]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 195
        },
        __self: this
      })));
    }
  }, {
    key: 'policyName',
    get: function get() {
      if (this.props.categoryCode === 'other') {
        return 'Collectibles Policy';
      } else {
        return (0, _startCase2.default)(this.props.categoryCode) + ' Policy';
      }
    }
  }, {
    key: 'priceHeading',
    get: function get() {
      var price = this.props.pricePerCycle || (0, _get3.default)(this.props.totals, 'grandtotal');

      var formattedPrice = _currencyFormatter2.default.format(price, {
        code: this.props.currencyCode
      });

      if (this.props.billingCycle) {
        return formattedPrice + '/' + this.props.billingCycle;
      } else {
        return formattedPrice;
      }
    }
  }, {
    key: 'billingCycle',
    get: function get() {
      switch (this.props.billingCycle) {
        case 'year':
          return ['Billing cycle', 'Annual'];

        case 'month':
          return ['Billing cycle', 'Monthly'];

        default:
          break;
      }
    }
  }, {
    key: 'hasPaymentOptions',
    get: function get() {
      var paymentCadences = (0, _get3.default)(this.props.paymentCadences, 'cadences');
      return paymentCadences && paymentCadences.length > 1;
    }
  }, {
    key: 'hasMultiPaymentSchedule',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      return schedule && schedule.length > 1;
    }
  }, {
    key: 'downPayment',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      var amount = (0, _find2.default)(schedule, {
        downpayment: true
      }).amount;
      return _currencyFormatter2.default.format(amount, {
        code: 'USD'
      });
    }
  }, {
    key: 'monthlyPayments',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      var amount = (0, _find2.default)(schedule, {
        downpayment: false
      }).amount;
      return _currencyFormatter2.default.format(amount, {
        code: 'USD'
      });
    }
  }, {
    key: 'pricingHeading',
    get: function get() {
      if (this.hasMultiPaymentSchedule) {
        return _react2.default.createElement('div', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 109
          },
          __self: this
        }, _react2.default.createElement('h1', {
          className: 'Payment-monthlyTitle',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 110
          },
          __self: this
        }, 'First Payment'), _react2.default.createElement('p', {
          className: 'Payment-monthlyDownPayment',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 111
          },
          __self: this
        }, this.downPayment), _react2.default.createElement('h2', {
          className: 'Payment-monthlyHeading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 112
          },
          __self: this
        }, 'Followed by 10 monthly payments'), _react2.default.createElement('p', {
          className: 'Payment-monthlyInstallmentPayment',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 115
          },
          __self: this
        }, this.monthlyPayments, '/month'), _react2.default.createElement('p', {
          className: 'Payment-monthlySubheading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 118
          },
          __self: this
        }, '(Billing Fees Included)'));
      } else {
        return _react2.default.createElement('div', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 123
          },
          __self: this
        }, _react2.default.createElement('h1', {
          className: 'PaymentLayoutNew-title',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 124
          },
          __self: this
        }, this.props.title));
      }
    }
  }]);

  return PaymentLayoutNew;
}(_react.PureComponent);

PaymentLayoutNew.propTypes = propTypes;
PaymentLayoutNew.defaultProps = defaultProps;
exports.default = PaymentLayoutNew;