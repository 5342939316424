import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import seo from 'shared/lib/seo';
import get from 'lodash/get';
import omit from 'lodash/omit';
import * as bopApi from '../../api/bop';
import withSubapp from 'shared/lib/apps/BopApp/hoc';
import withGlobals from '../../withGlobals';
import './BopContainer.css';

class BopContainer extends Component {

  componentDidMount() {
    const partnerName = get(this.props.partnerInfo, 'name');
    seo.setProps({
      title: `Small Business Insurance | ${partnerName}`,
      description: `Get a Business Owners Policy for Small Business through ${partnerName}.`,
      url: `https://${window.location.host}/small-business-insurance`,
      image: `https://${window.location.host}/images/landing-pet-mobile.jpg`,
    });
  }

  render() {
    const {
      children,
    } = this.props;
    return (
      <div className="BopContainer">
        {React.cloneElement(children, omit(this.props, 'children'))}
      </div>
    );
  }
}

const mapStateToProps =  (state, ownProps) => {
  const defaultDisclaimer = `Content and associated insurance products are provided by Sure HIIS Insurance Services, LLC (“Sure”), a licensed seller of insurance. The above does not in any way constitute an endorsement or referral by ${state.partnerInfo.name}. Products may not be offered in all states.`;
  return {
    disclaimer: ownProps.disclaimer || defaultDisclaimer,
  };
};

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'BOP',
  }),
  connect(mapStateToProps),
  withSubapp(bopApi),
);

export default enhance(BopContainer);
