import React from 'react';
import './Footer.css';

export default ({ partnerInfo, partnerLinks, partnerDisclaimer }) => (
  <div className="Footer">
    <div className="LandingRentersContainer">
      <ul className="Footer-links">
        <li className="Footer-linkItem">
          <a
            href="https://www.sureapp.com/privacy"
            className="Footer-linkItem-link"
          >
            Privacy Policy
          </a>
        </li>
        <li className="Footer-linkItem">
          <a
            href="https://www.sureapp.com/terms"
            className="Footer-linkItem-link"
          >
            Terms of Use
          </a>
        </li>
        <li className="Footer-linkItem">
          <a href="http://support.sureapp.com" className="Footer-linkItem-link">
            Support
          </a>
        </li>
        {partnerLinks &&
          partnerLinks.map(link => (
            <li className="Footer-linkItem" key={link.title}>
              <a href={link.link} className="Footer-linkItem-link">
                {link.title}
              </a>
            </li>
          ))}
      </ul>
      <p className="Footer-copyright">
        &copy; {new Date().getFullYear()} Sure Inc.
      </p>
    </div>
    <div className="LandingRentersContainer Footer-disclaimer">
      Content and associated insurance products are provided by Sure HIIS
      Insurance Services, LLC (“Sure”), a licensed seller of insurance. The
      above does not in any way constitute an endorsement or referral by{' '}
      {partnerInfo.name}. Products may not be offered in all states.
    </div>
    {partnerDisclaimer && (
      <div className="LandingRentersContainer Footer-disclaimer">
        {partnerDisclaimer}
      </div>
    )}
  </div>
);
