'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isWebPSupported = exports.FIELDS_TYPES = undefined;
exports.getFieldsType = getFieldsType;
exports.slugifyFieldAndChoice = slugifyFieldAndChoice;
exports.numberWithCommas = numberWithCommas;
exports.loadScript = loadScript;
exports.getErrorMessage = getErrorMessage;
exports.priceStrToNum = priceStrToNum;
exports.findPniScreenId = findPniScreenId;
exports.generateTrail = generateTrail;
exports.getDeviceInfo = getDeviceInfo;
exports.createStripeTokenFromElement = createStripeTokenFromElement;
exports.createPaymentMethod = createPaymentMethod;
exports.updatePaymentMethod = updatePaymentMethod;
exports.logException = logException;
exports.setSentryUserContext = setSentryUserContext;
exports.sanitizeSentryStatePayload = sanitizeSentryStatePayload;
exports.getDisclaimer = getDisclaimer;
exports.filterDynamicFormResponses = filterDynamicFormResponses;
exports.trimValues = trimValues;
exports.suspendPromise = suspendPromise;
exports.resolveUrlPath = resolveUrlPath;
exports.emitMessage = emitMessage;
exports.getResponseJsonWithStatus = getResponseJsonWithStatus;
exports.geocodeAddress = geocodeAddress;
exports.normalizeGooglePlaceAddressComponents = normalizeGooglePlaceAddressComponents;
exports.deepOmitBy = deepOmitBy;
exports.destructureAxiosReq = destructureAxiosReq;
exports.uuid = uuid;

var _camelCase = require('lodash/camelCase');

var _camelCase2 = _interopRequireDefault(_camelCase);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _every = require('lodash/every');

var _every2 = _interopRequireDefault(_every);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _findKey = require('lodash/findKey');

var _findKey2 = _interopRequireDefault(_findKey);

var _flatMap = require('lodash/flatMap');

var _flatMap2 = _interopRequireDefault(_flatMap);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

var _isPlainObject = require('lodash/isPlainObject');

var _isPlainObject2 = _interopRequireDefault(_isPlainObject);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

var _keys = require('lodash/keys');

var _keys2 = _interopRequireDefault(_keys);

var _lowerCase = require('lodash/lowerCase');

var _lowerCase2 = _interopRequireDefault(_lowerCase);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _mapValues = require('lodash/mapValues');

var _mapValues2 = _interopRequireDefault(_mapValues);

var _omitBy = require('lodash/omitBy');

var _omitBy2 = _interopRequireDefault(_omitBy);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

var _reject = require('lodash/reject');

var _reject2 = _interopRequireDefault(_reject);

var _size = require('lodash/size');

var _size2 = _interopRequireDefault(_size);

var _trim = require('lodash/trim');

var _trim2 = _interopRequireDefault(_trim);

var _snakeCase = require('lodash/snakeCase');

var _snakeCase2 = _interopRequireDefault(_snakeCase);

var _values = require('lodash/values');

var _values2 = _interopRequireDefault(_values);

var _uaParserJs = require('ua-parser-js');

var _uaParserJs2 = _interopRequireDefault(_uaParserJs);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _superagent = require('superagent');

var _superagent2 = _interopRequireDefault(_superagent);

var _resolveUrl = require('resolve-url');

var _resolveUrl2 = _interopRequireDefault(_resolveUrl);

var _browser = require('@sentry/browser');

var Sentry = _interopRequireWildcard(_browser);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var uaParser = new _uaParserJs2.default();
var FIELDS_TYPES = exports.FIELDS_TYPES = {
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  TEXT_FIELDS: 'TEXT_FIELDS',
  SUB_FIELDS: 'SUB_FIELDS',
  ADDRESS: 'ADDRESS'
};

function getFieldsType(fields) {
  if ((0, _isEmpty2.default)(fields)) return undefined;

  if (!(0, _isEmpty2.default)(fields[0].subfields)) {
    return FIELDS_TYPES.SUB_FIELDS;
  } else if ((0, _includes2.default)((0, _map2.default)(fields, 'widget'), 'autocomplete_address')) {
    return FIELDS_TYPES.ADDRESS;
  } else if (fields.length === 1 && (0, _size2.default)((0, _get2.default)(fields[0], 'settings.choices'))) {
    if (fields[0].settings.max_choices > 1) {
      return FIELDS_TYPES.MULTI_SELECT;
    } else {
      return FIELDS_TYPES.SINGLE_SELECT;
    }
  } else {
    return FIELDS_TYPES.TEXT_FIELDS;
  }
}

function slugifyFieldAndChoice(field, choice) {
  return (0, _snakeCase2.default)(field.key_name + ' ' + choice.value);
}

function numberWithCommas(x) {
  if (!(0, _isUndefined2.default)(x)) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}

function loadScript(src) {
  return new Promise(function (resolve, reject) {
    var s = void 0;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

function getErrorMessage(error) {
  var errorObj = (0, _get2.default)(error, 'response.body.error', error);

  if (!(0, _isEmpty2.default)(errorObj.fields)) {
    var firstKey = (0, _keys2.default)(errorObj.fields)[0];
    return firstKey + ' ' + (0, _lowerCase2.default)(errorObj.fields[firstKey]);
  } else {
    return errorObj.message || errorObj;
  }
}

function priceStrToNum(priceStr, opts) {
  opts = Object.assign({
    toFloat: false
  }, opts);

  if (priceStr) {
    var numStr = priceStr.slice(1);
    return opts.toFloat ? parseFloat(numStr) : parseInt(numStr, 10);
  } else {
    return 0;
  }
}

var pniKeyNames = ['pni_first_name', 'pni_last_name', 'owner_first_name', 'owner_last_name'];

function findPniScreenId(screens) {
  return (0, _findKey2.default)(screens, function (screen) {
    return (0, _find2.default)(screen.fields, function (field) {
      return (0, _includes2.default)(pniKeyNames, field.key_name);
    });
  });
}

function generateTrail(screens, fromScreenId, parentScreenId) {
  var showPniScreen = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var predicate = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : function () {
    return true;
  };
  var screenTrail = [];
  var pniScreenId = findPniScreenId(screens);
  var screenObj = screens[fromScreenId];
  var nextScreenObj = screens[screenObj.next_screen_id]; // Loop though all linked screens for which the predicate returns true and add the steps to the trail

  while (screenObj.next_screen_id) {
    if ((screenObj.next_screen_id !== pniScreenId || showPniScreen) && predicate(nextScreenObj)) {
      var obj = {
        id: screenObj.next_screen_id,
        parentId: parentScreenId,
        first: false
      };
      screenTrail.push(obj);
    }

    screenObj = screens[screenObj.next_screen_id];
    screenObj.next_screen_id && (nextScreenObj = screens[screenObj.next_screen_id]);
  }

  return screenTrail;
}

function getDeviceInfo() {
  return {
    browser: uaParser.getBrowser().name,
    browser_ver: uaParser.getBrowser().version,
    os: uaParser.getOS().name,
    os_ver: uaParser.getOS().version,
    tz: _momentTimezone2.default.tz.guess()
  };
}
/**
 * @param {Object} cardElement A Stripe "card" type element.
 * @param {String} [cardName] The card holder name.
 * @param {String} [cardAddressLine1] The card holder address line 1.
 * @param {String} [cardAddressCity] The card holder city.
 * @param {String} [cardAddressState] The card holder state.
 * https://stripe.com/docs/elements/reference#the-elements-object
 */


function createStripeTokenFromElement(cardElement, cardName, cardAddressLine1, cardAddressCity, cardAddressState) {
  return new Promise(function (resolve, reject) {
    window.stripe.createToken(cardElement, {
      name: cardName,
      address_line1: cardAddressLine1,
      address_city: cardAddressCity,
      address_state: cardAddressState
    }).then(function (result) {
      if (result.error) {
        reject(result.error);
      } else {
        resolve(result.token.id);
      }
    });
  });
}
/**
 * @param {Object} opts Payment method arguments.
 * @param {String} opts.firstName User first name.
 * @param {String} opts.lastName User last name.
 * @param {String} opts.email User email.
 * @param {String} opts.stripeToken The Stripe payment token.
 * @param {String} opts.apiEndpoint The Sure api url to post to.
 * @param {String} [opts.authToken] The Sure authentication token.
 * @param {Boolean} [opts.getId] If true, it will resolve with the payment
 * method ID instead of the entire body JSON.
 */


function createPaymentMethod(opts) {
  var payload = {
    first_name: opts.firstName,
    last_name: opts.lastName,
    email: opts.email,
    token: opts.stripeToken
  };

  var req = _superagent2.default.post(opts.apiEndpoint);

  if (opts.authToken) {
    var authMethod = opts.authToken.length < 60 ? 'Token' : 'Bearer';
    req.set('Authorization', authMethod + ' ' + opts.authToken);
  }

  return new Promise(function (resolve, reject) {
    req.send(payload).end(function (err, response) {
      if (err) {
        reject(err);
      } else if (opts.getId) {
        var paymentMethodId = response.body.public_id || response.body.payment_method_id;
        resolve(paymentMethodId);
      } else {
        resolve(response.body);
      }
    });
  });
}

function updatePaymentMethod(opts) {
  var payload = {
    email: opts.email,
    source_token: opts.stripeToken,
    name: opts.name
  };

  var req = _superagent2.default.post(opts.apiEndpoint);

  if (opts.authToken) {
    var authMethod = opts.authToken.length < 60 ? 'Token' : 'Bearer';
    req.set('Authorization', authMethod + ' ' + opts.authToken);
  }

  return new Promise(function (resolve, reject) {
    req.send(payload).end(function (err, response) {
      if (err) {
        reject(err);
      } else if (opts.getId) {
        var paymentMethodId = response.body.public_id || response.body.payment_method_id;
        resolve(paymentMethodId);
      } else {
        resolve(response.body);
      }
    });
  });
}

function logException(ex, context) {
  Sentry.withScope(function (scope) {
    if (!(0, _isEmpty2.default)(context) && (0, _isPlainObject2.default)(context)) {
      (0, _each2.default)(context, function (val, key) {
        scope.setExtra(key, val);
      });
    }

    var _hrq = (0, _get2.default)(ex, 'response.headers[\'x-request-id\']');

    if (!!_hrq) {
      scope.setTag('hrq', _hrq);
    }

    if (ex instanceof Error) {
      Sentry.captureException(ex);
    } else if ((0, _isPlainObject2.default)(ex) && ex.message) {
      Sentry.captureException(new Error(ex.message));
    } else if ((0, _isString2.default)(ex)) {
      Sentry.captureMessage(ex);
    }
  });
  window.console && window.console.error && window.console.error(ex);
}

function setSentryUserContext() {
  var account = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  Sentry.configureScope(function (scope) {
    scope.setUser({
      email: account.email,
      id: account.publicId
    });
  });
}
/**
 * Omit unneccessary or sensitive data from the Redux state.
 *
 * @param {Object} state The redux store state object.
 * @returns {Object} The sanitized state object.
 */


function sanitizeSentryStatePayload() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var omitKeys = ['airlines', 'authToken', 'clients', 'policies', 'policies', 'quotes', 'routing'];
  return (0, _omitBy2.default)(state, function (val, key) {
    return val === null || (0, _includes2.default)(omitKeys, key);
  });
}
/**
 * @param {Object|Array} [planOrPlans] A plan object or array of plan objects.
 * @param {String} [underwriterName] The underwriter name used for fallback messaging.
 *
 * @returns {String|null} The bottom disclaimer text, or null.
 */


function getDisclaimer(planOrPlans, underwriterName) {
  var plan = (0, _isArray2.default)(planOrPlans) ? planOrPlans[0] : planOrPlans;

  if (plan) {
    return (0, _get2.default)(plan, 'information.bottom_text');
  } else if (underwriterName) {
    return 'Policies underwritten by ' + underwriterName + '. Sold by Sure HIIS Insurance Services, LLC.';
  } else {
    return null;
  }
}
/**
 * @param {Array} screens The array of dynamic form screen objects.
 * @param {Object} responses The object of dynamic form responses.
 * Keys are dynamic form field key_name which are mapped to the response.
 *
 * @returns {Object} The filtered responses object.
 */


function filterDynamicFormResponses(screens, responses) {
  var fields = (0, _flatMap2.default)(screens, 'fields');
  var fieldKeys = (0, _map2.default)(fields, 'key_name');
  var groomedResponses = (0, _pick2.default)(responses, fieldKeys);
  var validatedResponses = (0, _mapValues2.default)(groomedResponses, function (response, key) {
    var field = (0, _find2.default)(fields, {
      key_name: key
    });

    switch (field.field_type) {
      case 'list':
        return (0, _reject2.default)(response, function (listItem) {
          if ((0, _isPlainObject2.default)(listItem)) {
            return (0, _every2.default)((0, _values2.default)(listItem), _isEmpty2.default);
          } else {
            return (0, _isEmpty2.default)(listItem);
          }
        });

      default:
        return response;
    }
  });
  return validatedResponses;
}

function trimValues(opts) {
  return (0, _mapValues2.default)(opts, function (val) {
    if ((0, _isPlainObject2.default)(val)) {
      return trimValues(val);
    } else if ((0, _isString2.default)(val)) {
      return (0, _trim2.default)(val);
    } else {
      return val;
    }
  });
}
/**
 * Suspends a promise from resolving until the minimum wait time.
 *
 * @param {Promise} promise The promise to resolve.
 * @param {Number} minWaitMs The minimum wait time. Default 0.
 */


function suspendPromise(promise) {
  var minWaitMs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Promise.all([promise, new Promise(function (resolve) {
    setTimeout(function () {
      return resolve();
    }, minWaitMs);
  })]);
}
/**
 * Proxy to the resolveUrl package which returns the path only.
 * This is mainly used by react-router navigation (v3) in order
 * to navigate to relative URLs, in which a path (not an absolute
 * url) is required.
 *
 * @param {...String} args 1 or more paths to resolve.
 */


function resolveUrlPath() {
  var resolvedUrl = _resolveUrl2.default.apply(undefined, arguments);

  var resolvedUrlPath = resolvedUrl.replace(window.location.origin, '');
  return resolvedUrlPath;
}
/**
 * Emit a message for embedding containers such as iframe or mobile webviews.
 * This calls postMessage, if available, on:
 *
 * 1. window.parent - For iframes
 * 2. window.webkit.messageHandlers.message - For iOS WKWebView
 *
 * @param {Object} message The message object to post. Typically should
 * contain the following structure:
 *   {
 *     type: 'EXAMPLE_EVENT_TYPE',
 *     payload: {},
 *   }
 */


function emitMessage() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (window.parent && window.parent.postMessage) {
    window.parent.postMessage(message, '*');
  }

  if ((0, _isFunction2.default)((0, _get2.default)(window.webkit, 'messageHandlers.message.postMessage'))) {
    window.webkit.messageHandlers.message.postMessage(message, '*');
  }
}
/**
 * Get JSON from a fetch response object and pass along the result with status.
 *
 * @param {Object} response Resulting response object from a fetch call.
 */


function getResponseJsonWithStatus(response) {
  if (response.status === 204) {
    return {
      status: response.status,
      body: null
    };
  } else {
    return response.json().then(function (data) {
      return {
        status: response.status,
        body: data
      };
    });
  }
}
/**
 * Geocode address to get lat/lng coordinates.
 * @param {String} address The full address to geocode.
 */


function geocodeAddress(address) {
  return new Promise(function (resolve, reject) {
    if (window.google) {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        address: address
      }, function (results, status) {
        if (status === 'OK') {
          resolve(Object.assign({}, normalizeGooglePlaceAddressComponents(results[0].address_components), {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }));
        } else {
          reject({
            code: status
          });
        }
      });
    } else {
      console.error('Google API library missing');
      reject({
        code: 'GOOGLE_LIBRARY_MISSING'
      });
    }
  });
}
/**
 * Normalize the address_components array from a Google places result from this:
 *   [
 *     {
 *       long_name: 'United States',
 *       short_name: 'US',
 *       types: ['country'],
 *     },
 *     // etc.
 *   ]
 *
 * To a standard object:
 * {
 *   streetAddress: '123 Mulberry Lane',
 *   unit: '5H',
 *   city: 'Brooklyn',
 *   region: 'NY',
 *   postal: '11211',
 *   country: 'US',
 * }
 *
 * @param {Array} addressComponents The Google place result.address_components array.
 */


function normalizeGooglePlaceAddressComponents() {
  var addressComponents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var addressTypesMap = addressComponents.reduce(function (result, addressComponent) {
    addressComponent.types.forEach(function (type) {
      result[(0, _camelCase2.default)(type)] = {
        longName: addressComponent.long_name,
        shortName: addressComponent.short_name
      };
    });
    return result;
  }, {});
  var streetNumber = (0, _get2.default)(addressTypesMap, 'streetNumber.longName');
  var streetAddress = (0, _get2.default)(addressTypesMap, 'route.longName');
  var city = (0, _get2.default)(addressTypesMap.locality || addressTypesMap.sublocalityLevel1 || addressTypesMap.administrativeAreaLevel3, 'longName');
  var region = (0, _get2.default)(addressTypesMap, 'administrativeAreaLevel1.shortName');
  var postal = (0, _get2.default)(addressTypesMap, 'postalCode.longName');
  var country = (0, _get2.default)(addressTypesMap, 'country.shortName');
  return {
    streetAddress: [streetNumber, streetAddress].join(' '),
    city: city,
    region: region,
    postal: postal,
    country: country
  };
}
/**
 * Recursively creates an object composed of the own and inherited enumerable string keyed properties
 * of object that predicate doesn't return truthy for. The predicate is invoked with two arguments: (value, key).
 * @param {Object} obj The source object.
 * @param {Function} [predicate] The function invoked per property.
 */


function deepOmitBy(obj, predicate) {
  var recursiveOmit = function recursiveOmit(recObj, recPredicate) {
    Object.keys(recObj).forEach(function (key) {
      var item = recObj[key];

      if (recPredicate({
        value: recObj[key],
        key: key
      })) {
        delete recObj[key];
      } else {
        if ((0, _isPlainObject2.default)(item)) {
          recursiveOmit(item, recPredicate);
        } else if ((0, _isArray2.default)(item)) {
          item.forEach(function (subitem) {
            return recursiveOmit(subitem, recPredicate);
          });
        }
      }
    });
    return recObj;
  };

  var newObj = (0, _cloneDeep2.default)(obj);
  recursiveOmit(newObj, predicate);
  return newObj;
}
/**
 * Returns a new promise which resolves the axios response body or rejects with the error data.
 */


function destructureAxiosReq(axiosPromise) {
  return new Promise(function (resolve, reject) {
    axiosPromise.then(function (response) {
      return resolve(response.data);
    }, function (error) {
      if (error.response) {
        reject(error.response.data.error);
      } else {
        reject(error);
      }
    });
  });
}
/**
 * Generate a UUID v4.
 * Mainly for use in our API header for idempotent transactions.
 */


function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
/**
 * Check browser webp support.
 * Function returns an async callback, so run it once immediately and set it to an exported variable.
 */


var isWebPSupported = exports.isWebPSupported = false;

(function () {
  check_webp_feature('lossy', function (_, isSupported) {
    exports.isWebPSupported = isWebPSupported = isSupported;
  });
})();
/**
 * check_webp_feature:
 *  'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
 *  'callback(feature, isSupported)' will be passed back the detection result (in an asynchronous way!)
 */


function check_webp_feature(feature, callback) {
  var kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
  };
  var img = new Image();

  img.onload = function () {
    var result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };

  img.onerror = function () {
    callback(feature, false);
  };

  img.src = 'data:image/webp;base64,' + kTestImages[feature];
}