import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddressForm from 'shared/lib/components/AddressForm';
import Button from 'shared/lib/components/Button';
import { RENTERS_ROUTES } from 'shared/lib/apps/RentersApp/routes';
import * as analytics from 'shared/lib/analytics';
import Footer from '../Footer';
import TopNavBar from '../TopNavBar';
import userSarahImg from './images/user-sarah-davidson.png';
import userJacobImg from './images/user-jacob-leatherman.png';
import userCourtneyImg from './images/user-courtney-boulter.png';
import './LandingRenters.css';

const propTypes = {
  propertyAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    postal: PropTypes.string,
    region: PropTypes.string,
    streetAddress: PropTypes.string,
    unit: PropTypes.string,
  }),
  resetRentersDate: PropTypes.func,
  updateRentersAddress: PropTypes.func,
};

const defaultProps = {
  propertyAddress: {},
  resetRentersDate: () => {},
  updateRentersAddress: () => {},
};

class LandingRenters extends Component {
  componentDidMount() {
    const { resetRentersData } = this.props;
    analytics.page('Renters - Landing');
    analytics.track('Renters - Landing Page Viewed');
    resetRentersData();
  }

  render() {
    const {
      isMenuOpen,
      onToggleMenu,
      partnerInfo,
      propertyAddress,
      settings,
      updateRentersAddress,
    } = this.props;

    return (
      <div className="LandingRenters">
        <div className="LandingRenters-hero">
          <TopNavBar
            transparent
            showMenuText
            isMenuOpen={isMenuOpen}
            sideMenuLinks={settings.sideMenuLinks}
            logoLightUrl={settings.logoLightUrl}
            logoDarkUrl={settings.logoDarkUrl}
            title={settings.title}
            onToggleMenu={onToggleMenu}
          />
          <div className="Container">
            <h1 className="LandingRenters-hero-heading">
              Get Renters Insurance and protect your treasures
            </h1>
            <p className="LandingRenters-hero-pitch">
              We've made it simple: tell us about you, where you live, and we'll
              get you the best policy.
            </p>
            <div className="LandingRenters-hero-form">
              <AddressForm
                streetAddress={propertyAddress.streetAddress}
                unit={propertyAddress.unit}
                city={propertyAddress.city}
                region={propertyAddress.region}
                postal={propertyAddress.postal}
                country={propertyAddress.country}
                line1CharLimit={30}
                onChange={updateRentersAddress}
              />
            </div>
            <Button
              className="LandingRenters-cta"
              to={RENTERS_ROUTES.START}
              passedThroughState={{ fromLanding: true }}
              shadow
            >
              <i className="material-icons">&#xE8E8;</i>
              Get Started
            </Button>
          </div>
        </div>

        <div className="LandingRenters-coverage">
          <div className="Container">
            <h2 className="LandingRenters-coverage-heading">
              What does Renters Insurance cover?
            </h2>
            <p className="LandingRenters-coverage-info">
              It covers a lot more than you might think. Protect your clothes,
              furniture, and even yourself from unexpected damages and injuries.
            </p>
            <div className="LandingRenters-coverage-threeUp">
              <div className="LandingRenters-coverage-threeUp-card">
                <div className="LandingRenters-coverage-threeUp-card-header">
                  <div className="LandingRenters-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE337;</i>
                  </div>
                </div>
                <h3 className="LandingRenters-coverage-threeUp-card-heading">
                  Your stuff is safe and sound
                </h3>
                <p className="LandingRenters-coverage-threeUp-card-info">
                  Your belongings will be covered against theft, loss, and most
                  forms of destruction. Even if it's in your car or with you on
                  the go, you can make sure your things are secure.
                </p>
              </div>
              <div className="LandingRenters-coverage-threeUp-card">
                <div className="LandingRenters-coverage-threeUp-card-header">
                  <div className="LandingRenters-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE84F;</i>
                  </div>
                </div>
                <h3 className="LandingRenters-coverage-threeUp-card-heading">
                  Covered against lawsuits
                </h3>
                <p className="LandingRenters-coverage-threeUp-card-info">
                  If you hurt someone or damage someone else's property, those
                  expenses can add up quickly, renters insurance has you
                  covered.
                </p>
              </div>
              <div className="LandingRenters-coverage-threeUp-card">
                <div className="LandingRenters-coverage-threeUp-card-header">
                  <div className="LandingRenters-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE548;</i>
                  </div>
                </div>
                <h3 className="LandingRenters-coverage-threeUp-card-heading">
                  Covered against medical costs
                </h3>
                <p className="LandingRenters-coverage-threeUp-card-info">
                  You can't always control what happens inside your home,
                  renters insurance covers reasonable medical expenses if
                  someone is hurt on your property.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="LandingRenters-pricing">
          <h2 className="LandingRenters-pricing-heading">
            How much is this going to cost?
          </h2>
          <p className="LandingRenters-pricing-info">
            Plans are as low as <strong>$11/month</strong>.
            <br />
            It's a no brainer.
          </p>
          <p className="LandingRenters-pricing-comparisonText">
            That's the same amount you would spend on
          </p>
          <div className="LandingRenters-pricing-examples">
            <div className="LandingRenters-pricing-examples-example">
              <div className="LandingRenters-pricing-examples-example-icon">
                <i className="material-icons">&#xE556;</i>
              </div>
              <p className="LandingRenters-pricing-examples-example-text">
                1 Dinner
              </p>
            </div>
            <div className="LandingRenters-pricing-examples-example">
              <div className="LandingRenters-pricing-examples-example-icon">
                <i className="material-icons">&#xE639;</i>
              </div>
              <p className="LandingRenters-pricing-examples-example-text">
                1 Month of Netflix
              </p>
            </div>
            <div className="LandingRenters-pricing-examples-example">
              <div className="LandingRenters-pricing-examples-example-icon">
                <i className="material-icons">&#xE541;</i>
              </div>
              <p className="LandingRenters-pricing-examples-example-text">
                1 Coffee Date
              </p>
            </div>
            <div className="LandingRenters-pricing-examples-example">
              <div className="LandingRenters-pricing-examples-example-icon">
                <i className="material-icons">&#xE540;</i>
              </div>
              <p className="LandingRenters-pricing-examples-example-text">
                1 Fancy Cocktail
              </p>
            </div>
          </div>
        </div>

        <div className="LandingRenters-testimonials">
          <h2 className="LandingRenters-testimonials-heading">
            Renters Insurance protects you from life's unexpected twists.
          </h2>
          <ul className="LandingRenters-testimonials-list">
            <li className="LandingRenters-testimonial">
              <p className="LandingRenters-testimonial-text">
                I had a house warming party, and my speakers were stolen.
                Luckily I had renters insurance, I was able to replace them with
                ease! Filing a claim was simple, and I was set back up in no
                time.
              </p>
              <div className="LandingRenters-testimonial-person">
                <img
                  className="LandingRenters-testimonial-person-avatar"
                  src={userSarahImg}
                  alt="Sarah Davidson"
                />
                <div className="LandingRenters-testimonial-person-info">
                  <p className="LandingRenters-testimonial-person-info-name">
                    Sarah Davidson
                  </p>
                  <p className="LandingRenters-testimonial-person-info-location">
                    Sunnyvale, CA
                  </p>
                </div>
              </div>
            </li>
            <li className="LandingRenters-testimonial">
              <p className="LandingRenters-testimonial-text">
                My laptop and some other items were stolen from my car while I
                was at the gym. I didn’t even realize renters insurance covered
                theft outside the home, but they took care of everything I lost,
                even my coat.
              </p>
              <div className="LandingRenters-testimonial-person">
                <img
                  className="LandingRenters-testimonial-person-avatar"
                  src={userJacobImg}
                  alt="Jacob Leatherman"
                />
                <div className="LandingRenters-testimonial-person-info">
                  <p className="LandingRenters-testimonial-person-info-name">
                    Jacob Leatherman
                  </p>
                  <p className="LandingRenters-testimonial-person-info-location">
                    Spokane, WA
                  </p>
                </div>
              </div>
            </li>
            <li className="LandingRenters-testimonial">
              <p className="LandingRenters-testimonial-text">
                I had some great outdoor furniture on my balcony. One night, a
                wild storm came through and blew over the umbrella, right
                through the glass door. My renters insurance covered the repair,
                and a new umbrella, too.
              </p>
              <div className="LandingRenters-testimonial-person">
                <img
                  className="LandingRenters-testimonial-person-avatar"
                  src={userCourtneyImg}
                  alt="Courtney Boulter"
                />
                <div className="LandingRenters-testimonial-person-info">
                  <p className="LandingRenters-testimonial-person-info-name">
                    Courtney Boulter
                  </p>
                  <p className="LandingRenters-testimonial-person-info-location">
                    San Jose, CA
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="LandingRenters-bottomCta">
          <h3 className="LandingRenters-bottomCta-heading">
            Ready to protect your home?
          </h3>
          <p className="LandingRenters-bottomCta-info">
            Based on your needs, we'll help you select the most coverage at the
            best price.
          </p>
          <Button
            className="LandingRenters-cta"
            to={RENTERS_ROUTES.START}
            sureStyle="success"
            shadow
          >
            <i className="material-icons">&#xE8E8;</i>
            Get Started
          </Button>
        </div>
        <Footer partnerInfo={partnerInfo} />
      </div>
    );
  }
}

LandingRenters.propTypes = propTypes;
LandingRenters.defaultProps = defaultProps;

export default LandingRenters;
