'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersPayment/RentersPayment.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AddressSection = require('shared/lib/components/AddressSection');

var _AddressSection2 = _interopRequireDefault(_AddressSection);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

var _PaymentLayout = require('shared/lib/components/PaymentLayout');

var _PaymentLayout2 = _interopRequireDefault(_PaymentLayout);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var RentersPayment = function (_Component) {
  _inherits(RentersPayment, _Component);

  function RentersPayment() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, RentersPayment);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentersPayment.__proto__ || Object.getPrototypeOf(RentersPayment)).call.apply(_ref, [this].concat(args))), _this), _this.handleUpdateRentersPaymentCadence = function (e) {
      _this.props.selectRentersPaymentCadence(e.target.value);

      _this.props.calculateRentersQuote().catch(_this.props.onError);
    }, _this.handleSubmit = function (cardElements) {
      analytics.track('Payment Info Entered', {
        category: 'order'
      });
      analytics.track('Checkout Step Completed', {
        category: 'order',
        step: 2
      });

      _this.props.purchase(cardElements).then(function () {
        _this.props.navTo((0, _utils.resolveUrlPath)('confirmation'));
      }).catch(function (err) {
        _this.props.onError(err.response.body.error);
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentersPayment, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Renters - Payment Info', {
        value: Math.round(this.props.totals.grandtotal)
      });
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 2
      });
      analytics.track('InitiateCheckout');
      analytics.track('Renters - Payment Info Viewed');
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentersPayment Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 58
        },
        __self: this
      }, _react2.default.createElement(_PaymentLayout2.default, {
        title: 'Your Renters Quote',
        policyDocUrl: this.props.plan.policy_doc_url,
        icon: 'location_city',
        policyStartDate: this.props.policyStartDate,
        billingCycle: this.props.rentersSelectedPaymentCadence === 'eleven_pay' ? 'month' : 'year',
        quote: this.props.rentersQuote,
        paymentCadences: this.props.rentersPaymentCadences,
        selectedPaymentCadence: this.props.rentersSelectedPaymentCadence,
        isPurchaseInProgress: this.props.isRentersInProgress,
        totals: this.props.totals,
        acknowledgmentHtml: (0, _get2.default)(this.props.plan, 'information.acknowledgment_html'),
        disclaimer: this.props.disclaimer,
        extraCCFields: true,
        onUpdatePaymentCadence: this.handleUpdateRentersPaymentCadence,
        onSubmit: this.handleSubmit,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 59
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Property address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 83
        },
        __self: this
      }, _react2.default.createElement(_AddressSection2.default, {
        fullName: this.props.fullName,
        address: this.props.propertyAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 84
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Underwritten by', this.props.policyProvider], ['Property coverage', '$' + (0, _utils.numberWithCommas)(this.props.selectedAmounts.personal_property_coverage || this.props.selectedAmounts.personal_property_amount)], ['Start date', this.props.policyStartDate.format('MMM D, YYYY')], ['Billing cycle', this.billingCycle]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }))));
    }
  }, {
    key: 'billingCycle',
    get: function get() {
      switch (this.props.rentersSelectedPaymentCadence) {
        case 'eleven_pay':
          return 'Monthly (11 Payments)';

        default:
          return 'Annual';
      }
    }
  }]);

  return RentersPayment;
}(_react.Component);

exports.default = RentersPayment;