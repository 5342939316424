'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LandingBlockCTABanner/LandingBlockCTABanner.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./LandingBlockCTABanner.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  subtitle: _propTypes2.default.string,
  ctaText: _propTypes2.default.string,
  ctaStyle: _propTypes2.default.oneOf(['primary', 'success', 'secondary', 'outline']),
  linkTo: _propTypes2.default.string,
  lightTheme: _propTypes2.default.bool,
  onClick: _propTypes2.default.func,
  footnote: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.element])
};
var defaultProps = {
  title: null,
  subtitle: null,
  ctaText: 'Get started',
  ctaStyle: 'success',
  lightTheme: false,
  onClick: function onClick() {},
  footnote: ''
};

var LandingBlockCTABanner = function (_PureComponent) {
  _inherits(LandingBlockCTABanner, _PureComponent);

  function LandingBlockCTABanner() {
    _classCallCheck(this, LandingBlockCTABanner);

    return _possibleConstructorReturn(this, (LandingBlockCTABanner.__proto__ || Object.getPrototypeOf(LandingBlockCTABanner)).apply(this, arguments));
  }

  _createClass(LandingBlockCTABanner, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          ctaStyle = _props.ctaStyle,
          ctaText = _props.ctaText,
          footnote = _props.footnote,
          lightTheme = _props.lightTheme,
          linkTo = _props.linkTo,
          onClick = _props.onClick,
          subtitle = _props.subtitle,
          title = _props.title;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('LandingBlockCTABanner', {
          'LandingBlockCTABanner--lightTheme': lightTheme
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      }, _react2.default.createElement('h3', {
        className: 'LandingBlockCTABanner-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 48
        },
        __self: this
      }, _react2.default.createElement('div', {
        dangerouslySetInnerHTML: {
          __html: title
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49
        },
        __self: this
      })), subtitle && _react2.default.createElement('p', {
        className: 'LandingBlockCTABanner-info',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 51
        },
        __self: this
      }, subtitle), _react2.default.createElement(_Button2.default, {
        className: 'LandingBlockCTABanner-cta',
        rel: 'nofollow',
        to: linkTo,
        sureStyle: ctaStyle || 'secondary',
        shadow: true,
        onClick: onClick,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 52
        },
        __self: this
      }, ctaText), footnote && ((0, _isString2.default)(footnote) ? _react2.default.createElement('p', {
        className: 'LandingBlockCTABanner-footnote',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 64
        },
        __self: this
      }, footnote) : footnote));
    }
  }]);

  return LandingBlockCTABanner;
}(_react.PureComponent);

LandingBlockCTABanner.propTypes = propTypes;
LandingBlockCTABanner.defaultProps = defaultProps;
exports.default = LandingBlockCTABanner;