'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/components/GlobalModals/GlobalModals.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ModalCollectiblesAddToExisting = require('./ModalCollectiblesAddToExisting');

var _ModalCollectiblesAddToExisting2 = _interopRequireDefault(_ModalCollectiblesAddToExisting);

var _ModalInfo = require('./ModalInfo');

var _ModalInfo2 = _interopRequireDefault(_ModalInfo);

var _ModalError = require('./ModalError');

var _ModalError2 = _interopRequireDefault(_ModalError);

var _ModalComponent = require('./ModalComponent');

var _ModalComponent2 = _interopRequireDefault(_ModalComponent);

var _ModalSelect = require('./ModalSelect');

var _ModalSelect2 = _interopRequireDefault(_ModalSelect);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  activeModal: _propTypes2.default.string,
  onDismiss: _propTypes2.default.func,
  className: _propTypes2.default.string
};
var defaultProps = {
  activeModal: null,
  onDismiss: function onDismiss() {},
  className: null
};

var GlobalModals = function (_PureComponent) {
  _inherits(GlobalModals, _PureComponent);

  function GlobalModals() {
    _classCallCheck(this, GlobalModals);

    return _possibleConstructorReturn(this, (GlobalModals.__proto__ || Object.getPrototypeOf(GlobalModals)).apply(this, arguments));
  }

  _createClass(GlobalModals, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          activeModal = _props.activeModal,
          rest = _objectWithoutProperties(_props, ['activeModal']);

      switch (activeModal) {
        case 'error':
          return _react2.default.createElement(_ModalError2.default, _extends({}, rest, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 27
            },
            __self: this
          }));

        case 'info':
          return _react2.default.createElement(_ModalInfo2.default, _extends({}, rest, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 29
            },
            __self: this
          }));

        case 'collectiblesAddToExisting':
          return _react2.default.createElement(_ModalCollectiblesAddToExisting2.default, _extends({}, rest, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 31
            },
            __self: this
          }));

        case 'component':
          return _react2.default.createElement(_ModalComponent2.default, _extends({}, rest, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 33
            },
            __self: this
          }));

        case 'select':
          return _react2.default.createElement(_ModalSelect2.default, _extends({}, rest, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 35
            },
            __self: this
          }));

        default:
          return null;
      }
    }
  }]);

  return GlobalModals;
}(_react.PureComponent);

GlobalModals.propTypes = propTypes;
GlobalModals.defaultProps = defaultProps;
exports.default = GlobalModals;