'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarSignedAgreement/RentalCarSignedAgreement.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _sureComponents = require('sure-components');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utils = require('shared/lib/utils');

var _isBoolean = require('lodash/isBoolean');

var _isBoolean2 = _interopRequireDefault(_isBoolean);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarSignedAgreement.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  hasSignedAgreement: _propTypes2.default.bool,
  plan: _propTypes2.default.object,
  updateHasSignedAgreement: _propTypes2.default.func,
  navTo: _propTypes2.default.func,
  showGlobalModal: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  hasSignedAgreement: null,
  plan: {}
};

var RentalCarSignedAgreement = function (_Component) {
  _inherits(RentalCarSignedAgreement, _Component);

  function RentalCarSignedAgreement() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, RentalCarSignedAgreement);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentalCarSignedAgreement.__proto__ || Object.getPrototypeOf(RentalCarSignedAgreement)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.track('Rental Car - Next Button Clicked');

      if (_this.props.hasSignedAgreement) {
        analytics.track('Rental Car - Ineligible for Coverage Date', {
          noninteraction: 1
        });

        _this.props.showGlobalModal({
          id: 'info',
          title: 'Ineligible for coverage',
          body: _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 43
            },
            __self: _this2
          }, 'Unfortunately we cannot cover you today, as Rental Car insurance is only available prior to signing the rental car agreement or picking up the vehicle. Please purchase your insurance before signing the agreement or picking up the vehicle in the future.')
        });
      } else {
        _this.props.navTo((0, _utils.resolveUrlPath)('use-type'));
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentalCarSignedAgreement, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Has Signed Agreement');
    }
  }, {
    key: 'handleUpdate',
    value: function handleUpdate(value) {
      this.props.updateHasSignedAgreement(value);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentalCarSignedAgreement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 62
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 63
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarSignedAgreement-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 64
        },
        __self: this
      }, 'Have you already signed the rental car agreement or picked up your car?'), _react2.default.createElement('form', {
        className: 'RentalCarSignedAgreement-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 68
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.RadioButton, {
        name: 'has_signed_agreement',
        value: 'true',
        title: 'Yes',
        checked: this.props.hasSignedAgreement === true,
        onChange: this.handleUpdate.bind(this, true),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 73
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.RadioButton, {
        name: 'has_signed_agreement',
        value: 'false',
        title: 'No',
        checked: this.props.hasSignedAgreement === false,
        onChange: this.handleUpdate.bind(this, false),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        isNextDisabled: !(0, _isBoolean2.default)(this.props.hasSignedAgreement),
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 94
        },
        __self: this
      })));
    }
  }]);

  return RentalCarSignedAgreement;
}(_react.Component);

RentalCarSignedAgreement.propTypes = propTypes;
RentalCarSignedAgreement.defaultProps = defaultProps;
exports.default = RentalCarSignedAgreement;