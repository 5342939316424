'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistedBopReducerKeys = exports.bopReducers = undefined;

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _utils = require('shared/lib/utils');

var _findIndex = require('lodash/findIndex');

var _findIndex2 = _interopRequireDefault(_findIndex);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var bopReducers = {
  bopBusinessClasses: function bopBusinessClasses() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BUSINESS_CLASSES_SUCCESS:
        return action.payload.businessClasses;

      case _actionTypes2.default.SIGN_OUT:
        return [];

      default:
        return state;
    }
  },
  bopRatingAddress: function bopRatingAddress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_BOP_RATING_ADDRESS:
        return Object.assign({}, state, action.payload.ratingAddress);

      case _actionTypes2.default.SIGN_OUT:
        return {};

      default:
        return state;
    }
  },
  bopDetails: function bopDetails() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_BOP_DETAILS:
        return Object.assign({}, state, action.payload.details);

      case _actionTypes2.default.GET_BOP_PLANS_SUCCESS:
        return (0, _pick2.default)(state, ['business_class_code']);

      case _actionTypes2.default.DELETE_BOP_RESPONSE:
        return (0, _omit2.default)(state, action.payload.responseKeys);

      case _actionTypes2.default.SIGN_OUT:
        return {};

      default:
        return state;
    }
  },
  bopPlans: function bopPlans() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_PLANS_SUCCESS:
        return action.payload.plans || [];

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return [];

      default:
        return state;
    }
  },

  /*
   * Survey flow
   * ========================================
   */
  bopScreens: function bopScreens() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_PLANS_SUCCESS:
        return action.payload.dynamic_form.screens;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return {};

      default:
        return state;
    }
  },
  bopScreenSequence: function bopScreenSequence() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    if (action.type === _actionTypes2.default.GET_BOP_PLANS_SUCCESS) {
      var dynamic_form = action.payload.dynamic_form;
      var map = [{
        id: dynamic_form.start_screen_id,
        parentId: null
      }];
      var trail = (0, _utils.generateTrail)(dynamic_form.screens, dynamic_form.start_screen_id, null, true);
      return map.concat(trail);
    } else if (action.type === _actionTypes2.default.SELECT_BOP_CHOICE) {
      var _action$getState = action.getState(),
          bopCurrentScreenId = _action$getState.bopCurrentScreenId,
          bopScreens = _action$getState.bopScreens;

      var currentIndex = (0, _findIndex2.default)(state, {
        id: bopCurrentScreenId
      });
      var trailToAdd = [];
      var parentId = state[currentIndex].id;

      var _trail = (0, _utils.generateTrail)(bopScreens, action.screen_id, parentId, true);

      var initialObj = {
        id: action.screen_id,
        parentId: parentId,
        first: true
      };
      trailToAdd.push(initialObj);

      if (_trail.length) {
        trailToAdd = trailToAdd.concat(_trail);
      }

      var start = state.slice(0, currentIndex + 1);
      var end = state.slice(currentIndex + 1);
      var newMap = start.concat(trailToAdd).concat(end);
      return newMap;
    } else if (action.type === _actionTypes2.default.BOP_SURVEY_BACK) {
      var _action$getState2 = action.getState(),
          _bopCurrentScreenId = _action$getState2.bopCurrentScreenId;

      var _currentIndex = (0, _findIndex2.default)(state, {
        id: _bopCurrentScreenId
      });

      if (_currentIndex !== 0) {
        var current = state[_currentIndex];
        var _newMap = []; // Clear all step created from this parent

        if (current.first && current.parentId) {
          state.forEach(function (step, idx) {
            if (step.parentId !== current.parentId) {
              _newMap.push(step);
            }
          });
          return _newMap;
        }

        return state;
      } else {
        return state;
      }
    } else {
      return state;
    }
  },
  bopCurrentScreenId: function bopCurrentScreenId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];
    var nextState = void 0;

    var _ref = action.getState && action.getState() || {},
        screenSequence = _ref.bopScreenSequence;

    if (action.type === _actionTypes2.default.BOP_SURVEY_NEXT) {
      var currentIndex = (0, _findIndex2.default)(screenSequence, {
        id: state
      });
      var nextIndex = currentIndex + 1;

      if (screenSequence[nextIndex]) {
        nextState = screenSequence[nextIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.BOP_SURVEY_BACK) {
      var _currentIndex2 = (0, _findIndex2.default)(screenSequence, {
        id: state
      });

      var prevIndex = _currentIndex2 - 1;

      if (_currentIndex2 !== 0) {
        nextState = screenSequence[prevIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.GET_BOP_PLANS_SUCCESS) {
      nextState = action.payload.dynamic_form.start_screen_id;
    } else {
      nextState = state;
    }

    return nextState;
  },
  bopCurrentResponse: function bopCurrentResponse() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_BOP_CURRENT_RESPONSE:
        return Object.assign({}, state, action.payload.response);

      case _actionTypes2.default.GET_BOP_PLANS:
      case _actionTypes2.default.SAVE_BOP_RESPONSE:
      case _actionTypes2.default.BOP_SURVEY_BACK:
      case _actionTypes2.default.BOP_SURVEY_NEXT:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return {};

      default:
        return state;
    }
  },
  bopPolicyStartDate: function bopPolicyStartDate() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _momentTimezone2.default)().toISOString();
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_PLANS_SUCCESS:
        return (0, _momentTimezone2.default)().toISOString();

      case _actionTypes2.default.UPDATE_BOP_START_DATE:
        return action.payload.isoString;

      default:
        return state;
    }
  },
  bopQuoteId: function bopQuoteId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_RATES_SUCCESS:
        return action.payload.quote_id || null;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return null;

      default:
        return state;
    }
  },
  bopRates: function bopRates() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_RATES_SUCCESS:
        return action.payload.rates || {};

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return {};

      default:
        return state;
    }
  },
  bopSelectedPlanId: function bopSelectedPlanId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_CHECKOUT:
        return action.payload.planId;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return null;

      default:
        return state;
    }
  },
  bopCheckoutInfo: function bopCheckoutInfo() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BOP_CHECKOUT_SUCCESS:
        return action.payload;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return {};

      default:
        return state;
    }
  },
  bopPurchaseInfo: function bopPurchaseInfo() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_BOP_SUCCESS:
        return action.payload;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return {};

      default:
        return state;
    }
  },
  isBopInProgress: function isBopInProgress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_BUSINESS_CLASSES:
      case _actionTypes2.default.GET_BOP_PLANS:
      case _actionTypes2.default.GET_BOP_RATES:
      case _actionTypes2.default.GET_BOP_CHECKOUT:
      case _actionTypes2.default.PURCHASE_BOP:
        return true;

      case _actionTypes2.default.GET_BUSINESS_CLASSES_SUCCESS:
      case _actionTypes2.default.GET_BUSINESS_CLASSES_FAILURE:
      case _actionTypes2.default.GET_BOP_PLANS_SUCCESS:
      case _actionTypes2.default.GET_BOP_PLANS_FAILURE:
      case _actionTypes2.default.GET_BOP_RATES_SUCCESS:
      case _actionTypes2.default.GET_BOP_RATES_FAILURE:
      case _actionTypes2.default.GET_BOP_CHECKOUT_SUCCESS:
      case _actionTypes2.default.GET_BOP_CHECKOUT_FAILURE:
      case _actionTypes2.default.PURCHASE_BOP_SUCCESS:
      case _actionTypes2.default.PURCHASE_BOP_FAILURE:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_BOP_DATA:
        return false;

      default:
        return state;
    }
  }
};
var persistedBopReducerKeys = (0, _without2.default)(Object.keys(bopReducers), 'isBopInProgress');
exports.bopReducers = bopReducers;
exports.persistedBopReducerKeys = persistedBopReducerKeys;