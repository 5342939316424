'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesEditQuoteModal/CollectiblesEditQuoteModal.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _findKey = require('lodash/findKey');

var _findKey2 = _interopRequireDefault(_findKey);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _ModalComponent = require('shared/lib/components/GlobalModals/ModalComponent');

var _ModalComponent2 = _interopRequireDefault(_ModalComponent);

var _ModalSelect = require('shared/lib/components/GlobalModals/ModalSelect');

var _ModalSelect2 = _interopRequireDefault(_ModalSelect);

var _ModalInfo = require('shared/lib/components/GlobalModals/ModalInfo');

var _ModalInfo2 = _interopRequireDefault(_ModalInfo);

var _SurveyManagerNew = require('shared/lib/components/SurveyManagerNew');

var _SurveyManagerNew2 = _interopRequireDefault(_SurveyManagerNew);

var _CollectiblesPolicyStartDate = require('../CollectiblesPolicyStartDate');

var _CollectiblesPolicyStartDate2 = _interopRequireDefault(_CollectiblesPolicyStartDate);

var _CollectiblesAddress = require('../CollectiblesAddress');

var _CollectiblesAddress2 = _interopRequireDefault(_CollectiblesAddress);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  categoryCode: _propTypes2.default.string,
  collectiblesEditQuoteModalContext: _propTypes2.default.shape({
    type: _propTypes2.default.string.isRequired
  }).isRequired,
  collectiblesPreEditContext: _propTypes2.default.object,
  currentResponse: _propTypes2.default.object,
  dismissEditCollectiblesQuoteModal: _propTypes2.default.func,
  jumpToCollectiblesSurveyScreenId: _propTypes2.default.func,
  location: _propTypes2.default.object,
  screenId: _propTypes2.default.string,
  screens: _propTypes2.default.object,
  screenSequence: _propTypes2.default.object
};
var defaultProps = {
  currentResponse: {},
  dismissEditCollectiblesQuoteModal: function dismissEditCollectiblesQuoteModal() {},
  jumpToCollectiblesSurveyScreenId: function jumpToCollectiblesSurveyScreenId() {},
  location: {},
  screenId: '',
  screens: {},
  screenSequence: {}
};
var BTN_TEXT_APPLY_CHANGE = 'Apply change';
var BTN_TEXT_NEXT = 'Next';

var CollectiblesEditQuoteModal = function (_Component) {
  _inherits(CollectiblesEditQuoteModal, _Component);

  function CollectiblesEditQuoteModal(props) {
    var _this2 = this;

    _classCallCheck(this, CollectiblesEditQuoteModal);

    var _this = _possibleConstructorReturn(this, (CollectiblesEditQuoteModal.__proto__ || Object.getPrototypeOf(CollectiblesEditQuoteModal)).call(this, props));

    _this.handleEditOwnershipType = function (e) {
      analytics.track('Collectibles - Edit Policy Ownership Type');
      var ownershipScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesSelectedCategory], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'ownership_type';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(ownershipScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit ownership details',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 205
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditMailingAddress = function (e) {
      analytics.track('Collectibles - Edit Mailing Address');
      var mailingAddressScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesSelectedCategory], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'mailing_address_line1';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(mailingAddressScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit mailing address',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 224
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditStartDate = function (e) {
      analytics.track('Collectibles - Edit Policy Start Date');

      _this.setState({
        editModalContext: {
          modalType: 'component',
          title: 'Edit policy start date',
          body: _react2.default.createElement(_CollectiblesPolicyStartDate2.default, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 236
            },
            __self: _this2
          })
        }
      });
    };

    _this.handleEditLocation = function (collectionId) {
      analytics.track('Collectibles - Edit Collection Location');
      var oldAddress = (0, _find2.default)(_this.props.collectiblesQuote.application.plan_applications[0].collections, {
        reference_id: collectionId
      }).address;

      _this.props.updateCollectiblesAddress({
        streetAddress: oldAddress.line1,
        unit: oldAddress.line2,
        city: oldAddress.city,
        region: oldAddress.state_or_region,
        country: oldAddress.country_code,
        postal: oldAddress.postal
      });

      _this.props.updateCollectiblesCategoryType(_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code);

      _this.setState({
        editModalContext: {
          modalType: 'component',
          title: 'Edit collection location',
          body: _react2.default.createElement(_CollectiblesAddress2.default, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 269
            },
            __self: _this2
          }),
          oldAddress: oldAddress
        }
      });
    };

    _this.handleEditBankVault = function (collectionId) {
      analytics.track('Collectibles - Edit Collection Bank Vault Status');
      var bankVaultScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'has_ul_rate_vault';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(bankVaultScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit bank vault',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 290
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditFireAlarm = function (collectionId) {
      analytics.track('Collectibles - Edit Collection Fire Alarm Status');
      var fireAlarmScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'has_fire_alarm';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(fireAlarmScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit fire alarm',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 311
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditBurglarAlarm = function (collectionId) {
      analytics.track('Collectibles - Edit Collection Burglar Alarm Status');
      var burglarAlarmScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'has_burglar_alarm';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(burglarAlarmScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit burglar alarm',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 334
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditTotalValue = function (collectionId) {
      analytics.track('Collectibles - Edit Collection Total Value');
      var totalValueScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'collection_total_value';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(totalValueScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit total value of collection',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 355
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditHighestValuedItem = function (collectionId) {
      analytics.track('Collectibles - Edit Highest Value Item Amount');
      var highestValueScreenId = (0, _findKey2.default)(_this.props.screens[_this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code], function (screen) {
        return (0, _get3.default)(screen.fields[0], 'key_name') === 'highest_value_item';
      });

      _this.props.jumpToCollectiblesSurveyScreenId(highestValueScreenId).then(function () {
        _this.setState({
          editModalContext: {
            modalType: 'component',
            title: 'Edit highest value item worth',
            body: _react2.default.createElement(_SurveyManagerNew2.default, {
              __source: {
                fileName: _jsxFileName,
                lineNumber: 378
              },
              __self: _this2
            })
          }
        });
      });
    };

    _this.handleEditRemoveCollection = function (collectionId) {
      analytics.track('Collectibles - Remove Collection Request');

      _this.setState({
        editModalContext: {
          modalType: 'info',
          title: 'Remove collection',
          body: 'Are you sure you want to remove this collection? All of the collection information will be lost.',
          btnText: 'Remove collection',
          sureStyle: 'destructive',
          onDismiss: _this.props.saveCollectiblesQuoteEditsAndCloseModal,
          afterConfirm: function afterConfirm() {
            return _this.props.removeCollectionFromQuote(collectionId).then(function () {
              return _this.props.getCollectiblesCheckout();
            }).catch(function (error) {
              if (error.code === 'rating_address_rejected') {
                _this.props.showGlobalModal({
                  id: 'info',
                  title: 'Cannot checkout with current collections',
                  body: "Your policy cannot be completed online due to one or more responses. Don't worry, the process to continue checkout is easy \u2013 simply call or text 1-844-335-5441 and our VIP team will take care of the rest.",
                  btnText: 'Exit',
                  afterDismiss: function afterDismiss() {
                    _this.props.navTo('/collectibles/' + _this.props.categorySlug);

                    _this.props.showCollectiblesNotificationBar({
                      title: 'Your policy could not be completed online due to one or more responses.',
                      body: "Don't worry, the process to continue checkout is easy \u2013 simply call or text 1-844-335-5441 and our VIP team will take care of the rest."
                    });
                  }
                });
              } else {
                _this.props.onError(error);
              }
            });
          }
        }
      });
    };

    _this.getOutstandingAddressDetails = function () {
      _this.setState({
        isAddingAddressDetails: true
      });

      _this.handleEditBurglarAlarm();
    };

    _this.updateQuoteAndGetCheckout = function () {
      var _this$props = _this.props,
          collectiblesEditQuoteModalContext = _this$props.collectiblesEditQuoteModalContext,
          getCollectiblesCheckout = _this$props.getCollectiblesCheckout,
          saveCollectiblesQuoteEditsAndCloseModal = _this$props.saveCollectiblesQuoteEditsAndCloseModal,
          updateCollectiblesPlanDetails = _this$props.updateCollectiblesPlanDetails,
          updateCollectionsInQuote = _this$props.updateCollectionsInQuote;
      var updateQuote = collectiblesEditQuoteModalContext.type === 'policy' ? updateCollectiblesPlanDetails : updateCollectionsInQuote;
      saveCollectiblesQuoteEditsAndCloseModal();
      updateQuote().then(function () {
        return getCollectiblesCheckout();
      }).catch(_this.props.onError);
    };

    _this.handleEditCompletion = function () {
      var _this$props2 = _this.props,
          collectiblesAddressContext = _this$props2.collectiblesAddressContext,
          collectiblesCollectionsByCollectionId = _this$props2.collectiblesCollectionsByCollectionId,
          collectiblesEditQuoteModalContext = _this$props2.collectiblesEditQuoteModalContext,
          propertyAddress = _this$props2.propertyAddress,
          responses = _this$props2.responses,
          updateCollectiblesAddressContext = _this$props2.updateCollectiblesAddressContext,
          updateCollectionDetails = _this$props2.updateCollectionDetails;

      switch (_this.state.editModalContext.title) {
        case 'Edit collection location':
          var oldAddressContext = (0, _cloneDeep2.default)(collectiblesAddressContext);
          var oldAddress = _this.state.editModalContext.oldAddress;
          var groomedOldAddress = {
            streetAddress: oldAddress.line1,
            unit: oldAddress.line2,
            city: oldAddress.city,
            region: oldAddress.state_or_region,
            country: oldAddress.country_code,
            postal: oldAddress.postal
          };
          var isNewAddressPresentInContext = !!(0, _find2.default)(collectiblesAddressContext, {
            address: (0, _omit2.default)(propertyAddress, ['lat', 'lng'])
          });
          var collectionOfCurrentCategoryAtNewAddress = (0, _filter2.default)(collectiblesCollectionsByCollectionId, {
            address: {
              line1: propertyAddress.streetAddress,
              line2: propertyAddress.unit,
              city: propertyAddress.city,
              state_or_region: propertyAddress.region,
              country_code: propertyAddress.country,
              postal: propertyAddress.postal
            },
            category_code: collectiblesCollectionsByCollectionId[collectiblesEditQuoteModalContext.collectionId].category_code
          });
          var doesCollectionOfCurrentCategoryExistAtNewAddress = collectionOfCurrentCategoryAtNewAddress.length ? collectionOfCurrentCategoryAtNewAddress[0].reference_id !== collectiblesEditQuoteModalContext.collectionId : false;

          if (doesCollectionOfCurrentCategoryExistAtNewAddress) {
            _this.props.dismissEditCollectiblesQuoteModal();

            return _this.props.showGlobalModal({
              id: 'info',
              title: 'Unable to add collection to that address',
              body: 'There is already a ' + (0, _find2.default)(_collectiblesCategories2.default, {
                categoryCode: collectiblesCollectionsByCollectionId[collectiblesEditQuoteModalContext.collectionId].category_code
              }).title.replace(/s$/, '').toLowerCase() + ' collection in your quote located at that address. You can only have one of each collection type at each address'
            });
          }

          var addressContextItemToUpdate = (0, _find2.default)(oldAddressContext, {
            address: groomedOldAddress
          });
          addressContextItemToUpdate.address = propertyAddress;

          if (isNewAddressPresentInContext) {
            updateCollectiblesAddressContext(groomedOldAddress, addressContextItemToUpdate);

            _this.updateQuoteAndGetCheckout();
          } else {
            updateCollectiblesAddressContext(groomedOldAddress, addressContextItemToUpdate);

            _this.getOutstandingAddressDetails();
          }

          break;

        case 'Edit bank vault':
          updateCollectionDetails(collectiblesEditQuoteModalContext.collectionId, {
            has_ul_rate_vault: responses.has_ul_rate_vault
          });

          _this.updateQuoteAndGetCheckout();

          break;

        case 'Edit fire alarm':
          updateCollectionDetails(collectiblesEditQuoteModalContext.collectionId, {
            has_fire_alarm: responses.has_fire_alarm
          });

          _this.updateQuoteAndGetCheckout();

          break;

        case 'Edit burglar alarm':
          updateCollectionDetails(collectiblesEditQuoteModalContext.collectionId, {
            has_burglar_alarm: responses.has_burglar_alarm
          });

          if (_this.state.isAddingAddressDetails && !(0, _includes2.default)(['jewelry', 'firearms'], _this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code)) {
            _this.handleEditFireAlarm();
          } else {
            _this.updateQuoteAndGetCheckout();
          }

          break;

        case 'Edit total value of collection':
          updateCollectionDetails(collectiblesEditQuoteModalContext.collectionId, {
            collection_total_value: responses.collection_total_value,
            highest_value_item: responses.highest_value_item
          });

          _this.updateQuoteAndGetCheckout();

          break;

        case 'Edit highest value item worth':
          updateCollectionDetails(collectiblesEditQuoteModalContext.collectionId, {
            highest_value_item: responses.highest_value_item
          });

          _this.updateQuoteAndGetCheckout();

          break;

        default:
          _this.updateQuoteAndGetCheckout();

          break;
      }
    };

    var policyContext = function policyContext() {
      return {
        modalType: 'select',
        title: 'Edit policy details',
        body: [['Edit ownership details', function () {
          return _this.handleEditOwnershipType();
        }], ['Edit mailing address', function () {
          return _this.handleEditMailingAddress();
        }], ['Edit start date', function () {
          return _this.handleEditStartDate();
        }]]
      };
    };

    var collectionContext = function collectionContext() {
      return {
        modalType: 'select',
        title: 'Edit ' + (0, _find2.default)(_collectiblesCategories2.default, {
          categoryCode: _this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code
        }).title.replace(/s$/, '').toLowerCase() + ' collection details',
        body: (0, _compact2.default)([['Edit location', function () {
          return _this.handleEditLocation(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }]].concat(_toConsumableArray(!(0, _includes2.default)(['jewelry', 'firearms'], _this.props.collectiblesCollectionsByCollectionId[_this.props.collectiblesEditQuoteModalContext.collectionId].category_code) && [['Edit bank vault', function () {
          return _this.handleEditBankVault(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }], ['Edit central station fire alarm', function () {
          return _this.handleEditFireAlarm(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }]]), [['Edit central station burglar alarm', function () {
          return _this.handleEditBurglarAlarm(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }], ['Edit total value', function () {
          return _this.handleEditTotalValue(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }], ['Edit highest valued item', function () {
          return _this.handleEditHighestValuedItem(_this.props.collectiblesEditQuoteModalContext.collectionId);
        }], ['Remove collection', _this.props.collectiblesQuote.application.plan_applications[0].collections.length > 1 ? function () {
          return _this.handleEditRemoveCollection(_this.props.collectiblesEditQuoteModalContext.collectionId);
        } : null, _this.props.collectiblesQuote.application.plan_applications[0].collections.length > 1 ? 'destructive' : 'disabled']]))
      };
    };

    _this.state = {
      isAddingAddressDetails: false,
      editModalScreenCounter: 0,
      editModalContext: _this.props.collectiblesEditQuoteModalContext.type === 'policy' ? policyContext() : collectionContext()
    };
    return _this;
  }

  _createClass(CollectiblesEditQuoteModal, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Edit Quote Modal', {
        categoryCode: this.props.categoryCode
      });

      if (this.props.collectiblesPreEditContext) {
        this.props.dismissEditCollectiblesQuoteModal();
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      if (!(0, _includes2.default)(this.props.location.pathname, 'quote')) {
        this.props.dismissEditCollectiblesQuoteModal();
      }

      if (this.props.collectiblesEditQuoteModalContext && this.props.screenId !== prevProps.screenId) {
        this.setState({
          editModalScreenCounter: prevState.editModalScreenCounter + 1
        });
      }

      if (prevProps.collectiblesEditQuoteModalContext && !this.props.collectiblesEditQuoteModalContext) {
        this.setState({
          editModalScreenCounter: 0
        });
      }

      if (this.state.isAddingAddressDetails && this.state.editModalScreenCounter === 2) {
        this.setState({
          isAddingAddressDetails: false
        });
      }
    }
    /*
     * Policy Edits
     * ====================================
     */

    /*
     * Collection Edits
     * ====================================
     */

    /*
     * Edit Completion
     * ====================================
     */

  }, {
    key: 'render',
    value: function render() {
      var editModalContext = this.state.editModalContext;

      if (editModalContext.modalType === 'select') {
        return _react2.default.createElement(_ModalSelect2.default, _extends({}, editModalContext, {
          onDismiss: this.props.dismissEditCollectiblesQuoteModal,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 684
          },
          __self: this
        }));
      } else if (editModalContext.modalType === 'component') {
        return _react2.default.createElement(_ModalComponent2.default, _extends({}, this.props, editModalContext, {
          className: 'CollectiblesEditQuoteModal',
          nextBtnText: this.editQuoteModalPagerBtnText,
          onDismiss: this.props.dismissEditCollectiblesQuoteModal,
          onEditCompletion: this.handleEditCompletion,
          isModalEditComplete: this.editQuoteModalPagerBtnText === BTN_TEXT_APPLY_CHANGE,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 691
          },
          __self: this
        }));
      } else if (editModalContext.modalType === 'info') {
        return _react2.default.createElement(_ModalInfo2.default, _extends({}, editModalContext, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 704
          },
          __self: this
        }));
      }
    }
  }, {
    key: 'editQuoteModalPagerBtnText',
    get: function get() {
      var _props = this.props,
          screenSequence = _props.screenSequence,
          collectiblesPreEditContext = _props.collectiblesPreEditContext,
          collectiblesCollectionsByCollectionId = _props.collectiblesCollectionsByCollectionId,
          collectiblesEditQuoteModalContext = _props.collectiblesEditQuoteModalContext,
          collectiblesSelectedCategory = _props.collectiblesSelectedCategory,
          screens = _props.screens,
          screenId = _props.screenId,
          currentResponse = _props.currentResponse;
      var totalScreensInEdit = screenSequence[collectiblesSelectedCategory].length - (0, _get3.default)((0, _get3.default)(collectiblesPreEditContext, 'oldScreenSequence', {}), collectiblesSelectedCategory, []).length + 1;
      var nextBtnText = void 0;

      switch (this.state.editModalContext.title) {
        case 'Edit ownership details':
          if (currentResponse.ownership_type === 'personal' || screens[collectiblesSelectedCategory][screenId].fields[0].key_name === 'ownership_type' && (0, _isEmpty2.default)(currentResponse) && this.state.editModalScreenCounter === totalScreensInEdit || screens[collectiblesSelectedCategory][screenId].fields[0].key_name !== 'ownership_type' && this.state.editModalScreenCounter === totalScreensInEdit) {
            nextBtnText = BTN_TEXT_APPLY_CHANGE;
          } else {
            nextBtnText = BTN_TEXT_NEXT;
          }

          break;

        case 'Edit bank vault':
          if (currentResponse.has_ul_rate_vault || this.state.editModalScreenCounter === totalScreensInEdit) {
            nextBtnText = BTN_TEXT_APPLY_CHANGE;
          } else {
            nextBtnText = BTN_TEXT_NEXT;
          }

          break;

        case 'Edit total value of collection':
          if (currentResponse.collection_total_value && currentResponse.collection_total_value >= collectiblesCollectionsByCollectionId[collectiblesEditQuoteModalContext.collectionId].highest_value_item || this.state.editModalScreenCounter === 2) {
            nextBtnText = BTN_TEXT_APPLY_CHANGE;
          } else {
            nextBtnText = BTN_TEXT_NEXT;
          }

          break;

        default:
          nextBtnText = BTN_TEXT_APPLY_CHANGE;
          break;
      }

      return nextBtnText;
    }
  }]);

  return CollectiblesEditQuoteModal;
}(_react.Component);

CollectiblesEditQuoteModal.propTypes = propTypes;
CollectiblesEditQuoteModal.defaultProps = defaultProps;
exports.default = CollectiblesEditQuoteModal;