'use strict';

var COLLECTIBLES_THREE_UPS = [// A
{
  title: 'Damages beyond wear-and-tear',
  description: 'If your collection can be seen, it can be touched. When your collection is insured, you can enjoy your valuable items without worrying about repair or replacement costs if they are ruined.',
  image: '/images/threeUp-damages.png'
}, // B
{
  title: 'Property theft',
  description: 'A savvy thief knows that sometimes the highest value item isn’t basic cash. Insurance safeguards your prized possessions in the event someone decides to make off with any part of your collection, even outside the home.',
  image: '/images/threeUp-propertyTheft.png'
}, // C
{
  title: 'Comprehensive coverage beyond the basics',
  description: 'Homeowners insurance often covers a portion of your belongings, but if you’ve been collecting for years, it’s likely not enough or is costing you too much. A specialized collectibles policy guarantees complete coverage at the right rates.',
  image: '/images/threeUp-comprehensive.png'
}, // D
{
  title: 'Damages beyond wear-and-tear',
  description: 'If your collection can be seen, it can be touched. When your collection is insured, you won’t have to worry about damage to your favorite firearms.',
  image: '/images/threeUp-damages.png'
}, // E
{
  title: 'Coverage while outside the home',
  description: 'Do you buy coins, gold, or silver online or at tradeshows and events? Collectibles insurance covers your items even when they’re in transit or outside your home, for total and complete protection.',
  image: '/images/threeUp-coin.png'
}, // F
{
  title: 'Unintentional loss',
  description: 'If you drop a diamond earring into the abyss during a scuba excursion, or misplace a bracelet while you’re frantically packing up your hotel room, you’re covered. Insurance allows you to live life to its fullest without worrying about your jewelry items going missing.',
  image: '/images/threeUp-coin.png'
}, // G
{
  title: 'Property theft',
  description: 'Jewelry is obviously a target for theft: People want to steal nice things. It’s awful, but it happens. Insurance safeguards your jewelry in the event someone decides to make off with your stuff, no matter where it is.',
  image: '/images/threeUp-propertyTheft.png'
}, // H
{
  title: 'Damages beyond wear-and-tear',
  description: 'Sometimes our most precious possessions are the most delicate. A sound insurance policy will protect you for damage to your jewelry beyond the regular wear and tear.',
  image: '/images/threeUp-damages.png'
}, // I
{
  title: 'Damages beyond wear-and-tear',
  description: 'If your art can be seen, it can be touched. When your collection is covered, you can worry less about your clumsy guest stumbling into your newest piece, and more about what time you can get everyone to leave.',
  image: '/images/threeUp-damages.png'
}, // J
{
  title: 'Property theft',
  description: 'A savvy thief knows that sometimes the high value items are on the wall, not in the safe. Insurance safeguards your prized possessions in the event someone decides to make off with some of your collection.',
  image: '/images/threeUp-propertyTheft.png'
}, // K
{
  title: 'Comprehensive coverage beyond the basics',
  description: 'Homeowners insurance often covers a portion of your belongings, but if you’ve been collecting for years, it’s likely not enough. Insuring art separately guarantees complete coverage without the limitations.',
  image: '/images/threeUp-comprehensive.png'
}];
var COLLECTIBLES_CATEGORIES = {
  jewelry: {
    title: 'Jewelry',
    threeUps: [COLLECTIBLES_THREE_UPS[5], COLLECTIBLES_THREE_UPS[6], COLLECTIBLES_THREE_UPS[7]],
    categoryCode: 'jewelry'
  },
  fine_art: {
    title: 'Fine Art',
    threeUps: [COLLECTIBLES_THREE_UPS[8], COLLECTIBLES_THREE_UPS[9], COLLECTIBLES_THREE_UPS[10]],
    categoryCode: 'fine_art'
  },
  coin: {
    title: 'Coins',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[4]],
    categoryCode: 'coins'
  },
  stamp: {
    title: 'Stamps',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'stamps'
  },
  book: {
    title: 'Books',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'books'
  },
  firearm: {
    title: 'Modern Firearms',
    threeUps: [COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2], COLLECTIBLES_THREE_UPS[3]],
    categoryCode: 'firearms'
  },
  antique_firearm: {
    title: 'Antique Firearms',
    threeUps: [COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2], COLLECTIBLES_THREE_UPS[3]],
    categoryCode: 'ant_firearms'
  },
  wine: {
    title: 'Wine/Spirits',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'wine'
  },
  action_figure: {
    title: 'Action Figures',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'action_figures',
    isSubCategory: true
  },
  antique_rug: {
    title: 'Antique and High-End Furniture',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'antique_rugs',
    isSubCategory: true
  },
  automobile_memorabilia: {
    title: 'Automobile Memorabilia',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'automobile_memorabilia',
    isSubCategory: true
  },
  vintage_camera: {
    title: 'Vintage Cameras',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'cameras_vintage',
    isSubCategory: true
  },
  china_tableware: {
    title: 'China Tableware',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'china_tableware',
    isSubCategory: true
  },
  comic_book: {
    title: 'Comic Books',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'comic_books',
    isSubCategory: true
  },
  crystal: {
    title: 'Crystal',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'crystal',
    isSubCategory: true
  },
  doll: {
    title: 'Dolls',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'dolls',
    isSubCategory: true
  },
  figurine: {
    title: 'Figurines',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'figurines',
    isSubCategory: true
  },
  fishing_tackle: {
    title: 'Antique Fishing Tackle',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'fishing_tackle',
    isSubCategory: true
  },
  funko: {
    title: 'Funko Collection',
    preserveCasing: true,
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'funko',
    isSubCategory: true
  },
  fur: {
    title: 'Fur',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'fur',
    isSubCategory: true
  },
  glass: {
    title: 'Glass',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'glass',
    isSubCategory: true
  },
  model_car: {
    title: 'Model Cars',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'model_cars',
    isSubCategory: true
  },
  model_train: {
    title: 'Model Trains',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'model_trains',
    isSubCategory: true
  },
  movie_memorabilia: {
    title: 'Movie Memorabilia',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'movie_memorabilia',
    isSubCategory: true
  },
  musical_instrument: {
    title: 'Musical Instruments',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'musical_instruments',
    isSubCategory: true
  },
  music_memorabilia: {
    title: 'Music Memorabilia',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'music_memorabilia',
    isSubCategory: true
  },
  ornament: {
    title: 'Ornaments',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'ornaments',
    isSubCategory: true
  },
  pottery: {
    title: 'Pottery',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'pottery',
    isSubCategory: true
  },
  rock: {
    title: 'Rocks and Minerals',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'rocks',
    isSubCategory: true
  },
  silver_gold_pewter_ware: {
    title: 'Silverware, Gold Ware, and Pewter Ware',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'silver_gold_pewter_ware',
    isSubCategory: true
  },
  sports_memorabilia: {
    title: 'Sports Memorabilia',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'sports_memorabilia',
    isSubCategory: true
  },
  toy: {
    title: 'Toy',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'toy',
    isSubCategory: true
  },
  trading_card: {
    title: 'Trading Cards',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'trading_cards',
    isSubCategory: true
  },
  teddy_bear: {
    title: 'Teddy Bears',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'teddy_bears',
    isSubCategory: true
  },
  militaria: {
    title: 'Militaria',
    secondaryTitle: '(including police)',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'militaria',
    isSubCategory: true
  },
  vinyl: {
    title: 'Vinyl',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'vinyl'
  },
  other: {
    title: 'Collectibles',
    threeUps: [COLLECTIBLES_THREE_UPS[0], COLLECTIBLES_THREE_UPS[1], COLLECTIBLES_THREE_UPS[2]],
    categoryCode: 'other',
    isSubCategory: true
  }
};
module.exports = COLLECTIBLES_CATEGORIES;