import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './BooleanTable.css';

const propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  values: PropTypes.object,
  onChange: PropTypes.func,
};

const defaultProps = {
  rows: [],
  values: {},
  onChange: () => {},
};

class BooleanTable extends Component {
  handleChange = e => {
    const key = e.currentTarget.dataset.key;
    const value = JSON.parse(e.currentTarget.dataset.value);
    this.props.onChange({
      [key]: value,
    });
  };

  render() {
    const { rows, values } = this.props;
    return (
      <div className="BooleanTable">
        <div className="BooleanTable-header">
          <div className="BooleanTable-header-spacer" />
          <div className="BooleanTable-header-heading">Yes</div>
          <div className="BooleanTable-header-heading">No</div>
        </div>
        <div className="BooleanTable-body">
          {rows.map(({ key, title }) => (
            <div key={key} className="BooleanTable-body-question">
              <div className="BooleanTable-body-question-title">{title}</div>
              <input
                className="BooleanTable-body-question-input"
                type="radio"
                name={key}
                checked={values[key] || false}
              />
              <div
                className={classnames('BooleanTable-body-question-radio', {
                  'is-checked': values[key] === true,
                })}
                data-key={key}
                data-value="true"
                onClick={this.handleChange}
              >
                <i className="far fa-circle BooleanTable-body-question-radio-unchecked" />
                <i className="far fa-dot-circle BooleanTable-body-question-radio-checked" />
              </div>
              <div
                className={classnames('BooleanTable-body-question-radio', {
                  'is-checked': values[key] === false,
                })}
                data-key={key}
                data-value="false"
                onClick={this.handleChange}
              >
                <i className="far fa-circle BooleanTable-body-question-radio-unchecked" />
                <i className="far fa-dot-circle BooleanTable-body-question-radio-checked" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

BooleanTable.propTypes = propTypes;
BooleanTable.defaultProps = defaultProps;

export default BooleanTable;
