'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _utils = require('shared/lib/utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (api) {
  /*
   * Pick up date
   * ========================================
   */
  function updateRentalCarPickUpDate(momentObj) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_PICK_UP_DATE,
      payload: {
        dateStr: momentObj && momentObj.toISOString() || null
      }
    };
  }
  /*
   * Return date
   * ========================================
   */


  function updateRentalCarReturnDate(momentObj) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_RETURN_DATE,
      payload: {
        dateStr: momentObj && momentObj.toISOString() || null
      }
    };
  }
  /*
   * Agency name
   * ========================================
   */


  function updateRentalCarAgency(agencyName) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_AGENCY,
      payload: {
        agencyName: agencyName
      }
    };
  }
  /*
   * Has signed agreement
   * ========================================
   */


  function updateRentalCarHasSignedAgreement(hasSignedAgreement) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_HAS_SIGNED_RENTAL_AGREEMENT,
      payload: {
        hasSignedAgreement: hasSignedAgreement
      }
    };
  }
  /*
   * Is for personal use
   * ========================================
   */


  function updateIsForPersonalUse(isForPersonalUse) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_IS_FOR_PERSONAL_USE,
      payload: {
        isForPersonalUse: isForPersonalUse
      }
    };
  }
  /*
   * Get plans
   * ========================================
   */


  function getRentalCarPlansRequest() {
    return {
      type: _actionTypes2.default.GET_RENTAL_CAR_PLANS
    };
  }

  function getRentalCarPlansSuccess(data) {
    return {
      type: _actionTypes2.default.GET_RENTAL_CAR_PLANS_SUCCESS,
      payload: data
    };
  }

  function getRentalCarPlansFailure(error) {
    return {
      type: _actionTypes2.default.GET_RENTAL_CAR_PLANS_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function getRentalCarPlans() {
    return function (dispatch, getState) {
      var _getState = getState(),
          authToken = _getState.authToken,
          rentalCarDriver = _getState.rentalCarDriver;

      dispatch(getRentalCarPlansRequest());
      return new Promise(function (resolve, reject) {
        api.getRentalCarPlans({
          streetAddress: rentalCarDriver.streetAddress,
          unit: rentalCarDriver.unit,
          city: rentalCarDriver.city,
          region: rentalCarDriver.region,
          postal: rentalCarDriver.postal,
          lat: rentalCarDriver.lat,
          lng: rentalCarDriver.lng,
          country: rentalCarDriver.country,
          authToken: authToken
        }).then(function (plans) {
          dispatch(getRentalCarPlansSuccess(plans));
          resolve(plans);
        }).catch(function (error) {
          dispatch(getRentalCarPlansFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Driver info
   * ========================================
   */


  function updateRentalCarDriver(driverInfo) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_DRIVER,
      payload: {
        driverInfo: driverInfo
      }
    };
  }
  /*
   * Has additional drivers
   * ========================================
   */


  function updateRentalCarHasAdditionalDrivers(hasAdditionalDrivers) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_HAS_ADDITIONAL_DRIVERS,
      payload: {
        hasAdditionalDrivers: hasAdditionalDrivers
      }
    };
  }
  /*
   * Additional drivers
   * ========================================
   */


  function addRentalCarAdditionalDriver() {
    return {
      type: _actionTypes2.default.ADD_RENTAL_CAR_ADDITIONAL_DRIVER
    };
  }

  function updateRentalCarAdditionalDrivers(additionalDrivers) {
    return {
      type: _actionTypes2.default.UPDATE_RENTAL_CAR_ADDITIONAL_DRIVERS,
      payload: {
        additionalDrivers: additionalDrivers
      }
    };
  }

  function removeRentalCarEmptyAdditionalDrivers() {
    return {
      type: _actionTypes2.default.REMOVE_RENTAL_CAR_EMPTY_ADDITIONAL_DRIVER
    };
  }
  /*
   * Select plan
   * ========================================
   */


  function selectRentalCarPlanRequest(planId) {
    return {
      type: _actionTypes2.default.SELECT_RENTAL_CAR_PLAN,
      payload: {
        planId: planId
      }
    };
  }

  function selectRentalCarPlanSuccess(data) {
    return {
      type: _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_SUCCESS,
      payload: data
    };
  }

  function selectRentalCarPlanFailure(error) {
    return {
      type: _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function selectRentalCarPlan(planId) {
    return function (dispatch, getState) {
      var _getState2 = getState(),
          rentalCarPickUpDate = _getState2.rentalCarPickUpDate,
          rentalCarReturnDate = _getState2.rentalCarReturnDate,
          rentalCarAgency = _getState2.rentalCarAgency,
          rentalCarDriver = _getState2.rentalCarDriver,
          rentalCarHasAdditionalDrivers = _getState2.rentalCarHasAdditionalDrivers,
          rentalCarAdditionalDrivers = _getState2.rentalCarAdditionalDrivers,
          authToken = _getState2.authToken;

      dispatch(selectRentalCarPlanRequest(planId));
      var addressPromise = new Promise(function (resolve, reject) {
        var addressProps = {
          address: {
            line1: rentalCarDriver.streetAddress,
            line2: rentalCarDriver.unit,
            city: rentalCarDriver.city,
            state_or_region: rentalCarDriver.region,
            country: rentalCarDriver.country,
            postal: rentalCarDriver.postal,
            lat: rentalCarDriver.lat,
            lng: rentalCarDriver.lng
          }
        };
        dispatch(updateRentalCarDriver(addressProps));
        resolve(addressProps);
      });
      return new Promise(function (resolve, reject) {
        addressPromise.then(function (addressProps) {
          var drivers = [Object.assign({}, (0, _pick2.default)(rentalCarDriver, ['firstName', 'lastName', 'email', 'phone']), {
            isMainDriver: true
          }, addressProps)];
          var additionalDrivers = (0, _filter2.default)(rentalCarAdditionalDrivers, function (additionalDriver) {
            return additionalDriver.firstName && additionalDriver.lastName;
          });
          additionalDrivers.forEach(function (driver) {
            return driver.isMainDriver = false;
          });

          if (rentalCarHasAdditionalDrivers) {
            drivers = drivers.concat(additionalDrivers);
          }

          var opts = {
            streetAddress: rentalCarDriver.streetAddress,
            unit: rentalCarDriver.unit,
            city: rentalCarDriver.city,
            region: rentalCarDriver.region,
            postal: rentalCarDriver.postal,
            country: rentalCarDriver.country,
            lat: rentalCarDriver.lat,
            lng: rentalCarDriver.lng,
            rentalCompanyName: rentalCarAgency,
            rentalStarted: (0, _momentTimezone2.default)(rentalCarPickUpDate).format('YYYY-MM-DD'),
            rentalEnded: (0, _momentTimezone2.default)(rentalCarReturnDate).format('YYYY-MM-DD'),
            phone: rentalCarDriver.phoneNumber,
            email: rentalCarDriver.email,
            drivers: drivers,
            planId: planId,
            authToken: authToken
          };
          return api.getRentalCarCheckoutInfo(opts);
        }).then(function (checkoutInfo) {
          dispatch(selectRentalCarPlanSuccess(checkoutInfo));
          resolve(checkoutInfo);
        }).catch(function (error) {
          dispatch(selectRentalCarPlanFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Purchase
   * ========================================
   */


  function startRentalCarPurchase() {
    return {
      type: _actionTypes2.default.PURCHASE_RENTAL_CAR
    };
  }

  function purchaseRentalCarSuccess(data) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS
    });
    return {
      type: _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS,
      payload: data
    };
  }

  function purchaseRentalCarError(error) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_RENTAL_CAR_FAILURE
    });
    return {
      type: _actionTypes2.default.PURCHASE_RENTAL_CAR_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function purchaseRentalCar(cardElement) {
    return function (dispatch, getState) {
      var _getState3 = getState(),
          account = _getState3.account,
          isRentalCarInProgress = _getState3.isRentalCarInProgress,
          rentalCarPickUpDate = _getState3.rentalCarPickUpDate,
          rentalCarReturnDate = _getState3.rentalCarReturnDate,
          rentalCarAgency = _getState3.rentalCarAgency,
          rentalCarDriver = _getState3.rentalCarDriver,
          rentalCarHasAdditionalDrivers = _getState3.rentalCarHasAdditionalDrivers,
          rentalCarAdditionalDrivers = _getState3.rentalCarAdditionalDrivers,
          rentalCarSelectedPlanId = _getState3.rentalCarSelectedPlanId,
          rentalCarPlans = _getState3.rentalCarPlans,
          rentalCarCheckoutInfo = _getState3.rentalCarCheckoutInfo,
          rentalCarUID = _getState3.rentalCarUID,
          authToken = _getState3.authToken,
          metadata = _getState3.metadata;

      if (isRentalCarInProgress) {
        console.warn('Purchase already in progress');
        return Promise.resolve();
      } else {
        dispatch(startRentalCarPurchase());
        var revenue = rentalCarCheckoutInfo.totals.grandtotal;
        var selectedPlan = (0, _find2.default)(rentalCarPlans, {
          plan_id: rentalCarSelectedPlanId
        });
        var drivers = [Object.assign({}, (0, _pick2.default)(rentalCarDriver, ['address', 'addressId', 'firstName', 'lastName', 'email', 'phone']), {
          isMainDriver: true
        })];
        var additionalDrivers = (0, _filter2.default)(rentalCarAdditionalDrivers, function (additionalDriver) {
          return additionalDriver.firstName && additionalDriver.lastName;
        });
        additionalDrivers.forEach(function (driver) {
          return driver.isMainDriver = false;
        });

        if (rentalCarHasAdditionalDrivers) {
          drivers = drivers.concat(additionalDrivers);
        }

        var opts = {
          streetAddress: rentalCarDriver.streetAddress,
          unit: rentalCarDriver.unit,
          city: rentalCarDriver.city,
          region: rentalCarDriver.region,
          postal: rentalCarDriver.postal,
          country: rentalCarDriver.country,
          lat: rentalCarDriver.lat,
          lng: rentalCarDriver.lng,
          rentalCompanyName: rentalCarAgency,
          rentalStarted: (0, _momentTimezone2.default)(rentalCarPickUpDate).format('YYYY-MM-DD'),
          rentalEnded: (0, _momentTimezone2.default)(rentalCarReturnDate).format('YYYY-MM-DD'),
          planId: rentalCarSelectedPlanId,
          planTitle: (0, _get2.default)(selectedPlan, 'information.title'),
          phone: rentalCarDriver.phoneNumber,
          firstName: rentalCarDriver.firstName,
          lastName: rentalCarDriver.lastName,
          email: rentalCarDriver.email || account.email,
          uid: rentalCarUID,
          drivers: drivers,
          authToken: authToken,
          revenue: revenue,
          cardElement: cardElement,
          metadata: metadata
        };
        return new Promise(function (resolve, reject) {
          api.purchaseRentalCar(opts).then(function (purchaseData) {
            var hydratedPurchaseData = (0, _merge2.default)({}, purchaseData, {
              details: {
                additionalDrivers: rentalCarAdditionalDrivers,
                driverInfo: rentalCarDriver,
                pickUpDate: (0, _momentTimezone2.default)(rentalCarPickUpDate).format('MMM D, YYYY'),
                returnDate: (0, _momentTimezone2.default)(rentalCarReturnDate).format('MMM D, YYYY'),
                rentalAgency: rentalCarAgency,
                totals: (0, _get2.default)(rentalCarCheckoutInfo, 'totals'),
                plan: selectedPlan
              }
            });
            dispatch(purchaseRentalCarSuccess(hydratedPurchaseData));
            resolve(hydratedPurchaseData);
          }).catch(function (err) {
            dispatch(purchaseRentalCarError(err.response.body.error));
            reject(err.response.body.error);
          });
        });
      }
    };
  } // ----------------------------------------
  // Exports
  // ----------------------------------------


  return {
    getRentalCarPlans: getRentalCarPlans,
    updateRentalCarPickUpDate: updateRentalCarPickUpDate,
    updateRentalCarReturnDate: updateRentalCarReturnDate,
    updateRentalCarAgency: updateRentalCarAgency,
    updateRentalCarHasSignedAgreement: updateRentalCarHasSignedAgreement,
    updateIsForPersonalUse: updateIsForPersonalUse,
    updateRentalCarDriver: updateRentalCarDriver,
    updateRentalCarHasAdditionalDrivers: updateRentalCarHasAdditionalDrivers,
    updateRentalCarAdditionalDrivers: updateRentalCarAdditionalDrivers,
    addRentalCarAdditionalDriver: addRentalCarAdditionalDriver,
    removeRentalCarEmptyAdditionalDrivers: removeRentalCarEmptyAdditionalDrivers,
    selectRentalCarPlan: selectRentalCarPlan,
    purchaseRentalCar: purchaseRentalCar
  };
};