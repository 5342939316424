import zipObject from 'lodash/zipObject';

const ACTIONS = [
  // Global

  'SHOW_GLOBAL_MODAL',
  'HIDE_GLOBAL_MODAL',
  'SHOW_MENU',
  'HIDE_MENU',

  'GET_BENEFICIARY_RELATIONSHIPS',
  'GET_BENEFICIARY_RELATIONSHIPS_SUCCESS',
  'GET_BENEFICIARY_RELATIONSHIPS_FAILURE',

  'UPDATE_PARTNER_INFO',

  // CarSafe

  'GET_CARSAFE_PLANS',
  'GET_CARSAFE_PLANS_SUCCESS',
  'GET_CARSAFE_PLANS_FAILURE',

  'UPDATE_CARSAFE_DETAILS',
  'SELECT_CARSAFE_PLAN',

  'GET_CARSAFE_CHECKOUT',
  'GET_CARSAFE_CHECKOUT_SUCCESS',
  'GET_CARSAFE_CHECKOUT_FAILURE',

  'PURCHASE_CARSAFE',
  'PURCHASE_CARSAFE_SUCCESS',
  'PURCHASE_CARSAFE_FAILURE',

  // HomeSafe

  'CREATE_HOMESAFE_QUOTE',
  'CREATE_HOMESAFE_QUOTE_SUCCESS',
  'CREATE_HOMESAFE_QUOTE_FAILURE',

  'UPDATE_HOMESAFE_QUOTE',
  'UPDATE_HOMESAFE_QUOTE_SUCCESS',
  'UPDATE_HOMESAFE_QUOTE_FAILURE',

  'GET_HOMESAFE_PLANS',
  'GET_HOMESAFE_PLANS_SUCCESS',
  'GET_HOMESAFE_PLANS_FAILURE',

  'UPDATE_HOMESAFE_DETAILS',
  'SELECT_HOMESAFE_PLAN',

  'GET_HOMESAFE_CHECKOUT',
  'GET_HOMESAFE_CHECKOUT_SUCCESS',
  'GET_HOMESAFE_CHECKOUT_FAILURE',

  'ADD_HOMESAFE_PAYMENT_METHOD',
  'ADD_HOMESAFE_PAYMENT_METHOD_SUCCESS',
  'ADD_HOMESAFE_PAYMENT_METHOD_FAILURE',

  'PURCHASE_HOMESAFE',
  'PURCHASE_HOMESAFE_SUCCESS',
  'PURCHASE_HOMESAFE_FAILURE',
];

const ACTION_TYPES = zipObject(ACTIONS, ACTIONS);

export default ACTION_TYPES;
