'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/BopApp/views/BopConfirmationView/BopConfirmationView.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _isBoolean = require('lodash/isBoolean');

var _isBoolean2 = _interopRequireDefault(_isBoolean);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _ConfirmationLayout = require('shared/lib/components/ConfirmationLayout');

var _ConfirmationLayout2 = _interopRequireDefault(_ConfirmationLayout);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _bop_details = require('../../bop_details.json');

var _bop_details2 = _interopRequireDefault(_bop_details);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.shape({
    phoneNumber: _propTypes2.default.string
  }),
  disclaimer: _propTypes2.default.string,
  selectedPlan: _propTypes2.default.object,
  checkoutInfo: _propTypes2.default.shape({
    currency_code: _propTypes2.default.string,
    items: _propTypes2.default.array,
    payment_frequency: _propTypes2.default.string,
    tax_percentage: _propTypes2.default.number,
    totals: _propTypes2.default.shape({
      subtotal: _propTypes2.default.number,
      grandtotal: _propTypes2.default.number
    })
  }).isRequired,
  purchaseInfo: _propTypes2.default.object
};
var defaultProps = {
  account: {},
  disclaimer: null
};

var BopConfirmationView = function (_Component) {
  _inherits(BopConfirmationView, _Component);

  function BopConfirmationView() {
    _classCallCheck(this, BopConfirmationView);

    return _possibleConstructorReturn(this, (BopConfirmationView.__proto__ || Object.getPrototypeOf(BopConfirmationView)).apply(this, arguments));
  }

  _createClass(BopConfirmationView, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('BOP - Confirmation');
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 3
      });
    }
  }, {
    key: 'detailsFor',
    value: function detailsFor(detailName) {
      var planDetails = _bop_details2.default[this.planCode][detailName];

      if ((0, _isBoolean2.default)(planDetails.title)) {
        if (planDetails.title) {
          return 'Included';
        }
      } else {
        return planDetails.title;
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'BopConfirmationView Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 121
        },
        __self: this
      }, _react2.default.createElement(_ConfirmationLayout2.default, {
        policyNumber: this.props.purchaseInfo.policy_number,
        icon: 'store_mall_directory',
        totals: this.props.checkoutInfo.totals,
        phoneNumber: this.props.account.phoneNumber,
        billingCycle: 'year',
        policyStartDate: this.props.policyStartDate,
        disclaimer: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 122
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Business details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 131
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Type', this.businessType], ['Business name', this.props.details.business_name], ['Primary contact', [this.props.details.pni_first_name, this.props.details.pni_last_name].join(' ')], ['Address', this.businessAddress], ['Entity', this.businessEntityName]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 132
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Coverage details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 143
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: this.coverageDetailsRows,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 144
        },
        __self: this
      }))));
    }
  }, {
    key: 'coverageDetailsRows',
    get: function get() {
      var rows = [['Underwritten By', 'Chubb'], ['Plan', (0, _get3.default)(this.props.selectedPlan, 'information.title')], ['Coverage term', _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 65
        },
        __self: this
      }, 'From ' + this.props.policyStartDate.format('MMM DD, YYYY'), _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 67
        },
        __self: this
      }), 'to ' + (0, _momentTimezone2.default)(this.props.policyStartDate).add(1, 'year').format('MMM DD, YYYY'))], ['General liability', this.detailsFor('general_liability')], ['Business personal property', this.detailsFor('business_personal_property')], ['Additional insured', this.detailsFor('additional_insured_coverage')], ['Lost business income', this.detailsFor('lost_business_income')], ['Employee liability coverage', this.detailsFor('employee_liability_coverage')], ['Hired and non-owned auto', this.detailsFor('hired_and_non_owned_auto')]];
      return (0, _filter2.default)(rows, function (row) {
        return !!row[1];
      });
    }
  }, {
    key: 'businessType',
    get: function get() {
      var businessClassCode = this.props.details.business_class_code;
      var businessClass = (0, _find2.default)(this.props.businessClasses, {
        code: businessClassCode
      });
      return (0, _get3.default)(businessClass, 'name');
    }
  }, {
    key: 'businessAddress',
    get: function get() {
      return _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 92
        },
        __self: this
      }, this.props.ratingAddress.streetAddress, _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 94
        },
        __self: this
      }), this.props.ratingAddress.unit && [this.props.ratingAddress.unit, _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      })], this.props.ratingAddress.city, ' ', this.props.ratingAddress.region, ', ', this.props.ratingAddress.postal);
    }
  }, {
    key: 'businessEntityName',
    get: function get() {
      var screen = (0, _find2.default)(this.props.screens, function (screen) {
        return (0, _find2.default)(screen.fields, {
          key_name: 'business_legal_nature'
        });
      });
      var field = (0, _find2.default)(screen.fields, {
        key_name: 'business_legal_nature'
      });
      var choice = (0, _find2.default)(field.settings.choices, {
        value: this.props.details.business_legal_nature
      });
      return (0, _get3.default)(choice, 'title');
    }
  }, {
    key: 'planCode',
    get: function get() {
      return (0, _get3.default)(this.props.selectedPlan, 'details.chubb_plan_code');
    }
  }]);

  return BopConfirmationView;
}(_react.Component);

BopConfirmationView.propTypes = propTypes;
BopConfirmationView.defaultProps = defaultProps;
exports.default = BopConfirmationView;