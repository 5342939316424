import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import { Link } from 'react-router';
import Button from 'shared/lib/components/Button';
import Modal from 'shared/lib/components/Modal';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import classnames from 'classnames';
import COUNTRIES from '../../constants/countries';
import heroImage from './images/landing-homesafe.png';
import iconDocImage from './images/icon-doc.png';
import iconHomeImage from './images/icon-home.png';
import './LandingHomeSafe.css';

export default class LandingHomeSafe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'ZA',
      isErrorShown: false,
      isRejectModalOpen: false,
    };
  }

  componentDidMount() {
    analytics.page('HomeSafe - Landing');
  }

  handleUpdateSelect = e => {
    this.setState({
      selectedCountry: e.target.value,
    });
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    if (!this.state.selectedCountry) {
      this.setState({
        isErrorShown: true,
      });
      window.scrollTo(0, 0);
    } else if (this.state.selectedCountry === 'ZA') {
      this.props.navTo('/residential-insurance/intro-survey');
    } else {
      this.setState({
        isErrorShown: false,
        isRejectModalOpen: true,
      });
    }
  };

  hideRejectModal = () => {
    this.setState({
      isRejectModalOpen: false,
    });
  };

  render() {
    return (
      <div className="LandingHomeSafe ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#6e27c5" bgImage={heroImage}>
          <h1 className="LandingHomeSafe-heroTitle">
            Protect your personal valuables against theft from your home.
          </h1>
          <form
            className="LandingHomeSafe-heroForm"
            onSubmit={this.handleSubmit}
          >
            <div className="LandingHomeSafe-heroForm-select">
              <select
                className={classnames('LandingHomeSafe-heroForm-select-input', {
                  'has-error': this.state.isErrorShown,
                })}
                onChange={this.handleUpdateSelect}
              >
                {COUNTRIES.map(country => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
              <i className="LandingHomeSafe-heroForm-select-icon fas fa-chevron-down" />
            </div>
            <button className="LandingHomeSafe-heroForm-submit" type="submit">
              View Plans
            </button>
          </form>
          {this.state.isErrorShown && (
            <p className="LandingHomeSafe-errorMsg">
              Please select your country
            </p>
          )}
        </HeroChubb>
        <div className="LandingHomeSafe-content">
          <div className="Container">
            <h2 className="LandingHomeSafe-content-title">
              <img
                className="LandingHomeSafe-content-title-icon"
                src={iconHomeImage}
                alt="Home icon"
              />
              Residential Insurance
            </h2>
            <p className="LandingHomeSafe-content-info">
              Our Residential Insurance provides you with cover in the event of
              theft or accidental death and disability as a result of a home
              burglary.
            </p>
            <h3 className="LandingHomeSafe-content-productTitle">
              Product information
            </h3>
            <div className="LandingHomeSafe-threeUp">
              <div className="LandingHomeSafe-threeUp-item">
                <div className="LandingHomeSafe-threeUp-item-content">
                  <h4 className="LandingHomeSafe-threeUp-item-content-title">
                    Coverage Highlights
                  </h4>
                  <ul className="LandingHomeSafe-threeUp-item-content-list">
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      Cover for the theft of your wallet or handbag up to R7500
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      Cover for the theft of your personal electronic items up
                      to R15000
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      Home lock and key replacement in the event these items are
                      stolen up to R7500
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      Identity theft cover for the theft of your cards and
                      important documents up to R2000
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      Accidental death and disability cover up to R250,00
                    </li>
                  </ul>
                  <button
                    className="LandingHomeSafe-threeUp-item-content-cta"
                    onClick={this.handleSubmit}
                  >
                    Get a Quote
                  </button>
                </div>
              </div>
              <div className="LandingHomeSafe-threeUp-item">
                <div className="LandingHomeSafe-threeUp-item-content">
                  <h4 className="LandingHomeSafe-threeUp-item-content-title">
                    FAQs
                  </h4>
                  <ul className="LandingHomeSafe-threeUp-item-content-list">
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      How do I calculate the value of my household contents?
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      I stay in a rented flat/cottage. Can I still get house
                      contents insurance even if I don't own the property?
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      In the event of a claim, what happens if I undervalued my
                      household contents?
                    </li>
                    <li className="LandingHomeSafe-threeUp-item-content-listItem">
                      In the event of a claim, what happens, if I over insured
                      my household contents?
                    </li>
                  </ul>
                  <Link
                    className="LandingHomeSafe-threeUp-item-content-cta"
                    to="/residential-insurance/faq"
                  >
                    View All
                  </Link>
                </div>
              </div>
              <div className="LandingHomeSafe-threeUp-item LandingHomeSafe-threeUp-item--policyDocs">
                <div className="LandingHomeSafe-threeUp-item-content">
                  <h4 className="LandingHomeSafe-threeUp-item-content-title">
                    Policy Documents
                  </h4>
                  <p className="LandingHomeSafe-threeUp-item-content-info">
                    Residential Insurance policy documents can be downloaded
                    below in PDF format.
                  </p>
                  <a
                    className="LandingHomeSafe-threeUp-item-content-link"
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/homesafe_assets/Residential+Policy+Wording.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="LandingHomeSafe-threeUp-item-content-link-icon"
                      src={iconDocImage}
                      alt="PDF icon"
                    />
                    <span>Policy Wording</span>
                    <i className="fas fa-caret-right" />
                  </a>
                  <a
                    className="LandingHomeSafe-threeUp-item-content-link"
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/homesafe_assets/Residential+Personal+Lines+Insurance+IPLKID.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="LandingHomeSafe-threeUp-item-content-link-icon"
                      src={iconDocImage}
                      alt="PDF icon"
                    />
                    <span>Key Information Document</span>
                    <i className="fas fa-caret-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="LandingHomeSafe-claimsCallout">
              <h4 className="LandingHomeSafe-claimsCallout-heading">
                Submit a claim
              </h4>
              <Link
                className="LandingHomeSafe-claimsCallout-cta"
                to="/chubb/claims"
              >
                Start a Claim
              </Link>
            </div>
          </div>
        </div>
        <FooterChubb />
        <Modal
          className="LandingHomeSafe-rejectModal"
          title="Error"
          isActive={this.state.isRejectModalOpen}
          onDismiss={this.hideRejectModal}
        >
          <p>
            Looks like you live outside of South Africa. Unfortunately we do not
            have any plans available for you at the moment.
          </p>
          <Button
            onClick={this.hideRejectModal}
            style={{
              backgroundColor: 'rgb(86,191,211)',
            }}
          >
            Go back to Chubb
          </Button>
        </Modal>
      </div>
    );
  }
}
