'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PaymentSection/PaymentSection.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CreditCardField = require('../CreditCardField');

var _CreditCardField2 = _interopRequireDefault(_CreditCardField);

var _IconCircle = require('../IconCircle');

var _IconCircle2 = _interopRequireDefault(_IconCircle);

var _SectionWrapper = require('../SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

require('./PaymentSection.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  extraCCFields: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  onChange: _propTypes2.default.func
};
var defaultProps = {
  extraCCFields: false,
  disabled: false,
  onChange: function onChange() {}
};

var PaymentSection = function (_PureComponent) {
  _inherits(PaymentSection, _PureComponent);

  function PaymentSection() {
    _classCallCheck(this, PaymentSection);

    return _possibleConstructorReturn(this, (PaymentSection.__proto__ || Object.getPrototypeOf(PaymentSection)).apply(this, arguments));
  }

  _createClass(PaymentSection, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          extraCCFields = _props.extraCCFields,
          disabled = _props.disabled,
          onChange = _props.onChange;
      return _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Payment method',
        className: 'PaymentSection',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 29
        },
        __self: this
      }, _react2.default.createElement(_CreditCardField2.default, {
        ref: function ref(c) {
          return _this2.creditCardField = c;
        },
        extraCCFields: extraCCFields,
        disabled: disabled,
        onChange: onChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 30
        },
        __self: this
      }), _react2.default.createElement(_IconCircle2.default, {
        icon: 'lock_outline',
        size: 40,
        color: 'medium',
        background: 'gray',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: this
      }), _react2.default.createElement('p', {
        className: 'PaymentSection-securityNotice',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 42
        },
        __self: this
      }, 'All information is transmitted over a secure connection. All transactions are protected with bank-grade security.'));
    }
  }, {
    key: 'elements',
    get: function get() {
      return this.creditCardField.elements;
    }
  }]);

  return PaymentSection;
}(_react.PureComponent);

PaymentSection.propTypes = propTypes;
PaymentSection.defaultProps = defaultProps;
exports.default = PaymentSection;