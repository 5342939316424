'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/BopApp/views/BopBasicInfoView/BopBasicInfoView.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _debounce = require('lodash/debounce');

var _debounce2 = _interopRequireDefault(_debounce);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _sureComponents = require('sure-components');

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AddressForm = require('shared/lib/components/AddressForm');

var _AddressForm2 = _interopRequireDefault(_AddressForm);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _utils = require('shared/lib/utils');

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./BopBasicInfoView.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  ratingAddress: _propTypes2.default.shape({
    streetAddress: _propTypes2.default.string,
    unit: _propTypes2.default.string,
    city: _propTypes2.default.string,
    region: _propTypes2.default.string,
    postal: _propTypes2.default.string,
    country: _propTypes2.default.string
  }),
  details: _propTypes2.default.object,
  businessClasses: _propTypes2.default.array,
  businessClassCode: _propTypes2.default.number,
  disclaimer: _propTypes2.default.string,
  onUpdateRatingAddress: _propTypes2.default.func,
  onUpdateDetails: _propTypes2.default.func,
  onGetPlans: _propTypes2.default.func,
  getBusinessClasses: _propTypes2.default.func,
  isInProgress: _propTypes2.default.boolean,
  navTo: _propTypes2.default.func
};
var defaultProps = {
  ratingAddress: {},
  details: {},
  disclaimer: null,
  businessClasses: [],
  businessClassCode: null,
  isInProgress: false
};

var BopBasicInfoView = function (_PureComponent) {
  _inherits(BopBasicInfoView, _PureComponent);

  function BopBasicInfoView() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BopBasicInfoView);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BopBasicInfoView.__proto__ || Object.getPrototypeOf(BopBasicInfoView)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      if (_this.isNextDisabled) {
        return Promise.resolve();
      } else {
        analytics.track('BOP - Address Submitted');
        return _this.residenceAddressComponent.getGeocodedValue().then(function (location) {
          _this.props.onUpdateRatingAddress({
            country: location.country,
            postal: location.postal
          });

          return _this.props.onGetPlans();
        }).then(function (response) {
          analytics.track('BOP - Address Verified');

          _this.props.navTo((0, _utils.resolveUrlPath)('survey'));
        }).catch(_this.props.onError);
      }
    }, _this.handleSelect = function (selectedObj) {
      _this.props.onUpdateDetails({
        business_class_code: selectedObj && parseInt(selectedObj.value, 10) || null
      });
    }, _this.handleChange = (0, _debounce2.default)(function (query) {
      if (query && query.length >= 3) {
        analytics.track('BOP - Business Type Searched', {
          term: query
        });
      }
    }, 250), _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BopBasicInfoView, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.getBusinessClasses().catch(this.props.onError);
      analytics.page('BOP - Basic Info');
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'BopBasicInfoView',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 117
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopBasicInfoView-address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 118
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 119
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'BopBasicInfoView-address-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 120
        },
        __self: this
      }, 'Tell us about your business'), _react2.default.createElement('form', {
        className: 'BopBasicInfoView-address-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 123
        },
        __self: this
      }, _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 127
        },
        __self: this
      }, 'What type of business are you insuring?'), _react2.default.createElement(_reactSelect2.default, {
        className: 'SureSelect',
        name: 'business_class_code',
        placeholder: 'Business type',
        value: this.props.details.business_class_code,
        options: this.businessClassOptions,
        disabled: this.props.isInProgress,
        isLoading: this.props.isInProgress,
        onChange: this.handleSelect,
        onInputChange: this.handleChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 128
        },
        __self: this
      }), _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 140
        },
        __self: this
      }, 'What is the address of your business?'), _react2.default.createElement(_AddressForm2.default, {
        streetAddress: this.props.ratingAddress.streetAddress,
        unit: this.props.ratingAddress.unit,
        city: this.props.ratingAddress.city,
        region: this.props.ratingAddress.region,
        postal: this.props.ratingAddress.postal,
        country: this.props.ratingAddress.country,
        ref: function ref(c) {
          return _this2.residenceAddressComponent = c;
        },
        onChange: this.props.onUpdateRatingAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 141
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 151
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        onNext: this.handleNext,
        isNextDisabled: this.isNextDisabled,
        isBackDisabled: true,
        isInProgress: this.props.isInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 153
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 159
        },
        __self: this
      }))));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      return !this.props.details.business_class_code || (0, _some2.default)([this.props.ratingAddress.streetAddress, this.props.ratingAddress.city, this.props.ratingAddress.region, this.props.ratingAddress.postal], _isEmpty2.default) || this.props.isInProgress;
    }
  }, {
    key: 'businessClassOptions',
    get: function get() {
      return (0, _map2.default)(this.props.businessClasses, function (businessClass) {
        return {
          label: businessClass.name,
          value: businessClass.code
        };
      });
    }
  }]);

  return BopBasicInfoView;
}(_react.PureComponent);

BopBasicInfoView.propTypes = propTypes;
BopBasicInfoView.defaultProps = defaultProps;
exports.default = BopBasicInfoView;