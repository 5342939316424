import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PagerButtons, RadioButton } from 'sure-components';
import get from 'lodash/get';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import * as analytics from 'shared/lib/analytics';
import './CarSafeBeneficiaryOptionView.css';

const propTypes = {
  carSafeDetails: PropTypes.object,
  carSafeSelectedPlan: PropTypes.object,
  updateCarSafeDetails: PropTypes.func,
};

const defaultProps = {
  carSafeDetails: {},
  carSafeSelectedPlan: {},
  updateCarSafeDetails: () => {},
};

class CarSafeBeneficiaryOptionView extends PureComponent {
  componentDidMount() {
    analytics.page('CarSafe - Beneficiary Option');
  }

  handleBack = () => {
    this.props.navTo('/vehicle-contents-insurance/policy-holder');
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.carSafeDetails.hasBeneficiary) {
      this.props.navTo('/vehicle-contents-insurance/beneficiary');
    } else {
      this.props.navTo('/vehicle-contents-insurance/payment');
    }
  };

  handleUpdate = e => {
    this.props.updateCarSafeDetails({
      hasBeneficiary: e.target.value === 'true',
    });
  };

  render() {
    const { carSafeDetails, carSafeSelectedPlan, disclaimer } = this.props;

    return (
      <div className="CarSafeBeneficiaryOptionView">
        <div className="Container Container--small">
          <h2 className="CarSafeBeneficiaryOptionView-planTitle">
            CarSafe: {get(carSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="CarSafeBeneficiaryOptionView-samplePolicyLink"
            href={carSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Key Information Document
          </a>
          <h1 className="CarSafeBeneficiaryOptionView-heading">
            Would you like to add a beneficiary?
          </h1>
          <form
            className="CarSafeBeneficiaryOptionView-form"
            onSubmit={this.handleNext}
          >
            <RadioButton
              name="hasBeneficiary"
              value="true"
              title="Yes"
              checked={carSafeDetails.hasBeneficiary === true}
              onChange={this.handleUpdate}
            />
            <RadioButton
              name="hasBeneficiary"
              value="false"
              title="No"
              text="By selecting ‘No’ the beneficiary will default to policy holder’s estate."
              checked={carSafeDetails.hasBeneficiary === false}
              onChange={this.handleUpdate}
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="CarSafeBeneficiaryOptionView-cta"
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
          />
          <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
        </div>
      </div>
    );
  }
}

CarSafeBeneficiaryOptionView.propTypes = propTypes;
CarSafeBeneficiaryOptionView.defaultProps = defaultProps;

export default CarSafeBeneficiaryOptionView;
