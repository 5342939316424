'use strict';

var POLICY = 'policy';
var ADDRESS = 'address';
var COLLECTION = 'collection';
var FIELD_KEY_DETAILS = {
  category_code: {
    type: COLLECTION
  },
  claims_count: {
    type: POLICY
  },
  collection_total_value: {
    type: COLLECTION
  },
  declined_insurance_type: {
    type: POLICY
  },
  filed_bankruptcy: {
    type: POLICY
  },
  has_burglar_alarm: {
    type: ADDRESS
  },
  has_fire_alarm: {
    type: ADDRESS
  },
  has_no_mailing_address: {
    type: POLICY
  },
  has_personal_safe: {
    type: COLLECTION
  },
  has_ul_rate_vault: {
    type: COLLECTION
  },
  has_ul_rated_safe: {
    type: COLLECTION
  },
  highest_value_item: {
    type: COLLECTION
  },
  is_declined_insurance: {
    type: POLICY
  },
  is_felon: {
    type: POLICY
  },
  mailing_address_city: {
    type: POLICY
  },
  mailing_address_country: {
    type: POLICY
  },
  mailing_address_line1: {
    type: POLICY
  },
  mailing_address_line2: {
    type: POLICY
  },
  mailing_address_postal: {
    type: POLICY
  },
  mailing_address_state_or_region: {
    type: POLICY
  },
  musical_group_name: {
    type: POLICY
  },
  owner_email: {
    type: POLICY
  },
  owner_first_name: {
    type: POLICY
  },
  owner_foundation: {
    type: POLICY
  },
  owner_last_name: {
    type: POLICY
  },
  owner_phone_number: {
    type: POLICY
  },
  owners: {
    type: POLICY
  },
  ownership_type: {
    type: POLICY
  },
  trust_name: {
    type: POLICY
  }
};
module.exports = FIELD_KEY_DETAILS;