import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextInput, PagerButtons, Select } from 'sure-components';
import get from 'lodash/get';
import map from 'lodash/map';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import TelInput from 'shared/lib/components/TelInput';
import Cleave from 'cleave.js/dist/cleave-react';
import * as analytics from 'shared/lib/analytics';
import './CarSafeBeneficiaryView.css';

const propTypes = {
  beneficiaryRelationships: PropTypes.array,
  carSafeDetails: PropTypes.object,
  carSafeSelectedPlan: PropTypes.object,
};

const defaultProps = {
  beneficiaryRelationships: [],
  carSafeDetails: {},
  carSafeSelectedPlan: {},
};

class CarSafeBeneficiaryView extends PureComponent {
  componentDidMount() {
    analytics.page('CarSafe - Beneficiary Info');
    this.props.getBeneficiaryRelationships();
  }

  handleBack = () => {
    this.props.navTo('/vehicle-contents-insurance/add-beneficiary');
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'beneficiaryInformationType') {
      analytics.track('CarSafe - Beneficiary Info Type Changed', {
        label: value,
      });
      this.props.updateCarSafeDetails({
        beneficiaryId: null,
        beneficiaryBirthdate: null,
      });
    }
    this.props.updateCarSafeDetails({
      [name]: value,
    });
  };

  handleInitBirthdate = cleave => {
    cleave.setRawValue(this.props.carSafeDetails.beneficiaryBirthdate);
  };

  handlePhoneChange = val => {
    this.props.updateCarSafeDetails({
      beneficiaryPhone: val,
    });
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.navTo('/vehicle-contents-insurance/payment');
  };

  render() {
    const {
      beneficiaryRelationships,
      carSafeDetails,
      carSafeSelectedPlan,
      disclaimer,
    } = this.props;

    return (
      <div className="CarSafeBeneficiaryView">
        <div className="Container Container--small">
          <h2 className="CarSafeBeneficiaryView-planTitle">
            CarSafe: {get(carSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="CarSafeBeneficiaryView-samplePolicyLink"
            href={carSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Key Information Document
          </a>
          <h1 className="CarSafeBeneficiaryView-heading">
            What is the beneficiary information?
          </h1>
          <form
            className="CarSafeBeneficiaryView-form"
            onSubmit={this.handleNext}
          >
            <label>Beneficiary relationship</label>
            <Select
              name="beneficiaryRelationship"
              required
              value={carSafeDetails.beneficiaryRelationship}
              onChange={this.handleChange}
            >
              <option value="">Relationship to Policy Holder</option>
              {map(beneficiaryRelationships, relationship => (
                <option key={relationship} value={relationship}>
                  {relationship}
                </option>
              ))}
            </Select>

            <FormGroup>
              <label>Beneficiary name</label>
              <TextInput
                name="beneficiaryFirstName"
                placeholder="First name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={carSafeDetails.beneficiaryFirstName || ''}
                onChange={this.handleChange}
              />
              <TextInput
                name="beneficiaryLastName"
                placeholder="Last name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={carSafeDetails.beneficiaryLastName || ''}
                onChange={this.handleChange}
              />
            </FormGroup>

            <label>Beneficiary information</label>
            <Select
              name="beneficiaryInformationType"
              value={carSafeDetails.beneficiaryInformationType}
              required
              onChange={this.handleChange}
            >
              <option value="">Select one</option>
              <option value="id">ID Number</option>
              <option value="birthdate">Birthdate (dd/mm/yyyy)</option>
            </Select>
            {carSafeDetails.beneficiaryInformationType === 'id' && (
              <TextInput
                name="beneficiaryId"
                placeholder="Enter ID Number"
                required
                minLength="13"
                maxLength="13"
                value={carSafeDetails.beneficiaryId || ''}
                onChange={this.handleChange}
              />
            )}
            {carSafeDetails.beneficiaryInformationType === 'birthdate' && (
              <Cleave
                className="CarSafeBeneficiaryView-birthdate SureTextInput"
                name="beneficiaryBirthdate"
                placeholder="dd/mm/yyyy"
                required
                options={{
                  delimiter: '/',
                  blocks: [2, 2, 4],
                  numericOnly: true,
                }}
                onInit={this.handleInitBirthdate}
                onChange={this.handleChange}
                ref={c => (this.birthdateNode = c)}
              />
            )}

            <FormGroup>
              <label>Beneficiary contact info</label>
              <TelInput
                className="CarSafeBeneficiaryView-telInput"
                name="beneficiaryPhone"
                required
                defaultCountry="za"
                preferredCountries={['za']}
                onChange={this.handlePhoneChange}
                value={carSafeDetails.beneficiaryPhone || ''}
              />
              <TextInput
                name="beneficiaryEmail"
                placeholder="Email"
                type="email"
                required
                value={carSafeDetails.beneficiaryEmail || ''}
                onChange={this.handleChange}
              />
            </FormGroup>
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="CarSafeBeneficiaryView-cta"
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
          />
          <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
        </div>
      </div>
    );
  }
}

CarSafeBeneficiaryView.propTypes = propTypes;
CarSafeBeneficiaryView.defaultProps = defaultProps;

export default CarSafeBeneficiaryView;
