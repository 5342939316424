import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import { Link } from 'react-router';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import classnames from 'classnames';
import heroImage from './images/landing-carsafe.png';
import iconSuitcaseImage from './images/icon-suitcase.png';
import './LandingCarSafeFAQ.css';

export default class LandingCarSafeFAQ extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    analytics.page('CarSafe - FAQ');
  }

  getFaqBoxKey(index) {
    return `isFaq${index}Open`;
  }

  toggleFaqBox = e => {
    const index = e.currentTarget.dataset.faqIndex;
    const key = this.getFaqBoxKey(index);
    this.setState({
      [key]: !this.state[key],
    });
  };

  render() {
    return (
      <div className="LandingCarSafeFAQ ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#01c1d6" bgImage={heroImage}>
          <h1 className="LandingCarSafeFAQ-heroTitle">
            Protect your personal valuables against theft out of your vehicle.
          </h1>
        </HeroChubb>
        <div className="LandingCarSafeFAQ-content">
          <div className="Container">
            <div className="LandingCarSafeFAQ-row">
              <div className="LandingCarSafeFAQ-faqs">
                <h2 className="LandingCarSafeFAQ-faqs-title">
                  <img
                    className="LandingCarSafe-content-title-icon"
                    src={iconSuitcaseImage}
                    alt="Suitcase icon"
                  />
                  FAQs
                </h2>
                <p className="LandingCarSafeFAQ-faqs-info">
                  Have a question? We’ve put together the following answers to
                  some frequently asked questions below.
                </p>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="0"
                  onClick={this.toggleFaqBox}
                >
                  <span>Will I be covered in the event of car jamming?</span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(0)],
                  })}
                >
                  <p>
                    No, unfortunately we do not provide cover in the event of
                    car jamming, we require proof of forcible entry into the
                    vehicle.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="1"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    My friend’s laptop was stolen from my vehicle, will they be
                    able to claim under my policy?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(1)],
                  })}
                >
                  <p>
                    No, unfortunately our policy only provides cover for the
                    personal belongings of the insured person and family
                    members.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="2"
                  onClick={this.toggleFaqBox}
                >
                  <span>What is an 'excess'?</span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(2)],
                  })}
                >
                  <p>
                    An excess is an agreed amount of money that you, the client,
                    is liable to pay in the event of an insurance claim being
                    settled. For example, if the excess on your contents cover
                    is R750, and the claim total amounts to R7,500, Chubb will
                    pay you the remaining R6,750.00 once we’ve deducted the
                    excess.
                  </p>
                  <p>
                    If your home was destroyed – along with the contents in it –
                    the excess would be deducted from the final settlement
                    amount paid to you.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="3"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    What is the 'standard time frame' in which you pay out?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(3)],
                  })}
                >
                  <p>
                    The time frame of any claim depends greatly on the type of
                    incident as well as how soon the required information and
                    supporting documentation is supplied to our claims
                    department.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="4"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    Someone else was using my vehicle with my knowledge and the
                    vehicle was broken into, my briefcase was stolen, can I
                    lodge a claim?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(4)],
                  })}
                >
                  <p>
                    Yes, your briefcase will be covered provided there was a
                    forcible entry into your vehicle.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="5"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    My car does not have an alarm system however the vehicle
                    does lock. Will this have an impact on any claims?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(5)],
                  })}
                >
                  <p>
                    No, this will not impact your claim provided that you ensure
                    your vehicle is locked at all times. However, proof of
                    forced entry is required in order to submit a claim.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="6"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    In the event of a theft claim, are invoices and/or receipts
                    for the items stolen required?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(6)],
                  })}
                >
                  <p>
                    In the unfortunate event of a contents insurance claim,
                    Chubb may ask you to prove ownership and value of the items
                    you claim for. It is advisable to keep your invoices to
                    ensure that the correct items are quoted on and replaced in
                    the event of a claim.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="7"
                  onClick={this.toggleFaqBox}
                >
                  <span>Why is there an excess?</span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(7)],
                  })}
                >
                  <p>The excess is designed mainly to:</p>
                  <ul>
                    <li>
                      Encourage policyholders to look after their contents (i.e.
                      handle them carefully and keep them safe)
                    </li>
                    <li>
                      Eliminate small claims that have a high administrative
                      cost relative to the value of the claim – which in turn
                      helps keep your premiums competitive.
                    </li>
                  </ul>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="8"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    What items are covered under the Vehicle Contents Plan?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(8)],
                  })}
                >
                  <p>
                    Items covered under personal possessions include; your
                    handbag, wallet, purse, briefcase or similar items.
                  </p>
                  <p>
                    Items covered under personal electronic items include; any
                    hand held electronic devices including but not limited to
                    mobile phones, tablets, personal organisers, personal
                    stereos, MP3 players, iPod or equivalent, smart watches,
                    computers/laptops/notebooks.
                  </p>
                  <p>
                    We do not provide cover for jewelry, watches, clothing,
                    money, bank notes, coins, stamps, documents, business
                    equipment and alcohol stolen from your motor vehicle.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="9"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    I own two vehicles, does cover extend to both my vehicles or
                    is it specific to only one vehicle?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(9)],
                  })}
                >
                  <p>
                    Yes, you are covered in the event of theft of personal items
                    from both vehicles limited to the sum insured shown in your
                    policy certificate.
                  </p>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="10"
                  onClick={this.toggleFaqBox}
                >
                  <span>What do I need to submit when logging a claim?</span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(10)],
                  })}
                >
                  <p>
                    You are required to report a claim to us within fourteen
                    (14) days of the incident occurring, documents that need to
                    be attached include:
                  </p>
                  <ul>
                    <li>
                      Full completed claim form which can be accessed online or
                      via our app
                    </li>
                    <li>
                      Police affidavit and any relevant documents issued by the
                      police or relevant authority
                    </li>
                    <li>
                      Copies of or the original invoices/receipts relating to
                      your personal possessions that verify the ownership and
                      value of the items
                    </li>
                    <li>Proof of forced entry</li>
                  </ul>
                </div>

                <h3
                  className="LandingCarSafeFAQ-faqs-question"
                  data-faq-index="11"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    My business laptop was stolen from my vehicle. Will I be
                    able to submit a claim under my policy?
                  </span>
                  <i className="LandingCarSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingCarSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(11)],
                  })}
                >
                  <p>
                    No, we do not provide cover for any business related
                    equipment. Cover is extended to items of a personal nature
                    only.
                  </p>
                </div>
              </div>
              <div className="LandingCarSafeFAQ-quoteCard">
                <h3 className="LandingCarSafeFAQ-quoteCard-title">
                  Get an instant quote in a few easy steps.
                </h3>
                <p className="LandingCarSafeFAQ-quoteCard-info">
                  Fill in our quick and easy online form to choose the perfect
                  cover for you.
                </p>
                <Link
                  to="/vehicle-contents-insurance/plans"
                  className="LandingCarSafeFAQ-quoteCard-cta"
                >
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterChubb />
      </div>
    );
  }
}
