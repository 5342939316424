'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarPayment/RentalCarPayment.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _startCase = require('lodash/startCase');

var _startCase2 = _interopRequireDefault(_startCase);

var _utils = require('shared/lib/utils');

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _PaymentLayout = require('shared/lib/components/PaymentLayout');

var _PaymentLayout2 = _interopRequireDefault(_PaymentLayout);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _approvalText = require('./approvalText');

var _approvalText2 = _interopRequireDefault(_approvalText);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  additionalDrivers: _propTypes2.default.array,
  disclaimer: _propTypes2.default.string,
  driverInfo: _propTypes2.default.object,
  isRentalCarInProgress: _propTypes2.default.bool,
  pickUpDate: _propTypes2.default.object,
  purchaseRentalCar: _propTypes2.default.func,
  rentalAgency: _propTypes2.default.string,
  returnDate: _propTypes2.default.object,
  selectedPlan: _propTypes2.default.object,
  totals: _propTypes2.default.object
};
var defaultProps = {
  disclaimer: null
};

var RentalCarPayment = function (_PureComponent) {
  _inherits(RentalCarPayment, _PureComponent);

  function RentalCarPayment() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, RentalCarPayment);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentalCarPayment.__proto__ || Object.getPrototypeOf(RentalCarPayment)).call.apply(_ref, [this].concat(args))), _this), _this.handleSubmit = function (_ref2) {
      var cardElement = _ref2.cardElement;

      _this.props.purchaseRentalCar(cardElement).then(function () {
        _this.props.navTo((0, _utils.resolveUrlPath)('confirmation'));
      }).catch(_this.props.onError);
    }, _this.showApprovalModal = function () {
      _this.props.showGlobalModal({
        id: 'info',
        title: '',
        body: _react2.default.createElement('p', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 75
          },
          __self: _this2
        }, _approvalText2.default),
        btnText: 'I understand and accept',
        afterDismiss: function afterDismiss() {
          _this.paymentLayout.handleAcknowledgmentUncheck();
        },
        afterConfirm: function afterConfirm() {}
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentalCarPayment, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.track('Rental Car - Payment Info Viewed', {
        noninteraction: 1
      });
      analytics.track('InitiateCheckout');
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 2
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement('div', {
        className: 'RentalCarPayment Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86
        },
        __self: this
      }, _react2.default.createElement(_PaymentLayout2.default, {
        title: 'Your ' + (0, _startCase2.default)((0, _get3.default)(this.props.selectedPlan, 'information.title')) + ' Rental Car Protection Quote',
        policyDocUrl: (0, _get3.default)(this.props.selectedPlan, 'policy_doc_url'),
        icon: 'directions_car',
        billingCycle: this.pricePerPeriod.billingCycle,
        isPurchaseInProgress: this.props.isRentalCarInProgress,
        pricePerCycle: this.pricePerPeriod.priceNum,
        totals: this.props.totals,
        acknowledgmentHtml: (0, _get3.default)(this.props.selectedPlan, 'information.acknowledgment_html'),
        isAcknowledgmentRequired: true,
        onShowAcknowledgment: this.showApprovalModal,
        ref: function ref(c) {
          return _this3.paymentLayout = c;
        },
        disclaimer: this.props.disclaimer,
        onSubmit: this.handleSubmit,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Rental car details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 107
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Agency', this.props.rentalAgency], ['Pick-up date', this.props.pickUpDate && this.props.pickUpDate.format('MMM D, YYYY')], ['Return date', this.props.returnDate && this.props.returnDate.format('MMM D, YYYY')]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Driver details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 125
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Primary driver', this.props.driverInfo.firstName + ' ' + this.props.driverInfo.lastName]].concat(_toConsumableArray(this.additionalDriversArr)),
        testId: 'rentalCarDrivers',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 126
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Coverage details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 140
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Underwritten by', 'Chubb'], ['Start date', this.props.pickUpDate && this.props.pickUpDate.format('MMM D, YYYY')], ['End date', this.props.returnDate && this.props.returnDate.format('MMM D, YYYY')]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 141
        },
        __self: this
      }))));
    }
  }, {
    key: 'additionalDriversArr',
    get: function get() {
      return this.props.additionalDrivers.map(function (driver, i) {
        return ['Additional driver', driver.firstName + ' ' + driver.lastName];
      });
    }
  }, {
    key: 'pricePerPeriod',
    get: function get() {
      var pricingTitle = (0, _get3.default)(this.props.selectedPlan, 'information.pricing_title');

      if (pricingTitle) {
        var _pricingTitle$split = pricingTitle.split('/'),
            _pricingTitle$split2 = _slicedToArray(_pricingTitle$split, 2),
            priceStr = _pricingTitle$split2[0],
            billingCycle = _pricingTitle$split2[1];

        var priceNum = parseFloat(priceStr.slice(1));
        return {
          priceNum: priceNum,
          billingCycle: billingCycle
        };
      } else {
        return {};
      }
    }
  }]);

  return RentalCarPayment;
}(_react.PureComponent);

RentalCarPayment.propTypes = propTypes;
RentalCarPayment.defaultProps = defaultProps;
exports.default = RentalCarPayment;