'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _head = require('lodash/head');

var _head2 = _interopRequireDefault(_head);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _utils = require('shared/lib/utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (api) {
  /*
   * Business classes
   * ========================================
   */
  function getBusinessClassesRequest() {
    return {
      type: _actionTypes2.default.GET_BUSINESS_CLASSES
    };
  }

  function getBusinessClassesSuccess() {
    var businessClasses = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      type: _actionTypes2.default.GET_BUSINESS_CLASSES_SUCCESS,
      payload: {
        businessClasses: businessClasses
      }
    };
  }

  function getBusinessClassesFailure(error) {
    return {
      type: _actionTypes2.default.GET_BUSINESS_CLASSES_FAILURE,
      error: error
    };
  }

  function getBusinessClasses() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState = getState(),
            authToken = _getState.authToken;

        dispatch(getBusinessClassesRequest());
        api.getBusinessClasses({
          authToken: authToken
        }).then(function (businessClasses) {
          dispatch(getBusinessClassesSuccess(businessClasses));
          resolve(businessClasses);
        }).catch(function (error) {
          dispatch(getBusinessClassesFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * BOP rating address
   * ========================================
   */


  function updateBopRatingAddress() {
    var ratingAddress = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      type: _actionTypes2.default.UPDATE_BOP_RATING_ADDRESS,
      payload: {
        ratingAddress: ratingAddress
      }
    };
  }
  /*
   * BOP details
   * ========================================
   */


  function updateBopDetails() {
    var details = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      type: _actionTypes2.default.UPDATE_BOP_DETAILS,
      payload: {
        details: details
      }
    };
  }

  function getBopPlansRequest(data) {
    return {
      type: _actionTypes2.default.GET_BOP_PLANS,
      data: data
    };
  }
  /*
   * BOP get plans
   * ========================================
   */


  function getBopPlansSuccess(data) {
    return {
      type: _actionTypes2.default.GET_BOP_PLANS_SUCCESS,
      payload: data
    };
  }

  function getBopPlansFailure(error) {
    return {
      type: _actionTypes2.default.GET_BOP_PLANS_FAILURE,
      error: error
    };
  }

  function getBopPlans() {
    return function (dispatch, getState) {
      var _getState2 = getState(),
          authToken = _getState2.authToken,
          bopRatingAddress = _getState2.bopRatingAddress,
          bopDetails = _getState2.bopDetails;

      return new Promise(function (resolve, reject) {
        dispatch(getBopPlansRequest({
          ratingAddress: bopRatingAddress,
          details: bopDetails
        }));
        api.getBopPlans({
          authToken: authToken,
          businessClassCode: bopDetails.business_class_code,
          ratingAddress: bopRatingAddress
        }).then(function (response) {
          dispatch(getBopPlansSuccess(response));
          resolve(response);
        }).catch(function (error) {
          dispatch(getBopPlansFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * BOP survey
   * ========================================
   */


  function bopSurveyNext() {
    return {
      type: _actionTypes2.default.BOP_SURVEY_NEXT
    };
  }

  function selectBOPChoiceByFieldAndValue(field, value, dispatch) {
    var choice = (0, _find2.default)(field.settings.choices, {
      value: value
    });

    if (choice && choice.screen_id) {
      dispatch({
        type: _actionTypes2.default.SELECT_BOP_CHOICE,
        screen_id: choice.screen_id
      });
    }
  }

  function updateBopCurrentResponse(response) {
    return {
      type: _actionTypes2.default.UPDATE_BOP_CURRENT_RESPONSE,
      payload: {
        response: response
      }
    };
  }

  function saveBopResponse(response) {
    return function (dispatch, getState) {
      var _getState3 = getState(),
          bopScreens = _getState3.bopScreens,
          bopCurrentScreenId = _getState3.bopCurrentScreenId;

      var screen = bopScreens[bopCurrentScreenId];
      var fieldsType = (0, _utils.getFieldsType)((0, _get2.default)(screen, 'fields'));

      if (fieldsType === _utils.FIELDS_TYPES.SINGLE_SELECT || fieldsType === _utils.FIELDS_TYPES.MULTI_SELECT) {
        var field = (0, _head2.default)(screen.fields);
        (0, _each2.default)(response, function (value, key) {
          if ((0, _isArray2.default)(value)) {
            (0, _each2.default)(value, function (_value) {
              selectBOPChoiceByFieldAndValue(field, _value, dispatch);
            });
          } else {
            selectBOPChoiceByFieldAndValue(field, value, dispatch);
          }
        });
      }

      dispatch(updateBopDetails(response));
      return Promise.resolve();
    };
  }

  function deleteBopResponse() {
    var responseKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      type: _actionTypes2.default.DELETE_BOP_RESPONSE,
      payload: {
        responseKeys: responseKeys
      }
    };
  }

  function surveyBopBack() {
    return {
      type: _actionTypes2.default.BOP_SURVEY_BACK
    };
  }
  /*
   * BOP policy start date
   * ========================================
   */


  function updateBopStartDate(momentObj) {
    return {
      type: _actionTypes2.default.UPDATE_BOP_START_DATE,
      payload: {
        isoString: momentObj && momentObj.toISOString() || null
      }
    };
  }
  /*
   * BOP get rates
   * ========================================
   */


  function getBopRatesRequest() {
    return {
      type: _actionTypes2.default.GET_BOP_RATES
    };
  }

  function getBopRatesSuccess(data) {
    return {
      type: _actionTypes2.default.GET_BOP_RATES_SUCCESS,
      payload: data
    };
  }

  function getBopRatesFailure(error) {
    return {
      type: _actionTypes2.default.GET_BOP_RATES_FAILURE,
      error: error
    };
  }

  function getBopRates() {
    return function (dispatch, getState) {
      var _getState4 = getState(),
          authToken = _getState4.authToken,
          bopRatingAddress = _getState4.bopRatingAddress,
          bopDetails = _getState4.bopDetails,
          bopScreens = _getState4.bopScreens,
          bopPolicyStartDate = _getState4.bopPolicyStartDate;

      var filteredDetails = (0, _utils.filterDynamicFormResponses)(bopScreens, bopDetails);
      var details = Object.assign({}, filteredDetails, {
        business_class_code: bopDetails.business_class_code,
        policy_effective_date: (0, _momentTimezone2.default)(bopPolicyStartDate).format('YYYY-MM-DD')
      });
      return new Promise(function (resolve, reject) {
        dispatch(getBopRatesRequest({
          bopRatingAddress: bopRatingAddress,
          details: details
        }));
        api.getBopRates({
          authToken: authToken,
          details: details,
          ratingAddress: bopRatingAddress
        }).then(function (response) {
          dispatch(getBopRatesSuccess(response));
          resolve(response);
        }).catch(function (error) {
          dispatch(getBopRatesFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * BOP get checkout
   * ========================================
   */


  function getBopCheckoutRequest(planId) {
    return {
      type: _actionTypes2.default.GET_BOP_CHECKOUT,
      payload: {
        planId: planId
      }
    };
  }

  function getBopCheckoutSuccess(data) {
    return {
      type: _actionTypes2.default.GET_BOP_CHECKOUT_SUCCESS,
      payload: data
    };
  }

  function getBopCheckoutFailure(error) {
    return {
      type: _actionTypes2.default.GET_BOP_CHECKOUT_FAILURE,
      error: error
    };
  }

  function getBopCheckout(planCode) {
    return function (dispatch, getState) {
      var _getState5 = getState(),
          authToken = _getState5.authToken,
          bopRatingAddress = _getState5.bopRatingAddress,
          bopQuoteId = _getState5.bopQuoteId,
          bopPlans = _getState5.bopPlans;

      var plan = (0, _find2.default)(bopPlans, {
        details: {
          chubb_plan_code: planCode
        }
      });
      return new Promise(function (resolve, reject) {
        dispatch(getBopCheckoutRequest(plan.plan_id));
        api.getBopCheckout({
          authToken: authToken,
          planId: plan.plan_id,
          quoteId: bopQuoteId,
          ratingAddress: bopRatingAddress
        }).then(function (response) {
          dispatch(getBopCheckoutSuccess(response));
          resolve(response);
        }).catch(function (error) {
          dispatch(getBopCheckoutFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * BOP purchase
   * ========================================
   */


  function purchaseBopRequest() {
    return {
      type: _actionTypes2.default.PURCHASE_BOP
    };
  }

  function purchaseBopSuccess(data) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_BOP_SUCCESS
    });
    return {
      type: _actionTypes2.default.PURCHASE_BOP_SUCCESS,
      payload: data
    };
  }

  function purchaseBopError(error) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_BOP_FAILURE
    });
    return {
      type: _actionTypes2.default.PURCHASE_BOP_FAILURE,
      error: error
    };
  }

  function purchaseBop(cardElement) {
    return function (dispatch, getState) {
      var _getState6 = getState(),
          authToken = _getState6.authToken,
          bopPlans = _getState6.bopPlans,
          bopSelectedPlanId = _getState6.bopSelectedPlanId,
          bopCheckoutInfo = _getState6.bopCheckoutInfo,
          bopQuoteId = _getState6.bopQuoteId,
          bopDetails = _getState6.bopDetails,
          metadata = _getState6.metadata;

      var revenue = bopCheckoutInfo.totals.grandtotal;
      var selectedPlan = (0, _find2.default)(bopPlans, {
        plan_id: bopSelectedPlanId
      });
      var opts = {
        planId: bopSelectedPlanId,
        planTitle: (0, _get2.default)(selectedPlan, 'information.title'),
        quoteId: bopQuoteId,
        authToken: authToken,
        firstName: bopDetails.pni_first_name,
        lastName: bopDetails.pni_last_name,
        phoneNumber: bopDetails.pni_phone_number,
        email: bopDetails.pni_email,
        revenue: revenue,
        cardElement: cardElement,
        metadata: metadata
      };
      dispatch(purchaseBopRequest());
      return new Promise(function (resolve, reject) {
        api.purchaseBop(opts).then(function (purchaseData) {
          dispatch(purchaseBopSuccess(purchaseData));
          resolve();
        }).catch(function (err) {
          dispatch(purchaseBopError(err.response.body.error));
          reject(err.response.body.error);
        });
      });
    };
  } // ----------------------------------------
  // Exports
  // ----------------------------------------


  return {
    getBusinessClasses: getBusinessClasses,
    updateBopRatingAddress: updateBopRatingAddress,
    updateBopDetails: updateBopDetails,
    getBopPlans: getBopPlans,
    bopSurveyNext: bopSurveyNext,
    updateBopCurrentResponse: updateBopCurrentResponse,
    saveBopResponse: saveBopResponse,
    deleteBopResponse: deleteBopResponse,
    surveyBopBack: surveyBopBack,
    updateBopStartDate: updateBopStartDate,
    getBopRates: getBopRates,
    getBopCheckout: getBopCheckout,
    purchaseBop: purchaseBop
  };
};