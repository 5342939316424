'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPaymentMethod = createPaymentMethod;
exports.createQuote = createQuote;
exports.getQuotePlans = getQuotePlans;
exports.updateQuote = updateQuote;
exports.getQuoteCheckout = getQuoteCheckout;
exports.purchaseQuote = purchaseQuote;

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _isPlainObject = require('lodash/isPlainObject');

var _isPlainObject2 = _interopRequireDefault(_isPlainObject);

var _utils = require('shared/lib/utils');

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * UTILS
 */

/**
 * @param {Object} opts Payment method arguments.
 * @param {String} opts.firstName User first name.
 * @param {String} opts.lastName User last name.
 * @param {String} opts.email User email.
 * @param {String} opts.stripeToken The Stripe payment token.
 * @param {String} opts.apiEndpoint The Sure api url to post to.
 * @param {String} [opts.authToken] The Sure authentication token.
 * @param {Boolean} [opts.getId] If true, it will resolve with the payment
 * method ID instead of the entire body JSON.
 */


function createPaymentMethod(opts) {
  var payload = {
    first_name: opts.firstName,
    last_name: opts.lastName,
    email: opts.email,
    token: opts.stripeToken
  };
  return new Promise(function (resolve, reject) {
    (0, _utils.destructureAxiosReq)(_axios2.default.post(opts.apiEndpoint, payload)).then(function (response) {
      if (opts.getId) {
        var paymentMethodId = response.public_id || response.payment_method_id;
        resolve(paymentMethodId);
      } else {
        resolve(response);
      }
    }).catch(reject);
  });
}
/**
 *  QUOTE API
 */

/**
 * Create a quote object.
 * @param {Object} opts The options.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.applicationUpdates The updates to be applied to the application object
 * @param {String} opts.verticalCode The quote's vertical code.
 * @param {String} opts.apiBaseUrl The user's environment-dependent api base URL.
 */


function createQuote() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var browserEnv = Object.assign({}, (0, _utils.getDeviceInfo)(), {
      lat: opts.lat,
      lng: opts.lng
    });
    var payload = (0, _utils.trimValues)({
      application: opts.applicationUpdates
    });
    (0, _utils.destructureAxiosReq)(_axios2.default.post(opts.apiBaseUrl + '/api/quote/v1/protections/' + opts.verticalCode + '/quote', payload, {
      headers: {
        'X-Environment': JSON.stringify(browserEnv)
      }
    })).then(resolve).catch(function (error) {
      (0, _utils.logException)(error);
      reject(error);
    });
  });
}
/**
 * Get quote plans.
 * @param {Object} opts The options.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} opts.quoteId The user's quote ID.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.verticalCode The quote's vertical code.
 * @param {String} opts.apiBaseUrl The user's environment-dependent api base URL.
 * @param {String} [opts.categoryCode] The category of insurance specified by the user (Collectibles only)
 */


function getQuotePlans() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var browserEnv = Object.assign({}, (0, _utils.getDeviceInfo)(), {
      lat: opts.lat,
      lng: opts.lng
    });
    var plansEndpoint = opts.categoryCode ? 'plans?category_code=' + opts.categoryCode : 'plans';
    (0, _utils.destructureAxiosReq)(_axios2.default.get(opts.apiBaseUrl + '/api/quote/v1/protections/' + opts.verticalCode + '/quotes/' + opts.quoteId + '/' + plansEndpoint, {
      headers: {
        'X-Environment': JSON.stringify(browserEnv)
      }
    })).then(resolve).catch(function (error) {
      (0, _utils.logException)(error);
      reject(error);
    });
  });
}
/**
 * Update a quote object.
 * @param {Object} opts The options.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.application The existing quote application.
 * @param {Object} opts.applicationUpdates The updates to be applied to the quote application
 * @param {Object} opts.applicationUpdates.plan_application The full plan_applications array, including existing and updated plan_applications
 * @param {String} opts.verticalCode The quote's vertical code.
 * @param {String} opts.quoteId The quote ID.
 * @param {String} opts.apiBaseUrl The user's environment-dependent api base URL.
 */


function updateQuote() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var browserEnv = (0, _utils.getDeviceInfo)();

    if (opts.lat && opts.lng) {
      browserEnv.lat = opts.lat;
      browserEnv.lng = opts.lng;
    }

    var payload = (0, _utils.trimValues)({
      application: Object.assign({}, (0, _merge2.default)({}, opts.application, opts.applicationUpdates), {
        plan_applications: opts.applicationUpdates.plan_applications || opts.application.plan_applications
      })
    });
    (0, _utils.destructureAxiosReq)(_axios2.default.put(opts.apiBaseUrl + '/api/quote/v1/protections/' + opts.verticalCode + '/quotes/' + opts.quoteId, payload, {
      headers: {
        'X-Environment': JSON.stringify(browserEnv)
      }
    })).then(resolve).catch(function (error) {
      (0, _utils.logException)(error);
      reject(error);
    });
  });
}
/**
 * Retrieve a landlord checkout model.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} opts.quoteId The user's quote ID.
 * @param {String} opts.verticalCode The quote's vertical code.
 * @param {String} opts.apiBaseUrl The user's environment-dependent api base URL.
 */


function getQuoteCheckout(opts) {
  return new Promise(function (resolve, reject) {
    var browserEnv = (0, _utils.getDeviceInfo)();

    if (opts.lat && opts.lng) {
      browserEnv.lat = opts.lat;
      browserEnv.lng = opts.lng;
    }

    (0, _utils.destructureAxiosReq)(_axios2.default.post(opts.apiBaseUrl + '/api/quote/v1/protections/' + opts.verticalCode + '/quotes/' + opts.quoteId + '/checkout', {}, {
      headers: {
        'X-Environment': JSON.stringify(browserEnv)
      }
    })).then(resolve).catch(function (error) {
      (0, _utils.logException)(error);
      reject(error);
    });
  });
}
/**
 * Purchase a quote.
 * @param {Object} opts The options.
 * @param {String} opts.prefix The events prefix.
 * @param {String} [opts.suffix] The events suffix.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} opts.quoteId The user's quote ID.
 * @param {String} opts.email The user's email address.
 * @param {String} opts.firstName The user's first name.
 * @param {String} opts.lastName The user's last name.
 * @param {String} opts.revenue The revenue (USD) generated by the quote purchase.
 * @param {String} opts.verticalCode The quote's vertical code.
 * @param {String} opts.verticalName The quote's vertical name.
 * @param {String} [opts.paymentCadence] The quote's payment cadence ("Yearly", "Monthly", "Once").
 * @param {String} opts.apiBaseUrl The user's environment-dependent api base URL.
 * @param {Object} [opts.metadata] Any metadata to attach to the purchase.
 */


function purchaseQuote() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var browserEnv = (0, _utils.getDeviceInfo)();

    if (opts.lat && opts.lng) {
      browserEnv.lat = opts.lat;
      browserEnv.lng = opts.lng;
    }

    (0, _utils.destructureAxiosReq)(_axios2.default.post(opts.apiBaseUrl + '/api/quote/v1/protections/' + opts.verticalCode + '/quotes/' + opts.quoteId + '/purchase', {
      metadata: opts.metadata
    }, {
      headers: {
        'X-Environment': JSON.stringify(browserEnv)
      }
    })).then(function (response) {
      if ((0, _isEmpty2.default)(response) || !(0, _isPlainObject2.default)(response)) {
        response = {};
      }

      if (response.account_id) {
        analytics.track('Dormant Account Created', {
          category: 'user',
          label: response.account_id
        });
        analytics.alias(response.account_id);
      }

      var orderId = (0, _get2.default)(response, 'agreements[0].agreement_id') || (0, _get2.default)(response, 'agreements[0].policy_number');
      analytics.track(opts.prefix + ' - Purchase Completed' + (0, _get2.default)(opts, 'suffix', ''), {
        category: 'order',
        label: opts.quoteId,
        value: opts.revenue,
        nonInteraction: 1
      });
      analytics.track('Order Completed', {
        category: 'order',
        order_id: orderId,
        revenue: opts.revenue,
        email: opts.email,
        name: opts.firstName,
        surname: opts.lastName
      });
      analytics.trackGTMPurchase({
        orderId: opts.quoteId,
        revenue: opts.revenue,
        paymentCadence: opts.paymentCadence,
        verticalCode: opts.verticalCode,
        verticalName: opts.verticalName
      });
      resolve(response);
    }).catch(function (error) {
      (0, _utils.logException)(error);
      analytics.track(opts.prefix + ' - Purchase Failed' + (0, _get2.default)(opts, 'suffix', ''), {
        category: 'order',
        label: opts.quoteId,
        value: opts.revenue,
        nonInteraction: 1
      });
      reject(error);
    });
  });
}