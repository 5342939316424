import React, { Component } from 'react';
import Button from 'shared/lib/components/Button';
import Footer from '../Footer';
import TopNavBar from '../TopNavBar';
import { RENTAL_CAR_ROUTES } from 'shared/lib/apps/RentalCarApp/routes';
import * as analytics from 'shared/lib/analytics';
import './LandingRentalCar.css';

import imageUserAngela from './images/user-angela-koda.png';
import imageUserTamara from './images/user-tamara-idencras.png';
import imageUserJoseph from './images/user-joseph-rowen.png';

export default class LandingRentalCar extends Component {
  componentDidMount() {
    analytics.page('Rental Car - Landing');
    analytics.track('Rental Car - Landing Page Viewed', {
      noninteraction: 1,
    });
  }

  render() {
    const { partnerInfo, isMenuOpen, settings, onToggleMenu } = this.props;

    return (
      <div className="LandingRentalCar">
        <div className="LandingRentalCar-hero">
          <TopNavBar
            transparent
            showMenuText
            isMenuOpen={isMenuOpen}
            sideMenuLinks={settings.sideMenuLinks}
            logoLightUrl={settings.logoLightUrl}
            logoDarkUrl={settings.logoDarkUrl}
            title={settings.title}
            onToggleMenu={onToggleMenu}
          />
          <div className="Container">
            <h1 className="LandingRentalCar-hero-heading">
              You’ve got enough to worry about while driving a rental
            </h1>
            <p className="LandingRentalCar-hero-pitch">
              Insurance is the last thing you want to think about when you’re
              traveling. Why wait until you’re at the rental counter? Put your
              mind at ease. Sure takes the pain out of the process to and offers
              get you comprehensive coverage at a more affordable rate.
            </p>
            <Button
              className="LandingRentalCar-cta"
              to={RENTAL_CAR_ROUTES.RENTAL_INFO}
              shadow
            >
              <i className="material-icons">&#xE8E8;</i>
              Check our prices
            </Button>
          </div>
        </div>

        <div className="LandingRentalCar-coverage">
          <div className="Container">
            <h2 className="LandingRentalCar-coverage-heading">
              What does car rental insurance cover?
            </h2>
            <p className="LandingRentalCar-coverage-info">
              Rental insurance bought through an agency can cost an additional
              50% of your daily car rental rate. With Sure, you’ll get better
              coverage for a fraction of the cost:
            </p>
            <div className="LandingRentalCar-coverage-threeUp">
              <div className="LandingRentalCar-coverage-threeUp-card">
                <div className="LandingRentalCar-coverage-threeUp-card-header">
                  <div className="LandingRentalCar-coverage-threeUp-card-header-icon">
                    <i className="material-icons">warning</i>
                  </div>
                </div>
                <h3 className="LandingRentalCar-coverage-threeUp-card-heading">
                  Physical damage coverage
                </h3>
                <p className="LandingRentalCar-coverage-threeUp-card-info">
                  From minor fender-benders to more serious accidents, you’ll be
                  covered for physical damage to the vehicle up to $100,000.
                  Your property within the car is covered, too.
                </p>
              </div>
              <div className="LandingRentalCar-coverage-threeUp-card">
                <div className="LandingRentalCar-coverage-threeUp-card-header">
                  <div className="LandingRentalCar-coverage-threeUp-card-header-icon">
                    <i className="material-icons">build</i>
                  </div>
                </div>
                <h3 className="LandingRentalCar-coverage-threeUp-card-heading">
                  Miscellaneous rental car snafus
                </h3>
                <p className="LandingRentalCar-coverage-threeUp-card-info">
                  Things happen when it’s not your car. Lost keys, towing
                  charges, flat tires and property theft can cost a small
                  fortune to manage on the road. We’ve got you covered for each.
                </p>
              </div>
              <div className="LandingRentalCar-coverage-threeUp-card">
                <div className="LandingRentalCar-coverage-threeUp-card-header">
                  <div className="LandingRentalCar-coverage-threeUp-card-header-icon">
                    <i className="material-icons">smartphone</i>
                  </div>
                </div>
                <h3 className="LandingRentalCar-coverage-threeUp-card-heading">
                  Better planning & no unexpected charges
                </h3>
                <p className="LandingRentalCar-coverage-threeUp-card-info">
                  Type A travelers, rejoice! With Sure, you can buy rental
                  insurance before you ever leave your house. Your to-do list
                  will thank you, and you’ll avoid unexpected charges at the
                  counter.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="LandingRentalCar-pricing">
          <h2 className="LandingRentalCar-pricing-heading">
            Here’s what you’d invest
          </h2>
          <p className="LandingRentalCar-pricing-info">
            Plans start at less than $10/per day. That’s some serious peace of
            mind for what you already pay for:
          </p>
          <p className="LandingRentalCar-pricing-comparisonText">
            Here’s where that money would be going otherwise:
          </p>
          <div className="LandingRentalCar-pricing-examples">
            <div className="LandingRentalCar-pricing-examples-example">
              <div className="LandingRentalCar-pricing-examples-example-icon">
                <i className="material-icons">local_gas_station</i>
              </div>
              <p className="LandingRentalCar-pricing-examples-example-text">
                5 gallons of gas
              </p>
            </div>
            <div className="LandingRentalCar-pricing-examples-example">
              <div className="LandingRentalCar-pricing-examples-example-icon">
                <i className="material-icons">radio</i>
              </div>
              <p className="LandingRentalCar-pricing-examples-example-text">
                Your monthly SiriusXM subscription
              </p>
            </div>
            <div className="LandingRentalCar-pricing-examples-example">
              <div className="LandingRentalCar-pricing-examples-example-icon">
                <i className="material-icons">ondemand_video</i>
              </div>
              <p className="LandingRentalCar-pricing-examples-example-text">
                Data overages to cover streaming video
              </p>
            </div>
            <div className="LandingRentalCar-pricing-examples-example">
              <div className="LandingRentalCar-pricing-examples-example-icon">
                <i className="material-icons">restaurant</i>
              </div>
              <p className="LandingRentalCar-pricing-examples-example-text">
                2 drive-through meals
              </p>
            </div>
          </div>
        </div>

        <div className="LandingRentalCar-testimonials">
          <h2 className="LandingRentalCar-testimonials-heading">
            Don’t let a car rental snafu ruin your trip
          </h2>
          <ul className="LandingRentalCar-testimonials-list">
            <li className="LandingRentalCar-testimonial">
              <p className="LandingRentalCar-testimonial-text">
                I made a bonehead move while filling up the gas tank in my
                rental. Since I was unfamiliar with the car, I opened the front
                door directly into an iron beam, causing a massive dent.
                Thankfully, I had insurance, which covered the full cost of the
                repair.
              </p>
              <div className="LandingRentalCar-testimonial-person">
                <img
                  className="LandingRentalCar-testimonial-person-avatar"
                  src={imageUserAngela}
                  alt="Angela Koda"
                />
                <div className="LandingRentalCar-testimonial-person-info">
                  <p className="LandingRentalCar-testimonial-person-info-name">
                    Angela Koda
                  </p>
                  <p className="LandingRentalCar-testimonial-person-info-location">
                    Columbus, OH
                  </p>
                </div>
              </div>
            </li>
            <li className="LandingRentalCar-testimonial">
              <p className="LandingRentalCar-testimonial-text">
                I was driving my younger sister to college across country when
                somewhere in the middle of Ohio we ran over a piece of debris
                and blew out a front tire. Fortunately, neither of us were hurt,
                but we did have to call a tow truck. Insurance had us covered.
              </p>
              <div className="LandingRentalCar-testimonial-person">
                <img
                  className="LandingRentalCar-testimonial-person-avatar"
                  src={imageUserTamara}
                  alt="Tamara Idencras"
                />
                <div className="LandingRentalCar-testimonial-person-info">
                  <p className="LandingRentalCar-testimonial-person-info-name">
                    Tamara Idencras
                  </p>
                  <p className="LandingRentalCar-testimonial-person-info-location">
                    Seattle, WA
                  </p>
                </div>
              </div>
            </li>
            <li className="LandingRentalCar-testimonial">
              <p className="LandingRentalCar-testimonial-text">
                My friends and I bought a rental to drive to a music festival.
                After having an amazing night, we returned to the car and
                realized that we’d somehow lost the keys. Because we bought
                insurance in advance, the cost of roadside assistance was 100%
                covered.
              </p>
              <div className="LandingRentalCar-testimonial-person">
                <img
                  className="LandingRentalCar-testimonial-person-avatar"
                  src={imageUserJoseph}
                  alt="Joseph Rowen"
                />
                <div className="LandingRentalCar-testimonial-person-info">
                  <p className="LandingRentalCar-testimonial-person-info-name">
                    Joseph Rowen
                  </p>
                  <p className="LandingRentalCar-testimonial-person-info-location">
                    Houston, TX
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="LandingRentalCar-bottomCta">
          <h3 className="LandingRentalCar-bottomCta-heading">
            Renting a car should be fun, not worrisome.
            <br />
            Protect yourself today.
          </h3>
          <Button
            className="LandingRentalCar-cta"
            to={RENTAL_CAR_ROUTES.RENTAL_INFO}
            sureStyle="success"
            shadow
          >
            <i className="material-icons">&#xE8E8;</i>
            Get Started
          </Button>
        </div>
        <Footer partnerInfo={partnerInfo} />
      </div>
    );
  }
}
