import ACTION_TYPES from '../../constants/actionTypes';
import { emitMessage } from 'shared/lib/utils';
import api from '../../api';

/**
 * CarSafe plans
 */

function getCarSafePlansRequest() {
  return {
    type: ACTION_TYPES.GET_CARSAFE_PLANS,
  };
}

function getCarSafePlansSuccess({ plans, settings }) {
  return {
    type: ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS,
    payload: {
      plans,
      settings,
    },
  };
}

function getCarSafePlansFailure(error) {
  return {
    type: ACTION_TYPES.GET_CARSAFE_PLANS_FAILURE,
    error,
  };
}

export function getCarSafePlans(countryCode) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    dispatch(getCarSafePlansRequest());
    return new Promise((resolve, reject) => {
      api
        .getCarSafePlans({
          authToken,
          countryCode,
        })
        .then(
          plans => {
            dispatch(getCarSafePlansSuccess(plans));
            resolve(plans);
          },
          error => {
            dispatch(getCarSafePlansFailure(error));
            reject(error);
          }
        );
    });
  };
}

/**
 * CarSafe Details
 */

export function updateCarSafeDetails(details) {
  return {
    type: ACTION_TYPES.UPDATE_CARSAFE_DETAILS,
    payload: details,
  };
}

/**
 * CarSafe Plan
 */

export function selectCarSafePlan(planId) {
  return {
    type: ACTION_TYPES.SELECT_CARSAFE_PLAN,
    payload: {
      planId,
    },
  };
}

/**
 * CarSafe checkout
 */

function getCarSafeCheckoutRequest() {
  return {
    type: ACTION_TYPES.GET_CARSAFE_CHECKOUT,
  };
}

function getCarSafeCheckoutSuccess(checkout) {
  return {
    type: ACTION_TYPES.GET_CARSAFE_CHECKOUT_SUCCESS,
    payload: checkout,
  };
}

function getCarSafeCheckoutFailure(error) {
  return {
    type: ACTION_TYPES.GET_CARSAFE_CHECKOUT_FAILURE,
    error,
  };
}

export function getCarSafeCheckout() {
  return (dispatch, getState) => {
    const { authToken, carSafeDetails, carSafeSelectedPlanId } = getState();

    dispatch(getCarSafeCheckoutRequest());
    return new Promise((resolve, reject) => {
      api
        .getCarSafeCheckout({
          authToken,
          firstName: carSafeDetails.firstName,
          lastName: carSafeDetails.lastName,
          email: carSafeDetails.email,
          phone: carSafeDetails.phone,
          hasBeneficiary: carSafeDetails.hasBeneficiary,
          beneficiaryFirstName: carSafeDetails.beneficiaryFirstName,
          beneficiaryLastName: carSafeDetails.beneficiaryLastName,
          beneficiaryEmail: carSafeDetails.beneficiaryEmail,
          beneficiaryPhone: carSafeDetails.beneficiaryPhone,
          beneficiaryRelationship: carSafeDetails.beneficiaryRelationship,
          beneficiaryDob: carSafeDetails.beneficiaryDob,
          beneficiaryId: carSafeDetails.beneficiaryId,
          planId: carSafeSelectedPlanId,
          policyholderId: carSafeDetails.policyholderId,
          streetAddress: carSafeDetails.streetAddress,
          unit: carSafeDetails.unit,
          city: carSafeDetails.city,
          region: 'ZA' || carSafeDetails.region,
          postal: carSafeDetails.postal,
          country: carSafeDetails.country,
        })
        .then(resp => {
          const plans = resp.data;
          dispatch(getCarSafeCheckoutSuccess(plans));
          resolve(plans);
        })
        .catch(error => {
          dispatch(getCarSafeCheckoutFailure(error.response.data.error));
          reject(error.response.data.error);
        });
    });
  };
}

/**
 * CarSafe purchase
 */

function purchaseCarSafeRequest() {
  return {
    type: ACTION_TYPES.PURCHASE_CARSAFE,
  };
}

function purchaseCarSafeSuccess(data) {
  emitMessage({
    actionType: ACTION_TYPES.PURCHASE_CARSAFE_SUCCESS,
  });
  return {
    type: ACTION_TYPES.PURCHASE_CARSAFE_SUCCESS,
    payload: data,
  };
}

function purchaseCarSafeError(error) {
  emitMessage({
    actionType: ACTION_TYPES.PURCHASE_CARSAFE_FAILURE,
  });
  return {
    type: ACTION_TYPES.PURCHASE_CARSAFE_FAILURE,
    error,
  };
}

export function purchaseCarSafe({ cardElement, cardName }) {
  return (dispatch, getState) => {
    const {
      authToken,
      carSafeSelectedPlanId,
      carSafeCheckout,
      carSafeDetails,
      metadata,
    } = getState();
    const revenue = carSafeCheckout.totals.grandtotal;

    const opts = {
      firstName: carSafeDetails.firstName,
      lastName: carSafeDetails.lastName,
      email: carSafeDetails.email,
      phone: carSafeDetails.phone,
      policyholderId: carSafeDetails.policyholderId,
      hasBeneficiary: carSafeDetails.hasBeneficiary,
      beneficiaryFirstName: carSafeDetails.beneficiaryFirstName,
      beneficiaryLastName: carSafeDetails.beneficiaryLastName,
      beneficiaryEmail: carSafeDetails.beneficiaryEmail,
      beneficiaryPhone: carSafeDetails.beneficiaryPhone,
      beneficiaryRelationship: carSafeDetails.beneficiaryRelationship,
      beneficiaryDob: carSafeDetails.beneficiaryDob,
      beneficiaryId: carSafeDetails.beneficiaryId,
      streetAddress: carSafeDetails.streetAddress,
      unit: carSafeDetails.unit,
      city: carSafeDetails.city,
      region: 'ZA' || carSafeDetails.region,
      postal: carSafeDetails.postal,
      country: carSafeDetails.country,
      planId: carSafeSelectedPlanId,
      authToken,
      revenue,
      cardElement,
      cardName,
      metadata,
    };

    dispatch(purchaseCarSafeRequest());

    return new Promise((resolve, reject) => {
      api
        .purchaseCarSafe(opts)
        .then(purchaseData => {
          dispatch(purchaseCarSafeSuccess(purchaseData));
          resolve();
        })
        .catch(error => {
          dispatch(purchaseCarSafeError(error));
          reject(error);
        });
    });
  };
}
