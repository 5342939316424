import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import seo from 'shared/lib/seo';
import find from 'lodash/find';
import {
  getBeneficiaryRelationships,
  getCarSafeCheckout,
  getCarSafePlans,
  purchaseCarSafe,
  selectCarSafePlan,
  updateCarSafeDetails,
} from '../../store/actions';
import { getDisclaimer } from 'shared/lib/utils';
import Helmet from 'react-helmet';
import withGlobals from '../../withGlobals';
import favicon from './images/favicon-chubb.ico';
import vehicleImg from './images/chubb-claims-block-vehicle.png';
import './CarSafeContainer.css';

class CarSafeContainer extends PureComponent {
  componentDidMount() {
    seo.setProps({
      title: `Chubb Vehicle Contents Insurance`,
      description:
        'Chubb Vehicle Contents Insurance provides you with cover in the event of theft and accidental death and disability as a result of a motor vehicle accident.',
      url: `https://${window.location.host}/vehicle-contents-insurance`,
      favicon,
      image: vehicleImg,
    });
  }

  render() {
    const { children, ...otherProps } = this.props;
    return (
      <div className="CarSafeContainer ChubbTheme">
        {React.cloneElement(children, otherProps)}
        {process.env.REACT_APP_ENV === 'production' && (
          <>
            <Helmet>
              <script>{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WR9BDK5');
            `}</script>
            </Helmet>
            <noscript>{`
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WR9BDK5" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `}</noscript>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    beneficiaryRelationships: state.beneficiaryRelationships,
    carSafePlans: state.carSafePlans,
    carSafeCheckout: state.carSafeCheckout,
    carSafeDetails: state.carSafeDetails,
    carSafeSettings: state.carSafeSettings,
    carSafeSelectedPlanId: state.carSafeSelectedPlanId,
    carSafeSelectedPlan: find(state.carSafePlans, {
      plan_id: state.carSafeSelectedPlanId,
    }),
    carSafePurchaseInfo: state.carSafePurchaseInfo,
    disclaimer: getDisclaimer(
      state.carSafePlans,
      'Chubb Insurance South Africa Limited'
    ),
    isCarSafeInProgress: state.isCarSafeInProgress,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBeneficiaryRelationships,
      getCarSafeCheckout,
      getCarSafePlans,
      purchaseCarSafe,
      selectCarSafePlan,
      updateCarSafeDetails,
    },
    dispatch
  );

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'CarSafe',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(CarSafeContainer);
