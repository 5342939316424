'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersPolicyStartDate/RentersPolicyStartDate.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _sureComponents = require('sure-components');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

require('./RentersPolicyStartDate.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  calculateRentersQuote: _propTypes2.default.func,
  plan: _propTypes2.default.object,
  policyStartDate: _propTypes2.default.object,
  updateRentersStartDate: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  calculateRentersQuote: function calculateRentersQuote() {},
  plan: {},
  updateRentersStartDate: function updateRentersStartDate() {}
};

var RentersPolicyStartDate = function (_PureComponent) {
  _inherits(RentersPolicyStartDate, _PureComponent);

  function RentersPolicyStartDate(props) {
    _classCallCheck(this, RentersPolicyStartDate);

    var _this = _possibleConstructorReturn(this, (RentersPolicyStartDate.__proto__ || Object.getPrototypeOf(RentersPolicyStartDate)).call(this, props));

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      var _this$props = _this.props,
          calculateRentersQuote = _this$props.calculateRentersQuote,
          getRentersPaymentCadences = _this$props.getRentersPaymentCadences,
          rentersSelectedPaymentCadence = _this$props.rentersSelectedPaymentCadence,
          selectRentersPaymentCadence = _this$props.selectRentersPaymentCadence,
          navTo = _this$props.navTo,
          onError = _this$props.onError;
      if (!rentersSelectedPaymentCadence) selectRentersPaymentCadence('eleven_pay');
      calculateRentersQuote().then(function () {
        getRentersPaymentCadences().then(function () {
          navTo((0, _utils.resolveUrlPath)('quote'));
        }).catch(onError);
      }).catch(onError);
    };

    _this.handleDatePickerFocus = function (_ref) {
      var focused = _ref.focused;

      _this.setState({
        isDatePickerOpen: focused
      });
    };

    _this.handleUpdateStartDate = function (momentObj) {
      if (momentObj && !momentObj.isSame(_this.props.policyStartDate)) {
        var label = momentObj.isAfter(_this.props.policyStartDate) ? 'later' : 'earlier';
        analytics.track('Renters - Edit Start Date', {
          category: 'quote',
          label: label,
          value: momentObj.diff(_this.props.policyStartDate, 'days') || _this.props.policyStartDate.diff(momentObj, 'days')
        });

        _this.props.updateRentersStartDate(momentObj);
      }
    };

    _this.state = {
      isDatePickerOpen: false
    };
    _this.minStartDate = (0, _momentTimezone2.default)().add(1, 'day');
    _this.maxStartDate = (0, _momentTimezone2.default)().add(90, 'days');
    _this.isMobile = window.innerWidth < 768;
    return _this;
  }

  _createClass(RentersPolicyStartDate, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Renters - Policy Start Date', {
        category: 'quote'
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'RentersPolicyStartDate',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 92
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 93
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentersPolicyStartDate-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 94
        },
        __self: this
      }, 'When should this policy take effect?'), _react2.default.createElement('form', {
        className: 'RentersPolicyStartDate-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 97
        },
        __self: this
      }, _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, 'Start Date'), _react2.default.createElement(_reactDates.SingleDatePicker, {
        date: _momentTimezone2.default.utc(this.props.policyStartDate),
        focused: this.state.isDatePickerOpen,
        required: true,
        block: true,
        displayFormat: 'MM/DD/YYYY',
        isOutsideRange: function isOutsideRange(day) {
          return day.isBefore(_this2.minStartDate, 'day') || day.isAfter(_this2.maxStartDate, 'day');
        },
        numberOfMonths: 1,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        onDateChange: this.handleUpdateStartDate,
        onFocusChange: this.handleDatePickerFocus,
        hideKeyboardShortcutsPanel: true,
        id: 'policy_effective_date',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 103
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 121
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 123
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 124
        },
        __self: this
      })));
    }
  }]);

  return RentersPolicyStartDate;
}(_react.PureComponent);

RentersPolicyStartDate.propTypes = propTypes;
RentersPolicyStartDate.defaultProps = defaultProps;
exports.default = RentersPolicyStartDate;