'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersQuoteDetails/RentersQuoteDetails.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _sureComponents = require('sure-components');

var _RentersQuoteOverviewAssurant = require('../RentersQuoteOverviewAssurant');

var _RentersQuoteOverviewAssurant2 = _interopRequireDefault(_RentersQuoteOverviewAssurant);

var _AddressSection = require('shared/lib/components/AddressSection');

var _AddressSection2 = _interopRequireDefault(_AddressSection);

var _QuoteLayout = require('shared/lib/components/QuoteLayout');

var _QuoteLayout2 = _interopRequireDefault(_QuoteLayout);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _utils = require('shared/lib/utils');

require('./RentersQuoteDetails.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var RentersQuoteDetails = function (_Component) {
  _inherits(RentersQuoteDetails, _Component);

  function RentersQuoteDetails() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, RentersQuoteDetails);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentersQuoteDetails.__proto__ || Object.getPrototypeOf(RentersQuoteDetails)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function () {
      var navTo = _this.props.navTo;
      analytics.track('Checkout Step Completed', {
        category: 'order',
        step: 2
      });
      navTo((0, _utils.resolveUrlPath)('payment'));
    }, _this.handleUpdateRentersPaymentCadence = function (e) {
      var _this$props = _this.props,
          onError = _this$props.onError,
          calculateRentersQuote = _this$props.calculateRentersQuote,
          selectRentersPaymentCadence = _this$props.selectRentersPaymentCadence;
      selectRentersPaymentCadence(e.target.value);
      calculateRentersQuote().catch(onError);
    }, _this.handleUpdateSelectedAmount = function (data) {
      var _this$props2 = _this.props,
          onError = _this$props2.onError,
          calculateRentersQuote = _this$props2.calculateRentersQuote,
          updateRentersSelectedAmounts = _this$props2.updateRentersSelectedAmounts;
      updateRentersSelectedAmounts(data);
      calculateRentersQuote().catch(onError);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentersQuoteDetails, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          plan = _props.plan,
          totals = _props.totals;
      analytics.page('Renters - Quote Overview', {
        category: 'quote',
        label: plan.id,
        value: Math.round(totals.grandtotal)
      });
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 1
      });
      analytics.track('AddToCart');
      analytics.track('Renters - Quote Overview Viewed');
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          disclaimer = _props2.disclaimer,
          fullName = _props2.fullName,
          goBack = _props2.goBack,
          isRentersInProgress = _props2.isRentersInProgress,
          plan = _props2.plan,
          policyStartDate = _props2.policyStartDate,
          propertyAddress = _props2.propertyAddress,
          rates = _props2.rates,
          rentersPaymentCadences = _props2.rentersPaymentCadences,
          rentersQuote = _props2.rentersQuote,
          rentersSelectedPaymentCadence = _props2.rentersSelectedPaymentCadence,
          selectedAmounts = _props2.selectedAmounts,
          updateRentersStartDate = _props2.updateRentersStartDate;
      return _react2.default.createElement('div', {
        className: 'RentersQuoteDetails Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }, _react2.default.createElement(_QuoteLayout2.default, {
        title: 'Your Renters Quote',
        policyDocUrl: plan.policy_doc_url,
        icon: 'location_city',
        billingCycle: rentersSelectedPaymentCadence === 'eleven_pay' ? 'month' : 'year',
        onUpdatePaymentCadence: this.handleUpdateRentersPaymentCadence,
        selectedPaymentCadence: rentersSelectedPaymentCadence,
        paymentCadences: rentersPaymentCadences,
        policyStartDate: policyStartDate,
        quote: rentersQuote,
        disclaimer: disclaimer,
        totals: this.quoteTotals,
        hideBillingSummary: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 91
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Property Address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 107
        },
        __self: this
      }, _react2.default.createElement(_AddressSection2.default, {
        fullName: fullName,
        address: propertyAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      })), _react2.default.createElement(_RentersQuoteOverviewAssurant2.default, {
        fullName: fullName,
        address: propertyAddress,
        plan: plan,
        policyStartDate: policyStartDate,
        rates: rates,
        selectedAmounts: selectedAmounts,
        onUpdateStartDate: updateRentersStartDate.bind(this),
        onUpdateSelectedAmount: this.handleUpdateSelectedAmount,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 110
        },
        __self: this
      }), _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 120
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.PagerButtons, {
        onBack: goBack,
        onNext: this.handleNext,
        isInProgress: isRentersInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 121
        },
        __self: this
      })));
    }
  }, {
    key: 'quoteTotals',
    get: function get() {
      var _props3 = this.props,
          rentersQuote = _props3.rentersQuote,
          totals = _props3.totals;
      var paymentScheduleTotal = (0, _get3.default)(rentersQuote, 'paymentSchedule.total_amount');

      if (paymentScheduleTotal) {
        return {
          grandtotal: paymentScheduleTotal
        };
      } else {
        return totals;
      }
    }
  }]);

  return RentersQuoteDetails;
}(_react.Component);

exports.default = RentersQuoteDetails;