'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/components/PlanDisclaimer/PlanDisclaimer.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _logoGray = require('./images/logo-gray.svg');

var _logoGray2 = _interopRequireDefault(_logoGray);

require('./PlanDisclaimer.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  className: _propTypes2.default.string,
  text: _propTypes2.default.string,
  showBuiltOnLogo: _propTypes2.default.bool,
  style: _propTypes2.default.object,
  lineBreakOnSentence: _propTypes2.default.bool
};
var defaultProps = {
  className: null,
  text: null,
  showBuiltOnLogo: false,
  style: null,
  lineBreakOnSentence: false
};

var PlanDisclaimer = function (_PureComponent) {
  _inherits(PlanDisclaimer, _PureComponent);

  function PlanDisclaimer() {
    _classCallCheck(this, PlanDisclaimer);

    return _possibleConstructorReturn(this, (PlanDisclaimer.__proto__ || Object.getPrototypeOf(PlanDisclaimer)).apply(this, arguments));
  }

  _createClass(PlanDisclaimer, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          lineBreakOnSentence = _props.lineBreakOnSentence,
          showBuiltOnLogo = _props.showBuiltOnLogo,
          text = _props.text,
          otherProps = _objectWithoutProperties(_props, ['className', 'lineBreakOnSentence', 'showBuiltOnLogo', 'text']);

      return text ? _react2.default.createElement('div', _extends({
        className: (0, _classnames2.default)('PlanDisclaimer', className)
      }, otherProps, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 38
        },
        __self: this
      }), _react2.default.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: lineBreakOnSentence ? this.renderTextWithLineBreaks : text
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 39
        },
        __self: this
      }), showBuiltOnLogo && _react2.default.createElement('a', {
        className: 'PlanDisclaimer-builtOnSureLink',
        href: 'https://www.sureapp.com',
        target: '_blank',
        rel: 'noopener noreferrer',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 45
        },
        __self: this
      }, 'Built on', ' ', _react2.default.createElement('img', {
        className: 'PlanDisclaimer-builtOnSureLink-logo',
        src: _logoGray2.default,
        alt: 'Sure logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 52
        },
        __self: this
      }))) : null;
    }
  }, {
    key: 'renderTextWithLineBreaks',
    get: function get() {
      // XXX: find all periods followed by a capital letter and add a line break after the period
      return this.props.text.replace(/(\.(\s+))/g, '$1 <br />');
    }
  }]);

  return PlanDisclaimer;
}(_react.PureComponent);

PlanDisclaimer.propTypes = propTypes;
PlanDisclaimer.defaultProps = defaultProps;
exports.default = PlanDisclaimer;