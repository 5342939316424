'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/BillingSummarySection/BillingSummarySection.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pickBy = require('lodash/pickBy');

var _pickBy2 = _interopRequireDefault(_pickBy);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _SectionWrapper = require('../SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _LineItemsList = require('../LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _currencyFormatter = require('currency-formatter');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  currencyCode: _propTypes2.default.string,
  billingCycle: _propTypes2.default.string,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  pricePerCycle: _propTypes2.default.number,
  totals: _propTypes2.default.object
};
var defaultProps = {
  currencyCode: 'USD',
  billingCycle: null,
  pricePerCycle: null,
  totals: {}
};

var BillingSummarySection = function (_PureComponent) {
  _inherits(BillingSummarySection, _PureComponent);

  function BillingSummarySection() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BillingSummarySection);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BillingSummarySection.__proto__ || Object.getPrototypeOf(BillingSummarySection)).call.apply(_ref, [this].concat(args))), _this), _this.totalsOrder = ['subtotal', 'discount', 'tax'], _this.totalsLabelMap = {
      subtotal: 'Subtotal',
      discount: 'Discount',
      tax: 'Tax'
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BillingSummarySection, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Billing details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: this.billingLineItems,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }));
    }
  }, {
    key: 'billingLineItems',
    get: function get() {
      var _this2 = this;

      var _props = this.props,
          billingCycle = _props.billingCycle,
          policyStartDate = _props.policyStartDate,
          pricePerCycle = _props.pricePerCycle,
          currencyCode = _props.currencyCode,
          totals = _props.totals;
      var nonEmptyTotals = (0, _pickBy2.default)(this.props.totals);
      var itemsArr = [];

      switch (billingCycle) {
        case 'year':
          itemsArr.push(['Billing cycle', 'Annual']);

          if (policyStartDate) {
            itemsArr.push(['Auto-renews on', policyStartDate.clone().add(1, 'year').format('MMM DD, YYYY')]);
          }

          break;

        case 'month':
          itemsArr.push(['Billing cycle', 'Monthly']);
          break;

        default:
          break;
      }

      (0, _each2.default)(this.totalsOrder, function (key) {
        var formattedTotal = (0, _currencyFormatter.format)(nonEmptyTotals[key], {
          code: _this2.props.currencyCode
        });

        if (nonEmptyTotals[key]) {
          itemsArr.push([_this2.totalsLabelMap[key], formattedTotal]);
        }
      });
      var grandTotal = (0, _currencyFormatter.format)(totals.grandtotal, {
        code: currencyCode
      });

      if (billingCycle && (!pricePerCycle || pricePerCycle === totals.grandtotal)) {
        grandTotal = grandTotal + '/' + billingCycle;
      }

      itemsArr.push([_react2.default.createElement('strong', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 82
        },
        __self: this
      }, 'Total due'), _react2.default.createElement('strong', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 82
        },
        __self: this
      }, grandTotal)]);
      return itemsArr;
    }
  }]);

  return BillingSummarySection;
}(_react.PureComponent);

BillingSummarySection.propTypes = propTypes;
BillingSummarySection.defaultProps = defaultProps;
exports.default = BillingSummarySection;