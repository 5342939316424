'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/BopApp/views/BopPolicyStartDateView/BopPolicyStartDateView.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _sureComponents = require('sure-components');

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

require('./BopPolicyStartDateView.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  getRates: _propTypes2.default.func,
  plan: _propTypes2.default.object,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  updateStartDate: _propTypes2.default.func,
  disclaimer: _propTypes2.default.string,
  isInProgress: _propTypes2.default.boolean
};
var defaultProps = {
  disclaimer: null,
  isInProgress: false
};

var BopPolicyStartDateView = function (_PureComponent) {
  _inherits(BopPolicyStartDateView, _PureComponent);

  function BopPolicyStartDateView(props) {
    _classCallCheck(this, BopPolicyStartDateView);

    var _this = _possibleConstructorReturn(this, (BopPolicyStartDateView.__proto__ || Object.getPrototypeOf(BopPolicyStartDateView)).call(this, props));

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      _this.props.getRates().then(function () {
        _this.props.navTo((0, _utils.resolveUrlPath)('plans'));
      }).catch(_this.props.onError);
    };

    _this.handleDatePickerFocus = function (_ref) {
      var focused = _ref.focused;

      _this.setState({
        isDatePickerOpen: focused
      });
    };

    _this.handleUpdateStartDate = function (momentObj) {
      if (momentObj && !momentObj.isSame(_this.props.policyStartDate)) {
        analytics.track('BOP - Edit Start Date');

        _this.props.updateStartDate(momentObj);
      }
    };

    _this.minStartDate = (0, _momentTimezone2.default)();
    _this.maxStartDate = (0, _momentTimezone2.default)().add(60, 'days');
    _this.isMobile = window.innerWidth < 768;
    _this.state = {
      isDatePickerOpen: false
    };
    return _this;
  }

  _createClass(BopPolicyStartDateView, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('BOP - Policy Start Date');
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'BopPolicyStartDateView',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 69
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 70
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'BopPolicyStartDateView-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 71
        },
        __self: this
      }, 'When should this policy take effect?'), _react2.default.createElement('form', {
        className: 'BopPolicyStartDateView-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 74
        },
        __self: this
      }, _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 79
        },
        __self: this
      }, 'Start Date'), _react2.default.createElement(_reactDates.SingleDatePicker, {
        date: this.props.policyStartDate,
        focused: this.state.isDatePickerOpen,
        numberOfMonths: 1,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        required: true,
        block: true,
        displayFormat: 'MM/DD/YYYY',
        isOutsideRange: function isOutsideRange(day) {
          return day.startOf('day').isBefore(_this2.minStartDate, 'day') || day.startOf('day').isAfter(_this2.maxStartDate);
        },
        onDateChange: this.handleUpdateStartDate,
        onFocusChange: this.handleDatePickerFocus,
        hideKeyboardShortcutsPanel: true,
        id: 'policy_effective_date',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 100
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 101
        },
        __self: this
      }), _react2.default.createElement(_ModalSpinner2.default, {
        message: 'Calculating your quote. This may take up to 15 seconds.',
        isActive: this.props.isInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      })));
    }
  }]);

  return BopPolicyStartDateView;
}(_react.PureComponent);

BopPolicyStartDateView.propTypes = propTypes;
BopPolicyStartDateView.defaultProps = defaultProps;
exports.default = BopPolicyStartDateView;