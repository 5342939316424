'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _head = require('lodash/head');

var _head2 = _interopRequireDefault(_head);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _isEqual = require('lodash/isEqual');

var _isEqual2 = _interopRequireDefault(_isEqual);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

var _startCase = require('lodash/startCase');

var _startCase2 = _interopRequireDefault(_startCase);

var _values = require('lodash/values');

var _values2 = _interopRequireDefault(_values);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _utils = require('shared/lib/utils');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _verticals = require('shared/lib/constants/verticals');

var _verticals2 = _interopRequireDefault(_verticals);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

exports.default = function (api) {
  /**
   * Create Collectibles quote
   */
  function createCollectiblesQuoteRequest() {
    return {
      type: _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE
    };
  }

  function createCollectiblesQuoteSuccess(quote) {
    return {
      type: _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function createCollectiblesQuoteFailure(error) {
    return {
      type: _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE_FAILURE,
      error: error
    };
  }

  function createCollectiblesQuote() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState = getState(),
            collectiblesPropertyAddress = _getState.collectiblesPropertyAddress,
            collectiblesResponses = _getState.collectiblesResponses,
            settings = _getState.settings;

        dispatch(createCollectiblesQuoteRequest());
        api.createQuote({
          applicationUpdates: {
            holders: [{
              first_name: collectiblesResponses.owner_first_name,
              last_name: collectiblesResponses.owner_last_name,
              email: collectiblesResponses.owner_email,
              phone_number: collectiblesResponses.owner_phone_number
            }],
            rating_address: {
              line1: collectiblesPropertyAddress.streetAddress,
              line2: collectiblesPropertyAddress.unit,
              city: collectiblesPropertyAddress.city,
              postal: collectiblesPropertyAddress.postal,
              state_or_region: collectiblesPropertyAddress.region,
              country_code: collectiblesPropertyAddress.country
            }
          },
          lat: collectiblesPropertyAddress.lat,
          lng: collectiblesPropertyAddress.lng,
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        }).then(function (quote) {
          dispatch(createCollectiblesQuoteSuccess(quote));
          resolve(quote);
        }).catch(function (error) {
          dispatch(createCollectiblesQuoteFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Get plans
   * ========================================
   */


  function getCollectiblesPlansRequest(quoteId) {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_PLANS,
      payload: {
        quoteId: quoteId
      }
    };
  }

  function getCollectiblesPlansSuccess(plans, category) {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS,
      data: plans,
      category: category
    };
  }

  function getCollectiblesPlansFailure(error) {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_PLANS_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function getCollectiblesPlans(quoteId) {
    return function (dispatch, getState) {
      var _getState2 = getState(),
          collectiblesPropertyAddress = _getState2.collectiblesPropertyAddress,
          collectiblesSelectedCategory = _getState2.collectiblesSelectedCategory,
          collectiblesQuote = _getState2.collectiblesQuote,
          settings = _getState2.settings;

      var opts = {
        quoteId: collectiblesQuote.quote_id,
        lat: collectiblesPropertyAddress.lat,
        lng: collectiblesPropertyAddress.lng,
        verticalCode: _verticals2.default.collectibles.code,
        apiBaseUrl: settings.apiBaseUrl,
        categoryCode: collectiblesSelectedCategory
      };
      dispatch(getCollectiblesPlansRequest(quoteId));
      return new Promise(function (resolve, reject) {
        api.getQuotePlans(opts).then(function (plans) {
          dispatch(getCollectiblesPlansSuccess(plans, collectiblesSelectedCategory));
          resolve(plans);
        }).catch(function (error) {
          dispatch(getCollectiblesPlansFailure(error));
          reject(error);
        });
      });
    };
  }
  /**
   *  Update address
   */


  function updateCollectiblesAddress(address) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_ADDRESS,
      address: address
    };
  }

  function updateCollectiblesAddressContext(addressToUpdate, updatedAddressContext) {
    return function (dispatch, getState) {
      var _getState3 = getState(),
          collectiblesEditQuoteModalContext = _getState3.collectiblesEditQuoteModalContext,
          collectiblesAddressContext = _getState3.collectiblesAddressContext;

      var oldAddressContext = (0, _find2.default)(collectiblesAddressContext, {
        address: (0, _omit2.default)(addressToUpdate, 'lat', 'lng')
      });
      var isUpdatingAddress = updatedAddressContext.address !== oldAddressContext.address && (0, _isEqual2.default)((0, _omit2.default)(updatedAddressContext, ['address']), (0, _omit2.default)(oldAddressContext, ['address']));
      dispatch({
        type: _actionTypes2.default.UPDATE_COLLECTIBLES_ADDRESS_CONTEXT,
        addressToUpdate: addressToUpdate,
        updatedAddressContext: updatedAddressContext,
        collectionId: collectiblesEditQuoteModalContext.collectionId,
        isUpdatingAddress: isUpdatingAddress,
        getState: getState
      });
    };
  }
  /**
   *  Update collection category type
   */


  function updateCollectiblesCategoryType(categoryCode) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_CATEGORY_TYPE,
      categoryCode: categoryCode
    };
  }
  /**
   *  Exclusion Notice
   */


  function showCollectiblesNotificationBar(notificationMessage, collectiblesPropertyAddress) {
    return {
      type: _actionTypes2.default.SHOW_COLLECTIBLES_NOTIFICATION_BAR,
      notificationMessage: notificationMessage,
      collectiblesPropertyAddress: collectiblesPropertyAddress
    };
  }

  function hideCollectiblesNotificationBar() {
    return {
      type: _actionTypes2.default.HIDE_COLLECTIBLES_NOTIFICATION_BAR
    };
  }
  /**
   *  Survey
   */


  function collectiblesSurveyNext() {
    return {
      type: _actionTypes2.default.COLLECTIBLES_SURVEY_NEXT
    };
  }

  function selectCollectiblesChoiceByFieldAndValue(field, value, dispatch) {
    var choice = (0, _find2.default)(field.settings.choices, {
      value: value
    });

    if (choice && choice.screen_id) {
      dispatch({
        type: _actionTypes2.default.SELECT_COLLECTIBLES_CHOICE,
        screen_id: choice.screen_id
      });
    }
  }

  function updateCollectiblesCurrentResponse(response) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_CURRENT_RESPONSE,
      payload: {
        response: response
      }
    };
  }

  function saveCollectiblesResponse(response) {
    return function (dispatch, getState) {
      var _getState4 = getState(),
          collectiblesScreens = _getState4.collectiblesScreens,
          collectiblesCurrentScreenId = _getState4.collectiblesCurrentScreenId,
          collectiblesSelectedCategory = _getState4.collectiblesSelectedCategory,
          collectiblesPropertyAddress = _getState4.collectiblesPropertyAddress;

      var screen = (0, _get2.default)(collectiblesScreens[collectiblesSelectedCategory], collectiblesCurrentScreenId);
      var fieldsType = (0, _utils.getFieldsType)((0, _get2.default)(screen, 'fields'));

      if (fieldsType === _utils.FIELDS_TYPES.SINGLE_SELECT || fieldsType === _utils.FIELDS_TYPES.MULTI_SELECT) {
        var field = (0, _head2.default)(screen.fields);
        (0, _each2.default)(response, function (value, key) {
          if ((0, _isArray2.default)(value)) {
            (0, _each2.default)(value, function (_value) {
              selectCollectiblesChoiceByFieldAndValue(field, _value, dispatch);
            });
          } else {
            selectCollectiblesChoiceByFieldAndValue(field, value, dispatch);
          }
        });
      }

      if (screen && screen.fields[0].key_name === 'has_no_mailing_address' && response.has_no_mailing_address) {
        response = Object.assign({}, response, {
          mailing_address_line1: collectiblesPropertyAddress.streetAddress,
          mailing_address_line2: collectiblesPropertyAddress.unit,
          mailing_address_city: collectiblesPropertyAddress.city,
          mailing_address_state_or_region: collectiblesPropertyAddress.region,
          mailing_address_postal: collectiblesPropertyAddress.postal,
          mailing_address_country: collectiblesPropertyAddress.country
        });
      }

      dispatch({
        type: _actionTypes2.default.SAVE_COLLECTIBLES_RESPONSE,
        response: response,
        collectiblesPropertyAddress: collectiblesPropertyAddress
      });
      return Promise.resolve();
    };
  }

  function deleteCollectiblesResponse() {
    var responseKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      type: _actionTypes2.default.DELETE_COLLECTIBLES_RESPONSE,
      payload: {
        responseKeys: responseKeys
      }
    };
  }

  function surveyCollectiblesBack() {
    return {
      type: _actionTypes2.default.COLLECTIBLES_SURVEY_BACK
    };
  }

  function updateCollectiblesSelectedAmounts(data) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_SELECTED_AMOUNTS,
      data: data
    };
  }

  function updateCollectionDetails(collectionId, detailsToUpdate) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTION_DETAILS,
      collectionId: collectionId,
      detailsToUpdate: detailsToUpdate
    };
  }
  /*
   * Update collectibles plan details
   * ========================================
   */


  function updateCollectiblesPlanDetailsRequest() {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS
    };
  }

  function updateCollectiblesPlanDetailsSuccess(quote) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function updateCollectiblesPlanDetailsFailure(error) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS_FAILURE,
      error: error
    };
  }

  function updateCollectiblesPlanDetails() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState5 = getState(),
            collectiblesPlan = _getState5.collectiblesPlan,
            collectiblesPolicyContext = _getState5.collectiblesPolicyContext,
            collectiblesPolicyStartDate = _getState5.collectiblesPolicyStartDate,
            collectiblesQuote = _getState5.collectiblesQuote,
            collectiblesSelectedAmounts = _getState5.collectiblesSelectedAmounts,
            settings = _getState5.settings;

        var ownershipDetails = function ownershipDetails() {
          var ownershipField = {
            ownership_type: collectiblesPolicyContext.ownership_type
          };

          switch (collectiblesPolicyContext.ownership_type) {
            case 'trust':
              return Object.assign({}, ownershipField, {
                trust_name: collectiblesPolicyContext.trust_name
              });

            case 'foundation':
              return Object.assign({}, ownershipField, {
                owner_foundation: collectiblesPolicyContext.owner_foundation
              });

            case 'executor':
            case 'jointly_owned':
              return Object.assign({}, ownershipField, {
                owners: collectiblesPolicyContext.owners
              });

            case 'musical_group':
              return Object.assign({}, ownershipField, {
                musical_group_name: collectiblesPolicyContext.musical_group_name
              });

            default:
              return ownershipField;
          }
        };

        var planDetails = Object.assign({}, ownershipDetails(), {
          has_no_mailing_address: collectiblesPolicyContext.has_no_mailing_address,
          reference_id: collectiblesQuote.application.plan_applications[0].reference_id,
          coverage_period_ended: collectiblesQuote.application.plan_applications[0].coverage_period_ended,
          coverage_period_started: collectiblesQuote.application.plan_applications[0].coverage_period_started,
          plan_id: collectiblesPlan.plan_id,
          plan_deductible: collectiblesSelectedAmounts.deductible,
          policy_effective_date: (0, _momentTimezone2.default)(collectiblesPolicyStartDate).format('YYYY-MM-DD'),
          mailing_address: {
            line1: collectiblesPolicyContext.mailing_address_line1,
            line2: collectiblesPolicyContext.mailing_address_line2,
            city: collectiblesPolicyContext.mailing_address_city,
            state_or_region: collectiblesPolicyContext.mailing_address_state_or_region,
            postal: collectiblesPolicyContext.mailing_address_postal,
            country_code: collectiblesPolicyContext.mailing_address_country
          }
        });
        dispatch(updateCollectiblesPlanDetailsRequest());
        api.updateQuote({
          quoteId: collectiblesQuote.quote_id,
          application: collectiblesQuote.application,
          applicationUpdates: {
            plan_applications: [Object.assign({}, planDetails, {
              collections: collectiblesQuote.application.plan_applications[0].collections
            })]
          },
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        }).then(function (quote) {
          dispatch(updateCollectiblesPlanDetailsSuccess(quote));
          resolve();
        }).catch(function (error) {
          dispatch(updateCollectiblesPlanDetailsFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Edit collection (via back button, not modal)
   * ========================================
   */


  function editCollection(collectionId) {
    return {
      type: _actionTypes2.default.EDIT_COLLECTION,
      collectionId: collectionId
    };
  }
  /*
   * Update existing collections in quote
   * ========================================
   */


  function updateCollectionsInQuoteRequest() {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE
    };
  }

  function updateCollectionsInQuoteSuccess(quote) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function updateCollectionsInQuoteFailure(error, collectiblesQuote) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_FAILURE,
      error: error,
      collectiblesQuote: collectiblesQuote
    };
  }

  function updateCollectionsInQuote() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState6 = getState(),
            collectiblesCollectionsByCollectionId = _getState6.collectiblesCollectionsByCollectionId,
            collectiblesAddressContext = _getState6.collectiblesAddressContext,
            collectiblesQuote = _getState6.collectiblesQuote,
            collectiblesPropertyAddress = _getState6.collectiblesPropertyAddress,
            collectiblesSelectedCategory = _getState6.collectiblesSelectedCategory,
            collectiblesResponses = _getState6.collectiblesResponses,
            collectiblesPolicyContext = _getState6.collectiblesPolicyContext,
            isEditingCollection = _getState6.isEditingCollection,
            collectionIdToEdit = _getState6.collectionIdToEdit,
            settings = _getState6.settings;

        var policyDetailsInCollection = (0, _pick2.default)(collectiblesPolicyContext, ['claims_count', 'declined_insurance_type', 'filed_bankruptcy', 'is_felon']);
        var addressContext = (0, _pick2.default)((0, _find2.default)(collectiblesAddressContext, {
          address: (0, _omit2.default)(collectiblesPropertyAddress, ['lat', 'lng'])
        }), ['has_burglar_alarm', 'has_fire_alarm']);
        var addressContextInCollection = (0, _includes2.default)(['jewelry', 'firearms'], collectiblesSelectedCategory) ? {
          has_burglar_alarm: addressContext.has_burglar_alarm
        } : {
          has_burglar_alarm: addressContext.has_burglar_alarm,
          has_fire_alarm: addressContext.has_fire_alarm
        };
        var updatedCollection = isEditingCollection ? Object.assign({
          address: {
            city: collectiblesPropertyAddress.city,
            country_code: collectiblesPropertyAddress.country,
            line1: collectiblesPropertyAddress.streetAddress,
            line2: collectiblesPropertyAddress.unit,
            postal: collectiblesPropertyAddress.postal,
            state_or_region: collectiblesPropertyAddress.region
          }
        }, policyDetailsInCollection, addressContextInCollection, {
          category_code: collectiblesSelectedCategory,
          collection_total_value: collectiblesResponses.collection_total_value,
          has_personal_safe: !!collectiblesResponses.has_personal_safe,
          has_ul_rate_vault: !!collectiblesResponses.has_ul_rate_vault,
          has_ul_rated_safe: !!collectiblesResponses.has_ul_rated_safe,
          highest_value_item: collectiblesResponses.highest_value_item,
          reference_id: collectionIdToEdit
        }) : null;
        dispatch(updateCollectionsInQuoteRequest());
        api.updateQuote({
          quoteId: collectiblesQuote.quote_id,
          application: collectiblesQuote.application,
          applicationUpdates: {
            plan_applications: [Object.assign({}, collectiblesQuote.application.plan_applications[0], {
              collections: (0, _compact2.default)([].concat(_toConsumableArray((0, _values2.default)((0, _omit2.default)(collectiblesCollectionsByCollectionId, collectionIdToEdit)).map(function (collection) {
                var groomedAddress = {
                  streetAddress: collection.address.line1,
                  unit: collection.address.line2,
                  city: collection.address.city,
                  region: collection.address.state_or_region,
                  country: collection.address.country_code,
                  postal: collection.address.postal
                };
                var addressContext = (0, _find2.default)(collectiblesAddressContext, {
                  address: groomedAddress
                });
                var addressContextInCollection = (0, _includes2.default)(['jewelry', 'firearms'], collection.category_code) ? {
                  has_burglar_alarm: addressContext.has_burglar_alarm
                } : {
                  has_burglar_alarm: addressContext.has_burglar_alarm,
                  has_fire_alarm: addressContext.has_fire_alarm
                };
                return Object.assign({}, collection, addressContextInCollection);
              })), [updatedCollection]))
            })]
          },
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        }).then(function (quote) {
          dispatch(updateCollectionsInQuoteSuccess(quote));
          resolve();
        }).catch(function (error) {
          dispatch(updateCollectionsInQuoteFailure(error, collectiblesQuote));
          reject(error);
        });
      });
    };
  }
  /*
   * Add collection to quote
   * ========================================
   */


  function addCollectionToQuoteRequest() {
    return {
      type: _actionTypes2.default.ADD_COLLECTION_TO_QUOTE
    };
  }

  function addCollectionToQuoteSuccess(quote) {
    return {
      type: _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function addCollectionToQuoteFailure(error) {
    return {
      type: _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_FAILURE,
      error: error
    };
  }

  function addCollectionToQuote() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState7 = getState(),
            collectiblesAddressContext = _getState7.collectiblesAddressContext,
            collectiblesCollectionsByCollectionId = _getState7.collectiblesCollectionsByCollectionId,
            collectiblesPlan = _getState7.collectiblesPlan,
            collectiblesPolicyContext = _getState7.collectiblesPolicyContext,
            collectiblesPolicyStartDate = _getState7.collectiblesPolicyStartDate,
            collectiblesPropertyAddress = _getState7.collectiblesPropertyAddress,
            collectiblesQuote = _getState7.collectiblesQuote,
            collectiblesResponses = _getState7.collectiblesResponses,
            collectiblesSelectedAmounts = _getState7.collectiblesSelectedAmounts,
            collectiblesSelectedCategory = _getState7.collectiblesSelectedCategory,
            settings = _getState7.settings;

        var policyDetailsInPlan = (0, _pick2.default)(collectiblesPolicyContext, ['has_no_mailing_address']);

        var ownershipDetails = function ownershipDetails() {
          var ownershipField = {
            ownership_type: collectiblesPolicyContext.ownership_type
          };

          switch (collectiblesPolicyContext.ownership_type) {
            case 'trust':
              return Object.assign({}, ownershipField, {
                trust_name: collectiblesPolicyContext.trust_name
              });

            case 'foundation':
              return Object.assign({}, ownershipField, {
                owner_foundation: collectiblesPolicyContext.owner_foundation
              });

            case 'executor':
            case 'jointly_owned':
              return Object.assign({}, ownershipField, {
                owners: collectiblesPolicyContext.owners
              });

            case 'musical_group':
              return Object.assign({}, ownershipField, {
                musical_group_name: collectiblesPolicyContext.musical_group_name
              });

            default:
              return ownershipField;
          }
        };

        var policyDetailsInCollection = (0, _pick2.default)(collectiblesPolicyContext, ['claims_count', 'declined_insurance_type', 'filed_bankruptcy', 'is_felon']);
        var addressContext = (0, _pick2.default)((0, _find2.default)(collectiblesAddressContext, {
          address: (0, _omit2.default)(collectiblesPropertyAddress, ['lat', 'lng'])
        }), ['has_burglar_alarm', 'has_fire_alarm']);
        var addressContextInCollection = (0, _includes2.default)(['jewelry', 'firearms'], collectiblesSelectedCategory) ? {
          has_burglar_alarm: addressContext.has_burglar_alarm
        } : {
          has_burglar_alarm: addressContext.has_burglar_alarm,
          has_fire_alarm: addressContext.has_fire_alarm
        };
        var planDetails = Object.assign({}, policyDetailsInPlan, ownershipDetails(), {
          plan_id: collectiblesPlan.plan_id,
          plan_deductible: collectiblesSelectedAmounts.deductible,
          policy_effective_date: (0, _momentTimezone2.default)(collectiblesPolicyStartDate).format('YYYY-MM-DD'),
          mailing_address: {
            line1: collectiblesPolicyContext.mailing_address_line1,
            line2: collectiblesPolicyContext.mailing_address_line2,
            city: collectiblesPolicyContext.mailing_address_city,
            state_or_region: collectiblesPolicyContext.mailing_address_state_or_region,
            postal: collectiblesPolicyContext.mailing_address_postal,
            country_code: collectiblesPolicyContext.mailing_address_country
          }
        });
        dispatch(addCollectionToQuoteRequest());
        api.updateQuote({
          quoteId: collectiblesQuote.quote_id,
          application: collectiblesQuote.application,
          applicationUpdates: {
            plan_applications: [Object.assign({}, collectiblesQuote.application.plan_applications[0], planDetails, {
              collections: [].concat(_toConsumableArray((0, _get2.default)(collectiblesQuote.application.plan_applications[0], 'collections', []).map(function (collection) {
                var groomedAddress = {
                  streetAddress: collection.address.line1,
                  unit: collection.address.line2,
                  city: collection.address.city,
                  region: collection.address.state_or_region,
                  country: collection.address.country_code,
                  postal: collection.address.postal
                };
                var addressContext = (0, _find2.default)(collectiblesAddressContext, {
                  address: groomedAddress
                });
                var addressContextInCollection = (0, _includes2.default)(['jewelry', 'firearms'], collection.category_code) ? {
                  has_burglar_alarm: addressContext.has_burglar_alarm
                } : {
                  has_burglar_alarm: addressContext.has_burglar_alarm,
                  has_fire_alarm: addressContext.has_fire_alarm
                };
                return Object.assign({}, collection, addressContextInCollection);
              })), [Object.assign({
                address: {
                  city: collectiblesPropertyAddress.city,
                  country_code: collectiblesPropertyAddress.country,
                  line1: collectiblesPropertyAddress.streetAddress,
                  line2: collectiblesPropertyAddress.unit,
                  postal: collectiblesPropertyAddress.postal,
                  state_or_region: collectiblesPropertyAddress.region
                }
              }, policyDetailsInCollection, addressContextInCollection, {
                category_code: collectiblesSelectedCategory,
                collection_total_value: collectiblesResponses.collection_total_value,
                has_personal_safe: !!collectiblesResponses.has_personal_safe,
                has_ul_rate_vault: !!collectiblesResponses.has_ul_rate_vault,
                has_ul_rated_safe: !!collectiblesResponses.has_ul_rated_safe,
                highest_value_item: collectiblesResponses.highest_value_item
              })])
            })]
          },
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        }).then(function (quote) {
          var oldCollectionIds = Object.keys(collectiblesCollectionsByCollectionId);

          var newCollectionId = _without2.default.apply(undefined, [(0, _map2.default)(quote.application.plan_applications[0].collections, 'reference_id')].concat(_toConsumableArray(oldCollectionIds)));

          if (newCollectionId.length > 1) {
            throw new Error('More than one new collection ID found: [' + newCollectionId.join(', ') + ']');
          } else if (newCollectionId.length === 0) {
            throw new Error('No new collection IDs found');
          } else {
            dispatch(addCollectionToQuoteSuccess(quote));
            resolve(newCollectionId[0]);
          }
        }).catch(function (error) {
          dispatch(addCollectionToQuoteFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Remove collection from quote
   * ========================================
   */


  function removeCollectionFromQuoteRequest(collectionId) {
    return {
      type: _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE,
      payload: {
        collectionId: collectionId
      }
    };
  }

  function removeCollectionFromQuoteSuccess(quote) {
    return {
      type: _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function removeCollectionFromQuoteFailure(error) {
    return {
      type: _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_FAILURE,
      error: error
    };
  }

  function removeCollectionFromQuote(collectionId) {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState8 = getState(),
            collectiblesQuote = _getState8.collectiblesQuote,
            settings = _getState8.settings;

        var collectionToRemove = (0, _find2.default)(collectiblesQuote.application.plan_applications[0].collections, {
          reference_id: collectionId
        });
        dispatch(removeCollectionFromQuoteRequest());
        api.updateQuote({
          quoteId: collectiblesQuote.quote_id,
          application: collectiblesQuote.application,
          applicationUpdates: {
            plan_applications: [Object.assign({}, collectiblesQuote.application.plan_applications[0], {
              collections: (0, _without2.default)(collectiblesQuote.application.plan_applications[0].collections, collectionToRemove)
            })]
          },
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        }).then(function (quote) {
          dispatch(removeCollectionFromQuoteSuccess(quote));
          resolve();
        }).catch(function (error) {
          dispatch(removeCollectionFromQuoteFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Get collectibles checkout
   * ========================================
   */


  function getCollectiblesCheckoutRequest() {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT
    };
  }

  function getCollectiblesCheckoutSuccess(quote, isInitialCalculation) {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS,
      payload: {
        quote: quote
      },
      isInitialCalculation: isInitialCalculation
    };
  }

  function getCollectiblesCheckoutFailure(error) {
    return {
      type: _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function getCollectiblesCheckout() {
    return function (dispatch, getState) {
      return new Promise(function (resolve, reject) {
        var _getState9 = getState(),
            collectiblesQuote = _getState9.collectiblesQuote,
            settings = _getState9.settings;

        var opts = {
          quoteId: collectiblesQuote.quote_id,
          verticalCode: _verticals2.default.collectibles.code,
          apiBaseUrl: settings.apiBaseUrl
        };
        dispatch(getCollectiblesCheckoutRequest());
        api.getQuoteCheckout(opts).then(function (quote) {
          var isInitialCalculation = !collectiblesQuote.billing;
          dispatch(getCollectiblesCheckoutSuccess(quote, isInitialCalculation));
          resolve(quote);
        }).catch(function (error) {
          dispatch(getCollectiblesCheckoutFailure(error));
          reject(error);
        });
      });
    };
  }
  /*
   * Edit quote
   * ========================================
   */


  function openEditCollectiblesQuoteModal(editModalContext) {
    return {
      type: _actionTypes2.default.OPEN_EDIT_COLLECTIBLES_QUOTE_MODAL,
      editModalContext: editModalContext
    };
  }

  function jumpToCollectiblesSurveyScreenId(newScreenId) {
    return function (dispatch, getState) {
      var _getState10 = getState(),
          collectiblesCurrentScreenId = _getState10.collectiblesCurrentScreenId,
          collectiblesResponses = _getState10.collectiblesResponses,
          collectiblesScreenSequence = _getState10.collectiblesScreenSequence,
          collectiblesPolicyContext = _getState10.collectiblesPolicyContext,
          collectiblesPropertyAddress = _getState10.collectiblesPropertyAddress,
          collectiblesEditQuoteModalContext = _getState10.collectiblesEditQuoteModalContext,
          collectiblesCollectionsByCollectionId = _getState10.collectiblesCollectionsByCollectionId,
          collectiblesSelectedCategory = _getState10.collectiblesSelectedCategory;

      var collectionToEdit = collectiblesCollectionsByCollectionId[collectiblesEditQuoteModalContext.collectionId];
      var newResponses = collectionToEdit ? Object.assign({}, collectiblesPolicyContext, collectiblesResponses, {
        collection_total_value: collectionToEdit.collection_total_value,
        highest_value_item: collectionToEdit.highest_value_item,
        has_ul_rate_vault: collectionToEdit.has_ul_rate_vault,
        has_personal_safe: collectionToEdit.has_personal_safe,
        has_fire_alarm: collectionToEdit.has_fire_alarm,
        has_burglar_alarm: collectionToEdit.has_burglar_alarm
      }) : Object.assign({}, collectiblesPolicyContext, collectiblesResponses);
      var newPropertyAddress = collectionToEdit ? {
        streetAddress: collectionToEdit.address.line1,
        unit: collectionToEdit.address.line2,
        city: collectionToEdit.address.city,
        region: collectionToEdit.address.state_or_region,
        country: collectionToEdit.address.country_code,
        postal: collectionToEdit.address.postal
      } : collectiblesPropertyAddress;
      var newSelectedCategory = (0, _get2.default)(collectionToEdit, 'category_code') || collectiblesSelectedCategory;
      return new Promise(function (resolve) {
        dispatch({
          type: _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID,
          oldScreenId: collectiblesCurrentScreenId,
          newScreenId: newScreenId,
          oldResponses: collectiblesResponses,
          newResponses: newResponses,
          oldPropertyAddress: collectiblesPropertyAddress,
          newPropertyAddress: newPropertyAddress,
          oldSelectedCategory: collectiblesSelectedCategory,
          newSelectedCategory: newSelectedCategory,
          oldScreenSequence: collectiblesScreenSequence
        });
        resolve();
      });
    };
  }

  function dismissEditCollectiblesQuoteModal() {
    return function (dispatch, getState) {
      var _getState11 = getState(),
          collectiblesPreEditContext = _getState11.collectiblesPreEditContext;

      if (collectiblesPreEditContext) {
        dispatch({
          type: _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL,
          screenId: collectiblesPreEditContext.oldScreenId || null,
          responses: collectiblesPreEditContext.oldResponses,
          screenSequence: collectiblesPreEditContext.oldScreenSequence,
          propertyAddress: collectiblesPreEditContext.oldPropertyAddress,
          selectedCategory: collectiblesPreEditContext.oldSelectedCategory
        });
      } else {
        dispatch({
          type: _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL
        });
      }
    };
  }

  function saveCollectiblesQuoteEditsAndCloseModal() {
    return {
      type: _actionTypes2.default.SAVE_COLLECTIBLES_QUOTE_EDITS_AND_CLOSE_MODAL
    };
  }
  /*
   * Add Payment Method
   * ========================================
   */


  function addCollectiblesPaymentMethodRequest() {
    return {
      type: _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD
    };
  }

  function addCollectiblesPaymentMethodSuccess(quote) {
    return {
      type: _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD_SUCCESS,
      payload: {
        quote: quote
      }
    };
  }

  function addCollectiblesPaymentMethodFailure(error) {
    return {
      type: _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD_FAILURE,
      error: error
    };
  }
  /*
   * Purchase
   * ========================================
   */


  function purchaseCollectiblesRequest() {
    return {
      type: _actionTypes2.default.PURCHASE_COLLECTIBLES
    };
  }

  function purchaseCollectiblesSuccess(data) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_COLLECTIBLES_SUCCESS
    });
    return {
      type: _actionTypes2.default.PURCHASE_COLLECTIBLES_SUCCESS,
      data: data.hydratedPurchaseData
    };
  }

  function purchaseCollectiblesFailure(error) {
    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_COLLECTIBLES_FAILURE
    });
    return {
      type: _actionTypes2.default.PURCHASE_COLLECTIBLES_FAILURE,
      payload: {
        error: error
      }
    };
  }

  function purchaseCollectibles(_ref) {
    var cardElement = _ref.cardElement,
        categoryCode = _ref.categoryCode,
        cardName = _ref.cardName;
    return function (dispatch, getState) {
      var _getState12 = getState(),
          account = _getState12.account,
          authToken = _getState12.authToken,
          collectiblesQuote = _getState12.collectiblesQuote,
          collectiblesSelectedCategory = _getState12.collectiblesSelectedCategory,
          settings = _getState12.settings,
          partnerInfo = _getState12.partnerInfo;

      var revenue = collectiblesQuote.billing.grand_total;
      analytics.track('Collectibles - Purchase Initiated', {
        category: 'order',
        label: collectiblesQuote.quote_id,
        value: revenue
      });
      var email = collectiblesQuote.application.holders[0].email || account.email;
      return new Promise(function (resolve, reject) {
        dispatch(addCollectiblesPaymentMethodRequest());
        var apiEndpoint = authToken && !partnerInfo ? settings.apiBaseUrl + '/api/user/v2/accounts/payment-method/add' : !authToken && !partnerInfo ? settings.apiBaseUrl + '/api/user/v2/potential/add-method' : settings.apiBaseUrl + '/api/partner/v1/methods';

        if (cardElement) {
          (0, _utils.createStripeTokenFromElement)(cardElement).then(function (stripeToken) {
            analytics.track('Collectibles - Stripe Token Created', {
              category: 'order',
              nonInteraction: 1
            });
            return api.createPaymentMethod({
              firstName: collectiblesQuote.application.holders[0].first_name,
              lastName: collectiblesQuote.application.holders[0].last_name,
              email: email,
              getId: true,
              authToken: authToken,
              apiEndpoint: apiEndpoint,
              stripeToken: stripeToken
            });
          }).then(resolve).catch(function (error) {
            dispatch(addCollectiblesPaymentMethodFailure(error));
            reject(error);
          });
        } else {
          resolve();
        }
      }).then(function (paymentMethodId) {
        return new Promise(function (resolve, reject) {
          analytics.track('Collectibles - Payment Method Created', {
            category: 'order',
            value: authToken ? 1 : 0,
            nonInteraction: 1
          });
          api.updateQuote({
            quoteId: collectiblesQuote.quote_id,
            application: collectiblesQuote.application,
            applicationUpdates: {
              payment_method_id: paymentMethodId
            },
            verticalCode: _verticals2.default.collectibles.code,
            apiBaseUrl: settings.apiBaseUrl
          }).then(function (quote) {
            dispatch(addCollectiblesPaymentMethodSuccess(quote));
            resolve(quote);
          }).catch(function (error) {
            dispatch(addCollectiblesPaymentMethodFailure(error));
            reject(error);
          });
        });
      }).then(function () {
        return new Promise(function (resolve, reject) {
          var opts = {
            prefix: 'Collectibles',
            quoteId: collectiblesQuote.quote_id,
            email: email,
            revenue: revenue,
            firstName: collectiblesQuote.application.holders[0].first_name,
            lastName: collectiblesQuote.application.holders[0].last_name,
            paymentCadence: 'Yearly',
            verticalCode: _verticals2.default.collectibles.code,
            verticalName: (0, _startCase2.default)(categoryCode) + ' Insurance',
            apiBaseUrl: settings.apiBaseUrl
          };
          dispatch(purchaseCollectiblesRequest());
          api.purchaseQuote(opts).then(function (purchaseData) {
            var hydratedPurchaseData = (0, _merge2.default)({}, purchaseData, {
              details: {
                totals: revenue
              }
            });
            dispatch(purchaseCollectiblesSuccess({
              hydratedPurchaseData: hydratedPurchaseData,
              collectiblesQuote: collectiblesQuote
            }));
            resolve(hydratedPurchaseData);
          }).catch(function (error) {
            if (error.code === 'unprocessable_request' && collectiblesSelectedCategory === 'jewelry' && !partnerInfo) {
              error.code = 'jewelry_exit_ramp';
            }

            dispatch(purchaseCollectiblesFailure(error));
            reject(error);
          });
        });
      });
    };
  }

  function updateCollectiblesStartDate(momentObj) {
    return {
      type: _actionTypes2.default.UPDATE_COLLECTIBLES_START_DATE,
      payload: {
        isoString: momentObj && momentObj.toISOString() || null
      }
    };
  }

  function clearCollectionDetails() {
    return {
      type: _actionTypes2.default.CLEAR_COLLECTION_DETAILS
    };
  }

  function resetCollectiblesData() {
    return {
      type: _actionTypes2.default.RESET_COLLECTIBLES_DATA
    };
  }
  /*
   * EXPORTS
   * ========================================
   */


  return {
    addCollectionToQuote: addCollectionToQuote,
    clearCollectionDetails: clearCollectionDetails,
    collectiblesSurveyNext: collectiblesSurveyNext,
    createCollectiblesQuote: createCollectiblesQuote,
    deleteCollectiblesResponse: deleteCollectiblesResponse,
    dismissEditCollectiblesQuoteModal: dismissEditCollectiblesQuoteModal,
    editCollection: editCollection,
    getCollectiblesCheckout: getCollectiblesCheckout,
    getCollectiblesPlans: getCollectiblesPlans,
    hideCollectiblesNotificationBar: hideCollectiblesNotificationBar,
    jumpToCollectiblesSurveyScreenId: jumpToCollectiblesSurveyScreenId,
    openEditCollectiblesQuoteModal: openEditCollectiblesQuoteModal,
    purchaseCollectibles: purchaseCollectibles,
    removeCollectionFromQuote: removeCollectionFromQuote,
    resetCollectiblesData: resetCollectiblesData,
    saveCollectiblesQuoteEditsAndCloseModal: saveCollectiblesQuoteEditsAndCloseModal,
    saveCollectiblesResponse: saveCollectiblesResponse,
    showCollectiblesNotificationBar: showCollectiblesNotificationBar,
    surveyCollectiblesBack: surveyCollectiblesBack,
    updateCollectiblesAddress: updateCollectiblesAddress,
    updateCollectiblesAddressContext: updateCollectiblesAddressContext,
    updateCollectiblesCategoryType: updateCollectiblesCategoryType,
    updateCollectiblesCurrentResponse: updateCollectiblesCurrentResponse,
    updateCollectiblesPlanDetails: updateCollectiblesPlanDetails,
    updateCollectiblesSelectedAmounts: updateCollectiblesSelectedAmounts,
    updateCollectiblesStartDate: updateCollectiblesStartDate,
    updateCollectionDetails: updateCollectionDetails,
    updateCollectionsInQuote: updateCollectionsInQuote
  };
};