'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/GlobalModals/ModalSelect.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _sureComponents = require('sure-components');

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

require('./ModalSelect.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  body: _propTypes2.default.array,
  afterDismiss: _propTypes2.default.func,
  onDismiss: _propTypes2.default.func
};

var ModalSelect = function (_PureComponent) {
  _inherits(ModalSelect, _PureComponent);

  function ModalSelect() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ModalSelect);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ModalSelect.__proto__ || Object.getPrototypeOf(ModalSelect)).call.apply(_ref, [this].concat(args))), _this), _this.handleDismiss = function () {
      _this.props.onDismiss();

      if ((0, _isFunction2.default)(_this.props.afterDismiss)) {
        _this.props.afterDismiss();
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ModalSelect, [{
    key: 'render',
    value: function render() {
      var title = this.props.title;
      return _react2.default.createElement(_sureComponents.Modal, {
        title: title || 'Info',
        className: 'ModalSelect',
        isActive: true,
        onDismiss: this.handleDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 41
        },
        __self: this
      }, this.bodyNode);
    }
  }, {
    key: 'bodyNode',
    get: function get() {
      var _this2 = this;

      var body = this.props.body;
      return body.map(function (option) {
        return _react2.default.createElement('button', {
          className: 'ModalSelect-optionBtn ModalSelect-optionBtn--sureStyle-' + (option[2] || 'default'),
          key: option[0],
          onClick: option[1],
          __source: {
            fileName: _jsxFileName,
            lineNumber: 25
          },
          __self: _this2
        }, _react2.default.createElement('span', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 31
          },
          __self: _this2
        }, option[0]), _react2.default.createElement('i', {
          className: 'material-icons',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 32
          },
          __self: _this2
        }, 'keyboard_arrow_right'));
      });
    }
  }]);

  return ModalSelect;
}(_react.PureComponent);

ModalSelect.propTypes = propTypes;
exports.default = ModalSelect;