'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _zipObject = require('lodash/zipObject');

var _zipObject2 = _interopRequireDefault(_zipObject);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ACTIONS = ['SIGN_OUT', 'GET_RENTAL_CAR_PLANS', 'GET_RENTAL_CAR_PLANS_SUCCESS', 'GET_RENTAL_CAR_PLANS_FAILURE', 'UPDATE_RENTAL_CAR_PICK_UP_DATE', 'UPDATE_RENTAL_CAR_RETURN_DATE', 'UPDATE_RENTAL_CAR_AGENCY', 'UPDATE_RENTAL_CAR_HAS_SIGNED_RENTAL_AGREEMENT', 'UPDATE_RENTAL_CAR_IS_FOR_PERSONAL_USE', 'UPDATE_RENTAL_CAR_DRIVER', 'UPDATE_RENTAL_CAR_HAS_ADDITIONAL_DRIVERS', 'ADD_RENTAL_CAR_ADDITIONAL_DRIVER', 'UPDATE_RENTAL_CAR_ADDITIONAL_DRIVERS', 'REMOVE_RENTAL_CAR_EMPTY_ADDITIONAL_DRIVER', 'SELECT_RENTAL_CAR_PLAN', 'SELECT_RENTAL_CAR_PLAN_SUCCESS', 'SELECT_RENTAL_CAR_PLAN_FAILURE', 'PURCHASE_RENTAL_CAR', 'PURCHASE_RENTAL_CAR_SUCCESS', 'PURCHASE_RENTAL_CAR_FAILURE', 'PURCHASE_RENTAL_CAR_EXTENSION', 'PURCHASE_RENTAL_CAR_EXTENSION_SUCCESS', 'PURCHASE_RENTAL_CAR_EXTENSION_FAILURE'];
var ACTION_TYPES = (0, _zipObject2.default)(ACTIONS, ACTIONS);
exports.default = ACTION_TYPES;