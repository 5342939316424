'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistedRentalCarReducerKeys = exports.rentalCarReducers = undefined;

var _clone = require('lodash/clone');

var _clone2 = _interopRequireDefault(_clone);

var _concat = require('lodash/concat');

var _concat2 = _interopRequireDefault(_concat);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _utils = require('shared/lib/utils');

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var rentalCarReducers = {
  rentalCarPickUpDate: function rentalCarPickUpDate() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_PICK_UP_DATE:
        return action.payload.dateStr;

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarReturnDate: function rentalCarReturnDate() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_RETURN_DATE:
        return action.payload.dateStr;

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarAgency: function rentalCarAgency() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_AGENCY:
        return action.payload.agencyName;

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return '';

      default:
        return state;
    }
  },
  rentalCarDriver: function rentalCarDriver() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_DRIVER:
        return Object.assign({}, state, action.payload.driverInfo);

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return {};

      default:
        return state;
    }
  },
  rentalCarHasAdditionalDrivers: function rentalCarHasAdditionalDrivers() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_HAS_ADDITIONAL_DRIVERS:
        return action.payload.hasAdditionalDrivers;

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarAdditionalDrivers: function rentalCarAdditionalDrivers() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.ADD_RENTAL_CAR_ADDITIONAL_DRIVER:
        return (0, _concat2.default)(state, [{
          firstName: '',
          lastName: ''
        }]);

      case _actionTypes2.default.UPDATE_RENTAL_CAR_ADDITIONAL_DRIVERS:
        return (0, _clone2.default)(action.payload.additionalDrivers);

      case _actionTypes2.default.REMOVE_RENTAL_CAR_EMPTY_ADDITIONAL_DRIVER:
        return (0, _filter2.default)(state, function (_ref) {
          var firstName = _ref.firstName,
              lastName = _ref.lastName;
          return !(0, _isEmpty2.default)(firstName) || !(0, _isEmpty2.default)(lastName);
        });

      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return [];

      default:
        return state;
    }
  },
  rentalCarPlans: function rentalCarPlans() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_RENTAL_CAR_PLANS_SUCCESS:
        return action.payload.plans;

      case _actionTypes2.default.GET_RENTAL_CAR_PLANS:
      case _actionTypes2.default.GET_RENTAL_CAR_PLANS_FAILURE:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return [];

      default:
        return state;
    }
  },
  rentalCarSelectedPlanId: function rentalCarSelectedPlanId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN:
        return action.payload.planId;

      case _actionTypes2.default.GET_RENTAL_CAR_PLANS:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarCheckoutInfo: function rentalCarCheckoutInfo() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_SUCCESS:
        return action.payload;

      case _actionTypes2.default.GET_RENTAL_CAR_PLANS:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_FAILURE:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarPurchase: function rentalCarPurchase() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
        return action.payload;

      case _actionTypes2.default.GET_RENTAL_CAR_PLANS:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarHasSignedAgreement: function rentalCarHasSignedAgreement() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_HAS_SIGNED_RENTAL_AGREEMENT:
        return action.payload.hasSignedAgreement;

      case _actionTypes2.default.UPDATE_RENTAL_CAR_PICK_UP_DATE:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  rentalCarIsForPersonalUse: function rentalCarIsForPersonalUse() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTAL_CAR_IS_FOR_PERSONAL_USE:
        return action.payload.isForPersonalUse;

      case _actionTypes2.default.UPDATE_RENTAL_CAR_PICK_UP_DATE:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  isRentalCarInProgress: function isRentalCarInProgress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_RENTAL_CAR_PLANS:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_EXTENSION:
        return true;

      case _actionTypes2.default.GET_RENTAL_CAR_PLANS_SUCCESS:
      case _actionTypes2.default.GET_RENTAL_CAR_PLANS_FAILURE:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_SUCCESS:
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_FAILURE:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_FAILURE:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_EXTENSION_SUCCESS:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_EXTENSION_FAILURE:
      case _actionTypes2.default.SIGN_OUT:
        return false;

      default:
        return state;
    }
  },
  rentalCarUID: function rentalCarUID() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN_SUCCESS:
        return (0, _utils.uuid)();

      case _actionTypes2.default.SELECT_RENTAL_CAR_PLAN:
      case _actionTypes2.default.PURCHASE_RENTAL_CAR_SUCCESS:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  }
};
var persistedRentalCarReducerKeys = (0, _without2.default)(Object.keys(rentalCarReducers), 'isRentalCarInProgress');
exports.rentalCarReducers = rentalCarReducers;
exports.persistedRentalCarReducerKeys = persistedRentalCarReducerKeys;