'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/apps/WeddingApp/hoc.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

exports.default = withSubapp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

var _utils = require('shared/lib/utils');

var _reactRouterRedux = require('react-router-redux');

var _actions = require('./actions');

var _actions2 = _interopRequireDefault(_actions);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {};
};

var mapDispatchToPropsWithActions = function mapDispatchToPropsWithActions(actions) {
  return function (dispatch) {
    return Object.assign({}, (0, _redux.bindActionCreators)(actions, dispatch), {
      navTo: function navTo(path) {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)(path)));
      }
    });
  };
};

function withSubapp(api) {
  var weddingActions = (0, _actions2.default)(api);
  return function (WrappedComponent) {
    var SubappHOC = function (_Component) {
      _inherits(SubappHOC, _Component);

      function SubappHOC(props) {
        _classCallCheck(this, SubappHOC);

        var _this = _possibleConstructorReturn(this, (SubappHOC.__proto__ || Object.getPrototypeOf(SubappHOC)).call(this, props));

        _this.displayName = 'withSubapp(' + (WrappedComponent.displayName || WrappedComponent.name) + ')';
        _this.WrappedComponent = WrappedComponent;
        return _this;
      }

      _createClass(SubappHOC, [{
        key: 'render',
        value: function render() {
          return _react2.default.createElement('div', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 37
            },
            __self: this
          }, _react2.default.createElement(WrappedComponent, _extends({}, this.props, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 38
            },
            __self: this
          })), _react2.default.createElement(_ModalSpinner2.default, {
            message: 'Calculating your quote. This may take up to 15 seconds.',
            isActive: this.props.isFetchingQuote,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 39
            },
            __self: this
          }));
        }
      }]);

      return SubappHOC;
    }(_react.Component);

    var ComponentWithStatics = (0, _hoistNonReactStatics2.default)(SubappHOC, WrappedComponent);
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToPropsWithActions(weddingActions))(ComponentWithStatics);
  };
}