'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarConfirmation/RentalCarConfirmation.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _ConfirmationLayout = require('shared/lib/components/ConfirmationLayout');

var _ConfirmationLayout2 = _interopRequireDefault(_ConfirmationLayout);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object,
  disclaimer: _propTypes2.default.string,
  purchase: _propTypes2.default.object
};
var defaultProps = {
  account: {},
  disclaimer: null,
  purchase: {}
};

var RentalCarConfirmation = function (_Component) {
  _inherits(RentalCarConfirmation, _Component);

  function RentalCarConfirmation() {
    _classCallCheck(this, RentalCarConfirmation);

    return _possibleConstructorReturn(this, (RentalCarConfirmation.__proto__ || Object.getPrototypeOf(RentalCarConfirmation)).apply(this, arguments));
  }

  _createClass(RentalCarConfirmation, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Confirmation', {
        category: 'user'
      });
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 3
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentalCarConfirmation Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 39
        },
        __self: this
      }, _react2.default.createElement(_ConfirmationLayout2.default, {
        policyNumber: this.props.purchase.policy_number,
        policyDocUrl: (0, _get2.default)(this.props.purchase, 'details.plan.policy_doc_url'),
        icon: 'directions_car',
        totals: this.props.purchase.details.totals,
        phoneNumber: this.props.account.phoneNumber,
        disclaimer: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 40
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Rental car details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 48
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Agency', this.props.purchase.details.rentalAgency], ['Pick-up date', this.props.purchase.details.pickUpDate], ['Return date', this.props.purchase.details.returnDate]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Driver details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 58
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Primary driver', this.props.purchase.details.driverInfo.firstName + ' ' + this.props.purchase.details.driverInfo.lastName]].concat(_toConsumableArray(this.additionalDriversArr)),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 59
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Coverage details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 72
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Underwritten by', 'Chubb'], ['Start date', this.props.purchase.details.pickUpDate], ['End date', this.props.purchase.details.returnDate]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 73
        },
        __self: this
      }))));
    }
  }, {
    key: 'additionalDriversArr',
    get: function get() {
      return this.props.purchase.details.additionalDrivers.map(function (driver, i) {
        return ['Additional driver', driver.firstName + ' ' + driver.lastName];
      });
    }
  }]);

  return RentalCarConfirmation;
}(_react.Component);

RentalCarConfirmation.propTypes = propTypes;
RentalCarConfirmation.defaultProps = defaultProps;
exports.default = RentalCarConfirmation;