'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LandingBlockPrice/LandingBlockPrice.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

require('./LandingBlockPrice.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  price: _propTypes2.default.string,
  footnote: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.element]),
  priceSubtitle: _propTypes2.default.string,
  lightTheme: _propTypes2.default.bool
};
var defaultProps = {
  title: "Here's what you'd invest",
  price: null,
  priceSubtitle: null,
  items: [],
  lightTheme: false
};

var LandingBlockPrice = function (_PureComponent) {
  _inherits(LandingBlockPrice, _PureComponent);

  function LandingBlockPrice() {
    _classCallCheck(this, LandingBlockPrice);

    return _possibleConstructorReturn(this, (LandingBlockPrice.__proto__ || Object.getPrototypeOf(LandingBlockPrice)).apply(this, arguments));
  }

  _createClass(LandingBlockPrice, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          price = _props.price,
          priceSubtitle = _props.priceSubtitle,
          footnote = _props.footnote;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('LandingBlockPrice', {
          'LandingBlockPrice--light': this.props.lightTheme
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'LandingBlockPrice-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 33
        },
        __self: this
      }, title), _react2.default.createElement('p', {
        className: 'LandingBlockPrice-info',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 34
        },
        __self: this
      }, price, priceSubtitle && _react2.default.createElement('span', {
        className: 'LandingBlockPrice-info-subtitle',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 37
        },
        __self: this
      }, ' ', priceSubtitle)), footnote && ((0, _isString2.default)(this.props.footnote) ? _react2.default.createElement('p', {
        className: 'LandingBlockPrice-footnote',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 45
        },
        __self: this
      }, this.props.footnote) : footnote));
    }
  }]);

  return LandingBlockPrice;
}(_react.PureComponent);

LandingBlockPrice.propTypes = propTypes;
LandingBlockPrice.defaultProps = defaultProps;
exports.default = LandingBlockPrice;