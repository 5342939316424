import ACTION_TYPES from '../../constants/actionTypes';

export default {
  homeSafeQuote(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.CREATE_HOMESAFE_QUOTE_SUCCESS:
      case ACTION_TYPES.UPDATE_HOMESAFE_QUOTE_SUCCESS:
      case ACTION_TYPES.GET_HOMESAFE_CHECKOUT_SUCCESS:
        return action.payload.quote;
      case ACTION_TYPES.CREATE_HOMESAFE_QUOTE:
      case ACTION_TYPES.CREATE_HOMESAFE_QUOTE_FAILURE:
        return null;
      default:
        return state;
    }
  },

  homeSafePlans(state = [], action) {
    switch (action.type) {
      case ACTION_TYPES.GET_HOMESAFE_PLANS_SUCCESS:
        return action.payload.plans;
      case ACTION_TYPES.GET_HOMESAFE_PLANS:
      case ACTION_TYPES.GET_HOMESAFE_PLANS_FAILURE:
        return [];
      default:
        return state;
    }
  },

  homeSafeSettings(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_HOMESAFE_PLANS_SUCCESS:
        return action.payload.settings;
      case ACTION_TYPES.GET_HOMESAFE_PLANS:
      case ACTION_TYPES.GET_HOMESAFE_PLANS_FAILURE:
        return {};
      default:
        return state;
    }
  },

  homeSafeDetails(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_HOMESAFE_DETAILS:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  },

  homeSafeSelectedPlanId(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SELECT_HOMESAFE_PLAN:
        return action.payload.planId;
      default:
        return state;
    }
  },

  homeSafePurchaseInfo(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.PURCHASE_HOMESAFE_SUCCESS:
        return action.payload;
      case ACTION_TYPES.PURCHASE_HOMESAFE:
      case ACTION_TYPES.GET_HOMESAFE_PLANS_SUCCESS:
      case ACTION_TYPES.GET_HOMESAFE_PLANS:
      case ACTION_TYPES.SELECT_HOMESAFE_PLAN:
        return {};
      default:
        return state;
    }
  },

  isHomeSafeInProgress(state = false, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_HOMESAFE_PLANS:
      case ACTION_TYPES.GET_HOMESAFE_CHECKOUT:
      case ACTION_TYPES.PURCHASE_HOMESAFE:
        return true;
      case ACTION_TYPES.GET_HOMESAFE_PLANS_SUCCESS:
      case ACTION_TYPES.GET_HOMESAFE_PLANS_FAILURE:
      case ACTION_TYPES.GET_HOMESAFE_CHECKOUT_SUCCESS:
      case ACTION_TYPES.GET_HOMESAFE_CHECKOUT_FAILURE:
      case ACTION_TYPES.PURCHASE_HOMESAFE_SUCCESS:
      case ACTION_TYPES.PURCHASE_HOMESAFE_FAILURE:
        return false;
      default:
        return state;
    }
  },
};
