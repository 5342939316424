import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import seo from 'shared/lib/seo';
import get from 'lodash/get';
import omit from 'lodash/omit';
import * as api from '../../api/rentalCar';
import withSubapp from 'shared/lib/apps/RentalCarApp/hoc';
import withGlobals from '../../withGlobals';
import './RentalCarContainer.css';

class RentalCarContainer extends Component {
  componentDidMount() {
    const partnerName = get(this.props.partnerInfo, 'name');
    seo.setProps({
      title: `Rental Car Insurance | ${partnerName}`,
      description: `Get Rental Car Insurance through ${partnerName} to protect your rented vehicle. Plans as low as $6.`,
      url: `https://${window.location.host}/rental-car-insurance`,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <div className="RentalCarContainer">
        {React.cloneElement(children, omit(this.props, 'children'))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const defaultDisclaimer = `Content and associated insurance products are provided by Sure HIIS Insurance Services, LLC (“Sure”), a licensed seller of insurance. The above does not in any way constitute an endorsement or referral by ${
    state.partnerInfo.name
  }. Products may not be offered in all states.`;
  return {
    disclaimer: ownProps.disclaimer || defaultDisclaimer,
  };
};

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'Rental Car',
  }),
  connect(mapStateToProps),
  withSubapp(api)
);

export default enhance(RentalCarContainer);
