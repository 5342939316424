import React, { Fragment, Component } from 'react';
import { Link } from 'react-router';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import logoDefaultLight from './images/logo-default-light.png';
import logoDefaultDark from './images/logo-default-dark.png';
import './TopNavBar.css';

export default class TopNavBar extends Component {
  static propTypes = {
    hasProgressBar: PropTypes.bool,
    isMenuOpen: PropTypes.bool,
    logoDarkUrl: PropTypes.string,
    logoLightUrl: PropTypes.string,
    sideMenuLinks: PropTypes.array,
    title: PropTypes.string,
    transparent: PropTypes.bool,
  };

  static defaultProps = {
    hasProgressBar: false,
    isMenuOpen: false,
    logoDarkUrl: '',
    logoLightUrl: '',
    sideMenuLinks: [],
    title: 'Company',
    transparent: false,
  };

  get menu() {
    const itemNodes = map(flatten(this.props.sideMenuLinks), (linkObj, i) => {
      if (startsWith(linkObj.href, '/')) {
        return (
          <li key={i} className="TopNavBar-menu-item">
            <Link className="TopNavBar-menu-item-link" to={linkObj.href}>
              {linkObj.title}
            </Link>
          </li>
        );
      } else {
        return (
          <li key={i} className="TopNavBar-menu-item">
            <a
              className="TopNavBar-menu-item-link"
              href={linkObj.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkObj.title}
            </a>
          </li>
        );
      }
    });

    return (
      <ul
        className={classnames('TopNavBar-menu', {
          'is-active': this.props.isMenuOpen,
        })}
      >
        {itemNodes}
      </ul>
    );
  }

  render() {
    const {
      hasProgressBar,
      isMenuOpen,
      logoDarkUrl,
      logoLightUrl,
      onToggleMenu,
      progress,
      showMenuText,
      sideMenuLinks,
      title,
      transparent,
    } = this.props;
    return (
      <div
        className={classnames('TopNavBar', {
          'TopNavBar--withProgressBar': hasProgressBar,
          'TopNavBar--transparent': transparent,
          'is-menuOpen': isMenuOpen,
        })}
      >
        <div className="TopNavBar-container">
          <Link className="TopNavBar-logo" to="/">
            <img
              className="TopNavBar-logo-img"
              src={
                transparent
                  ? logoLightUrl || logoDefaultLight
                  : logoDarkUrl || logoDefaultDark
              }
              alt={title + ' logo'}
            />
          </Link>
          {sideMenuLinks && !!sideMenuLinks.length && (
            <div className="TopNavBar-menuButton" onClick={onToggleMenu}>
              <i className="TopNavBar-menuButton-icon material-icons">
                {isMenuOpen ? 'clear' : 'menu'}
              </i>
              {showMenuText && (
                <span className="TopNavBar-menuButton-text">Menu</span>
              )}
            </div>
          )}
        </div>
        {hasProgressBar && (
          <div
            className="TopNavBar-progressBar"
            style={{ width: progress + 'vw' }}
          />
        )}
        {sideMenuLinks && !!sideMenuLinks.length && (
          <Fragment>
            {this.menu}
            <div
              className={classnames('TopNavBar-overlay', {
                'is-active': isMenuOpen,
              })}
              onClick={onToggleMenu}
            />
          </Fragment>
        )}
      </div>
    );
  }
}
