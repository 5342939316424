'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLLECTIBLES_CATEGORY_PATH = exports.COLLECTIBLES_BASE_PATH = exports.collectiblesRoutesCreator = undefined;
var _jsxFileName = 'src/apps/CollectiblesApp/routes.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _endsWith = require('lodash/endsWith');

var _endsWith2 = _interopRequireDefault(_endsWith);

var _SurveyManagerNew = require('shared/lib/components/SurveyManagerNew');

var _SurveyManagerNew2 = _interopRequireDefault(_SurveyManagerNew);

var _CollectiblesPolicyholderInfo = require('./views/CollectiblesPolicyholderInfo');

var _CollectiblesPolicyholderInfo2 = _interopRequireDefault(_CollectiblesPolicyholderInfo);

var _CollectiblesCategory = require('./views/CollectiblesCategory');

var _CollectiblesCategory2 = _interopRequireDefault(_CollectiblesCategory);

var _CollectiblesAddress = require('./views/CollectiblesAddress');

var _CollectiblesAddress2 = _interopRequireDefault(_CollectiblesAddress);

var _CollectiblesPolicyStartDate = require('./views/CollectiblesPolicyStartDate');

var _CollectiblesPolicyStartDate2 = _interopRequireDefault(_CollectiblesPolicyStartDate);

var _CollectiblesQuoteDetails = require('./views/CollectiblesQuoteDetails');

var _CollectiblesQuoteDetails2 = _interopRequireDefault(_CollectiblesQuoteDetails);

var _CollectiblesPayment = require('./views/CollectiblesPayment');

var _CollectiblesPayment2 = _interopRequireDefault(_CollectiblesPayment);

var _CollectiblesConfirmation = require('./views/CollectiblesConfirmation');

var _CollectiblesConfirmation2 = _interopRequireDefault(_CollectiblesConfirmation);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var COLLECTIBLES_BASE_PATH = 'collectible-insurance';
var COLLECTIBLES_CATEGORY_PATH = 'collectibles/:categorySlug';
var pathnameParams = window.location.pathname.split('/');
var categorySlug = (0, _find2.default)(pathnameParams, function (param) {
  return (0, _endsWith2.default)(param, '-insurance');
});

var collectiblesRoutesCreator = function collectiblesRoutesCreator(_ref) {
  var container = _ref.container,
      landingComponent = _ref.landingComponent,
      redirectIfMissing = _ref.redirectIfMissing;
  return _react2.default.createElement(_react.Fragment, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 26
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.Route, {
    path: COLLECTIBLES_BASE_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28
    },
    __self: undefined
  })), _react2.default.createElement(_reactRouter.Route, {
    path: COLLECTIBLES_CATEGORY_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 30
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'start',
    component: _CollectiblesPolicyholderInfo2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 32
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'category',
    component: _CollectiblesCategory2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesResponses.owner_phone_number',
      pathname: 'collectibles/' + categorySlug + '/start'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 33
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'address(/new)',
    component: _CollectiblesAddress2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesSelectedCategory',
      pathname: 'collectibles/' + categorySlug + '/category'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 41
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'survey/:screenId',
    component: _SurveyManagerNew2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesPropertyAddress.streetAddress',
      pathname: 'collectibles/' + categorySlug + '/address'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 49
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'policy-date',
    component: _CollectiblesPolicyStartDate2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesPropertyAddress.streetAddress',
      pathname: 'collectibles/' + categorySlug + '/address'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 57
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'quote',
    component: _CollectiblesQuoteDetails2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesQuote.application.plan_applications[0].collections[0]',
      pathname: 'collectibles/' + categorySlug + '/address'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 65
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'payment',
    component: _CollectiblesPayment2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesQuote.application.plan_applications[0].collections[0]',
      pathname: 'collectibles/' + categorySlug + '/address'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 74
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'confirmation',
    component: _CollectiblesConfirmation2.default,
    onEnter: redirectIfMissing({
      key: 'collectiblesQuote.application.plan_applications[0].collections[0]',
      pathname: 'collectibles/' + categorySlug + '/address'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 83
    },
    __self: undefined
  })));
};

exports.collectiblesRoutesCreator = collectiblesRoutesCreator;
exports.COLLECTIBLES_BASE_PATH = COLLECTIBLES_BASE_PATH;
exports.COLLECTIBLES_CATEGORY_PATH = COLLECTIBLES_CATEGORY_PATH;