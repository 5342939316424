'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SurveyQuestionNew/SurveyQuestionNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _head = require('lodash/head');

var _head2 = _interopRequireDefault(_head);

var _SingleSelectFieldsNew = require('../SingleSelectFieldsNew');

var _SingleSelectFieldsNew2 = _interopRequireDefault(_SingleSelectFieldsNew);

var _MultiSelectFieldsNew = require('../MultiSelectFieldsNew');

var _MultiSelectFieldsNew2 = _interopRequireDefault(_MultiSelectFieldsNew);

var _TextFieldsNew = require('../TextFieldsNew');

var _TextFieldsNew2 = _interopRequireDefault(_TextFieldsNew);

var _SubFieldsNew = require('../SubFieldsNew');

var _SubFieldsNew2 = _interopRequireDefault(_SubFieldsNew);

var _AddressAutocompleteFieldsNew = require('../AddressAutocompleteFieldsNew');

var _AddressAutocompleteFieldsNew2 = _interopRequireDefault(_AddressAutocompleteFieldsNew);

var _utils = require('shared/lib/utils');

require('./SurveyQuestionNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var SurveyQuestionNew = function (_Component) {
  _inherits(SurveyQuestionNew, _Component);

  function SurveyQuestionNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SurveyQuestionNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SurveyQuestionNew.__proto__ || Object.getPrototypeOf(SurveyQuestionNew)).call.apply(_ref, [this].concat(args))), _this), _this.handleShowHelp = function (e) {
      e.preventDefault();

      _this.props.onShowHelp();
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SurveyQuestionNew, [{
    key: 'validate',
    value: function validate() {
      if (this.fieldNode.validate) {
        return this.fieldNode.validate();
      } else {
        return Promise.resolve();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var formContentNode = void 0;
      var field = (0, _head2.default)(this.props.fields);
      var screenType = (0, _utils.getFieldsType)(this.props.fields);

      switch (screenType) {
        case _utils.FIELDS_TYPES.SINGLE_SELECT:
          formContentNode = _react2.default.createElement(_SingleSelectFieldsNew2.default, {
            field: field,
            defaultChecked: this.props.responses[field.key_name],
            onChange: this.props.onChange,
            ref: function ref(c) {
              return _this2.fieldNode = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 41
            },
            __self: this
          });
          break;

        case _utils.FIELDS_TYPES.MULTI_SELECT:
          formContentNode = _react2.default.createElement(_MultiSelectFieldsNew2.default, {
            field: field,
            defaultChecked: this.props.responses[field.key_name],
            onChange: this.props.onChange,
            ref: function ref(c) {
              return _this2.fieldNode = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 51
            },
            __self: this
          });
          break;

        case _utils.FIELDS_TYPES.TEXT_FIELDS:
          formContentNode = _react2.default.createElement(_TextFieldsNew2.default, {
            fields: this.props.fields,
            values: this.props.responses,
            onChange: this.props.onChange,
            ref: function ref(c) {
              return _this2.fieldNode = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 61
            },
            __self: this
          });
          break;

        case _utils.FIELDS_TYPES.ADDRESS:
          formContentNode = _react2.default.createElement(_AddressAutocompleteFieldsNew2.default, {
            fields: this.props.fields,
            values: this.props.responses,
            onChange: this.props.onChange,
            ref: function ref(c) {
              return _this2.fieldNode = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 71
            },
            __self: this
          });
          break;

        case _utils.FIELDS_TYPES.SUB_FIELDS:
          formContentNode = _react2.default.createElement(_SubFieldsNew2.default, {
            fields: this.props.fields,
            defaultValues: this.props.responses[field.key_name],
            onChange: this.props.onChange,
            ref: function ref(c) {
              return _this2.fieldNode = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 81
            },
            __self: this
          });
          break;

        default:
          formContentNode = null;
          break;
      }

      return _react2.default.createElement('div', {
        className: 'SurveyQuestionNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 95
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'SurveyQuestionNew-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 96
        },
        __self: this
      }, this.props.title), this.props.helpText && _react2.default.createElement('a', {
        href: '#help',
        className: 'SurveyQuestionNew-help',
        onClick: this.handleShowHelp,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      }, 'Need help?'), this.isMailingAddressScreen && _react2.default.createElement('div', {
        className: 'SurveyQuestionNew-propertyAddress',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 107
        },
        __self: this
      }, _react2.default.createElement('p', {
        className: 'SurveyQuestionNew-propertyAddress-address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      }, _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 109
        },
        __self: this
      }, this.props.propertyAddress.streetAddress), _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 110
        },
        __self: this
      }, this.props.propertyAddress.unit), _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 111
        },
        __self: this
      }, this.props.propertyAddress.city, ',', ' ', this.props.propertyAddress.region, ' ', this.props.propertyAddress.postal))), formContentNode);
    }
  }, {
    key: 'isMailingAddressScreen',
    get: function get() {
      return !!((0, _find2.default)(this.props.fields, {
        key_name: 'has_mailing_address'
      }) || (0, _find2.default)(this.props.fields, {
        key_name: 'has_no_mailing_address'
      }));
    }
  }]);

  return SurveyQuestionNew;
}(_react.Component);

exports.default = SurveyQuestionNew;