import Q from 'q';
import request from 'superagent';
import { getDeviceInfo, logException, trimValues } from 'shared/lib/utils';
import { purchase } from '../api';

/*
 * Get BOP business class categories.
 * @param {String} opts.authToken The Sure API auth token.
 */
export function getBusinessClasses({ authToken }) {
  return fetch(
    `${
      process.env.REACT_APP_SURE_API_BASE_URL
    }/api/partner/v1/protections/commercial/business-classes`,
    {
      headers: {
        'X-Environment': JSON.stringify(getDeviceInfo()),
        Authorization: `Token ${authToken}`,
      },
    }
  ).then(response => {
    if (response.status >= 400) {
      return response.json().then(errorResp => {
        const error = new Error(response.statusText);
        logException(error, errorResp);
        throw error;
      });
    } else {
      return response.json();
    }
  });
}

/**
 * Get BOP plans.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.businessClassCode The business class code.
 * @param {Object} opts.ratingAddress The user property address.
 * @param {Object} opts.ratingAddress.streetAddress The street address.
 * @param {Object} [opts.ratingAddress.unit] The unit number.
 * @param {Object} opts.ratingAddress.city The city.
 * @param {Object} opts.ratingAddress.region The state or region.
 * @param {Object} opts.ratingAddress.postal The postal code.
 * @param {Object} opts.ratingAddress.country The country.
 */
export function getBopPlans(opts) {
  const deferred = Q.defer();
  const payload = trimValues({
    rating_address: {
      line1: opts.ratingAddress.streetAddress,
      line2: opts.ratingAddress.unit,
      city: opts.ratingAddress.city,
      state_or_region: opts.ratingAddress.region,
      postal: opts.ratingAddress.postal,
      country_code: opts.ratingAddress.country,
    },
    business_class_code: opts.businessClassCode,
  });

  request
    .post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/commercial/plans`
    )
    .set('Authorization', `Token ${opts.authToken}`)
    .set('X-Environment', JSON.stringify(getDeviceInfo()))
    .send(payload)
    .end((error, response) => {
      if (error) {
        logException(error, {
          payload,
          response: response.body,
        });
        deferred.reject(error.response.body.error);
      } else {
        deferred.resolve(response.body);
      }
    });

  return deferred.promise;
}

/**
 * Get BOP rates.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.details The details and survey responses.
 * @param {Object} opts.ratingAddress The user property address.
 * @param {Object} opts.ratingAddress.streetAddress The street address.
 * @param {Object} [opts.ratingAddress.unit] The unit number.
 * @param {Object} opts.ratingAddress.city The city.
 * @param {Object} opts.ratingAddress.region The state or region.
 * @param {Object} opts.ratingAddress.postal The postal code.
 * @param {Object} opts.ratingAddress.country The country.
 */
export function getBopRates(opts) {
  const deferred = Q.defer();
  const payload = trimValues({
    rating_address: {
      line1: opts.ratingAddress.streetAddress,
      line2: opts.ratingAddress.unit,
      city: opts.ratingAddress.city,
      state_or_region: opts.ratingAddress.region,
      postal: opts.ratingAddress.postal,
      country_code: opts.ratingAddress.country,
    },
    details: opts.details,
  });

  request
    .post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/commercial/rates`
    )
    .set('Authorization', `Token ${opts.authToken}`)
    .set('X-Environment', JSON.stringify(getDeviceInfo()))
    .send(payload)
    .end((error, response) => {
      if (error) {
        logException(error, {
          payload,
          response: response.body,
        });
        deferred.reject(error.response.body.error);
      } else {
        deferred.resolve(response.body);
      }
    });

  return deferred.promise;
}

/**
 * Get BOP checkout.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.planId The selected plan ID.
 * @param {String} opts.quoteId The quote ID.
 * @param {Object} opts.ratingAddress The user property address.
 * @param {Object} opts.ratingAddress.streetAddress The street address.
 * @param {Object} [opts.ratingAddress.unit] The unit number.
 * @param {Object} opts.ratingAddress.city The city.
 * @param {Object} opts.ratingAddress.region The state or region.
 * @param {Object} opts.ratingAddress.postal The postal code.
 * @param {Object} opts.ratingAddress.country The country.
 */
export function getBopCheckout(opts) {
  const deferred = Q.defer();
  const payload = trimValues({
    rating_address: {
      line1: opts.ratingAddress.streetAddress,
      line2: opts.ratingAddress.unit,
      city: opts.ratingAddress.city,
      state_or_region: opts.ratingAddress.region,
      postal: opts.ratingAddress.postal,
      country_code: opts.ratingAddress.country,
    },
    plan_id: opts.planId,
    quote_id: opts.quoteId,
  });

  request
    .post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/commercial/checkout`
    )
    .set('Authorization', `Token ${opts.authToken}`)
    .set('X-Environment', JSON.stringify(getDeviceInfo()))
    .send(payload)
    .end((error, response) => {
      if (error) {
        logException(error, {
          payload,
          response: response.body,
        });
        deferred.reject(error.response.body.error);
      } else {
        const checkoutObj = response.body;
        const normalizedCheckoutObj = {
          currencyCode: checkoutObj.currency.code,
          paymentSchedule: checkoutObj.payment_schedule,
          quote: checkoutObj.quote,
          totals: {
            grandtotal: checkoutObj.grandtotal,
          },
        };
        deferred.resolve(normalizedCheckoutObj);
      }
    });

  return deferred.promise;
}

/**
 * Purchase a bop policy.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.planId The selected plan ID.
 * @param {String} opts.quoteId The quote ID.
 * @param {String} opts.firstName The user's first name.
 * @param {String} opts.lastName The user's last name.
 * @param {String} opts.email The user's email.
 * @param {Object} opts.cardElement The Stripe card element.
 * @param {Number} opts.revenue The total revenue of the purchase.
 * @param {Object} [opts.metadata] Any metadata properties to attach.
 */
export function purchaseBop(opts) {
  const payload = {
    quote_id: opts.quoteId,
    metadata: opts.metadata,
  };

  return purchase({
    planId: opts.planId,
    firstName: opts.firstName,
    lastName: opts.lastName,
    email: opts.email,
    authToken: opts.authToken,
    cardElement: opts.cardElement,
    revenue: opts.revenue,
    payload,
    prefix: 'BOP',
    endpoint: '/api/partner/v1/protections/commercial/purchase',
  });
}
