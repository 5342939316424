'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CreditCardFields/CreditCardFields.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _upperFirst = require('lodash/upperFirst');

var _upperFirst2 = _interopRequireDefault(_upperFirst);

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

require('./CreditCardFields.css');

require('paymentfont/css/paymentfont.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  className: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  onChange: _propTypes2.default.func
};
var defaultProps = {
  className: '',
  disabled: false,
  onChange: function onChange() {}
};
var classes = {
  complete: 'CreditCardFields-input--complete',
  focus: 'CreditCardFields-input--focus',
  invalid: 'CreditCardFields-input--invalid'
};
var style = {
  base: {
    color: '#00345c',
    fontFamily: '"Nunito", Helvetica, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#dadfe9'
    }
  },
  invalid: {
    color: '#cc1236'
  }
};
var cardBrandToPfClass = {
  visa: 'pf-visa',
  mastercard: 'pf-mastercard',
  amex: 'pf-american-express',
  discover: 'pf-discover',
  unknown: 'pf-credit-card'
};

var CreditCardFields = function (_Component) {
  _inherits(CreditCardFields, _Component);

  function CreditCardFields() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CreditCardFields);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CreditCardFields.__proto__ || Object.getPrototypeOf(CreditCardFields)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      cardCvc: false,
      cardExpiry: false,
      cardNumber: false,
      brandClasses: [],
      cardName: '',
      isCardNameComplete: false,
      isCardCvcInvalid: false,
      isCardExpiryInvalid: false,
      isCardNumberInvalid: false
    }, _this.isFieldInvalid = function (e) {
      _this.setState(_defineProperty({}, 'is' + (0, _upperFirst2.default)(e.elementType) + 'Invalid', !!e.error));
    }, _this.handleChange = function (e) {
      if (e.elementType) {
        _this.isFieldInvalid(e);

        if (e.brand) {
          _this.setBrandIcon(e.brand, e.complete);
        }

        if (e.elementType) {
          _this.setState(_defineProperty({}, e.elementType, e.complete), function () {
            _this.props.onChange({
              isComplete: _this.state.cardCvc && _this.state.cardExpiry && _this.state.cardNumber && _this.state.isCardNameComplete
            });
          });
        }
      } else if (e.target.value !== '') {
        _this.setState({
          isCardNameComplete: true,
          cardName: e.target.value
        }, function () {
          _this.props.onChange({
            isComplete: _this.state.cardCvc && _this.state.cardExpiry && _this.state.cardNumber && _this.state.isCardNameComplete
          });
        });
      } else {
        _this.setState({
          isCardNameComplete: false,
          cardName: e.target.value
        }, function () {
          _this.props.onChange({
            isComplete: _this.state.cardCvc && _this.state.cardExpiry && _this.state.cardNumber && _this.state.isCardNameComplete
          });
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CreditCardFields, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.init();
      this.setBrandIcon('unknown');
    }
  }, {
    key: 'init',
    value: function init() {
      if (!window.Stripe) {
        console.error('Cannot init CreditCardFields without Stripe.');
        return false;
      }

      var elements = window.stripe.elements({
        fonts: [{// cssSrc: 'https://fonts.googleapis.com/css?family=Nunito',
        }]
      });
      /**
       * CARD NUMBER
       */

      this.cardNumberElement = elements.create('cardNumber', {
        classes: classes,
        style: style,
        placeholder: '0000 0000 0000 0000'
      });
      this.cardNumberElement.mount(this.cardNumberMountNode);
      this.cardNumberElement.on('change', this.handleChange);
      /**
       * CARD EXPIRY
       */

      this.cardExpiryElement = elements.create('cardExpiry', {
        classes: classes,
        style: style
      });
      this.cardExpiryElement.mount(this.cardExpiryMountNode);
      this.cardExpiryElement.on('change', this.handleChange);
      /**
       * CARD CVC
       */

      this.cardCvcElement = elements.create('cardCvc', {
        classes: classes,
        style: style,
        placeholder: '000'
      });
      this.cardCvcElement.mount(this.cardCvcMountNode);
      this.cardCvcElement.on('change', this.handleChange);
    }
  }, {
    key: 'setBrandIcon',
    value: function setBrandIcon(brand, isComplete) {
      var pfClass = 'pf-credit-card';

      if (brand in cardBrandToPfClass) {
        pfClass = cardBrandToPfClass[brand];
      }

      if (isComplete) {
        this.setState({
          brandClasses: ['pf', pfClass, 'CreditCardFields-brand-i-complete']
        });
      } else {
        this.setState({
          brandClasses: ['pf', pfClass]
        });
      }
    }
  }, {
    key: 'reset',
    value: function reset() {
      this.cardNumberElement.unmount();
      this.cardExpiryElement.unmount();
      this.cardCvcElement.unmount();
      this.setState({
        cardName: ''
      });
      this.init();
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          disabled = _props.disabled;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('CreditCardFields', className, {
          'is-disabled': disabled
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 213
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'CreditCardFields-label',
        htmlFor: 'cardName',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 218
        },
        __self: this
      }, 'Cardholder Name', _react2.default.createElement(_TextInput2.default, {
        name: 'cardName',
        id: 'cardName',
        required: true,
        type: 'text',
        onChange: this.handleChange,
        value: this.state.cardName,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      })), _react2.default.createElement('label', {
        className: (0, _classnames2.default)('CreditCardFields-label', {
          'is-invalid': this.state.isCardNumberInvalid
        }),
        htmlFor: 'cardNumberElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 229
        },
        __self: this
      }, _react2.default.createElement('span', {
        className: 'CreditCardFields-label-span',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 235
        },
        __self: this
      }, 'Card Number'), _react2.default.createElement('div', {
        ref: function ref(c) {
          return _this2.cardNumberMountNode = c;
        },
        className: 'CreditCardFields-input',
        id: 'cardNumberElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 236
        },
        __self: this
      }), _react2.default.createElement('span', {
        className: 'CreditCardFields-label-span CreditCardFields-brand',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 241
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: (0, _classnames2.default)('CreditCardFields-brand-i', this.state.brandClasses),
        id: 'brand-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 242
        },
        __self: this
      }))), _react2.default.createElement('div', {
        className: 'CreditCardFields-inputRow--desktop',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 252
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CreditCardFields-inputRow-item--desktop',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 253
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: (0, _classnames2.default)('CreditCardFields-label', {
          'is-invalid': this.state.isCardExpiryInvalid
        }),
        htmlFor: 'cardExpiryElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 254
        },
        __self: this
      }, _react2.default.createElement('span', {
        className: 'CreditCardFields-label-span',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 260
        },
        __self: this
      }, 'Expiration Date'), _react2.default.createElement('div', {
        ref: function ref(c) {
          return _this2.cardExpiryMountNode = c;
        },
        className: 'CreditCardFields-input',
        id: 'cardExpiryElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 263
        },
        __self: this
      }))), _react2.default.createElement('div', {
        className: 'CreditCardFields-inputRow-item--desktop',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 270
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: (0, _classnames2.default)('CreditCardFields-label', {
          'is-invalid': this.state.isCardCvcInvalid
        }),
        htmlFor: 'cardCvcElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 271
        },
        __self: this
      }, _react2.default.createElement('span', {
        className: 'CreditCardFields-label-span',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 277
        },
        __self: this
      }, 'Security Code (CVC)'), _react2.default.createElement('div', {
        ref: function ref(c) {
          return _this2.cardCvcMountNode = c;
        },
        className: 'CreditCardFields-input',
        id: 'cardCvcElement',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 280
        },
        __self: this
      })))));
    }
  }, {
    key: 'cardName',
    get: function get() {
      return this.state.cardName;
    }
  }, {
    key: 'element',
    get: function get() {
      return this.cardNumberElement;
    }
  }]);

  return CreditCardFields;
}(_react.Component);

CreditCardFields.propTypes = propTypes;
CreditCardFields.defaultProps = defaultProps;
exports.default = CreditCardFields;