import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BooleanTable from '../BooleanTable';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import Button from 'shared/lib/components/Button';
import every from 'lodash/every';
import isBoolean from 'lodash/isBoolean';
import map from 'lodash/map';
import * as analytics from 'shared/lib/analytics';
import './HomeSafeScreeningSurveyView.css';

const propTypes = {
  homeSafeDetails: PropTypes.object,
};

const defaultProps = {
  homeSafeDetails: {},
};

class HomeSafeScreeningSurveyView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
    this.questions = [
      {
        title:
          'Have you had two (2) or more claims in the last five (5) years?',
        key: 'plan_screening_questions.has_two_or_more_claims',
      },
      {
        title:
          'Do you have a fence/wall made of solid construction (brick or concrete) and surrounded by electric fencing?',
        key: 'plan_screening_questions.has_fence',
      },
      {
        title:
          'Do you live in a house i.e. not an access controlled complex/apartment/housing compound',
        key: 'plan_screening_questions.lives_in_house',
      },
      {
        title:
          'Will your home be unoccupied for more than 60 consecutive days per annum?',
        key: 'plan_screening_questions.unoccupied_for_60_days',
      },
      {
        title: 'It this home your main residence?',
        key: 'plan_screening_questions.is_main_residence',
      },
      {
        title: 'Is your home sublet or communed?',
        key: 'plan_screening_questions.is_sublet_or_communed',
      },
      {
        title:
          'In the past 3 years has an insurer refused, cancelled, refused to renew or imposed special conditions to your household insurance?',
        key: 'plan_screening_questions.has_insurance_refusal_or_cancellation',
      },
      {
        title: 'Are the walls made of brick, stone, or concrete?',
        key: 'plan_screening_questions.has_stone_walls',
      },
      {
        title:
          'Is the roof made of solid construction (tile, corrugated iron, concrete, or slate?',
        key: 'plan_screening_questions.has_solid_construction_roof',
      },
    ];
  }

  componentDidMount() {
    analytics.page('HomeSafe - Intro Survey');
  }

  handleChange = details => {
    this.props.updateHomeSafeDetails(details);
  };

  handleNext = () => {
    const { updateHomeSafeQuote, navTo, onError } = this.props;
    this.setState({
      isInProgress: true,
    });
    updateHomeSafeQuote()
      .then(() => {
        navTo('/residential-insurance/payment');
      })
      .catch(error => {
        this.setState({
          isInProgress: false,
        });
        onError(error);
      });
  };

  get isNextDisabled() {
    const reqKeys = map(this.questions, 'key');
    return (
      !every(reqKeys, key => {
        return isBoolean(this.props.homeSafeDetails[key]);
      }) || this.state.isInProgress
    );
  }

  render() {
    const { disclaimer, homeSafeDetails } = this.props;

    return (
      <div className="HomeSafeScreeningSurveyView Container ChubbTheme">
        <h1 className="HomeSafeScreeningSurveyView-heading">
          Please answer the questions below:
        </h1>

        <BooleanTable
          rows={this.questions}
          values={homeSafeDetails}
          onChange={this.handleChange}
        />
        <Button
          className="HomeSafeScreeningSurveyView-cta"
          disabled={this.isNextDisabled}
          onClick={this.handleNext}
        >
          Next <i className="far fa-chevron-right" />
        </Button>
        <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
      </div>
    );
  }
}

HomeSafeScreeningSurveyView.propTypes = propTypes;
HomeSafeScreeningSurveyView.defaultProps = defaultProps;

export default HomeSafeScreeningSurveyView;
