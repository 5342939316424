'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarDriver/RentalCarDriver.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _sureComponents = require('sure-components');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _utils = require('shared/lib/utils');

var _TelInput = require('shared/lib/components/TelInput');

var _TelInput2 = _interopRequireDefault(_TelInput);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AddressForm = require('shared/lib/components/AddressForm');

var _AddressForm2 = _interopRequireDefault(_AddressForm);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarDriver.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object,
  disclaimer: _propTypes2.default.string,
  driverInfo: _propTypes2.default.object,
  isLoggedIn: _propTypes2.default.bool,
  isRentalCarInProgress: _propTypes2.default.bool,
  updateDriverInfo: _propTypes2.default.func
};
var defaultProps = {
  account: {},
  disclaimer: null,
  isLoggedIn: false,
  isRentalCarInProgress: false
};

var RentalCarDriver = function (_Component) {
  _inherits(RentalCarDriver, _Component);

  function RentalCarDriver() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, RentalCarDriver);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentalCarDriver.__proto__ || Object.getPrototypeOf(RentalCarDriver)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.identify({
        email: _this.props.driverInfo.email
      });
      analytics.track('Rental Car - Next Button Clicked');

      _this.residenceAddressInput.getGeocodedValue().then(function (geocodedData) {
        _this.props.updateDriverInfo(geocodedData);

        return _this.props.getRentalCarPlans();
      }).then(function () {
        analytics.track('Rental Car - Residence Address Submitted');

        _this.props.navTo((0, _utils.resolveUrlPath)('additional-drivers'));
      }).catch(_this.props.onError);
    }, _this.handleContactChange = function (e) {
      _this.props.updateDriverInfo(_defineProperty({}, e.target.name, e.target.value));
    }, _this.handlePhoneNumberChange = function (number) {
      _this.props.updateDriverInfo({
        phoneNumber: number
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentalCarDriver, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'RentalCarDriver',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 85
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarDriver-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      }, 'Please provide primary driver contact information:'), _react2.default.createElement('form', {
        className: 'RentalCarDriver-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }, _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 91
        },
        __self: this
      }, 'Contact Info'), _react2.default.createElement(_sureComponents.FormGroup, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 92
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.TextInput, {
        name: 'firstName',
        placeholder: 'First name',
        maxLength: '40',
        required: true,
        value: this.props.driverInfo.firstName || '',
        onChange: this.handleContactChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 93
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'lastName',
        placeholder: 'Last name',
        maxLength: '40',
        required: true,
        value: this.props.driverInfo.lastName || '',
        onChange: this.handleContactChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 101
        },
        __self: this
      }), _react2.default.createElement(_TelInput2.default, {
        name: 'phoneNumber',
        className: 'RentalCarDriver-form-telInput',
        required: true,
        value: this.props.driverInfo.phoneNumber || '',
        onChange: this.handlePhoneNumberChange,
        preferredCountries: ['us'],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 109
        },
        __self: this
      }), !this.props.isLoggedIn && _react2.default.createElement(_sureComponents.TextInput, {
        name: 'email',
        type: 'email',
        placeholder: 'Email address',
        required: true,
        value: this.props.driverInfo.email || '',
        onChange: this.handleContactChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 118
        },
        __self: this
      })), _react2.default.createElement('label', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 128
        },
        __self: this
      }, 'Address'), _react2.default.createElement(_AddressForm2.default, {
        streetAddress: this.props.driverInfo.streetAddress,
        unit: this.props.driverInfo.unit,
        city: this.props.driverInfo.city,
        region: this.props.driverInfo.region,
        postal: this.props.driverInfo.postal,
        country: this.props.driverInfo.country,
        onChange: this.props.updateDriverInfo,
        ref: function ref(c) {
          return _this2.residenceAddressInput = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 139
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        isNextDisabled: this.isNextDisabled,
        isInProgress: this.props.isRentalCarInProgress,
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 141
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 147
        },
        __self: this
      })));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      var requiredVals = [this.props.driverInfo.firstName, this.props.driverInfo.lastName, this.props.driverInfo.phoneNumber, this.props.driverInfo.streetAddress, this.props.driverInfo.city, this.props.driverInfo.region, this.props.driverInfo.postal];

      if (!this.props.isLoggedIn) {
        requiredVals.push(this.props.driverInfo.email);
      }

      return (0, _some2.default)(requiredVals, _isEmpty2.default);
    }
  }]);

  return RentalCarDriver;
}(_react.Component);

RentalCarDriver.propTypes = propTypes;
RentalCarDriver.defaultProps = defaultProps;
exports.default = RentalCarDriver;