import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import seo from 'shared/lib/seo';
import compact from 'lodash/compact';
import get from 'lodash/get';
import omit from 'lodash/omit';
import * as collectiblesApi from '../../api/collectibles';
import * as sharedApi from 'shared/lib/api';
import withSubapp from 'shared/lib/apps/CollectiblesApp/hoc';
import withGlobals from '../../withGlobals';
import NotificationBar from 'shared/lib/components/NotificationBar';
import './CollectiblesContainer.css';

class CollectiblesContainer extends Component {
  state = {
    isPolicyFlow: false,
  };

  componentDidMount() {
    if (compact(this.props.location.pathname.split('/')).length >= 3) {
      const partnerName = get(this.props.partnerInfo, 'name');

      seo.setProps({
        title: `Collectibles & Fine Arts | ${partnerName}`,
        description: `Get Collectibles and Fine Arts insurance through ${partnerName} to protect your treasures.`,
        url: `https://${window.location.host}/collectible-insurance`,
        image: `https://${
          window.location.host
        }/images/collectibles/landing-other.jpg`,
        fallback: true,
      });
      this.setState({
        isPolicyFlow: true,
      });
    } else {
      this.setState({
        isPolicyFlow: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (compact(this.props.location.pathname.split('/')).length >= 3) {
        this.setState({
          isPolicyFlow: true,
        });
      } else {
        this.setState({
          isPolicyFlow: false,
        });
      }
    }
  }

  get isCollectiblesMulticheckoutEnabled() {
    return (
      process.env.REACT_APP_IS_COLLECTIBLES_MULTI_CHECKOUT_ENABLED ||
      this.props.isHagerty
    );
  }

  render() {
    const { children } = this.props;
    return (
      <div className="CollectiblesContainer">
        {this.props.notificationMessage && (
          <NotificationBar
            message={this.props.notificationMessage}
            onDismiss={this.props.hideCollectiblesNotificationBar}
            isFullWidth={!this.state.isPolicyFlow}
            sureStyle="info"
            className="NotificationBar--collectibles"
          />
        )}
        {React.cloneElement(children, {
          ...omit(this.props, 'children'),
          isCollectiblesMulticheckoutEnabled: this
            .isCollectiblesMulticheckoutEnabled,
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  collectiblesPolicies: [],
});

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'Collectibles',
  }),
  connect(mapStateToProps),
  withSubapp({ ...collectiblesApi, ...sharedApi })
);

export default enhance(CollectiblesContainer);
