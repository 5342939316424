'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersConfirmation/RentersConfirmation.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AddressSection = require('shared/lib/components/AddressSection');

var _AddressSection2 = _interopRequireDefault(_AddressSection);

var _utils = require('shared/lib/utils');

var _ConfirmationLayout = require('shared/lib/components/ConfirmationLayout');

var _ConfirmationLayout2 = _interopRequireDefault(_ConfirmationLayout);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  selectedAmounts: _propTypes2.default.object,
  totals: _propTypes2.default.object,
  policyProvider: _propTypes2.default.string,
  policyNumber: _propTypes2.default.string,
  planNumber: _propTypes2.default.string,
  plan: _propTypes2.default.shape({
    policy_doc_url: _propTypes2.default.string
  }).isRequired,
  account: _propTypes2.default.shape({
    phoneNumber: _propTypes2.default.string
  }),
  fullName: _propTypes2.default.string,
  propertyAddress: _propTypes2.default.shape({
    streetAddress: _propTypes2.default.string,
    unit: _propTypes2.default.string,
    region: _propTypes2.default.string,
    city: _propTypes2.default.string,
    postal: _propTypes2.default.string,
    country: _propTypes2.default.string
  }).isRequired,
  policyStartDate: _reactMomentProptypes2.default.momentObj
};
var defaultProps = {
  account: {},
  disclaimer: null,
  fullName: null,
  plan: {},
  planNumber: null,
  policyNumber: null,
  policyProvider: null,
  policyStartDate: null,
  propertyAddress: {},
  selectedAmounts: {},
  totals: {}
};

var RentersConfirmation = function (_Component) {
  _inherits(RentersConfirmation, _Component);

  function RentersConfirmation() {
    _classCallCheck(this, RentersConfirmation);

    return _possibleConstructorReturn(this, (RentersConfirmation.__proto__ || Object.getPrototypeOf(RentersConfirmation)).apply(this, arguments));
  }

  _createClass(RentersConfirmation, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Renters - Confirmation', {
        category: 'plan',
        label: this.props.plan.plan_id,
        value: Math.round(this.props.totals.grandtotal)
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentersConfirmation Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      }, _react2.default.createElement(_ConfirmationLayout2.default, {
        title: 'Your Renters Quote',
        policyNumber: this.props.planNumber,
        policyDocUrl: this.props.plan.policy_doc_url,
        icon: 'location_city',
        totals: this.props.totals,
        phoneNumber: this.props.account.phoneNumber,
        disclaimer: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 88
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        title: 'Property Address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 97
        },
        __self: this
      }, _react2.default.createElement(_AddressSection2.default, {
        fullName: this.props.fullName,
        address: this.props.propertyAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 103
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: this.detailRows,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }))));
    }
  }, {
    key: 'detailRows',
    get: function get() {
      var rows = [['Underwritten by', this.props.policyProvider]];

      if (this.props.policyNumber) {
        rows.push(['Policy number', this.props.policyNumber]);
      }

      return rows.concat([['Property coverage', '$' + (0, _utils.numberWithCommas)(this.props.selectedAmounts.personal_property_coverage || this.props.selectedAmounts.personal_property_amount)], ['Start date', this.props.policyStartDate.format('MMM D, YYYY')], ['Billing cycle', this.billingCycle]]);
    }
  }, {
    key: 'billingCycle',
    get: function get() {
      switch (this.props.rentersSelectedPaymentCadence) {
        case 'eleven_pay':
          return 'Monthly (11 Payments)';

        default:
          return 'Annual';
      }
    }
  }]);

  return RentersConfirmation;
}(_react.Component);

RentersConfirmation.propTypes = propTypes;
RentersConfirmation.defaultProps = defaultProps;
exports.default = RentersConfirmation;