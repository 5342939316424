import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import * as analytics from 'shared/lib/analytics';
import ConfirmationLayout from 'shared/lib/components/ConfirmationLayout';
import LineItemsList from 'shared/lib/components/LineItemsList';
import SectionWrapper from 'shared/lib/components/SectionWrapper';
import { format as currencyFormat } from 'currency-formatter';

const propTypes = {
  carSafeCheckout: PropTypes.object,
  carSafeSelectedPlan: PropTypes.object,
  carSafeSettings: PropTypes.object,
  disclaimer: PropTypes.string,
  getCarSafeCheckout: PropTypes.func,
  purchaseCarSafe: PropTypes.func,
};

const defaultProps = {
  carSafeCheckout: {},
  carSafeSelectedPlan: {},
  carSafeSettings: {},
  disclaimer: null,
  getCarSafeCheckout: () => {},
  purchaseCarSafe: () => {},
};

class CarSafeConfirmationView extends Component {
  componentDidMount() {
    analytics.page('CarSafe - Confirmation');
    analytics.track('Checkout Step Viewed', {
      category: 'order',
      step: 3,
    });
  }

  get totals() {
    const total = get(
      this.props.carSafeCheckout,
      'payment_schedule.schedule[0].amount'
    );
    return {
      subtotal: total,
      tax: 0,
      grandtotal: total,
    };
  }

  render() {
    const {
      carSafeDetails,
      carSafePurchaseInfo,
      carSafeSelectedPlan,
      carSafeSettings,
      disclaimer,
    } = this.props;
    const currencyOpts = {
      code: get(carSafeSettings, 'currency.code'),
      precision: 0,
    };

    return (
      <div className="CarSafeConfirmationView Container Container--small">
        <ConfirmationLayout
          policyNumber={carSafePurchaseInfo.policy_number}
          totals={this.totals}
          hideBillingSummary
          hideSmsForm
          billingCycle="month"
          disclaimer={disclaimer}
        >
          <SectionWrapper title="Policy holder details">
            <p>
              {[carSafeDetails.firstName, carSafeDetails.lastName].join(' ')}
            </p>
            <p>{carSafeDetails.streetAddress}</p>
            {carSafeDetails.unit && <p>{carSafeDetails.unit}</p>}
            <p>
              {[
                carSafeDetails.city,
                carSafeDetails.region,
                carSafeDetails.postal,
              ].join(', ')}
            </p>
            <p>{carSafeDetails.email}</p>
            <p>{carSafeDetails.policyholderId}</p>
            <p>{carSafeDetails.phone}</p>
          </SectionWrapper>

          {carSafeDetails.hasBeneficiary && (
            <SectionWrapper title="Beneficiary details">
              <p>{carSafeDetails.beneficiaryRelationship}</p>
              <p>
                {[
                  carSafeDetails.beneficiaryFirstName,
                  carSafeDetails.beneficiaryLastName,
                ].join(' ')}
              </p>
              <p>
                {carSafeDetails.beneficiaryId || carSafeDetails.beneficiaryDob}
              </p>
              <p>{carSafeDetails.beneficiaryEmail}</p>
              <p>{carSafeDetails.beneficiaryPhone}</p>
            </SectionWrapper>
          )}

          <SectionWrapper
            title={`Plan details: ${carSafeSelectedPlan.information.title}`}
          >
            <LineItemsList
              rows={[
                [
                  'Personal Possesion Theft (Wallet, handbag, etc.)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .personal_possession_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Electronics Theft (Laptop, phone, etc.)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .electronics_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Home Lock and Key Replacement',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .home_lock_and_key,
                    currencyOpts
                  ),
                ],
                [
                  'Identity Theft',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts.identity_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Accidental Death, Dismemberment & Disability (Motor vehicle accident only)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts.accidental_ddd,
                    currencyOpts
                  ),
                ],
              ]}
            />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList rows={[['Policies issued by', 'Chubb']]} />
            <LineItemsList rows={[['Billing frequency', 'Monthly']]} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList
              rows={[
                [
                  'Subtotal',
                  currencyFormat(this.totals.subtotal, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Tax',
                  currencyFormat(this.totals.tax, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Total',
                  <strong>
                    {currencyFormat(this.totals.grandtotal, {
                      ...currencyOpts,
                      precision: 2,
                    })}/month
                  </strong>,
                ],
              ]}
            />
          </SectionWrapper>
        </ConfirmationLayout>
      </div>
    );
  }
}

CarSafeConfirmationView.propTypes = propTypes;
CarSafeConfirmationView.defaultProps = defaultProps;

export default CarSafeConfirmationView;
