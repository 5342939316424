'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/MultiSelectFieldsNew/MultiSelectFieldsNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _CheckboxButton = require('shared/lib/components/CheckboxButton');

var _CheckboxButton2 = _interopRequireDefault(_CheckboxButton);

var _utils = require('shared/lib/utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var MultiSelectFieldsNew = function (_Component) {
  _inherits(MultiSelectFieldsNew, _Component);

  function MultiSelectFieldsNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MultiSelectFieldsNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MultiSelectFieldsNew.__proto__ || Object.getPrototypeOf(MultiSelectFieldsNew)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function () {
      _this.props.onChange(_this.value);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MultiSelectFieldsNew, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var choices = (0, _get3.default)(this.props.field, 'settings.choices') || [];
      var choicesNodes = choices.map(function (choice) {
        var isChecked = (0, _includes2.default)(_this2.props.defaultChecked, choice.value);
        return _react2.default.createElement(_CheckboxButton2.default, {
          key: _this2.props.field.key_name + choice.value,
          name: _this2.props.field.key_name,
          value: choice.value,
          defaultChecked: isChecked,
          title: choice.title,
          text: choice.help_text,
          ref: function ref(c) {
            return _this2[(0, _utils.slugifyFieldAndChoice)(_this2.props.field, choice)] = c;
          },
          onChange: _this2.handleChange,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 33
          },
          __self: _this2
        });
      });
      return _react2.default.createElement('div', {
        className: 'MultiSelectFieldsNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 46
        },
        __self: this
      }, choicesNodes);
    }
  }, {
    key: 'value',
    get: function get() {
      var _this3 = this;

      var choices = (0, _get3.default)(this.props.field, 'settings.choices') || [];
      var checkedChoices = (0, _filter2.default)(choices, function (choice) {
        var ref = _this3[(0, _utils.slugifyFieldAndChoice)(_this3.props.field, choice)];

        return ref.checked;
      });
      return _defineProperty({}, this.props.field.key_name, (0, _map2.default)(checkedChoices, 'value'));
    }
  }]);

  return MultiSelectFieldsNew;
}(_react.Component);

exports.default = MultiSelectFieldsNew;