'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SectionWrapperNew/SectionWrapperNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./SectionWrapperNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  onEditBtnClick: _propTypes2.default.func,
  note: _propTypes2.default.string
};
var defaultProps = {
  title: null,
  onEditBtnClick: null,
  note: null
};

var SectionWrapperNew = function (_PureComponent) {
  _inherits(SectionWrapperNew, _PureComponent);

  function SectionWrapperNew() {
    _classCallCheck(this, SectionWrapperNew);

    return _possibleConstructorReturn(this, (SectionWrapperNew.__proto__ || Object.getPrototypeOf(SectionWrapperNew)).apply(this, arguments));
  }

  _createClass(SectionWrapperNew, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          title = _props.title,
          children = _props.children,
          note = _props.note;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)(className, 'SectionWrapperNew'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      }, title && this.titleNode, children, note && _react2.default.createElement('p', {
        className: 'SectionWrapperNew-note',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 46
        },
        __self: this
      }, note));
    }
  }, {
    key: 'titleNode',
    get: function get() {
      var _props2 = this.props,
          title = _props2.title,
          onEditBtnClick = _props2.onEditBtnClick;

      if (!onEditBtnClick) {
        return _react2.default.createElement('h3', {
          className: 'SectionWrapperNew-title',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 22
          },
          __self: this
        }, title);
      } else {
        return _react2.default.createElement('div', {
          className: 'SectionWrapperNew-titleWrapper',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 25
          },
          __self: this
        }, _react2.default.createElement('h3', {
          className: 'SectionWrapperNew-title',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 26
          },
          __self: this
        }, title), _react2.default.createElement('span', {
          className: 'SectionWrapperNew-titleEditBtn',
          'data-section-title': title,
          onClick: onEditBtnClick,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 27
          },
          __self: this
        }, 'Edit'));
      }
    }
  }]);

  return SectionWrapperNew;
}(_react.PureComponent);

SectionWrapperNew.propTypes = propTypes;
SectionWrapperNew.defaultProps = defaultProps;
exports.default = SectionWrapperNew;