'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PaymentSectionNew/PaymentSectionNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _CheckboxButton = require('shared/lib/components/CheckboxButton');

var _CheckboxButton2 = _interopRequireDefault(_CheckboxButton);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _CreditCardFields = require('shared/lib/components/CreditCardFields');

var _CreditCardFields2 = _interopRequireDefault(_CreditCardFields);

var _iconMc = require('./icon-mc.svg');

var _iconMc2 = _interopRequireDefault(_iconMc);

var _iconVisa = require('./icon-visa.svg');

var _iconVisa2 = _interopRequireDefault(_iconVisa);

var _iconAmex = require('./icon-amex.svg');

var _iconAmex2 = _interopRequireDefault(_iconAmex);

var _iconDiscover = require('./icon-discover.svg');

var _iconDiscover2 = _interopRequireDefault(_iconDiscover);

var _iconSecure = require('./icon-secure.png');

var _iconSecure2 = _interopRequireDefault(_iconSecure);

require('./PaymentSectionNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  acknowledgmentHtml: _propTypes2.default.string,
  action: _propTypes2.default.oneOf(['charge', 'refund', 'no_change']),
  currencyCode: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  disclaimer: _propTypes2.default.string,
  onSubmit: _propTypes2.default.func,
  refundDetails: _propTypes2.default.shape({
    by_payment_method: _propTypes2.default.string
  }),
  totals: _propTypes2.default.object,
  partnerInfo: _propTypes2.default.object
};
var defaultProps = {
  currencyCode: 'USD',
  disabled: false,
  partnerInfo: null
};

var PaymentSectionNew = function (_PureComponent) {
  _inherits(PaymentSectionNew, _PureComponent);

  function PaymentSectionNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, PaymentSectionNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PaymentSectionNew.__proto__ || Object.getPrototypeOf(PaymentSectionNew)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isAcknowledgementChecked: false,
      isCreditCardComplete: false
    }, _this.handleSubmit = function (e) {
      e.preventDefault();

      if (!_this.isSubmitDisabled) {
        _this.props.onSubmit(_this.creditCardFields.element, _this.creditCardFields.state.cardName);
      }
    }, _this.handleCreditCardFormChange = function (_ref2) {
      var isComplete = _ref2.isComplete;

      _this.setState({
        isCreditCardComplete: isComplete
      });
    }, _this.handleAcknowledgmentChange = function (e) {
      _this.setState({
        isAcknowledgementChecked: e.target.checked
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(PaymentSectionNew, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          acknowledgmentHtml = _props.acknowledgmentHtml,
          disabled = _props.disabled,
          disclaimer = _props.disclaimer,
          partnerInfo = _props.partnerInfo;
      return _react2.default.createElement('form', {
        onSubmit: this.handleSubmit,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 94
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'PaymentSectionNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 95
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'PaymentSectionNew-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 96
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'PaymentSectionNew-wrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 97
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'PaymentSectionNew-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      }, 'Payment information'), _react2.default.createElement('div', {
        className: 'PaymentSectionNew-method',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 99
        },
        __self: this
      }, _react2.default.createElement('h3', {
        className: 'PaymentSectionNew-method-subtitle',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 100
        },
        __self: this
      }, 'Payment method'), _react2.default.createElement('div', {
        className: 'PaymentSectionNew-logos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 103
        },
        __self: this
      }, _react2.default.createElement('img', {
        className: 'PaymentSectionNew-logos-icon',
        src: _iconVisa2.default,
        alt: 'Visa logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'PaymentSectionNew-logos-icon',
        src: _iconMc2.default,
        alt: 'Mastercard logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 109
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'PaymentSectionNew-logos-icon',
        src: _iconAmex2.default,
        alt: 'Amex logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 114
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'PaymentSectionNew-logos-icon',
        src: _iconDiscover2.default,
        alt: 'Discover logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 119
        },
        __self: this
      })), _react2.default.createElement('div', {
        className: 'PaymentSectionNew-security',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 125
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'PaymentSectionNew-security-lockWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 126
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'PaymentSectionNew-security-lock fas fa-lock',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 127
        },
        __self: this
      })), _react2.default.createElement('p', {
        className: 'PaymentSectionNew-security-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      }, 'All transactions are ', _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 130
        },
        __self: this
      }), 'secure and encrypted.'), _react2.default.createElement('img', {
        className: 'PaymentSectionNew-security-icon PaymentSectionNew-security-icon--secure',
        src: _iconSecure2.default,
        alt: 'Security logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 133
        },
        __self: this
      }))), _react2.default.createElement(_CreditCardFields2.default, {
        ref: function ref(c) {
          return _this2.creditCardFields = c;
        },
        disabled: disabled,
        onChange: this.handleCreditCardFormChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 141
        },
        __self: this
      }), acknowledgmentHtml && _react2.default.createElement(_CheckboxButton2.default, {
        className: 'PaymentSectionNew-acknowledgmentButton',
        name: 'acknowledgment',
        value: 'true',
        isHtml: true,
        title: acknowledgmentHtml,
        ref: function ref(c) {
          return _this2.acknowledgmentCheckbox = c;
        },
        onChange: this.handleAcknowledgmentChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 147
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 158
        },
        __self: this
      }))), _react2.default.createElement(_Button2.default, {
        sureStyle: 'primaryNew',
        type: 'submit',
        disabled: this.isSubmitDisabled,
        isInProgress: disabled,
        isStickyOnMobile: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 165
        },
        __self: this
      }, 'Pay ' + this.grandTotal)));
    }
  }, {
    key: 'grandTotal',
    get: function get() {
      return _currencyFormatter2.default.format((0, _get3.default)(this.props.totals, 'grandtotal'), {
        code: this.props.currencyCode
      });
    }
  }, {
    key: 'isSubmitDisabled',
    get: function get() {
      var _props2 = this.props,
          acknowledgmentHtml = _props2.acknowledgmentHtml,
          action = _props2.action,
          disabled = _props2.disabled;

      if (disabled) {
        return true;
      } else if (action === 'no_change' || action === 'refund') {
        return false;
      } else if (acknowledgmentHtml) {
        return !this.state.isCreditCardComplete || !this.state.isAcknowledgementChecked;
      } else {
        return !this.state.isCreditCardComplete;
      }
    }
  }]);

  return PaymentSectionNew;
}(_react.PureComponent);

PaymentSectionNew.propTypes = propTypes;
PaymentSectionNew.defaultProps = defaultProps;
exports.default = PaymentSectionNew;