import Q from 'q';
import request from 'superagent';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import {
  createPaymentMethod,
  createStripeTokenFromElement,
  getDeviceInfo,
  logException,
  trimValues,
  destructureAxiosReq,
} from 'shared/lib/utils';
import axios from 'axios';
import * as analytics from 'shared/lib/analytics';
import * as rentersApi from './renters';
import * as rentalCarApi from './rentalCar';
import * as carSafeApi from './carsafe';
import * as homeSafeApi from './homesafe';
import * as bopApi from './bop';
import * as collectiblesApi from './collectibles';
import * as sharedApi from 'shared/lib/api';

let subdomain = window.location.hostname.split('.')[0];
if (subdomain === 'localhost') {
  subdomain = 'partner';
}

export function purchase(opts) {
  const deferred = Q.defer();
  opts.prefix = opts.prefix || '';
  opts.suffix = opts.suffix || '';
  opts.cardName = opts.cardName || '';
  opts = trimValues(opts);

  analytics.track(`${opts.prefix} - Purchase Initiated${opts.suffix}`, {
    category: 'order',
    label: opts.planId,
    value: opts.revenue,
  });

  new Promise((resolve, reject) => {
    if (opts.cardElement) {
      createStripeTokenFromElement(opts.cardElement, opts.cardName)
        .then(stripeToken => {
          analytics.track(
            `${opts.prefix} - Stripe Token Created${opts.suffix}`,
            {
              category: 'order',
              nonInteraction: 1,
            }
          );

          return createPaymentMethod({
            firstName: opts.firstName,
            lastName: opts.lastName,
            email: opts.email,
            getId: true,
            authToken: opts.authToken,
            apiEndpoint: `${
              process.env.REACT_APP_SURE_API_BASE_URL
            }/api/partner/v1/methods`,
            stripeToken,
          });
        })
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  })
    .then(paymentMethodId => {
      analytics.track(`${opts.prefix} - Payment Method Created${opts.suffix}`, {
        category: 'order',
        value: opts.authToken ? 1 : 0,
        nonInteraction: 1,
      });

      const apiDeferred = Q.defer();
      const browserEnv = {
        lat: opts.lat,
        lng: opts.lng,
        ...getDeviceInfo(),
      };

      if (!opts.authToken) {
        opts.payload.first_name = opts.firstName;
        opts.payload.last_name = opts.lastName;
        opts.payload.phone_number = opts.phoneNumber;
        opts.payload.email = opts.email;
      }

      const req = request
        .post(`${process.env.REACT_APP_SURE_API_BASE_URL}${opts.endpoint}`)
        .set('Authorization', `Token ${opts.authToken}`)
        .set('X-Environment', JSON.stringify(browserEnv));

      if (opts.uid) {
        req.set('X-Unique-Key', opts.uid);
      }

      req
        .send({
          payment_method_id: paymentMethodId,
          plan_id: opts.planId,
          ...opts.payload,
        })
        .end((error, response) => {
          if (error) {
            logException(error, {
              response: get(response, 'body'),
              payload: opts.payload,
            });
            apiDeferred.reject(error);
          } else {
            apiDeferred.resolve(response.body);
          }
        });

      return apiDeferred.promise;
    })
    .then((purchaseData = {}) => {
      if (isEmpty(purchaseData) || !isPlainObject(purchaseData)) {
        purchaseData = {};
      }

      if (purchaseData.account_id) {
        analytics.track('Dormant Account Created', {
          category: 'user',
          label: purchaseData.account_id,
        });
        analytics.alias(purchaseData.account_id);
      }
      analytics.track(`${opts.prefix} - Purchase Completed${opts.suffix}`, {
        category: 'order',
        label: opts.planId,
        value: opts.revenue,
        nonInteraction: 1,
      });
      analytics.track('Order Completed', {
        category: 'order',
        order_id: opts.uid || purchaseData.agreement_id,
        revenue: opts.revenue,
        email: opts.email,
        name: opts.firstName,
        surname: opts.lastName,
      });

      const hydratedPurchaseData = Object.assign(
        {
          _payload: opts.payload,
        },
        purchaseData
      );

      deferred.resolve(hydratedPurchaseData);
      return hydratedPurchaseData;
    })
    .catch(error => {
      logException(error);
      analytics.track(`${opts.prefix} - Purchase Failed${opts.suffix}`, {
        category: 'order',
        label: opts.planId,
        value: opts.revenue,
        nonInteraction: 1,
      });
      deferred.reject(error);
    });

  return deferred.promise;
}

const api = {
  /**
   * Retrieve white label site settings by current subdomain.
   * If domain is localhost, defaults to generic partner.
   */
  getSite() {
    return axios.get(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/site?site=${subdomain}`
    );
  },

  /**
   * Retrieve partner details.
   */
  getPartnerInfo(opts = {}) {
    return axios.get(
      `${process.env.REACT_APP_SURE_API_BASE_URL}/api/partner/v1/partner`
    );
  },

  /**
   * Get beneficiary relationship options.
   *
   * @param {Object} opts The options.
   * @param {String} [opts.authToken] The Sure API auth token.
   */
  getBeneficiaryRelationships({ authToken }) {
    return destructureAxiosReq(
      axios.get(
        `${
          process.env.REACT_APP_SURE_API_BASE_URL
        }/api/partner/v1/info/beneficiary_relationships`
      )
    );
  },

  ...rentersApi,
  ...bopApi,
  ...collectiblesApi,
  ...carSafeApi,
  ...homeSafeApi,
  ...rentalCarApi,
  ...sharedApi,
};

export default api;
