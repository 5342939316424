'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CreditCardField/CreditCardField.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _AddressForm = require('shared/lib/components/AddressForm');

var _AddressForm2 = _interopRequireDefault(_AddressForm);

var _trim = require('lodash/trim');

var _trim2 = _interopRequireDefault(_trim);

require('./CreditCardField.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  className: _propTypes2.default.string,
  extraCCFields: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  onChange: _propTypes2.default.func
};
var defaultProps = {
  className: '',
  extraCCFields: false,
  disabled: false,
  onChange: function onChange() {}
};

var CreditCardField = function (_PureComponent) {
  _inherits(CreditCardField, _PureComponent);

  function CreditCardField(props) {
    _classCallCheck(this, CreditCardField);

    var _this = _possibleConstructorReturn(this, (CreditCardField.__proto__ || Object.getPrototypeOf(CreditCardField)).call(this, props));

    _this.handleChange = function (e) {
      if (e.error) {
        _this.errorNode.textContent = e.error.message;
      } else {
        _this.errorNode.textContent = '';
      }

      _this.setState({
        isCardComplete: e.complete
      });

      _this.props.onChange({
        isComplete: e.complete && (!_this.props.extraCCFields || !!_this.state.cardName.length) && (!_this.props.extraCCFields || !!(0, _trim2.default)(_this.state.cardAddressInfo.streetAddress).length && !!(0, _trim2.default)(_this.state.cardAddressInfo.city).length && !!(0, _trim2.default)(_this.state.cardAddressInfo.region).length)
      });
    };

    _this.handleFieldChange = function (e) {
      _this.setState(_defineProperty({}, e.target.name, e.target.value));

      _this.props.onChange({
        isComplete: _this.state.isCardComplete && (!_this.props.extraCCFields || !!e.target.value.length)
      });
    };

    _this.updateCardAddressInfo = function (cardAddressInfo) {
      _this.setState({
        cardAddressInfo: cardAddressInfo
      });

      _this.props.onChange({
        isComplete: _this.state.isCardComplete && !!(0, _trim2.default)(cardAddressInfo.streetAddress).length && !!(0, _trim2.default)(cardAddressInfo.city).length && !!(0, _trim2.default)(cardAddressInfo.region).length
      });
    };

    _this.state = {
      cardName: '',
      isCardComplete: false,
      cardAddressInfo: {}
    };
    return _this;
  }

  _createClass(CreditCardField, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.init();
    }
  }, {
    key: 'init',
    value: function init() {
      if (!window.Stripe) {
        console.error('Cannot init CreditCardField without Stripe.');
        return false;
      }

      var elements = window.stripe.elements({
        fonts: [{
          family: 'Lato',
          src: 'url(https://fonts.googleapis.com/css?family=Lato)',
          weight: 400
        }]
      });
      this.cardElement = elements.create('card', {
        classes: {
          complete: 'CreditCardField-input--complete',
          focus: 'CreditCardField-input--focus',
          invalid: 'CreditCardField-input--invalid'
        },
        hidePostalCode: !this.props.extraCCFields,
        style: {
          base: {
            color: 'rgba(0, 52, 92, 1)',
            fontFamily: '"Lato", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px'
          },
          invalid: {
            color: 'rgb(187, 45, 59)'
          }
        }
      });
      this.cardElement.mount(this.mountNode);
      this.cardElement.on('change', this.handleChange.bind(this));
    }
  }, {
    key: 'reset',
    value: function reset() {
      this.cardElement.unmount();
      this.init();
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          className = _props.className,
          disabled = _props.disabled,
          extraCCFields = _props.extraCCFields;
      var _state = this.state,
          cardName = _state.cardName,
          cardAddressInfo = _state.cardAddressInfo;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('CreditCardField', className, {
          'is-disabled': disabled
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 139
        },
        __self: this
      }, extraCCFields && _react2.default.createElement(_react.Fragment, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 145
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'CreditCardField-label',
        htmlFor: 'cardName',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 146
        },
        __self: this
      }, 'Cardholder Name'), _react2.default.createElement(_TextInput2.default, {
        name: 'cardName',
        id: 'cardName',
        className: 'CreditCardField-nameInput',
        required: true,
        type: 'text',
        placeholder: 'Name on card',
        onChange: this.handleFieldChange,
        value: cardName,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 149
        },
        __self: this
      }), _react2.default.createElement('label', {
        className: 'CreditCardField-label',
        htmlFor: 'cardAddress',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 159
        },
        __self: this
      }, 'Cardholder Address'), _react2.default.createElement(_AddressForm2.default, {
        name: 'cardAddress',
        streetAddress: cardAddressInfo.streetAddress,
        unit: cardAddressInfo.unit,
        city: cardAddressInfo.city,
        region: cardAddressInfo.region,
        postalType: 'hidden',
        required: true,
        country: cardAddressInfo.country,
        onChange: this.updateCardAddressInfo,
        ref: function ref(c) {
          return _this2.residenceAddressInput = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 162
        },
        __self: this
      })), _react2.default.createElement('label', {
        className: 'CreditCardField-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 176
        },
        __self: this
      }, 'Card Details'), _react2.default.createElement('div', {
        ref: function ref(c) {
          return _this2.mountNode = c;
        },
        className: 'CreditCardField-input',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 177
        },
        __self: this
      }), _react2.default.createElement('div', {
        ref: function ref(c) {
          return _this2.errorNode = c;
        },
        className: 'CreditCardField-error',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 181
        },
        __self: this
      }));
    }
  }, {
    key: 'elements',
    get: function get() {
      return {
        cardElement: this.cardElement,
        cardName: this.state.cardName,
        cardAddressLine1: this.state.cardAddressInfo.streetAddress,
        cardAddressCity: this.state.cardAddressInfo.city,
        cardAddressState: this.state.cardAddressInfo.region
      };
    }
  }]);

  return CreditCardField;
}(_react.PureComponent);

CreditCardField.propTypes = propTypes;
CreditCardField.defaultProps = defaultProps;
exports.default = CreditCardField;