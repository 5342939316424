import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import './LandingAboutChubb.css';

export default class LandingAboutChubb extends PureComponent {
  componentDidMount() {
    analytics.page('About Chubb');
  }

  render() {
    return (
      <div className="LandingAboutChubb ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb
          accentColor="#ffb617"
          bgImage="/images/chubb/landing-carsafe.png"
        >
          <h1 className="LandingAboutChubb-heroTitle">
            Chubb is the world’s largest publicly traded property and casualty
            insurer.
          </h1>
        </HeroChubb>
        <div className="LandingAboutChubb-content">
          <div className="Container">
            <h2 className="LandingAboutChubb-content-title">About Chubb</h2>
            <p className="LandingAboutChubb-content-info">
              Chubb is the world’s largest publicly traded property and casualty
              insurer. With operations in 54 countries, Chubb provides
              commercial and personal property and casualty insurance, personal
              accident and supplemental health insurance, reinsurance and life
              insurance to a diverse group of clients. As an underwriting
              company, we assess, assume and manage risk with insight and
              discipline. We service and pay our claims fairly and promptly. We
              combine the precision of craftsmanship with decades of experience
              to conceive, craft and deliver the very best insurance coverage
              and service to individuals and families, and businesses of all
              sizes. Chubb is also defined by its extensive product and service
              offerings, broad distribution capabilities, exceptional financial
              strength and local operations globally. The company serves
              multinational corporations, mid-size and small businesses with
              property and casualty insurance and risk engineering services;
              affluent and high net worth individuals with substantial assets to
              protect; individuals purchasing life, personal accident,
              supplemental health, homeowners, automobile and specialty personal
              insurance coverage; companies and affinity groups providing or
              offering accident and health insurance programs and life insurance
              to their employees or members; and insurers managing exposures
              with reinsurance coverage.
            </p>
            <p className="LandingAboutChubb-content-info">
              Chubb has more than $160 billion in assets and $35 billion of
              gross premiums written in 2016. Chubb’s core operating insurance
              companies maintain financial strength ratings of AA from Standard
              & Poor’s and A++ from A.M. Best. Chubb Limited, the parent company
              of Chubb, is listed on the New York Stock Exchange (NYSE: CB) and
              is a component of the S&P 500 index.
            </p>
            <p className="LandingAboutChubb-content-info">
              Chubb maintains executive offices in Zurich, New York, London and
              other locations, and employs approximately 31,000 people
              worldwide.
            </p>
            <p className="LandingAboutChubb-content-info">
              In South Africa, Chubb has been trading in the since July 2006 and
              holds a local financial strength rating of AA(ZA) from Global
              Credit Rating Company (GCR).
            </p>
          </div>
        </div>
        <FooterChubb />
      </div>
    );
  }
}
