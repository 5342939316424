import React, { PureComponent } from 'react';
import Button from 'shared/lib/components/Button';
import { RENTAL_CAR_ROUTES } from 'shared/lib/apps/RentalCarApp/routes';
import * as analytics from 'shared/lib/analytics';
import './LandingRentalCarEmbed.css';

export default class LandingRentalCarEmbed extends PureComponent {
  componentDidMount() {
    analytics.page('Rental Car - Landing');
  }

  render() {
    return (
      <div className="LandingRentalCarEmbed">
        <div className="LandingRentalCarEmbed-hero">
          <div className="Container">
            <h1 className="LandingRentalCarEmbed-hero-heading">
              You’ve got enough to worry about while driving a rental
            </h1>
            <p className="LandingRentalCarEmbed-hero-pitch">
              Insurance is the last thing you want to think about when you’re
              traveling. Why wait until you’re at the rental counter? Put your
              mind at ease. Sure takes the pain out of the process to and offers
              get you comprehensive coverage at a more affordable rate.
            </p>
            <Button
              className="LandingRentalCarEmbed-cta"
              to={`/embed/v1/${RENTAL_CAR_ROUTES.RENTAL_INFO}`}
              shadow
            >
              <i className="material-icons">&#xE8E8;</i>
              Check our prices
            </Button>
            <p className="LandingRentalCarEmbed-hero-disclaimer">
              We'll never sell or share your personal information
            </p>
          </div>
        </div>

        <div className="LandingRentalCarEmbed-coverage">
          <div className="Container">
            <h2 className="LandingRentalCarEmbed-coverage-heading">
              What does car rental insurance cover?
            </h2>
            <p className="LandingRentalCarEmbed-coverage-info">
              Rental insurance bought through an agency can cost an additional
              50% of your daily car rental rate. With Sure, you’ll get better
              coverage for a fraction of the cost:
            </p>
            <div className="LandingRentalCarEmbed-coverage-threeUp">
              <div className="LandingRentalCarEmbed-coverage-threeUp-card">
                <div className="LandingRentalCarEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentalCarEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">warning</i>
                  </div>
                </div>
                <h3 className="LandingRentalCarEmbed-coverage-threeUp-card-heading">
                  Physical damage coverage
                </h3>
                <p className="LandingRentalCarEmbed-coverage-threeUp-card-info">
                  From minor fender-benders to more serious accidents, you’ll be
                  covered for physical damage to the vehicle up to $100,000.
                  Your property within the car is covered, too.
                </p>
              </div>
              <div className="LandingRentalCarEmbed-coverage-threeUp-card">
                <div className="LandingRentalCarEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentalCarEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">build</i>
                  </div>
                </div>
                <h3 className="LandingRentalCarEmbed-coverage-threeUp-card-heading">
                  Miscellaneous rental car snafus
                </h3>
                <p className="LandingRentalCarEmbed-coverage-threeUp-card-info">
                  Things happen when it’s not your car. Lost keys, towing
                  charges, flat tires and property theft can cost a small
                  fortune to manage on the road. We’ve got you covered for each.
                </p>
              </div>
              <div className="LandingRentalCarEmbed-coverage-threeUp-card">
                <div className="LandingRentalCarEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentalCarEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">smartphone</i>
                  </div>
                </div>
                <h3 className="LandingRentalCarEmbed-coverage-threeUp-card-heading">
                  Better planning & no unexpected charges
                </h3>
                <p className="LandingRentalCarEmbed-coverage-threeUp-card-info">
                  Type A travelers, rejoice! With Sure, you can buy rental
                  insurance before you ever leave your house. Your to-do list
                  will thank you, and you’ll avoid unexpected charges at the
                  counter.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="LandingRentalCarEmbed-pricing">
          <h2 className="LandingRentalCarEmbed-pricing-heading">
            Here’s what you’d invest
          </h2>
          <p className="LandingRentalCarEmbed-pricing-info">
            Plans start at less than $10/per day. That’s some serious peace of
            mind for what you already pay for:
          </p>
          <p className="LandingRentalCarEmbed-pricing-comparisonText">
            Here’s where that money would be going otherwise:
          </p>
          <div className="LandingRentalCarEmbed-pricing-examples">
            <div className="LandingRentalCarEmbed-pricing-examples-example">
              <div className="LandingRentalCarEmbed-pricing-examples-example-icon">
                <i className="material-icons">local_gas_station</i>
              </div>
              <p className="LandingRentalCarEmbed-pricing-examples-example-text">
                5 gallons of gas
              </p>
            </div>
            <div className="LandingRentalCarEmbed-pricing-examples-example">
              <div className="LandingRentalCarEmbed-pricing-examples-example-icon">
                <i className="material-icons">radio</i>
              </div>
              <p className="LandingRentalCarEmbed-pricing-examples-example-text">
                Your monthly SiriusXM subscription
              </p>
            </div>
            <div className="LandingRentalCarEmbed-pricing-examples-example">
              <div className="LandingRentalCarEmbed-pricing-examples-example-icon">
                <i className="material-icons">ondemand_video</i>
              </div>
              <p className="LandingRentalCarEmbed-pricing-examples-example-text">
                Data overages to cover streaming video
              </p>
            </div>
            <div className="LandingRentalCarEmbed-pricing-examples-example">
              <div className="LandingRentalCarEmbed-pricing-examples-example-icon">
                <i className="material-icons">restaurant</i>
              </div>
              <p className="LandingRentalCarEmbed-pricing-examples-example-text">
                2 drive-through meals
              </p>
            </div>
          </div>
        </div>

        <div className="LandingRentalCarEmbed-bottomCta">
          <h3 className="LandingRentalCarEmbed-bottomCta-heading">
            Renting a car should be fun, not worrisome.
            <br />
            Protect yourself today.
          </h3>
          <Button
            className="LandingRentalCarEmbed-cta"
            to={`/embed/v1/${RENTAL_CAR_ROUTES.RENTAL_INFO}`}
            sureStyle="success"
            shadow
          >
            <i className="material-icons">&#xE8E8;</i>
            Get Started
          </Button>
        </div>

        <div className="LandingRentalCarEmbed-footer">
          <div className="Container">
            <p>
              Content and associated insurance products are provided by Sure
              HIIS Insurance Services, LLC (“Sure”), a licensed seller of
              insurance. The above does not in any way constitute an endorsement
              or referral by the hosting company of Sure’s products or services.
              Products may not be offered in all states. Read our{' '}
              <a
                href="https://www.sureapp.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://www.sureapp.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}
