'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/components/Button/Button.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouter = require('react-router');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./Button.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disabled: _propTypes2.default.bool,
  href: _propTypes2.default.string,
  isInProgress: _propTypes2.default.bool,
  isStickyOnMobile: _propTypes2.default.bool,
  onClick: _propTypes2.default.func,
  rel: _propTypes2.default.string,
  shadow: _propTypes2.default.bool,
  style: _propTypes2.default.object,
  sureStyle: _propTypes2.default.oneOf(['default', 'success', 'secondary', 'destructive', 'outline', 'facebook', 'primaryNew', 'outlineNew']),
  to: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.shape({
    pathname: _propTypes2.default.string,
    query: _propTypes2.default.string
  })])
};
var defaultProps = {
  disabled: false,
  href: null,
  isInProgress: false,
  isStickyOnMobile: false,
  rel: null,
  shadow: false,
  style: null,
  sureStyle: 'default',
  to: null
};

var Button = function (_PureComponent) {
  _inherits(Button, _PureComponent);

  function Button() {
    _classCallCheck(this, Button);

    return _possibleConstructorReturn(this, (Button.__proto__ || Object.getPrototypeOf(Button)).apply(this, arguments));
  }

  _createClass(Button, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          to = _props.to,
          rel = _props.rel,
          href = _props.href,
          disabled = _props.disabled,
          type = _props.type,
          sureStyle = _props.sureStyle,
          shadow = _props.shadow,
          isInProgress = _props.isInProgress,
          isStickyOnMobile = _props.isStickyOnMobile,
          className = _props.className,
          passedThroughState = _props.passedThroughState,
          otherProps = _objectWithoutProperties(_props, ['to', 'rel', 'href', 'disabled', 'type', 'sureStyle', 'shadow', 'isInProgress', 'isStickyOnMobile', 'className', 'passedThroughState']);

      if (to) {
        return _react2.default.createElement(_reactRouter.Link, _extends({
          className: this.classes,
          rel: rel,
          to: {
            pathname: to,
            state: Object.assign({}, passedThroughState)
          }
        }, otherProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 88
          },
          __self: this
        }), this.buttonContent);
      } else if (href) {
        return _react2.default.createElement('a', _extends({
          className: this.classes,
          rel: rel,
          href: href
        }, otherProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 102
          },
          __self: this
        }), this.buttonContent);
      } else {
        return _react2.default.createElement('button', _extends({
          className: this.classes,
          disabled: disabled,
          type: type
        }, otherProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 108
          },
          __self: this
        }), this.buttonContent);
      }
    }
  }, {
    key: 'classes',
    get: function get() {
      return (0, _classnames2.default)(this.props.className, 'Button', 'Button--' + this.props.sureStyle, {
        'Button--shadow': this.props.shadow,
        'is-disabled': this.props.disabled,
        'is-sticky': this.props.isStickyOnMobile
      });
    }
  }, {
    key: 'buttonContent',
    get: function get() {
      if (this.props.isInProgress) {
        return _react2.default.createElement('span', {
          className: 'Button-spinner',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 63
          },
          __self: this
        });
      } else {
        return this.props.children;
      }
    }
  }]);

  return Button;
}(_react.PureComponent);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
exports.default = Button;