import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import { Link } from 'react-router';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import './ChubbSitemapView.css';

export default class ChubbSitemapView extends PureComponent {
  componentDidMount() {
    analytics.page('Chubb Sitemap');
  }

  render() {
    return (
      <div className="ChubbSitemapView ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#150f96">
          <h1 className="ChubbSitemapView-heroTitle">Chubb Sitemap</h1>
        </HeroChubb>
        <div className="ChubbSitemapView-content">
          <div className="Container">
            <ul className="ChubbSitemapView-content-list">
              <li className="ChubbSitemapView-content-listItem">
                <Link
                  to="/vehicle-contents-insurance"
                  className="ChubbSitemapView-content-listItem-link"
                >
                  Vehicle Contents Insurance
                </Link>
              </li>
              <li className="ChubbSitemapView-content-listItem">
                <Link
                  to="/vehicle-contents-insurance/faq"
                  className="ChubbSitemapView-content-listItem-link"
                >
                  Vehicle Contents Insurance FAQ
                </Link>
              </li>
              <li className="ChubbSitemapView-content-listItem">
                <Link
                  to="/chubb/claims"
                  className="ChubbSitemapView-content-listItem-link"
                >
                  How to Claim
                </Link>
              </li>
              <li className="ChubbSitemapView-content-listItem">
                <Link
                  to="/about-chubb"
                  className="ChubbSitemapView-content-listItem-link"
                >
                  About Chubb
                </Link>
              </li>
              <li className="ChubbSitemapView-content-listItem">
                <Link
                  to="/contact-chubb"
                  className="ChubbSitemapView-content-listItem-link"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <FooterChubb />
      </div>
    );
  }
}
