'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistedRentersReducerKeys = exports.rentersReducers = undefined;

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _findIndex = require('lodash/findIndex');

var _findIndex2 = _interopRequireDefault(_findIndex);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _pickBy = require('lodash/pickBy');

var _pickBy2 = _interopRequireDefault(_pickBy);

var _startsWith = require('lodash/startsWith');

var _startsWith2 = _interopRequireDefault(_startsWith);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _utils = require('shared/lib/utils');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var rentersReducers = {
  rentersPlan: function rentersPlan() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return action.data.plans[0] || null;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return null;

      default:
        return state;
    }
  },

  /*
   * Personal information
   * ========================================
   */
  rentersPropertyAddress: function rentersPropertyAddress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTERS_ADDRESS:
        return Object.assign({}, state, action.address);

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },
  rentersZipCodeResults: function rentersZipCodeResults() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return [];

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return (0, _get2.default)(action.data.zip_code_results, 'locations') || [];

      default:
        return state;
    }
  },

  /*
   * Survey flow
   * ========================================
   */
  rentersScreens: function rentersScreens() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return action.data.dynamic_form.screens;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },
  rentersScreenSequence: function rentersScreenSequence() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    if (action.type === _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS) {
      var dynamic_form = action.data.dynamic_form;
      var map = [{
        id: dynamic_form.start_screen_id,
        parentId: null
      }];
      var trail = (0, _utils.generateTrail)(dynamic_form.screens, dynamic_form.start_screen_id, null);
      return map.concat(trail);
    } else if (action.type === _actionTypes2.default.SELECT_RENTERS_CHOICE) {
      var _action$getState = action.getState(),
          rentersCurrentScreenId = _action$getState.rentersCurrentScreenId,
          rentersScreens = _action$getState.rentersScreens;

      var currentIndex = (0, _findIndex2.default)(state, {
        id: rentersCurrentScreenId
      });
      var trailToAdd = [];
      var parentId = state[currentIndex].id;

      var _trail = (0, _utils.generateTrail)(rentersScreens, action.screen_id, parentId);

      var initialObj = {
        id: action.screen_id,
        parentId: parentId,
        first: true
      };
      var pniScreenId = (0, _utils.findPniScreenId)(rentersScreens);

      if (action.screen_id !== pniScreenId) {
        trailToAdd.push(initialObj);
      }

      if (_trail.length) {
        trailToAdd = trailToAdd.concat(_trail);
      }

      var start = state.slice(0, currentIndex + 1);
      var end = state.slice(currentIndex + 1);
      var newMap = start.concat(trailToAdd).concat(end);
      return newMap;
    } else if (action.type === _actionTypes2.default.RENTERS_SURVEY_BACK) {
      var _action$getState2 = action.getState(),
          _rentersCurrentScreenId = _action$getState2.rentersCurrentScreenId;

      var _currentIndex = (0, _findIndex2.default)(state, {
        id: _rentersCurrentScreenId
      });

      if (_currentIndex !== 0) {
        var current = state[_currentIndex];
        var _newMap = []; // Clear all step created from this parent

        if (current.first && current.parentId) {
          state.forEach(function (step, idx) {
            if (step.parentId !== current.parentId) {
              _newMap.push(step);
            }
          });
          return _newMap;
        }

        return state;
      } else {
        return state;
      }
    } else {
      return state;
    }
  },
  rentersCurrentScreenId: function rentersCurrentScreenId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];
    var nextState = void 0;

    var _ref = action.getState && action.getState() || {},
        screenSequence = _ref.rentersScreenSequence;

    if (action.type === _actionTypes2.default.RENTERS_SURVEY_NEXT) {
      var currentIndex = (0, _findIndex2.default)(screenSequence, {
        id: state
      });
      var nextIndex = currentIndex + 1;

      if (screenSequence[nextIndex]) {
        nextState = screenSequence[nextIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.RENTERS_SURVEY_BACK) {
      var _currentIndex2 = (0, _findIndex2.default)(screenSequence, {
        id: state
      });

      var prevIndex = _currentIndex2 - 1;

      if (_currentIndex2 !== 0) {
        nextState = screenSequence[prevIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS) {
      nextState = action.data.dynamic_form.start_screen_id;
    } else {
      nextState = state;
    }

    return nextState;
  },
  rentersCurrentResponse: function rentersCurrentResponse() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_RENTERS_CURRENT_RESPONSE:
        return Object.assign({}, state, action.payload.response);

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.SAVE_RENTERS_RESPONSE:
      case _actionTypes2.default.RENTERS_SURVEY_BACK:
      case _actionTypes2.default.RENTERS_SURVEY_NEXT:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },
  rentersResponses: function rentersResponses() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];
    var newState = (0, _cloneDeep2.default)(state);

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
        return (0, _pickBy2.default)(action.data.rentersResponses, function (val, key) {
          return (0, _startsWith2.default)(key, 'pni_');
        });

      case _actionTypes2.default.SAVE_RENTERS_RESPONSE:
        Object.assign(newState, action.response);
        return newState;

      case _actionTypes2.default.DELETE_RENTERS_RESPONSE:
        return (0, _omit2.default)(state, action.payload.responseKeys);

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },

  /*
   * Quote and checkout
   * ========================================
   */
  isFetchingRentersQuote: function isFetchingRentersQuote() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE:
        return true;

      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_SUCCESS:
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_FAILURE:
        return false;

      default:
        return state;
    }
  },
  rentersRates: function rentersRates() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_SUCCESS:
        return action.data.rates || {};

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },
  rentersRatesFetchedAt: function rentersRatesFetchedAt() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_RENTERS_RATES_SUCCESS:
        return Date.now();

      case _actionTypes2.default.GET_RENTERS_RATES:
      case _actionTypes2.default.GET_RENTERS_RATES_FAILURE:
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.SAVE_RENTERS_RESPONSE:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return null;

      default:
        return state;
    }
  },
  rentersQuote: function rentersQuote() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_SUCCESS:
        return action.data.quote;

      case _actionTypes2.default.GET_RENTERS_RATES_SUCCESS:
        return Object.assign({}, state, {
          quote: {
            id: action.payload.quoteId
          }
        });

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.SAVE_RENTERS_RESPONSE:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return null;

      default:
        return state;
    }
  },
  rentersPolicyStartDate: function rentersPolicyStartDate() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _momentTimezone2.default.utc().toISOString();
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return _momentTimezone2.default.utc().add(1, 'day').toISOString();

      case _actionTypes2.default.UPDATE_RENTERS_START_DATE:
        return action.payload.isoString;

      default:
        return state;
    }
  },
  rentersSelectedAmounts: function rentersSelectedAmounts() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return {
          personal_property_amount: (0, _get2.default)(action.data, 'plans[0].details.personal_property_amount.default')
        };

      case _actionTypes2.default.UPDATE_RENTERS_SELECTED_AMOUNTS:
        return Object.assign({}, state, action.data);

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return {};

      default:
        return state;
    }
  },
  isRentersQuoteStale: function isRentersQuoteStale() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_SUCCESS:
      case _actionTypes2.default.CALCULATE_RENTERS_QUOTE_FAILURE:
        return false;

      case _actionTypes2.default.UPDATE_RENTERS_SELECTED_AMOUNTS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return true;

      default:
        return state;
    }
  },
  rentersPaymentCadences: function rentersPaymentCadences() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_PAYMENT_CADENCES_SUCCESS:
        return action.payload;

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
        return null;

      default:
        return state;
    }
  },
  rentersSelectedPaymentCadence: function rentersSelectedPaymentCadence() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_ADDRESS_SUCCESS:
        return (0, _get2.default)(action.data, 'plans[0].subscription_options.default_cadence');

      case _actionTypes2.default.SELECT_PAYMENT_CADENCE:
        return action.payload.rentersPaymentCadence;

      default:
        return state;
    }
  },
  rentersPlanNumber: function rentersPlanNumber() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_RENTERS_SUCCESS:
        return action.data.policy_number;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return null;

      default:
        return state;
    }
  },
  rentersPolicyNumber: function rentersPolicyNumber() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_RENTERS_SUCCESS:
        return (0, _get2.default)(action.data, 'details.ex_policy_number');

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_RENTERS_DATA:
        return null;

      default:
        return state;
    }
  },
  isRentersInProgress: function isRentersInProgress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS:
      case _actionTypes2.default.GET_PAYMENT_CADENCES:
      case _actionTypes2.default.PURCHASE_RENTERS:
        return true;

      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS:
      case _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_FAILURE:
      case _actionTypes2.default.GET_PAYMENT_CADENCES_SUCCESS:
      case _actionTypes2.default.GET_PAYMENT_CADENCES_FAILURE:
      case _actionTypes2.default.PURCHASE_RENTERS_SUCCESS:
      case _actionTypes2.default.PURCHASE_RENTERS_FAILURE:
        return false;

      default:
        return state;
    }
  }
};
var persistedRentersReducerKeys = (0, _without2.default)(Object.keys(rentersReducers), 'isRentersInProgress', 'isFetchingRentersQuote', 'rentersCurrentResponse', 'isRentersQuoteStale');
exports.rentersReducers = rentersReducers;
exports.persistedRentersReducerKeys = persistedRentersReducerKeys;