'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarAddDrivers/RentalCarAddDrivers.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _sureComponents = require('sure-components');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _utils = require('shared/lib/utils');

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarAddDrivers.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  addAdditionalDriver: _propTypes2.default.func,
  additionalDrivers: _propTypes2.default.array,
  disclaimer: _propTypes2.default.string,
  updateAdditionalDrivers: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null
};

var RentalCarAddDrivers = function (_Component) {
  _inherits(RentalCarAddDrivers, _Component);

  function RentalCarAddDrivers() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, RentalCarAddDrivers);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentalCarAddDrivers.__proto__ || Object.getPrototypeOf(RentalCarAddDrivers)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.track('Rental Car - Next Button Clicked', {
        category: 'survey'
      });

      _this.props.removeRentalCarEmptyAdditionalDrivers();

      _this.props.navTo((0, _utils.resolveUrlPath)('plans'));
    }, _this.handleUpdate = function () {
      var newDriversObj = (0, _map2.default)(_this.props.additionalDrivers, function (_, i) {
        return {
          firstName: _this['inputFirstName' + i].value,
          lastName: _this['inputLastName' + i].value
        };
      });

      _this.props.updateAdditionalDrivers(newDriversObj);
    }, _this.handleAddDriver = function () {
      analytics.track('Rental Car - Add Additional Driver Clicked', {
        category: 'survey',
        value: _this.props.additionalDrivers.length
      });

      _this.props.addAdditionalDriver();
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentalCarAddDrivers, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Additional Drivers Form', {
        category: 'survey'
      });

      if ((0, _isEmpty2.default)(this.props.additionalDrivers)) {
        this.props.addAdditionalDriver();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentalCarAddDrivers',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 96
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 97
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarAddDrivers-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      }, "Please give us the names of the drivers you\u2019d like added to your insurance"), _react2.default.createElement('form', {
        className: 'RentalCarAddDrivers-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, this.formNodes, _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      })), this.props.additionalDrivers.length < 5 && _react2.default.createElement('button', {
        className: 'RentalCarAddDrivers-addButton',
        onClick: this.handleAddDriver,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 111
        },
        __self: this
      }, 'Add another driver'), _react2.default.createElement(_sureComponents.PagerButtons, {
        isNextDisabled: this.isNextDisabled,
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 118
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 123
        },
        __self: this
      })));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      return (0, _some2.default)(this.props.additionalDrivers, function (driverObj) {
        return (0, _isEmpty2.default)(driverObj.firstName) !== (0, _isEmpty2.default)(driverObj.lastName);
      });
    }
  }, {
    key: 'formNodes',
    get: function get() {
      var _this2 = this;

      return (0, _map2.default)(this.props.additionalDrivers, function (driverObj, i) {
        return _react2.default.createElement('div', {
          key: i,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 42
          },
          __self: _this2
        }, _react2.default.createElement('label', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 43
          },
          __self: _this2
        }, 'Additional Driver ', i + 1), _react2.default.createElement(_sureComponents.FormGroup, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 44
          },
          __self: _this2
        }, _react2.default.createElement(_sureComponents.TextInput, {
          name: 'first_name',
          placeholder: 'First name',
          ref: function ref(c) {
            return _this2['inputFirstName' + i] = c;
          },
          maxLength: '40',
          value: driverObj.firstName,
          onChange: _this2.handleUpdate,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 45
          },
          __self: _this2
        }), _react2.default.createElement(_sureComponents.TextInput, {
          name: 'last_name',
          placeholder: 'Last name',
          ref: function ref(c) {
            return _this2['inputLastName' + i] = c;
          },
          maxLength: '40',
          value: driverObj.lastName,
          onChange: _this2.handleUpdate,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 53
          },
          __self: _this2
        })));
      });
    }
  }]);

  return RentalCarAddDrivers;
}(_react.Component);

RentalCarAddDrivers.propTypes = propTypes;
RentalCarAddDrivers.defaultProps = defaultProps;
exports.default = RentalCarAddDrivers;