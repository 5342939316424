'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getStateFromUrl;

var _queryString = require('query-string');

var _camelCase = require('lodash/camelCase');

var _camelCase2 = _interopRequireDefault(_camelCase);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _hasIn = require('lodash/hasIn');

var _hasIn2 = _interopRequireDefault(_hasIn);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function getStateFromUrl() {
  var queryStr = (0, _queryString.parse)(window.location.search);
  var urlState = {};

  if ((0, _hasIn2.default)(queryStr, 'isEmbed')) {
    urlState.isEmbed = !!queryStr.isEmbed;
    delete queryStr.isEmbed;
  }

  if ((0, _hasIn2.default)(queryStr, 'authToken')) {
    urlState.authToken = queryStr.authToken;
    delete queryStr.authToken;
  }

  var updateUrlState = function updateUrlState(queryStrParams, key, verticalStateObj, verticalStateKey) {
    if (key === 'phone_number') {
      return urlState[verticalStateObj][verticalStateKey || (0, _camelCase2.default)(key)] = queryStr[key].slice(-10).slice(0, queryStrParams[key]);
    } else {
      return urlState[verticalStateObj][verticalStateKey || (0, _camelCase2.default)(key)] = queryStr[key].slice(0, queryStrParams[key]);
    }
  };
  /**
   * User info
   * ==============================
   */


  var propertyAddressKeys = {
    street_address: 60,
    unit: 60,
    city: 30,
    region: 30,
    postal: 30
  };
  var userInfoKeys = {
    first_name: 20,
    middle_name: 20,
    last_name: 20,
    suffix: 10,
    phone_number: 10,
    email: 50
  };

  var updateUrlAddressState = function updateUrlAddressState() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return updateUrlState.apply(undefined, [propertyAddressKeys].concat(args));
  };

  var updateUrlUserInfoState = function updateUrlUserInfoState() {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return updateUrlState.apply(undefined, [userInfoKeys].concat(args));
  };

  (0, _each2.default)(propertyAddressKeys, function (val, key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.rentersPropertyAddress = urlState.rentersPropertyAddress || {};
      urlState.collectiblesPropertyAddress = urlState.collectiblesPropertyAddress || {};
      urlState.bopRatingAddress = urlState.bopRatingAddress || {};
      urlState.rentalCarDriver = urlState.rentalCarDriver || {};
      urlState.petOwnerInfo = urlState.petOwnerInfo || {};
      urlState.landlordLandlordInfo = urlState.landlordLandlordInfo || {};
      updateUrlAddressState(key, 'rentersPropertyAddress');
      updateUrlAddressState(key, 'collectiblesPropertyAddress');
      updateUrlAddressState(key, 'bopRatingAddress');
      updateUrlAddressState(key, 'rentalCarDriver');
      updateUrlAddressState(key, 'petOwnerInfo');
      updateUrlAddressState(key, 'landlordLandlordInfo');
      delete queryStr[key];
    }
  });
  (0, _each2.default)(userInfoKeys, function (val, key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.rentersResponses = urlState.rentersResponses || {};
      urlState.collectiblesResponses = urlState.collectiblesResponses || {};
      urlState.rentalCarDriver = urlState.rentalCarDriver || {};
      urlState.petOwnerInfo = urlState.petOwnerInfo || {};
      urlState.landlordLandlordInfo = urlState.landlordLandlordInfo || {};
      updateUrlUserInfoState(key, 'collectiblesResponses', 'owner_' + key);
      updateUrlUserInfoState(key, 'rentersResponses', 'pni_' + key);
      updateUrlUserInfoState(key, 'rentalCarDriver');
      updateUrlUserInfoState(key, 'petOwnerInfo');
      updateUrlUserInfoState(key, 'landlordLandlordInfo');
      delete queryStr[key];
    }
  });
  /**
   * Renters-specific
   * ==============================
   */

  var selectedAmountsKeys = ['liability_limit', 'min_liability_limit'];
  (0, _each2.default)(selectedAmountsKeys, function (key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.ratesSettings = urlState.ratesSettings || {};
      urlState.ratesSettings[key] = parseInt(queryStr[key], 10);
      delete queryStr[key];
    }
  });
  /**
   * Pet-specific
   * ==============================
   */

  var petCoOwnerNameKeys = ['co_owner_first_name', 'co_owner_last_name'];
  (0, _each2.default)(petCoOwnerNameKeys, function (key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.petOwnerInfo = urlState.petOwnerInfo || {};
      urlState.petOwnerInfo[(0, _camelCase2.default)(key)] = queryStr[key];
      delete queryStr[key];
    }
  });
  var petInfoKeys = ['pet_dob_month', 'pet_dob_year', 'pet_gender', 'pet_name', 'pet_type'];
  (0, _each2.default)(petInfoKeys, function (key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.petInfo = urlState.petInfo || {};
      urlState.petInfo[(0, _camelCase2.default)(key.slice(4))] = queryStr[key];
      delete queryStr[key];
    }
  });
  /**
   * Landlord-specific
   * ==============================
   */

  var leaseInfoKeys = ['lease_street_address', 'lease_unit', 'lease_city', 'lease_region', 'lease_postal'];
  (0, _each2.default)(leaseInfoKeys, function (key) {
    if ((0, _hasIn2.default)(queryStr, key)) {
      urlState.landlordCurrentLease = urlState.landlordCurrentLease || {};
      urlState.landlordCurrentLease[(0, _camelCase2.default)(key.slice(6))] = queryStr[key];
      delete queryStr[key];
    }
  });
  /**
   * Rental car specific
   * ==============================
   */

  if (queryStr.pick_up_date) {
    urlState.rentalCarPickUpDate = (0, _momentTimezone2.default)(queryStr.pick_up_date, 'YYYY-MM-DD').toISOString();
    delete queryStr.pick_up_date;
  }

  if (queryStr.return_date) {
    urlState.rentalCarReturnDate = (0, _momentTimezone2.default)(queryStr.return_date, 'YYYY-MM-DD').toISOString();
    delete queryStr.return_date;
  }

  if (queryStr.rental_car_agency) {
    urlState.rentalCarAgency = queryStr.rental_car_agency;
    delete queryStr.rental_car_agency;
  }

  if (queryStr.is_personal_use === 'true') {
    urlState.rentalCarIsForPersonalUse = true;
    delete queryStr.is_personal_use;
  } else if (queryStr.is_personal_use === 'false') {
    urlState.rentalCarIsForPersonalUse = false;
    delete queryStr.is_personal_use;
  }
  /**
   * Remaining tracked as metadata
   * ==============================
   */


  if (!(0, _isEmpty2.default)(queryStr)) {
    urlState.metadata = queryStr;
  }

  return urlState;
}