import React, { PureComponent } from 'react';
import Button from 'shared/lib/components/Button';
import { RENTERS_ROUTES } from 'shared/lib/apps/RentersApp/routes';
import * as analytics from 'shared/lib/analytics';
import logoPoweredBy from './images/logo-powered-by-sure.png';
import './LandingRentersEmbed.css';

export default class LandingRentersEmbed extends PureComponent {
  componentDidMount() {
    analytics.page('Renters - Landing');
    analytics.track('Renters - Landing Page Viewed');
  }

  render() {
    return (
      <div className="LandingRentersEmbed">
        <div className="LandingRentersEmbed-hero">
          <div className="Container">
            <img
              className="LandingRentersEmbed-hero-logo"
              src={logoPoweredBy}
              alt="Powered by Sure"
            />
            <h1 className="LandingRentersEmbed-hero-heading">
              Get Renters Insurance and protect your treasures
            </h1>
            <p className="LandingRentersEmbed-hero-pitch">
              We've made it simple: tell us about you, where you live, and we'll
              get you the best policy.
            </p>
            <Button
              className="LandingRentersEmbed-cta"
              to={RENTERS_ROUTES.START}
              shadow
            >
              <i className="material-icons">&#xE8E8;</i>
              Get Started
            </Button>
            <p className="LandingRentersEmbed-hero-disclaimer">
              We'll never sell or share your personal information
            </p>
          </div>
        </div>

        <div className="LandingRentersEmbed-coverage">
          <div className="Container">
            <h2 className="LandingRentersEmbed-coverage-heading">
              What does Renters Insurance cover?
            </h2>
            <p className="LandingRentersEmbed-coverage-info">
              It covers a lot more than you might think. Protect your clothes,
              furniture, and even yourself from unexpected damages and injuries.
            </p>
            <div className="LandingRentersEmbed-coverage-threeUp">
              <div className="LandingRentersEmbed-coverage-threeUp-card">
                <div className="LandingRentersEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentersEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE337;</i>
                  </div>
                </div>
                <h3 className="LandingRentersEmbed-coverage-threeUp-card-heading">
                  Your stuff is safe and sound
                </h3>
                <p className="LandingRentersEmbed-coverage-threeUp-card-info">
                  Your belongings will be covered against theft, loss, and most
                  forms of destruction. Even if it's in your car or with you on
                  the go, you can make sure your things are secure.
                </p>
              </div>
              <div className="LandingRentersEmbed-coverage-threeUp-card">
                <div className="LandingRentersEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentersEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE84F;</i>
                  </div>
                </div>
                <h3 className="LandingRentersEmbed-coverage-threeUp-card-heading">
                  Covered against lawsuits
                </h3>
                <p className="LandingRentersEmbed-coverage-threeUp-card-info">
                  If you hurt someone or damage someone else's property, those
                  expenses can add up quickly, renters insurance has you
                  covered.
                </p>
              </div>
              <div className="LandingRentersEmbed-coverage-threeUp-card">
                <div className="LandingRentersEmbed-coverage-threeUp-card-header">
                  <div className="LandingRentersEmbed-coverage-threeUp-card-header-icon">
                    <i className="material-icons">&#xE548;</i>
                  </div>
                </div>
                <h3 className="LandingRentersEmbed-coverage-threeUp-card-heading">
                  Covered against medical costs
                </h3>
                <p className="LandingRentersEmbed-coverage-threeUp-card-info">
                  You can't always control what happens inside your home,
                  renters insurance covers reasonable medical expenses if
                  someone is hurt on your property.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="LandingRentersEmbed-pricing">
          <h2 className="LandingRentersEmbed-pricing-heading">
            How much is this going to cost?
          </h2>
          <p className="LandingRentersEmbed-pricing-info">
            Plans are as low as <strong>$11/month</strong>.
            <br />
            It's a no brainer.
          </p>
          <p className="LandingRentersEmbed-pricing-comparisonText">
            That's the same amount you would spend on
          </p>
          <div className="LandingRentersEmbed-pricing-examples">
            <div className="LandingRentersEmbed-pricing-examples-example">
              <div className="LandingRentersEmbed-pricing-examples-example-icon">
                <i className="material-icons">&#xE556;</i>
              </div>
              <p className="LandingRentersEmbed-pricing-examples-example-text">
                1 Dinner
              </p>
            </div>
            <div className="LandingRentersEmbed-pricing-examples-example">
              <div className="LandingRentersEmbed-pricing-examples-example-icon">
                <i className="material-icons">&#xE639;</i>
              </div>
              <p className="LandingRentersEmbed-pricing-examples-example-text">
                1 Month of Netflix
              </p>
            </div>
            <div className="LandingRentersEmbed-pricing-examples-example">
              <div className="LandingRentersEmbed-pricing-examples-example-icon">
                <i className="material-icons">&#xE541;</i>
              </div>
              <p className="LandingRentersEmbed-pricing-examples-example-text">
                1 Coffee Date
              </p>
            </div>
            <div className="LandingRentersEmbed-pricing-examples-example">
              <div className="LandingRentersEmbed-pricing-examples-example-icon">
                <i className="material-icons">&#xE540;</i>
              </div>
              <p className="LandingRentersEmbed-pricing-examples-example-text">
                1 Fancy Cocktail
              </p>
            </div>
          </div>
        </div>

        <div className="LandingRentersEmbed-bottomCta">
          <h3 className="LandingRentersEmbed-bottomCta-heading">
            Ready to protect your home?
          </h3>
          <p className="LandingRentersEmbed-bottomCta-info">
            Based on your needs, we'll help you select the most coverage at the
            best price.
          </p>
          <Button
            className="LandingRentersEmbed-cta"
            to={RENTERS_ROUTES.START}
            sureStyle="success"
            shadow
          >
            <i className="material-icons">&#xE8E8;</i>
            Get Started
          </Button>
        </div>

        <div className="LandingRentersEmbed-footer">
          <div className="Container">
            <p>
              Content and associated insurance products are provided by Sure
              HIIS Insurance Services, LLC (“Sure”), a licensed seller of
              insurance. The above does not in any way constitute an endorsement
              or referral by the hosting company of Sure’s products or services.
              Products may not be offered in all states. Read our{' '}
              <a
                href="https://www.sureapp.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://www.sureapp.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}
