import React, { Component } from 'react';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import Button from 'shared/lib/components/Button';
import classnames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import pull from 'lodash/pull';
import union from 'lodash/union';
import { format as currencyFormat } from 'currency-formatter';
import * as analytics from 'shared/lib/analytics';
import './HomeSafeAdditionalBenefitsView.css';

const propTypes = {};
const defaultProps = {};

class HomeSafeAdditionalBenefitsView extends Component {
  componentDidMount() {
    analytics.page('HomeSafe - Screening Survey');
  }

  handleChange = e => {
    const { homeSafeDetails } = this.props;
    const key = e.currentTarget.dataset.key;
    const addOns = union([], homeSafeDetails.addOns);
    if (this.isAddOnSelected(key)) {
      pull(addOns, key);
    } else {
      addOns.push(key);
    }
    this.props.updateHomeSafeDetails({
      addOns,
    });
  };

  handleNext = () => {
    this.props.updateHomeSafeQuote().then(() => {
      this.props.navTo('/residential-insurance/policy-holder');
    });
  };

  handleSkip = e => {
    e.preventDefault();
    this.props.updateHomeSafeDetails({
      addOns: [],
    });
    this.props.updateHomeSafeQuote().then(() => {
      this.props.navTo('/residential-insurance/policy-holder');
    });
  };

  isAddOnSelected = key => {
    return includes(this.props.homeSafeDetails.addOns, key);
  };

  render() {
    const { disclaimer, homeSafeSelectedPlan } = this.props;

    return (
      <div className="HomeSafeAdditionalBenefitsView Container ChubbTheme">
        <h1 className="HomeSafeAdditionalBenefitsView-heading">
          Add additional coverage to your plan:
        </h1>
        <a
          href="#skip"
          className="HomeSafeAdditionalBenefitsView-skipLink"
          onClick={this.handleSkip}
        >
          Continue without additional coverage
        </a>

        {map(
          get(homeSafeSelectedPlan, 'details.plan_add_ons'),
          (addon, key) => (
            <div
              className={classnames('CheckboxCard', {
                'is-selected': this.isAddOnSelected(key),
              })}
              key={key}
            >
              <div
                className="CheckboxCard-checkCol"
                data-key={key}
                onClick={this.handleChange}
              >
                <input
                  className="CheckboxCard-checkCol-input"
                  type="checkbox"
                  name={key}
                  checked={this.isAddOnSelected(key)}
                  onChange={this.handleChange}
                />
                <div
                  className={classnames('CheckboxCard-checkCol-checkbox', {
                    'is-selected': this.isAddOnSelected(key),
                  })}
                >
                  <i className="fas fa-square CheckboxCard-checkCol-checkbox-icon CheckboxCard-checkCol-checkbox-icon--unchecked" />
                  <i className="fas fa-check-square CheckboxCard-checkCol-checkbox-icon CheckboxCard-checkCol-checkbox-icon--checked" />
                </div>
                Add
              </div>
              <div className="CheckboxCard-col CheckboxCard-col--main">
                <h2 className="CheckboxCard-col-heading">
                  {addon.display_title}
                </h2>
                <a
                  href={addon.policy_doc_url}
                  className="CheckboxCard-col-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Key Information Document
                </a>
              </div>
              <div className="CheckboxCard-col CheckboxCard-col--coverage">
                <p className="CheckboxCard-col-heading">Insured up to</p>
                <p className="CheckboxCard-col-info">{addon.coverage_amount}</p>
              </div>
              <div className="CheckboxCard-col CheckboxCard-col--price">
                <p className="CheckboxCard-col-boldHeading">
                  {currencyFormat(addon.price, {
                    code: 'ZAR',
                  })}
                </p>
                <p className="CheckboxCard-col-info">
                  <em>per month</em>
                </p>
              </div>
            </div>
          )
        )}

        <Button
          className="HomeSafeAdditionalBenefitsView-cta"
          onClick={this.handleNext}
        >
          Next <i className="far fa-chevron-right" />
        </Button>
        <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
      </div>
    );
  }
}

HomeSafeAdditionalBenefitsView.propTypes = propTypes;
HomeSafeAdditionalBenefitsView.defaultProps = defaultProps;

export default HomeSafeAdditionalBenefitsView;
