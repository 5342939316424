'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/TelInput/TelInput.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactIntlTelInput = require('react-intl-tel-input');

var _reactIntlTelInput2 = _interopRequireDefault(_reactIntlTelInput);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('react-intl-tel-input/dist/libphonenumber.js');

require('react-intl-tel-input/dist/main.css');

require('./TelInput.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  defaultCountry: _propTypes2.default.string,
  onlyCountries: _propTypes2.default.array,
  preferredCountries: _propTypes2.default.array,
  value: _propTypes2.default.string,
  onChange: _propTypes2.default.func
};
var defaultProps = {
  onChange: function onChange() {}
};

var TelInput = function (_PureComponent) {
  _inherits(TelInput, _PureComponent);

  function TelInput() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TelInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TelInput.__proto__ || Object.getPrototypeOf(TelInput)).call.apply(_ref, [this].concat(args))), _this), _this.handleGeoIpLookup = function (callback) {
      if (window.geoip2) {
        window.geoip2.insights(function (data) {
          var countryCode = data && data.country ? data.country.iso_code : '';
          callback(countryCode);
        });
      }
    }, _this.handlePhoneNumberChange = function (status, value, countryData, number, id) {
      var valueOnlyDigits = value.replace(/\D/g, '');

      if (countryData.iso2 === 'us' && valueOnlyDigits.length <= 10 || countryData.iso2 !== 'us') {
        _this.props.onChange(number);
      }
    }, _this.handleFlagChange = function (err, _ref2) {
      var dialCode = _ref2.dialCode;

      _this.props.onChange('+' + dialCode);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TelInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          defaultCountry = _props.defaultCountry,
          onlyCountries = _props.onlyCountries,
          preferredCountries = _props.preferredCountries,
          value = _props.value,
          name = _props.name,
          id = _props.id,
          otherProps = _objectWithoutProperties(_props, ['className', 'defaultCountry', 'onlyCountries', 'preferredCountries', 'value', 'name', 'id']);

      return _react2.default.createElement(_reactIntlTelInput2.default, {
        telInputProps: otherProps,
        fieldName: name,
        fieldId: id,
        css: [(0, _classnames2.default)('intl-tel-input TelInput', className), 'TelInput-input'],
        defaultCountry: defaultCountry || 'auto',
        onlyCountries: onlyCountries,
        preferredCountries: preferredCountries || [],
        autoComplete: 'phone',
        format: true,
        formatOnInit: true,
        geoIpLookup: this.handleGeoIpLookup,
        onPhoneNumberBlur: this.handlePhoneNumberChange,
        onPhoneNumberChange: this.handlePhoneNumberChange,
        onSelectFlag: this.handleFlagChange,
        value: value,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 57
        },
        __self: this
      });
    }
  }]);

  return TelInput;
}(_react.PureComponent);

TelInput.propTypes = propTypes;
TelInput.defaultProps = defaultProps;
exports.default = TelInput;