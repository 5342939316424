'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesCategory/CollectiblesCategory.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _debounce = require('lodash/debounce');

var _debounce2 = _interopRequireDefault(_debounce);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _sortBy = require('lodash/sortBy');

var _sortBy2 = _interopRequireDefault(_sortBy);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _PagerButtons = require('shared/lib/components/PagerButtons');

var _PagerButtons2 = _interopRequireDefault(_PagerButtons);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CreditCardLogos = require('shared/lib/components/CreditCardLogos');

var _CreditCardLogos2 = _interopRequireDefault(_CreditCardLogos);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

require('./CollectiblesCategory.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  updateCollectiblesCategoryType: _propTypes2.default.func,
  isCollectiblesInProgress: _propTypes2.default.bool,
  navTo: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  updateCollectiblesCategoryType: function updateCollectiblesCategoryType() {},
  isCollectiblesInProgress: false
};

var CollectiblesCategory = function (_PureComponent) {
  _inherits(CollectiblesCategory, _PureComponent);

  function CollectiblesCategory() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesCategory);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesCategory.__proto__ || Object.getPrototypeOf(CollectiblesCategory)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      if (_this.isNextDisabled) {
        return Promise.resolve();
      } else {
        _this.props.navTo('address');
      }
    }, _this.handleSelect = function (selectedObj) {
      _this.props.updateCollectiblesCategoryType(selectedObj && selectedObj.value || null);
    }, _this.handleChange = (0, _debounce2.default)(function (query) {
      if (query && query.length >= 3) {
        analytics.track('Collectibles - Category Type Searched', {
          term: query
        });
      }
    }, 250), _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesCategory, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Category Selection');
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'CollectiblesCategory',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesCategory-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 103
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'CollectiblesCategory-pane-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 105
        },
        __self: this
      }, 'What type of collection do you want to insure?'), _react2.default.createElement('form', {
        className: 'CollectiblesCategory-pane-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      }, _react2.default.createElement(_reactSelect2.default, {
        className: 'SureSelect',
        name: 'collectibles_category',
        placeholder: 'Collection type',
        value: this.props.collectiblesSelectedCategory,
        options: this.collectiblesCategoryOptions,
        disabled: this.props.isCollectiblesInProgress,
        isLoading: this.props.isCollectiblesInProgress,
        onChange: this.handleSelect,
        onInputChange: this.handleChange,
        required: true,
        ref: function ref(c) {
          return _this2.input = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 112
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        ref: function ref(c) {
          return _this2.inputSubmit = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 125
        },
        __self: this
      })), _react2.default.createElement(_PagerButtons2.default, {
        onNext: function onNext() {
          return _this2.inputSubmit.click();
        },
        isNextDisabled: this.isNextDisabled,
        isBackHidden: true,
        isInProgress: this.props.isCollectiblesInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 131
        },
        __self: this
      }), _react2.default.createElement(_CreditCardLogos2.default, {
        className: 'CollectiblesCategory-creditCardLogos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 137
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        className: 'CollectiblesCategory-planDisclaimer',
        text: this.props.disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!this.props.partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 138
        },
        __self: this
      }))));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      return this.props.isCollectiblesInProgress;
    }
  }, {
    key: 'collectiblesCategoryOptions',
    get: function get() {
      var categories = (0, _map2.default)((0, _omit2.default)(_collectiblesCategories2.default, ['other'].concat(_toConsumableArray(this.props.blacklistCategories))), function (category) {
        return {
          label: category.secondaryTitle ? category.title + ' ' + category.secondaryTitle : category.title,
          value: category.categoryCode
        };
      });
      var hagertyFirstCategories = [(0, _find2.default)(categories, {
        value: 'wine'
      }), (0, _find2.default)(categories, {
        value: 'coins'
      }), (0, _find2.default)(categories, {
        value: 'ant_firearms'
      }), (0, _find2.default)(categories, {
        value: 'militaria'
      })];
      var hagertyRestCategories = (0, _sortBy2.default)(_without2.default.apply(undefined, [categories].concat(hagertyFirstCategories, [(0, _find2.default)(categories, {
        value: 'jewelry'
      })])), function (category) {
        return category.label;
      });
      var hagertyCategories = [].concat(hagertyFirstCategories, _toConsumableArray(hagertyRestCategories));
      return this.props.isHagerty ? hagertyCategories : categories;
    }
  }]);

  return CollectiblesCategory;
}(_react.PureComponent);

CollectiblesCategory.propTypes = propTypes;
CollectiblesCategory.defaultProps = defaultProps;
exports.default = CollectiblesCategory;