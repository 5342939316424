import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  showGlobalModal,
  hideGlobalModal,
  updatePartnerInfo,
} from './store/actions';
import hoistStatics from 'hoist-non-react-statics';
import GlobalModals from 'shared/lib/components/GlobalModals';
import ErrorPage from 'shared/lib/components/ErrorPage';
import { logException } from 'shared/lib/utils';
import * as analytics from 'shared/lib/analytics';

const mapStateToProps = state => {
  return {
    activeGlobalModal: state.activeGlobalModal,
    globalModalProps: state.globalModalProps,

    // White label specific
    isMenuOpen: state.isMenuOpen,
    partnerInfo: state.partnerInfo,
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  ...bindActionCreators(
    {
      showGlobalModal,
      hideGlobalModal,
      updatePartnerInfo,
    },
    dispatch
  ),
});

export default function withGlobals(opts = {}) {
  return WrappedComponent => {
    class ErrorHandlerComponent extends Component {
      constructor(props) {
        super(props);
        this.state = {
          hasError: false,
        };
        this.displayName = `withGlobals(${WrappedComponent.displayName ||
          WrappedComponent.name})`;
        this.WrappedComponent = WrappedComponent;
      }

      componentDidMount() {
        if (!opts.analyticsPrefix) {
          console.warn(`${this.displayName} option analyticsPrefix is missing`);
        }
      }

      componentDidCatch(error, info) {
        logException(error, info);
        this.setState({
          hasError: true,
        });
      }

      trackEventWithPrefix(event) {
        const eventName = [opts.analyticsPrefix, event].join(' - ');
        const eventParams = {
          noninteraction: 1,
        };
        analytics.track(eventName, eventParams);
      }

      handleError = (error, info) => {
        logException(error, info);
        this.props.showGlobalModal({
          id: 'error',
          error,
          info,
        });
        switch (error.code) {
          case 'no_offers_found':
            this.trackEventWithPrefix('No Offers Found');
            break;
          case 'property_address_invalid':
          case 'ZERO_RESULTS':
            this.trackEventWithPrefix('Invalid Address');
            break;
          case 'quote_request_rejected':
            this.trackEventWithPrefix('Quote Ineligible');
            break;
          default:
            break;
        }
      };

      render() {
        const {
          activeGlobalModal,
          globalModalProps,
          hideGlobalModal,
        } = this.props;

        return (
          <Fragment>
            {this.state.hasError ? (
              <ErrorPage />
            ) : (
              <WrappedComponent onError={this.handleError} {...this.props} />
            )}
            <GlobalModals
              activeModal={activeGlobalModal}
              onDismiss={hideGlobalModal}
              {...globalModalProps}
            />
          </Fragment>
        );
      }
    }

    const ComponentWithStatics = hoistStatics(
      ErrorHandlerComponent,
      WrappedComponent
    );
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(ComponentWithStatics);
  };
}
