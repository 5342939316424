'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LandingBlockThreeUp/LandingBlockThreeUp.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./LandingBlockThreeUp.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  subtitle: _propTypes2.default.string,
  cards: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    icon: _propTypes2.default.string,
    backgroundImage: _propTypes2.default.string,
    heading: _propTypes2.default.string,
    description: _propTypes2.default.string
  })).isRequired,
  isIconImage: _propTypes2.default.bool
};
var defaultProps = {
  title: null,
  subtitle: null,
  cards: [],
  isIconImage: false
};

var LandingBlockThreeUp = function (_PureComponent) {
  _inherits(LandingBlockThreeUp, _PureComponent);

  function LandingBlockThreeUp() {
    _classCallCheck(this, LandingBlockThreeUp);

    return _possibleConstructorReturn(this, (LandingBlockThreeUp.__proto__ || Object.getPrototypeOf(LandingBlockThreeUp)).apply(this, arguments));
  }

  _createClass(LandingBlockThreeUp, [{
    key: 'iconNodeForCard',
    value: function iconNodeForCard(card) {
      if (this.props.isIconImage && card.icon) {
        return _react2.default.createElement('div', {
          className: 'LandingBlockThreeUp-card-header-iconImage',
          style: {
            backgroundImage: 'url(\'' + process.env.PUBLIC_URL + card.icon + '\')'
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 32
          },
          __self: this
        });
      } else if (card.icon) {
        return _react2.default.createElement('div', {
          className: 'LandingBlockThreeUp-card-header-icon',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 41
          },
          __self: this
        }, _react2.default.createElement('i', {
          className: 'material-icons',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 42
          },
          __self: this
        }, card.icon));
      } else {
        return null;
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          title = _props.title,
          subtitle = _props.subtitle,
          cards = _props.cards;
      return _react2.default.createElement('div', {
        className: 'LandingBlockThreeUp',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 54
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'LandingCollectiblesContainer',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 55
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'LandingBlockThreeUp-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 56
        },
        __self: this
      }, title), _react2.default.createElement('p', {
        className: 'LandingBlockThreeUp-description',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 57
        },
        __self: this
      }, subtitle), _react2.default.createElement('div', {
        className: 'LandingBlockThreeUp-threeUp',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 58
        },
        __self: this
      }, (0, _map2.default)(cards, function (card, i) {
        return _react2.default.createElement('div', {
          className: (0, _classnames2.default)('LandingBlockThreeUp-card', {
            'LandingBlockThreeUp-card--noIcon': !card.icon
          }),
          key: i,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 60
          },
          __self: _this2
        }, _react2.default.createElement('div', {
          className: 'LandingBlockThreeUp-card-header',
          style: {
            backgroundImage: card.backgroundImage && 'url(' + card.backgroundImage + ')'
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 66
          },
          __self: _this2
        }, _this2.iconNodeForCard(card)), _react2.default.createElement('h3', {
          className: 'LandingBlockThreeUp-card-heading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 75
          },
          __self: _this2
        }, card.heading), _react2.default.createElement('p', {
          className: 'LandingBlockThreeUp-card-description',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 78
          },
          __self: _this2
        }, card.description));
      }))));
    }
  }]);

  return LandingBlockThreeUp;
}(_react.PureComponent);

LandingBlockThreeUp.propTypes = propTypes;
LandingBlockThreeUp.defaultProps = defaultProps;
exports.default = LandingBlockThreeUp;