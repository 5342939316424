import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import { Link } from 'react-router';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import classnames from 'classnames';
import heroImage from './images/landing-homesafe.png';
import iconSuitcaseImage from './images/icon-suitcase.png';
import './LandingHomeSafeFAQ.css';

export default class LandingHomeSafeFAQ extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    analytics.page('HomeSafe - FAQ');
  }

  getFaqBoxKey(index) {
    return `isFaq${index}Open`;
  }

  toggleFaqBox = e => {
    const index = e.currentTarget.dataset.faqIndex;
    const key = this.getFaqBoxKey(index);
    this.setState({
      [key]: !this.state[key],
    });
  };

  render() {
    return (
      <div className="LandingHomeSafeFAQ ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#01c1d6" bgImage={heroImage}>
          <h1 className="LandingHomeSafeFAQ-heroTitle">
            Protect your personal valuables against theft out of your vehicle.
          </h1>
        </HeroChubb>
        <div className="LandingHomeSafeFAQ-content">
          <div className="Container">
            <div className="LandingHomeSafeFAQ-row">
              <div className="LandingHomeSafeFAQ-faqs">
                <h2 className="LandingHomeSafeFAQ-faqs-title">
                  <img
                    className="LandingHomeSafe-content-title-icon"
                    src={iconSuitcaseImage}
                    alt="Suitcase icon"
                  />
                  FAQs
                </h2>
                <p className="LandingHomeSafeFAQ-faqs-info">
                  Have a question? We’ve put together the following answers to
                  some frequently asked questions below.
                </p>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="0"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    How do I calculate the value of my household contents?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(0)],
                  })}
                >
                  <p>
                    The best and easiest way is to create an inventory of all of
                    your household contents including the replacement value of
                    each item. The total replacement value of this entire list
                    is the insurable value. An Inventory list can be found{' '}
                    <a
                      href="https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/616167/HMLR_Dataset_Inventory_Published_May2017.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="1"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    I stay in a rented flat/cottage. Can I still get house
                    contents insurance even if I don't own the property?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(1)],
                  })}
                >
                  <p>
                    Yes, you can certainly get household contents insurance
                    whether you own the property or rent it.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="2"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    In the event of a claim, what happens if I undervalued my
                    household contents?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(2)],
                  })}
                >
                  <p>
                    You would carry a part of the risk in proportion to the
                    amount that you undervalued your household items. In other
                    words, if the actual value of your household contents is
                    R100 000 and you have cover for R50 000 (50% of the value),
                    Chubb Insurance will cover 50% of your loss.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="3"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    In the event of a claim, what happens if I over insured my
                    household contents?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(3)],
                  })}
                >
                  <p>
                    The basic principal of insurance states that an insurer
                    should place you back in the same position (i.e. no
                    enrichment) as you were before you suffered the loss which
                    you are claiming for. The idea behind insuring your assets
                    is to financially prepare you for a possible future loss.
                    What does this mean? Chubb will only replace the items that
                    were stolen.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="4"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    I reside with two friends in a flat. How do I go about
                    insuring my household contents?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(4)],
                  })}
                >
                  <p>
                    We will cover a maximum of two unrelated people living
                    together. More than two unrelated people living together are
                    seen as a commune which is not covered.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="5"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    Will I be covered in the event that my home contents are
                    damaged due to a power surge?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(5)],
                  })}
                >
                  <p>
                    No, unfortunately we do not provide cover for damage due to
                    power surges.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="6"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    What will happen if I don’t have proof of the contents which
                    I purchased a while back? Will my claim still be valid even
                    though I don’t have proof of purchase?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(6)],
                  })}
                >
                  <p>
                    In terms of the policy, when you claim, we may require proof
                    of ownership and value of any items for which you claim. You
                    will need to provide a photograph of the items, which you
                    are claiming for or in the case of damage make available the
                    damaged item for inspection in order to substantiate the
                    extent and nature of the damages. We may request other forms
                    of proof for the specific item you are claiming for, which
                    is not limited to a receipt and/or purchase invoice. Please
                    contact our claims call centre if you have further
                    questions.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="7"
                  onClick={this.toggleFaqBox}
                >
                  <span>My TV was accidently damaged. Am I covered?</span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(7)],
                  })}
                >
                  <p>
                    No, unfortunately we do not provide cover in this event.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="8"
                  onClick={this.toggleFaqBox}
                >
                  <span>What does contents insurance cover?</span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(8)],
                  })}
                >
                  <p>Theft of home contents benefits covers the following:</p>
                  <ul>
                    <li>Furniture and furnishings</li>
                    <li>Clothing and shoes</li>
                    <li>
                      Personal possessions such as handbags, wallets and
                      briefcases that were kept in your home
                    </li>
                    <li>Carpets – fixed and unfixed</li>
                    <li>Internal blinds, curtains and window coverings</li>
                  </ul>
                  <p>
                    Additional coverage which you can add to your policy
                    includes:{' '}
                  </p>
                  <ul>
                    <li>
                      Tech Safe Home: Cover for electrical appliances such as
                      washing machines, dishwashers, laptops, mobile phones etc.
                    </li>
                    <li>
                      Glass Cover: Cover for the cost incurred to repair, change
                      or replace plate glass due to theft at your home
                    </li>
                    <li>
                      Cash Disaster Relief: A daily payout should your home
                      become uninhabitable for a minimum of 48 hours due to one
                      of the following reasons:
                      <ul>
                        <li>
                          Fire, lightning, thunderbolt, subterranean fire,
                          explosion or flood
                        </li>
                        <li>
                          Theft, but only if accompanied by actual forcible and
                          violent force breaking into or out of a building or
                          any such attempt
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    Your insurance certificate shows you the benefits and level
                    of cover you are entitled to.
                    <br />
                    <small>
                      * Please refer to the policy wording for a full list of
                      covered items.
                    </small>
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="9"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    Can I get extra cover for items such as my laptop which I
                    take with me outside my home?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(9)],
                  })}
                >
                  <p>
                    Yes, you may take out the{' '}
                    <Link to="/vehicle-contents-insurance">CarSafe</Link>{' '}
                    product which will cover theft from your vehicle for
                    personal valuables.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="10"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    Will I be offered alternative accommodation while my
                    contents are being replaced?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(10)],
                  })}
                >
                  <p>
                    No, you will not be offered alternate accommodation while
                    your contents are being replaced however in the event that
                    your home becomes uninhabitable or inaccessible due to fire,
                    lightning, explosion, flood or due to theft that is
                    accompanied by actual forcible and violent entry, then we
                    will pay you the amount specified in your policy schedule to
                    assist with the costs of alternate accommodation.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="11"
                  onClick={this.toggleFaqBox}
                >
                  <span>What is classed as an accessible window?</span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(11)],
                  })}
                >
                  <p>
                    Accessible windows are windows, skylights and/or roof lights
                    that are at ground floor or basement level, or within easy
                    reach of the ground and are capable of being opened (such as
                    a first-floor window above a flat-roofed, single-story
                    extension).
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="12"
                  onClick={this.toggleFaqBox}
                >
                  <span>Does the residential insurance cover my bicycle?</span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(12)],
                  })}
                >
                  <p>No, unfortunately, we do not offer cover for bicycles.</p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="13"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    My property is unoccupied - can I still get a quote?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(13)],
                  })}
                >
                  <p>
                    No, unfortunately we do not cover property that is
                    unoccupied for period longer than 60 days.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="14"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    Can I claim termite damage under my residential insurance?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(14)],
                  })}
                >
                  <p>
                    No, unfortunately we don’t cover damage caused by insects or
                    pests, such as termites, moths, or rats.
                  </p>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="15"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    What items are not covered by residential standalone
                    insurance?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(15)],
                  })}
                >
                  <p>The items listed below will not be covered:</p>
                  <ul>
                    <li>Contact lenses and prescription glasses</li>
                    <li>Cellphones</li>
                    <li>Hearing aids</li>
                    <li>
                      Jewellery, watches, laptops and other things that are
                      stolen or damaged while you're not at home
                    </li>
                    <li>Geysers</li>
                    <li>Fixtures, like carpets, windows and lights</li>
                  </ul>
                </div>

                <h3
                  className="LandingHomeSafeFAQ-faqs-question"
                  data-faq-index="16"
                  onClick={this.toggleFaqBox}
                >
                  <span>
                    When replacing an item, is inflation taken into account?
                  </span>
                  <i className="LandingHomeSafeFAQ-faqs-question-icon fas fa-caret-down" />
                </h3>
                <div
                  className={classnames('LandingHomeSafeFAQ-faqs-answer', {
                    'is-open': this.state[this.getFaqBoxKey(16)],
                  })}
                >
                  <p>
                    We will pay the full current value to replace an item if the
                    original receipt is submitted as proof of ownership or
                    replacement value, with an allowance for depreciation, if
                    the original receipt is not available.
                  </p>
                </div>
              </div>

              <div className="LandingHomeSafeFAQ-quoteCard">
                <h3 className="LandingHomeSafeFAQ-quoteCard-title">
                  Get an instant quote in a few easy steps.
                </h3>
                <p className="LandingHomeSafeFAQ-quoteCard-info">
                  Fill in our quick and easy online form to choose the perfect
                  cover for you.
                </p>
                <Link
                  to="/residential-insurance/intro-survey"
                  className="LandingHomeSafeFAQ-quoteCard-cta"
                >
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterChubb />
      </div>
    );
  }
}
