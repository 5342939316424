import ACTION_TYPES from '../../constants/actionTypes';
import api from '../../api';

/**
 * Beneficiary relationships
 */

function getBeneficiaryRelationshipsRequest() {
  return {
    type: ACTION_TYPES.GET_BENEFICIARY_RELATIONSHIPS,
  };
}

function getBeneficiaryRelationshipsSuccess(beneficiaryRelationships) {
  return {
    type: ACTION_TYPES.GET_BENEFICIARY_RELATIONSHIPS_SUCCESS,
    payload: {
      beneficiaryRelationships,
    },
  };
}

function getBeneficiaryRelationshipsFailure(error) {
  return {
    type: ACTION_TYPES.GET_BENEFICIARY_RELATIONSHIPS_FAILURE,
    error,
  };
}

export function getBeneficiaryRelationships() {
  return (dispatch, getState) => {
    const { authToken } = getState();
    dispatch(getBeneficiaryRelationshipsRequest());
    return new Promise((resolve, reject) => {
      api.getBeneficiaryRelationships({ authToken }).then(
        resp => {
          dispatch(
            getBeneficiaryRelationshipsSuccess(resp.beneficiary_relationships)
          );
          resolve(resp.beneficiary_relationships);
        },
        error => {
          dispatch(getBeneficiaryRelationshipsFailure(error));
          reject(error);
        }
      );
    });
  };
}
