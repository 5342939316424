import React from 'react';
import Helmet from 'react-helmet';
import filter from 'lodash/filter';
import omitBy from 'lodash/omitBy';

const publicUrl = process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}` : '';

const scripts = [
  {
    _name: 'FontAwesome',
    _env: ['development', 'sandbox', 'production'],
    type: 'text/javascript',
    src: `${publicUrl}/fontawesome-pro/js/fontawesome-all.min.js`,
    async: true,
  },
  {
    _name: 'VWO',
    _env: ['development', 'production'],
    type: 'text/javascript',
    innerHTML: `var _vwo_code=(function(){var account_id = 327748,settings_tolerance=2000,library_tolerance=2500,use_existing_jquery = false, is_spa = 1, /* DO NOT EDIT BELOW THIS LINE */ f = false, d = document; return { use_existing_jquery: function () { return use_existing_jquery; }, library_tolerance: function () { return library_tolerance; }, finish: function () { if (!f) { f = true; var a = d.getElementById('_vis_opt_path_hides'); if (a) a.parentNode.removeChild(a); } }, finished: function () { return f; }, load: function (a) { var b = d.createElement('script'); b.src = a; b.type = 'text/javascript'; b.innerText; b.onerror = function () { _vwo_code.finish(); }; d.getElementsByTagName('head')[0].appendChild(b); }, init: function () { settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance); var a = d.createElement('style'), b = 'body{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}', h = d.getElementsByTagName('head')[0]; a.setAttribute('id', '_vis_opt_path_hides'); a.setAttribute('type', 'text/css'); if (a.styleSheet) a.styleSheet.cssText = b; else a.appendChild(d.createTextNode(b)); h.appendChild(a); this.load('//dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&f=' + (+is_spa) + '&r=' + Math.random()); return settings_timer; } }; }()); _vwo_settings_timer = _vwo_code.init()`,
  },
];

export default () => {
  const env = process.env.REACT_APP_ENV || 'development';
  const scriptsByEnv = filter(scripts, { _env: [env] });
  const sanitizedScripts = scriptsByEnv.map(script => {
    return omitBy(script, (val, key) => key[0] === '_');
  });

  return <Helmet script={sanitizedScripts} />;
};
