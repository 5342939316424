import ACTION_TYPES from '../../constants/actionTypes';

export default {
  carSafePlans(state = [], action) {
    switch (action.type) {
      case ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS:
        return action.payload.plans;
      case ACTION_TYPES.GET_CARSAFE_PLANS:
      case ACTION_TYPES.GET_CARSAFE_PLANS_FAILURE:
        return [];
      default:
        return state;
    }
  },

  carSafeSettings(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS:
        return action.payload.settings;
      case ACTION_TYPES.GET_CARSAFE_PLANS:
      case ACTION_TYPES.GET_CARSAFE_PLANS_FAILURE:
        return {};
      default:
        return state;
    }
  },

  carSafeDetails(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_CARSAFE_DETAILS:
        return {
          ...state,
          ...action.payload,
        };
      case ACTION_TYPES.SELECT_CARSAFE_PLAN:
        return {};
      default:
        return state;
    }
  },

  carSafeSelectedPlanId(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SELECT_CARSAFE_PLAN:
        return action.payload.planId;
      default:
        return state;
    }
  },

  carSafeCheckout(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_CARSAFE_CHECKOUT_SUCCESS:
        return action.payload;
      case ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS:
      case ACTION_TYPES.GET_CARSAFE_PLANS:
      case ACTION_TYPES.SELECT_CARSAFE_PLAN:
        return {};
      default:
        return state;
    }
  },

  carSafePurchaseInfo(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.PURCHASE_CARSAFE_SUCCESS:
        return action.payload;
      case ACTION_TYPES.PURCHASE_CARSAFE:
      case ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS:
      case ACTION_TYPES.GET_CARSAFE_PLANS:
      case ACTION_TYPES.SELECT_CARSAFE_PLAN:
        return {};
      default:
        return state;
    }
  },

  isCarSafeInProgress(state = false, action) {
    switch (action.type) {
      case ACTION_TYPES.GET_CARSAFE_PLANS:
      case ACTION_TYPES.GET_CARSAFE_CHECKOUT:
      case ACTION_TYPES.PURCHASE_CARSAFE:
        return true;
      case ACTION_TYPES.GET_CARSAFE_PLANS_SUCCESS:
      case ACTION_TYPES.GET_CARSAFE_PLANS_FAILURE:
      case ACTION_TYPES.GET_CARSAFE_CHECKOUT_SUCCESS:
      case ACTION_TYPES.GET_CARSAFE_CHECKOUT_FAILURE:
      case ACTION_TYPES.PURCHASE_CARSAFE_SUCCESS:
      case ACTION_TYPES.PURCHASE_CARSAFE_FAILURE:
        return false;
      default:
        return state;
    }
  },
};
