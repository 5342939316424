'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesQuoteDetails/CollectiblesQuoteDetails.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _uniq = require('lodash/uniq');

var _uniq2 = _interopRequireDefault(_uniq);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _AddressSectionNew = require('shared/lib/components/AddressSectionNew');

var _AddressSectionNew2 = _interopRequireDefault(_AddressSectionNew);

var _CreditCardLogos = require('shared/lib/components/CreditCardLogos');

var _CreditCardLogos2 = _interopRequireDefault(_CreditCardLogos);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _PagerButtons = require('shared/lib/components/PagerButtons');

var _PagerButtons2 = _interopRequireDefault(_PagerButtons);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _QuoteLayoutNew = require('shared/lib/components/QuoteLayoutNew');

var _QuoteLayoutNew2 = _interopRequireDefault(_QuoteLayoutNew);

var _SectionWrapperNew = require('shared/lib/components/SectionWrapperNew');

var _SectionWrapperNew2 = _interopRequireDefault(_SectionWrapperNew);

var _Select = require('shared/lib/components/Select');

var _Select2 = _interopRequireDefault(_Select);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

require('./CollectiblesQuoteDetails.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  categorySlug: _propTypes2.default.string,
  categoryCode: _propTypes2.default.string,
  totals: _propTypes2.default.object,
  navTo: _propTypes2.default.func,
  updateCollectiblesSelectedAmounts: _propTypes2.default.func,
  currencyCode: _propTypes2.default.string,
  isCollectiblesInProgress: _propTypes2.default.bool,
  collectiblesEditQuoteModalContext: _propTypes2.default.object,
  editCollectiblesQuote: _propTypes2.default.func,
  selectedAmounts: _propTypes2.default.object,
  responses: _propTypes2.default.object,
  plan: _propTypes2.default.object,
  rates: _propTypes2.default.array,
  propertyAddress: _propTypes2.default.object,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  policyProvider: _propTypes2.default.string
};
var defaultProps = {
  isCollectiblesInProgress: false
};

var CollectiblesQuoteDetails = function (_Component) {
  _inherits(CollectiblesQuoteDetails, _Component);

  function CollectiblesQuoteDetails() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesQuoteDetails);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesQuoteDetails.__proto__ || Object.getPrototypeOf(CollectiblesQuoteDetails)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isAddingCollection: false
    }, _this.handleBack = function () {
      _this.props.navTo('policy-date');
    }, _this.handleNext = function () {
      analytics.track('Checkout Step Completed', {
        category: 'order',
        step: 1
      });

      _this.props.navTo('payment');
    }, _this.handleUpdateSelectedAmount = function (e) {
      var _e$target = e.target,
          name = _e$target.name,
          value = _e$target.value;

      try {
        value = JSON.parse(value);
      } catch (e) {// Do nothing
      }

      _this.props.updateCollectiblesSelectedAmounts(_defineProperty({}, name, value));

      _this.props.updateCollectiblesPlanDetails().then(_this.props.getCollectiblesCheckout).catch(_this.props.onError);
    }, _this.handleEdit = function (e, collectionId) {
      var editContext = {};

      if (e.target.dataset.sectionTitle === 'Policy Details') {
        analytics.track('Collectibles - Edit Policy Details');
        editContext = {
          type: 'policy'
        };
      } else {
        analytics.track('Collectibles - Edit Collection Details');
        editContext = {
          type: 'collection',
          collectionId: collectionId
        };
      }

      _this.props.openEditCollectiblesQuoteModal(editContext);
    }, _this.handleAddCollection = function (e) {
      _this.setState({
        isAddingCollection: true
      }, function () {
        _this.props.clearCollectionDetails();

        _this.props.navTo('category');
      });
    }, _this.groomedAddress = function (address) {
      return {
        streetAddress: address.line1,
        unit: address.line2,
        region: address.state_or_region,
        city: address.city,
        postal: address.postal,
        country: address.country_code
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesQuoteDetails, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Quote Overview', {
        categoryCode: this.props.categoryCode
      });
      analytics.track('Collectibles - Quote Overview Page Viewed', {
        categoryCode: this.props.categoryCode,
        noninteraction: 1
      });
      analytics.track('AddToCart');
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 1
      });
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      if (!this.state.isAddingCollection) {
        var latestCollectionId = (0, _last2.default)(this.props.collectiblesQuote.application.plan_applications[0].collections).reference_id;
        var latestCollection = this.props.collectiblesCollectionsByCollectionId[latestCollectionId];
        var latestCollectionResponses = Object.assign({}, this.props.collectiblesPolicyContext, {
          collection_total_value: latestCollection.collection_total_value,
          highest_value_item: latestCollection.highest_value_item,
          has_ul_rate_vault: latestCollection.has_ul_rate_vault,
          has_personal_safe: latestCollection.has_personal_safe,
          has_fire_alarm: latestCollection.has_fire_alarm,
          has_burglar_alarm: latestCollection.has_burglar_alarm
        });
        this.props.updateCollectiblesAddress(this.groomedAddress(latestCollection.address));
        this.props.updateCollectiblesCategoryType(latestCollection.category_code);
        this.props.saveResponse(latestCollectionResponses);
        this.props.editCollection(latestCollectionId);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          disclaimer = _props.disclaimer,
          isCollectiblesInProgress = _props.isCollectiblesInProgress,
          plan = _props.plan,
          policyStartDate = _props.policyStartDate,
          selectedAmounts = _props.selectedAmounts,
          totals = _props.totals,
          collectiblesQuote = _props.collectiblesQuote;
      return _react2.default.createElement('div', {
        className: 'CollectiblesQuoteDetails',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 217
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesQuoteDetails-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 218
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small CollectiblesQuoteDetails-policyContainer',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 219
        },
        __self: this
      }, _react2.default.createElement(_QuoteLayoutNew2.default, {
        title: 'Your Quote',
        policyDocUrl: (0, _get3.default)(plan, 'policy_doc_url'),
        icon: 'widgets',
        billingCycle: 'year',
        disclaimer: disclaimer,
        totals: totals,
        hideBillingSummary: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapperNew2.default, {
        title: 'Deductible',
        note: (0, _get3.default)(plan, 'details.deductible.description'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 229
        },
        __self: this
      }, _react2.default.createElement(_Select2.default, {
        name: 'deductible',
        value: (0, _get3.default)(selectedAmounts, 'deductible'),
        onChange: this.handleUpdateSelectedAmount,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 233
        },
        __self: this
      }, this.deductibleOptions)), _react2.default.createElement(_SectionWrapperNew2.default, {
        title: 'Policy Details',
        onEditBtnClick: this.handleEdit,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 242
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Underwritten by', (0, _get3.default)(this.props, 'policyProvider')], this.ownerRow, ['Mailing Address', _react2.default.createElement(_AddressSectionNew2.default, {
          address: this.groomedAddress(collectiblesQuote.application.plan_applications[0].mailing_address),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 252
          },
          __self: this
        })], ['Start date', policyStartDate.format('MM/DD/YYYY')], ['Coverage term', policyStartDate.format('MM/DD/YYYY') + " \u2013 " + (0, _momentTimezone2.default)(policyStartDate).add(1, 'year').format('MM/DD/YYYY')]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 246
        },
        __self: this
      }))))), (0, _get3.default)(collectiblesQuote.application, 'plan_applications[0].collections', []).map(function (collection, index) {
        return _react2.default.createElement('div', {
          className: 'CollectiblesQuoteDetails-pane',
          key: collection.reference_id,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 279
          },
          __self: _this2
        }, _react2.default.createElement('div', {
          className: 'Container Container--small CollectiblesQuoteDetails-collectionContainer',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 283
          },
          __self: _this2
        }, _react2.default.createElement(_SectionWrapperNew2.default, {
          title: collection.category_code ? (0, _find2.default)(_collectiblesCategories2.default, {
            categoryCode: collection.category_code
          }).title + ' collection details' : 'Collection details',
          onEditBtnClick: function onEditBtnClick(e) {
            return _this2.handleEdit(e, collection.reference_id);
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 284
          },
          __self: _this2
        }, _react2.default.createElement(_LineItemsList2.default, {
          rows: (0, _compact2.default)([['Location', _react2.default.createElement(_AddressSectionNew2.default, {
            address: _this2.groomedAddress(collection.address),
            __source: {
              fileName: _jsxFileName,
              lineNumber: 302
            },
            __self: _this2
          })]].concat(_toConsumableArray(!(0, _includes2.default)(['jewelry', 'firearms'], collection.category_code) && [['Bank vault', collection.has_ul_rate_vault ? 'Yes' : 'No'], ['Central station fire alarm', collection.has_fire_alarm ? 'Yes' : 'No']]), [['Central station burglar alarm', collection.has_burglar_alarm ? 'Yes' : 'No'], ['Total value', _currencyFormatter2.default.format(collection.collection_total_value, {
            code: _this2.props.currencyCode
          })], ['Highest valued item', _currencyFormatter2.default.format(collection.highest_value_item, {
            code: _this2.props.currencyCode
          })]])),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 298
          },
          __self: _this2
        }))));
      }), this.props.isCollectiblesMulticheckoutEnabled && _react2.default.createElement('div', {
        className: 'CollectiblesQuoteDetails-addCollectionWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 345
        },
        __self: this
      }, _react2.default.createElement(_Button2.default, {
        className: 'CollectiblesQuoteDetails-addCollectionBtn',
        onClick: this.handleAddCollection,
        isInProgress: isCollectiblesInProgress,
        sureStyle: 'outlineNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 346
        },
        __self: this
      }, '+ Add another collection')), _react2.default.createElement(_PagerButtons2.default, {
        nextBtnText: 'Proceed to checkout',
        onNext: this.handleNext,
        isBackHidden: true,
        isInProgress: isCollectiblesInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 356
        },
        __self: this
      }), _react2.default.createElement('div', {
        className: 'CollectiblesQuoteDetails-pane CreditCardLogosWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 362
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small CollectiblesQuoteDetails-creditCardLogosContainer',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 363
        },
        __self: this
      }, _react2.default.createElement(_CreditCardLogos2.default, {
        className: 'CollectiblesQuoteDetails-creditCardLogos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 364
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        className: 'CollectiblesQuoteDetails-planDisclaimer',
        text: this.props.disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!this.props.partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 365
        },
        __self: this
      }))), _react2.default.createElement(_ModalSpinner2.default, {
        message: 'Calculating your quote. This may take up to 15 seconds.',
        isActive: isCollectiblesInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 373
        },
        __self: this
      }));
    }
  }, {
    key: 'deductibleOptions',
    get: function get() {
      var _this3 = this;

      var uniqValues = (0, _uniq2.default)((0, _map2.default)(this.props.rates, 'deductible'));
      return (0, _map2.default)(uniqValues, function (val) {
        return _react2.default.createElement('option', {
          value: val,
          key: val,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 100
          },
          __self: _this3
        }, '$', (0, _utils.numberWithCommas)(val));
      });
    }
  }, {
    key: 'ownerRow',
    get: function get() {
      var _this4 = this;

      var collectiblesPolicyContext = this.props.collectiblesPolicyContext;
      var holder = void 0;

      switch (collectiblesPolicyContext.ownership_type) {
        case 'trust':
          holder = collectiblesPolicyContext.trust_name;
          break;

        case 'foundation':
          holder = collectiblesPolicyContext.owner_foundation;
          break;

        case 'executor':
        case 'jointly_owned':
          (0, _get3.default)(collectiblesPolicyContext, 'owners', []).map(function (owner, i) {
            return i === 0 ? holder = [owner.first_name + ' ' + owner.last_name] : holder = [].concat(_toConsumableArray(holder), [_react2.default.createElement('br', {
              key: owner.first_name + i,
              __source: {
                fileName: _jsxFileName,
                lineNumber: 188
              },
              __self: _this4
            }), owner.first_name + ' ' + owner.last_name]);
          });
          break;

        case 'musical_group':
          holder = collectiblesPolicyContext.musical_group_name;
          break;

        default:
          holder = collectiblesPolicyContext.owner_first_name + ' ' + collectiblesPolicyContext.owner_last_name;
          break;
      }

      return ['Owner', holder];
    }
  }]);

  return CollectiblesQuoteDetails;
}(_react.Component);

CollectiblesQuoteDetails.propTypes = propTypes;
CollectiblesQuoteDetails.defaultProps = defaultProps;
exports.default = CollectiblesQuoteDetails;