'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesAddress/CollectiblesAddress.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _values = require('lodash/values');

var _values2 = _interopRequireDefault(_values);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _isEqual = require('lodash/isEqual');

var _isEqual2 = _interopRequireDefault(_isEqual);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _uniqWith = require('lodash/uniqWith');

var _uniqWith2 = _interopRequireDefault(_uniqWith);

var _PagerButtons = require('shared/lib/components/PagerButtons');

var _PagerButtons2 = _interopRequireDefault(_PagerButtons);

var _RadioButton = require('shared/lib/components/RadioButton');

var _RadioButton2 = _interopRequireDefault(_RadioButton);

var _FormGroup = require('shared/lib/components/FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _CreditCardLogos = require('shared/lib/components/CreditCardLogos');

var _CreditCardLogos2 = _interopRequireDefault(_CreditCardLogos);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AddressFormNew = require('shared/lib/components/AddressFormNew');

var _AddressFormNew2 = _interopRequireDefault(_AddressFormNew);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./CollectiblesAddress.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object,
  activeModal: _propTypes2.default.string,
  categoryCode: _propTypes2.default.string,
  categorySlug: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  isCollectiblesInProgress: _propTypes2.default.bool,
  isLoggedIn: _propTypes2.default.bool,
  propertyAddress: _propTypes2.default.object,
  resetCollectiblesData: _propTypes2.default.func,
  responses: _propTypes2.default.object,
  saveResponse: _propTypes2.default.func,
  shouldPreserveAddress: _propTypes2.default.bool,
  onSubmitAddress: _propTypes2.default.func,
  updateCollectiblesAddress: _propTypes2.default.func,
  createColleciblesQuote: _propTypes2.default.func,
  getCollectiblesPlans: _propTypes2.default.func
};
var defaultProps = {
  account: {},
  activeModal: null,
  disclaimer: null,
  isCollectiblesInProgress: false,
  isLoggedIn: false,
  propertyAddress: {},
  responses: {},
  shouldPreserveAddress: false
};

var CollectiblesAddress = function (_Component) {
  _inherits(CollectiblesAddress, _Component);

  function CollectiblesAddress() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesAddress);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesAddress.__proto__ || Object.getPrototypeOf(CollectiblesAddress)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isSubmitting: false,
      checkedNode: null,
      isQuoteEmpty: true
    }, _this.showDuplicateCategoryError = function () {
      _this.props.showGlobalModal({
        id: 'info',
        title: 'Unable to add collection at address',
        body: 'There is already a ' + (0, _find2.default)(_collectiblesCategories2.default, {
          categoryCode: _this.props.collectiblesSelectedCategory
        }).title.replace(/s$/, '').toLowerCase() + ' collection in your quote located at that address. Would you like to edit that collection?',
        afterConfirm: function afterConfirm() {
          return _this.props.navTo('quote');
        }
      });
    }, _this.handleNext = function (e) {
      var _this$props = _this.props,
          categoryCode = _this$props.categoryCode,
          collectiblesCollectionsByCollectionId = _this$props.collectiblesCollectionsByCollectionId,
          collectiblesSelectedCategory = _this$props.collectiblesSelectedCategory,
          collectiblesQuote = _this$props.collectiblesQuote,
          createCollectiblesQuote = _this$props.createCollectiblesQuote,
          getCollectiblesPlans = _this$props.getCollectiblesPlans,
          isModalEditComplete = _this$props.isModalEditComplete,
          navTo = _this$props.navTo,
          notifyExclusion = _this$props.notifyExclusion,
          onEditCompletion = _this$props.onEditCompletion,
          onError = _this$props.onError,
          onSubmitAddress = _this$props.onSubmitAddress,
          propertyAddress = _this$props.propertyAddress,
          responses = _this$props.responses,
          updateCollectiblesAddress = _this$props.updateCollectiblesAddress;

      if (e) {
        e.preventDefault();
      }

      if (_this.isAddressFormVisible) {
        _this.setState({
          isSubmitting: true
        });

        return _this.residenceAddressComponent.getGeocodedValue().then(function (location) {
          updateCollectiblesAddress({
            lat: location.lat,
            lng: location.lng,
            country: location.country,
            postal: location.postal
          });

          _this.setState({
            isSubmitting: false
          });

          if ((0, _filter2.default)(collectiblesCollectionsByCollectionId, {
            category_code: collectiblesSelectedCategory,
            address: {
              line1: propertyAddress.streetAddress,
              line2: propertyAddress.unit,
              city: propertyAddress.city,
              state_or_region: propertyAddress.region,
              country_code: propertyAddress.country,
              postal: propertyAddress.postal
            }
          }).length && !isModalEditComplete) {
            _this.showDuplicateCategoryError();
          } else {
            if (!collectiblesQuote.quote_id) {
              return createCollectiblesQuote().then(function (quote) {
                analytics.identify({
                  email: responses.owner_email
                });
                analytics.track('Collectibles - Verify Address', {
                  categoryCode: categoryCode
                });
                return getCollectiblesPlans(collectiblesQuote.quote_id).then(function (response) {
                  analytics.track('Collectibles - Address Verified', {
                    categoryCode: categoryCode,
                    noninteraction: 1
                  });
                  notifyExclusion({
                    title: 'Exclusion Notice:',
                    body: response.exclusion_notice
                  });
                  var nextUrl = (0, _last2.default)(_this.props.location.pathname.split('/')) === 'new' ? '../survey/' + response.dynamic_form.start_screen_id : 'survey/' + response.dynamic_form.start_screen_id;
                  navTo(nextUrl);
                });
              });
            } else {
              if (isModalEditComplete) {
                onEditCompletion();
              } else {
                return onSubmitAddress();
              }
            }
          }
        }).catch(function (error) {
          _this.setState({
            isSubmitting: false
          });

          onError(error);
        });
      } else {
        if (_this.state.checkedNode === 'new') {
          navTo('address/new');
        } else {
          analytics.track('Collectibles - Existing Address Selected', {
            categoryCode: categoryCode
          });
          var selectedAddress = _this.existingAddresses[_this.state.checkedNode];
          var groomedAddress = {
            streetAddress: selectedAddress.line1,
            unit: selectedAddress.line2,
            city: selectedAddress.city,
            postal: selectedAddress.postal,
            region: selectedAddress.state_or_region,
            country: selectedAddress.country_code
          };
          updateCollectiblesAddress(groomedAddress);

          if ((0, _filter2.default)(collectiblesCollectionsByCollectionId, {
            category_code: collectiblesSelectedCategory,
            address: selectedAddress
          }).length) {
            _this.showDuplicateCategoryError();
          } else {
            return getCollectiblesPlans(collectiblesQuote.quote_id).then(function (response) {
              navTo('survey/' + response.dynamic_form.start_screen_id);
            }).catch(onError);
          }
        }
      }
    }, _this.handleInputChange = function (e) {
      if (e.target.id) {
        _this.props.saveResponse(_defineProperty({}, e.target.name, e.target.value));
      }
    }, _this.handleExistingAddressSelection = function (e) {
      _this.setState({
        checkedNode: e.target.value
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesAddress, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Current Collectibles Address', {
        categoryCode: this.props.categoryCode
      });

      if ((0, _get3.default)(this.props.collectiblesQuote, 'application.plan_applications[0].collections')) {
        this.setState({
          isQuoteEmpty: false
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          collectiblesSelectedCategory = _props.collectiblesSelectedCategory,
          disclaimer = _props.disclaimer,
          isCollectiblesInProgress = _props.isCollectiblesInProgress,
          partnerInfo = _props.partnerInfo,
          nextBtnText = _props.nextBtnText;
      return _react2.default.createElement('div', {
        className: 'CollectiblesAddress',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 317
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesAddress-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 318
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 319
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'CollectiblesAddress-pane-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 320
        },
        __self: this
      }, 'Where is this ' + (collectiblesSelectedCategory ? (0, _find2.default)(_collectiblesCategories2.default, {
        categoryCode: collectiblesSelectedCategory
      }).title.replace(/s$/, '').toLowerCase() + ' ' : '') + ' collection located?'), _react2.default.createElement('h3', {
        className: 'CollectiblesAddress-pane-subheading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 331
        },
        __self: this
      }, '(We use that address to calculate your quote)'), _react2.default.createElement('form', {
        className: 'CollectiblesAddress-pane-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 334
        },
        __self: this
      }, this.isAddressFormVisible ? this.newAddressNode : this.existingAddressesNode, _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        ref: function ref(c) {
          return _this2.inputSubmit = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 341
        },
        __self: this
      })), _react2.default.createElement(_CreditCardLogos2.default, {
        className: 'CollectiblesAddress-creditCardLogos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 347
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        className: 'CollectiblesAddress-planDisclaimer',
        text: disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 348
        },
        __self: this
      }))), _react2.default.createElement(_PagerButtons2.default, {
        nextBtnText: nextBtnText,
        onNext: function onNext() {
          return _this2.inputSubmit.click();
        },
        isNextDisabled: this.state.isSubmitting || this.isNextDisabled,
        isBackHidden: true,
        isInProgress: isCollectiblesInProgress || this.state.isSubmitting,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 356
        },
        __self: this
      }));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      var propertyAddress = this.props.propertyAddress;
      return this.isAddressFormVisible ? ![propertyAddress.streetAddress, propertyAddress.city, propertyAddress.region, propertyAddress.postal].every(function (val) {
        return val;
      }) : !this.state.checkedNode;
    }
  }, {
    key: 'isAddressFormVisible',
    get: function get() {
      return this.state.isQuoteEmpty || (0, _last2.default)(this.props.location.pathname.split('/')) === 'new' || (0, _get3.default)(this.props, 'isInModal', false);
    }
  }, {
    key: 'newAddressNode',
    get: function get() {
      var _this3 = this;

      var _props2 = this.props,
          propertyAddress = _props2.propertyAddress,
          updateCollectiblesAddress = _props2.updateCollectiblesAddress;
      return _react2.default.createElement(_AddressFormNew2.default, {
        autoFocus: true,
        streetAddress: propertyAddress.streetAddress,
        unit: propertyAddress.unit,
        city: propertyAddress.city,
        region: propertyAddress.region,
        postal: propertyAddress.postal,
        country: propertyAddress.country,
        ref: function ref(c) {
          return _this3.residenceAddressComponent = c;
        },
        onChange: updateCollectiblesAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 251
        },
        __self: this
      });
    }
  }, {
    key: 'existingAddresses',
    get: function get() {
      var collectiblesQuote = this.props.collectiblesQuote;
      return (0, _uniqWith2.default)(collectiblesQuote.application.plan_applications[0].collections.map(function (collection) {
        return collection.address;
      }), _isEqual2.default);
    }
  }, {
    key: 'existingAddressesNode',
    get: function get() {
      var _this4 = this;

      return _react2.default.createElement(_FormGroup2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 277
        },
        __self: this
      }, this.existingAddresses.map(function (address, index) {
        var title = (0, _compact2.default)([address.line1, address.line2 && ', ' + address.line2, '\n', address.city + ' ' + address.state_or_region + ', ' + address.postal]).join('');
        return _react2.default.createElement(_RadioButton2.default, {
          key: (0, _without2.default)((0, _values2.default)(address), 'lat', 'lng', 'country').join(''),
          name: 'address',
          className: 'CollectiblesAddress-existingAddress',
          title: title,
          value: index,
          checked: _this4.state.checkedNode === index.toString(),
          onChange: _this4.handleExistingAddressSelection,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 286
          },
          __self: _this4
        });
      }), _react2.default.createElement(_RadioButton2.default, {
        name: 'address',
        value: 'new',
        title: 'Add new collection address',
        checked: this.state.checkedNode === 'new',
        onChange: this.handleExistingAddressSelection,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 297
        },
        __self: this
      }));
    }
  }]);

  return CollectiblesAddress;
}(_react.Component);

CollectiblesAddress.propTypes = propTypes;
CollectiblesAddress.defaultProps = defaultProps;
exports.default = CollectiblesAddress;