import ACTION_TYPES from '../../constants/actionTypes';

export function showGlobalModal({ id, ...rest }) {
  return {
    type: ACTION_TYPES.SHOW_GLOBAL_MODAL,
    payload: {
      id,
      ...rest,
    },
  };
}

export function hideGlobalModal() {
  return {
    type: ACTION_TYPES.HIDE_GLOBAL_MODAL,
  };
}

export function showMenu() {
  return {
    type: ACTION_TYPES.SHOW_MENU,
  };
}

export function hideMenu() {
  return {
    type: ACTION_TYPES.HIDE_MENU,
  };
}
