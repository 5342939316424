import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import seo from 'shared/lib/seo';
import {
  createHomeSafeQuote,
  getBeneficiaryRelationships,
  getHomeSafePlans,
  getHomeSafeCheckout,
  purchaseHomeSafe,
  selectHomeSafePlan,
  updateHomeSafeDetails,
  updateHomeSafeQuote,
} from '../../store/actions';
import find from 'lodash/find';
import withGlobals from '../../withGlobals';
import favicon from './images/favicon-chubb.ico';
import residentialImg from './images/chubb-claims-block-residential.png';
import './HomeSafeContainer.css';

class HomeSafeContainer extends PureComponent {
  componentDidMount() {
    seo.setProps({
      title: `Chubb Residential Insurance`,
      description:
        'Chubb Residential Insurance provides you with cover in the event of theft or accidental death and disability as a result of a home burglary.',
      url: `https://${window.location.host}/homesafe`,
      favicon,
      image: residentialImg,
    });
  }

  render() {
    const { children, ...otherProps } = this.props;
    return (
      <div className="HomeSafeContainer ChubbTheme">
        {React.cloneElement(children, otherProps)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    beneficiaryRelationships: state.beneficiaryRelationships,
    homeSafeSelectedPlanId: state.homeSafeSelectedPlanId,
    homeSafeSelectedPlan: find(state.homeSafePlans, {
      plan_id: state.homeSafeSelectedPlanId,
    }),
    homeSafeCheckout: state.homeSafeCheckout,
    homeSafeDetails: state.homeSafeDetails,
    homeSafePlans: state.homeSafePlans,
    homeSafeQuote: state.homeSafeQuote,
    homeSafeSettings: state.homeSafeSettings,
    isHomeSafeInProgress: state.isHomeSafeInProgress,
    disclaimer:
      '** Chubb Insurance South Africa Limited, an authorised Financial Services Provider (FSP: 27176). The policy wording will take precedence over all the guidance contained in the website and in all instances the terms and conditions of the policy wording will apply. Chubb only provides general guidance and does not consider your objectives, financial situation or needs; therefore this guidance cannot be considered as financial advice. Chubb can be e-mailed at clientqueries@chubb.com .',
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createHomeSafeQuote,
      getBeneficiaryRelationships,
      getHomeSafePlans,
      getHomeSafeCheckout,
      purchaseHomeSafe,
      selectHomeSafePlan,
      updateHomeSafeDetails,
      updateHomeSafeQuote,
    },
    dispatch
  );

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'HomeSafe',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(HomeSafeContainer);
