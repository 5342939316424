import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextInput, PagerButtons, Select } from 'sure-components';
import get from 'lodash/get';
import map from 'lodash/map';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import TelInput from 'shared/lib/components/TelInput';
import Cleave from 'cleave.js/dist/cleave-react';
import * as analytics from 'shared/lib/analytics';
import './HomeSafeBeneficiaryView.css';

const propTypes = {
  beneficiaryRelationships: PropTypes.array,
  homeSafeDetails: PropTypes.object,
  homeSafeSelectedPlan: PropTypes.object,
};

const defaultProps = {
  beneficiaryRelationships: [],
  homeSafeDetails: {},
  homeSafeSelectedPlan: {},
};

class HomeSafeBeneficiaryView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
    };
  }

  componentDidMount() {
    analytics.page('HomeSafe - Beneficiary Info');
    this.props.getBeneficiaryRelationships();
  }

  handleBack = () => {
    this.props.navTo('/residential-insurance/add-beneficiary');
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'beneficiaryInformationType') {
      analytics.track('HomeSafe - Beneficiary Info Type Changed', {
        label: value,
      });
      this.props.updateHomeSafeDetails({
        beneficiaryId: null,
        beneficiaryBirthdate: null,
      });
    }
    this.props.updateHomeSafeDetails({
      [name]: value,
    });
  };

  handleInitBirthdate = cleave => {
    cleave.setRawValue(this.props.homeSafeDetails.beneficiaryBirthdate);
  };

  handlePhoneChange = val => {
    this.props.updateHomeSafeDetails({
      beneficiaryPhone: val,
    });
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }
    const { updateHomeSafeQuote, navTo, onError } = this.props;
    this.setState({
      isInProgress: true,
    });
    updateHomeSafeQuote()
      .then(() => {
        navTo('/residential-insurance/additional-questions');
      })
      .catch(error => {
        this.setState({
          isInProgress: false,
        });
        onError(error);
      });
  };

  render() {
    const {
      beneficiaryRelationships,
      homeSafeDetails,
      homeSafeSelectedPlan,
      disclaimer,
    } = this.props;

    return (
      <div className="HomeSafeBeneficiaryView">
        <div className="Container Container--small">
          <h2 className="HomeSafeBeneficiaryView-planTitle">
            HomeSafe: {get(homeSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="HomeSafeBeneficiaryView-samplePolicyLink"
            href={homeSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Key Information Document
          </a>
          <h1 className="HomeSafeBeneficiaryView-heading">
            What is the beneficiary information?
          </h1>
          <form
            className="HomeSafeBeneficiaryView-form"
            onSubmit={this.handleNext}
          >
            <label>Beneficiary relationship</label>
            <Select
              name="beneficiaryRelationship"
              required
              value={homeSafeDetails.beneficiaryRelationship}
              onChange={this.handleChange}
            >
              <option value="">Relationship to Policy Holder</option>
              {map(beneficiaryRelationships, relationship => (
                <option key={relationship} value={relationship}>
                  {relationship}
                </option>
              ))}
            </Select>

            <FormGroup>
              <label>Beneficiary name</label>
              <TextInput
                name="beneficiaryFirstName"
                placeholder="First name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={homeSafeDetails.beneficiaryFirstName || ''}
                onChange={this.handleChange}
              />
              <TextInput
                name="beneficiaryLastName"
                placeholder="Last name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={homeSafeDetails.beneficiaryLastName || ''}
                onChange={this.handleChange}
              />
            </FormGroup>

            <label>Beneficiary information</label>
            <Select
              name="beneficiaryInformationType"
              value={homeSafeDetails.beneficiaryInformationType}
              required
              onChange={this.handleChange}
            >
              <option value="">Select one</option>
              <option value="id">ID Number</option>
              <option value="birthdate">Birthdate (dd/mm/yyyy)</option>
            </Select>
            {homeSafeDetails.beneficiaryInformationType === 'id' && (
              <TextInput
                name="beneficiaryId"
                placeholder="Enter ID Number"
                required
                minLength="13"
                maxLength="13"
                value={homeSafeDetails.beneficiaryId || ''}
                onChange={this.handleChange}
              />
            )}
            {homeSafeDetails.beneficiaryInformationType === 'birthdate' && (
              <Cleave
                className="HomeSafeBeneficiaryView-birthdate SureTextInput"
                name="beneficiaryBirthdate"
                placeholder="dd/mm/yyyy"
                required
                options={{
                  delimiter: '/',
                  blocks: [2, 2, 4],
                  numericOnly: true,
                }}
                onInit={this.handleInitBirthdate}
                onChange={this.handleChange}
                ref={c => (this.birthdateNode = c)}
              />
            )}

            <FormGroup>
              <label>Beneficiary contact info</label>
              <TelInput
                className="HomeSafeBeneficiaryView-telInput"
                required
                defaultCountry="za"
                preferredCountries={['za']}
                onChange={this.handlePhoneChange}
                value={homeSafeDetails.beneficiaryPhone || ''}
              />
              <TextInput
                name="beneficiaryEmail"
                placeholder="Email"
                type="email"
                required
                value={homeSafeDetails.beneficiaryEmail || ''}
                onChange={this.handleChange}
              />
            </FormGroup>
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="HomeSafeBeneficiaryView-cta"
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
            isInProgress={this.state.isInProgress}
          />
          <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
        </div>
      </div>
    );
  }
}

HomeSafeBeneficiaryView.propTypes = propTypes;
HomeSafeBeneficiaryView.defaultProps = defaultProps;

export default HomeSafeBeneficiaryView;
