'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/AddressAutocompleteFieldsNew/AddressAutocompleteFieldsNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

var _Select = require('shared/lib/components/Select');

var _Select2 = _interopRequireDefault(_Select);

var _FormGroup = require('shared/lib/components/FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _GooglePlacesSuggestInput = require('shared/lib/components/GooglePlacesSuggestInput');

var _GooglePlacesSuggestInput2 = _interopRequireDefault(_GooglePlacesSuggestInput);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  fields: _propTypes2.default.array,
  values: _propTypes2.default.object
};
var defaultProps = {
  fields: [],
  values: {}
};

var AddressAutocompleteFieldsNew = function (_Component) {
  _inherits(AddressAutocompleteFieldsNew, _Component);

  function AddressAutocompleteFieldsNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AddressAutocompleteFieldsNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AddressAutocompleteFieldsNew.__proto__ || Object.getPrototypeOf(AddressAutocompleteFieldsNew)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (e) {
      var _e$target = e.target,
          name = _e$target.name,
          value = _e$target.value,
          type = _e$target.type;

      if (type === 'number') {
        value = parseInt(value, 10);
      }

      _this.props.onChange(_defineProperty({}, name, value));
    }, _this.handleSuggestionSelect = function (address) {
      var flagKeyMapping = {
        address_line1: 'streetAddress',
        address_line2: 'unit',
        address_city: 'city',
        address_region: 'region',
        address_postal: 'postal',
        address_country: 'country'
      };
      var remappedResponsesObj = {};
      (0, _each2.default)(flagKeyMapping, function (addressKey, flagKey) {
        var field = (0, _find2.default)(_this.props.fields, function (field) {
          return (0, _includes2.default)(field.settings.flags, flagKey);
        });

        if (field) {
          remappedResponsesObj[field.key_name] = address[addressKey];
        }
      });

      _this.props.onChange(remappedResponsesObj);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AddressAutocompleteFieldsNew, [{
    key: 'getFieldLabel',
    value: function getFieldLabel(field) {
      var label = field.label;

      if (!field.required && this.props.fields.length === 1) {
        label += ' (optional)';
      }

      return label;
    }
  }, {
    key: 'renderSelectField',
    value: function renderSelectField(field) {
      var _this2 = this;

      var choices = (0, _get2.default)(field, 'settings.choices') || [];
      var options = choices.map(function (choice) {
        return _react2.default.createElement('option', {
          value: choice.value,
          key: choice.value,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 69
          },
          __self: _this2
        }, choice.title);
      });
      return _react2.default.createElement(_react.Fragment, {
        key: field.key_name + '-fragment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: field.key_name,
        key: field.key_name + '-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 77
        },
        __self: this
      }, this.getFieldLabel(field)), _react2.default.createElement(_Select2.default, {
        key: field.key_name,
        name: field.key_name,
        required: field.required,
        value: this.props.values[field.key_name],
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this2.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 84
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: '',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 92
        },
        __self: this
      }, this.getFieldLabel(field)), options));
    }
  }, {
    key: 'renderAutocompleteField',
    value: function renderAutocompleteField(field) {
      var _this3 = this;

      return _react2.default.createElement(_react.Fragment, {
        key: field.key_name + '-fragment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 101
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: field.key_name,
        key: field.key_name + '-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, this.getFieldLabel(field)), _react2.default.createElement(_GooglePlacesSuggestInput2.default, {
        className: 'Input--textNew',
        name: field.key_name,
        placeholder: this.getFieldLabel(field),
        required: field.required,
        value: this.props.values[field.key_name] || undefined,
        onChange: this.handleChange,
        onSuggestionSelect: this.handleSuggestionSelect,
        ref: function ref(c) {
          return _this3.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 109
        },
        __self: this
      }));
    }
  }, {
    key: 'renderTextField',
    value: function renderTextField(field) {
      var _this4 = this;

      var regExpStr = (0, _get2.default)(field, 'settings.regex');
      var unicodeRegExp = /\\u([\d\w]{4})/gi;
      var decodedRegExp = void 0;

      if (regExpStr) {
        decodedRegExp = regExpStr.replace(unicodeRegExp, function (match, grp) {
          return String.fromCharCode(parseInt(grp, 16));
        });
      }

      return _react2.default.createElement(_react.Fragment, {
        key: field.key_name + '-fragment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 135
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: field.key_name,
        key: field.key_name + '-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 136
        },
        __self: this
      }, this.getFieldLabel(field)), _react2.default.createElement(_TextInput2.default, {
        key: field.key_name,
        name: field.key_name,
        type: field.field_type,
        max: (0, _get2.default)(field, 'settings.max_value'),
        min: (0, _get2.default)(field, 'settings.min_value'),
        required: field.required,
        pattern: decodedRegExp,
        value: this.props.values[field.key_name] || '',
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this4.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 143
        },
        __self: this
      }));
    }
  }, {
    key: 'validate',
    value: function validate() {
      if (this.fieldNode.validate) {
        return this.fieldNode.validate();
      } else {
        return Promise.resolve();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this5 = this;

      var inputs = this.props.fields.map(function (field) {
        if (field.widget === 'autocomplete_address') {
          return _this5.renderAutocompleteField(field);
        } else if (!(0, _isEmpty2.default)((0, _get2.default)(field, 'settings.choices'))) {
          return _this5.renderSelectField(field);
        } else {
          return _this5.renderTextField(field);
        }
      });
      return _react2.default.createElement('div', {
        className: 'AddressAutocompleteFieldsNew',
        style: {
          marginTop: '20px'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 179
        },
        __self: this
      }, _react2.default.createElement(_FormGroup2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 183
        },
        __self: this
      }, inputs));
    }
  }]);

  return AddressAutocompleteFieldsNew;
}(_react.Component);

AddressAutocompleteFieldsNew.propTypes = propTypes;
AddressAutocompleteFieldsNew.defaultProps = defaultProps;
exports.default = AddressAutocompleteFieldsNew;