'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CreditCardLogos/CreditCardLogos.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _iconMc = require('./icon-mc.svg');

var _iconMc2 = _interopRequireDefault(_iconMc);

var _iconVisa = require('./icon-visa.svg');

var _iconVisa2 = _interopRequireDefault(_iconVisa);

var _iconAmex = require('./icon-amex.svg');

var _iconAmex2 = _interopRequireDefault(_iconAmex);

var _iconDiscover = require('./icon-discover.svg');

var _iconDiscover2 = _interopRequireDefault(_iconDiscover);

var _iconSecure = require('./icon-secure.png');

var _iconSecure2 = _interopRequireDefault(_iconSecure);

require('./CreditCardLogos.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  activeLink: _propTypes2.default.string,
  className: _propTypes2.default.string
};

var CreditCardLogos = function (_PureComponent) {
  _inherits(CreditCardLogos, _PureComponent);

  function CreditCardLogos() {
    _classCallCheck(this, CreditCardLogos);

    return _possibleConstructorReturn(this, (CreditCardLogos.__proto__ || Object.getPrototypeOf(CreditCardLogos)).apply(this, arguments));
  }

  _createClass(CreditCardLogos, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_react.Fragment, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 19
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: (0, _classnames2.default)(this.props.className, 'CreditCardLogos-logos'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 20
        },
        __self: this
      }, _react2.default.createElement('img', {
        className: 'CreditCardLogos-logos-icon',
        src: _iconVisa2.default,
        alt: 'Visa logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 23
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'CreditCardLogos-logos-icon',
        src: _iconMc2.default,
        alt: 'Mastercard logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'CreditCardLogos-logos-icon',
        src: _iconAmex2.default,
        alt: 'Amex logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 33
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'CreditCardLogos-logos-icon',
        src: _iconDiscover2.default,
        alt: 'Discover logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 38
        },
        __self: this
      }), _react2.default.createElement('img', {
        className: 'CreditCardLogos-logos-icon CreditCardLogos-logos-icon--secure',
        src: _iconSecure2.default,
        alt: 'Security logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      })), _react2.default.createElement('div', {
        className: (0, _classnames2.default)(this.props.className, 'CreditCardLogos-connection'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49
        },
        __self: this
      }, _react2.default.createElement('span', {
        className: 'CreditCardLogos-connection-icon fas fa-lock',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 55
        },
        __self: this
      }), _react2.default.createElement('p', {
        className: 'CreditCardLogos-connection-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 56
        },
        __self: this
      }, 'All transactions are secure and encrypted.')));
    }
  }]);

  return CreditCardLogos;
}(_react.PureComponent);

CreditCardLogos.propTypes = propTypes;
exports.default = CreditCardLogos;