import React, { Component } from 'react';
import Button from 'shared/lib/components/Button';
import * as typeformEmbed from '@typeform/embed';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import Footer from '../Footer';
import TopNavBar from '../TopNavBar';
import LandingBlockThreeUp from 'shared/lib/components/LandingBlockThreeUp';
import LandingBlockPartner from 'shared/lib/components/LandingBlockPartner';
import LandingBlockCTABanner from 'shared/lib/components/LandingBlockCTABanner';
import * as analytics from 'shared/lib/analytics';
import logoChubb from './logo-chubb.png';
import imageHero from './bop-hero.jpg';
import imageThreeUpLiability from './threeUp-general-liability.png';
import imageThreeUpProperty from './threeUp-business-personal-property.png';
import imageThreeIncome from './threeUp-lost-income.png';
import './LandingBop.css';

export default class LandingBop extends Component {
  constructor(props) {
    super(props);
    this.typeformModal = typeformEmbed.makePopup(
      `https://sureapp.typeform.com/to/MOsleL?id=${get(
        this.props.partnerInfo,
        'client_id',
        'blue_app'
      )}&partner=${kebabCase(get(this.props.partnerInfo, 'name', 'blue_app'))}`,
      {}
    );
  }

  componentDidMount() {
    analytics.page('BOP - Landing');
  }

  handleTypeformClick = () => {
    this.typeformModal.open();
  };

  get heroBgStyle() {
    return {
      backgroundImage: `
        linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
        url('${imageHero}')
      `,
    };
  }

  render() {
    const { partnerInfo, isMenuOpen, settings, onToggleMenu } = this.props;

    return (
      <div className="LandingBop">
        <div className="LandingBop-hero" style={this.heroBgStyle}>
          <TopNavBar
            transparent
            showMenuText
            isMenuOpen={isMenuOpen}
            sideMenuLinks={settings.sideMenuLinks}
            logoLightUrl={settings.logoLightUrl}
            logoDarkUrl={settings.logoDarkUrl}
            title={settings.title}
            onToggleMenu={onToggleMenu}
          />
          <div className="LandingBopContainer">
            <h1 className="LandingBop-hero-heading">
              Protect your small business so you can focus on making it grow.
            </h1>
            <p className="LandingBop-hero-pitch">
              You’ve done the hard work. Now it’s time to ensure that you are
              protected. Sure’s all-in-one policy that covers critical items
              allows you to run your business with peace of mind.
            </p>
            <div className="LandingBop-hero-ctaContainer">
              <Button
                className="LandingBop-cta"
                onClick={this.handleTypeformClick}
                sureStyle="secondary"
                shadow
              >
                Check our prices
              </Button>
            </div>
          </div>
        </div>

        <LandingBlockThreeUp
          title="What does small business insurance cover?"
          subtitle="Get protected from the unexpected, with a policy tailored to your needs. Small business insurance covers:"
          cards={[
            {
              backgroundImage: imageThreeUpLiability,
              heading: 'General Liability',
              description:
                'Covers legal and litigation issues tied to 3rd party claims of property damage, and bodily injury and associated medical costs.',
            },
            {
              backgroundImage: imageThreeUpProperty,
              heading: 'Business Personal Property',
              description:
                'Covers loss or damage to property owned by the business, such as computers, furniture, machinery, etc.',
            },
            {
              backgroundImage: imageThreeIncome,
              heading: 'Lost Business Income',
              description:
                'Pays back lost income if a business has to close for a covered loss.',
            },
          ]}
        />

        <LandingBlockPartner
          title="We've partnered with the most trusted insurers to get the right coverage for your business."
          partnerName="Chubb"
          logo={logoChubb}
        >
          <p>
            Chubb Small Commercial Insurance leverages superior underwriting
            expertise with the world renowned claims, account services, and
            financial strength that you expect from Chubb.
          </p>
          <p>
            Chubb is the world’s largest publicly traded P&C insurance company
            and the largest commercial insurer in the U.S.
          </p>
        </LandingBlockPartner>

        <LandingBlockCTABanner
          title="Protect your investments in your business."
          subtitle="Let us pair you with the best policy for you and your small business."
          ctaText="Check our prices"
          ctaStyle="secondary"
          onClick={this.handleTypeformClick}
        />

        <Footer partnerInfo={partnerInfo} />
      </div>
    );
  }
}
