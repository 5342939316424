'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _endsWith = require('lodash/endsWith');

var _endsWith2 = _interopRequireDefault(_endsWith);

var _head = require('lodash/head');

var _head2 = _interopRequireDefault(_head);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _utils = require('shared/lib/utils');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (api) {
  function submitRentersAddressRequest(data) {
    return {
      type: _actionTypes2.default.SUBMIT_RENTERS_ADDRESS,
      data: data
    };
  }

  function submitRentersAddressSuccess(data) {
    return {
      type: _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_SUCCESS,
      data: data
    };
  }

  function submitRentersAddressFailure(error) {
    return {
      type: _actionTypes2.default.SUBMIT_RENTERS_ADDRESS_FAILURE,
      error: error
    };
  }

  function selectRentersChoiceByFieldAndValue(field, value, dispatch) {
    var choice = (0, _find2.default)(field.settings.choices, {
      value: value
    });

    if (choice && choice.screen_id) {
      dispatch({
        type: _actionTypes2.default.SELECT_RENTERS_CHOICE,
        screen_id: choice.screen_id
      });
    }
  }

  function calculateRentersQuoteRequest() {
    return {
      type: _actionTypes2.default.CALCULATE_RENTERS_QUOTE
    };
  }

  function calculateRentersQuoteSuccess(data) {
    return {
      type: _actionTypes2.default.CALCULATE_RENTERS_QUOTE_SUCCESS,
      data: data
    };
  }

  function calculateRentersQuoteFailure(error) {
    return {
      type: _actionTypes2.default.CALCULATE_RENTERS_QUOTE_FAILURE,
      error: error
    };
  }

  function getRentersRates() {
    return {
      type: _actionTypes2.default.GET_RENTERS_RATES
    };
  }

  function getRentersRatesSuccess(ratesResponse) {
    return {
      type: _actionTypes2.default.GET_RENTERS_RATES_SUCCESS,
      payload: {
        quoteId: ratesResponse.quote_id,
        rates: ratesResponse.rates
      }
    };
  }

  function getRentersRatesIfNeeded(dispatch, getState) {
    var _getState = getState(),
        authToken = _getState.authToken,
        rentersPlan = _getState.rentersPlan,
        rentersPolicyStartDate = _getState.rentersPolicyStartDate,
        rentersPropertyAddress = _getState.rentersPropertyAddress,
        rentersQuote = _getState.rentersQuote,
        rentersRates = _getState.rentersRates,
        rentersRatesFetchedAt = _getState.rentersRatesFetchedAt,
        rentersScreens = _getState.rentersScreens,
        rentersResponses = _getState.rentersResponses;

    var details = (0, _utils.filterDynamicFormResponses)(rentersScreens, rentersResponses);
    (0, _each2.default)(details, function (val, key) {
      if ((0, _endsWith2.default)(key, '_dob')) {
        var _val$match$slice = val.match(/(\d{0,2})\/(\d{0,2})\/(\d{2,4})/).slice(1),
            _val$match$slice2 = _slicedToArray(_val$match$slice, 3),
            month = _val$match$slice2[0],
            day = _val$match$slice2[1],
            year = _val$match$slice2[2];

        details[key] = [year, month, day].join('-');
      }
    });
    details.property_special_city = rentersPropertyAddress.city;
    details.property_special_county = rentersPropertyAddress.county;
    return new Promise(function (resolve, reject) {
      if (rentersRatesFetchedAt && (0, _momentTimezone2.default)().diff((0, _momentTimezone2.default)(rentersRatesFetchedAt), 'hours') < 4 && (0, _get2.default)(rentersQuote, 'id')) {
        resolve({
          quote_id: (0, _get2.default)(rentersQuote, 'id'),
          rates: rentersRates
        });
      } else {
        api.getRentersRates({
          authToken: authToken,
          details: details,
          planId: rentersPlan.plan_id,
          policyEffectiveDate: (0, _momentTimezone2.default)(rentersPolicyStartDate).format('YYYY-MM-DD'),
          propertyAddress: rentersPropertyAddress,
          quoteId: (0, _get2.default)(rentersQuote, 'quote.id')
        }).then(resolve).catch(reject);
      }
    });
  }

  function _calculateRentersQuote(dispatch, getState) {
    dispatch(calculateRentersQuoteRequest());

    var _getState2 = getState(),
        authToken = _getState2.authToken,
        rentersPlan = _getState2.rentersPlan,
        rentersPolicyStartDate = _getState2.rentersPolicyStartDate,
        rentersPropertyAddress = _getState2.rentersPropertyAddress,
        rentersQuote = _getState2.rentersQuote,
        rentersScreens = _getState2.rentersScreens,
        rentersResponses = _getState2.rentersResponses,
        rentersSelectedAmounts = _getState2.rentersSelectedAmounts,
        rentersSelectedPaymentCadence = _getState2.rentersSelectedPaymentCadence,
        ratesSettings = _getState2.ratesSettings;

    var details = (0, _utils.filterDynamicFormResponses)(rentersScreens, rentersResponses);
    (0, _each2.default)(details, function (val, key) {
      if ((0, _endsWith2.default)(key, '_dob')) {
        var _val$match$slice3 = val.match(/(\d{0,2})\/(\d{0,2})\/(\d{2,4})/).slice(1),
            _val$match$slice4 = _slicedToArray(_val$match$slice3, 3),
            month = _val$match$slice4[0],
            day = _val$match$slice4[1],
            year = _val$match$slice4[2];

        details[key] = [year, month, day].join('-');
      }
    });
    details.property_special_city = rentersPropertyAddress.city;
    details.property_special_county = rentersPropertyAddress.county;
    return new Promise(function (resolve, reject) {
      dispatch(getRentersRates());
      getRentersRatesIfNeeded(dispatch, getState).then(function (ratesResponse) {
        /**
         * Two liability_limit params can be configured:
         * liability_limit - The default selected limit (must match an available option)
         * min_liability_limit - The minimum amount to show (hides lower options)
         */
        // Filter those below the set minimum liability limit
        if (ratesSettings && ratesSettings.min_liability_limit) {
          ratesResponse.rates.liability = (0, _filter2.default)(ratesResponse.rates.liability, function (liabilityObj) {
            return liabilityObj.liability_limit >= ratesSettings.min_liability_limit;
          });
        }

        var liabilityObj = ratesSettings && (0, _find2.default)(ratesResponse.rates.liability, {
          liability_limit: ratesSettings.liability_limit
        }) || ratesResponse.rates.liability[0];
        var selectedRates = Object.assign(Object.assign({}, ratesResponse.rates.personal_property[0], liabilityObj), ratesResponse.rates.defaults, rentersSelectedAmounts);
        var checkoutPayload = {
          authToken: authToken,
          details: details,
          email: rentersResponses.pni_email,
          selectedRates: selectedRates,
          planId: rentersPlan.plan_id,
          policyEffectiveDate: (0, _momentTimezone2.default)(rentersPolicyStartDate).format('YYYY-MM-DD'),
          propertyAddress: rentersPropertyAddress,
          quoteId: (0, _get2.default)(rentersQuote, 'quote.id') || ratesResponse.quote_id,
          paymentCadence: rentersSelectedPaymentCadence
        };
        dispatch(getRentersRatesSuccess(ratesResponse));
        dispatch(updateRentersSelectedAmounts(selectedRates));
        return api.getRentersCheckoutInfo(checkoutPayload).then(function (checkoutInfo) {
          var checkoutResult = {
            quote: checkoutInfo,
            rates: ratesResponse.rates
          };
          dispatch(calculateRentersQuoteSuccess(checkoutResult));
          resolve(checkoutInfo);
        });
      }).catch(function (error) {
        dispatch(calculateRentersQuoteFailure(error));
        reject(error);
      });
    });
  }

  function updateRentersSelectedAmounts(data) {
    return {
      type: _actionTypes2.default.UPDATE_RENTERS_SELECTED_AMOUNTS,
      data: data
    };
  }

  function getRentersPaymentCadencesRequest() {
    return {
      type: _actionTypes2.default.GET_PAYMENT_CADENCES
    };
  }

  function getRentersPaymentCadencesSuccess(rentersPaymentCadences) {
    return {
      type: _actionTypes2.default.GET_PAYMENT_CADENCES_SUCCESS,
      payload: rentersPaymentCadences
    };
  }

  function getRentersPaymentCadencesFailure(error) {
    return {
      type: _actionTypes2.default.GET_PAYMENT_CADENCES_FAILURE,
      error: error
    };
  }

  function startRentersPurchase() {
    return {
      type: _actionTypes2.default.PURCHASE_RENTERS
    };
  }

  function purchaseRentersSuccess(data) {
    // XXX: Deprecated v0 event
    (0, _utils.emitMessage)({
      actionType: 'PURCHASE_SUCCESS'
    }); // Updated v1 event

    (0, _utils.emitMessage)({
      actionType: _actionTypes2.default.PURCHASE_RENTERS_SUCCESS
    });
    return {
      type: _actionTypes2.default.PURCHASE_RENTERS_SUCCESS,
      data: data
    };
  }

  function purchaseRentersError(error) {
    return {
      type: _actionTypes2.default.PURCHASE_RENTERS_FAILURE,
      error: error
    };
  } // ----------------------------------------
  // Exports
  // ----------------------------------------


  return {
    submitRentersAddress: function submitRentersAddress() {
      return function (dispatch, getState) {
        var _getState3 = getState(),
            authToken = _getState3.authToken,
            rentersPropertyAddress = _getState3.rentersPropertyAddress,
            rentersResponses = _getState3.rentersResponses;

        dispatch(submitRentersAddressRequest({
          rentersPropertyAddress: rentersPropertyAddress,
          rentersResponses: rentersResponses
        }));
        return new Promise(function (resolve, reject) {
          api.getRentersPlans({
            authToken: authToken,
            propertyAddress: rentersPropertyAddress,
            personalInfo: {
              firstName: rentersResponses.pni_first_name,
              lastName: rentersResponses.pni_last_name,
              phone: rentersResponses.pni_phone_number,
              email: rentersResponses.pni_email
            }
          }).then(function (response) {
            if (response.dynamic_form.start_screen_id === (0, _utils.findPniScreenId)(response.dynamic_form.screens)) {
              response.dynamic_form.start_screen_id = response.dynamic_form.screens[response.dynamic_form.start_screen_id].next_screen_id;
            }

            dispatch(submitRentersAddressSuccess(response));
            resolve(response);
          }).catch(function (error) {
            dispatch(submitRentersAddressFailure(error));
            reject(error);
          });
        });
      };
    },
    updateRentersAddress: function updateRentersAddress(address) {
      return {
        type: _actionTypes2.default.UPDATE_RENTERS_ADDRESS,
        address: address
      };
    },
    rentersSurveyNext: function rentersSurveyNext() {
      return {
        type: _actionTypes2.default.RENTERS_SURVEY_NEXT
      };
    },
    updateRentersCurrentResponse: function updateRentersCurrentResponse(response) {
      return {
        type: _actionTypes2.default.UPDATE_RENTERS_CURRENT_RESPONSE,
        payload: {
          response: response
        }
      };
    },
    saveRentersResponse: function saveRentersResponse(response) {
      return function (dispatch, getState) {
        var _getState4 = getState(),
            rentersScreens = _getState4.rentersScreens,
            rentersCurrentScreenId = _getState4.rentersCurrentScreenId;

        var screen = rentersScreens[rentersCurrentScreenId];
        var fieldsType = (0, _utils.getFieldsType)((0, _get2.default)(screen, 'fields'));

        if (fieldsType === _utils.FIELDS_TYPES.SINGLE_SELECT || fieldsType === _utils.FIELDS_TYPES.MULTI_SELECT) {
          var field = (0, _head2.default)(screen.fields);
          (0, _each2.default)(response, function (value, key) {
            if ((0, _isArray2.default)(value)) {
              (0, _each2.default)(value, function (_value) {
                selectRentersChoiceByFieldAndValue(field, _value, dispatch);
              });
            } else {
              selectRentersChoiceByFieldAndValue(field, value, dispatch);
            }
          });
        }

        dispatch({
          type: _actionTypes2.default.SAVE_RENTERS_RESPONSE,
          response: response
        });
        return Promise.resolve();
      };
    },
    deleteRentersResponse: function deleteRentersResponse() {
      var responseKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return {
        type: _actionTypes2.default.DELETE_RENTERS_RESPONSE,
        payload: {
          responseKeys: responseKeys
        }
      };
    },
    surveyRentersBack: function surveyRentersBack() {
      return {
        type: _actionTypes2.default.RENTERS_SURVEY_BACK
      };
    },
    calculateRentersQuote: function calculateRentersQuote() {
      return function (dispatch, getState) {
        var _getState5 = getState(),
            rentersPlan = _getState5.rentersPlan;

        return _calculateRentersQuote(dispatch, getState);
      };
    },
    purchaseRenters: function purchaseRenters(_ref) {
      var cardElement = _ref.cardElement,
          cardName = _ref.cardName,
          cardAddressLine1 = _ref.cardAddressLine1,
          cardAddressCity = _ref.cardAddressCity,
          cardAddressState = _ref.cardAddressState;
      return function (dispatch, getState) {
        var _getState$rentersResp = getState().rentersResponses,
            firstName = _getState$rentersResp.pni_first_name,
            lastName = _getState$rentersResp.pni_last_name,
            email = _getState$rentersResp.pni_email;

        var _getState6 = getState(),
            rentersPlan = _getState6.rentersPlan,
            rentersQuote = _getState6.rentersQuote,
            rentersSelectedPaymentCadence = _getState6.rentersSelectedPaymentCadence,
            authToken = _getState6.authToken,
            metadata = _getState6.metadata;

        var revenue = (0, _get2.default)(rentersQuote, 'totals.grandtotal');
        var opts = {
          planId: rentersPlan.plan_id,
          quoteId: rentersQuote.quote.id,
          paymentCadence: rentersSelectedPaymentCadence,
          authToken: authToken,
          cardName: cardName,
          cardAddressLine1: cardAddressLine1,
          cardAddressCity: cardAddressCity,
          cardAddressState: cardAddressState,
          firstName: firstName,
          lastName: lastName,
          email: email,
          revenue: revenue,
          cardElement: cardElement,
          metadata: metadata
        };
        dispatch(startRentersPurchase());
        return new Promise(function (resolve, reject) {
          api.purchaseRenters(opts).then(function (purchaseData) {
            dispatch(purchaseRentersSuccess(purchaseData));
            resolve();
          }).catch(function (err) {
            dispatch(purchaseRentersError(err));
            reject(err);
          });
        });
      };
    },
    updateRentersSelectedAmounts: updateRentersSelectedAmounts,
    updateRentersStartDate: function updateRentersStartDate(momentObj) {
      return {
        type: _actionTypes2.default.UPDATE_RENTERS_START_DATE,
        payload: {
          isoString: momentObj && momentObj.toISOString() || null
        }
      };
    },
    getRentersPaymentCadences: function getRentersPaymentCadences() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function (dispatch, getState) {
        var _getState7 = getState(),
            authToken = _getState7.authToken,
            rentersPropertyAddress = _getState7.rentersPropertyAddress,
            rentersQuote = _getState7.rentersQuote;

        dispatch(getRentersPaymentCadencesRequest());
        return new Promise(function (resolve, reject) {
          api.getRentersPaymentCadences({
            authToken: authToken,
            quoteId: (0, _get2.default)(rentersQuote, 'quote.id'),
            property_address: {
              street_address: rentersPropertyAddress.streetAddress,
              unit: rentersPropertyAddress.unit,
              city: rentersPropertyAddress.city,
              region: rentersPropertyAddress.region,
              postal: rentersPropertyAddress.postal,
              country_code: rentersPropertyAddress.country
            }
          }).then(function (rentersPaymentCadences) {
            dispatch(getRentersPaymentCadencesSuccess(rentersPaymentCadences));
            resolve(rentersPaymentCadences);
          }).catch(function (error) {
            dispatch(getRentersPaymentCadencesFailure(error));
            reject(error);
          });
        });
      };
    },
    selectRentersPaymentCadence: function selectRentersPaymentCadence(rentersPaymentCadence) {
      return {
        type: _actionTypes2.default.SELECT_PAYMENT_CADENCE,
        payload: {
          rentersPaymentCadence: rentersPaymentCadence
        }
      };
    },
    resetRentersData: function resetRentersData() {
      return {
        type: _actionTypes2.default.RESET_RENTERS_DATA
      };
    }
  };
};