import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import Button from 'shared/lib/components/Button';
import Card from 'shared/lib/components/Card';
import { format as currencyFormat } from 'currency-formatter';
import * as analytics from 'shared/lib/analytics';
import './ChubbPlansTable.css';

const propTypes = {
  currencyCode: PropTypes.string,
  headerTitleNote: PropTypes.string,
  plans: PropTypes.array,
  onSelect: PropTypes.function,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
    })
  ),
};

const defaultProps = {
  currencyCode: 'ZAR',
  plans: [],
  onSelect: () => {},
  headers: [],
};

class ChubbPlansTable extends PureComponent {
  handleSelect = e => {
    const planId = e.target.dataset.planid;
    this.props.onSelect(planId);
  };

  trackClick = e => {
    const planId = e.target.dataset.planid;
    analytics.track('CarSafe - Key Information Doc Link Clicked', {
      label: planId,
    });
  };

  render() {
    const { headers, plans, currencyCode, headerTitleNote } = this.props;
    const currencyOpts = {
      code: currencyCode,
      precision: 0,
    };

    return (
      <div className="ChubbPlansTable">
        <div className="ChubbPlansTable-plansLegend">
          <div className="ChubbPlansTable-plansLegend-header">
            <h2 className="ChubbPlansTable-plansLegend-title">
              Plan Details:
              {headerTitleNote && (
                <small className="ChubbPlansTable-plansLegend-title-note">
                  (Motor Vehicle only)*
                </small>
              )}
            </h2>
          </div>
          <ul className="ChubbPlansTable-plansLegend-list">
            {headers.map(({ key, content }) => (
              <li
                key={key}
                className="ChubbPlansTable-plansLegend-list-heading"
              >
                {content}
              </li>
            ))}
          </ul>
        </div>

        {map(plans, plan => (
          <Card key={plan.plan_id} className="ChubbPlansTable-plan">
            <div className="ChubbPlansTable-plan-header">
              <h2 className="ChubbPlansTable-plan-title">
                {plan.information.title}
              </h2>
              <a
                href={plan.policy_doc_url}
                className="ChubbPlansTable-plan-policyDocLink"
                target="_blank"
                rel="noopener noreferrer"
                data-planid={plan.plan_id}
                onClick={this.trackClick}
              >
                View Key Information Document
              </a>
              <h3 className="ChubbPlansTable-plan-price">
                {plan.information.pricing_title}
                <small className="ChubbPlansTable-plan-pricePeriod">
                  per month
                </small>
              </h3>
              <Button
                className="ChubbPlansTable-plan-cta"
                data-planid={plan.plan_id}
                onClick={this.handleSelect}
              >
                Select Plan
              </Button>
            </div>
            <dl className="ChubbPlansTable-list">
              {headers.map(({ key, content }) => (
                <Fragment key={key}>
                  <dt className="ChubbPlansTable-list-heading">{content}</dt>
                  <dd className="ChubbPlansTable-list-detail">
                    <span className="ChubbPlansTable-list-detail-covered">
                      Covered up to{' '}
                    </span>
                    {currencyFormat(
                      get(plan, `details.coverage_amounts.${key}`),
                      currencyOpts
                    )}
                  </dd>
                </Fragment>
              ))}
            </dl>
          </Card>
        ))}
      </div>
    );
  }
}

ChubbPlansTable.propTypes = propTypes;
ChubbPlansTable.defaultProps = defaultProps;

export default ChubbPlansTable;
