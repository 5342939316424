import authReducers from './auth';
import globalReducers from './global';
import miscReducers from './misc';
import carSafeReducers from './carsafe';
import homeSafeReducers from './homesafe';
import { rentersReducers } from 'shared/lib/apps/RentersApp/reducers';
import { bopReducers } from 'shared/lib/apps/BopApp/reducers';
import { collectiblesReducers } from 'shared/lib/apps/CollectiblesApp/reducers';
import { rentalCarReducers } from 'shared/lib/apps/RentalCarApp/reducers';

export default {
  ...authReducers,
  ...bopReducers,
  ...carSafeReducers,
  ...homeSafeReducers,
  ...collectiblesReducers,
  ...globalReducers,
  ...miscReducers,
  ...rentalCarReducers,
  ...rentersReducers,
};
