import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as analytics from 'shared/lib/analytics';
import each from 'lodash/each';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import ConfirmationLayout from 'shared/lib/components/ConfirmationLayout';
import LineItemsList from 'shared/lib/components/LineItemsList';
import SectionWrapper from 'shared/lib/components/SectionWrapper';
import { format as currencyFormat } from 'currency-formatter';

const propTypes = {
  homeSafeCheckout: PropTypes.object,
  homeSafeSelectedPlan: PropTypes.object,
  homeSafeSettings: PropTypes.object,
  disclaimer: PropTypes.string,
  getHomeSafeCheckout: PropTypes.func,
  purchaseHomeSafe: PropTypes.func,
};

const defaultProps = {
  homeSafeCheckout: {},
  homeSafeSelectedPlan: {},
  homeSafeSettings: {},
  disclaimer: null,
  getHomeSafeCheckout: () => {},
  purchaseHomeSafe: () => {},
};

class HomeSafeConfirmationView extends Component {
  componentDidMount() {
    analytics.page('HomeSafe - Confirmation');
    analytics.track('Checkout Step Viewed', {
      category: 'order',
      step: 3,
    });
  }

  get planDetailsRows() {
    const {
      homeSafeDetails,
      homeSafeSelectedPlan,
      homeSafeSettings,
    } = this.props;
    const currencyOpts = {
      code: get(homeSafeSettings, 'currency.code'),
      precision: 0,
    };
    const rows = [
      [
        'Home Burglary',
        currencyFormat(
          get(homeSafeSelectedPlan, 'details.coverage_amounts.home_burglary'),
          currencyOpts
        ),
      ],
      [
        'Home ADD',
        currencyFormat(
          get(homeSafeSelectedPlan, 'details.coverage_amounts.home_add'),
          currencyOpts
        ),
      ],
    ];

    each(homeSafeDetails.addOns, addOnsKey => {
      const addOn = get(
        homeSafeSelectedPlan,
        `details.plan_add_ons.${addOnsKey}`
      );
      rows.push([startCase(addOnsKey), addOn.coverage_amount]);
    });

    return rows;
  }
  get totals() {
    return {
      subtotal: get(this.props.homeSafeQuote, 'billing.sub_total'),
      tax: get(this.props.homeSafeQuote, 'billing.tax_total'),
      grandtotal: get(this.props.homeSafeQuote, 'billing.grand_total'),
    };
  }

  render() {
    const {
      homeSafeDetails,
      homeSafePurchaseInfo,
      homeSafeSelectedPlan,
      homeSafeSettings,
      disclaimer,
    } = this.props;
    const currencyOpts = {
      code: get(homeSafeSettings, 'currency.code'),
      precision: 0,
    };

    return (
      <div className="HomeSafeConfirmationView Container Container--small">
        <ConfirmationLayout
          policyNumber={get(
            homeSafePurchaseInfo,
            'agreements[0].policy_number'
          )}
          totals={this.totals}
          hideBillingSummary
          hideSmsForm
          billingCycle="month"
          disclaimer={disclaimer}
        >
          <SectionWrapper title="Policy holder details">
            <p>
              {[homeSafeDetails.firstName, homeSafeDetails.lastName].join(' ')}
            </p>
            <p>{homeSafeDetails.streetAddress}</p>
            {homeSafeDetails.unit && <p>{homeSafeDetails.unit}</p>}
            <p>
              {[
                homeSafeDetails.city,
                homeSafeDetails.region,
                homeSafeDetails.postal,
              ].join(', ')}
            </p>
            <p>{homeSafeDetails.email}</p>
            <p>{homeSafeDetails.policyholderId}</p>
            <p>{homeSafeDetails.phone}</p>
          </SectionWrapper>

          {homeSafeDetails.hasBeneficiary && (
            <SectionWrapper title="Beneficiary details">
              <p>{homeSafeDetails.beneficiaryRelationship}</p>
              <p>
                {[
                  homeSafeDetails.beneficiaryFirstName,
                  homeSafeDetails.beneficiaryLastName,
                ].join(' ')}
              </p>
              <p>
                {homeSafeDetails.beneficiaryId ||
                  homeSafeDetails.beneficiaryDob}
              </p>
              <p>{homeSafeDetails.beneficiaryEmail}</p>
              <p>{homeSafeDetails.beneficiaryPhone}</p>
            </SectionWrapper>
          )}

          <SectionWrapper
            title={`Plan details: ${homeSafeSelectedPlan.information.title}`}
          >
            <LineItemsList rows={this.planDetailsRows} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList rows={[['Policies issued by', 'Chubb']]} />
            <LineItemsList rows={[['Billing frequency', 'Monthly']]} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList
              rows={[
                [
                  'Subtotal',
                  currencyFormat(this.totals.subtotal, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Tax',
                  currencyFormat(this.totals.tax, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Total',
                  <strong>
                    {currencyFormat(this.totals.grandtotal, {
                      ...currencyOpts,
                      precision: 2,
                    })}
                    /month
                  </strong>,
                ],
              ]}
            />
          </SectionWrapper>
        </ConfirmationLayout>
      </div>
    );
  }
}

HomeSafeConfirmationView.propTypes = propTypes;
HomeSafeConfirmationView.defaultProps = defaultProps;

export default HomeSafeConfirmationView;
