'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RENTERS_BASE_PATH = exports.RENTERS_ROUTES = exports.rentersRoutesCreator = undefined;
var _jsxFileName = 'src/apps/RentersApp/routes.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _SurveyManager = require('../../components/SurveyManager');

var _SurveyManager2 = _interopRequireDefault(_SurveyManager);

var _RentersPropertyInfo = require('./views/RentersPropertyInfo');

var _RentersPropertyInfo2 = _interopRequireDefault(_RentersPropertyInfo);

var _RentersPolicyStartDate = require('./views/RentersPolicyStartDate');

var _RentersPolicyStartDate2 = _interopRequireDefault(_RentersPolicyStartDate);

var _RentersQuoteDetails = require('./views/RentersQuoteDetails');

var _RentersQuoteDetails2 = _interopRequireDefault(_RentersQuoteDetails);

var _RentersPayment = require('./views/RentersPayment');

var _RentersPayment2 = _interopRequireDefault(_RentersPayment);

var _RentersConfirmation = require('./views/RentersConfirmation');

var _RentersConfirmation2 = _interopRequireDefault(_RentersConfirmation);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RENTERS_BASE_PATH = 'renters-insurance';
var RENTERS_ROUTES = {
  START: RENTERS_BASE_PATH + '/start',
  SURVEY: RENTERS_BASE_PATH + '/survey',
  POLICY_DATE: RENTERS_BASE_PATH + '/policy-date',
  QUOTE: RENTERS_BASE_PATH + '/quote',
  PAYMENT: RENTERS_BASE_PATH + '/payment',
  CONFIRMATION: RENTERS_BASE_PATH + '/confirmation'
};

var rentersRoutesCreator = function rentersRoutesCreator(_ref) {
  var container = _ref.container,
      landingComponent = _ref.landingComponent;
  var subdomain = window.location.hostname.split('.')[0];
  var isSureWhitelabel = ['insurance', 'www', 'localhost', 'sure'].includes(subdomain);
  return _react2.default.createElement(_reactRouter.Route, {
    path: RENTERS_BASE_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'start',
    component: isSureWhitelabel ? function () {
      window.location = 'https://insurance.' + process.env.REACT_APP_DOMAIN + '.com/renters-insurance/start';
      return null;
    } : _RentersPropertyInfo2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'survey',
    component: _SurveyManager2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 45
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'policy-date',
    component: _RentersPolicyStartDate2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 46
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'quote',
    component: _RentersQuoteDetails2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 47
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'payment',
    component: _RentersPayment2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 48
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'confirmation',
    component: _RentersConfirmation2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 49
    },
    __self: undefined
  }));
};

exports.rentersRoutesCreator = rentersRoutesCreator;
exports.RENTERS_ROUTES = RENTERS_ROUTES;
exports.RENTERS_BASE_PATH = RENTERS_BASE_PATH;