'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/AddressSection/AddressSection.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./AddressSection.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  address: _propTypes2.default.shape({
    streetAddress: _propTypes2.default.string,
    unit: _propTypes2.default.string,
    city: _propTypes2.default.string,
    region: _propTypes2.default.string,
    postal: _propTypes2.default.string
  }).isRequired,
  fullName: _propTypes2.default.string
};
var defaultProps = {
  fullName: null
};

var AddressSection = function (_PureComponent) {
  _inherits(AddressSection, _PureComponent);

  function AddressSection() {
    _classCallCheck(this, AddressSection);

    return _possibleConstructorReturn(this, (AddressSection.__proto__ || Object.getPrototypeOf(AddressSection)).apply(this, arguments));
  }

  _createClass(AddressSection, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          address = _props.address,
          fullName = _props.fullName;
      return _react2.default.createElement('address', {
        className: 'AddressSection',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }, fullName && _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 30
        },
        __self: this
      }, fullName, _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 32
        },
        __self: this
      })), address.streetAddress, _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: this
      }), address.unit && _react2.default.createElement('span', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 38
        },
        __self: this
      }, address.unit), address.unit && _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 40
        },
        __self: this
      }), address.city, ', ', address.region, ' ', address.postal);
    }
  }]);

  return AddressSection;
}(_react.PureComponent);

AddressSection.propTypes = propTypes;
AddressSection.defaultProps = defaultProps;
exports.default = AddressSection;