'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesPolicyStartDate/CollectiblesPolicyStartDate.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _CreditCardLogos = require('shared/lib/components/CreditCardLogos');

var _CreditCardLogos2 = _interopRequireDefault(_CreditCardLogos);

var _PagerButtons = require('shared/lib/components/PagerButtons');

var _PagerButtons2 = _interopRequireDefault(_PagerButtons);

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./CollectiblesPolicyStartDate.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  categorySlug: _propTypes2.default.string,
  categoryCode: _propTypes2.default.string,
  isCollectiblesInProgress: _propTypes2.default.bool,
  plan: _propTypes2.default.object,
  policyStartDate: _propTypes2.default.object,
  updateCollectiblesStartDate: _propTypes2.default.func,
  navTo: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  isCollectiblesInProgress: false
};

var CollectiblesPolicyStartDate = function (_PureComponent) {
  _inherits(CollectiblesPolicyStartDate, _PureComponent);

  function CollectiblesPolicyStartDate(props) {
    _classCallCheck(this, CollectiblesPolicyStartDate);

    var _this = _possibleConstructorReturn(this, (CollectiblesPolicyStartDate.__proto__ || Object.getPrototypeOf(CollectiblesPolicyStartDate)).call(this, props));

    _this.handleDatePickerFocus = function (_ref) {
      var focused = _ref.focused;

      _this.setState({
        isDatePickerOpen: focused
      });
    };

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      if (_this.props.isModalEditComplete) {
        _this.props.onEditCompletion();
      } else {
        var adjustQuote = _this.props.isEditingCollection ? _this.props.updateCollectionsInQuote : _this.props.addCollectionToQuote;
        return adjustQuote().then(_this.props.getCollectiblesCheckout).then(function () {
          return _this.props.navTo('quote');
        }).catch(_this.props.onError);
      }
    };

    _this.handleUpdateStartDate = function (momentObj) {
      if (momentObj && !momentObj.isSame(_this.props.policyStartDate)) {
        var label = momentObj.isAfter(_this.props.policyStartDate) ? 'later' : 'earlier';
        analytics.track('Collectibles - Edit Start Date', {
          category: 'quote',
          label: label,
          value: Math.abs(momentObj.diff(_this.props.policyStartDate, 'days')),
          categoryCode: _this.props.categoryCode
        });

        _this.props.updateCollectiblesStartDate(momentObj);
      }
    };

    _this.isDateOutsideRange = function (day) {
      return (0, _momentTimezone2.default)(day).startOf('day').isBefore(_this.minStartDate) || (0, _momentTimezone2.default)(day).startOf('day').isAfter(_this.maxStartDate);
    };

    _this.minStartDate = (0, _momentTimezone2.default)().tz('America/New_York').subtract(1, 'day');
    _this.maxStartDate = (0, _momentTimezone2.default)().tz('America/New_York').add(30, 'days');
    _this.isMobile = window.innerWidth < 768;
    _this.state = {
      isDatePickerOpen: false
    };
    return _this;
  }

  _createClass(CollectiblesPolicyStartDate, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Policy Start Date', {
        categoryCode: this.props.categoryCode
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'CollectiblesPolicyStartDate',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 100
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesPolicyStartDate-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 101
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'CollectiblesPolicyStartDate-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 103
        },
        __self: this
      }, 'When should this policy take effect?'), _react2.default.createElement('form', {
        className: 'CollectiblesPolicyStartDate-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 106
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'CollectiblesPolicyStartDate-input-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 111
        },
        __self: this
      }, 'Start Date'), _react2.default.createElement(_reactDates.SingleDatePicker, {
        date: this.props.policyStartDate,
        focused: this.state.isDatePickerOpen,
        required: true,
        block: true,
        displayFormat: 'MM/DD/YYYY',
        numberOfMonths: 1,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        isOutsideRange: this.isDateOutsideRange,
        onDateChange: this.handleUpdateStartDate,
        onFocusChange: this.handleDatePickerFocus,
        hideKeyboardShortcutsPanel: true,
        id: 'policy_effective_date',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 114
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      })), _react2.default.createElement(_CreditCardLogos2.default, {
        className: 'CollectiblesPolicyStartDate-creditCardLogos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 131
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        className: 'CollectiblesPolicyStartDate-planDisclaimer',
        text: this.props.disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!this.props.partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 132
        },
        __self: this
      }))), _react2.default.createElement(_ModalSpinner2.default, {
        message: 'Calculating your quote. This may take up to 15 seconds.',
        isActive: this.props.isCollectiblesInProgress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 140
        },
        __self: this
      }), _react2.default.createElement(_PagerButtons2.default, {
        nextBtnText: this.props.nextBtnText,
        onNext: this.handleNext,
        isBackHidden: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 144
        },
        __self: this
      }));
    }
  }]);

  return CollectiblesPolicyStartDate;
}(_react.PureComponent);

CollectiblesPolicyStartDate.propTypes = propTypes;
CollectiblesPolicyStartDate.defaultProps = defaultProps;
exports.default = CollectiblesPolicyStartDate;