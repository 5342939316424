import ACTION_TYPES from '../../constants/actionTypes';

export default {
  authToken(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_PARTNER_INFO:
        return action.authToken;
      default:
        return state;
    }
  },

  settings(state = {}, action) {
    return state;
  },

  partnerInfo(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.UPDATE_PARTNER_INFO:
        return action.partnerInfo;
      default:
        return state;
    }
  },
};
