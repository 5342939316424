import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import heroImage from './images/landing-carsafe.png';
import './LandingContactChubb.css';

export default class LandingContactChubb extends PureComponent {
  componentDidMount() {
    analytics.page('Contact Chubb');
  }

  render() {
    return (
      <div className="LandingContactChubb ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#7acb00" bgImage={heroImage}>
          <h1 className="LandingContactChubb-heroTitle">
            Protect yourself against the unexpected.
          </h1>
        </HeroChubb>
        <div className="LandingContactChubb-content">
          <div className="Container">
            <h2 className="LandingContactChubb-content-title">Contact Us</h2>
            <div className="LandingContactChubb-row">
              <div className="LandingContactChubb-contactCard">
                <div className="LandingContactChubb-contactCardWrapper">
                  <h3 className="LandingContactChubb-contactCard-title">
                    Contact details
                  </h3>
                  <p className="LandingContactChubb-contactCard-info">
                    If you wish to discuss anything concerning your policy you
                    can e-mail us on{' '}
                    <a
                      href="mailto:clientqueries@chubb.com"
                      className="LandingContactChubb-link"
                    >
                      clientqueries@chubb.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="LandingContactChubb-contactDetailsCard">
                <h3 className="LandingContactChubb-contactDetailsCard-title">
                  Vehicle Contents & Residential Insurance
                </h3>
                <h4 className="LandingContactChubb-contactDetailsCard-heading">
                  Chubb Insurance South Africa Limited
                </h4>
                <br />
                <p className="LandingContactChubb-contactDetailsCard-heading">
                  <strong>Physical Address</strong>
                </p>
                <address className="LandingContactChubb-contactDetailsCard-address">
                  Ground Floor, The Bridle, Hunts End Office Park
                  <br />
                  38 Wierda Road West, Wierda Valley
                  <br />
                  Sandton
                </address>
                <br />
                <p className="LandingContactChubb-contactDetailsCard-heading">
                  <strong>Postal Address</strong>
                </p>
                <address className="LandingContactChubb-contactDetailsCard-address">
                  PO Box 1192
                  <br />
                  Saxonwold
                  <br />
                  2132
                </address>
                <p className="LandingContactChubb-contactDetailsCard-address">
                  <strong>FSP No.</strong>
                  <br />
                  27176
                </p>
                <p className="LandingContactChubb-contactDetailsCard-numbers">
                  O:{' '}
                  <a
                    href="tel:+270117225700"
                    className="LandingContactChubb-link"
                  >
                    +27 (0) 11 722 5700
                  </a>
                  <br />
                  F:{' '}
                  <a
                    href="tel:+27(0)117830812"
                    className="LandingContactChubb-link"
                  >
                    +27 (0) 11 783 0812
                  </a>
                </p>
              </div>
            </div>
            <div className="LandingContactChubb-row">
              <div className="LandingContactChubb-complaintsCard">
                <h3 className="LandingContactChubb-complaintsCard-title">
                  Customer complaints
                </h3>
                <p className="LandingContactChubb-complaintsCard-info">
                  We aim to provide a high quality service at all times. If for
                  any reason you are unhappy with our service, we would like you
                  to tell us, so that we can try to put things right.
                </p>
                <p className="LandingContactChubb-complaintsCard-disclaimer">
                  Please e-mail us at clientqueries@chubb.com or on the contact
                  details contained in your Policy Document. Please ensure you
                  have your Policy number handy when contacting us.
                </p>
                <p className="LandingContactChubb-complaintsCard-disclaimer">
                  In the event you have cause for complaint and we are unable to
                  resolve it with you, you may be able to approach the FAIS
                  Ombudsman.
                </p>
              </div>
              <div className="LandingContactChubb-addlContactsCard">
                <h4 className="LandingContactChubb-addlContactsCard-title">
                  FAIS Ombudsman
                </h4>
                <p className="LandingContactChubb-addlContactsCard-info">
                  P O Box 74571 Lynwood Ridge 0040
                  <br />
                  <a href="tel:0124709080" className="LandingContactChubb-link">
                    (012) 470 9080
                  </a>{' '}
                  /{' '}
                  <a href="tel:0124709097" className="LandingContactChubb-link">
                    (012) 470 9097
                  </a>{' '}
                  /{' '}
                  <a href="tel:0123483447" className="LandingContactChubb-link">
                    (012) 348 3447
                  </a>
                  <br />
                  <a
                    href="mailto:info@faisombud.co.za"
                    className="LandingContactChubb-link"
                  >
                    info@faisombud.co.za
                  </a>
                  <br />
                  <a
                    href="https://www.faisombud.co.za"
                    className="LandingContactChubb-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.faisombud.co.za
                  </a>
                </p>

                <h4 className="LandingContactChubb-addlContactsCard-title">
                  Registrar of Short-Term Insurance
                </h4>
                <p className="LandingContactChubb-addlContactsCard-info">
                  Financial Sector Conduct Authority (FSCA)
                  <br />
                  PO Box 35655
                  <br />
                  MENLO PARK, 0102
                  <br />
                  <a href="tel:0124288000" className="LandingContactChubb-link">
                    (012) 428-8000
                  </a>{' '}
                  /{' '}
                  <a href="tel:0123470221" className="LandingContactChubb-link">
                    (012) 347 0221
                  </a>
                  <br />
                  <a
                    href="https://www.fsb.co.za"
                    className="LandingContactChubb-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.fsb.co.za
                  </a>
                </p>

                <h4 className="LandingContactChubb-addlContactsCard-title">
                  Short Term Insurance Ombudsman
                </h4>
                <p className="LandingContactChubb-addlContactsCard-info">
                  P O Box 32334 Braamfontein 2017
                  <br />
                  <a href="tel:0860726890" className="LandingContactChubb-link">
                    0860 726 890
                  </a>{' '}
                  /{' '}
                  <a href="tel:0117268900" className="LandingContactChubb-link">
                    (011) 726 8900
                  </a>
                  /{' '}
                  <a href="tel:0117265501" className="LandingContactChubb-link">
                    (011) 726 5501
                  </a>
                  <br />
                  <a
                    href="mailto:info@osti.co.za"
                    className="LandingContactChubb-link"
                  >
                    info@osti.co.za
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterChubb />
      </div>
    );
  }
}
