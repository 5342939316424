'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RENTAL_CAR_BASE_PATH = exports.RENTAL_CAR_ROUTES = exports.rentalCarRoutesCreator = undefined;
var _jsxFileName = 'src/apps/RentalCarApp/routes.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _RentalCarRentalInfo = require('./views/RentalCarRentalInfo');

var _RentalCarRentalInfo2 = _interopRequireDefault(_RentalCarRentalInfo);

var _RentalCarSignedAgreement = require('./views/RentalCarSignedAgreement');

var _RentalCarSignedAgreement2 = _interopRequireDefault(_RentalCarSignedAgreement);

var _RentalCarUseType = require('./views/RentalCarUseType');

var _RentalCarUseType2 = _interopRequireDefault(_RentalCarUseType);

var _RentalCarDriver = require('./views/RentalCarDriver');

var _RentalCarDriver2 = _interopRequireDefault(_RentalCarDriver);

var _RentalCarAdditionalDrivers = require('./views/RentalCarAdditionalDrivers');

var _RentalCarAdditionalDrivers2 = _interopRequireDefault(_RentalCarAdditionalDrivers);

var _RentalCarAddDrivers = require('./views/RentalCarAddDrivers');

var _RentalCarAddDrivers2 = _interopRequireDefault(_RentalCarAddDrivers);

var _RentalCarPlans = require('./views/RentalCarPlans');

var _RentalCarPlans2 = _interopRequireDefault(_RentalCarPlans);

var _RentalCarPayment = require('./views/RentalCarPayment');

var _RentalCarPayment2 = _interopRequireDefault(_RentalCarPayment);

var _RentalCarConfirmation = require('./views/RentalCarConfirmation');

var _RentalCarConfirmation2 = _interopRequireDefault(_RentalCarConfirmation);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RENTAL_CAR_BASE_PATH = 'rental-car-insurance';
var RENTAL_CAR_ROUTES = {
  RENTAL_INFO: RENTAL_CAR_BASE_PATH + '/rental-info',
  AGREEMENT_CONFIRMATION: RENTAL_CAR_BASE_PATH + '/agreement-confirmation',
  USE_TYPE: RENTAL_CAR_BASE_PATH + '/use-type',
  DRIVER: RENTAL_CAR_BASE_PATH + '/driver',
  ADDITIONAL_DRIVERS: RENTAL_CAR_BASE_PATH + '/additional-drivers',
  ADD_DRIVERS: RENTAL_CAR_BASE_PATH + '/add-drivers',
  PLANS: RENTAL_CAR_BASE_PATH + '/plans',
  PAYMENT: RENTAL_CAR_BASE_PATH + '/payment',
  CONFIRMATION: RENTAL_CAR_BASE_PATH + '/confirmation'
};

var rentalCarRoutesCreator = function rentalCarRoutesCreator(_ref) {
  var container = _ref.container,
      landingComponent = _ref.landingComponent,
      redirectIfMissing = _ref.redirectIfMissing;
  return _react2.default.createElement(_reactRouter.Route, {
    path: RENTAL_CAR_BASE_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 32
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'rental-info',
    component: _RentalCarRentalInfo2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 33
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'agreement-confirmation',
    component: _RentalCarSignedAgreement2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarPickUpDate',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 34
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'use-type',
    component: _RentalCarUseType2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarPickUpDate',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 42
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'driver',
    component: _RentalCarDriver2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarAgency',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 50
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'additional-drivers',
    component: _RentalCarAdditionalDrivers2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarDriver',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 58
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'add-drivers',
    component: _RentalCarAddDrivers2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarDriver',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 66
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'plans',
    component: _RentalCarPlans2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarPlans',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 74
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'payment',
    component: _RentalCarPayment2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarCheckoutInfo',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 82
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'confirmation',
    component: _RentalCarConfirmation2.default,
    onEnter: redirectIfMissing({
      key: 'rentalCarPurchase',
      pathname: '/rental-car-insurance/rental-info'
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 90
    },
    __self: undefined
  }));
};

exports.rentalCarRoutesCreator = rentalCarRoutesCreator;
exports.RENTAL_CAR_ROUTES = RENTAL_CAR_ROUTES;
exports.RENTAL_CAR_BASE_PATH = RENTAL_CAR_BASE_PATH;