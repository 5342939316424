'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesPayment/CollectiblesPayment.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _startCase = require('lodash/startCase');

var _startCase2 = _interopRequireDefault(_startCase);

var _startsWith = require('lodash/startsWith');

var _startsWith2 = _interopRequireDefault(_startsWith);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _PaymentLayoutNew = require('shared/lib/components/PaymentLayoutNew');

var _PaymentLayoutNew2 = _interopRequireDefault(_PaymentLayoutNew);

var _PaymentSectionNew = require('shared/lib/components/PaymentSectionNew');

var _PaymentSectionNew2 = _interopRequireDefault(_PaymentSectionNew);

require('./CollectiblesPayment.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  categoryCode: _propTypes2.default.string,
  currencyCode: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  isCollectiblesInProgress: _propTypes2.default.bool,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  purchase: _propTypes2.default.func,
  responses: _propTypes2.default.object,
  selectedAmounts: _propTypes2.default.object,
  totals: _propTypes2.default.object
};
var defaultProps = {
  isCollectiblesInProgress: false
};

var CollectiblesPayment = function (_Component) {
  _inherits(CollectiblesPayment, _Component);

  function CollectiblesPayment() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesPayment);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesPayment.__proto__ || Object.getPrototypeOf(CollectiblesPayment)).call.apply(_ref, [this].concat(args))), _this), _this.handleSubmit = function (cardElement, cardName) {
      analytics.track('Checkout Step Completed', {
        category: 'order',
        step: 2
      });
      return _this.props.purchase(cardElement, cardName).then(function () {
        _this.props.navTo('confirmation');
      }).catch(_this.props.onError);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesPayment, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Payment', {
        categoryCode: this.props.categoryCode
      });
      analytics.track('Collectibles - Payment Page Viewed', {
        categoryCode: this.props.categoryCode,
        noninteraction: 1
      });
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 2
      });

      if ((0, _startsWith2.default)((0, _get2.default)(this.props.notificationMessage, 'title'), 'Exclusion Notice')) {
        this.props.hideCollectiblesNotificationBar();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          categoryCode = _props.categoryCode,
          currencyCode = _props.currencyCode,
          disclaimer = _props.disclaimer,
          isCollectiblesInProgress = _props.isCollectiblesInProgress,
          partnerInfo = _props.partnerInfo,
          plan = _props.plan,
          policyStartDate = _props.policyStartDate,
          totals = _props.totals,
          userFullName = _props.userFullName;
      return _react2.default.createElement('div', {
        className: 'CollectiblesPayment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesPayment-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 88
        },
        __self: this
      }, _react2.default.createElement(_PaymentLayoutNew2.default, {
        title: 'Checkout',
        categoryCode: categoryCode,
        currencyCode: currencyCode,
        policyStartDate: policyStartDate,
        billingCycle: 'year',
        totals: totals,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: this
      }))), _react2.default.createElement(_PaymentSectionNew2.default, {
        acknowledgmentHtml: (0, _get2.default)(plan, 'information.acknowledgment_html'),
        currencyCode: currencyCode,
        disclaimer: disclaimer,
        disabled: isCollectiblesInProgress,
        onSubmit: this.handleSubmit,
        userFullName: userFullName,
        totals: totals,
        partnerInfo: partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 99
        },
        __self: this
      }));
    }
  }, {
    key: 'heading',
    get: function get() {
      if (this.props.categoryCode === 'other') {
        return 'Your Collectibles Insurance Quote';
      } else {
        return 'Your ' + (0, _startCase2.default)(this.props.categoryCode) + ' Insurance Quote';
      }
    }
  }]);

  return CollectiblesPayment;
}(_react.Component);

CollectiblesPayment.propTypes = propTypes;
CollectiblesPayment.defaultProps = defaultProps;
exports.default = CollectiblesPayment;