import ACTION_TYPES from '../../constants/actionTypes';
import VERTICALS from 'shared/lib/constants/verticals';
import { createStripeTokenFromElement, emitMessage } from 'shared/lib/utils';
import moment from 'moment-timezone';
import each from 'lodash/each';
import find from 'lodash/find';
import merge from 'lodash/merge';
import set from 'lodash/set';
import startsWith from 'lodash/startsWith';
import map from 'lodash/map';
import api from '../../api';
import * as analytics from 'shared/lib/analytics';

/*
 * Create HomeSafe quote
 * ========================================
 */

function createHomeSafeQuoteRequest() {
  return {
    type: ACTION_TYPES.CREATE_HOMESAFE_QUOTE,
  };
}

function createHomeSafeQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.CREATE_HOMESAFE_QUOTE_SUCCESS,
    payload: {
      quote,
    },
  };
}

function createHomeSafeQuoteFailure(error) {
  return {
    type: ACTION_TYPES.CREATE_HOMESAFE_QUOTE_FAILURE,
    error,
  };
}

export function createHomeSafeQuote(countryCode) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const applicationUpdates = {
        holders: [],
        rating_address: {
          state_or_region: countryCode,
          country_code: countryCode,
        },
        plan_applications: [],
      };

      dispatch(createHomeSafeQuoteRequest());
      api
        .createQuote({
          applicationUpdates,
          verticalCode: VERTICALS.homesafe.code,
          apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
        })
        .then(quote => {
          dispatch(createHomeSafeQuoteSuccess(quote));
          resolve(quote);
        })
        .catch(error => {
          dispatch(createHomeSafeQuoteFailure(error));
          reject(error);
        });
    });
  };
}

/*
 * Update HomeSafe quote
 * ========================================
 */

function updateHomeSafeQuoteRequest() {
  return {
    type: ACTION_TYPES.UPDATE_HOMESAFE_QUOTE,
  };
}

function updateHomeSafeQuoteSuccess(quote) {
  return {
    type: ACTION_TYPES.UPDATE_HOMESAFE_QUOTE_SUCCESS,
    payload: {
      quote,
    },
  };
}

function updateHomeSafeQuoteFailure(error) {
  return {
    type: ACTION_TYPES.UPDATE_HOMESAFE_QUOTE_FAILURE,
    error,
  };
}

export function updateHomeSafeQuote() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        authToken,
        homeSafeDetails,
        homeSafePlans,
        homeSafeSelectedPlanId,
        homeSafeQuote,
      } = getState();
      const homeSafeSelectedPlan = find(homeSafePlans, {
        plan_id: homeSafeSelectedPlanId,
      });
      const planApplication = {
        plan_add_ons: map(
          homeSafeDetails.addOns,
          key => homeSafeSelectedPlan.details.plan_add_ons[key]
        ),
        plan_id: homeSafeSelectedPlanId,
        policy_effective_date: moment().toISOString(),
      };
      each(homeSafeDetails, (val, key) => {
        if (
          startsWith(key, 'plan_rating_questions') ||
          startsWith(key, 'plan_screening_questions')
        ) {
          set(planApplication, key, val);
        }
      });
      const applicationUpdates = {
        holders: [
          {
            first_name: homeSafeDetails.firstName,
            last_name: homeSafeDetails.lastName,
            email: homeSafeDetails.email,
            phone_number: homeSafeDetails.phone,
          },
        ],
        rating_address: {
          line1: homeSafeDetails.streetAddress,
          line2: homeSafeDetails.unit,
          city: homeSafeDetails.city,
          state_or_region: homeSafeDetails.region,
          postal: homeSafeDetails.postal,
          country_code: homeSafeDetails.country,
        },
        plan_applications: [planApplication],
      };
      if (homeSafeDetails.hasBeneficiary) {
        applicationUpdates.plan_applications[0].beneficiary = {
          first_name: homeSafeDetails.beneficiaryFirstName,
          last_name: homeSafeDetails.beneficiaryLastName,
          email: homeSafeDetails.beneficiaryEmail,
          relationship_description: homeSafeDetails.beneficiaryRelationship,
        };

        if (homeSafeDetails.beneficiaryInformationType === 'id') {
          applicationUpdates.plan_applications[0].beneficiary.nationality_number =
            homeSafeDetails.beneficiaryId;
        }
        if (homeSafeDetails.beneficiaryInformationType === 'dob') {
          applicationUpdates.plan_applications[0].beneficiary.date_of_birth =
            homeSafeDetails.beneficiaryDob;
        }
      }

      dispatch(updateHomeSafeQuoteRequest());
      api
        .updateQuote({
          authToken,
          quoteId: homeSafeQuote.quote_id,
          application: homeSafeQuote.application,
          applicationUpdates,
          verticalCode: VERTICALS.homesafe.code,
          apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
        })
        .then(quote => {
          dispatch(updateHomeSafeQuoteSuccess(quote));
          resolve(quote);
        })
        .catch(error => {
          dispatch(updateHomeSafeQuoteFailure(error));
          reject(error);
        });
    });
  };
}

/**
 * HomeSafe plans
 */

function getHomeSafePlansRequest(quoteId) {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_PLANS,
    payload: { quoteId },
  };
}

function getHomeSafePlansSuccess(plans) {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_PLANS_SUCCESS,
    payload: plans,
  };
}

function getHomeSafePlansFailure(error) {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_PLANS_FAILURE,
    error,
  };
}

export function getHomeSafePlans() {
  return (dispatch, getState) => {
    const { authToken, homeSafeQuote } = getState();
    const opts = {
      quoteId: homeSafeQuote.quote_id,
      authToken,
      verticalCode: VERTICALS.homesafe.code,
      apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
    };

    dispatch(getHomeSafePlansRequest(opts.quoteId));
    return new Promise((resolve, reject) => {
      api.getQuotePlans(opts).then(
        plans => {
          dispatch(getHomeSafePlansSuccess(plans));
          resolve(plans);
        },
        error => {
          dispatch(getHomeSafePlansFailure(error));
          reject(error);
        }
      );
    });
  };
}

/**
 * HomeSafe Details
 */

export function updateHomeSafeDetails(details) {
  return {
    type: ACTION_TYPES.UPDATE_HOMESAFE_DETAILS,
    payload: details,
  };
}

/**
 * HomeSafe Plan
 */

export function selectHomeSafePlan(planId) {
  return {
    type: ACTION_TYPES.SELECT_HOMESAFE_PLAN,
    payload: {
      planId,
    },
  };
}

/**
 * HomeSafe checkout
 */

function getHomeSafeCheckoutRequest() {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_CHECKOUT,
  };
}

function getHomeSafeCheckoutSuccess(quote) {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_CHECKOUT_SUCCESS,
    payload: {
      quote,
    },
  };
}

function getHomeSafeCheckoutFailure(error) {
  return {
    type: ACTION_TYPES.GET_HOMESAFE_CHECKOUT_FAILURE,
    error,
  };
}

export function getHomeSafeCheckout() {
  return (dispatch, getState) => {
    const { authToken, homeSafeQuote } = getState();
    const opts = {
      authToken,
      quoteId: homeSafeQuote.quote_id,
      verticalCode: VERTICALS.homesafe.code,
      apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
    };

    dispatch(getHomeSafeCheckoutRequest());
    return new Promise((resolve, reject) => {
      api
        .getQuoteCheckout(opts)
        .then(quote => {
          dispatch(getHomeSafeCheckoutSuccess(quote));
          resolve(quote);
        })
        .catch(error => {
          dispatch(getHomeSafeCheckoutFailure(error));
          reject(error);
        });
    });
  };
}

/**
 * HomeSafe purchase
 */

/**
 * Payment method
 */

function addHomeSafePaymentMethodRequest() {
  return {
    type: ACTION_TYPES.ADD_HOMESAFE_PAYMENT_METHOD,
  };
}

function addHomeSafePaymentMethodSuccess(quote) {
  return {
    type: ACTION_TYPES.ADD_HOMESAFE_PAYMENT_METHOD_SUCCESS,
    payload: {
      quote,
    },
  };
}

function addHomeSafePaymentMethodFailure(error) {
  return {
    type: ACTION_TYPES.ADD_HOMESAFE_PAYMENT_METHOD_FAILURE,
    error,
  };
}

function addHomeSafePaymentMethod(cardElement, dispatch, getState) {
  const { authToken, homeSafeDetails, homeSafeQuote } = getState();

  dispatch(addHomeSafePaymentMethodRequest());
  return new Promise((resolve, reject) => {
    createStripeTokenFromElement(cardElement)
      .then(stripeToken => {
        analytics.track(`HomeSafe - Stripe Token Created`, {
          category: 'order',
          nonInteraction: 1,
        });

        return api.createPaymentMethod({
          firstName: homeSafeDetails.firstName,
          lastName: homeSafeDetails.lastName,
          email: homeSafeDetails.email,
          getId: true,
          apiEndpoint: `${
            process.env.REACT_APP_SURE_API_BASE_URL
          }/api/partner/v1/methods`,
          authToken,
          stripeToken,
        });
      })
      .then(paymentMethodId => {
        analytics.track(`HomeSafe - Payment Method Created`, {
          category: 'order',
          nonInteraction: 1,
        });

        return api.updateQuote({
          authToken,
          quoteId: homeSafeQuote.quote_id,
          application: homeSafeQuote.application,
          applicationUpdates: {
            payment_method_id: paymentMethodId,
          },
          verticalCode: VERTICALS.homesafe.code,
          apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
        });
      })
      .then(quote => {
        dispatch(addHomeSafePaymentMethodSuccess(quote));
        resolve(quote);
      })
      .catch(error => {
        dispatch(addHomeSafePaymentMethodFailure(error));
        reject(error);
      });
  });
}

/**
 * Purchase
 */

function purchaseHomeSafeRequest() {
  return {
    type: ACTION_TYPES.PURCHASE_HOMESAFE,
  };
}

function purchaseHomeSafeSuccess(data) {
  emitMessage({
    actionType: ACTION_TYPES.PURCHASE_HOMESAFE_SUCCESS,
  });
  return {
    type: ACTION_TYPES.PURCHASE_HOMESAFE_SUCCESS,
    payload: data,
  };
}

function purchaseHomeSafeFailure(error) {
  emitMessage({
    actionType: ACTION_TYPES.PURCHASE_HOMESAFE_FAILURE,
  });
  return {
    type: ACTION_TYPES.PURCHASE_HOMESAFE_FAILURE,
    error,
  };
}

export function purchaseHomeSafe(cardElement) {
  return (dispatch, getState) => {
    const { authToken, homeSafeDetails, homeSafeQuote, metadata } = getState();
    const revenue = homeSafeQuote.billing.grand_total;

    dispatch(purchaseHomeSafeRequest());
    analytics.track('HomeSafe - Purchase Initiated', {
      category: 'order',
      label: homeSafeQuote.quote_id,
      value: revenue,
    });

    return new Promise((resolve, reject) => {
      addHomeSafePaymentMethod(cardElement, dispatch, getState)
        .then(() => {
          const opts = {
            prefix: 'HomeSafe',
            quoteId: homeSafeQuote.quote_id,
            verticalCode: VERTICALS.homesafe.code,
            apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
            authToken,
            revenue,
            metadata,
          };
          return api.purchaseQuote(opts).then(purchaseData => {
            const hydratedPurchaseData = merge({}, purchaseData, {
              details: {
                homeSafeDetails: homeSafeDetails,
                totals: homeSafeQuote.billing,
              },
            });
            dispatch(
              purchaseHomeSafeSuccess({
                ...hydratedPurchaseData,
                quote: homeSafeQuote,
              })
            );
            resolve(hydratedPurchaseData);
          });
        })
        .catch(error => {
          dispatch(purchaseHomeSafeFailure(error));
          reject(error);
        });
    });
  };
}
