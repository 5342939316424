'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PaymentLayout/PaymentLayout.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _CheckboxButton = require('shared/lib/components/CheckboxButton');

var _CheckboxButton2 = _interopRequireDefault(_CheckboxButton);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _flatten = require('lodash/flatten');

var _flatten2 = _interopRequireDefault(_flatten);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _IconCircle = require('../IconCircle');

var _IconCircle2 = _interopRequireDefault(_IconCircle);

var _PaymentSection = require('../PaymentSection');

var _PaymentSection2 = _interopRequireDefault(_PaymentSection);

var _BillingSummarySection = require('../BillingSummarySection');

var _BillingSummarySection2 = _interopRequireDefault(_BillingSummarySection);

var _LineItemsList = require('../LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapper = require('../SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _PlanDisclaimer = require('../PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _PricingLayout = require('../PricingLayout');

var _PricingLayout2 = _interopRequireDefault(_PricingLayout);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

require('./PaymentLayout.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  acknowledgmentHtml: _propTypes2.default.string,
  action: _propTypes2.default.oneOf(['charge', 'refund', 'no_change']),
  extraCCFields: _propTypes2.default.bool,
  currencyCode: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  hideBillingSummary: _propTypes2.default.bool,
  icon: _propTypes2.default.string,
  isPurchaseInProgress: _propTypes2.default.bool,
  notice: _propTypes2.default.string,
  onSubmit: _propTypes2.default.func,
  paymentCadences: _propTypes2.default.object,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  billingCycle: _propTypes2.default.string,
  policyDocText: _propTypes2.default.string,
  policyDocUrl: _propTypes2.default.string,
  pricePerCycle: _propTypes2.default.number,
  quote: _propTypes2.default.object,
  selectedPaymentCadence: _propTypes2.default.string,
  title: _propTypes2.default.string,
  totals: _propTypes2.default.object
};
var defaultProps = {
  acknowledgmentHtml: null,
  action: 'charge',
  extraCCFields: false,
  currencyCode: 'USD',
  disclaimer: null,
  hideBillingSummary: false,
  isPurchaseInProgress: false,
  notice: null,
  paymentCadences: {},
  policyStartDate: null,
  billingCycle: null,
  policyDocText: 'View Sample Policy',
  policyDocUrl: null,
  pricePerCycle: null,
  quote: {},
  selectedPaymentCadence: null
};

var PaymentLayout = function (_PureComponent) {
  _inherits(PaymentLayout, _PureComponent);

  function PaymentLayout(props) {
    _classCallCheck(this, PaymentLayout);

    var _this = _possibleConstructorReturn(this, (PaymentLayout.__proto__ || Object.getPrototypeOf(PaymentLayout)).call(this, props));

    _this.handleCreditCardFormChange = function (e) {
      _this.setState({
        isCreditCardComplete: e.isComplete
      });
    };

    _this.handleAcknowledgmentChange = function (e) {
      if (e.target.checked && _this.props.isAcknowledgmentRequired) {
        _this.props.onShowAcknowledgment();
      }

      _this.setState({
        isAcknowledgementChecked: e.target.checked
      });
    };

    _this.handleAcknowledgmentUncheck = function () {
      _this.acknowledgmentCheckbox.setUnchecked();

      _this.setState({
        isAcknowledgementChecked: false
      });
    };

    _this.handleSubmit = function (e) {
      e.preventDefault();

      switch (_this.props.action) {
        case 'no_change':
        case 'refund':
          _this.props.onSubmit();

          break;

        default:
          if (!_this.isSubmitDisabled) {
            _this.props.onSubmit(_this.creditCardField.elements);
          }

          break;
      }
    };

    _this.state = {
      isAcknowledgementChecked: false,
      isCreditCardComplete: false
    };
    return _this;
  }

  _createClass(PaymentLayout, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          currencyCode = _props.currencyCode,
          disclaimer = _props.disclaimer,
          hideBillingSummary = _props.hideBillingSummary,
          icon = _props.icon,
          notice = _props.notice,
          onUpdatePaymentCadence = _props.onUpdatePaymentCadence,
          policyStartDate = _props.policyStartDate,
          billingCycle = _props.billingCycle,
          paymentCadences = _props.paymentCadences,
          policyDocText = _props.policyDocText,
          policyDocUrl = _props.policyDocUrl,
          pricePerCycle = _props.pricePerCycle,
          quote = _props.quote,
          selectedPaymentCadence = _props.selectedPaymentCadence,
          title = _props.title,
          totals = _props.totals;
      return _react2.default.createElement('div', {
        className: 'PaymentLayout',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 243
        },
        __self: this
      }, notice && _react2.default.createElement('p', {
        className: 'PaymentLayout-notice',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 244
        },
        __self: this
      }, notice), icon && _react2.default.createElement(_IconCircle2.default, {
        className: 'PaymentLayout-icon',
        icon: icon,
        size: 80,
        color: 'medium',
        background: 'gray',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 247
        },
        __self: this
      }), policyDocUrl && _react2.default.createElement('a', {
        href: policyDocUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'PaymentLayout-samplePolicyLink',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 256
        },
        __self: this
      }, policyDocText), _react2.default.createElement(_PricingLayout2.default, {
        currencyCode: currencyCode,
        paymentCadences: paymentCadences,
        billingCycle: billingCycle,
        onUpdatePaymentCadence: onUpdatePaymentCadence,
        pricePerCycle: pricePerCycle,
        quote: quote,
        selectedPaymentCadence: selectedPaymentCadence,
        title: title,
        totals: totals,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 266
        },
        __self: this
      }), _react2.default.createElement('hr', {
        className: 'PaymentLayout-headingDivider',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 278
        },
        __self: this
      }), _react2.default.createElement('form', {
        onSubmit: this.handleSubmit,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 280
        },
        __self: this
      }, children, !hideBillingSummary && _react2.default.createElement(_BillingSummarySection2.default, {
        totals: totals,
        currencyCode: currencyCode,
        pricePerCycle: pricePerCycle,
        policyStartDate: policyStartDate,
        billingCycle: billingCycle || null,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 284
        },
        __self: this
      }), this.paymentSection, _react2.default.createElement(_PlanDisclaimer2.default, {
        text: disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 294
        },
        __self: this
      })));
    }
  }, {
    key: 'isSubmitDisabled',
    get: function get() {
      var _props2 = this.props,
          acknowledgmentHtml = _props2.acknowledgmentHtml,
          action = _props2.action,
          isPurchaseInProgress = _props2.isPurchaseInProgress;

      if (isPurchaseInProgress) {
        return true;
      } else if (action === 'no_change' || action === 'refund') {
        return false;
      } else if (acknowledgmentHtml) {
        return !this.state.isCreditCardComplete || !this.state.isAcknowledgementChecked;
      } else {
        return !this.state.isCreditCardComplete;
      }
    }
  }, {
    key: 'paymentSection',
    get: function get() {
      var _this2 = this;

      var _props3 = this.props,
          acknowledgmentHtml = _props3.acknowledgmentHtml,
          isAcknowledgmentRequired = _props3.isAcknowledgmentRequired,
          onShowAcknowledgment = _props3.onShowAcknowledgment,
          isPurchaseInProgress = _props3.isPurchaseInProgress,
          action = _props3.action,
          currencyCode = _props3.currencyCode,
          refundDetails = _props3.refundDetails;

      switch (action) {
        case 'no_change':
          return _react2.default.createElement(_Button2.default, {
            type: 'submit',
            disabled: this.isSubmitDisabled,
            isInProgress: isPurchaseInProgress,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 137
            },
            __self: this
          }, 'Confirm changes');

        case 'refund':
          return _react2.default.createElement('div', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 147
            },
            __self: this
          }, _react2.default.createElement(_SectionWrapper2.default, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 148
            },
            __self: this
          }, _react2.default.createElement(_LineItemsList2.default, {
            rows: (0, _flatten2.default)((0, _map2.default)((0, _get3.default)(refundDetails, 'by_payment_method'), function (paymentMethodDetails) {
              return [['Refund to', paymentMethodDetails.payment_method.details.brand + " \u2022\u2022\u2022\u2022 " + paymentMethodDetails.payment_method.details.last4], ['Refund amount', '' + _currencyFormatter2.default.format(paymentMethodDetails.refund_amount, {
                code: currencyCode
              })]];
            })),
            __source: {
              fileName: _jsxFileName,
              lineNumber: 149
            },
            __self: this
          })), _react2.default.createElement(_Button2.default, {
            type: 'submit',
            disabled: this.isSubmitDisabled,
            isInProgress: isPurchaseInProgress,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 174
            },
            __self: this
          }, 'Refund:', ' ', _currencyFormatter2.default.format((0, _get3.default)(this.props.refundDetails, 'total_refund_amount'), {
            code: currencyCode
          })));

        default:
          return _react2.default.createElement('div', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 189
            },
            __self: this
          }, _react2.default.createElement(_PaymentSection2.default, {
            extraCCFields: this.props.extraCCFields,
            onChange: this.handleCreditCardFormChange,
            disabled: isPurchaseInProgress,
            ref: function ref(c) {
              return _this2.creditCardField = c;
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 190
            },
            __self: this
          }), acknowledgmentHtml && _react2.default.createElement(_CheckboxButton2.default, {
            className: 'PaymentLayout-acknowledgmentButton',
            name: 'acknowledgment',
            value: 'true',
            isHtml: true,
            title: acknowledgmentHtml,
            ref: function ref(c) {
              return _this2.acknowledgmentCheckbox = c;
            },
            onChange: this.handleAcknowledgmentChange,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 198
            },
            __self: this
          }), _react2.default.createElement(_Button2.default, {
            type: 'submit',
            disabled: this.isSubmitDisabled,
            isInProgress: isPurchaseInProgress,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 209
            },
            __self: this
          }, 'Complete Your Order'));
      }
    }
  }]);

  return PaymentLayout;
}(_react.PureComponent);

PaymentLayout.propTypes = propTypes;
PaymentLayout.defaultProps = defaultProps;
exports.default = PaymentLayout;