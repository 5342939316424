'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/Modal/Modal.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./Modal.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  className: _propTypes2.default.string,
  isActive: _propTypes2.default.bool,
  onDismiss: _propTypes2.default.func
};
var defaultProps = {
  className: '',
  isActive: false,
  onDismiss: function onDismiss() {}
};

var Modal = function (_Component) {
  _inherits(Modal, _Component);

  function Modal() {
    _classCallCheck(this, Modal);

    return _possibleConstructorReturn(this, (Modal.__proto__ || Object.getPrototypeOf(Modal)).apply(this, arguments));
  }

  _createClass(Modal, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)(this.props.className, 'ModalNew', {
          'is-active': this.props.isActive
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 21
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Modal-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 26
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Modal-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 27
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Modal-header-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }, this.props.title), _react2.default.createElement('i', {
        className: 'Modal-header-close material-icons',
        onClick: this.props.onDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 29
        },
        __self: this
      }, 'clear')), _react2.default.createElement('div', {
        className: 'Modal-body',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: this
      }, this.props.children)));
    }
  }]);

  return Modal;
}(_react.Component);

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
exports.default = Modal;