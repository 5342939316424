import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import logoChubb from './images/logo-chubb-insured.svg';
import logoSure from './images/logo-white.svg';
import './FooterChubb.css';

export default class FooterChubb extends PureComponent {
  render() {
    return (
      <div className="FooterChubb">
        <div className="Container">
          <img
            src={logoChubb}
            alt="Chubb Insured logo"
            className="FooterChubb-chubbLogo"
          />
          <p className="FooterChubb-info">
            Chubb Insurance South Africa Limited, an authorised Financial
            Services Provider (FSP: 27176). The policy wording will take
            precedence over all the guidance contained in the website and in all
            instances the terms and conditions of the policy wording will apply.
            Chubb only provides general guidance and does not consider your
            objectives, financial situation or needs; therefore this guidance
            cannot be considered as financial advice. Chubb can be e-mailed at{' '}
            <a href="mailto:clientqueries@chubb.com" style={{ color: '#fff' }}>
              clientqueries@chubb.com
            </a>
            .
          </p>
          <div className="FooterChubb-links">
            <ul className="FooterChubb-list">
              <li className="FooterChubb-listItem">
                <Link
                  className="FooterChubb-listItem-link"
                  href="/chubb/sitemap"
                >
                  Sitemap
                </Link>
              </li>
              <li className="FooterChubb-listItem">
                <a
                  className="FooterChubb-listItem-link"
                  href="https://www.chubbtravelinsurance.co.za/en/privacy-policy"
                >
                  Privacy and Cookies
                </a>
              </li>
              <li className="FooterChubb-listItem">
                <a
                  className="FooterChubb-listItem-link"
                  href="https://www2.chubb.com/us-en/terms-of-use.aspx"
                >
                  Terms of Use
                </a>
              </li>
              <li className="FooterChubb-listItem">
                <Link className="FooterChubb-listItem-link" to="/about-chubb">
                  Who is Chubb
                </Link>
              </li>
              <li className="FooterChubb-listItem">
                <Link className="FooterChubb-listItem-link" to="/contact-chubb">
                  Contact Us
                </Link>
              </li>
            </ul>
            <a
              className="FooterChubb-builtOnSureLink"
              href="https://www.sureapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Built on{' '}
              <img
                className="FooterChubb-builtOnSureLink-logo"
                src={logoSure}
                alt="Sure logo"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
