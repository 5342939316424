'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SingleSelectFieldsNew/SingleSelectFieldsNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _RadioButton = require('../RadioButton');

var _RadioButton2 = _interopRequireDefault(_RadioButton);

require('./SingleSelectFieldsNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var SingleSelectFieldsNew = function (_Component) {
  _inherits(SingleSelectFieldsNew, _Component);

  function SingleSelectFieldsNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SingleSelectFieldsNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SingleSelectFieldsNew.__proto__ || Object.getPrototypeOf(SingleSelectFieldsNew)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      selected: _this.props.defaultChecked
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SingleSelectFieldsNew, [{
    key: 'handleChange',
    value: function handleChange(choice) {
      this.setState({
        selected: choice.value
      });
      this.props.onChange(_defineProperty({}, this.props.field.key_name, choice.value));
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var choices = (0, _get2.default)(this.props.field, 'settings.choices') || [];
      var choicesNodes = choices.map(function (choice) {
        return _react2.default.createElement(_RadioButton2.default, {
          key: _this2.props.field.key_name + choice.value,
          name: _this2.props.field.key_name,
          value: choice.value,
          checked: _this2.state.selected === choice.value,
          title: choice.title,
          text: choice.help_text,
          onChange: _this2.handleChange.bind(_this2, choice),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 31
          },
          __self: _this2
        });
      });
      return _react2.default.createElement('div', {
        className: 'SingleSelectFieldsNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      }, choicesNodes);
    }
  }, {
    key: 'value',
    get: function get() {
      return _defineProperty({}, this.props.field.key_name, this.state.selected);
    }
  }]);

  return SingleSelectFieldsNew;
}(_react.Component);

exports.default = SingleSelectFieldsNew;