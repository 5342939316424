"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (api) {
  /*
   * Wedding actions
   * ========================================
   */
  // ----------------------------------------
  // Exports
  // ----------------------------------------
  return function () {};
};