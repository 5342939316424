'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _zipObject = require('lodash/zipObject');

var _zipObject2 = _interopRequireDefault(_zipObject);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/*
 * App actions
 */


var ACTIONS = ['SIGN_OUT', 'GET_BUSINESS_CLASSES', 'GET_BUSINESS_CLASSES_SUCCESS', 'GET_BUSINESS_CLASSES_FAILURE', 'UPDATE_BOP_DETAILS', 'UPDATE_BOP_RATING_ADDRESS', 'GET_BOP_PLANS', 'GET_BOP_PLANS_SUCCESS', 'GET_BOP_PLANS_FAILURE', 'SELECT_BOP_CHOICE', 'SAVE_BOP_RESPONSE', 'DELETE_BOP_RESPONSE', 'UPDATE_BOP_CURRENT_RESPONSE', 'BOP_SURVEY_NEXT', 'BOP_SURVEY_BACK', 'UPDATE_BOP_START_DATE', 'GET_BOP_RATES', 'GET_BOP_RATES_SUCCESS', 'GET_BOP_RATES_FAILURE', 'GET_BOP_CHECKOUT', 'GET_BOP_CHECKOUT_SUCCESS', 'GET_BOP_CHECKOUT_FAILURE', 'PURCHASE_BOP', 'PURCHASE_BOP_SUCCESS', 'PURCHASE_BOP_FAILURE'];
var ACTION_TYPES = (0, _zipObject2.default)(ACTIONS, ACTIONS);
exports.default = ACTION_TYPES;