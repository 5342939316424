'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/NotificationBar/NotificationBar.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./NotificationBar.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  message: _propTypes2.default.oneOfType([_propTypes2.default.string.isRequired, _propTypes2.default.shape({
    title: _propTypes2.default.string,
    body: _propTypes2.default.string.isRequired
  })]),
  sureStyle: _propTypes2.default.oneOf(['default', 'success', 'error', 'info']),
  autoDismissDelay: _propTypes2.default.number,
  isFullWidth: _propTypes2.default.bool
};
var defaultProps = {
  sureStyle: 'default',
  autoDismissDelay: 0,
  isFullWidth: false
};

var NotificationBar = function (_PureComponent) {
  _inherits(NotificationBar, _PureComponent);

  function NotificationBar() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, NotificationBar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = NotificationBar.__proto__ || Object.getPrototypeOf(NotificationBar)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isDismissed: false,
      isStateTransition: true,
      height: 50,
      // minimum possible height of NotificationBar component
      windowWidth: 0
    }, _this.updateHeight = function () {
      var height = _this.notificationBar.clientHeight;

      _this.setState({
        height: height
      });
    }, _this.handleDismiss = function () {
      _this.setState({
        isDismissed: true,
        isStateTransition: true
      });

      _this.autoDismissTimer = 0;
      _this.handleDismissTimer = setTimeout(function () {
        _this.props.onDismiss();

        _this.handleDismissTimer = 0;
      }, 350);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(NotificationBar, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.autoDismissDelay) {
        this.autoDismissTimer = setTimeout(this.handleDismiss, this.props.autoDismissDelay);
      }

      this.triggerAnimateOn = setTimeout(this.setState({
        isStateTransition: false
      }), 10);
      this.updateHeight();
      window.addEventListener('resize', this.updateHeight);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      if (this.autoDismissTimer) {
        clearTimeout(this.autoDismissTimer);
        this.autoDismissTimer = 0;
      }

      if (this.handleDismissTimer) {
        clearTimeout(this.handleDismissTimer);
        this.handleDismissTimer = 0;
      }

      clearTimeout(this.triggerAnimateOn);
      this.triggerAnimateOn = 0;
      window.removeEventListener('resize', this.updateHeight);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)(this.props.className, 'NotificationBar', {
          'is-dismissed': this.state.isDismissed,
          'is-full-width': this.props.isFullWidth
        }),
        ref: function ref(c) {
          return _this2.notificationBar = c;
        },
        style: this.state.isStateTransition ? {
          marginTop: -this.state.height,
          marginBottom: 0
        } : null,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 84
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'NotificationBar-wrapper NotificationBar-wrapper--' + this.props.sureStyle,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 99
        },
        __self: this
      }, _react2.default.createElement('p', {
        className: 'NotificationBar-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }, (0, _get2.default)(this.props.message, 'title') && _react2.default.createElement('span', {
        className: 'NotificationBar-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 106
        },
        __self: this
      }, this.props.message.title + ' '), typeof this.props.message === 'string' ? this.props.message : (0, _get2.default)(this.props.message, 'body'), _react2.default.createElement('i', {
        className: 'NotificationBar-closeBtn material-icons',
        onClick: this.handleDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 113
        },
        __self: this
      }, 'clear'))));
    }
  }]);

  return NotificationBar;
}(_react.PureComponent);

NotificationBar.propTypes = propTypes;
NotificationBar.defaultProps = defaultProps;
exports.default = NotificationBar;