'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/apps/BopApp/hoc.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

exports.default = withSubapp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _reactRouterRedux = require('react-router-redux');

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _first = require('lodash/first');

var _first2 = _interopRequireDefault(_first);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

var _utils = require('shared/lib/utils');

var _actions = require('./actions');

var _actions2 = _interopRequireDefault(_actions);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var currentScreen = state.bopScreens[state.bopCurrentScreenId];
  var fields = (0, _get2.default)(currentScreen, 'fields') || [];
  return {
    businessClasses: state.bopBusinessClasses,
    ratingAddress: state.bopRatingAddress,
    details: state.bopDetails,
    policyStartDate: state.bopPolicyStartDate && (0, _momentTimezone2.default)(state.bopPolicyStartDate) || null,
    rates: state.bopRates,
    checkoutInfo: state.bopCheckoutInfo,
    purchaseInfo: state.bopPurchaseInfo,
    plans: state.bopPlans,
    selectedPlanId: state.bopSelectedPlanId,
    selectedPlan: (0, _find2.default)(state.bopPlans, {
      plan_id: state.bopSelectedPlanId
    }),
    disclaimer: (0, _utils.getDisclaimer)(state.bopPlans, 'Chubb National Insurance Company'),
    isInProgress: state.isBopInProgress,
    policyProvider: 'Chubb',
    screens: state.bopScreens,
    fields: fields,
    flags: (0, _get2.default)(currentScreen, 'flags'),
    helpText: (0, _get2.default)(currentScreen, 'help_text'),
    question: (0, _get2.default)(currentScreen, 'question'),
    currentResponse: state.bopCurrentResponse,
    responses: state.bopDetails,
    isFirstScreen: (0, _get2.default)((0, _first2.default)(state.bopScreenSequence), 'id') === state.bopCurrentScreenId,
    isLastScreen: (0, _get2.default)((0, _last2.default)(state.bopScreenSequence), 'id') === state.bopCurrentScreenId,
    screenId: state.bopCurrentScreenId,
    surveyType: 'BOP'
  };
};

var mapDispatchToPropsWithActions = function mapDispatchToPropsWithActions(actions) {
  return function (dispatch) {
    var bopSurveyNext = actions.bopSurveyNext,
        deleteBopResponse = actions.deleteBopResponse,
        getBopCheckout = actions.getBopCheckout,
        getBopPlans = actions.getBopPlans,
        getBopRates = actions.getBopRates,
        getBusinessClasses = actions.getBusinessClasses,
        purchaseBop = actions.purchaseBop,
        saveBopResponse = actions.saveBopResponse,
        surveyBopBack = actions.surveyBopBack,
        updateBopCurrentResponse = actions.updateBopCurrentResponse,
        updateBopDetails = actions.updateBopDetails,
        updateBopRatingAddress = actions.updateBopRatingAddress,
        updateBopStartDate = actions.updateBopStartDate;
    return Object.assign({}, (0, _redux.bindActionCreators)({
      getBusinessClasses: getBusinessClasses,
      deleteResponse: deleteBopResponse,
      getCheckout: getBopCheckout,
      getRates: getBopRates,
      onGetPlans: getBopPlans,
      onUpdateDetails: updateBopDetails,
      onUpdateRatingAddress: updateBopRatingAddress,
      submitPurchase: purchaseBop,
      surveyBack: surveyBopBack,
      surveyNext: bopSurveyNext,
      updateCurrentResponse: updateBopCurrentResponse,
      updateStartDate: updateBopStartDate
    }, dispatch), {
      onCompleteSurvey: function onCompleteSurvey() {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)('policy-date')));
      },
      backToStart: function backToStart() {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)('basic-info')));
      },
      saveResponse: function saveResponse(response) {
        if (response.pni_email) {
          analytics.identify({
            email: response.pni_email
          });
        }

        return dispatch(saveBopResponse(response));
      }
    });
  };
};

function withSubapp(api) {
  var bopActions = (0, _actions2.default)(api);
  return function (WrappedComponent) {
    var SubappHOC = function (_Component) {
      _inherits(SubappHOC, _Component);

      function SubappHOC(props) {
        _classCallCheck(this, SubappHOC);

        var _this = _possibleConstructorReturn(this, (SubappHOC.__proto__ || Object.getPrototypeOf(SubappHOC)).call(this, props));

        _this.displayName = 'withSubapp(' + (WrappedComponent.displayName || WrappedComponent.name) + ')';
        _this.WrappedComponent = WrappedComponent;
        return _this;
      }

      _createClass(SubappHOC, [{
        key: 'render',
        value: function render() {
          return _react2.default.createElement('div', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 119
            },
            __self: this
          }, _react2.default.createElement(WrappedComponent, _extends({}, this.props, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 120
            },
            __self: this
          })), _react2.default.createElement(_ModalSpinner2.default, {
            message: 'Calculating your quote. This may take up to 15 seconds.',
            isActive: this.props.isFetchingPetQuote,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 121
            },
            __self: this
          }));
        }
      }]);

      return SubappHOC;
    }(_react.Component);

    var ComponentWithStatics = (0, _hoistNonReactStatics2.default)(SubappHOC, WrappedComponent);
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToPropsWithActions(bopActions))(ComponentWithStatics);
  };
}