'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/components/RadioButton/RadioButton.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./RadioButton.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  checked: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  defaultChecked: _propTypes2.default.bool,
  name: _propTypes2.default.string.isRequired,
  onChange: _propTypes2.default.func,
  onClick: _propTypes2.default.func,
  secondaryTitle: _propTypes2.default.string,
  text: _propTypes2.default.string,
  title: _propTypes2.default.string.isRequired,
  value: _propTypes2.default.any.isRequired
};
var defaultProps = {
  checked: false,
  className: '',
  name: '',
  onChange: function onChange() {},
  onClick: function onClick() {},
  secondaryTitle: '',
  text: '',
  title: '',
  value: ''
};

var RadioButton = function (_Component) {
  _inherits(RadioButton, _Component);

  function RadioButton() {
    _classCallCheck(this, RadioButton);

    return _possibleConstructorReturn(this, (RadioButton.__proto__ || Object.getPrototypeOf(RadioButton)).apply(this, arguments));
  }

  _createClass(RadioButton, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.hasOwnProperty('defaultChecked')) {
        console.warn('[RadioButton]', 'Property defaultChecked is deprecated. Use a controlled component with the checked property instead.');
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          name = _props.name,
          value = _props.value,
          checked = _props.checked,
          onChange = _props.onChange,
          onClick = _props.onClick,
          title = _props.title,
          text = _props.text,
          secondaryTitle = _props.secondaryTitle,
          className = _props.className,
          rest = _objectWithoutProperties(_props, ['name', 'value', 'checked', 'onChange', 'onClick', 'title', 'text', 'secondaryTitle', 'className']);

      return _react2.default.createElement('label', {
        className: (0, _classnames2.default)(this.props.className, 'Input--radioNew', {
          'is-checked': this.props.checked
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 55
        },
        __self: this
      }, _react2.default.createElement('input', _extends({
        type: 'radio',
        name: this.props.name,
        value: this.props.value,
        checked: this.props.checked,
        onChange: this.props.onChange,
        onClick: this.props.onClick
      }, rest, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 60
        },
        __self: this
      })), _react2.default.createElement('div', {
        className: 'Input-radioButton',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 69
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Input-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 70
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: 'material-icons Input-icon-unchecked',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 71
        },
        __self: this
      }, 'radio_button_unchecked'), _react2.default.createElement('i', {
        className: 'material-icons Input-icon-checked',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 74
        },
        __self: this
      }, 'radio_button_checked')), _react2.default.createElement('span', {
        className: 'Input-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 78
        },
        __self: this
      }, this.props.title)), _react2.default.createElement('div', {
        className: 'Input-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Input-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 81
        },
        __self: this
      }, this.props.text && _react2.default.createElement('span', {
        className: 'Input-text-help',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 83
        },
        __self: this
      }, this.props.text)), this.props.secondaryTitle && _react2.default.createElement('div', {
        className: 'Input-secondaryTitle',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 87
        },
        __self: this
      }, this.props.secondaryTitle)));
    }
  }]);

  return RadioButton;
}(_react.Component);

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;
exports.default = RadioButton;