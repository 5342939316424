import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import seo from 'shared/lib/seo';
import get from 'lodash/get';
import omit from 'lodash/omit';
import * as api from '../../api/renters';
import withSubapp from 'shared/lib/apps/RentersApp/hoc';
import withGlobals from '../../withGlobals';
import './RentersContainer.css';

class RentersContainer extends Component {

  componentDidMount() {
    const partnerName = get(this.props.partnerInfo, 'name');
    seo.setProps({
      title: `Renters Insurance | ${partnerName}`,
      description: `Get Renters Insurance through ${partnerName} to protect your clothes, furniture, and yourself. Plans as low as $11/month.`,
      url: `https://${window.location.host}/renters-insurance`,
      image: `https://${window.location.host}/images/stock-couple-apartment-floor.jpg`,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <div className="RentersContainer">
        {React.cloneElement(children, omit(this.props, 'children'))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const defaultDisclaimer = `Content and associated insurance products are provided by Sure HIIS Insurance Services, LLC (“Sure”), a licensed seller of insurance. The above does not in any way constitute an endorsement or referral by ${state.partnerInfo.name}. Products may not be offered in all states.`;
  return {
    disclaimer: ownProps.disclaimer || defaultDisclaimer,
  };
};

const enhance = compose(
  withGlobals({
    analyticsPrefix: 'Renters',
  }),
  connect(mapStateToProps),
  withSubapp(api),
);

export default enhance(RentersContainer);
