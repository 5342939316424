'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LineItemsList/LineItemsList.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./LineItemsList.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  className: _propTypes2.default.string,
  rows: _propTypes2.default.arrayOf(_propTypes2.default.array),
  testId: _propTypes2.default.string
};
var defaultProps = {
  className: '',
  rows: []
};

var LineItemsList = function (_PureComponent) {
  _inherits(LineItemsList, _PureComponent);

  function LineItemsList() {
    _classCallCheck(this, LineItemsList);

    return _possibleConstructorReturn(this, (LineItemsList.__proto__ || Object.getPrototypeOf(LineItemsList)).apply(this, arguments));
  }

  _createClass(LineItemsList, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          rows = _props.rows,
          className = _props.className,
          testId = _props.testId;
      return _react2.default.createElement('dl', {
        className: (0, _classnames2.default)(className, 'LineItemsList'),
        'data-testid': testId,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 23
        },
        __self: this
      }, (0, _map2.default)(rows, function (row, i) {
        return _react2.default.createElement('div', {
          key: i,
          className: 'LineItemsList-line',
          'data-testid': testId + '-item-' + i,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 28
          },
          __self: _this2
        }, _react2.default.createElement('dt', {
          className: 'LineItemsList-key',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 33
          },
          __self: _this2
        }, row[0]), _react2.default.createElement('dd', {
          className: 'LineItemsList-value',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 34
          },
          __self: _this2
        }, row[1]));
      }));
    }
  }]);

  return LineItemsList;
}(_react.PureComponent);

LineItemsList.propTypes = propTypes;
LineItemsList.defaultProps = defaultProps;
exports.default = LineItemsList;