import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import * as typeformEmbed from '@typeform/embed';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import Button from 'shared/lib/components/Button';
import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';
import LandingBlockTwoUp from 'shared/lib/components/LandingBlockTwoUp';
import LandingBlockPrice from 'shared/lib/components/LandingBlockPrice';
import LandingBlockCTABanner from 'shared/lib/components/LandingBlockCTABanner';
import './LandingWedding.css';
import imageWeddingAisle from './images/stock-wedding-aisle.jpg';
import imageWeddingGlasses from './images/stock-wedding-glasses.jpg';

export default class LandingWedding extends PureComponent {
  constructor(props) {
    super(props);
    this.typeformModal = typeformEmbed.makePopup(
      `https://sureapp.typeform.com/to/Qzfml5?id=${get(
        this.props.partnerInfo,
        'client_id',
        'no_client_id'
      )}&partner=${kebabCase(
        get(this.props.partnerInfo, 'name', 'no_client_name')
      )}`,
      {}
    );
  }

  componentDidMount() {
    analytics.page('Wedding - Landing');
  }

  handleTypeformClick = () => {
    this.typeformModal.open();
  };

  render() {
    const { partnerInfo, isMenuOpen, settings, onToggleMenu } = this.props;

    return (
      <div className="LandingWedding">
        <div className="LandingWedding-hero">
          <TopNavBar
            transparent
            showMenuText
            isMenuOpen={isMenuOpen}
            sideMenuLinks={settings.sideMenuLinks}
            logoLightUrl={settings.logoLightUrl}
            logoDarkUrl={settings.logoDarkUrl}
            title={settings.title}
            onToggleMenu={onToggleMenu}
          />
          <div className="Container">
            <h1 className="LandingWedding-hero-heading">
              Protection for the happiest day of your life.
            </h1>
            <p className="LandingWedding-hero-pitch">
              You’ve planned the ceremony, you booked the band –
              <br />
              now insure the day.
            </p>
            <Button
              className="LandingLandlordCTA LandingWedding-cta"
              onClick={this.handleTypeformClick}
              sureStyle="secondary"
            >
              Check Our Prices
            </Button>
            <p className="LandingWedding-hero-disclaimer">
              We'll never sell or share your personal information
            </p>
          </div>
        </div>

        <LandingBlockTwoUp
          title="What does wedding insurance cover?"
          subtitle="With all of the planning and prepping, you want to make sure your wedding is protected simply and quickly. Buying wedding insurance online is smart and easy."
          cards={[
            {
              backgroundImage: imageWeddingAisle,
              heading: 'Cancellation or Postponement of Event',
              description:
                'Coverage provides reimbursement for losses and expenses, resulting from: severe weather, sudden illness, no-show vendors, damage to attire, photos or gifts.',
            },
            {
              backgroundImage: imageWeddingGlasses,
              heading: 'Wedding Liability Coverage',
              description:
                'Protects you if you’re held liable for property damage or bodily injury. Offers host liquor liability to protect against alcohol-related accidents.',
            },
          ]}
        />

        <LandingBlockPrice
          title="Liability coverage starting at:"
          price="$64"
          footnote={
            <p className="LandingWedding-priceFootnote">
              And Cancellation starting at{' '}
              <span className="LandingWedding-priceFootnote--accent">$105</span>
            </p>
          }
        />

        <LandingBlockCTABanner
          title="Protect your special day and the memories to come."
          ctaText="Get Started"
          ctaStyle="secondary"
          footnote={
            <p className="LandingWedding-footerCTA">
              Or call us at:{' '}
              <a
                href="tel:+18443355441"
                className="LandingWedding-footerCTA--telLink"
              >
                (844) 335-5441
              </a>
            </p>
          }
          onClick={this.handleTypeformClick}
        />

        <Footer partnerInfo={partnerInfo} />
      </div>
    );
  }
}
