'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LandingBlockPriceComparison/LandingBlockPriceComparison.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./LandingBlockPriceComparison.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  subtitle: _propTypes2.default.string,
  footnote: _propTypes2.default.string,
  itemsHeading: _propTypes2.default.string,
  items: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    icon: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node, _propTypes2.default.element]),
    description: _propTypes2.default.string
  })),
  lightTheme: _propTypes2.default.bool
};
var defaultProps = {
  title: "Here's what you'd invest",
  subtitle: null,
  itemsHeading: null,
  items: [],
  lightTheme: false
};

var LandingBlockPriceComparison = function (_PureComponent) {
  _inherits(LandingBlockPriceComparison, _PureComponent);

  function LandingBlockPriceComparison() {
    _classCallCheck(this, LandingBlockPriceComparison);

    return _possibleConstructorReturn(this, (LandingBlockPriceComparison.__proto__ || Object.getPrototypeOf(LandingBlockPriceComparison)).apply(this, arguments));
  }

  _createClass(LandingBlockPriceComparison, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          title = _props.title,
          subtitle = _props.subtitle,
          itemsHeading = _props.itemsHeading,
          items = _props.items,
          footnote = _props.footnote;
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('LandingBlockPriceComparison', {
          'LandingBlockPriceComparison--light': this.props.lightTheme,
          'LandingBlockPriceComparison--noExamples': !this.props.items.length
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 39
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'LandingBlockPriceComparison-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 45
        },
        __self: this
      }, title), _react2.default.createElement('p', {
        className: 'LandingBlockPriceComparison-info',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 46
        },
        __self: this
      }, _react2.default.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: subtitle
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 47
        },
        __self: this
      })), itemsHeading && _react2.default.createElement('p', {
        className: 'LandingBlockPriceComparison-itemsHeading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 50
        },
        __self: this
      }, itemsHeading), !(0, _isEmpty2.default)(items) && _react2.default.createElement('div', {
        className: 'LandingBlockPriceComparison-examples',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 55
        },
        __self: this
      }, (0, _map2.default)(items, function (item, i) {
        return _react2.default.createElement('div', {
          key: i,
          className: 'LandingBlockPriceComparison-examples-example',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 57
          },
          __self: _this2
        }, _react2.default.createElement('div', {
          className: 'LandingBlockPriceComparison-examples-example-icon',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 61
          },
          __self: _this2
        }, _react2.default.isValidElement(item.icon) ? item.icon : _react2.default.createElement('i', {
          className: 'material-icons',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 65
          },
          __self: _this2
        }, item.icon)), _react2.default.createElement('p', {
          className: 'LandingBlockPriceComparison-examples-example-text',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 68
          },
          __self: _this2
        }, item.description));
      })), footnote && _react2.default.createElement('p', {
        className: 'LandingBlockPriceComparison-footnote',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: this
      }, '* ', this.props.footnote));
    }
  }]);

  return LandingBlockPriceComparison;
}(_react.PureComponent);

LandingBlockPriceComparison.propTypes = propTypes;
LandingBlockPriceComparison.defaultProps = defaultProps;
exports.default = LandingBlockPriceComparison;