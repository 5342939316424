import axios from 'axios';
import {
  getDeviceInfo,
  trimValues,
  destructureAxiosReq,
} from 'shared/lib/utils';
import { purchase } from '../api';

/**
 * Get renters plans for an address.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.propertyAddress The user property address.
 * @param {Object} opts.propertyAddress.streetAddress The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.personalInfo The user's personal information.
 * @param {Object} opts.personalInfo.firstName The user's first name.
 * @param {Object} opts.personalInfo.lastName The user's last name.
 * @param {Object} opts.personalInfo.phone The user's phone number.
 * @param {Object} opts.personalInfo.email The user's email address.
 */
export function getRentersPlans(opts) {
  const payload = trimValues({
    property_address: {
      street_address: opts.propertyAddress.streetAddress,
      unit: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    first_name: opts.personalInfo.firstName,
    last_name: opts.personalInfo.lastName,
    phone_number: opts.personalInfo.phone,
    email: opts.personalInfo.email,
  });

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1.1/protections/renters/plans`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(getDeviceInfo()),
        },
      }
    )
  );
}

/**
 * Retrieve renters rates from QBE.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.propertyAddress The user's property address.
 * @param {Object} opts.propertyAddress.street_address The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {String} opts.planId The renters plan ID.
 * @param {String} [opts.quoteId] The renters quote ID (required for quote
 * recalculation).
 * @param {Moment} opts.policyEffectiveDate The policy start date.
 * @param {Object} opts.details The user's answers from the dynamic form.
 * @param {String} opts.details.property_special_city The city returned from
 * the QBE address verification response.
 * @param {String} opts.details.property_special_county The county returned
 * from the QBE address verification response.
 * @param {String} opts.details.pni_email The user's email (required for
 * logged out requests).
 */
export function getRentersRates(opts) {
  const payload = {
    property_address: {
      street_address: opts.propertyAddress.streetAddress,
      unit: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    details: opts.details,
    settings: {
      policy_effective_date: opts.policyEffectiveDate,
    },
  };

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1.1/protections/renters/rates`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(getDeviceInfo()),
        },
      }
    )
  );
}

/**
 * Retrieve a renters checkout model.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {Object} opts.propertyAddress The user's property address.
 * @param {Object} opts.propertyAddress.street_address The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {String} opts.planId The renters plan ID.
 * @param {String} [opts.quoteId] The renters quote ID (required for quote
 * recalculation).
 * @param {String} opts.email The user's email.
 * @param {Object} opts.selectedRates An object of selected rates. E.g.
 * personal_property_coverage, all_peril_deductible, medical_coverage, etc.
 * @param {Moment} opts.policyEffectiveDate The policy start date.
 * @param {String} [opts.paymentCadence] The select payment cadence.
 * @param {Object} opts.details The user's answers from the dynamic form.
 */
export function getRentersCheckoutInfo(opts) {
  const payload = {
    property_address: {
      street_address: opts.propertyAddress.streetAddress,
      unit: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    email: opts.email,
    settings: {
      personal_property_amount: opts.personalPropertyAmount,
      policy_effective_date: opts.policyEffectiveDate,
      ...opts.selectedRates,
    },
    payment_cadence: opts.paymentCadence,
    details: opts.details,
  };

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1.1/protections/renters/checkout`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(getDeviceInfo()),
        },
      }
    )
  ).then(checkoutObj => {
    const normalizedCheckoutObj = {
      currencyCode: checkoutObj.currency.code,
      paymentSchedule: checkoutObj.payment_schedule,
      quote: checkoutObj.quote,
      totals: {
        grandtotal: checkoutObj.grandtotal,
      },
    };
    return normalizedCheckoutObj;
  });
}

/**
 * Get payment cadence options for a quote.
 *
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.quoteId The quote ID.
 * @param {Object} opts.propertyAddress The user's property address.
 * @param {Object} opts.propertyAddress.street_address The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.propertyAddress.postal The postal code.
 */
export function getRentersPaymentCadences(opts = {}) {
  const payload = {
    property_address: opts.property_address,
    quote_id: opts.quoteId,
  };

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1.1/protections/renters/cadences`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(getDeviceInfo()),
        },
      }
    )
  );
}

/**
 * Purchase a renters policy.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.planId The renters plan ID.
 * @param {String} opts.quoteId The renters quote ID.
 * @param {String} opts.firstName The user's first name.
 * @param {String} opts.lastName The user's last name.
 * @param {String} opts.email Primary driver email.
 * @param {String} [opts.paymentCadence] Selected payment cadence.
 * @param {Object} opts.cardElement The Stripe card element.
 * @param {Object} opts.cardName The cardholder name.
 * @param {Number} opts.revenue The total revenue of the purchase.
 * @param {Object} [opts.metadata] Any metadata with the purchase.

 */
export function purchaseRenters(opts) {
  const payload = {
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    metadata: opts.metadata,
    payment_cadence: opts.paymentCadence,
  };

  return purchase({
    planId: opts.planId,
    firstName: opts.firstName,
    lastName: opts.lastName,
    email: opts.email,
    authToken: opts.authToken,
    cardElement: opts.cardElement,
    cardName: opts.cardName,
    revenue: opts.revenue,
    payload,
    prefix: 'Renters',
    endpoint: `/api/partner/v1.1/protections/renters/purchase`,
  });
}
