import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './HeroChubb.css';

const propTypes = {
  accentColor: PropTypes.string,
  bgImage: PropTypes.string,
};

const defaultProps = {
  accentColor: '#01c1d6',
};

class HeroChubb extends PureComponent {
  render() {
    const { children, accentColor, bgImage } = this.props;
    const bgStyle = bgImage
      ? { backgroundImage: `url(${bgImage})` }
      : { backgroundColor: accentColor };
    return (
      <div className="HeroChubb" style={bgStyle}>
        <div className="HeroChubb-accent" style={{ background: accentColor }} />
        <div className="Container">
          <div className="HeroChubb-content">{children}</div>
        </div>
      </div>
    );
  }
}

HeroChubb.propTypes = propTypes;
HeroChubb.defaultProps = defaultProps;

export default HeroChubb;
