'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/LandingBlockPartner/LandingBlockPartner.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./LandingBlockPartner.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  partnerName: _propTypes2.default.string,
  logo: _propTypes2.default.string
};
var defaultProps = {
  title: null,
  partnerName: null
};

var LandingBlockPartner = function (_PureComponent) {
  _inherits(LandingBlockPartner, _PureComponent);

  function LandingBlockPartner() {
    _classCallCheck(this, LandingBlockPartner);

    return _possibleConstructorReturn(this, (LandingBlockPartner.__proto__ || Object.getPrototypeOf(LandingBlockPartner)).apply(this, arguments));
  }

  _createClass(LandingBlockPartner, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          logo = _props.logo,
          partnerName = _props.partnerName,
          title = _props.title;
      return _react2.default.createElement('div', {
        className: 'LandingBlockPartner',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 26
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'LandingBlockPartner-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 27
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'LandingBlockPartner-text-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }, title), children), _react2.default.createElement('div', {
        className: 'LandingBlockPartner-branding',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 33
        },
        __self: this
      }, _react2.default.createElement('img', {
        className: 'LandingBlockPartner-branding-logo',
        src: logo,
        alt: partnerName + ' logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 34
        },
        __self: this
      })));
    }
  }]);

  return LandingBlockPartner;
}(_react.PureComponent);

LandingBlockPartner.propTypes = propTypes;
LandingBlockPartner.defaultProps = defaultProps;
exports.default = LandingBlockPartner;