'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/apps/CollectiblesApp/hoc.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

exports.default = withSubapp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _reactRouterRedux = require('react-router-redux');

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _first = require('lodash/first');

var _first2 = _interopRequireDefault(_first);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _min = require('lodash/min');

var _min2 = _interopRequireDefault(_min);

var _snakeCase = require('lodash/snakeCase');

var _snakeCase2 = _interopRequireDefault(_snakeCase);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _sumBy = require('lodash/sumBy');

var _sumBy2 = _interopRequireDefault(_sumBy);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

var _utils = require('shared/lib/utils');

var _actions = require('./actions');

var _actions2 = _interopRequireDefault(_actions);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

var _CollectiblesEditQuoteModal = require('./views/CollectiblesEditQuoteModal');

var _CollectiblesEditQuoteModal2 = _interopRequireDefault(_CollectiblesEditQuoteModal);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var getCategoryPropsFromParams = function getCategoryPropsFromParams(params) {
  var categorySlug = params.categorySlug || 'other-insurance';
  var categoryKey = (0, _snakeCase2.default)(categorySlug.replace('-insurance', ''));
  var categoryConfig = _collectiblesCategories2.default[categoryKey];
  var categoryCode = categoryConfig.categoryCode;
  return {
    categorySlug: categorySlug,
    categoryKey: categoryKey,
    categoryConfig: categoryConfig,
    categoryCode: categoryCode
  };
};

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var currentScreen = (0, _get3.default)(state.collectiblesScreens[state.collectiblesSelectedCategory], state.collectiblesCurrentScreenId);

  var _getCategoryPropsFrom = getCategoryPropsFromParams(ownProps.params),
      categorySlug = _getCategoryPropsFrom.categorySlug,
      categoryConfig = _getCategoryPropsFrom.categoryConfig,
      categoryCode = _getCategoryPropsFrom.categoryCode;

  return {
    categoryCode: categoryCode,
    categoryConfig: categoryConfig,
    categorySlug: categorySlug,
    collectiblesAddressContext: state.collectiblesAddressContext,
    collectiblesCollectionsByCollectionId: state.collectiblesCollectionsByCollectionId,
    collectiblesEditQuoteModalContext: state.collectiblesEditQuoteModalContext,
    collectiblesPolicyContext: state.collectiblesPolicyContext,
    collectiblesPreEditContext: state.collectiblesPreEditContext,
    collectiblesQuote: state.collectiblesQuote,
    collectiblesSelectedCategory: state.collectiblesSelectedCategory,
    currencyCode: (0, _get3.default)(state.collectiblesQuoteTotal, 'currency_code'),
    currentResponse: state.collectiblesCurrentResponse,
    disclaimer: 'Policies underwritten by Markel Insurance Company.',
    fields: (0, _get3.default)(currentScreen, 'fields') || [],
    flags: (0, _get3.default)(currentScreen, 'flags'),
    hasPrepopulatedData: state.hasPrepopulatedData,
    helpText: (0, _get3.default)(currentScreen, 'help_text'),
    isCollectiblesInProgress: state.isCollectiblesInProgress,
    isEditingCollection: state.isEditingCollection,
    collectionIdToEdit: state.collectionIdToEdit,
    isFirstScreen: (0, _get3.default)((0, _first2.default)(state.collectiblesScreenSequence[state.collectiblesSelectedCategory]), 'id', false) === state.collectiblesCurrentScreenId,
    isLastScreen: (0, _get3.default)((0, _last2.default)(state.collectiblesScreenSequence[state.collectiblesSelectedCategory]), 'id', false) === state.collectiblesCurrentScreenId,
    isPurchaseInProgress: state.isPurchaseInProgress,
    notificationMessage: state.collectiblesNotificationMessage,
    phoneNumber: (0, _get3.default)(state.collectiblesResponses, 'owner_phone_number'),
    plan: state.collectiblesPlan,
    policyNumber: state.collectiblesPolicyNumber,
    policyProvider: 'Markel',
    policyStartDate: (0, _momentTimezone2.default)(state.collectiblesPolicyStartDate),
    propertyAddress: state.collectiblesPropertyAddress,
    question: (0, _get3.default)(currentScreen, 'question'),
    rates: state.collectiblesRates,
    responses: state.collectiblesResponses,
    screenId: state.collectiblesCurrentScreenId,
    screenSequence: state.collectiblesScreenSequence,
    screens: state.collectiblesScreens,
    selectedAmounts: state.collectiblesSelectedAmounts,
    surveyType: 'Collectibles',
    totals: (0, _get3.default)(state.collectiblesQuoteTotal, 'amount')
  };
};

var mapDispatchToPropsWithActions = function mapDispatchToPropsWithActions(actions) {
  return function (dispatch, ownProps) {
    var addCollectionToQuote = actions.addCollectionToQuote,
        clearCollectionDetails = actions.clearCollectionDetails,
        collectiblesSurveyNext = actions.collectiblesSurveyNext,
        createCollectiblesQuote = actions.createCollectiblesQuote,
        deleteCollectiblesResponse = actions.deleteCollectiblesResponse,
        dismissEditCollectiblesQuoteModal = actions.dismissEditCollectiblesQuoteModal,
        editCollection = actions.editCollection,
        getCollectiblesCheckout = actions.getCollectiblesCheckout,
        getCollectiblesPlans = actions.getCollectiblesPlans,
        hideCollectiblesNotificationBar = actions.hideCollectiblesNotificationBar,
        jumpToCollectiblesSurveyScreenId = actions.jumpToCollectiblesSurveyScreenId,
        openEditCollectiblesQuoteModal = actions.openEditCollectiblesQuoteModal,
        purchaseCollectibles = actions.purchaseCollectibles,
        removeCollectionFromQuote = actions.removeCollectionFromQuote,
        resetCollectiblesData = actions.resetCollectiblesData,
        saveCollectiblesQuoteEditsAndCloseModal = actions.saveCollectiblesQuoteEditsAndCloseModal,
        saveCollectiblesResponse = actions.saveCollectiblesResponse,
        showCollectiblesNotificationBar = actions.showCollectiblesNotificationBar,
        surveyCollectiblesBack = actions.surveyCollectiblesBack,
        updateCollectiblesAddress = actions.updateCollectiblesAddress,
        updateCollectiblesAddressContext = actions.updateCollectiblesAddressContext,
        updateCollectiblesCategoryType = actions.updateCollectiblesCategoryType,
        updateCollectiblesCurrentResponse = actions.updateCollectiblesCurrentResponse,
        updateCollectiblesPlanDetails = actions.updateCollectiblesPlanDetails,
        updateCollectiblesSelectedAmounts = actions.updateCollectiblesSelectedAmounts,
        updateCollectiblesStartDate = actions.updateCollectiblesStartDate,
        updateCollectionDetails = actions.updateCollectionDetails,
        updateCollectionsInQuote = actions.updateCollectionsInQuote;

    var _getCategoryPropsFrom2 = getCategoryPropsFromParams(ownProps.params),
        categoryCode = _getCategoryPropsFrom2.categoryCode;

    return Object.assign({}, (0, _redux.bindActionCreators)({
      addCollectionToQuote: addCollectionToQuote,
      clearCollectionDetails: clearCollectionDetails,
      createCollectiblesQuote: createCollectiblesQuote,
      deleteResponse: deleteCollectiblesResponse,
      dismissEditCollectiblesQuoteModal: dismissEditCollectiblesQuoteModal,
      editCollection: editCollection,
      getCollectiblesCheckout: getCollectiblesCheckout,
      getCollectiblesPlans: getCollectiblesPlans,
      hideCollectiblesNotificationBar: hideCollectiblesNotificationBar,
      jumpToCollectiblesSurveyScreenId: jumpToCollectiblesSurveyScreenId,
      openEditCollectiblesQuoteModal: openEditCollectiblesQuoteModal,
      removeCollectionFromQuote: removeCollectionFromQuote,
      resetCollectiblesData: resetCollectiblesData,
      saveCollectiblesQuoteEditsAndCloseModal: saveCollectiblesQuoteEditsAndCloseModal,
      saveResponse: saveCollectiblesResponse,
      showCollectiblesNotificationBar: showCollectiblesNotificationBar,
      surveyBack: surveyCollectiblesBack,
      surveyNext: collectiblesSurveyNext,
      updateCollectiblesAddress: updateCollectiblesAddress,
      updateCollectiblesAddressContext: updateCollectiblesAddressContext,
      updateCollectiblesCategoryType: updateCollectiblesCategoryType,
      updateCollectiblesPlanDetails: updateCollectiblesPlanDetails,
      updateCollectiblesSelectedAmounts: updateCollectiblesSelectedAmounts,
      updateCollectiblesStartDate: updateCollectiblesStartDate,
      updateCollectionDetails: updateCollectionDetails,
      updateCollectionsInQuote: updateCollectionsInQuote,
      updateCurrentResponse: updateCollectiblesCurrentResponse
    }, dispatch), {
      navTo: function navTo(url) {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)(url)));
      },
      backToStart: function backToStart() {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)('start')));
      },
      purchase: function purchase(cardElement, cardName) {
        return dispatch(purchaseCollectibles({
          cardElement: cardElement,
          categoryCode: categoryCode,
          cardName: cardName
        }));
      }
    });
  };
};

function withSubapp(api) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var collectiblesActions = (0, _actions2.default)(api);
  return function (WrappedComponent) {
    var SubappHOC = function (_Component) {
      _inherits(SubappHOC, _Component);

      function SubappHOC() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SubappHOC);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SubappHOC.__proto__ || Object.getPrototypeOf(SubappHOC)).call.apply(_ref, [this].concat(args))), _this), _this.displayName = 'withSubapp(' + (WrappedComponent.displayName || WrappedComponent.name) + ')', _this.WrappedComponent = WrappedComponent, _this.notifyExclusion = function (exclusionNotice) {
          var _this$props = _this.props,
              showCollectiblesNotificationBar = _this$props.showCollectiblesNotificationBar,
              collectiblesAddressContext = _this$props.collectiblesAddressContext,
              propertyAddress = _this$props.propertyAddress,
              categoryCode = _this$props.categoryCode;

          if (exclusionNotice && !(0, _some2.default)(collectiblesAddressContext, {
            address: propertyAddress
          })) {
            showCollectiblesNotificationBar(exclusionNotice, propertyAddress);
            analytics.track('Collectibles - Exclusion Notice Shown', {
              categoryCode: categoryCode,
              noninteraction: 1
            });
          }
        }, _this.handleSubmitAddress = function (e) {
          analytics.identify({
            email: _this.props.responses.owner_email
          });
          analytics.track('Collectibles - Verify Address', {
            categoryCode: _this.props.categoryCode
          });
          return _this.props.getCollectiblesPlans(_this.props.collectiblesQuote.quote_id).then(function (response) {
            analytics.track('Collectibles - Address Verified', {
              categoryCode: _this.props.categoryCode,
              noninteraction: 1
            });
            var nextUrl = (0, _last2.default)(_this.props.location.pathname.split('/')) === 'new' ? '../survey/' + response.dynamic_form.start_screen_id : 'survey/' + response.dynamic_form.start_screen_id;

            _this.props.navTo(nextUrl);

            _this.notifyExclusion({
              title: 'Exclusion Notice:',
              body: response.exclusion_notice
            });
          }).catch(_this.props.onError);
        }, _this.handleCompleteSurvey = function () {
          if ((0, _isEmpty2.default)(_this.props.collectiblesQuote.application.plan_applications)) {
            _this.props.navTo('../policy-date');
          } else {
            var adjustQuote = _this.props.isEditingCollection ? _this.props.updateCollectionsInQuote : _this.props.addCollectionToQuote;
            return adjustQuote().then(_this.props.getCollectiblesCheckout).then(function () {
              return _this.props.navTo('../quote');
            }).catch(_this.props.onError);
          }
        }, _temp), _possibleConstructorReturn(_this, _ret);
      }

      _createClass(SubappHOC, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
          if (!!window.sessionStorage.isHagerty && process.env.REACT_APP_ENV !== 'production' && this.props.partnerInfo) {
            var authToken = 'pfudKwmora1AiQG3LRWP8ClxsYeBDMphq5X90tN6OfHbF2Z4T';
            var partnerInfo = {
              email: 'michael+hagerty@sureapp.com',
              name: 'Hagerty',
              client_id: '5b3bc901db27972d89000000'
            };
            _axios2.default.defaults.headers.common['Authorization'] = 'Token ' + authToken;
            this.props.updatePartnerInfo(authToken, partnerInfo);
          }

          if (!this.props.collectiblesPolicies) {
            console.warn('CollectiblesApp', 'Missing collectiblesPolicies prop');
          }
        }
      }, {
        key: 'validateSurveyResponse',
        value: function validateSurveyResponse(surveyQuestionComponent) {
          var _this2 = this;

          return new Promise(function (resolve, reject) {
            var currencyField = (0, _get3.default)(surveyQuestionComponent.fieldNode, 'fieldNode', null);
            var surveyQuestionSettings = (0, _get3.default)(surveyQuestionComponent.props.fields[0], 'settings', {});

            if (currencyField && currencyField.value && Number(currencyField.value.replace(/\D/g, '')) > Number(currencyField.props.maxValue)) {
              if (currencyField.props.name === 'collection_total_value') {
                analytics.track('Collectibles - Collection Limit Exceeded', {
                  value: currencyField.value,
                  noninteraction: 1
                });
                var title = _this2.isHagerty ? 'You have exceeded the coverage limit' : 'You qualify for Sure’s VIP service!';
                var message = void 0;

                if (_this2.isHagerty) {
                  message = surveyQuestionSettings.isCurrentValueLimitedByCategory ? 'Sorry! Our maximum category value at this time is $100,000. We’re working hard to expand to our customer’s needs, so please send us a note at specialtyproductteam@hagerty.com and we’ll contact you when we are ready to insure your collection.' : 'Sorry! Our maximum total collection value at this time is $800,000. We’re working hard to expand to our customer’s needs, so please send us a note at specialtyproductteam@hagerty.com and we’ll contact you when we are ready to insure your collection.';
                } else {
                  message = 'Good news! You’re one of the select few customers who qualify to work with our VIP service team. Don’t worry, the process is easy – simply call or text 1-844-335-5441 and our VIP team will take care of the rest.';
                }

                reject({
                  title: title,
                  message: message
                });
              } else if (currencyField.props.name === 'highest_value_item') {
                analytics.track('Collectibles - Highest Value Item Exceeds Total Collection Value', {
                  value: currencyField.value,
                  noninteraction: 1
                });
                reject({
                  title: 'Item value exceeds total value',
                  message: 'The highest value item in your collection may not exceed the total value of the collection. Please adjust item value, or go back and adjust the collection value.'
                });
              }
            } else {
              resolve();
            }
          });
        }
      }, {
        key: 'render',
        value: function render() {
          return _react2.default.createElement(_react.Fragment, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 464
            },
            __self: this
          }, _react2.default.createElement(WrappedComponent, _extends({}, this.props, this.propsWithContext, {
            validate: this.validateSurveyResponse,
            blacklistCategories: options.blacklistCategories || [],
            onSubmitAddress: this.handleSubmitAddress,
            notifyExclusion: this.notifyExclusion,
            onCompleteSurvey: this.handleCompleteSurvey,
            isInProgress: this.props.isCollectiblesInProgress,
            isHagerty: this.isHagerty,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 465
            },
            __self: this
          })), _react2.default.createElement(_ModalSpinner2.default, {
            message: 'Calculating your quote. This may take up to 15 seconds.',
            isActive: this.props.isFetchingPetQuote,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 476
            },
            __self: this
          }), this.props.collectiblesEditQuoteModalContext && _react2.default.createElement(_CollectiblesEditQuoteModal2.default, _extends({}, this.props, this.propsWithContext, {
            validate: this.validateSurveyResponse,
            isInProgress: this.props.isCollectiblesInProgress,
            isHagerty: this.isHagerty,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 481
            },
            __self: this
          })));
        }
      }, {
        key: 'isHagerty',
        get: function get() {
          return (// Hagerty client ID for QA env
            (0, _get3.default)(this.props.partnerInfo, 'client_id') === '5b3bc901db27972d89000000' && process.env.REACT_APP_ENV !== 'production' || // Hagerty client ID for sandbox env
            (0, _get3.default)(this.props.partnerInfo, 'client_id') === '5bc4a1dfbfafbb2092000000' && process.env.REACT_APP_ENV !== 'production' || // Hagerty client ID for prod env
            (0, _get3.default)(this.props.partnerInfo, 'client_id') === '5bd9df7ee0959f5d60000000'
          );
        }
      }, {
        key: 'collectionMaxValue',
        get: function get() {
          var _props = this.props,
              collectiblesCollectionsByCollectionId = _props.collectiblesCollectionsByCollectionId,
              collectiblesEditQuoteModalContext = _props.collectiblesEditQuoteModalContext,
              collectiblesSelectedCategory = _props.collectiblesSelectedCategory,
              collectionIdToEdit = _props.collectionIdToEdit,
              collectiblesQuote = _props.collectiblesQuote;
          var collections = (0, _get3.default)(collectiblesQuote.application, 'plan_applications[0].collections', []);
          var currentCollectionId = (0, _get3.default)(collectiblesEditQuoteModalContext, 'collectionId') || collectionIdToEdit || null;
          var currentCollection = (0, _find2.default)(collections, {
            reference_id: currentCollectionId
          });
          var categoryCodesToTreatAsOther = (0, _map2.default)((0, _filter2.default)(_collectiblesCategories2.default, 'isSubCategory'), 'categoryCode');
          var currentCategory = collectiblesEditQuoteModalContext ? collectiblesCollectionsByCollectionId[collectiblesEditQuoteModalContext.collectionId].category_code : collectiblesSelectedCategory;
          var isCurrentCategorySubCategory = (0, _includes2.default)(categoryCodesToTreatAsOther, currentCategory);
          var currentCategoryValue = (0, _sumBy2.default)((0, _filter2.default)((0, _without2.default)(collections, currentCollection), function (collection) {
            return isCurrentCategorySubCategory ? (0, _includes2.default)(categoryCodesToTreatAsOther, collection.category_code) : collection.category_code === currentCategory;
          }), 'collection_total_value');
          var currentPolicyValue = (0, _sumBy2.default)((0, _without2.default)(collections, currentCollection), 'collection_total_value');
          var maxValue = this.isHagerty ? (0, _min2.default)([100000 - currentCategoryValue, 800000 - currentPolicyValue]) : 100000 - currentPolicyValue;
          var isCurrentValueLimitedByCategory = this.isHagerty ? 100000 - currentCategoryValue < 800000 - currentPolicyValue : false;
          return {
            value: maxValue,
            isCurrentValueLimitedByCategory: isCurrentValueLimitedByCategory
          };
        }
      }, {
        key: 'fieldsWithContext',
        get: function get() {
          var responses = this.props.responses;
          var fields = (0, _cloneDeep2.default)(this.props.fields); // XXX: Set a max value for `highest_value_item` that is equal to the collection's total value

          if (fields[0] && fields[0].key_name === 'highest_value_item') {
            fields[0].settings.max_value = responses.collection_total_value || fields[0].settings.max_value;
          } // XXX: Set a max value for `collection_total_value` dependent on isHagerty


          if (fields[0] && fields[0].key_name === 'collection_total_value') {
            fields[0].settings.max_value = this.collectionMaxValue.value.toString();
            fields[0].settings.isCurrentValueLimitedByCategory = this.collectionMaxValue.isCurrentValueLimitedByCategory;
          } // XXX: Update the regex on the country field (was '.{3,}') to allow the formatting provided by Google Places autofill


          if (fields[4] && fields[4].key_name === 'mailing_address_country') {
            fields[4].settings.regex = '.{2,}';
          }

          return fields;
        }
      }, {
        key: 'questionWithContext',
        get: function get() {
          var fields = this.props.fields;
          var question = (0, _cloneDeep2.default)(this.props.question);

          if (this.isHagerty) {
            if (fields[0] && fields[0].key_name === 'claims_count') {
              question = 'How many paid collectibles or art claims have you had in the last 3 years?';
            } else if (fields[0] && fields[0].key_name === 'declined_insurance_type') {
              question = 'Has your collectibles or art insurance coverage ever been cancelled, declined, or non-renewed?';
            }
          }

          return question;
        }
      }, {
        key: 'disclaimerWithContext',
        get: function get() {
          var disclaimer = this.props.disclaimer;

          if (this.isHagerty) {
            disclaimer = disclaimer + ' Provided to you by Hagerty Insurance Agency, LLC. Questions? Call 800-955-1012';
          }

          return disclaimer;
        }
      }, {
        key: 'propsWithContext',
        get: function get() {
          return {
            fields: this.fieldsWithContext,
            question: this.questionWithContext,
            disclaimer: this.disclaimerWithContext
          };
        }
      }]);

      return SubappHOC;
    }(_react.Component);

    var ComponentWithStatics = (0, _hoistNonReactStatics2.default)(SubappHOC, WrappedComponent);
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToPropsWithActions(collectiblesActions))(ComponentWithStatics);
  };
}