'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarRentalInfo/RentalCarRentalInfo.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _sureComponents = require('sure-components');

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _utils = require('shared/lib/utils');

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarRentalInfo.css');

var _agencies = require('./agencies');

var _agencies2 = _interopRequireDefault(_agencies);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  pickUpDate: _propTypes2.default.object,
  plan: _propTypes2.default.object,
  rentalAgency: _propTypes2.default.string,
  returnDate: _propTypes2.default.object,
  updatePickUpDate: _propTypes2.default.func,
  updateRentalCarAgency: _propTypes2.default.func,
  updateReturnDate: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  pickUpDate: null,
  rentalAgency: '',
  returnDate: null
};

var RentalCarRentalInfo = function (_Component) {
  _inherits(RentalCarRentalInfo, _Component);

  function RentalCarRentalInfo(props) {
    var _this2 = this;

    _classCallCheck(this, RentalCarRentalInfo);

    var _this = _possibleConstructorReturn(this, (RentalCarRentalInfo.__proto__ || Object.getPrototypeOf(RentalCarRentalInfo)).call(this, props));

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      var _this$props = _this.props,
          rentalAgency = _this$props.rentalAgency,
          pickUpDate = _this$props.pickUpDate,
          navTo = _this$props.navTo,
          showGlobalModal = _this$props.showGlobalModal,
          updateRentalCarAgency = _this$props.updateRentalCarAgency;
      var otherAgency = _this.state.otherAgency;

      if (rentalAgency === 'Other') {
        updateRentalCarAgency(otherAgency);
      }

      analytics.track('Rental Car - Next Button Clicked', {
        category: 'survey'
      });

      if (rentalAgency === 'Renty' || rentalAgency === 'Other' && otherAgency.trim().toLowerCase() === 'renty') {
        showGlobalModal({
          id: 'info',
          title: 'Ineligible for coverage',
          body: _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 120
            },
            __self: _this2
          }, 'Rental Car insurance is not available to customers of Renty Rental Car.')
        });
      } else if (pickUpDate.isAfter(_this.momentToday, 'day')) {
        navTo((0, _utils.resolveUrlPath)('use-type'));
      } else {
        navTo((0, _utils.resolveUrlPath)('agreement-confirmation'));
      }
    };

    _this.handleDatePickerFocus = function (focusedInput) {
      _this.setState({
        datePickerFocusedInput: focusedInput
      });
    };

    _this.handleDateChange = function (_ref) {
      var startDate = _ref.startDate,
          endDate = _ref.endDate;
      analytics.track('Rental Car - Date Changed');

      _this.props.updateRentalCarPickUpDate(startDate);

      _this.props.updateRentalCarReturnDate(endDate);
    };

    _this.handleUpdateRentalAgency = function (option) {
      if (option) {
        _this.props.updateRentalCarAgency(option.value);
      } else {
        _this.props.updateRentalCarAgency(null);
      }
    };

    _this.handleOtherRentalAgency = function (e) {
      _this.setState({
        otherAgency: e.target.value
      });
    };

    _this.state = {
      datePickerFocusedInput: null,
      otherAgency: ''
    };
    _this.momentToday = (0, _momentTimezone2.default)().tz('America/New_York');
    _this.isMobile = window.innerWidth < 768;
    return _this;
  }

  _createClass(RentalCarRentalInfo, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Rental Info');
      analytics.track('Rental Car - Rental Info Page Viewed', {
        noninteraction: 1
      });
      var isPickUpDateInvalid = this.props.pickUpDate && this.props.pickUpDate.isBefore(this.momentToday, 'day');
      var isReturnDateInvalid = this.props.returnDate && this.props.returnDate.isBefore(this.momentToday, 'day');

      if (isPickUpDateInvalid || isReturnDateInvalid) {
        this.handleDateChange(null, null);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (this.props.pickUpDate && this.props.returnDate && prevProps.pickUpDate !== this.props.pickUpDate && this.props.returnDate.diff(this.props.pickUpDate, 'days') > 30) {
        this.props.updateRentalCarReturnDate(null);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          disclaimer = _props.disclaimer,
          pickUpDate = _props.pickUpDate,
          rentalAgency = _props.rentalAgency,
          returnDate = _props.returnDate;
      var isOtherAgencyOption = rentalAgency === 'Other';
      return _react2.default.createElement('div', {
        className: 'RentalCarRentalInfo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 161
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 162
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarRentalInfo-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 163
        },
        __self: this
      }, 'Please provide us information about your rental reservation:'), _react2.default.createElement('form', {
        className: 'RentalCarRentalInfo-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 166
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'RentalCarRentalInfo-dates-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 171
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'RentalCarRentalInfo-dates-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 172
        },
        __self: this
      }, 'Pick-Up Date'), _react2.default.createElement('div', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 175
        },
        __self: this
      }), _react2.default.createElement('label', {
        className: 'RentalCarRentalInfo-dates-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 176
        },
        __self: this
      }, 'Return Date')), _react2.default.createElement(_reactDates.DateRangePicker, {
        startDate: pickUpDate,
        startDateId: 'pick_up_date',
        startDatePlaceholderText: 'Pick-Up',
        endDate: returnDate,
        endDateId: 'return_date',
        endDatePlaceholderText: 'Return',
        numberOfMonths: 2,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        onDatesChange: this.handleDateChange,
        focusedInput: this.state.datePickerFocusedInput,
        onFocusChange: this.handleDatePickerFocus,
        block: true,
        noBorder: true,
        hideKeyboardShortcutsPanel: true,
        displayFormat: 'MM/DD/YYYY',
        minimumNights: 0,
        isOutsideRange: function isOutsideRange(day) {
          return day.startOf('day').isBefore(_this3.momentToday, 'day') || day.startOf('day').isAfter((0, _momentTimezone2.default)(pickUpDate).tz('America/New_York').add(30, 'days'));
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 180
        },
        __self: this
      }), _react2.default.createElement('label', {
        className: 'RentalCarRentalInfo-agency-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 207
        },
        __self: this
      }, 'Rental Car Agency'), _react2.default.createElement(_reactSelect2.default, {
        className: 'SureSelect RentalCarRentalInfo-agency',
        name: 'rental_agency',
        options: this.agencyOptions,
        placeholder: 'Example: Avis',
        value: rentalAgency,
        onChange: this.handleUpdateRentalAgency,
        required: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 210
        },
        __self: this
      }), isOtherAgencyOption && _react2.default.createElement('div', {
        className: 'RentalCarRentalInfo-agency-other',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'RentalCarRentalInfo-agency-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 221
        },
        __self: this
      }, 'Name of Rental Car Agency'), _react2.default.createElement(_TextInput2.default, {
        className: 'SureSelect RentalCarRentalInfo-agency-otherInput',
        name: 'otherAgency',
        placeholder: 'Rental car agency',
        maxLength: '40',
        required: true,
        value: this.state.otherAgency || '',
        onChange: this.handleOtherRentalAgency,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 224
        },
        __self: this
      })), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 235
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        isBackDisabled: true,
        isNextDisabled: this.isNextDisabled,
        onNext: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 237
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 242
        },
        __self: this
      })));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      var otherAgency = this.props.rentalAgency === 'Other' ? this.state.otherAgency : 'other';
      return (0, _some2.default)([this.props.pickUpDate, this.props.returnDate, this.props.rentalAgency, otherAgency], _isEmpty2.default);
    }
  }, {
    key: 'agencyOptions',
    get: function get() {
      return (0, _map2.default)(_agencies2.default, function (agency) {
        return {
          value: agency,
          label: agency
        };
      });
    }
  }]);

  return RentalCarRentalInfo;
}(_react.Component);

RentalCarRentalInfo.propTypes = propTypes;
RentalCarRentalInfo.defaultProps = defaultProps;
exports.default = RentalCarRentalInfo;