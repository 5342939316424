import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as analytics from 'shared/lib/analytics';
import each from 'lodash/each';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import PaymentLayout from 'shared/lib/components/PaymentLayout';
import LineItemsList from 'shared/lib/components/LineItemsList';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import SectionWrapper from 'shared/lib/components/SectionWrapper';
import Spinner from 'shared/lib/components/Spinner';
import { format as currencyFormat } from 'currency-formatter';
import './HomeSafePaymentView.css';

const propTypes = {
  homeSafeQuote: PropTypes.object,
  homeSafeSelectedPlan: PropTypes.object,
  homeSafeSettings: PropTypes.object,
  disclaimer: PropTypes.string,
  getHomeSafeCheckout: PropTypes.func,
  isHomeSafeInProgress: PropTypes.bool,
  purchaseHomeSafe: PropTypes.func,
};

const defaultProps = {
  homeSafeQuote: {},
  homeSafeSelectedPlan: {},
  homeSafeSettings: {},
  disclaimer: null,
  getHomeSafeCheckout: () => {},
  isHomeSafeInProgress: false,
  purchaseHomeSafe: () => {},
};

class HomeSafePaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    analytics.page('HomeSafe - Payment');
    this.setState({ isLoading: true });
    this.props
      .getHomeSafeCheckout()
      .then(() => {
        analytics.page('HomeSafe - Checkout Loaded');
        this.setState({ isLoading: false });
      })
      .catch(error => {
        analytics.page('HomeSafe - Checkout Loading Failed');
        console.error('error', error);
        this.setState({ isLoading: false });
        this.props.onError(error);
      });
  }

  handleSubmit = ({ cardElement }) => {
    analytics.page('HomeSafe - Checkout Submitted');
    this.props
      .purchaseHomeSafe(cardElement)
      .then(() => {
        this.props.navTo('/residential-insurance/confirmation');
      })
      .catch(this.props.onError);
  };

  get planDetailsRows() {
    const {
      homeSafeDetails,
      homeSafeSelectedPlan,
      homeSafeSettings,
    } = this.props;
    const currencyOpts = {
      code: get(homeSafeSettings, 'currency.code'),
      precision: 0,
    };
    const rows = [
      [
        'Home Burglary',
        currencyFormat(
          get(homeSafeSelectedPlan, 'details.coverage_amounts.home_burglary'),
          currencyOpts
        ),
      ],
      [
        'Home ADD',
        currencyFormat(
          get(homeSafeSelectedPlan, 'details.coverage_amounts.home_add'),
          currencyOpts
        ),
      ],
    ];

    each(homeSafeDetails.addOns, addOnsKey => {
      const addOn = get(
        homeSafeSelectedPlan,
        `details.plan_add_ons.${addOnsKey}`
      );
      rows.push([startCase(addOnsKey), addOn.coverage_amount]);
    });

    return rows;
  }

  get totals() {
    return {
      subtotal: get(this.props.homeSafeQuote, 'billing.sub_total'),
      tax: get(this.props.homeSafeQuote, 'billing.tax_total'),
      grandtotal: get(this.props.homeSafeQuote, 'billing.grand_total'),
    };
  }

  render() {
    const {
      homeSafeQuote,
      homeSafeDetails,
      homeSafeSelectedPlan,
      homeSafeSettings,
      disclaimer,
      isHomeSafeInProgress,
    } = this.props;
    const currencyOpts = {
      code: get(homeSafeSettings, 'currency.code'),
      precision: 0,
    };

    return this.state.isLoading ? (
      <div className="HomeSafePaymentView Container is-loading">
        <Spinner />
      </div>
    ) : (
      <div className="HomeSafePaymentView Container Container--small">
        <PaymentLayout
          title="Your Quote"
          policyDocText="View Key Information Document"
          policyDocUrl={homeSafeSelectedPlan.policy_doc_url}
          billingCycle="month"
          hideBillingSummary
          isPurchaseInProgress={isHomeSafeInProgress}
          currencyCode={homeSafeSettings.currency.code}
          pricePerCycle={get(homeSafeQuote, 'billing.grand_total')}
          acknowledgmentHtml={get(
            homeSafeSelectedPlan,
            'information.acknowledgment_html'
          )}
          totals={this.totals}
          onSubmit={this.handleSubmit}
        >
          <SectionWrapper title="Policy holder details">
            <p>
              {[homeSafeDetails.firstName, homeSafeDetails.lastName].join(' ')}
            </p>
            <p>{homeSafeDetails.streetAddress}</p>
            {homeSafeDetails.unit && <p>{homeSafeDetails.unit}</p>}
            <p>
              {[
                homeSafeDetails.city,
                homeSafeDetails.region,
                homeSafeDetails.postal,
              ].join(', ')}
            </p>
            <p>{homeSafeDetails.email}</p>
            <p>{homeSafeDetails.policyholderId}</p>
            <p>{homeSafeDetails.phone}</p>
          </SectionWrapper>

          {homeSafeDetails.hasBeneficiary && (
            <SectionWrapper title="Beneficiary details">
              <p>{homeSafeDetails.beneficiaryRelationship}</p>
              <p>
                {[
                  homeSafeDetails.beneficiaryFirstName,
                  homeSafeDetails.beneficiaryLastName,
                ].join(' ')}
              </p>
              <p>
                {homeSafeDetails.beneficiaryId ||
                  homeSafeDetails.beneficiaryBirthdate}
              </p>
              <p>{homeSafeDetails.beneficiaryEmail}</p>
              <p>{homeSafeDetails.beneficiaryPhone}</p>
            </SectionWrapper>
          )}

          <SectionWrapper
            title={`Plan details: ${get(
              homeSafeSelectedPlan,
              'information.title'
            )}`}
          >
            <LineItemsList rows={this.planDetailsRows} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList rows={[['Policies issued by', 'Chubb']]} />
            <LineItemsList rows={[['Billing frequency', 'Monthly']]} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList
              rows={[
                [
                  'Subtotal',
                  currencyFormat(this.totals.subtotal, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Tax',
                  currencyFormat(this.totals.tax, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Total',
                  <strong>
                    {currencyFormat(this.totals.grandtotal, {
                      ...currencyOpts,
                      precision: 2,
                    })}
                    /month
                  </strong>,
                ],
              ]}
            />
          </SectionWrapper>
        </PaymentLayout>
        <PlanDisclaimer
          showBuiltOnLogo
          style={{
            marginTop: '-40px',
          }}
          text={disclaimer}
        />
      </div>
    );
  }
}

HomeSafePaymentView.propTypes = propTypes;
HomeSafePaymentView.defaultProps = defaultProps;

export default HomeSafePaymentView;
