'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/BopApp/views/BopPlansView/BopPlansView.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

var _isBoolean = require('lodash/isBoolean');

var _isBoolean2 = _interopRequireDefault(_isBoolean);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _reactWaypoint = require('react-waypoint');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

require('./BopPlansView.css');

var _logoChubb = require('./logo-chubb.png');

var _logoChubb2 = _interopRequireDefault(_logoChubb);

var _bop_details = require('../../bop_details.json');

var _bop_details2 = _interopRequireDefault(_bop_details);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  rates: _propTypes2.default.shape({
    gold: _propTypes2.default.number,
    platinum: _propTypes2.default.number,
    silver: _propTypes2.default.number
  }),
  navTo: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  rates: {}
};

var BopPlansView = function (_Component) {
  _inherits(BopPlansView, _Component);

  function BopPlansView(props) {
    _classCallCheck(this, BopPlansView);

    var _this = _possibleConstructorReturn(this, (BopPlansView.__proto__ || Object.getPrototypeOf(BopPlansView)).call(this, props));

    _this.handleWaypointHeaderEnter = function () {
      _this.setState({
        isHeadingStuck: false
      });
    };

    _this.handleWaypointHeaderLeave = function () {
      _this.setState({
        isHeadingStuck: true
      });
    };

    _this.handleWaypointFooterEnter = function () {
      _this.setState({
        isFooterStuck: false
      });
    };

    _this.handleWaypointFooterLeave = function () {
      _this.setState({
        isFooterStuck: true
      });
    };

    _this.state = {
      isHeadingStuck: false,
      isFooterStuck: true
    };
    return _this;
  }

  _createClass(BopPlansView, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('BOP - Plans');
      analytics.track('Checkout Step Viewed', {
        category: 'order',
        step: 1
      });
    }
  }, {
    key: 'getRatePrice',
    value: function getRatePrice(planName) {
      var rate = this.props.rates[planName];

      if (rate) {
        var priceStr = _currencyFormatter2.default.format(rate, {
          code: 'USD'
        });

        return '' + priceStr;
      }
    }
  }, {
    key: 'selectPlan',
    value: function selectPlan(planCode) {
      var _this2 = this;

      analytics.track('BOP - Plan Selected');
      this.props.getCheckout(planCode).then(function () {
        _this2.props.navTo((0, _utils.resolveUrlPath)('payment'));
      }).catch(this.props.onError);
    }
  }, {
    key: 'columnsFor',
    value: function columnsFor(detailName) {
      var _this3 = this;

      return (0, _map2.default)(['silver', 'gold', 'platinum'], function (planCode) {
        var planDetails = _bop_details2.default[planCode][detailName];

        if ((0, _isBoolean2.default)(planDetails.title)) {
          return _react2.default.createElement('div', {
            className: 'BopPlansViewTable-row-content-col',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 72
            },
            __self: _this3
          }, _react2.default.createElement('span', {
            className: 'material-icons BopPlansViewTable-iconNegative',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 73
            },
            __self: _this3
          }, planDetails.title ? 'check' : 'close'));
        } else {
          return _react2.default.createElement('div', {
            className: 'BopPlansViewTable-row-content-col',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 80
            },
            __self: _this3
          }, _react2.default.createElement('h3', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 81
            },
            __self: _this3
          }, planDetails.title), planDetails.description && _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 82
            },
            __self: _this3
          }, planDetails.description));
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      return _react2.default.createElement('div', {
        className: 'BopPlansView Container',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 115
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 116
        },
        __self: this
      }, _react2.default.createElement(_reactWaypoint.Waypoint, {
        onEnter: this.handleWaypointHeaderEnter,
        onLeave: this.handleWaypointHeaderLeave,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 117
        },
        __self: this
      }), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-headingWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 121
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: (0, _classnames2.default)('BopPlansViewTable-row BopPlansViewTable-heading', {
          'is-stuck': this.state.isHeadingStuck
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 122
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 128
        },
        __self: this
      }, _react2.default.createElement('h1', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      }, 'You\'re almost done!'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 130
        },
        __self: this
      }, "We\u2019ve prepared personalized Small Business Insurance quotes that best meet your needs.")), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 135
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 136
        },
        __self: this
      }, _react2.default.createElement('h1', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 137
        },
        __self: this
      }, 'Silver'), _react2.default.createElement('h3', {
        className: 'BopPlansViewTable-price',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 138
        },
        __self: this
      }, this.getRatePrice('silver'), _react2.default.createElement('small', {
        className: 'BopPlansViewTable-price-period',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 140
        },
        __self: this
      }, '/year'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 145
        },
        __self: this
      }, _react2.default.createElement('h1', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 146
        },
        __self: this
      }, 'Gold'), _react2.default.createElement('h3', {
        className: 'BopPlansViewTable-price',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 147
        },
        __self: this
      }, this.getRatePrice('gold'), _react2.default.createElement('small', {
        className: 'BopPlansViewTable-price-period',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 149
        },
        __self: this
      }, '/year'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 154
        },
        __self: this
      }, _react2.default.createElement('h1', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 155
        },
        __self: this
      }, 'Platinum'), _react2.default.createElement('h3', {
        className: 'BopPlansViewTable-price',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 156
        },
        __self: this
      }, this.getRatePrice('platinum'), _react2.default.createElement('small', {
        className: 'BopPlansViewTable-price-period',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 158
        },
        __self: this
      }, '/year')))))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-body',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 166
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 167
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 168
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 169
        },
        __self: this
      }, 'General Liability'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 170
        },
        __self: this
      }, 'Protects you, your business and your employees from claims involving bodily injury or property damage')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 175
        },
        __self: this
      }, this.columnsFor('general_liability'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 180
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 181
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 182
        },
        __self: this
      }, 'Business Personal Property'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 183
        },
        __self: this
      }, 'Covers damage/loss of business-owned property (furniture, equipment, etc).')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 188
        },
        __self: this
      }, this.columnsFor('business_personal_property'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 193
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 194
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 195
        },
        __self: this
      }, 'Additional Insured'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 196
        },
        __self: this
      }, 'Provides additional insured status to the listed party. This saves you time and money by not having to add specific endorsements when additional insureds are required.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 202
        },
        __self: this
      }, this.columnsFor('additional_insured_coverage'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 207
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 208
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 209
        },
        __self: this
      }, 'Lost Business Income'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 210
        },
        __self: this
      }, 'Provides coverage for lost income if your business has to close as a result of a covered loss.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 215
        },
        __self: this
      }, this.columnsFor('lost_business_income'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 221
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 222
        },
        __self: this
      }, 'Data Breach: Privacy Liability'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 223
        },
        __self: this
      }, 'Provides protection due to cyber attack.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 225
        },
        __self: this
      }, this.columnsFor('data_breach_privacy_liability'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 230
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 231
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 232
        },
        __self: this
      }, 'Data Breach: Additional Costs'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 233
        },
        __self: this
      }, 'E.g. Law firm costs to review applicable privacy regulations, notification costs, and reimbursement of credit monitoring services.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 239
        },
        __self: this
      }, this.columnsFor('data_breach_additional_costs'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 244
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 245
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 246
        },
        __self: this
      }, 'Employee Liability Coverage'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 247
        },
        __self: this
      }, 'Protects your business in the event of a lawsuit for wrongful termination, harassment, etc.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 252
        },
        __self: this
      }, this.columnsFor('employee_liability_coverage'))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 257
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 258
        },
        __self: this
      }, _react2.default.createElement('h2', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 259
        },
        __self: this
      }, 'Hired and non-owned auto'), _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 260
        },
        __self: this
      }, 'Provides liability coverage if you or your employees use personal vehicles for your business on an incidental basis. This is not commercial auto coverage.')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 266
        },
        __self: this
      }, this.columnsFor('hired_and_non_owned_auto')))), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-footerWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 271
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: (0, _classnames2.default)('BopPlansViewTable-row BopPlansViewTable-footer', {
          'is-stuck': this.state.isFooterStuck
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 272
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-header',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 278
        },
        __self: this
      }, _react2.default.createElement('p', {
        className: 'BopPlansViewTable-underwriterHeading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 279
        },
        __self: this
      }, 'Policies underwritten by'), _react2.default.createElement('img', {
        className: 'BopPlansViewTable-logoChubb',
        src: _logoChubb2.default,
        alt: 'Chubb logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 282
        },
        __self: this
      })), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-footerBarWrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 288
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content BopPlansViewTable-footerBar',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 289
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 290
        },
        __self: this
      }, _react2.default.createElement(_Button2.default, {
        className: 'BopPlansViewTable-cta',
        disabled: this.props.isInProgress,
        onClick: function onClick() {
          return _this4.selectPlan('silver');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 291
        },
        __self: this
      }, 'Silver')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 299
        },
        __self: this
      }, _react2.default.createElement(_Button2.default, {
        className: 'BopPlansViewTable-cta',
        disabled: this.props.isInProgress,
        onClick: function onClick() {
          return _this4.selectPlan('gold');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 300
        },
        __self: this
      }, 'Gold')), _react2.default.createElement('div', {
        className: 'BopPlansViewTable-row-content-col',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 308
        },
        __self: this
      }, _react2.default.createElement(_Button2.default, {
        className: 'BopPlansViewTable-cta',
        disabled: this.props.isInProgress,
        onClick: function onClick() {
          return _this4.selectPlan('platinum');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 309
        },
        __self: this
      }, 'Platinum'))))))), _react2.default.createElement(_reactWaypoint.Waypoint, {
        bottomOffset: window.screen.width > 768 ? 0 : -115,
        onEnter: this.handleWaypointFooterEnter,
        onLeave: this.handleWaypointFooterLeave,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 322
        },
        __self: this
      }), _react2.default.createElement('div', {
        style: {
          padding: '0 10px',
          textAlign: 'center'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 327
        },
        __self: this
      }, _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 328
        },
        __self: this
      })));
    }
  }]);

  return BopPlansView;
}(_react.Component);

BopPlansView.propTypes = propTypes;
BopPlansView.defaultProps = defaultProps;
exports.default = BopPlansView;