'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SurveyManager/SurveyManager.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactTransitionGroup = require('react-transition-group');

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _sureComponents = require('sure-components');

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _SurveyQuestion = require('shared/lib/components/SurveyQuestion');

var _SurveyQuestion2 = _interopRequireDefault(_SurveyQuestion);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _values = require('lodash/values');

var _values2 = _interopRequireDefault(_values);

var _reject = require('lodash/reject');

var _reject2 = _interopRequireDefault(_reject);

var _every = require('lodash/every');

var _every2 = _interopRequireDefault(_every);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isArray = require('lodash/isArray');

var _isArray2 = _interopRequireDefault(_isArray);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _isNull = require('lodash/isNull');

var _isNull2 = _interopRequireDefault(_isNull);

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

var _isUndefined = require('lodash/isUndefined');

var _isUndefined2 = _interopRequireDefault(_isUndefined);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _pick = require('lodash/pick');

var _pick2 = _interopRequireDefault(_pick);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./SurveyManager.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  backToStart: _propTypes2.default.func,
  disclaimer: _propTypes2.default.string,
  currentResponse: _propTypes2.default.object,
  fields: _propTypes2.default.array,
  flags: _propTypes2.default.array,
  fullName: _propTypes2.default.string,
  helpText: _propTypes2.default.string,
  isFirstScreen: _propTypes2.default.bool,
  isLastScreen: _propTypes2.default.bool,
  propertyAddress: _propTypes2.default.object,
  question: _propTypes2.default.string,
  responses: _propTypes2.default.object,
  screenId: _propTypes2.default.string,
  surveyBack: _propTypes2.default.func,
  surveyNext: _propTypes2.default.func,
  onCompleteSurvey: _propTypes2.default.func,
  surveyType: _propTypes2.default.string,
  updateCurrentResponse: _propTypes2.default.func
};
var defaultProps = {
  backToStart: function backToStart() {},
  disclaimer: null,
  currentResponse: {},
  fields: [],
  flags: [],
  fullName: '',
  helpText: '',
  isFirstScreen: false,
  isLastScreen: false,
  propertyAddress: {},
  question: '',
  responses: {},
  screenId: '',
  surveyBack: function surveyBack() {},
  surveyNext: function surveyNext() {},
  surveyType: 'Generic',
  updateCurrentResponse: function updateCurrentResponse() {}
};

var SurveyManager = function (_Component) {
  _inherits(SurveyManager, _Component);

  function SurveyManager(props) {
    _classCallCheck(this, SurveyManager);

    var _this = _possibleConstructorReturn(this, (SurveyManager.__proto__ || Object.getPrototypeOf(SurveyManager)).call(this, props));

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.track(_this.props.surveyType + ' - Next Button Clicked');

      if (!_this.isNextDisabled() && _this.formNode.checkValidity()) {
        var fieldKeys = (0, _map2.default)(_this.props.fields, 'key_name');
        var existingResponses = (0, _pick2.default)(_this.props.responses, fieldKeys);
        var responses = Object.assign(existingResponses, _this.props.currentResponse);
        (0, _each2.default)(responses, function (val, key) {
          var field = (0, _find2.default)(_this.props.fields, {
            key_name: key
          });

          if (field && field.field_type === 'list' && field.subfields.length) {
            responses[key] = (0, _reject2.default)(val, function (obj) {
              return (0, _isEmpty2.default)((0, _compact2.default)((0, _values2.default)(obj)));
            });
          }
        });

        _this.props.saveResponse(responses).then(function () {
          return _this.surveyQuestionNode.validate();
        }).then(function () {
          if (_this.props.isLastScreen) {
            _this.props.onCompleteSurvey();
          } else {
            _this.props.surveyNext();
          }
        }).catch(function (_ref) {
          var title = _ref.title,
              message = _ref.message;

          _this.showModal({
            title: title,
            body: message
          });
        });
      } else {
        _this.formNode.reportValidity();
      }
    };

    _this.handleBack = function () {
      analytics.track(_this.props.surveyType + ' - Back Button Clicked', {
        category: 'survey'
      });
      var fieldKeys = (0, _map2.default)(_this.props.fields, 'key_name');

      _this.props.deleteResponse(fieldKeys);

      if (_this.props.isFirstScreen) {
        _this.props.backToStart();
      } else {
        _this.props.surveyBack();
      }
    };

    _this.showModal = function (_ref2) {
      var title = _ref2.title,
          body = _ref2.body;

      _this.setState({
        isModalActive: true,
        modalTitle: title,
        modalBody: body
      });
    };

    _this.hideModal = function () {
      _this.setState({
        isModalActive: false,
        modalTitle: null,
        modalBody: null
      });
    };

    _this.handleShowHelp = function () {
      _this.showModal({
        title: 'Help',
        body: _this.props.helpText
      });
    };

    _this.state = {
      isModalActive: false,
      modalTitle: null,
      modalBody: null
    };
    return _this;
  }

  _createClass(SurveyManager, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page(this.props.surveyType + ' - Dynamic - ' + this.props.question, {
        category: 'survey'
      });
      this.props.updateCurrentResponse();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.screenId !== this.props.screenId) {
        analytics.page(this.props.surveyType + ' - Dynamic - ' + this.props.question, {
          category: 'survey'
        });
      }
    }
  }, {
    key: 'isNextDisabled',
    value: function isNextDisabled() {
      var _this2 = this;

      var requiredFields = (0, _filter2.default)(this.props.fields, {
        required: true
      });
      var requiredFieldKeys = (0, _map2.default)(requiredFields, 'key_name');
      var isNextEnabled = (0, _every2.default)(requiredFieldKeys, function (key) {
        var field = (0, _find2.default)(requiredFields, {
          key_name: key
        });
        var response = _this2.props.currentResponse[key];

        if ((0, _isUndefined2.default)(response) || (0, _isNull2.default)(response)) {
          response = _this2.props.responses[key];
        }

        switch (field.field_type) {
          case 'list':
            return (0, _isArray2.default)(response) && (0, _every2.default)(response, function (_response) {
              if (_response && field.subfields && field.subfields.length) {
                var subfieldKeys = (0, _map2.default)((0, _filter2.default)(field.subfields, {
                  required: true
                }), 'key_name');
                var allKeysPresent = (0, _every2.default)(subfieldKeys, function (subfieldKey) {
                  return !(0, _isEmpty2.default)(_response[subfieldKey]);
                });
                var allKeysEmpty = (0, _every2.default)(subfieldKeys, function (subfieldKey) {
                  return (0, _isEmpty2.default)(_response[subfieldKey]);
                });
                return allKeysPresent || allKeysEmpty;
              } else {
                return !((0, _isUndefined2.default)(_response) || (0, _isNull2.default)(_response));
              }
            });

          default:
            return !(0, _isUndefined2.default)(response) && response !== null && (!(0, _isString2.default)(response) || response.length);
        }
      });
      return !isNextEnabled;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement('div', {
        className: 'SurveyManager',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 244
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 245
        },
        __self: this
      }, _react2.default.createElement('form', {
        className: 'SurveyManager-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        ref: function ref(c) {
          return _this3.formNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 246
        },
        __self: this
      }, _react2.default.createElement(_reactTransitionGroup.CSSTransitionGroup, {
        transitionName: 'SurveyManager--transition',
        transitionEnterTimeout: 500,
        transitionLeave: false,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 252
        },
        __self: this
      }, _react2.default.createElement(_SurveyQuestion2.default, {
        key: this.props.screenId,
        title: this.props.question,
        fields: this.props.fields,
        responses: Object.assign(this.props.responses, this.props.currentResponse),
        helpText: this.props.helpText,
        fullName: this.props.fullName,
        propertyAddress: this.props.propertyAddress,
        onShowHelp: this.handleShowHelp,
        onChange: this.props.updateCurrentResponse,
        ref: function ref(c) {
          return _this3.surveyQuestionNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 257
        },
        __self: this
      })), this.actionsNode, _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer || (0, _get2.default)(this.props.plan, 'information.bottom_text'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 274
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 280
        },
        __self: this
      }))), _react2.default.createElement(_sureComponents.Modal, {
        title: this.state.modalTitle,
        isActive: this.state.isModalActive,
        onDismiss: this.hideModal,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 283
        },
        __self: this
      }, _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 288
        },
        __self: this
      }, this.state.modalBody), _react2.default.createElement(_Button2.default, {
        onClick: this.hideModal,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 289
        },
        __self: this
      }, 'Ok')));
    }
  }, {
    key: 'actionsNode',
    get: function get() {
      if ((0, _includes2.default)(this.props.flags, 'reject_form_screen')) {
        return _react2.default.createElement(_Button2.default, {
          onClick: this.handleBack,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 230
          },
          __self: this
        }, 'Go Back');
      } else {
        return _react2.default.createElement(_sureComponents.PagerButtons, {
          onNext: this.handleNext,
          onBack: this.handleBack,
          isNextDisabled: this.isNextDisabled(),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 233
          },
          __self: this
        });
      }
    }
  }]);

  return SurveyManager;
}(_react.Component);

SurveyManager.propTypes = propTypes;
SurveyManager.defaultProps = defaultProps;
exports.default = SurveyManager;