import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router';
import { showMenu, hideMenu } from '../../store/actions';
import TopNavBar from '../../components/TopNavBar';
import compact from 'lodash/compact';
import startsWith from 'lodash/startsWith';
import map from 'lodash/map';
import omit from 'lodash/omit';
import './GlobalContainer.css';

class GlobalContainer extends Component {
  onToggleMenu = () => {
    if (this.props.isMenuOpen) {
      this.props.hideMenu();
    } else {
      this.props.showMenu();
    }
  };

  render() {
    const { isEmbed, isMenuOpen, settings, children, routes } = this.props;

    return (
      <div className="GlobalContainer">
        {!isEmbed &&
          compact(map(routes, 'path')).length > 2 && (
            <TopNavBar
              hasProgressBar={true}
              isMenuOpen={isMenuOpen}
              sideMenuLinks={settings.sideMenuLinks}
              logoLightUrl={settings.logoLightUrl}
              logoDarkUrl={settings.logoDarkUrl}
              title={settings.title}
              onToggleMenu={this.onToggleMenu}
            />
          )}
        {React.cloneElement(children, {
          ...omit(this.props, 'children'),
          onToggleMenu: this.onToggleMenu,
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isEmbed:
      startsWith(ownProps.router.getCurrentLocation().pathname, '/embed') ||
      state.isEmbed,
    isMenuOpen: state.isMenuOpen,
    partnerInfo: state.partnerInfo,
    settings: state.settings,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goBack,
      navTo: push,
      showMenu,
      hideMenu,
    },
    dispatch
  );

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(GlobalContainer);
