import axios from 'axios';
import {
  destructureAxiosReq,
  getDeviceInfo,
  trimValues,
} from 'shared/lib/utils';
import { purchase } from '../api';

/**
 * Get collectibles plans for an address.
 *
 * @param {Object} opts The options.
 * @param {String} opts.categoryCode The collectibles category code.
 * @param {Object} opts.propertyAddress The user property address.
 * @param {Object} opts.propertyAddress.streetAddress The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Number} opts.propertyAddress.lat The user's lat location coordinate.
 * @param {Number} opts.propertyAddress.lng The user's lng location coordinate.
 * @param {Object} opts.personalInfo The user's personal information.
 * @param {Object} opts.personalInfo.firstName The user's first name.
 * @param {Object} opts.personalInfo.lastName The user's last name.
 * @param {Object} opts.personalInfo.phone The user's phone number.
 * @param {Object} opts.personalInfo.email The user's email address.
 */
export function getCollectiblesPlans(opts) {
  const payload = trimValues({
    rating_address: {
      line1: opts.propertyAddress.streetAddress,
      line2: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      state_or_region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    first_name: opts.personalInfo.firstName,
    last_name: opts.personalInfo.lastName,
    phone_number: opts.personalInfo.phone,
    email: opts.personalInfo.email,
    category_code: opts.categoryCode,
    partner_id: window.SURE_PARTNER && window.SURE_PARTNER.client_id,
  });
  const browserEnv = Object.assign(
    {
      lat: opts.propertyAddress.lat,
      lng: opts.propertyAddress.lng,
    },
    getDeviceInfo()
  );

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/collectibles/plans`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(browserEnv),
        },
      }
    )
  );
}

/**
 * Retrieve collectibles rates.
 *
 * @param {Object} opts The options.
 * @param {Object} opts.propertyAddress The user's property address.
 * @param {Object} opts.propertyAddress.streetAddress The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {Number} opts.propertyAddress.lat The user's lat location coordinate.
 * @param {Number} opts.propertyAddress.lng The user's lng location coordinate.
 * @param {String} opts.planId The collectibles plan ID.
 * @param {String} [opts.quoteId] The collectibles quote ID (required for quote
 * recalculation).
 * @param {Moment} opts.policyEffectiveDate The policy start date.
 * @param {Object} opts.details The user's answers from the dynamic form.
 * @param {String} opts.details.property_special_city The city returned from
 * the QBE address verification response.
 * @param {String} opts.details.property_special_county The county returned
 * from the QBE address verification response.
 * @param {String} opts.details.owner_email The user's email (required for
 * logged out requests).
 */
export function getCollectiblesRates(opts) {
  const browserEnv = Object.assign(
    {
      lat: opts.propertyAddress.lat,
      lng: opts.propertyAddress.lng,
    },
    getDeviceInfo()
  );
  const payload = trimValues({
    rating_address: {
      line1: opts.propertyAddress.streetAddress,
      line2: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      state_or_region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    details: opts.details,
    settings: {
      policy_effective_date: opts.policyEffectiveDate,
      deductible: opts.deductible,
    },
    payment_frequency: opts.paymentFrequency,
  });

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/collectibles/rates`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(browserEnv),
        },
      }
    )
  );
}

/**
 * Retrieve a collectibles checkout model.
 *
 * @param {Object} opts The options.
 * @param {String} opts.categoryCode The collectibles category code.
 * @param {String} opts.paymentFrequency The payment frequency code.
 * @param {Number} opts.deductible The selected deductible amount.
 * @param {Object} opts.propertyAddress The user's property address.
 * @param {Object} opts.propertyAddress.streetAddress The street address.
 * @param {Object} [opts.propertyAddress.unit] The unit number.
 * @param {Object} opts.propertyAddress.city The city.
 * @param {Object} opts.propertyAddress.region The state or region.
 * @param {Object} opts.propertyAddress.country The country.
 * @param {Object} opts.propertyAddress.postal The postal code.
 * @param {Number} opts.propertyAddress.lat The user's lat location coordinate.
 * @param {Number} opts.propertyAddress.lng The user's lng location coordinate.
 * @param {String} opts.planId The collectibles plan ID.
 * @param {String} [opts.quoteId] The collectibles quote ID (required for quote
 * recalculation).
 * @param {String} opts.email The user's email.
 * @param {String} opts.phoneNumber The user's phone number.
 * @param {Object} opts.selectedRates An object of selected rates. E.g.
 * personal_property_coverage, all_peril_deductible, medical_coverage, etc.
 * @param {Moment} opts.policyEffectiveDate The policy start date.
 * @param {Object} opts.details The user's answers from the dynamic form.
 */
export function getCollectiblesCheckoutInfo(opts) {
  const browserEnv = Object.assign(
    {
      lat: opts.propertyAddress.lat,
      lng: opts.propertyAddress.lng,
    },
    getDeviceInfo()
  );
  const payload = trimValues({
    rating_address: {
      line1: opts.propertyAddress.streetAddress,
      line2: opts.propertyAddress.unit,
      city: opts.propertyAddress.city,
      state_or_region: opts.propertyAddress.region,
      postal: opts.propertyAddress.postal,
      country_code: opts.propertyAddress.country,
    },
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    first_name: opts.details.owner_first_name,
    last_name: opts.details.owner_last_name,
    phone_number: opts.phoneNumber,
    email: opts.email,
    details: {
      policy_effective_date: opts.policyEffectiveDate,
      category_code: opts.categoryCode,
      deductible: opts.deductible,
      ...opts.details,
    },
    payment_frequency: opts.paymentFrequency,
  });

  return new Promise((resolve, reject) => {
    destructureAxiosReq(
      axios.post(
        `${
          process.env.REACT_APP_SURE_API_BASE_URL
        }/api/partner/v1/protections/collectibles/checkout`,
        payload,
        {
          headers: {
            'X-Environment': JSON.stringify(browserEnv),
          },
        }
      )
    )
      .then(checkoutObj => {
        const normalizedCheckoutObj = {
          currency_code: checkoutObj.currency.code,
          paymentSchedule: checkoutObj.payment_schedule,
          quote: checkoutObj.quote,
          totals: {
            grandtotal: checkoutObj.grandtotal,
          },
        };
        resolve(normalizedCheckoutObj);
      })
      .catch(reject);
  });
}

/**
 * Purchase a collectibles policy.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.categoryCode The collectibles category code.
 * @param {String} opts.paymentFrequency The payment frequency code.
 * @param {Number} opts.deductible The selected deductible amount.
 * @param {String} opts.planId The collectibles plan ID.
 * @param {String} opts.quoteId The rentecollectibles rs quote ID.
 * @param {String} opts.policyEffectiveDate The policy start date.
 * @param {Object} opts.selectedRates The selected rates (deductible).
 * @param {Object} opts.details The survey responses.
 * @param {String} opts.streetAddress The user's street address.
 * @param {String} opts.unit The user's unit.
 * @param {String} opts.city The user's city.
 * @param {String} opts.region The user's state or region.
 * @param {String} opts.postal The user's postal code.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} opts.firstName The user's first name.
 * @param {String} opts.lastName The user's last name.
 * @param {String} opts.email The user's email address.
 * @param {String} opts.phoneNumber The user's email phone number.
 * @param {Object} opts.cardElement The Stripe card element.
 * @param {Number} opts.revenue The total revenue of the purchase.
 * @param {Object} [opts.metadata] Any metadata properties to attach.
 */
export function purchaseCollectibles(opts) {
  const payload = {
    plan_id: opts.planId,
    quote_id: opts.quoteId,
    payment_frequency: opts.paymentFrequency,
    details: {
      policy_effective_date: opts.policyEffectiveDate,
      category_code: opts.categoryCode,
      deductible: opts.deductible,
      ...opts.details,
    },
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
    first_name: opts.firstName,
    last_name: opts.lastName,
    phone_number: opts.phoneNumber,
    email: opts.email,
    metadata: opts.metadata,
  };

  return purchase({
    planId: opts.planId,
    firstName: opts.firstName,
    lastName: opts.lastName,
    email: opts.email,
    phoneNumber: opts.phoneNumber,
    authToken: opts.authToken,
    cardElement: opts.cardElement,
    revenue: opts.revenue,
    payload,
    prefix: 'Collectibles',
    endpoint: '/api/partner/v1/protections/collectibles/purchase',
  });
}
