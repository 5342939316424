import ACTION_TYPES from '../../constants/actionTypes';

export default {
  beneficiaryRelationships(state = [], action) {
    switch (action.type) {
      case ACTION_TYPES.GET_BENEFICIARY_RELATIONSHIPS_SUCCESS:
        return action.payload.beneficiaryRelationships;
      default:
        return state;
    }
  },
};
