import { destructureAxiosReq } from 'shared/lib/utils';
import { purchase } from '../api';
import axios from 'axios';

/**
 * Get CarSafe plans.
 *
 * @param {Object} opts The options.
 * @param {String} opts.authToken The Sure API auth token.
 * @param {String} opts.countryCode The country code.
 */
export function getCarSafePlans({ countryCode, authToken }) {
  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/carsafe/plans`,
      {
        country_code: countryCode,
        region: countryCode,
      }
    )
  );
}

/**
 * Get CarSafe checkout.
 *
 * @param {Object} opts The options.
 * @param {String} opts.authToken The Sure API auth token.
 * @param {String} opts.firstName The primary user's first name.
 * @param {String} opts.lastName The primary user's last name.
 * @param {String} opts.email The primary user's email.
 * @param {String} opts.phone The primary user's phone number.
 * @param {String} opts.policyholderId The primary user's national ID number.
 * @param {Boolean} opts.hasBeneficiary Whether to include a beneficiary.
 * @param {String} [opts.beneficiaryFirstName] The beneficiary's first name.
 * @param {String} [opts.beneficiaryLastName] The beneficiary's last name.
 * @param {String} [opts.beneficiaryEmail] The beneficiary's email.
 * @param {String} [opts.beneficiaryPhone] The beneficiary's phone number.
 * @param {String} [opts.beneficiaryRelationship] The beneficiary's relationship to the primary user.
 * @param {String} [opts.beneficiaryDob] The beneficiary's DOB. Must include beneficiaryDob OR beneficiaryID.
 * @param {String} [opts.beneficiaryId] The beneficiary's national ID number. Must include beneficiaryDob OR beneficiaryId.
 * @param {String} opts.planId The selected plan ID.
 * @param {Object} opts.streetAddress The street address.
 * @param {Object} [opts.unit] The unit number.
 * @param {Object} opts.city The city.
 * @param {Object} opts.region The state or region.
 * @param {Object} opts.postal The postal code.
 * @param {Object} opts.country The country.
 */
export function getCarSafeCheckout(opts = {}) {
  const payload = {
    details: {
      first_name: opts.firstName,
      last_name: opts.lastName,
      email: opts.email,
      phone_number: opts.phone,
      nationality_number: opts.policyholderId,
    },
    plan_id: opts.planId,
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
  };

  if (opts.hasBeneficiary) {
    payload.beneficiary = {
      first_name: opts.beneficiaryFirstName,
      last_name: opts.beneficiaryLastName,
      email: opts.beneficiaryEmail,
      phone_number: opts.beneficiaryPhone,
      relationship_description: opts.beneficiaryRelationship,
      nationality_number: opts.beneficiaryId,
      dob: opts.beneficiaryDob,
    };
  }

  return axios.post(
    `${
      process.env.REACT_APP_SURE_API_BASE_URL
    }/api/partner/v1/protections/carsafe/checkout`,
    payload
  );
}

/**
 * Purchase a carsafe policy.
 *
 * @param {Object} opts The options.
 * @param {String} opts.authToken The Sure API auth token.
 * @param {String} opts.firstName The primary user's first name.
 * @param {String} opts.lastName The primary user's last name.
 * @param {String} opts.email The primary user's email.
 * @param {String} opts.phone The primary user's phone number.
 * @param {String} opts.policyholderId The primary user's national ID number.
 * @param {Boolean} opts.hasBeneficiary Whether to include a beneficiary.
 * @param {String} [opts.beneficiaryFirstName] The beneficiary's first name.
 * @param {String} [opts.beneficiaryLastName] The beneficiary's last name.
 * @param {String} [opts.beneficiaryEmail] The beneficiary's email.
 * @param {String} [opts.beneficiaryPhone] The beneficiary's phone number.
 * @param {String} [opts.beneficiaryRelationship] The beneficiary's relationship to the primary user.
 * @param {String} [opts.beneficiaryDob] The beneficiary's DOB. Must include beneficiaryDob OR beneficiaryID.
 * @param {String} [opts.beneficiaryId] The beneficiary's national ID number. Must include beneficiaryDob OR beneficiaryId.
 * @param {String} opts.planId The selected plan ID.
 * @param {Object} opts.streetAddress The street address.
 * @param {Object} [opts.unit] The unit number.
 * @param {Object} opts.city The city.
 * @param {Object} opts.region The state or region.
 * @param {Object} opts.postal The postal code.
 * @param {Object} opts.country The country.
 * @param {String} opts.planId The selected plan ID.
 * @param {Object} opts.cardElement The Stripe card element.
 * @param {String} opts.cardName The cardholder name.
 * @param {Number} opts.revenue The total revenue of the purchase.
 * @param {Object} [opts.metadata] Any metadata properties to attach.
 */
export function purchaseCarSafe(opts) {
  const payload = {
    details: {
      first_name: opts.firstName,
      last_name: opts.lastName,
      email: opts.email,
      phone_number: opts.phone,
      nationality_number: opts.policyholderId,
    },
    plan_id: opts.planId,
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
    metadata: opts.metadata,
  };

  if (opts.hasBeneficiary) {
    payload.beneficiary = {
      first_name: opts.beneficiaryFirstName,
      last_name: opts.beneficiaryLastName,
      email: opts.beneficiaryEmail,
      phone_number: opts.beneficiaryPhone,
      relationship_description: opts.beneficiaryRelationship,
      nationality_number: opts.beneficiaryId,
      dob: opts.beneficiaryDob,
    };
  }

  return purchase({
    planId: opts.planId,
    firstName: opts.firstName,
    lastName: opts.lastName,
    email: opts.email,
    authToken: opts.authToken,
    cardElement: opts.cardElement,
    cardName: opts.cardName,
    revenue: opts.revenue,
    payload,
    prefix: 'CarSafe',
    endpoint: '/api/partner/v1/protections/carsafe/purchase',
  });
}
