import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import Spinner from 'shared/lib/components/Spinner';
import ChubbPlansTable from '../ChubbPlansTable';
import * as analytics from 'shared/lib/analytics';
import './HomeSafePlansView.css';

const propTypes = {
  createHomeSafeQuotee: PropTypes.func,
  getHomeSafePlans: PropTypes.func,
  homeSafePlans: PropTypes.array,
  homeSafeSelectedPlan: PropTypes.object,
  homeSafeSettings: PropTypes.object,
};

const defaultProps = {
  homeSafePlans: [],
  homeSafeSelectedPlan: {},
  homeSafeSettings: {
    currency: {
      code: 'ZAR',
      symbol: 'R',
    },
  },
};

class HomeSafePlansView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    analytics.page('HomeSafe - Plans');
    this.setState({ isLoading: true });
    this.props
      .createHomeSafeQuote('ZA')
      .then(this.props.updateHomeSafeQuote)
      .then(this.props.getHomeSafePlans)
      .then(() => {
        analytics.track('HomeSafe - Plans Loaded');
        this.setState({ isLoading: false });
      })
      .catch(error => {
        analytics.track('HomeSafe - Plans Loading Failed');
        this.setState({ isLoading: false });
        this.props.onError(error);
      });
  }

  handleSelect = planId => {
    const { selectHomeSafePlan, homeSafePlans, navTo } = this.props;
    analytics.track('HomeSafe - Plan Selected', {
      label: planId,
    });
    selectHomeSafePlan(planId);

    const plan = find(homeSafePlans, { plan_id: planId });
    const planAddOns = get(plan, 'details.plan_add_ons');
    if (isEmpty(planAddOns)) {
      navTo('/residential-insurance/policy-holder');
    } else {
      navTo('/residential-insurance/additional-benefits');
    }
  };

  render() {
    const { homeSafePlans, homeSafeSettings, disclaimer } = this.props;
    const plansTableHeaders = [
      {
        key: 'home_burglary',
        content: 'Home Burglary',
      },
      {
        key: 'home_add',
        content: 'Home ADD',
      },
    ];

    return this.state.isLoading ? (
      <div className="HomeSafePlansView Container is-loading">
        <Spinner />
        Getting available plans...
      </div>
    ) : (
      <div className="HomeSafePlansView Container">
        <h1 className="HomeSafePlansView-heading">
          Select a Residential Insurance plan below:
        </h1>
        <ChubbPlansTable
          headers={plansTableHeaders}
          plans={homeSafePlans}
          currencyCode={get(homeSafeSettings, 'currency.code')}
          onSelect={this.handleSelect}
        />
        <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
      </div>
    );
  }
}

HomeSafePlansView.propTypes = propTypes;
HomeSafePlansView.defaultProps = defaultProps;

export default HomeSafePlansView;
