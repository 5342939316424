import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as analytics from 'shared/lib/analytics';
import get from 'lodash/get';
import PaymentLayout from 'shared/lib/components/PaymentLayout';
import LineItemsList from 'shared/lib/components/LineItemsList';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import SectionWrapper from 'shared/lib/components/SectionWrapper';
import Spinner from 'shared/lib/components/Spinner';
import { suspendPromise } from 'shared/lib/utils';
import { format as currencyFormat } from 'currency-formatter';
import './CarSafePaymentView.css';

const propTypes = {
  carSafeCheckout: PropTypes.object,
  carSafeSelectedPlan: PropTypes.object,
  carSafeSettings: PropTypes.object,
  disclaimer: PropTypes.string,
  getCarSafeCheckout: PropTypes.func,
  isCarSafeInProgress: PropTypes.bool,
  purchaseCarSafe: PropTypes.func,
};

const defaultProps = {
  carSafeCheckout: {},
  carSafeSelectedPlan: {},
  carSafeSettings: {},
  disclaimer: null,
  getCarSafeCheckout: () => {},
  isCarSafeInProgress: false,
  purchaseCarSafe: () => {},
};

class CarSafePaymentView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    analytics.page('CarSafe - Payment');
    this.setState({ isLoading: true });
    suspendPromise(this.props.getCarSafeCheckout(), 1000)
      .then(() => {
        analytics.page('CarSafe - Checkout Loaded');
        this.setState({ isLoading: false });
      })
      .catch(error => {
        analytics.page('CarSafe - Checkout Loading Failed');
        console.error('error', error);
        this.setState({ isLoading: false });
        this.props.onError(error);
      });
  }

  handleSubmit = elements => {
    analytics.page('CarSafe - Checkout Submitted');
    this.props
      .purchaseCarSafe(elements)
      .then(() => {
        this.props.navTo('/vehicle-contents-insurance/confirmation');
      })
      .catch(this.props.onError);
  };

  get totals() {
    const total = get(
      this.props.carSafeCheckout,
      'payment_schedule.schedule[0].amount'
    );
    return {
      subtotal: total,
      tax: 0,
      grandtotal: total,
    };
  }

  render() {
    const {
      carSafeCheckout,
      carSafeDetails,
      carSafeSelectedPlan,
      carSafeSettings,
      disclaimer,
      isCarSafeInProgress,
    } = this.props;
    const currencyOpts = {
      code: get(carSafeSettings, 'currency.code'),
      precision: 0,
    };

    return this.state.isLoading ? (
      <div className="CarSafePaymentView Container is-loading">
        <Spinner />
      </div>
    ) : (
      <div className="CarSafePaymentView Container Container--small">
        <PaymentLayout
          title="Your Quote"
          policyDocText="View Key Information Document"
          policyDocUrl={carSafeSelectedPlan.policy_doc_url}
          billingCycle="month"
          hideBillingSummary
          isPurchaseInProgress={isCarSafeInProgress}
          currencyCode={carSafeCheckout.currency_code}
          pricePerCycle={get(
            carSafeCheckout,
            'payment_schedule.schedule[0].amount'
          )}
          acknowledgmentHtml={get(
            carSafeSelectedPlan,
            'information.acknowledgment_html'
          )}
          totals={this.totals}
          extraCCFields
          onSubmit={this.handleSubmit}
        >
          <SectionWrapper title="Policy holder details">
            <p>
              {[carSafeDetails.firstName, carSafeDetails.lastName].join(' ')}
            </p>
            <p>{carSafeDetails.streetAddress}</p>
            {carSafeDetails.unit && <p>{carSafeDetails.unit}</p>}
            <p>
              {[
                carSafeDetails.city,
                carSafeDetails.region,
                carSafeDetails.postal,
              ].join(', ')}
            </p>
            <p>{carSafeDetails.email}</p>
            <p>{carSafeDetails.policyholderId}</p>
            <p>{carSafeDetails.phone}</p>
          </SectionWrapper>

          {carSafeDetails.hasBeneficiary && (
            <SectionWrapper title="Beneficiary details">
              <p>{carSafeDetails.beneficiaryRelationship}</p>
              <p>
                {[
                  carSafeDetails.beneficiaryFirstName,
                  carSafeDetails.beneficiaryLastName,
                ].join(' ')}
              </p>
              <p>
                {carSafeDetails.beneficiaryId ||
                  carSafeDetails.beneficiaryBirthdate}
              </p>
              <p>{carSafeDetails.beneficiaryEmail}</p>
              <p>{carSafeDetails.beneficiaryPhone}</p>
            </SectionWrapper>
          )}

          <SectionWrapper
            title={`Plan details: ${get(
              carSafeSelectedPlan,
              'information.title'
            )}`}
          >
            <LineItemsList
              rows={[
                [
                  'Personal Possesion Theft (Wallet, handbag, etc.)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .personal_possession_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Electronics Theft (Laptop, phone, etc.)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .electronics_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Home Lock and Key Replacement',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts
                      .home_lock_and_key,
                    currencyOpts
                  ),
                ],
                [
                  'Identity Theft',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts.identity_theft,
                    currencyOpts
                  ),
                ],
                [
                  'Accidental Death, Dismemberment & Disability (Motor vehicle accident only)',
                  currencyFormat(
                    carSafeSelectedPlan.details.coverage_amounts.accidental_ddd,
                    currencyOpts
                  ),
                ],
              ]}
            />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList rows={[['Policies issued by', 'Chubb']]} />
            <LineItemsList rows={[['Billing frequency', 'Monthly']]} />
          </SectionWrapper>

          <SectionWrapper>
            <LineItemsList
              rows={[
                [
                  'Subtotal',
                  currencyFormat(this.totals.subtotal, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Tax',
                  currencyFormat(this.totals.tax, {
                    ...currencyOpts,
                    precision: 2,
                  }),
                ],
                [
                  'Total',
                  <strong>
                    {currencyFormat(this.totals.grandtotal, {
                      ...currencyOpts,
                      precision: 2,
                    })}
                    /month
                  </strong>,
                ],
              ]}
            />
          </SectionWrapper>
        </PaymentLayout>
        <PlanDisclaimer
          showBuiltOnLogo
          style={{
            marginTop: '-40px',
          }}
          text={disclaimer}
        />
      </div>
    );
  }
}

CarSafePaymentView.propTypes = propTypes;
CarSafePaymentView.defaultProps = defaultProps;

export default CarSafePaymentView;
