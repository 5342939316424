'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersPropertyInfo/RentersPropertyInfo.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _sureComponents = require('sure-components');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AddressForm = require('shared/lib/components/AddressForm');

var _AddressForm2 = _interopRequireDefault(_AddressForm);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _cleaveReact = require('cleave.js/dist/cleave-react');

var _cleaveReact2 = _interopRequireDefault(_cleaveReact);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

var _utils = require('shared/lib/utils');

require('./RentersPropertyInfo.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object,
  activeModal: _propTypes2.default.string,
  isRentersInProgress: _propTypes2.default.bool,
  propertyAddress: _propTypes2.default.object,
  responses: _propTypes2.default.object,
  saveResponse: _propTypes2.default.func,
  submitRentersAddress: _propTypes2.default.func,
  updateRentersAddress: _propTypes2.default.func,
  zipCodeResults: _propTypes2.default.array
};
var defaultProps = {
  account: {},
  activeModal: null,
  isRentersInProgress: false,
  zipCodeResults: [],
  propertyAddress: {},
  responses: {}
};

var RentersPropertyInfo = function (_Component) {
  _inherits(RentersPropertyInfo, _Component);

  function RentersPropertyInfo(props) {
    _classCallCheck(this, RentersPropertyInfo);

    var _this = _possibleConstructorReturn(this, (RentersPropertyInfo.__proto__ || Object.getPrototypeOf(RentersPropertyInfo)).call(this, props));

    _this.onCleaveInit = function (cleave) {
      cleave.setRawValue(_this.onCleaveInitVal);
    };

    _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.identify({
        email: _this.props.responses.pni_email
      });

      _this.setState({
        isSubmitting: true
      });

      var _this$props = _this.props,
          navTo = _this$props.navTo,
          onError = _this$props.onError,
          submitRentersAddress = _this$props.submitRentersAddress,
          updateRentersAddress = _this$props.updateRentersAddress;

      _this.residenceAddressComponent.getGeocodedValue().then(function (location) {
        updateRentersAddress({
          lat: location.lat,
          lng: location.lng,
          country: location.country,
          postal: location.postal
        });
        return submitRentersAddress();
      }).then(function (response) {
        analytics.track('Renters - Property Address Submitted');

        if ((0, _isEmpty2.default)(response.zip_code_results)) {
          navTo((0, _utils.resolveUrlPath)('survey'));
        } else if (response.zip_code_results.locations.length > 1) {
          _this.showAddressValidationModal();

          analytics.track('Renters - Verify Address', {
            category: 'user',
            value: response.zip_code_results.locations.length
          });
        } else {
          _this.handleAddressValidation(response.zip_code_results.locations[0], 0, true);

          navTo((0, _utils.resolveUrlPath)('survey'));
        }
      }).catch(function (error) {
        _this.setState({
          isSubmitting: false
        });

        onError(error);
      });
    };

    _this.handleInputChange = function (e) {
      if (e.target.name) {
        _this.props.saveResponse(_defineProperty({}, e.target.name, e.target.value));
      }
    };

    _this.showAddressValidationModal = function () {
      _this.setState({
        isAddressValidationModalActive: true,
        isSubmitting: false
      });
    };

    _this.hideAddressValidationModal = function () {
      _this.setState({
        isAddressValidationModalActive: false,
        isSubmitting: false
      });
    };

    _this.state = {
      isSubmitting: false,
      isAddressValidationModalActive: false
    };
    return _this;
  }

  _createClass(RentersPropertyInfo, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (!this.props.isEmbed && !(0, _get2.default)(this.props.location.state, 'fromLanding')) {
        this.props.resetRentersData();
        this.onCleaveInitVal = '';
      } else {
        this.onCleaveInitVal = this.props.responses.pni_phone_number || '';
      }
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Renters - Current Property Address', {
        category: 'survey'
      });
      analytics.track('Renters - Current Property Address Viewed');

      if (this.props.isLoggedIn) {
        this.props.saveResponse({
          pni_first_name: this.props.account.firstName,
          pni_last_name: this.props.account.lastName,
          pni_phone_number: this.props.account.phoneNumber.slice(-10),
          pni_email: this.props.account.email
        });
      }
    }
  }, {
    key: 'handleAddressValidation',
    value: function handleAddressValidation(location, index) {
      var autoSelected = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (!autoSelected) {
        analytics.track('Renters - Address Verified', {
          category: 'user',
          value: index
        });
      }

      this.props.updateRentersAddress(location);
      this.props.hideGlobalModal();
      this.props.navTo((0, _utils.resolveUrlPath)('survey'));
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          disclaimer = _props.disclaimer,
          isRentersInProgress = _props.isRentersInProgress,
          propertyAddress = _props.propertyAddress,
          responses = _props.responses,
          updateRentersAddress = _props.updateRentersAddress;
      return _react2.default.createElement('div', {
        className: 'RentersPropertyInfo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 201
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'RentersPropertyInfo-intro',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 202
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 203
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'RentersPropertyInfo-intro-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 204
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: 'RentersPropertyInfo-intro-icon-icon material-icons',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 205
        },
        __self: this
      }, 'location_city')), _react2.default.createElement('h1', {
        className: 'RentersPropertyInfo-intro-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 209
        },
        __self: this
      }, 'Renters Insurance'), _react2.default.createElement('p', {
        className: 'RentersPropertyInfo-intro-info',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 212
        },
        __self: this
      }, 'Tell us about you and your place. Earn discounts based on your details. Get your quote, then get your policy.'))), _react2.default.createElement('div', {
        className: 'RentersPropertyInfo-address',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 218
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 219
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'RentersPropertyInfo-address-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      }, 'What is your address and personal information?'), _react2.default.createElement('form', {
        className: 'RentersPropertyInfo-address-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 223
        },
        __self: this
      }, _react2.default.createElement(_AddressForm2.default, {
        streetAddress: propertyAddress.streetAddress,
        unit: propertyAddress.unit,
        city: propertyAddress.city,
        region: propertyAddress.region,
        postal: propertyAddress.postal,
        country: propertyAddress.country,
        line1CharLimit: 30,
        ref: function ref(c) {
          return _this2.residenceAddressComponent = c;
        },
        onChange: updateRentersAddress,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 227
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.FormGroup, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 238
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.TextInput, {
        name: 'pni_first_name',
        placeholder: 'First Name',
        required: true,
        value: responses.pni_first_name || '',
        maxLength: '20',
        pattern: '[a-zA-Z ]+',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 239
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'pni_middle_name',
        placeholder: 'Middle Name (optional)',
        value: responses.pni_middle_name || '',
        maxLength: '20',
        pattern: '[a-zA-Z ]*',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 248
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'pni_last_name',
        placeholder: 'Last Name',
        required: true,
        value: responses.pni_last_name || '',
        maxLength: '20',
        pattern: '[a-zA-Z ]+',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 256
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.Select, {
        name: 'pni_suffix',
        value: responses.pni_suffix || '',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 265
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: '',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 270
        },
        __self: this
      }, 'Suffix (optional)'), _react2.default.createElement('option', {
        value: 'Jr.',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 271
        },
        __self: this
      }, 'Jr.'), _react2.default.createElement('option', {
        value: 'Sr.',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 272
        },
        __self: this
      }, 'Sr.'), _react2.default.createElement('option', {
        value: 'II',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 273
        },
        __self: this
      }, 'II'), _react2.default.createElement('option', {
        value: 'III',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 274
        },
        __self: this
      }, 'III'), _react2.default.createElement('option', {
        value: 'IV',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 275
        },
        __self: this
      }, 'IV'), _react2.default.createElement('option', {
        value: 'V',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 276
        },
        __self: this
      }, 'V'), _react2.default.createElement('option', {
        value: 'VI',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 277
        },
        __self: this
      }, 'VI'), _react2.default.createElement('option', {
        value: 'VII',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 278
        },
        __self: this
      }, 'VII'), _react2.default.createElement('option', {
        value: 'VIII',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 279
        },
        __self: this
      }, 'VIII'), _react2.default.createElement('option', {
        value: 'IX',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 280
        },
        __self: this
      }, 'IX'), _react2.default.createElement('option', {
        value: 'X',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 281
        },
        __self: this
      }, 'X')), _react2.default.createElement(_cleaveReact2.default, {
        className: 'SureTextInput',
        name: 'pni_phone_number',
        placeholder: 'Phone Number (e.g. 555-555-5555)',
        required: true,
        onInit: this.onCleaveInit,
        options: {
          blocks: [3, 3, 4],
          delimiter: '-',
          numericOnly: true
        },
        style: {
          borderRadius: '0',
          margin: '0',
          borderTop: '0'
        },
        value: responses.pni_phone_number,
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 283
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        type: 'email',
        name: 'pni_email',
        placeholder: 'Email Address',
        required: true,
        value: responses.pni_email || '',
        maxLength: '50',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 298
        },
        __self: this
      })), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        ref: function ref(c) {
          return _this2.inputSubmit = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 308
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        onNext: function onNext() {
          return _this2.inputSubmit.click();
        },
        isNextDisabled: this.state.isSubmitting,
        isBackDisabled: true,
        isInProgress: isRentersInProgress || this.state.isSubmitting,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 314
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 320
        },
        __self: this
      }))), this.state.isAddressValidationModalActive && _react2.default.createElement(_sureComponents.Modal, {
        className: 'RentersPropertyInfo-modal',
        title: 'Verify Address',
        isActive: true,
        onDismiss: this.hideAddressValidationModal,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 324
        },
        __self: this
      }, _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 330
        },
        __self: this
      }, "We\u2019ve found multiple addresses that fit the information you\u2019ve provided. Please confirm your residence."), _react2.default.createElement('form', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 334
        },
        __self: this
      }, this.modalLocationsNode)));
    }
  }, {
    key: 'modalLocationsNode',
    get: function get() {
      var _this3 = this;

      return this.props.zipCodeResults.map(function (location, i) {
        var radioTitle = (0, _compact2.default)([_this3.props.propertyAddress.streetAddress, _this3.props.propertyAddress.unit, location.city, _this3.props.propertyAddress.region, _this3.props.propertyAddress.postal, location.county]).join(' ');
        return _react2.default.createElement('div', {
          key: i,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 178
          },
          __self: _this3
        }, _react2.default.createElement('label', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 179
          },
          __self: _this3
        }, 'Option ', i + 1), _react2.default.createElement(_sureComponents.RadioButton, {
          name: 'location',
          value: location.city + location.county,
          title: radioTitle,
          onChange: _this3.handleAddressValidation.bind(_this3, location, i),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 180
          },
          __self: _this3
        }));
      });
    }
  }]);

  return RentersPropertyInfo;
}(_react.Component);

RentersPropertyInfo.propTypes = propTypes;
RentersPropertyInfo.defaultProps = defaultProps;
exports.default = RentersPropertyInfo;