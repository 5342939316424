import React, { Component } from 'react';
import Button from 'shared/lib/components/Button';
import startCase from 'lodash/startCase';
import endsWith from 'lodash/endsWith';
import get from 'lodash/get';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
import TopNavBar from '../TopNavBar';
import Footer from '../Footer';
import LandingBlockThreeUp from 'shared/lib/components/LandingBlockThreeUp';
import LandingBlockPartner from 'shared/lib/components/LandingBlockPartner';
import LandingBlockPriceComparison from 'shared/lib/components/LandingBlockPriceComparison';
import LandingBlockCTABanner from 'shared/lib/components/LandingBlockCTABanner';
import seo from 'shared/lib/seo';
import * as analytics from 'shared/lib/analytics';
import imageMarkelLogo from './logo-markel.png';
import { ReactComponent as JewelryIcon } from './icon-jewelry.svg';
import './LandingCollectibles.css';

const propTypes = {
  categoryCode: PropTypes.string,
  categorySlug: PropTypes.string,
  categoryConfig: PropTypes.shape({
    title: PropTypes.string,
    threeUps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
      })
    ),
  }),
};

const hagertyDisclaimer = `*This is a general description of coverage. 
All coverage is subject to policy provisions and availability by state. 
Hagerty Insurance Agency, LLC is licensed in all 50 states and the District of Columbia. 
The purpose of this notice is to provide you with information about how Hagerty is compensated. 
Hagerty receives a commission based upon a fixed percentage of each policyholder’s insurance premium. 
The commission percentage varies according to the insurer and the product sold by Hagerty. 
The commission is paid by the insurance company to Hagerty each policy term for both new policies and renewals. 
Hagerty may also receive additional compensation from insurance companies based upon a number of factors, 
including, without limitation, growth, profitability, volume of business, and retention. 
This additional income is not guaranteed. If you would like to know more about the compensation we 
expect to receive as it relates to your policy, you may email auto@hagerty.com or call 800-922-4050.`;

class LandingCollectibles extends Component {
  componentDidMount() {
    const {
      categoryCode,
      categoryConfig,
      categorySlug,
      partnerInfo,
    } = this.props;

    let canonicalUrl = `https://${
      window.location.hostname
    }/collectibles/${categorySlug}`;
    if (categorySlug === 'other-insurance') {
      canonicalUrl = `https://${
        window.location.hostname
      }/collectible-insurance`;
    }
    const { title } = categoryConfig;
    const heroImgUrl = `${
      process.env.PUBLIC_URL
    }/images/collectibles/landing-${kebabCase(this.props.categoryCode)}.jpg`;
    seo.setProps({
      title: `${startCase(title)} Insurance | ${partnerInfo.name}`,
      description: `Get ${startCase(title)} Insurance through ${
        partnerInfo.name
      } to protect your treasures.`,
      url: canonicalUrl,
      image: `https://${window.location.hostname}${heroImgUrl}`,
    });
    analytics.page('Collectibles - Landing', {
      categoryCode,
    });
    analytics.track('Collectibles - Landing Page Viewed', {
      categoryCode,
      noninteraction: 1,
    });
    if (
      startsWith(
        get(this.props.notificationMessage, 'title'),
        'Exclusion Notice'
      )
    ) {
      this.props.hideCollectiblesNotificationBar();
    }
  }

  get conjunction() {
    if (endsWith(this.props.categoryConfig.title, 's')) {
      return 'are';
    } else {
      return 'is';
    }
  }

  get heroBg() {
    const heroImgUrl = `${
      process.env.PUBLIC_URL
    }/images/collectibles/landing-${kebabCase(this.props.categoryCode)}.jpg`;
    return [
      'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)',
      `url('${heroImgUrl}')`,
    ];
  }

  get pricingNode() {
    switch (this.props.categoryCode) {
      case 'jewelry':
        return (
          <LandingBlockPriceComparison
            title="Here’s what it costs"
            subtitle="With annual coverage starting at 1.35%* of the property value, protecting your jewelry just makes sense. Just think of it as..."
            footnote="The minimum policy price is $125"
            items={[
              {
                icon: 'blur_circular',
                description: 'The cost of 1 pearl on a 16-inch necklace',
              },
              {
                icon: <JewelryIcon />,
                description: '$1.35 for every $100 your wedding ring is worth',
              },
              {
                icon: 'hearing',
                description: '$50 to protect a pair of gold diamond earrings',
              },
              {
                icon: 'watch',
                description: '$100 to cover a $10,000 Rolex watch',
              },
            ]}
          />
        );
      case 'firearms':
        return (
          <LandingBlockPriceComparison
            title="Here’s what it costs"
            subtitle="Pricing is just 2% of your modern firearm collection value, per year, with a minimum policy price of $125."
          />
        );
      default:
        return (
          <LandingBlockPriceComparison
            title="Here’s what it costs"
            subtitle="Pricing is just $150 a year for collections up to $75,000 in value (and pretty reasonable beyond that collection size, too). Yea, we know: “wow”."
          />
        );
    }
  }

  render() {
    const {
      partnerInfo,
      isMenuOpen,
      settings,
      onToggleMenu,
      categoryConfig,
      categorySlug,
    } = this.props;

    return (
      <div className="LandingCollectibles">
        <div
          className="LandingCollectibles-hero"
          style={{
            backgroundImage: this.heroBg,
          }}
        >
          <TopNavBar
            transparent
            showMenuText
            isMenuOpen={isMenuOpen}
            sideMenuLinks={settings.sideMenuLinks}
            logoLightUrl={settings.logoLightUrl}
            logoDarkUrl={settings.logoDarkUrl}
            title={settings.title}
            onToggleMenu={onToggleMenu}
          />
          <div className="LandingCollectiblesContainer">
            <h1 className="LandingCollectibles-hero-heading">
              Your{' '}
              {!!categoryConfig.preserveCasing
                ? categoryConfig.title
                : lowerCase(categoryConfig.title)}{' '}
              {this.conjunction} worth protecting
            </h1>
            <p className="LandingCollectibles-hero-pitch">
              You’ve invested your time and money into building your collection.
              Now protect your property and your wallet with our competitive
              rates and comprehensive coverage.
            </p>
            <Button
              className="LandingCollectiblesCTA LandingCollectibles-cta"
              to={`/collectibles/${categorySlug}/start`}
              sureStyle="secondary"
            >
              Check our prices
            </Button>
          </div>
        </div>

        <LandingBlockThreeUp
          title="What does collectibles insurance cover?"
          subtitle="Whether you’re collecting as an investment or as a hobby or passion project, purchasing collectibles insurance guarantees your precious items will be protected."
          cards={map(categoryConfig.threeUps, threeUp => ({
            icon: threeUp.image,
            heading: threeUp.title,
            description: threeUp.description,
          }))}
          isIconImage
        />

        <LandingBlockPartner
          title="We’ve partnered with the best to keep your investment safe"
          partnerName="Markel"
          logo={imageMarkelLogo}
        >
          <p>
            Insuring your collectibles doesn’t need to involve paperwork and
            pain. We’ve partnered with <strong>Markel</strong> to bring you an
            affordable, yet comprehensive plan, that you can purchase
            instantly—right from the trade show or shop.
            {this.props.isHagerty && '*'}
          </p>
          <p>
            Markel has been a trusted insurer of personal property since 1988.
            Get covered through{' '}
            {this.props.isHagerty
              ? 'Hagerty Insurance Agency, LLC built on Sure'
              : 'Sure'}{' '}
            and manage all of your policies and claims through a single,
            user-friendly platform.
          </p>
        </LandingBlockPartner>

        {this.pricingNode}

        <LandingBlockCTABanner
          title="If it’s worth collecting, it’s worth protecting"
          subtitle={`Let us pair you with the best policy for you and your ${lowerCase(
            categoryConfig.title
          )}.`}
          lightTheme
          ctaText="Check our prices"
          ctaStyle="secondary"
          linkTo={`/collectibles/${categorySlug}/start`}
        />

        <Footer
          partnerInfo={partnerInfo}
          partnerDisclaimer={this.props.isHagerty && hagertyDisclaimer}
        />
      </div>
    );
  }
}

LandingCollectibles.propTypes = propTypes;

export default LandingCollectibles;
