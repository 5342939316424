'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PricingLayout/PricingLayout.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _sureComponents = require('sure-components');

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

require('./PricingLayout.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  currencyCode: _propTypes2.default.string,
  paymentCadences: _propTypes2.default.object,
  billingCycle: _propTypes2.default.string,
  onUpdatePaymentCadence: _propTypes2.default.func,
  pricePerCycle: _propTypes2.default.number,
  quote: _propTypes2.default.object,
  selectedPaymentCadence: _propTypes2.default.string,
  title: _propTypes2.default.string,
  totals: _propTypes2.default.object
};
var defaultProps = {
  currencyCode: 'USD',
  paymentCadences: {},
  billingCycle: null,
  onUpdatePaymentCadence: function onUpdatePaymentCadence() {},
  pricePerCycle: null,
  quote: {},
  selectedPaymentCadence: null
};

var PricingLayout = function (_PureComponent) {
  _inherits(PricingLayout, _PureComponent);

  function PricingLayout() {
    _classCallCheck(this, PricingLayout);

    return _possibleConstructorReturn(this, (PricingLayout.__proto__ || Object.getPrototypeOf(PricingLayout)).apply(this, arguments));
  }

  _createClass(PricingLayout, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          onUpdatePaymentCadence = _props.onUpdatePaymentCadence,
          selectedPaymentCadence = _props.selectedPaymentCadence;
      return _react2.default.createElement(_react.Fragment, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: this
      }, this.pricingHeading, this.hasPaymentOptions && _react2.default.createElement('div', {
        className: 'PricingLayout-paymentOptions',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 101
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'payment_cadence',
        value: selectedPaymentCadence,
        onChange: onUpdatePaymentCadence,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 102
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'annual',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 107
        },
        __self: this
      }, 'Annual (1 payment)'), _react2.default.createElement('option', {
        value: 'eleven_pay',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      }, 'Monthly (11 payments)'))));
    }
  }, {
    key: 'priceHeading',
    get: function get() {
      var price = this.props.pricePerCycle || (0, _get3.default)(this.props.totals, 'grandtotal');

      var formattedPrice = _currencyFormatter2.default.format(price, {
        code: this.props.currencyCode
      });

      if (this.props.billingCycle) {
        return formattedPrice + '/' + this.props.billingCycle;
      } else {
        return formattedPrice;
      }
    }
  }, {
    key: 'hasPaymentOptions',
    get: function get() {
      var paymentCadences = (0, _get3.default)(this.props.paymentCadences, 'cadences');
      return paymentCadences && paymentCadences.length > 1;
    }
  }, {
    key: 'hasMultiPaymentSchedule',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      return schedule && schedule.length > 1;
    }
  }, {
    key: 'downPayment',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      var amount = (0, _find2.default)(schedule, {
        downpayment: true
      }).amount;
      return _currencyFormatter2.default.format(amount, {
        code: 'USD'
      });
    }
  }, {
    key: 'monthlyPayments',
    get: function get() {
      var schedule = (0, _get3.default)(this.props.quote, 'paymentSchedule.schedule');
      var amount = (0, _find2.default)(schedule, {
        downpayment: false
      }).amount;
      return _currencyFormatter2.default.format(amount, {
        code: 'USD'
      });
    }
  }, {
    key: 'pricingHeading',
    get: function get() {
      if (this.hasMultiPaymentSchedule) {
        return _react2.default.createElement('div', {
          className: 'PricingLayout',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 70
          },
          __self: this
        }, _react2.default.createElement('h1', {
          className: 'PricingLayout-monthlyTitle',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 71
          },
          __self: this
        }, 'First Payment'), _react2.default.createElement('p', {
          className: 'PricingLayout-monthlyDownPayment',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 72
          },
          __self: this
        }, this.downPayment), _react2.default.createElement('h2', {
          className: 'PricingLayout-monthlyHeading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 73
          },
          __self: this
        }, 'Followed by 10 monthly payments'), _react2.default.createElement('p', {
          className: 'PricingLayout-monthlyInstallmentPayment',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 76
          },
          __self: this
        }, this.monthlyPayments, '/month'), _react2.default.createElement('p', {
          className: 'PricingLayout-monthlySubheading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 79
          },
          __self: this
        }, '(Billing Fees Included)'));
      } else {
        return _react2.default.createElement('div', {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 86
          },
          __self: this
        }, _react2.default.createElement('h1', {
          className: 'PricingLayout-title',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 87
          },
          __self: this
        }, this.props.title), _react2.default.createElement('h2', {
          className: 'PricingLayout-price',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 88
          },
          __self: this
        }, this.priceHeading));
      }
    }
  }]);

  return PricingLayout;
}(_react.PureComponent);

PricingLayout.propTypes = propTypes;
PricingLayout.defaultProps = defaultProps;
exports.default = PricingLayout;