'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/TextFieldsNew/TextFieldsNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _endsWith = require('lodash/endsWith');

var _endsWith2 = _interopRequireDefault(_endsWith);

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

var _Select = require('shared/lib/components/Select');

var _Select2 = _interopRequireDefault(_Select);

var _FormGroup = require('shared/lib/components/FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CurrencyFieldNew = require('../CurrencyFieldNew');

var _CurrencyFieldNew2 = _interopRequireDefault(_CurrencyFieldNew);

var _cleaveReact = require('cleave.js/dist/cleave-react');

var _cleaveReact2 = _interopRequireDefault(_cleaveReact);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  fields: _propTypes2.default.array,
  values: _propTypes2.default.object
};
var defaultProps = {
  fields: [],
  values: {}
};

var TextFieldsNew = function (_Component) {
  _inherits(TextFieldsNew, _Component);

  function TextFieldsNew(props) {
    _classCallCheck(this, TextFieldsNew);

    var _this = _possibleConstructorReturn(this, (TextFieldsNew.__proto__ || Object.getPrototypeOf(TextFieldsNew)).call(this, props));

    _this.handleChange = function (e) {
      var _e$target = e.target,
          name = _e$target.name,
          value = _e$target.value,
          type = _e$target.type;

      if (type === 'number') {
        value = parseInt(value, 10);
      }

      _this.props.onChange(_defineProperty({}, name, value));
    };

    _this.handleDatePickerFocus = function (_ref) {
      var focused = _ref.focused;

      _this.setState({
        isDatePickerOpen: focused
      });
    };

    _this.state = {
      isDatePickerOpen: false
    };
    _this.isMobile = window.innerWidth < 768;
    return _this;
  }

  _createClass(TextFieldsNew, [{
    key: 'getFieldLabel',
    value: function getFieldLabel(field) {
      var label = field.label;

      if (!field.required && this.props.fields.length === 1) {
        label += ' (optional)';
      }

      return label;
    }
  }, {
    key: 'renderDateFormatField',
    value: function renderDateFormatField(field) {
      var _this2 = this;

      var cleaveOpts = {
        date: true,
        datePattern: ['m', 'd', 'Y'],
        delimiter: '/'
      };
      return _react2.default.createElement(_cleaveReact2.default, {
        key: field.key_name,
        className: 'SureTextInput',
        name: field.key_name,
        placeholder: 'MM/DD/YYYY',
        required: field.required,
        options: cleaveOpts,
        pattern: '\\d{2}\\/\\d{2}\\/\\d{4}',
        defaultValue: this.props.values[field.key_name],
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this2.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 65
        },
        __self: this
      });
    }
  }, {
    key: 'renderDatePickerField',
    value: function renderDatePickerField(field) {
      var _this3 = this;

      var format = field.widget === 'date_picker' ? 'YYYY-MM-DD' : null;
      var value = this.props.values[field.key_name];
      var minDate = (0, _get2.default)(field, 'settings.date_after') || null;
      var maxDate = (0, _get2.default)(field, 'settings.date_before') || null;
      return _react2.default.createElement(_reactDates.SingleDatePicker, {
        key: field.key_name,
        name: field.key_name,
        date: value && (0, _momentTimezone2.default)(value) || null,
        focused: this.state.isDatePickerOpen,
        block: true,
        numberOfMonths: 1,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        placeholder: this.getFieldLabel(field),
        displayFormat: 'MM/DD/YYYY',
        onDateChange: function onDateChange(momentObj) {
          return _this3.handleChange({
            target: {
              name: field.key_name,
              value: momentObj ? momentObj.format(format) : null
            }
          });
        },
        isOutsideRange: function isOutsideRange(day) {
          return day.startOf('day').isBefore((0, _momentTimezone2.default)(minDate)) || day.startOf('day').isAfter((0, _momentTimezone2.default)(maxDate));
        },
        onFocusChange: this.handleDatePickerFocus,
        hideKeyboardShortcutsPanel: true,
        id: 'policy_effective_date',
        ref: function ref(c) {
          return _this3.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86
        },
        __self: this
      });
    }
  }, {
    key: 'renderSelectField',
    value: function renderSelectField(field) {
      var _this4 = this;

      var choices = (0, _get2.default)(field, 'settings.choices') || [];
      var options = choices.map(function (choice) {
        return _react2.default.createElement('option', {
          value: choice.value,
          key: choice.value,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 121
          },
          __self: _this4
        }, choice.title);
      });
      return _react2.default.createElement(_react.Fragment, {
        key: field.key_name + '-fragment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 128
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: field.key_name,
        key: field.key_name + '-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      }, this.getFieldLabel(field)), _react2.default.createElement(_Select2.default, {
        key: field.key_name,
        name: field.key_name,
        required: field.required,
        value: this.props.values[field.key_name],
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this4.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 136
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: '',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 144
        },
        __self: this
      }, this.getFieldLabel(field)), options));
    }
  }, {
    key: 'renderCurrencyFieldNew',
    value: function renderCurrencyFieldNew(field) {
      var _this5 = this;

      return _react2.default.createElement(_CurrencyFieldNew2.default, {
        key: field.key_name,
        name: field.key_name,
        placeholder: this.getFieldLabel(field),
        required: field.required,
        maxValue: (0, _get2.default)(field, 'settings.max_value'),
        minValue: (0, _get2.default)(field, 'settings.min_value'),
        value: this.props.values[field.key_name] || undefined,
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this5.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 153
        },
        __self: this
      });
    }
  }, {
    key: 'renderTextField',
    value: function renderTextField(field) {
      var _this6 = this;

      var regExpStr = (0, _get2.default)(field, 'settings.regex');
      var unicodeRegExp = /\\u([\d\w]{4})/gi;
      var decodedRegExp = void 0;

      if (regExpStr) {
        decodedRegExp = regExpStr.replace(unicodeRegExp, function (match, grp) {
          return String.fromCharCode(parseInt(grp, 16));
        });
      }

      return _react2.default.createElement(_react.Fragment, {
        key: field.key_name + '-fragment',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 179
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: field.key_name,
        key: field.key_name + '-label',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 180
        },
        __self: this
      }, this.getFieldLabel(field)), _react2.default.createElement(_TextInput2.default, {
        key: field.key_name,
        name: field.key_name,
        type: field.field_type,
        max: (0, _get2.default)(field, 'settings.max_value'),
        min: (0, _get2.default)(field, 'settings.min_value'),
        required: field.required,
        pattern: decodedRegExp,
        value: this.props.values[field.key_name] || '',
        onChange: this.handleChange,
        ref: function ref(c) {
          return _this6.fieldNode = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 187
        },
        __self: this
      }));
    }
  }, {
    key: 'validate',
    value: function validate() {
      if (this.fieldNode.validate) {
        return this.fieldNode.validate();
      } else {
        return Promise.resolve();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this7 = this;

      var inputs = this.props.fields.map(function (field) {
        if (field.widget === 'currency_field') {
          return _this7.renderCurrencyFieldNew(field);
        } else if ((0, _endsWith2.default)(field.key_name, '_dob')) {
          return _this7.renderDateFormatField(field);
        } else if (field.widget === 'date_picker' || field.widget === 'datetime_picker') {
          return _this7.renderDatePickerField(field);
        } else if (!(0, _isEmpty2.default)((0, _get2.default)(field, 'settings.choices'))) {
          return _this7.renderSelectField(field);
        } else {
          return _this7.renderTextField(field);
        }
      });
      return _react2.default.createElement('div', {
        className: 'TextFieldsNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 230
        },
        __self: this
      }, _react2.default.createElement(_FormGroup2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 231
        },
        __self: this
      }, inputs));
    }
  }]);

  return TextFieldsNew;
}(_react.Component);

TextFieldsNew.propTypes = propTypes;
TextFieldsNew.defaultProps = defaultProps;
exports.default = TextFieldsNew;