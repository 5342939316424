'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOP_BASE_PATH = exports.BOP_ROUTES = exports.bopRoutesCreator = undefined;
var _jsxFileName = 'src/apps/BopApp/routes.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _SurveyManager = require('../../components/SurveyManager');

var _SurveyManager2 = _interopRequireDefault(_SurveyManager);

var _BopBasicInfoView = require('./views/BopBasicInfoView');

var _BopBasicInfoView2 = _interopRequireDefault(_BopBasicInfoView);

var _BopPolicyStartDateView = require('./views/BopPolicyStartDateView');

var _BopPolicyStartDateView2 = _interopRequireDefault(_BopPolicyStartDateView);

var _BopPlansView = require('./views/BopPlansView');

var _BopPlansView2 = _interopRequireDefault(_BopPlansView);

var _BopPaymentView = require('./views/BopPaymentView');

var _BopPaymentView2 = _interopRequireDefault(_BopPaymentView);

var _BopConfirmationView = require('./views/BopConfirmationView');

var _BopConfirmationView2 = _interopRequireDefault(_BopConfirmationView);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var BOP_BASE_PATH = 'small-business-owners-insurance';
var BOP_ROUTES = {
  INFO: BOP_BASE_PATH + '/basic-info',
  SURVEY: BOP_BASE_PATH + '/survey',
  POLICY_DATE: BOP_BASE_PATH + '/policy-date',
  PLANS: BOP_BASE_PATH + '/plans',
  PAYMENT: BOP_BASE_PATH + '/payment',
  CONFIRMATION: BOP_BASE_PATH + '/confirmation'
};

var bopRoutesCreator = function bopRoutesCreator(_ref) {
  var container = _ref.container,
      landingComponent = _ref.landingComponent;
  return _react2.default.createElement(_reactRouter.Route, {
    path: BOP_BASE_PATH,
    component: container,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 21
    },
    __self: undefined
  }, _react2.default.createElement(_reactRouter.IndexRoute, {
    component: landingComponent,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 22
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'basic-info',
    component: _BopBasicInfoView2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'survey',
    component: _SurveyManager2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 24
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'policy-date',
    component: _BopPolicyStartDateView2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 25
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'plans',
    component: _BopPlansView2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 26
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'payment',
    component: _BopPaymentView2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27
    },
    __self: undefined
  }), _react2.default.createElement(_reactRouter.Route, {
    path: 'confirmation',
    component: _BopConfirmationView2.default,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28
    },
    __self: undefined
  }));
};

exports.bopRoutesCreator = bopRoutesCreator;
exports.BOP_ROUTES = BOP_ROUTES;
exports.BOP_BASE_PATH = BOP_BASE_PATH;