'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/PagerButtons/PagerButtons.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./PagerButtons.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  onNext: _propTypes2.default.func,
  onBack: _propTypes2.default.func,
  nextBtnText: _propTypes2.default.string,
  backBtnText: _propTypes2.default.string,
  isNextDisabled: _propTypes2.default.bool,
  isBackDisabled: _propTypes2.default.bool,
  isBackHidden: _propTypes2.default.bool,
  isInProgress: _propTypes2.default.bool
};
var defaultProps = {
  onNext: function onNext() {},
  onBack: function onBack() {},
  nextBtnText: null,
  backBtnText: null,
  isNextDisabled: false,
  isBackDisabled: false,
  isBackHidden: false,
  isInProgress: false
};

var PagerButtons = function (_PureComponent) {
  _inherits(PagerButtons, _PureComponent);

  function PagerButtons() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, PagerButtons);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PagerButtons.__proto__ || Object.getPrototypeOf(PagerButtons)).call.apply(_ref, [this].concat(args))), _this), _this.handleBack = function () {
      if (!_this.props.isBackDisabled) {
        _this.props.onBack();
      }
    }, _this.handleNext = function () {
      if (!_this.props.isNextDisabled) {
        _this.props.onNext();
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(PagerButtons, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('PagerButtonsNew', this.props.className),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: this
      }, !this.props.isBackHidden && _react2.default.createElement('button', {
        type: 'button',
        className: (0, _classnames2.default)('PagerButtonsNew-button', 'PagerButtonsNew-button--back', {
          'is-disabled': this.props.isBackDisabled
        }),
        onClick: this.handleBack,
        disabled: this.props.isNextDisabled,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 78
        },
        __self: this
      }, this.props.backBtnText || 'Back'), this.nextButtonContent);
    }
  }, {
    key: 'nextButtonContent',
    get: function get() {
      if (this.props.isInProgress) {
        return _react2.default.createElement('div', {
          className: (0, _classnames2.default)('PagerButtonsNew-button', 'PagerButtonsNew-button--spinner', {
            'PagerButtonsNew-button--fullWidth': this.props.isBackHidden
          }),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 44
          },
          __self: this
        }, _react2.default.createElement('span', {
          className: 'PagerButtonsNew-spinner',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 51
          },
          __self: this
        }));
      } else {
        return _react2.default.createElement('button', {
          type: 'button',
          className: (0, _classnames2.default)('PagerButtonsNew-button', 'PagerButtonsNew-button--next', {
            'PagerButtonsNew-button--fullWidth': this.props.isBackHidden
          }, {
            'is-disabled': this.props.isNextDisabled
          }, {
            'is-inProgress': this.props.isInProgress
          }),
          onClick: this.handleNext,
          disabled: this.props.isNextDisabled,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 56
          },
          __self: this
        }, this.props.nextBtnText || 'Next');
      }
    }
  }]);

  return PagerButtons;
}(_react.PureComponent);

PagerButtons.propTypes = propTypes;
PagerButtons.defaultProps = defaultProps;
exports.default = PagerButtons;