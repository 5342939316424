import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextInput, PagerButtons } from 'sure-components';
import get from 'lodash/get';
import Button from 'shared/lib/components/Button';
import Modal from 'shared/lib/components/Modal';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import AddressForm from 'shared/lib/components/AddressForm';
import TelInput from 'shared/lib/components/TelInput';
import * as analytics from 'shared/lib/analytics';
import './CarSafePolicyHolderView.css';

const propTypes = {
  carSafeDetails: PropTypes.object,
  carSafeSelectedPlan: PropTypes.object,
  updateCarSafeDetails: PropTypes.func,
};

const defaultProps = {
  carSafeDetails: {},
  carSafeSelectedPlan: {},
  updateCarSafeDetails: () => {},
};

class CarSafePolicyHolderView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
      isRejectModalOpen: false,
    };
  }

  componentDidMount() {
    analytics.page('CarSafe - Policy Holder Info');
  }

  handleBack = () => {
    this.props.navTo('/vehicle-contents-insurance/plans');
  };

  handleChange = e => {
    this.props.updateCarSafeDetails({
      [e.target.name]: e.target.value,
    });
  };

  handlePhoneChange = val => {
    this.props.updateCarSafeDetails({
      phone: val,
    });
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }

    analytics.track('CarSafe - Policy Holder Info Submitted');
    this.setState({
      isInProgress: true,
    });
    this.residenceAddressInput.getGeocodedValue().then(location => {
      this.props.updateCarSafeDetails({
        lat: location.lat,
        lng: location.lng,
        country: location.country,
        postal: location.postal,
      });
      if (location.country === 'ZA') {
        this.props.navTo('/vehicle-contents-insurance/add-beneficiary');
      } else {
        analytics.track('CarSafe - No Offers Modal Shown', {
          label: location.country,
        });
        this.setState({
          isInProgress: false,
          isRejectModalOpen: true,
        });
      }
    });
  };

  hideRejectModal = () => {
    this.setState({
      isRejectModalOpen: false,
    });
  };

  trackDocClick = () => {
    analytics.track('CarSafe - Key Information Doc Link Clicked', {
      label: this.props.carSafeSelectedPlanId,
    });
  };

  render() {
    const {
      carSafeDetails,
      carSafeSelectedPlan,
      disclaimer,
      updateCarSafeDetails,
    } = this.props;

    return (
      <div className="CarSafePolicyHolderView">
        <div className="Container Container--small">
          <h2 className="CarSafePolicyHolderView-planTitle">
            CarSafe: {get(carSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="CarSafePolicyHolderView-samplePolicyLink"
            href={carSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noreferrer noopener"
            onClick={this.trackDocClick}
          >
            View Key Information Document
          </a>
          <h1 className="CarSafePolicyHolderView-heading">
            What is the policy holder information?
          </h1>
          <form
            className="CarSafePolicyHolderView-form"
            onSubmit={this.handleNext}
          >
            <FormGroup>
              <label>Contact info</label>
              <TextInput
                name="firstName"
                placeholder="First name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={carSafeDetails.firstName || ''}
                onChange={this.handleChange}
              />
              <TextInput
                name="lastName"
                placeholder="Last name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={carSafeDetails.lastName || ''}
                onChange={this.handleChange}
              />
              <TelInput
                required
                className="CarSafePolicyHolderView-telInput"
                defaultCountry="za"
                onlyCountries={['za']}
                name="phone"
                onChange={this.handlePhoneChange}
                value={carSafeDetails.phone}
              />
              <TextInput
                name="email"
                placeholder="Email"
                type="email"
                required
                value={carSafeDetails.email || ''}
                onChange={this.handleChange}
              />
            </FormGroup>
            <label>Policyholder ID Number</label>
            <TextInput
              name="policyholderId"
              placeholder="ID Number"
              required
              pattern="\d{13}"
              minLength="13"
              maxLength="13"
              value={carSafeDetails.policyholderId || ''}
              onChange={this.handleChange}
            />
            <label>Residence</label>
            <AddressForm
              streetAddress={carSafeDetails.streetAddress}
              unit={carSafeDetails.unit}
              city={carSafeDetails.city}
              region={carSafeDetails.region}
              postal={carSafeDetails.postal}
              country={carSafeDetails.country}
              onChange={updateCarSafeDetails}
              ref={c => (this.residenceAddressInput = c)}
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="CarSafePolicyHolderView-cta"
            isInProgress={this.state.isInProgress}
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
          />
          <PlanDisclaimer showBuiltOnLogo text={disclaimer} />
        </div>
        <Modal
          className="CarSafePolicyHolderView-rejectModal"
          title="No plans available"
          isActive={this.state.isRejectModalOpen}
          onDismiss={this.hideRejectModal}
        >
          <p>
            Looks like you live outside of South Africa. Unfortunately we do not
            have any plans available for you at the moment.
          </p>
          <Button
            onClick={this.hideRejectModal}
            style={{
              backgroundColor: 'rgb(86,191,211)',
            }}
          >
            Okay
          </Button>
        </Modal>
      </div>
    );
  }
}

CarSafePolicyHolderView.propTypes = propTypes;
CarSafePolicyHolderView.defaultProps = defaultProps;

export default CarSafePolicyHolderView;
