'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CheckboxButton/CheckboxButton.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./CheckboxButton.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  checked: _propTypes2.default.bool,
  defaultChecked: _propTypes2.default.bool,
  isHtml: _propTypes2.default.bool,
  name: _propTypes2.default.string.isRequired,
  onChange: _propTypes2.default.func,
  secondaryTitle: _propTypes2.default.string,
  text: _propTypes2.default.string,
  title: _propTypes2.default.string.isRequired,
  value: _propTypes2.default.any.isRequired
};
var defaultProps = {
  checked: false,
  defaultChecked: false,
  isHtml: false,
  name: '',
  onChange: function onChange() {},
  secondaryTitle: '',
  text: '',
  title: '',
  value: ''
};

var CheckboxButton = function (_Component) {
  _inherits(CheckboxButton, _Component);

  function CheckboxButton() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CheckboxButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CheckboxButton.__proto__ || Object.getPrototypeOf(CheckboxButton)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isChecked: _this.props.defaultChecked || _this.props.checked
    }, _this.setUnchecked = function () {
      _this.setState({
        isChecked: false
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CheckboxButton, [{
    key: 'handleChange',
    value: function handleChange(e) {
      this.setState({
        isChecked: this.input.checked
      });
      this.props.onChange(e);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('label', {
        className: (0, _classnames2.default)(this.props.className, 'Input--checkboxNew', {
          'is-checked': this.state.isChecked
        }),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 65
        },
        __self: this
      }, _react2.default.createElement('input', {
        type: 'checkbox',
        name: this.props.name,
        value: this.props.value,
        checked: this.state.isChecked,
        ref: function ref(c) {
          return _this2.input = c;
        },
        onChange: this.handleChange.bind(this),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 70
        },
        __self: this
      }), _react2.default.createElement('div', {
        className: 'Input-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 78
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: 'far fa-square Input-icon-unchecked',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 79
        },
        __self: this
      }), _react2.default.createElement('i', {
        className: 'far fa-check-square Input-icon-checked',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      })), _react2.default.createElement('div', {
        className: 'Input-content',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 82
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Input-text',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 83
        },
        __self: this
      }, this.titleNode, this.props.text && _react2.default.createElement('span', {
        className: 'Input-text-help',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 86
        },
        __self: this
      }, this.props.text)), this.props.secondaryTitle && _react2.default.createElement('div', {
        className: 'Input-secondaryTitle',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }, this.props.secondaryTitle)));
    }
  }, {
    key: 'titleNode',
    get: function get() {
      if (this.props.isHtml) {
        var htmlObj = {
          __html: this.props.title
        };
        return _react2.default.createElement('span', {
          className: 'Input-text-main',
          dangerouslySetInnerHTML: htmlObj,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 39
          },
          __self: this
        });
      } else {
        return _react2.default.createElement('span', {
          className: 'Input-text-main',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 42
          },
          __self: this
        }, this.props.title);
      }
    }
  }, {
    key: 'checked',
    get: function get() {
      return this.input.checked;
    }
  }]);

  return CheckboxButton;
}(_react.Component);

CheckboxButton.propTypes = propTypes;
CheckboxButton.defaultProps = defaultProps;
exports.default = CheckboxButton;