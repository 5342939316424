'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/ConfirmationLayout/ConfirmationLayout.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _IconCircle = require('../IconCircle');

var _IconCircle2 = _interopRequireDefault(_IconCircle);

var _BillingSummarySection = require('../BillingSummarySection');

var _BillingSummarySection2 = _interopRequireDefault(_BillingSummarySection);

var _PlanDisclaimer = require('../PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

require('./ConfirmationLayout.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  currencyCode: _propTypes2.default.string,
  hideBillingSummary: _propTypes2.default.bool,
  hideSmsForm: _propTypes2.default.bool,
  icon: _propTypes2.default.string,
  isPendingApproval: _propTypes2.default.bool,
  billingCycle: _propTypes2.default.string,
  phoneNumber: _propTypes2.default.string,
  policyDocUrl: _propTypes2.default.string,
  policyNumber: _propTypes2.default.string,
  totals: _propTypes2.default.object
};
var defaultProps = {
  disclaimer: null,
  currencyCode: 'USD',
  hideBillingSummary: false,
  hideSmsForm: false,
  message: 'A confirmation has been sent to you.',
  isPendingApproval: false,
  billingCycle: null,
  phoneNumber: null,
  policyDocUrl: null,
  policyNumber: null
};

var ConfirmationLayout = function (_PureComponent) {
  _inherits(ConfirmationLayout, _PureComponent);

  function ConfirmationLayout() {
    _classCallCheck(this, ConfirmationLayout);

    return _possibleConstructorReturn(this, (ConfirmationLayout.__proto__ || Object.getPrototypeOf(ConfirmationLayout)).apply(this, arguments));
  }

  _createClass(ConfirmationLayout, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'ConfirmationLayout',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 72
        },
        __self: this
      }, this.props.policyNumber && _react2.default.createElement('p', {
        className: 'ConfirmationLayout-policyNumber',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 74
        },
        __self: this
      }, 'Plan #', this.props.policyNumber), this.iconCircle, this.props.policyDocUrl && _react2.default.createElement('a', {
        href: this.props.policyDocUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'ConfirmationLayout-samplePolicyLink',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      }, 'View Sample Policy'), _react2.default.createElement('h1', {
        className: 'ConfirmationLayout-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: this
      }, this.title), _react2.default.createElement('h2', {
        className: 'ConfirmationLayout-message',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 90
        },
        __self: this
      }, this.props.message), _react2.default.createElement('hr', {
        className: 'ConfirmationLayout-headingDivider',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 91
        },
        __self: this
      }), this.props.children, !this.props.hideBillingSummary && _react2.default.createElement(_BillingSummarySection2.default, {
        totals: this.props.totals,
        currencyCode: this.props.currencyCode,
        billingCycle: this.props.billingCycle,
        policyStartDate: this.props.policyStartDate,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 96
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }));
    }
  }, {
    key: 'title',
    get: function get() {
      if (this.props.isPendingApproval) {
        return 'Your policy is pending review.';
      } else {
        return "You're all set!";
      }
    }
  }, {
    key: 'iconCircle',
    get: function get() {
      if (this.props.isPendingApproval) {
        return _react2.default.createElement(_IconCircle2.default, {
          className: 'ConfirmationLayout-icon',
          icon: 'assignment',
          background: 'gray',
          color: 'dark',
          size: 80,
          badgeIcon: 'search',
          badgeBackground: 'blue',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 47
          },
          __self: this
        });
      } else if (this.props.icon) {
        return _react2.default.createElement(_IconCircle2.default, {
          className: 'ConfirmationLayout-icon',
          icon: this.props.icon,
          size: 80,
          badgeIcon: 'check',
          badgeBackground: 'green',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 59
          },
          __self: this
        });
      }
    }
  }]);

  return ConfirmationLayout;
}(_react.PureComponent);

ConfirmationLayout.propTypes = propTypes;
ConfirmationLayout.defaultProps = defaultProps;
exports.default = ConfirmationLayout;