'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/AddressForm/AddressForm.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _sureComponents = require('sure-components');

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _GooglePlacesSuggestInput = require('shared/lib/components/GooglePlacesSuggestInput');

var _GooglePlacesSuggestInput2 = _interopRequireDefault(_GooglePlacesSuggestInput);

var _utils = require('shared/lib/utils');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('./AddressForm.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  streetAddress: _propTypes2.default.string,
  unit: _propTypes2.default.string,
  city: _propTypes2.default.string,
  region: _propTypes2.default.string,
  postal: _propTypes2.default.string,
  country: _propTypes2.default.string,
  postalType: _propTypes2.default.string,
  onChange: _propTypes2.default.func
};
var defaultProps = {
  streetAddress: '',
  unit: '',
  city: '',
  region: '',
  postal: '',
  country: '',
  postalType: 'text',
  onChange: function onChange() {}
};

var AddressForm = function (_Component) {
  _inherits(AddressForm, _Component);

  function AddressForm(props) {
    _classCallCheck(this, AddressForm);

    var _this = _possibleConstructorReturn(this, (AddressForm.__proto__ || Object.getPrototypeOf(AddressForm)).call(this, props));

    _this.handleChange = function (e) {
      var key = e.target.name;
      var newState = Object.assign({
        streetAddress: _this.props.streetAddress,
        unit: _this.props.unit,
        city: _this.props.city,
        region: _this.props.region,
        postal: _this.props.postal,
        country: _this.props.country
      }, _defineProperty({}, key, e.target.value));

      if (key !== 'unit' && _this.autocomplete) {
        _this.autocomplete.set('place', null);
      }

      _this.props.onChange(newState);
    };

    _this.handleGooglePlacesFocus = function () {
      _this.setState({
        isGooglePlacesInputFocused: true
      });
    };

    _this.handleGooglePlacesBlur = function () {
      _this.setState({
        isGooglePlacesInputFocused: false
      });
    };

    _this.state = {
      isGooglePlacesInputFocused: false
    };
    return _this;
  }

  _createClass(AddressForm, [{
    key: 'getGeocodedValue',
    value: function getGeocodedValue() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        var address = (0, _compact2.default)([_this2.props.streetAddress, _this2.props.unit, _this2.props.city, _this2.props.region, _this2.props.postal]).join(' ');
        (0, _utils.geocodeAddress)(address).then(function (geocodedResult) {
          resolve(Object.assign({}, _this2.value, geocodedResult));
        }).catch(reject);
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_sureComponents.FormGroup, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 112
        },
        __self: this
      }, _react2.default.createElement(_GooglePlacesSuggestInput2.default, {
        name: 'streetAddress',
        value: this.props.streetAddress,
        maxLength: '30',
        required: true,
        onChange: this.handleChange,
        onSuggestionSelect: this.props.onChange,
        onFocus: this.handleGooglePlacesFocus,
        onBlur: this.handleGooglePlacesBlur,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 113
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'unit',
        placeholder: 'Address Line 2 (optional)',
        value: this.props.unit,
        maxLength: '30',
        onChange: this.handleChange,
        autoComplete: 'unit',
        autofill: 'off',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 123
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'city',
        placeholder: 'City',
        value: this.props.city,
        maxLength: '30',
        required: true,
        onChange: this.handleChange,
        autoComplete: 'city',
        autofill: 'off',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 132
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'region',
        placeholder: 'State/Province/Region',
        value: this.props.region,
        maxLength: '30',
        onChange: this.handleChange,
        autoComplete: 'state',
        autofill: 'off',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 142
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.TextInput, {
        name: 'postal',
        placeholder: 'Zip/Postal Code',
        value: this.props.postal,
        type: this.props.postalType,
        maxLength: '30',
        required: true,
        onChange: this.handleChange,
        autoComplete: 'postal',
        autofill: 'off',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 151
        },
        __self: this
      }), _react2.default.createElement('input', {
        name: 'country',
        type: 'hidden',
        value: this.props.country,
        onChange: this.handleChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 162
        },
        __self: this
      }));
    }
  }, {
    key: 'isEditing',
    get: function get() {
      return this.state.isGooglePlacesInputFocused;
    }
  }, {
    key: 'value',
    get: function get() {
      return {
        streetAddress: this.props.streetAddress,
        unit: this.props.unit,
        city: this.props.city,
        region: this.props.region,
        postal: this.props.postal,
        country: this.props.country
      };
    }
  }]);

  return AddressForm;
}(_react.Component);

AddressForm.propTypes = propTypes;
AddressForm.defaultProps = defaultProps;
exports.default = AddressForm;