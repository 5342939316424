'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesConfirmation/CollectiblesConfirmation.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AddressSectionNew = require('shared/lib/components/AddressSectionNew');

var _AddressSectionNew2 = _interopRequireDefault(_AddressSectionNew);

var _ConfirmationLayoutNew = require('shared/lib/components/ConfirmationLayoutNew');

var _ConfirmationLayoutNew2 = _interopRequireDefault(_ConfirmationLayoutNew);

var _LineItemsList = require('shared/lib/components/LineItemsList');

var _LineItemsList2 = _interopRequireDefault(_LineItemsList);

var _SectionWrapperNew = require('shared/lib/components/SectionWrapperNew');

var _SectionWrapperNew2 = _interopRequireDefault(_SectionWrapperNew);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _collectiblesCategories = require('shared/lib/constants/collectiblesCategories');

var _collectiblesCategories2 = _interopRequireDefault(_collectiblesCategories);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./CollectiblesConfirmation.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object
};
var defaultProps = {
  account: {}
};

var CollectiblesConfirmation = function (_PureComponent) {
  _inherits(CollectiblesConfirmation, _PureComponent);

  function CollectiblesConfirmation() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesConfirmation);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesConfirmation.__proto__ || Object.getPrototypeOf(CollectiblesConfirmation)).call.apply(_ref, [this].concat(args))), _this), _this.groomedMailingAddress = function (address) {
      return {
        streetAddress: address.line1,
        unit: address.line2,
        region: address.state_or_region,
        city: address.city,
        postal: address.postal,
        country: address.country_code
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesConfirmation, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Confirmation', {
        categoryCode: this.props.categoryCode
      });

      if (this.props.notificationMessage) {
        this.props.hideCollectiblesNotificationBar();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'CollectiblesConfirmation',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesConfirmation-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 44
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 45
        },
        __self: this
      }, _react2.default.createElement(_ConfirmationLayoutNew2.default, {
        message: 'Your payment will not bind coverage until the insurance carrier approves your application, and you will receive an email when you are approved. If your application is not approved, your payment will be refunded.',
        policyNumber: this.props.policyNumber,
        policyDocUrl: this.props.plan.policy_doc_url,
        icon: 'widgets',
        totals: this.props.totals,
        billingCycle: 'year',
        policyStartDate: this.props.policyStartDate,
        phoneNumber: this.props.account.phoneNumber,
        isPendingApproval: true,
        disclaimer: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 46
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapperNew2.default, {
        title: 'Policy details',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 58
        },
        __self: this
      }, _react2.default.createElement(_LineItemsList2.default, {
        rows: [['Mailing Address', _react2.default.createElement(_AddressSectionNew2.default, {
          address: this.groomedMailingAddress(this.props.collectiblesQuote.application.plan_applications[0].mailing_address),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 63
          },
          __self: this
        })], ['Underwritten by', 'Markel'], ['Deductible', _currencyFormatter2.default.format(this.props.selectedAmounts.deductible, {
          code: this.props.currencyCode
        })], ['Billing cycle', 'Annual (1 payment)'], ['Policy start date', this.props.policyStartDate.format('MM/DD/YYYY')], ['Policy end date', (0, _momentTimezone2.default)(this.props.policyStartDate).add(1, 'year').format('MM/DD/YYYY')]],
        __source: {
          fileName: _jsxFileName,
          lineNumber: 59
        },
        __self: this
      })), this.props.collectiblesQuote.application.plan_applications[0].collections.map(function (collection, index) {
        return _react2.default.createElement(_SectionWrapperNew2.default, {
          key: collection.reference_id,
          title: collection.category_code ? (0, _find2.default)(_collectiblesCategories2.default, {
            categoryCode: collection.category_code
          }).title + ' collection details' : 'Collection details',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 95
          },
          __self: _this2
        }, _react2.default.createElement(_LineItemsList2.default, {
          rows: [['Location', _react2.default.createElement(_AddressSectionNew2.default, {
            address: _this2.groomedMailingAddress(collection.address),
            __source: {
              fileName: _jsxFileName,
              lineNumber: 111
            },
            __self: _this2
          })], ['Total value', _currencyFormatter2.default.format(collection.collection_total_value, {
            code: _this2.props.currencyCode
          })], ['Highest valued item', _currencyFormatter2.default.format(collection.highest_value_item, {
            code: _this2.props.currencyCode
          })]],
          __source: {
            fileName: _jsxFileName,
            lineNumber: 107
          },
          __self: _this2
        }));
      })))));
    }
  }]);

  return CollectiblesConfirmation;
}(_react.PureComponent);

CollectiblesConfirmation.propTypes = propTypes;
CollectiblesConfirmation.defaultProps = defaultProps;
exports.default = CollectiblesConfirmation;