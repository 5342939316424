import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import {
  destructureAxiosReq,
  getDeviceInfo,
  trimValues,
} from 'shared/lib/utils';
import { purchase } from '../api';
import axios from 'axios';

/**
 * Get rental car plans.
 * @param {Object} opts The options.
 * @param {String} opts.streetAddress The user's street address.
 * @param {String} opts.unit The user's unit.
 * @param {String} opts.city The user's city.
 * @param {String} opts.region The user's state or region.
 * @param {String} opts.postal The user's postal code.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} [opts.authToken] The Sure API auth token.
 */
export function getRentalCarPlans(opts) {
  const browserEnv = {
    ...getDeviceInfo(),
    lat: opts.lat,
    lng: opts.lng,
  };
  const payload = trimValues({
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
  });

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/rental-car/plans`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(browserEnv),
        },
      }
    )
  );
}

/**
 * Retrieve a rental car checkout model.
 *
 * @param {Object} opts The options.
 * @param {String} [opts.authToken] The Sure API auth token.
 * @param {String} opts.streetAddress The user's street address.
 * @param {String} opts.unit The user's unit.
 * @param {String} opts.city The user's city.
 * @param {String} opts.region The user's state or region.
 * @param {String} opts.postal The user's postal code.
 * @param {Number} opts.lat The user's lat location coordinate.
 * @param {Number} opts.lng The user's lng location coordinate.
 * @param {String} opts.phone The user's phone number.
 * @param {String} opts.email The user's email address.
 * @param {Array} opts.drivers List of drivers (objects).
 * @param {String} opts.drivers.firstName Driver first name.
 * @param {String} opts.drivers.lastName Driver last name.
 * @param {Boolean} opts.drivers.isMainDriver Whether this is the
 * primary driver on file.
 * @param {String} [opts.drivers.address_id] Address public ID (for logged in).
 * @param {String} [opts.drivers.address] Address object (for logged out).
 * @param {String} [opts.drivers.address.line1] Address line 1.
 * @param {String} [opts.drivers.address.line2] Address line 2.
 * @param {String} [opts.drivers.address.city] Address city.
 * @param {String} [opts.drivers.address.state_or_region] Address region.
 * @param {String} [opts.drivers.address.postal] Address postal code.
 * @param {String} [opts.drivers.address.country] Address country codd3.
 * @param {String} opts.planId The selected rental car plan id.
 * @param {String} opts.rentalCompanyName Rental company.
 * @param {String} opts.rentalStarted Rental start date YYYY-MM-DD.
 * @param {String} opts.rentalEnded Rental return date YYYY-MM-DD.
 */
export function getRentalCarCheckoutInfo(opts) {
  const browserEnv = {
    ...getDeviceInfo(),
    lat: opts.lat,
    lng: opts.lng,
  };
  const drivers = map(opts.drivers, driver =>
    mapKeys(driver, (value, key) => snakeCase(key))
  );

  const payload = trimValues({
    plan_id: opts.planId,
    details: {
      rental_company_name: opts.rentalCompanyName,
      rental_started: opts.rentalStarted,
      rental_ended: opts.rentalEnded,
      drivers,
    },
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
    email: opts.email,
    phone_number: opts.phone,
  });

  return destructureAxiosReq(
    axios.post(
      `${
        process.env.REACT_APP_SURE_API_BASE_URL
      }/api/partner/v1/protections/rental-car/checkout`,
      payload,
      {
        headers: {
          'X-Environment': JSON.stringify(browserEnv),
        },
      }
    )
  );
}

export function purchaseRentalCar(opts) {
  const drivers = map(opts.drivers, driver =>
    mapKeys(driver, (value, key) => snakeCase(key))
  );

  const payload = {
    phone_number: opts.phone,
    email: opts.email,
    details: {
      rental_company_name: opts.rentalCompanyName,
      rental_started: opts.rentalStarted,
      rental_ended: opts.rentalEnded,
      drivers,
    },
    rating_address: {
      line1: opts.streetAddress,
      line2: opts.unit,
      city: opts.city,
      state_or_region: opts.region,
      postal: opts.postal,
      country_code: opts.country,
    },
    first_name: opts.firstName,
    last_name: opts.lastName,
    metadata: opts.metadata,
  };

  return purchase({
    planId: opts.planId,
    firstName: opts.firstName,
    lastName: opts.lastName,
    email: opts.email,
    phoneNumber: opts.phone,
    authToken: opts.authToken,
    cardElement: opts.cardElement,
    revenue: opts.revenue,
    lat: opts.lat,
    lng: opts.lng,
    payload,
    prefix: 'Rental Car',
    endpoint: '/api/partner/v1/protections/rental-car/purchase',
    uid: opts.uid,
  });
}
