import each from 'lodash/each';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { persistedRentersReducerKeys } from 'shared/lib/apps/RentersApp/reducers';
import { persistedBopReducerKeys } from 'shared/lib/apps/BopApp/reducers';
import { persistedCollectiblesReducerKeys } from 'shared/lib/apps/CollectiblesApp/reducers';
import { persistedRentalCarReducerKeys } from 'shared/lib/apps/RentalCarApp/reducers';

// XXX: Test for sessionStorage support.
// Some browsers like Safari private mode does not support sessionStorage.
let isSessionStorageSupported;
try {
  window.sessionStorage.setItem('__supportTest__', 1);
  isSessionStorageSupported = true;
} catch (e) {
  isSessionStorageSupported = false;
  window.console.error('sessionStorage is unsupported', e);
}

const sessionPersistKeys = [
  'isEmbed',
  'metadata',
  'beneficiaryRelationships',
  'carSafePlans',
  'carSafeSettings',
  'carSafeDetails',
  'carSafeSelectedPlanId',
  'carSafeCheckout',
  'carSafePurchaseInfo',
  'homeSafeQuote',
  'homeSafePlans',
  'homeSafeSettings',
  'homeSafeDetails',
  'homeSafeSelectedPlanId',
  'homeSafePurchaseInfo',
  ...persistedRentersReducerKeys,
  ...persistedBopReducerKeys,
  ...persistedCollectiblesReducerKeys,
  ...persistedRentalCarReducerKeys,
];

const sessionPersistor = {
  getSessionState() {
    let result = {};
    if (isSessionStorageSupported) {
      const restoredState = pick(window.sessionStorage, sessionPersistKeys);
      each(restoredState, (val, key) => {
        try {
          restoredState[key] = JSON.parse(val);
        } catch (e) {
          // Do nada
        }
      });
      merge(result, restoredState);
    }
    return result;
  },

  createPersistor(store) {
    if (isSessionStorageSupported) {
      store.subscribe(() => {
        const state = store.getState();
        const subState = pick(state, sessionPersistKeys);
        each(subState, (val, key) => {
          if (!isBoolean(val) && isEmpty(val)) {
            window.sessionStorage.removeItem(key);
          } else if (isObject(val)) {
            window.sessionStorage.setItem(key, JSON.stringify(val));
          } else {
            window.sessionStorage.setItem(key, val);
          }
        });
      });
    }
  },
};

export default sessionPersistor;
