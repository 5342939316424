'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/apps/RentersApp/hoc.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

exports.default = withSubapp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _reactRouterRedux = require('react-router-redux');

var _ModalSpinner = require('shared/lib/components/ModalSpinner');

var _ModalSpinner2 = _interopRequireDefault(_ModalSpinner);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _first = require('lodash/first');

var _first2 = _interopRequireDefault(_first);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _sumBy = require('lodash/sumBy');

var _sumBy2 = _interopRequireDefault(_sumBy);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

var _utils = require('shared/lib/utils');

var _actions = require('./actions');

var _actions2 = _interopRequireDefault(_actions);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var currentScreen = state.rentersScreens[state.rentersCurrentScreenId];
  var fields = (0, _get2.default)(currentScreen, 'fields') || [];
  var policyProvider = 'Assurant';
  var paymentSchedule = (0, _get2.default)(state.quote, 'payment_schedule.schedule');
  return {
    currentResponse: state.rentersCurrentResponse,
    disclaimer: ownProps.disclaimer || (0, _utils.getDisclaimer)(state.rentersPlan),
    fields: fields,
    flags: (0, _get2.default)(currentScreen, 'flags'),
    helpText: (0, _get2.default)(currentScreen, 'help_text'),
    isFetchingQuote: state.isFetchingRentersQuote,
    isFirstScreen: (0, _get2.default)((0, _first2.default)(state.rentersScreenSequence), 'id') === state.rentersCurrentScreenId,
    isLastScreen: (0, _get2.default)((0, _last2.default)(state.rentersScreenSequence), 'id') === state.rentersCurrentScreenId,
    isQuoteStale: state.isRentersQuoteStale,
    isRentersInProgress: state.isRentersInProgress,
    rentersPaymentCadences: state.rentersPaymentCadences,
    phoneNumber: (0, _get2.default)(state.rentersResponses, 'pni_phone_number'),
    plan: state.rentersPlan,
    planNumber: state.rentersPlanNumber,
    policyNumber: state.rentersPolicyNumber,
    policyProvider: policyProvider,
    policyStartDate: (0, _momentTimezone2.default)(state.rentersPolicyStartDate),
    propertyAddress: state.rentersPropertyAddress,
    question: (0, _get2.default)(currentScreen, 'question'),
    rentersQuote: state.rentersQuote,
    rates: state.rentersRates,
    responses: state.rentersResponses,
    rentersSelectedPaymentCadence: state.rentersSelectedPaymentCadence,
    screenId: state.rentersCurrentScreenId,
    selectedAmounts: state.rentersSelectedAmounts,
    surveyType: 'Renters',
    totals: (0, _get2.default)(state.rentersQuote, 'totals'),
    zipCodeResults: state.rentersZipCodeResults,
    fullName: (0, _compact2.default)([state.rentersResponses.pni_first_name, state.rentersResponses.pni_middle_name, state.rentersResponses.pni_last_name, state.rentersResponses.pni_suffix]).join(' ')
  };
};

var mapDispatchToPropsWithActions = function mapDispatchToPropsWithActions(actions) {
  return function (dispatch, ownProps) {
    var submitRentersAddress = actions.submitRentersAddress,
        updateRentersCurrentResponse = actions.updateRentersCurrentResponse,
        saveRentersResponse = actions.saveRentersResponse,
        updateRentersAddress = actions.updateRentersAddress,
        surveyRentersBack = actions.surveyRentersBack,
        purchaseRenters = actions.purchaseRenters,
        _calculateRentersQuote = actions.calculateRentersQuote,
        updateRentersStartDate = actions.updateRentersStartDate,
        updateRentersSelectedAmounts = actions.updateRentersSelectedAmounts,
        rentersSurveyNext = actions.rentersSurveyNext,
        deleteRentersResponse = actions.deleteRentersResponse,
        resetRentersData = actions.resetRentersData,
        getRentersPaymentCadences = actions.getRentersPaymentCadences,
        selectRentersPaymentCadence = actions.selectRentersPaymentCadence;
    return Object.assign({}, (0, _redux.bindActionCreators)({
      deleteResponse: deleteRentersResponse,
      purchase: purchaseRenters,
      saveResponse: saveRentersResponse,
      surveyBack: surveyRentersBack,
      surveyNext: rentersSurveyNext,
      updateCurrentResponse: updateRentersCurrentResponse,
      getRentersPaymentCadences: getRentersPaymentCadences,
      resetRentersData: resetRentersData,
      selectRentersPaymentCadence: selectRentersPaymentCadence,
      submitRentersAddress: submitRentersAddress,
      updateRentersAddress: updateRentersAddress,
      updateRentersSelectedAmounts: updateRentersSelectedAmounts,
      updateRentersStartDate: updateRentersStartDate
    }, dispatch), {
      backToStart: function backToStart() {
        return dispatch((0, _reactRouterRedux.push)((0, _utils.resolveUrlPath)('start')));
      },
      onCompleteSurvey: function onCompleteSurvey() {
        return dispatch((0, _reactRouterRedux.push)({
          pathname: (0, _utils.resolveUrlPath)('policy-date'),
          query: ownProps.location.query
        }));
      },
      calculateRentersQuote: function calculateRentersQuote() {
        return dispatch(_calculateRentersQuote());
      }
    });
  };
};

function withSubapp(api) {
  var rentersActions = (0, _actions2.default)(api);
  return function (WrappedComponent) {
    var SubappHOC = function (_Component) {
      _inherits(SubappHOC, _Component);

      function SubappHOC(props) {
        _classCallCheck(this, SubappHOC);

        var _this = _possibleConstructorReturn(this, (SubappHOC.__proto__ || Object.getPrototypeOf(SubappHOC)).call(this, props));

        _this.displayName = 'withSubapp(' + (WrappedComponent.displayName || WrappedComponent.name) + ')';
        _this.WrappedComponent = WrappedComponent;
        return _this;
      }

      _createClass(SubappHOC, [{
        key: 'render',
        value: function render() {
          return _react2.default.createElement('div', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 130
            },
            __self: this
          }, _react2.default.createElement(WrappedComponent, _extends({}, this.props, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 131
            },
            __self: this
          })), _react2.default.createElement(_ModalSpinner2.default, {
            message: 'Calculating your quote. This may take up to 15 seconds.',
            isActive: this.props.isFetchingQuote,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 132
            },
            __self: this
          }));
        }
      }]);

      return SubappHOC;
    }(_react.Component);

    var ComponentWithStatics = (0, _hoistNonReactStatics2.default)(SubappHOC, WrappedComponent);
    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToPropsWithActions(rentersActions))(ComponentWithStatics);
  };
}