import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PagerButtons, RadioButton } from 'sure-components';
import get from 'lodash/get';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import * as analytics from 'shared/lib/analytics';
import './HomeSafeBeneficiaryOptionView.css';

const propTypes = {
  homeSafeDetails: PropTypes.object,
  homeSafeSelectedPlan: PropTypes.object,
  updateHomeSafeDetails: PropTypes.func,
};

const defaultProps = {
  homeSafeDetails: {},
  homeSafeSelectedPlan: {},
  updateHomeSafeDetails: () => {},
};

class HomeSafeBeneficiaryOptionView extends PureComponent {
  componentDidMount() {
    analytics.page('HomeSafe - Beneficiary Option');
  }

  handleBack = () => {
    this.props.navTo('/residential-insurance/policy-holder');
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.homeSafeDetails.hasBeneficiary) {
      this.props.navTo('/residential-insurance/beneficiary');
    } else {
      this.props.navTo('/residential-insurance/additional-questions');
    }
  };

  handleUpdate = e => {
    this.props.updateHomeSafeDetails({
      hasBeneficiary: e.target.value === 'true',
    });
  };

  render() {
    const { homeSafeDetails, homeSafeSelectedPlan, disclaimer } = this.props;

    return (
      <div className="HomeSafeBeneficiaryOptionView">
        <div className="Container Container--small">
          <h2 className="HomeSafeBeneficiaryOptionView-planTitle">
            HomeSafe: {get(homeSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="HomeSafeBeneficiaryOptionView-samplePolicyLink"
            href={homeSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Key Information Document
          </a>
          <h1 className="HomeSafeBeneficiaryOptionView-heading">
            Would you like to add a beneficiary?
          </h1>
          <form
            className="HomeSafeBeneficiaryOptionView-form"
            onSubmit={this.handleNext}
          >
            <RadioButton
              name="hasBeneficiary"
              value="true"
              title="Yes"
              checked={homeSafeDetails.hasBeneficiary === true}
              onChange={this.handleUpdate}
            />
            <RadioButton
              name="hasBeneficiary"
              value="false"
              title="No"
              text="By selecting ‘No’ the beneficiary will default to policy holder’s estate."
              checked={homeSafeDetails.hasBeneficiary === false}
              onChange={this.handleUpdate}
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="HomeSafeBeneficiaryOptionView-cta"
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
          />
          <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
        </div>
      </div>
    );
  }
}

HomeSafeBeneficiaryOptionView.propTypes = propTypes;
HomeSafeBeneficiaryOptionView.defaultProps = defaultProps;

export default HomeSafeBeneficiaryOptionView;
