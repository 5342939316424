'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/SubFieldsNew/SubFieldsNew.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _times = require('lodash/times');

var _times2 = _interopRequireDefault(_times);

var _TextFieldsNew = require('shared/lib/components/TextFieldsNew');

var _TextFieldsNew2 = _interopRequireDefault(_TextFieldsNew);

require('./SubFieldsNew.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var SubFieldsNew = function (_Component) {
  _inherits(SubFieldsNew, _Component);

  function SubFieldsNew() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SubFieldsNew);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SubFieldsNew.__proto__ || Object.getPrototypeOf(SubFieldsNew)).call.apply(_ref, [this].concat(args))), _this), _this.fieldValues = [].concat(_toConsumableArray(_this.props.defaultValues)), _this.state = {
      numFieldsToShow: (0, _get2.default)(_this.props.fields[0], 'settings.min_choices') || 0
    }, _this.handleChange = function (i, val) {
      _this.fieldValues[i] = Object.assign({}, _this.fieldValues[i], val);

      _this.props.onChange(_defineProperty({}, _this.props.fields[0].key_name, _this.fieldValues));
    }, _this.handleAdd = function (e) {
      e.preventDefault();

      if (_this.state.numFieldsToShow < _this.props.fields[0].settings.max_choices) {
        _this.setState({
          numFieldsToShow: _this.state.numFieldsToShow + 1
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SubFieldsNew, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var cursor = Math.max(this.state.numFieldsToShow, this.props.defaultValues.length);
      var subfieldNodes = [];
      (0, _times2.default)(cursor, function (i) {
        var firstField = _this2.props.fields[0];
        var subfields = (0, _cloneDeep2.default)(firstField.subfields);
        var isFieldsetRequired = i < firstField.settings.min_choices;
        subfields.forEach(function (field) {
          if (field.required !== false) {
            field.required = isFieldsetRequired;
          }
        });
        subfieldNodes.push(_react2.default.createElement('label', {
          key: 'label-' + i,
          className: 'SubFieldsNew-label',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 58
          },
          __self: _this2
        }, firstField.settings.row_item_name, ' ', i + 1, ' ', isFieldsetRequired ? '' : '(optional)'));
        subfieldNodes.push(_react2.default.createElement(_TextFieldsNew2.default, {
          key: 'subfield-' + i,
          fields: subfields,
          required: isFieldsetRequired,
          values: _this2.props.defaultValues[i],
          onChange: function onChange(val) {
            return _this2.handleChange(i, val);
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 65
          },
          __self: _this2
        }));
      });
      return _react2.default.createElement('div', {
        className: 'SubFieldsNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: this
      }, subfieldNodes, this.state.numFieldsToShow < (0, _get2.default)(this.props.fields[0], 'settings.max_choices') && _react2.default.createElement(_Button2.default, {
        onClick: this.handleAdd,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 80
        },
        __self: this
      }, (0, _get2.default)(this.props.fields[0], 'settings.add_button_caption')));
    }
  }]);

  return SubFieldsNew;
}(_react.Component);

SubFieldsNew.defaultProps = {
  fields: [],
  defaultValues: []
};
exports.default = SubFieldsNew;