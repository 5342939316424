import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-select/dist/react-select.css';
import 'sure-components/styles.css';
import './index.css';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import merge from 'lodash/merge';
import {
  Router,
  Route,
  IndexRoute,
  Redirect,
  useRouterHistory,
} from 'react-router';
import { createHistory } from 'history';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import {
  routerMiddleware,
  syncHistoryWithStore,
  routerReducer,
} from 'react-router-redux';
import sessionPersistor from './session_persistor';
import getStateFromUrl from 'shared/lib/urlParamHydrator';
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import replace from 'lodash/replace';
import some from 'lodash/some';
import values from 'lodash/values';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import axios from 'axios';
import * as analytics from 'shared/lib/analytics';
import * as Sentry from '@sentry/browser';
import {
  getDeviceInfo,
  logException,
  sanitizeSentryStatePayload,
} from 'shared/lib/utils';

import reducers from './store/reducers';
import COLLECTIBLES_CATEGORIES from 'shared/lib/constants/collectiblesCategories';
import api from './api';

import HeadTags from './components/HeadTags';
import LandingRenters from './components/LandingRenters';
import LandingBop from './components/LandingBop';
import LandingCollectibles from './components/LandingCollectibles';
import LandingCarSafe from './components/LandingCarSafe';
import LandingCarSafeFAQ from './components/LandingCarSafeFAQ';
import LandingHomeSafe from './components/LandingHomeSafe';
import LandingAboutChubb from './components/LandingAboutChubb';
import LandingContactChubb from './components/LandingContactChubb';
import LandingRentalCar from './components/LandingRentalCar';
import LandingHomeSafeFAQ from './components/LandingHomeSafeFAQ';
import LandingWedding from './components/LandingWedding';

import LandingRentersEmbed from './components/LandingRentersEmbed';
import LandingBopEmbed from './components/LandingBopEmbed';
import LandingCollectiblesEmbed from './components/LandingCollectiblesEmbed';
import LandingRentalCarEmbed from './components/LandingRentalCarEmbed';
import LandingWeddingEmbed from './components/LandingWeddingEmbed';

import CarSafePlansView from './components/CarSafePlansView';
import CarSafePolicyHolderView from './components/CarSafePolicyHolderView';
import CarSafeBeneficiaryOptionView from './components/CarSafeBeneficiaryOptionView';
import CarSafeBeneficiaryView from './components/CarSafeBeneficiaryView';
import CarSafePaymentView from './components/CarSafePaymentView';
import CarSafeConfirmationView from './components/CarSafeConfirmationView';
import HomeSafeIntroSurveyView from './components/HomeSafeIntroSurveyView';
import HomeSafePlansView from './components/HomeSafePlansView';
import HomeSafeAdditionalBenefitsView from './components/HomeSafeAdditionalBenefitsView';
import HomeSafePolicyHolderView from './components/HomeSafePolicyHolderView';
import HomeSafeBeneficiaryOptionView from './components/HomeSafeBeneficiaryOptionView';
import HomeSafeBeneficiaryView from './components/HomeSafeBeneficiaryView';
import HomeSafeScreeningSurveyView from './components/HomeSafeScreeningSurveyView';
import HomeSafePaymentView from './components/HomeSafePaymentView';
import HomeSafeConfirmationView from './components/HomeSafeConfirmationView';
import ChubbClaimsView from './components/ChubbClaimsView';
import ChubbSitemapView from './components/ChubbSitemapView';

import GlobalContainer from './containers/GlobalContainer';
import RentersContainer from './containers/RentersContainer';
import BopContainer from './containers/BopContainer';
import CollectiblesContainer from './containers/CollectiblesContainer';
import CarSafeContainer from './containers/CarSafeContainer';
import HomeSafeContainer from './containers/HomeSafeContainer';
import RentalCarContainer from './containers/RentalCarContainer';
import WeddingContainer from './containers/WeddingContainer';

/**
 * Subapps
 */

import {
  rentersRoutesCreator,
  RENTERS_BASE_PATH,
} from 'shared/lib/apps/RentersApp/routes';
import { bopRoutesCreator, BOP_BASE_PATH } from 'shared/lib/apps/BopApp/routes';
import {
  collectiblesRoutesCreator,
  COLLECTIBLES_BASE_PATH,
} from 'shared/lib/apps/CollectiblesApp/routes';
import {
  rentalCarRoutesCreator,
  RENTAL_CAR_BASE_PATH,
} from 'shared/lib/apps/RentalCarApp/routes';

import {
  weddingRoutesCreator,
  WEDDING_BASE_PATH,
} from 'shared/lib/apps/WeddingApp/routes';

/**
 * Polyfills
 */
import 'react-app-polyfill/ie11';

const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

/*
 * Initialize third party
 */

if (!window.Cypress) {
  const apiRegExp = new RegExp(process.env.REACT_APP_SURE_API_BASE_URL);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: ENV,
    maxBreadcrumbs: 15,
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === 'xhr') {
        return apiRegExp.test(get(breadcrumb, 'data.url')) ? breadcrumb : null;
      } else {
        return breadcrumb;
      }
    },
    integrations: [
      new Sentry.Integrations.Breadcrumbs({ console: ENV === 'production' }),
    ],
  });
}

window.stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

/*
 * Initialize store
 */

// XXX: Adds a getState() function to every
// action passed into the reducer
const actionGetStateMiddleware = store => next => action => {
  next({ ...action, getState: store.getState });
};

const browserHistory = useRouterHistory(createHistory)();

const storeMiddleware = [
  thunk,
  actionGetStateMiddleware,
  routerMiddleware(browserHistory),
];

if (process.env.REACT_APP_ENV !== 'production') {
  storeMiddleware.push(createLogger());
}

if (process.env.REACT_APP_SENTRY_DSN) {
  storeMiddleware.push(store => next => action => {
    Sentry.addBreadcrumb({
      message: action.type,
      category: 'redux_action',
      level: 'info',
    });
    Sentry.configureScope(scope => {
      scope.setExtra('state', sanitizeSentryStatePayload(store.getState()));
    });
    next(action);
  });
}

/*
 * Axios config
 */
axios.defaults.headers.common['X-Environment'] = JSON.stringify(
  getDeviceInfo()
);
axios.interceptors.response.use(
  response => response,
  error => {
    logException(error);
    return Promise.reject(error);
  }
);

api
  .getSite()
  .then(resp => {
    const site = resp.data;
    axios.defaults.headers.common['Authorization'] = `Token ${site.api_token}`;

    /*
     * Fetch partner details
     */
    return new Promise((resolve, reject) => {
      api
        .getPartnerInfo()
        .then(partnerResp => resolve({ site, info: partnerResp.data }), reject);
    });
  })
  .then(({ site, info }) => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY && !window.Cypress) {
      analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY, {
        clientId: info.client_id,
        clientName: info.name,
        clientEmail: info.email,
      });
    }
    window.SURE_PARTNER = info;

    /*
     * Boot store with client settings
     */

    const urlState = getStateFromUrl();
    const initialState = merge(sessionPersistor.getSessionState(), urlState, {
      authToken: site.api_token,
      settings: {
        ...site.settings,
        title: site.title,
        apiBaseUrl: process.env.REACT_APP_SURE_API_BASE_URL,
      },
      partnerInfo: info,
      hasPrepopulatedData: !isEmpty(urlState),
    });

    const composeEnhancers =
      process.env.REACT_APP_ENV !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    const store = createStore(
      combineReducers({
        ...reducers,
        routing: routerReducer,
      }),
      initialState,
      composeEnhancers(applyMiddleware(...storeMiddleware))
    );

    syncHistoryWithStore(browserHistory, store);
    sessionPersistor.createPersistor(store);

    /*
     * Configure routing and mount app
     */

    const redirectToVertical = (nextState, replace) => {
      switch (first(site.verticals)) {
        case 'renters':
          return replace(RENTERS_BASE_PATH);
        case 'commercial':
          return replace(BOP_BASE_PATH);
        case 'collectibles':
          return replace(COLLECTIBLES_BASE_PATH);
        case 'rental_car':
          return replace(RENTAL_CAR_BASE_PATH);
        case 'carsafe':
          return replace('/vehicle-contents-insurance');
        case 'homesafe':
          return replace('/residential-insurance');
        case 'wedding':
          return replace(WEDDING_BASE_PATH);
        default:
          return null;
      }
    };

    const PassThroughComponent = ({ children, ...otherProps }) =>
      React.cloneElement(children, otherProps);

    const redirectIfMissing = opts => {
      return (nextState, stateReplace) => {
        const value = get(store.getState(), opts.key);
        if (
          isEmpty(value) ||
          (isPlainObject(value) && some(values(value), isUndefined))
        ) {
          stateReplace({
            pathname: replace(opts.pathname, /:[a-zA-Z-_]+/, match => {
              return nextState.params[match.slice(1)];
            }),
          });
        }
      };
    };

    ReactDOM.render(
      <Fragment>
        <HeadTags />
        <Provider store={store}>
          <Router
            onUpdate={() => window.scrollTo(0, 0)}
            history={browserHistory}
          >
            <Route path="/" component={GlobalContainer}>
              <IndexRoute component={() => {}} onEnter={redirectToVertical} />

              <Route path="about-chubb" component={LandingAboutChubb} />
              <Route path="contact-chubb" component={LandingContactChubb} />
              <Route
                path="vehicle-contents-insurance"
                component={CarSafeContainer}
              >
                <IndexRoute component={LandingCarSafe} />
                <Route path="plans" component={CarSafePlansView} />
                <Route
                  path="policy-holder"
                  component={CarSafePolicyHolderView}
                />
                <Route
                  path="add-beneficiary"
                  component={CarSafeBeneficiaryOptionView}
                />
                <Route path="beneficiary" component={CarSafeBeneficiaryView} />
                <Route path="payment" component={CarSafePaymentView} />
                <Route
                  path="confirmation"
                  component={CarSafeConfirmationView}
                />
              </Route>
              <Route path="residential-insurance" component={HomeSafeContainer}>
                <IndexRoute component={LandingHomeSafe} />
                <Route
                  path="intro-survey"
                  component={HomeSafeIntroSurveyView}
                />
                <Route path="plans" component={HomeSafePlansView} />
                <Route
                  path="additional-benefits"
                  component={HomeSafeAdditionalBenefitsView}
                />
                <Route
                  path="policy-holder"
                  component={HomeSafePolicyHolderView}
                />
                <Route
                  path="add-beneficiary"
                  component={HomeSafeBeneficiaryOptionView}
                />
                <Route path="beneficiary" component={HomeSafeBeneficiaryView} />
                <Route
                  path="additional-questions"
                  component={HomeSafeScreeningSurveyView}
                />
                <Route path="payment" component={HomeSafePaymentView} />
                <Route
                  path="confirmation"
                  component={HomeSafeConfirmationView}
                />
              </Route>
              <Route
                path="vehicle-contents-insurance/faq"
                component={LandingCarSafeFAQ}
              />
              <Route
                path="residential-insurance/faq"
                component={LandingHomeSafeFAQ}
              />
              <Route path="chubb/claims" component={ChubbClaimsView} />
              <Route path="chubb/sitemap" component={ChubbSitemapView} />

              {rentersRoutesCreator({
                container: RentersContainer,
                landingComponent: LandingRenters,
              })}
              {collectiblesRoutesCreator({
                container: CollectiblesContainer,
                landingComponent: LandingCollectibles,
                redirectIfMissing,
              })}
              {bopRoutesCreator({
                container: BopContainer,
                landingComponent: LandingBop,
              })}
              {rentalCarRoutesCreator({
                container: RentalCarContainer,
                landingComponent: LandingRentalCar,
                redirectIfMissing,
              })}

              {weddingRoutesCreator({
                container: WeddingContainer,
                landingComponent: LandingWedding,
              })}

              {
                // XXX: Deprecated embed v0 route
                // Last day supported is 05/31/2018
              }
              <Redirect path="start" to="/embed/v1/renters-insurance/start" />
              <Route path="embed/v1" component={PassThroughComponent}>
                <IndexRoute component={() => {}} />

                <Redirect path="renters" to="/embed/v1/renters-insurance" />
                <Redirect
                  path="_renters"
                  to="/embed/v1/renters-insurance/start"
                />
                {rentersRoutesCreator({
                  container: RentersContainer,
                  landingComponent: LandingRentersEmbed,
                })}

                <Redirect
                  path="collectibles"
                  to="/embed/v1/collectibles/other-insurance"
                />
                <Redirect
                  path="_collectibles"
                  to="/embed/v1/collectibles/other-insurance/start"
                />
                {flatten(
                  map(
                    COLLECTIBLES_CATEGORIES,
                    (collectiblesCategory, collectiblesKey) => [
                      <Redirect
                        key={collectiblesKey}
                        path={kebabCase(collectiblesCategory.categoryCode)}
                        to={`/embed/v1/collectibles/${kebabCase(
                          collectiblesKey
                        )}-insurance`}
                      />,
                      <Redirect
                        key={`_${collectiblesKey}`}
                        path={`_${kebabCase(
                          collectiblesCategory.categoryCode
                        )}`}
                        to={`/embed/v1/collectibles/${kebabCase(
                          collectiblesKey
                        )}-insurance/start`}
                      />,
                    ]
                  )
                )}
                {collectiblesRoutesCreator({
                  container: CollectiblesContainer,
                  landingComponent: LandingCollectiblesEmbed,
                  redirectIfMissing,
                })}

                <Redirect
                  path="bop"
                  to="/embed/v1/small-business-owners-insurance"
                />
                <Redirect
                  path="_bop"
                  to="/embed/v1/small-business-owners-insurance/basic-info"
                />
                {bopRoutesCreator({
                  container: BopContainer,
                  landingComponent: LandingBopEmbed,
                })}

                <Redirect
                  path="rental-car"
                  to="/embed/v1/rental-car-insurance"
                />
                <Redirect
                  path="_rental-car"
                  to="/embed/v1/rental-car-insurance/rental-info"
                />
                {rentalCarRoutesCreator({
                  container: RentalCarContainer,
                  landingComponent: LandingRentalCarEmbed,
                  redirectIfMissing,
                })}
                <Redirect path="wedding" to="/embed/v1/wedding-protection" />
                {weddingRoutesCreator({
                  container: WeddingContainer,
                  landingComponent: LandingWeddingEmbed,
                  redirectIfMissing,
                })}
              </Route>
            </Route>

            <Redirect path="*" to="/" />
          </Router>
        </Provider>
      </Fragment>,
      document.getElementById('root')
    );
  })
  .catch(error => {
    console.error(error);
    if (window.location.hostname !== 'localhost') {
      logException(error);
      window.location = process.env.REACT_APP_HOMEPAGE;
    }
  });
