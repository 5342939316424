'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/ErrorPage/ErrorPage.js';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _logoBlue = require('./logo-blue.svg');

var _logoBlue2 = _interopRequireDefault(_logoBlue);

require('./ErrorPage.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function () {
  return _react2.default.createElement('div', {
    className: 'ErrorPage',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 6
    },
    __self: undefined
  }, _react2.default.createElement('div', {
    className: 'ErrorPage-header',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 7
    },
    __self: undefined
  }, _react2.default.createElement('div', {
    className: 'Container',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 8
    },
    __self: undefined
  }, _react2.default.createElement('a', {
    href: '/',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 9
    },
    __self: undefined
  }, _react2.default.createElement('img', {
    className: 'ErrorPage-logo',
    src: _logoBlue2.default,
    alt: 'Sure logo',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 10
    },
    __self: undefined
  })))), _react2.default.createElement('div', {
    className: 'ErrorPage-content Container Container--small',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 14
    },
    __self: undefined
  }, _react2.default.createElement('h1', {
    className: 'ErrorPage-heading',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 15
    },
    __self: undefined
  }, 'Something went wrong'), _react2.default.createElement('p', {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 16
    },
    __self: undefined
  }, 'Sorry, there\'s been an error. Please try refreshing the page.'), _react2.default.createElement('p', {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 17
    },
    __self: undefined
  }, 'If the error persists, email us at', ' ', _react2.default.createElement('a', {
    href: 'mailto:support@sureapp.com',
    __source: {
      fileName: _jsxFileName,
      lineNumber: 19
    },
    __self: undefined
  }, 'support@sureapp.com'), ' and we\'ll help as soon as we can. Sorry for the inconvenience.')));
};