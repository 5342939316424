'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/IconCircle/IconCircle.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./IconCircle.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  size: _propTypes2.default.oneOf([40, 60, 80, 100]),
  color: _propTypes2.default.oneOf(['dark', 'medium', 'light']),
  background: _propTypes2.default.oneOf(['blue', 'gray', 'aquamarine', 'purple', 'orange', 'green']),
  icon: _propTypes2.default.string,
  badgeIcon: _propTypes2.default.string,
  badgeColor: _propTypes2.default.oneOf(['dark', 'medium', 'light']),
  badgeBackground: _propTypes2.default.oneOf(['blue', 'gray', 'aquamarine', 'purple', 'orange', 'green', 'red'])
};
var defaultProps = {
  size: 80,
  color: 'dark',
  background: 'blue',
  badgeIcon: null,
  badgeColor: 'light',
  badgeBackground: 'gray'
};

var IconCircle = function (_PureComponent) {
  _inherits(IconCircle, _PureComponent);

  function IconCircle() {
    _classCallCheck(this, IconCircle);

    return _possibleConstructorReturn(this, (IconCircle.__proto__ || Object.getPrototypeOf(IconCircle)).apply(this, arguments));
  }

  _createClass(IconCircle, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('IconCircle', 'IconCircle--size-' + this.props.size, 'IconCircle--color-' + this.props.color, 'IconCircle--background-' + this.props.background, this.props.className),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: 'material-icons IconCircle-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 52
        },
        __self: this
      }, this.props.icon), this.props.badgeIcon && _react2.default.createElement('div', {
        className: (0, _classnames2.default)('IconCircle-badgeIcon', 'IconCircle-badgeIcon--color-' + this.props.badgeColor, 'IconCircle-badgeIcon--background-' + this.props.badgeBackground),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 54
        },
        __self: this
      }, _react2.default.createElement('i', {
        className: 'material-icons IconCircle-badgeIcon-icon',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 61
        },
        __self: this
      }, this.props.badgeIcon)));
    }
  }]);

  return IconCircle;
}(_react.PureComponent);

IconCircle.propTypes = propTypes;
IconCircle.defaultProps = defaultProps;
exports.default = IconCircle;