'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _zipObject = require('lodash/zipObject');

var _zipObject2 = _interopRequireDefault(_zipObject);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ACTIONS = ['SIGN_OUT', 'SUBMIT_RENTERS_ADDRESS', 'SUBMIT_RENTERS_ADDRESS_SUCCESS', 'SUBMIT_RENTERS_ADDRESS_FAILURE', 'UPDATE_RENTERS_ADDRESS', 'GET_RENTERS_RATES', 'GET_RENTERS_RATES_SUCCESS', 'UPDATE_RENTERS_SELECTED_AMOUNTS', 'SELECT_RENTERS_CHOICE', 'SAVE_RENTERS_RESPONSE', 'DELETE_RENTERS_RESPONSE', 'UPDATE_RENTERS_CURRENT_RESPONSE', 'RENTERS_SURVEY_NEXT', 'RENTERS_SURVEY_BACK', 'CALCULATE_RENTERS_QUOTE', 'CALCULATE_RENTERS_QUOTE_SUCCESS', 'CALCULATE_RENTERS_QUOTE_FAILURE', 'UPDATE_RENTERS_START_DATE', 'UPDATE_RENTERS_PERSONAL_PROPERTY_AMOUNT', 'PURCHASE_RENTERS', 'PURCHASE_RENTERS_SUCCESS', 'PURCHASE_RENTERS_FAILURE', 'RESET_RENTERS_DATA', 'GET_PAYMENT_CADENCES', 'GET_PAYMENT_CADENCES_SUCCESS', 'GET_PAYMENT_CADENCES_FAILURE', 'SELECT_PAYMENT_CADENCE'];
var ACTION_TYPES = (0, _zipObject2.default)(ACTIONS, ACTIONS);
exports.default = ACTION_TYPES;