'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.load = load;
exports.page = page;
exports.track = track;
exports.identify = identify;
exports.alias = alias;
exports.clearMergeProps = clearMergeProps;
exports.trackGTMPurchase = trackGTMPurchase;

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _pickBy = require('lodash/pickBy');

var _pickBy2 = _interopRequireDefault(_pickBy);

var _startsWith = require('lodash/startsWith');

var _startsWith2 = _interopRequireDefault(_startsWith);

var _queryString = require('query-string');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _mergeProps = {};
/**
 * Initialize the analytics tool.
 *
 * @param {String} segmentWriteKey The Segment.io API write key.
 * @param {Object} [mergeProps] Any properties to merge with every analytics request.
 */

function load(segmentWriteKey) {
  var mergeProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (window.analytics) {
    _mergeProps = mergeProps;
    window.analytics.load(segmentWriteKey);
  }
}

function page(pageName) {
  var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (window.analytics) {
    var mergedProps = (0, _merge2.default)({}, _mergeProps, Object.assign({}, properties, {
      url: properties.url || window.location.href,
      path: properties.path || window.location.pathname
    }));
    window.analytics.page(pageName, mergedProps);
  }
}

function track(event, properties) {
  if (window.analytics) {
    var mergedProps = (0, _merge2.default)({}, _mergeProps, properties);
    window.analytics.track(event, mergedProps);
  }
}

function identify() {
  var account = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (window.analytics) {
    var publicId = account.publicId || account.public_id || account.id;
    var queryStr = (0, _queryString.parse)(window.location.search);
    var utmParams = (0, _pickBy2.default)(queryStr, function (_, key) {
      return (0, _startsWith2.default)(key.toLowerCase(), 'utm') || key === 'gclid';
    });

    if (publicId) {
      var traits = Object.assign({
        email: account.email,
        name: account.name,
        firstName: account.firstName || account.first_name,
        lastName: account.lastName || account.last_name,
        id: publicId,
        phone: account.phoneNumber || account.phone_number
      }, utmParams);
      window.analytics.identify(publicId, traits);
    } else {
      window.analytics.identify(Object.assign({}, account, utmParams));
    }
  }
}

function alias(userId) {
  if (window.analytics) {
    window.analytics.alias(userId);
  }
}

function clearMergeProps() {
  _mergeProps = {};
}
/**
 * Track ecommerce purchase event through Google Tag Manager.
 *
 * @param {Object} opts The options.
 * @param {String} opts.orderId The order ID (usually agreement_id or policy_number).
 * @param {String} [opts.partnerId] The partnerId. Default: sure.
 * @param {Number} opts.revenue The revenue amount.
 * @param {String} opts.verticalCode The vertical code.
 * @param {String} opts.verticalName The vertical name.
 * @param {String} [opts.paymentCadence] The payment cadence ("Yearly", "Monthly", "Once"). Default: "Once".
 * @param {String} [opts.planTitle] The plan title, if multiple plans available.
 */


function trackGTMPurchase() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: opts.orderId,
            affiliation: opts.partnerId || 'sure',
            revenue: opts.revenue,
            tax: '',
            shipping: ''
          },
          products: [{
            name: opts.verticalName,
            id: opts.verticalCode,
            price: opts.revenue,
            brand: '',
            category: opts.paymentCadence || 'Once',
            variant: opts.planTitle || '',
            quantity: 1
          }]
        }
      }
    });
  }
}