import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import Modal from 'shared/lib/components/Modal';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import claimsHeroImg from './images/chubb-claims-hero.png';
import './ChubbClaimsView.css';

export default class ChubbClaimsView extends PureComponent {
  constructor(props) {
    super(props);
    this.MODALS = {
      CLAIMS: 'CLAIMS',
      PERSONAL: 'PERSONAL',
      RESIDENTIAL: 'RESIDENTIAL',
      VEHICLE: 'VEHICLE',
      VEHICLE_CONTENTS_REQS: 'VEHICLE_CONTENTS_REQS',
      RESIDENTIAL_REQS: 'RESIDENTIAL_REQS',
      PERSONAL_ACCIDENT_REQS: 'PERSONAL_ACCIDENT_REQS',
    };
    this.state = {
      activeModal: null,
    };
  }

  componentDidMount() {
    analytics.page('Chubb Claims');
  }

  showModal = e => {
    e.preventDefault();
    const modalId = e.target.dataset.modalId;
    this.setState({
      activeModal: modalId,
    });
  };

  hideModal = () => {
    this.setState({
      activeModal: null,
    });
  };

  render() {
    return (
      <div className="ChubbClaimsView ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#ff0198" bgImage={claimsHeroImg}>
          <h1 className="ChubbClaimsView-heroTitle">
            Chubb’s claims are handled with empathy, efficiency and expertise.
          </h1>
        </HeroChubb>
        <div className="ChubbClaimsView-content">
          <div className="Container">
            <h2 className="ChubbClaimsView-content-title">
              How to Claim
              <small className="ChubbClaimsView-content-title-subheading">
                Need to claim? Read this:
              </small>
            </h2>
            <p className="ChubbClaimsView-content-info">
              At Chubb, we work hard to process and pay your claim fairly and
              promptly. If you’ve experienced a loss under your policy, please
              complete the online claim form and follow the claim instructions
              below.
            </p>

            <div className="ChubbClaimsView-block ChubbClaimsView-block--vehicle">
              <div className="ChubbClaimsView-block-content">
                <h3 className="ChubbClaimsView-block-content-title">
                  Vehicle Contents Insurance
                </h3>
                <h4 className="ChubbClaimsView-block-content-heading">
                  What we cover:
                </h4>
                <ul className="ChubbClaimsView-block-content-list">
                  <li className="ChubbClaimsView-block-content-listItem">
                    Wallet & handbag (Theft of personal possessions)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Tech Safe (Theft of personal electronic items)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Home Lock and Key Cover (In the event these items are
                    stolen)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Identity Theft (Cards and important documents)
                  </li>
                </ul>
                <a
                  href="#vehicle-coverage"
                  className="ChubbClaimsView-block-content-uncoveredLink"
                  data-modal-id={this.MODALS.VEHICLE}
                  onClick={this.showModal}
                >
                  What We Do Not Cover
                </a>
              </div>
              <div className="ChubbClaimsView-block-image" />
            </div>

            <div className="ChubbClaimsView-block ChubbClaimsView-block--residential">
              <div className="ChubbClaimsView-block-image" />
              <div className="ChubbClaimsView-block-content">
                <h3 className="ChubbClaimsView-block-content-title">
                  Residential Insurance
                </h3>
                <h4 className="ChubbClaimsView-block-content-heading">
                  What we cover:
                </h4>
                <ul className="ChubbClaimsView-block-content-list">
                  <li className="ChubbClaimsView-block-content-listItem">
                    Home Burglary (Home contents)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Tech Safe Home (Theft of personal electronic items)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Glass Coverage (Damaged / broken windows)
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Disaster Cash (In the event your home becomes uninhabitable
                    or inaccessible)
                  </li>
                </ul>
                <a
                  href="#residential-coverage"
                  className="ChubbClaimsView-block-content-uncoveredLink"
                  data-modal-id={this.MODALS.RESIDENTIAL}
                  onClick={this.showModal}
                >
                  What We Do Not Cover
                </a>
              </div>
            </div>

            <div className="ChubbClaimsView-block ChubbClaimsView-block--personal">
              <div className="ChubbClaimsView-block-content">
                <h3 className="ChubbClaimsView-block-content-title">
                  Personal Accident Insurance
                </h3>
                <h4 className="ChubbClaimsView-block-content-heading">
                  What we cover:
                </h4>
                <ul className="ChubbClaimsView-block-content-list">
                  <li className="ChubbClaimsView-block-content-listItem">
                    Accidental Death
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Accidental Disability
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    Non-medical expense cover as a result of hospitalisation
                  </li>
                  <li className="ChubbClaimsView-block-content-listItem">
                    50% Additional Payout for Motor Vehicle Acciden
                  </li>
                </ul>
                <a
                  href="#personal-coverage"
                  className="ChubbClaimsView-block-content-uncoveredLink"
                  data-modal-id={this.MODALS.PERSONAL}
                  onClick={this.showModal}
                >
                  What We Do Not Cover
                </a>
              </div>
              <div className="ChubbClaimsView-block-image" />
            </div>

            <div className="ChubbClaimsView-twoUp">
              <div className="ChubbClaimsView-twoUp-item">
                <div className="ChubbClaimsView-twoUp-item-content">
                  <h4 className="ChubbClaimsView-twoUp-item-content-title">
                    Submitting your claim
                  </h4>
                  <a
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/carsafe_assets/Vehicle+Claim+Form.pdf"
                    className="ChubbClaimsView-twoUp-item-content-cta"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Vehicle Claim Form
                  </a>
                  <a
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/homesafe_assets/Residential+Insurance+Claim+Form_opt.pdf"
                    className="ChubbClaimsView-twoUp-item-content-cta"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Residential Claim Form
                  </a>
                  <a
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/Personal+Accident+Forms.zip"
                    className="ChubbClaimsView-twoUp-item-content-cta"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Personal Accident Claim Forms
                  </a>
                  <a
                    href="#claims"
                    className="ChubbClaimsView-twoUp-item-content-moreInfoLink"
                    data-modal-id={this.MODALS.CLAIMS}
                    onClick={this.showModal}
                  >
                    Claims Instructions
                  </a>
                </div>
              </div>
              <div className="ChubbClaimsView-twoUp-item">
                <div className="ChubbClaimsView-twoUp-item-content">
                  <h4 className="ChubbClaimsView-twoUp-item-content-title">
                    Required Documents
                  </h4>
                  <ul className="ChubbClaimsView-twoUp-item-content-list">
                    <li className="ChubbClaimsView-twoUp-item-content-listItem">
                      Completed Claim Form
                    </li>
                    <li className="ChubbClaimsView-twoUp-item-content-listItem">
                      Your South Africa ID card or green barcoded ID book
                    </li>
                  </ul>
                  <p className="ChubbClaimsView-twoUp-item-content-info">
                    In addition, the following supporting documents are
                    required:
                    <br />
                    <a
                      href="#vehicle-contents-requirements"
                      className="ChubbClaimsView-twoUp-item-content-moreInfoLink"
                      data-modal-id={this.MODALS.VEHICLE_CONTENTS_REQS}
                      onClick={this.showModal}
                    >
                      Vehicle Contents Insurance Requirements
                    </a>
                    <a
                      href="#residential-requirements"
                      className="ChubbClaimsView-twoUp-item-content-moreInfoLink"
                      data-modal-id={this.MODALS.RESIDENTIAL_REQS}
                      onClick={this.showModal}
                    >
                      Residential Insurance Requirements
                    </a>
                    <a
                      href="#personal-accident-requirements"
                      className="ChubbClaimsView-twoUp-item-content-moreInfoLink"
                      data-modal-id={this.MODALS.PERSONAL_ACCIDENT_REQS}
                      onClick={this.showModal}
                    >
                      Personal Accident Insurance Requirements
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterChubb />
        <Modal
          className="ChubbClaimsView-modal"
          title="What Vehicle Content Insurance Does Not Cover"
          isActive={this.state.activeModal === this.MODALS.VEHICLE}
          onDismiss={this.hideModal}
        >
          <p>
            This Policy does not cover the following unless specially mentioned
            in the Policy Wording:
          </p>
          <ul>
            <li>
              If the theft is as a result of no forcible and/or violent entry
              into the car.
            </li>
            <li>
              For theft of personal possessions, personal electronic items, keys
              and/or access cards, cards and/or important documents from
              automobiles or motorcycles were these items are not stored out of
              sight in a locked compartment, regardless if the vehicle is locked
              or unlocked.
            </li>
            <li>
              Loss of your personal possessions, personal electronic items; your
              keys and/or access cards and your cards and/or important
              documents.
            </li>
            <li>Counterfeit or fake goods</li>
            <li>War or any act of war.</li>
          </ul>
          <p>
            <small>
              For a full list of policy exclusions please consult the policy
              wording
            </small>
          </p>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="What Residential Insurance Does Not Cover"
          isActive={this.state.activeModal === this.MODALS.RESIDENTIAL}
          onDismiss={this.hideModal}
        >
          <p>
            This Policy does not cover the following unless specially mentioned
            in the Policy Wording:
          </p>
          <ul>
            <li>
              Precious stones, medals, coins, stamp collections, coin
              collections, curiosities, sculptures, manuscripts, rare books or
              documents of any kind.
            </li>
            <li>
              Deeds, bonds, bills of exchange, promissory notes, money or
              securities for money, monetary instruments, stamps, business books
              or documents, books of accounts, cheques, share certificates,
              tickets, stamps, plans, patterns, models, moulds, designs,
              specifications, blue prints, document of title to goods, contracts
              or other legal documents or documents of any other kind.
            </li>
            <li>
              Loss or damage where any inmate or member of Your household or of
              Your business staff or any other person lawfully on the premises
              is concerned in the actual theft of or damage to any of the
              articles or premises or where such loss or damage has been
              expedited or in any way assisted or brought about by any such
              person or persons.
            </li>
            <li>
              Loss or damage caused by wear and tear or gradual deterioration.
            </li>
            <li>
              Theft or attempted theft from yards, gardens, open spaces or
              out-buildings.
            </li>
          </ul>
          <p>
            <small>
              For a full list of policy exclusions please consult the policy
              wording
            </small>
          </p>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="What Personal Accident Insurance Does Not Cover"
          isActive={this.state.activeModal === this.MODALS.PERSONAL}
          onDismiss={this.hideModal}
        >
          <p>
            This Policy does not cover the following unless specially mentioned
            in the Policy Wording:
          </p>
          <ul>
            <li>
              Suicide, attempted suicide or deliberate self-inflicted injury
            </li>
            <li>
              Driving any kind of vehicle while the alcohol level is higher than
              the legal limit
            </li>
            <li>
              Taking of a drug or narcotic unless it is properly prescribed by a
              medical practitioner and was not taken for the treatment of any
              drug addiction
            </li>
            <li>Any illness or disease</li>
            <li>
              Dental or optical treatment unless as a result of bodily Injury
            </li>
            <li>
              Bacterial or viral infection except bacterial infection that is
              the direct result of an accidental cut or wound directly following
              bodily Injury
            </li>
            <li>
              Childbirth, abortion, miscarriage, pregnancy or any related
              condition
            </li>
          </ul>
          <p>
            <small>
              For a full list of policy exclusions please consult the policy
              wording
            </small>
          </p>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="Submitting Your Claim"
          isActive={this.state.activeModal === this.MODALS.CLAIMS}
          onDismiss={this.hideModal}
        >
          <p>
            Complete one of the downloadable claim forms in full and submit it
            to <a href="mailto:myclaim@chubb.com">myclaim@chubb.com</a> within
            30 days.
          </p>
          <p>
            <small>
              Complete ALL relevant sections that relate to your claim in full.
              Missing details could delay your assessment. Attach ALL of the
              required supporting documents to your claim. Failing to do this
              could delay the assessment of your claim.
            </small>
          </p>
          <p>
            <small>
              Once your claim is submitted you should receive confirmation of
              your claim number – expect to wait 3 days for emailed claims and 5
              days for posted claims. If you have a query about your claim or
              its status, please contact the Chubb claims department on{' '}
              <a href="tel:0860223252">0860 223 252</a>
              or <a href="mailto:myclaim@chubb.com">myclaim@chubb.com</a>.
            </small>
          </p>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="Personal Accident Insurance Required Documents"
          isActive={
            this.state.activeModal === this.MODALS.PERSONAL_ACCIDENT_REQS
          }
          onDismiss={this.hideModal}
        >
          <p>Personal Accident – Accidental Death:</p>
          <ul>
            <li>Letter of executorship.</li>
            <li>Copy of Estate late account details.</li>
            <li>Certified copy of death certificate.</li>
            <li>
              Death Notification form (BI-1663) –obtained at funeral
              undertakers.
            </li>
            <li>
              Post Mortem Report. • Blood Alcohol Results – if taken and if
              relevant.
            </li>
            <li>Road Accident Report if a motor vehicle accident.</li>
            <li>SAP Statement (completed by SAPS investigating officer).</li>
            <li>
              Inquest report / witness statements / Newspaper Clippings (in
              cases of suicide / murder).
            </li>
          </ul>
          <p>Personal Accident – Accidental Disability:</p>
          <p>
            <small>
              Initial, Progress and Final Medical Reports from the attending
              doctor/ specialist indicating the following:
            </small>
          </p>
          <ul>
            <li>The injuries sustained in the accident.</li>
            <li>
              The prognosis and whether the condition is likely to improve with
              further treatment.
            </li>
            <li>When the condition is likely to stabilise.</li>
            <li>
              Any underlying illness / condition that has contributed to the
              disability.
            </li>
            <li>
              Indicate the functional impairments and express as a percentage
              (%) the extent of possible permanent disability.
            </li>
            <li>
              Is the insured likely to resume his usual or any occupation?
            </li>
          </ul>
          <p>Personal Accident – Hospitalization:</p>
          <ul>
            <li>Copy of hospital account</li>
          </ul>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="Residential Insurance Required Documents"
          isActive={this.state.activeModal === this.MODALS.RESIDENTIAL_REQS}
          onDismiss={this.hideModal}
        >
          <ul>
            <li>Any document that demonstrates proof of ownership.</li>
            <li>
              Any document that adequately supports the amount claimed
              (replacement invoices or repair quotes).
            </li>
            <li>A description of the damage you are claiming for.</li>
            <li>Photographs taken from the scene (if any were taken).</li>
            <li>
              Proof of forcible entry or exit to your home (where necessary).
            </li>
            <li>The police reference number (case number).</li>
          </ul>
        </Modal>
        <Modal
          className="ChubbClaimsView-modal"
          title="Vehicle Contents Insurance Required Documents"
          isActive={
            this.state.activeModal === this.MODALS.VEHICLE_CONTENTS_REQS
          }
          onDismiss={this.hideModal}
        >
          <ul>
            <li>
              Written affidavit from the police or relevant authority
              twenty-four (24) hours of the incident occurring.
            </li>
            <li>Any additional relevant documents issued by the police.</li>
            <li>
              Original invoices/receipts that verify the ownership and value of
              the items.
            </li>
          </ul>
        </Modal>
      </div>
    );
  }
}
