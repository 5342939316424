'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentersApp/views/RentersQuoteOverviewAssurant/RentersQuoteOverviewAssurant.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDates = require('react-dates');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _compact = require('lodash/compact');

var _compact2 = _interopRequireDefault(_compact);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _uniq = require('lodash/uniq');

var _uniq2 = _interopRequireDefault(_uniq);

var _sureComponents = require('sure-components');

var _utils = require('shared/lib/utils');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _SectionWrapper = require('shared/lib/components/SectionWrapper');

var _SectionWrapper2 = _interopRequireDefault(_SectionWrapper);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentersQuoteOverviewAssurant.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  onUpdateSelectedAmount: _propTypes2.default.func,
  onUpdateStartDate: _propTypes2.default.func,
  plan: _propTypes2.default.object,
  policyStartDate: _propTypes2.default.object,
  rates: _propTypes2.default.object,
  selectedAmounts: _propTypes2.default.object
};

var RentersQuoteOverviewAssurant = function (_PureComponent) {
  _inherits(RentersQuoteOverviewAssurant, _PureComponent);

  function RentersQuoteOverviewAssurant(props) {
    _classCallCheck(this, RentersQuoteOverviewAssurant);

    var _this = _possibleConstructorReturn(this, (RentersQuoteOverviewAssurant.__proto__ || Object.getPrototypeOf(RentersQuoteOverviewAssurant)).call(this, props));

    _this.handleUpdateStartDate = function (momentObj) {
      if (momentObj && !momentObj.isSame(_this.props.policyStartDate)) {
        analytics.track('Renters - Edit Start Date');

        _this.props.onUpdateStartDate(momentObj);
      }
    };

    _this.handleDatePickerFocus = function (_ref) {
      var focused = _ref.focused;

      _this.setState({
        isDatePickerOpen: focused
      });
    };

    _this.isMobile = window.innerWidth < 768;
    _this.state = {
      isDatePickerOpen: false
    };
    return _this;
  }

  _createClass(RentersQuoteOverviewAssurant, [{
    key: 'getFieldTitle',
    value: function getFieldTitle(key) {
      return (0, _get2.default)(this.props.plan, 'details.' + key + '.title');
    }
  }, {
    key: 'getFieldDescription',
    value: function getFieldDescription(key) {
      return (0, _get2.default)(this.props.plan, 'details.' + key + '.description');
    }
  }, {
    key: 'getFieldValue',
    value: function getFieldValue(key) {
      return (0, _get2.default)(this.props.plan, 'details.' + key + '.value');
    }
  }, {
    key: 'handleUpdateSelectedAmount',
    value: function handleUpdateSelectedAmount(e, key) {
      var value = e.target.value;

      try {
        value = JSON.parse(value);
      } catch (e) {// Do nothing
      }

      analytics.track('Renters - Edit Selected Amount', {
        category: 'quote',
        label: key,
        value: value
      });

      var updatedAmounts = _defineProperty({}, key, value);

      if (key === 'personal_property_coverage') {
        var constrainedObjs = (0, _filter2.default)(this.props.rates.personal_property, {
          personal_property_coverage: value
        });
        var allPerilVals = (0, _uniq2.default)((0, _map2.default)(constrainedObjs, 'all_peril_deductible'));
        var hurricaneVals = (0, _compact2.default)((0, _uniq2.default)((0, _map2.default)(constrainedObjs, 'hurricane_deductible')));
        updatedAmounts.all_peril_deductible = allPerilVals[0];
        updatedAmounts.hurricane_deductible = hurricaneVals[0];
      }

      this.props.onUpdateSelectedAmount(updatedAmounts);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          policyStartDate = _props.policyStartDate,
          rates = _props.rates,
          selectedAmounts = _props.selectedAmounts;
      return _react2.default.createElement('div', {
        className: 'RentersQuoteOverviewAssurant',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 152
        },
        __self: this
      }, _react2.default.createElement(_SectionWrapper2.default, {
        id: 'policy_effective_date',
        title: 'Start date',
        note: 'The date you want your policy to take effect.',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 153
        },
        __self: this
      }, _react2.default.createElement(_reactDates.SingleDatePicker, {
        date: policyStartDate,
        focused: this.state.isDatePickerOpen,
        required: true,
        block: true,
        displayFormat: 'MM/DD/YYYY',
        isOutsideRange: function isOutsideRange(day) {
          return day.isBefore((0, _momentTimezone2.default)().tz('America/New_York')) || day.isAfter((0, _momentTimezone2.default)().tz('America/New_York').add(90, 'days'));
        },
        numberOfMonths: 1,
        orientation: this.isMobile ? 'vertical' : 'horizontal',
        withFullScreenPortal: this.isMobile,
        onDateChange: this.handleUpdateStartDate,
        onFocusChange: this.handleDatePickerFocus,
        hideKeyboardShortcutsPanel: true,
        id: 'policy_effective_date',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 158
        },
        __self: this
      })), _react2.default.createElement(_SectionWrapper2.default, {
        id: 'personal_property_coverage',
        title: this.getFieldTitle('personal_property_coverage'),
        note: this.getFieldDescription('personal_property_coverage'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 182
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'personal_property_coverage',
        value: (0, _get2.default)(selectedAmounts, 'personal_property_coverage'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'personal_property_coverage');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 187
        },
        __self: this
      }, this.personalPropertySelectOptions)), _react2.default.createElement(_SectionWrapper2.default, {
        id: 'all_peril_deductible',
        title: this.getFieldTitle('all_peril_deductible'),
        note: this.getFieldDescription('all_peril_deductible'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 198
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'all_peril_deductible',
        value: (0, _get2.default)(selectedAmounts, 'all_peril_deductible'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'all_peril_deductible');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 203
        },
        __self: this
      }, this.personalPropertyDeductibleOptions)), !(0, _isEmpty2.default)(this.personalPropertyHurricaneOptions) && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'hurricane_deductible',
        title: this.getFieldTitle('hurricane_deductible'),
        note: this.getFieldDescription('hurricane_deductible'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 215
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'hurricane_deductible',
        value: (0, _get2.default)(selectedAmounts, 'hurricane_deductible'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'hurricane_deductible');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 220
        },
        __self: this
      }, this.personalPropertyHurricaneOptions)), _react2.default.createElement(_SectionWrapper2.default, {
        id: 'liability_limit',
        title: this.getFieldTitle('liability_limit'),
        note: this.getFieldDescription('liability_limit'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 232
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'liability_limit',
        value: (0, _get2.default)(selectedAmounts, 'liability_limit'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'liability_limit');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 237
        },
        __self: this
      }, this.liabilityLimitOptions)), _react2.default.createElement(_SectionWrapper2.default, {
        id: 'loss_of_use',
        title: this.getFieldTitle('loss_of_use'),
        note: this.getFieldDescription('loss_of_use'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 248
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'loss_of_use',
        value: 'default',
        disabled: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 253
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'default',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 254
        },
        __self: this
      }, 'Included'))), rates.allow_replacement_cost && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'replacement_cost',
        title: this.getFieldTitle('replacement_cost'),
        note: this.getFieldDescription('replacement_cost'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 259
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'include_replacement_cost',
        value: (0, _get2.default)(selectedAmounts, 'include_replacement_cost'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'include_replacement_cost');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 264
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'false',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 271
        },
        __self: this
      }, 'Not Included'), _react2.default.createElement('option', {
        value: 'true',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 272
        },
        __self: this
      }, 'Included'))), rates.allow_water_backup && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'water_backup',
        title: this.getFieldTitle('water_backup'),
        note: this.getFieldDescription('water_backup'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 278
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'include_water_backup',
        value: (0, _get2.default)(selectedAmounts, 'include_water_backup'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'include_water_backup');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 283
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'false',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 290
        },
        __self: this
      }, 'Not Included'), _react2.default.createElement('option', {
        value: 'true',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 291
        },
        __self: this
      }, 'Included'))), rates.allow_identity_fraud && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'identity_fraud',
        title: this.getFieldTitle('identity_fraud'),
        note: this.getFieldDescription('identity_fraud'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 297
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'include_identity_fraud',
        value: (0, _get2.default)(selectedAmounts, 'include_identity_fraud'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'include_identity_fraud');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 302
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'false',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 309
        },
        __self: this
      }, 'Not Included'), _react2.default.createElement('option', {
        value: 'true',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 310
        },
        __self: this
      }, 'Included'))), rates.allow_earthquake && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'earthquake_property_coverage',
        title: this.getFieldTitle('earthquake_property_coverage'),
        note: this.getFieldDescription('earthquake_property_coverage'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 316
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.Select, {
        name: 'include_earthquake',
        value: (0, _get2.default)(selectedAmounts, 'include_earthquake'),
        onChange: function onChange(e) {
          return _this2.handleUpdateSelectedAmount(e, 'include_earthquake');
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 321
        },
        __self: this
      }, _react2.default.createElement('option', {
        value: 'false',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 328
        },
        __self: this
      }, 'Not Included'), _react2.default.createElement('option', {
        value: 'true',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 329
        },
        __self: this
      }, 'Included'))), rates.allow_earthquake && _react2.default.createElement(_SectionWrapper2.default, {
        id: 'earthquake_deductible',
        title: this.getFieldTitle('earthquake_deductible'),
        note: this.getFieldDescription('earthquake_deductible'),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 334
        },
        __self: this
      }), _react2.default.createElement('br', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 341
        },
        __self: this
      }));
    }
  }, {
    key: 'personalPropertySelectOptions',
    get: function get() {
      var _this3 = this;

      var uniqValues = (0, _uniq2.default)((0, _map2.default)(this.props.rates.personal_property, 'personal_property_coverage'));
      return (0, _map2.default)(uniqValues, function (val) {
        return _react2.default.createElement('option', {
          value: val,
          key: val,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 41
          },
          __self: _this3
        }, '$', (0, _utils.numberWithCommas)(val));
      });
    }
  }, {
    key: 'personalPropertyDeductibleOptions',
    get: function get() {
      var _this4 = this;

      var constrainedObjs = (0, _filter2.default)(this.props.rates.personal_property, {
        personal_property_coverage: this.props.selectedAmounts.personal_property_coverage
      });
      var uniqValues = (0, _uniq2.default)((0, _map2.default)(constrainedObjs, 'all_peril_deductible'));
      return (0, _map2.default)(uniqValues, function (val) {
        return _react2.default.createElement('option', {
          value: val,
          key: val,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 57
          },
          __self: _this4
        }, '$', (0, _utils.numberWithCommas)(val));
      });
    }
  }, {
    key: 'personalPropertyHurricaneOptions',
    get: function get() {
      var _this5 = this;

      var constrainedObjs = (0, _filter2.default)(this.props.rates.personal_property, {
        personal_property_coverage: this.props.selectedAmounts.personal_property_coverage
      });
      var uniqValues = (0, _compact2.default)((0, _uniq2.default)((0, _map2.default)(constrainedObjs, 'hurricane_deductible')));
      return (0, _map2.default)(uniqValues, function (val) {
        return _react2.default.createElement('option', {
          value: val,
          key: val,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 75
          },
          __self: _this5
        }, '$', (0, _utils.numberWithCommas)(val));
      });
    }
  }, {
    key: 'liabilityLimitOptions',
    get: function get() {
      var _this6 = this;

      var uniqValues = (0, _uniq2.default)((0, _map2.default)(this.props.rates.liability, 'liability_limit'));
      return (0, _map2.default)(uniqValues, function (val) {
        return _react2.default.createElement('option', {
          value: val,
          key: val,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 86
          },
          __self: _this6
        }, '$', (0, _utils.numberWithCommas)(val));
      });
    }
  }]);

  return RentersQuoteOverviewAssurant;
}(_react.PureComponent);

RentersQuoteOverviewAssurant.propTypes = propTypes;
exports.default = RentersQuoteOverviewAssurant;