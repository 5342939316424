'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  baggage: {
    description: 'Baggage Protection',
    code: 'baggage',
    icon: 'work',
    title: 'Baggage',
    claimable: true
  },
  collectibles: {
    description: 'Collectibles Insurance',
    code: 'collectibles',
    icon: 'widgets',
    title: 'Collectibles',
    claimable: true
  },
  devices: {
    description: 'Smartphone Protection',
    code: 'devices',
    icon: 'smartphone',
    title: 'Smartphone',
    claimable: true
  },
  flights: {
    description: 'Flight Protection',
    code: 'flights',
    icon: 'airplanemode_active',
    title: 'Flight',
    claimable: true
  },
  pet: {
    description: 'Pet Insurance',
    code: 'pet',
    icon: 'pets',
    title: 'Pet',
    claimable: true
  },
  rental_car: {
    description: 'Rental Car Protection',
    code: 'rental_car',
    icon: 'directions_car',
    title: 'Rental Car',
    claimable: true
  },
  renters: {
    description: 'Renters Insurance',
    code: 'renters',
    icon: 'location_city',
    title: 'Renters',
    claimable: true
  },
  taxi: {
    description: 'Rideshare Protection',
    code: 'taxi',
    icon: 'local_taxi',
    title: 'Rideshare',
    claimable: true
  },
  commercial: {
    description: 'Small Business Insurance',
    code: 'commercial',
    icon: 'store_mall_directory',
    title: 'Small Business',
    claimable: true
  },
  landlord: {
    description: 'Landlord Protection',
    code: 'landlord',
    icon: 'location_city',
    title: 'Landlord',
    claimable: true
  },
  carsafe: {
    description: 'Vehicle Contents Insurance',
    code: 'carsafe',
    icon: 'directions_car',
    title: 'CarSafe',
    claimable: false
  },
  homesafe: {
    description: 'Residential Insurance',
    code: 'homesafe',
    icon: 'location_city',
    title: 'HomeSafe',
    claimable: false
  }
};