import React, { PureComponent } from 'react';
import * as analytics from 'shared/lib/analytics';
import { Link } from 'react-router';
import Button from 'shared/lib/components/Button';
import Modal from 'shared/lib/components/Modal';
import FooterChubb from '../FooterChubb';
import HeroChubb from '../HeroChubb';
import TopNavBarChubb from '../TopNavBarChubb';
import classnames from 'classnames';
import COUNTRIES from '../../constants/countries';
import heroImage from './images/landing-carsafe.png';
import iconCarImage from './images/icon-car.png';
import iconDocImage from './images/icon-doc.png';
import './LandingCarSafe.css';

export default class LandingCarSafe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'ZA',
      isErrorShown: false,
      isRejectModalOpen: false,
    };
  }

  componentDidMount() {
    analytics.page('CarSafe - Landing');
  }

  handleUpdateSelect = e => {
    const country = e.target.value;
    analytics.track('CarSafe - Country Selected', {
      label: country,
    });
    this.setState({
      selectedCountry: country,
    });
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    if (!this.state.selectedCountry) {
      this.setState({
        isErrorShown: true,
      });
      window.scrollTo(0, 0);
      analytics.track('CarSafe - No Country Error Shown');
    } else if (this.state.selectedCountry === 'ZA') {
      analytics.track('CarSafe - Country Submitted', {
        value: parseInt(e.currentTarget.dataset.submitLocation, 10),
      });
      this.props.navTo('/vehicle-contents-insurance/plans');
    } else {
      this.setState({
        isErrorShown: false,
        isRejectModalOpen: true,
      });
      analytics.track('CarSafe - No Offers Modal Shown', {
        label: this.state.selectedCountry,
      });
    }
  };

  hideRejectModal = () => {
    this.setState({
      isRejectModalOpen: false,
    });
  };

  render() {
    return (
      <div className="LandingCarSafe ChubbTheme">
        <TopNavBarChubb />
        <HeroChubb accentColor="#01c1d6" bgImage={heroImage}>
          <h1 className="LandingCarSafe-heroTitle">
            Protect your personal valuables against theft from your vehicle.
          </h1>
          <form
            className="LandingCarSafe-heroForm"
            data-submit-location="0"
            onSubmit={this.handleSubmit}
          >
            <div className="LandingCarSafe-heroForm-select">
              <select
                className={classnames('LandingCarSafe-heroForm-select-input', {
                  'has-error': this.state.isErrorShown,
                })}
                name="residence_country"
                onChange={this.handleUpdateSelect}
              >
                {COUNTRIES.map(country => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
              <i className="LandingCarSafe-heroForm-select-icon fas fa-chevron-down" />
            </div>
            <button className="LandingCarSafe-heroForm-submit" type="submit">
              View Plans
            </button>
          </form>
          {this.state.isErrorShown && (
            <p className="LandingCarSafe-errorMsg">
              Please select your country
            </p>
          )}
        </HeroChubb>
        <div className="LandingCarSafe-content">
          <div className="Container">
            <h2 className="LandingCarSafe-content-title">
              <img
                className="LandingCarSafe-content-title-icon"
                src={iconCarImage}
                alt="Car icon"
              />
              Vehicle Contents Insurance
            </h2>
            <p className="LandingCarSafe-content-info">
              Our Vehicle Contents Insurance provides you with cover in the
              event of theft as a result of forcible entry into your vehicle.
              For further peace of mind, it provides cover for accidental death
              and disability as a result of a motor vehicle accident.
            </p>
            <h3 className="LandingCarSafe-content-productTitle">
              Product information
            </h3>
            <div className="LandingCarSafe-threeUp">
              <div className="LandingCarSafe-threeUp-item">
                <div className="LandingCarSafe-threeUp-item-content">
                  <h4 className="LandingCarSafe-threeUp-item-content-title">
                    Coverage Highlights
                  </h4>
                  <ul className="LandingCarSafe-threeUp-item-content-list">
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      Wallet & handbag (Theft of personal possessions)
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      Tech Safe (Theft of personal electronic items)
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      Home Lock and Key Cover (In the event these items are
                      stolen)
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      Identity Theft (Cards and important documents)
                    </li>
                  </ul>
                  <button
                    className="LandingCarSafe-threeUp-item-content-cta"
                    data-submit-location="1"
                    onClick={this.handleSubmit}
                  >
                    Get a Quote
                  </button>
                </div>
              </div>
              <div className="LandingCarSafe-threeUp-item">
                <div className="LandingCarSafe-threeUp-item-content">
                  <h4 className="LandingCarSafe-threeUp-item-content-title">
                    FAQs
                  </h4>
                  <ul className="LandingCarSafe-threeUp-item-content-list">
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      What is the ‘standard time frame’ in which you pay out?
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      Will I be covered in the event of car jamming?
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      In the event of a theft claim, are invoices and/or
                      receipts for the items stolen required?
                    </li>
                    <li className="LandingCarSafe-threeUp-item-content-listItem">
                      What do I need to submit when logging a claim?
                    </li>
                  </ul>
                  <Link
                    className="LandingCarSafe-threeUp-item-content-cta"
                    to="/vehicle-contents-insurance/faq"
                  >
                    View All
                  </Link>
                </div>
              </div>
              <div className="LandingCarSafe-threeUp-item LandingCarSafe-threeUp-item--policyDocs">
                <div className="LandingCarSafe-threeUp-item-content">
                  <h4 className="LandingCarSafe-threeUp-item-content-title">
                    Policy Documents
                  </h4>
                  <p className="LandingCarSafe-threeUp-item-content-info">
                    Vehicle Contents Insurance policy documents can be
                    downloaded below in PDF format.
                  </p>
                  <a
                    className="LandingCarSafe-threeUp-item-content-link"
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/carsafe_assets/vehicle_contents_cover_policy_wording.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="LandingCarSafe-threeUp-item-content-link-icon"
                      src={iconDocImage}
                      alt="PDF icon"
                    />
                    <span>Policy Wording</span>
                    <i className="fas fa-caret-right" />
                  </a>
                  <a
                    className="LandingCarSafe-threeUp-item-content-link"
                    href="https://s3.amazonaws.com/sure-media/pdf/chubb/carsafe_assets/vehicle_contents_personal_lines_insurance.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="LandingCarSafe-threeUp-item-content-link-icon"
                      src={iconDocImage}
                      alt="PDF icon"
                    />
                    <span>Key Information Document</span>
                    <i className="fas fa-caret-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="LandingCarSafe-claimsCallout">
              <h4 className="LandingCarSafe-claimsCallout-heading">
                Submit a claim
              </h4>
              <Link
                className="LandingCarSafe-claimsCallout-cta"
                to="/chubb/claims"
              >
                Start a Claim
              </Link>
            </div>
          </div>
        </div>
        <FooterChubb />
        <Modal
          className="LandingCarSafe-rejectModal"
          title="Error"
          isActive={this.state.isRejectModalOpen}
          onDismiss={this.hideRejectModal}
        >
          <p>
            Looks like you live outside of South Africa. Unfortunately we do not
            have any plans available for you at the moment.
          </p>
          <Button
            onClick={this.hideRejectModal}
            style={{
              backgroundColor: 'rgb(86,191,211)',
            }}
          >
            Go back to Chubb
          </Button>
        </Modal>
      </div>
    );
  }
}
