import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextInput, PagerButtons } from 'sure-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Button from 'shared/lib/components/Button';
import Modal from 'shared/lib/components/Modal';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import AddressForm from 'shared/lib/components/AddressForm';
import TelInput from 'shared/lib/components/TelInput';
import * as analytics from 'shared/lib/analytics';
import './HomeSafePolicyHolderView.css';

const propTypes = {
  homeSafeDetails: PropTypes.object,
  homeSafeSelectedPlan: PropTypes.object,
  updateHomeSafeDetails: PropTypes.func,
};

const defaultProps = {
  homeSafeDetails: {},
  homeSafeSelectedPlan: {},
  updateHomeSafeDetails: () => {},
};

class HomeSafePolicyHolderView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInProgress: false,
      isRejectModalOpen: false,
    };
  }

  componentDidMount() {
    analytics.page('HomeSafe - Policy Holder Info');
  }

  handleBack = () => {
    const { homeSafeSelectedPlan, navTo } = this.props;
    if (isEmpty(homeSafeSelectedPlan.details.plan_add_ons)) {
      navTo('/residential-insurance/plans');
    } else {
      navTo('/residential-insurance/additional-benefits');
    }
  };

  handleChange = e => {
    this.props.updateHomeSafeDetails({
      [e.target.name]: e.target.value,
    });
  };

  handlePhoneChange = val => {
    this.props.updateHomeSafeDetails({
      phone: val,
    });
  };

  handleNext = e => {
    if (e) {
      e.preventDefault();
    }
    const {
      updateHomeSafeDetails,
      updateHomeSafeQuote,
      navTo,
      onError,
    } = this.props;

    analytics.track('HomeSafe - Policy Holder Info Submitted');
    this.setState({
      isInProgress: true,
    });
    this.residenceAddressInput
      .getGeocodedValue()
      .then(location => {
        updateHomeSafeDetails({
          lat: location.lat,
          lng: location.lng,
          country: location.country,
          postal: location.postal,
        });
        if (location.country === 'ZA') {
          return updateHomeSafeQuote().then(() => {
            navTo('/residential-insurance/add-beneficiary');
          });
        } else {
          analytics.track('HomeSafe - No Offers Modal Shown', {
            label: location.country,
          });
          this.setState({
            isInProgress: false,
            isRejectModalOpen: true,
          });
        }
      })
      .catch(error => {
        this.setState({
          isInProgress: false,
        });
        onError(error);
      });
  };

  hideRejectModal = () => {
    this.setState({
      isRejectModalOpen: false,
    });
  };

  trackDocClick = () => {
    analytics.track('HomeSafe - Key Information Doc Link Clicked', {
      label: this.props.homeSafeSelectedPlanId,
    });
  };

  render() {
    const {
      homeSafeDetails,
      homeSafeSelectedPlan,
      disclaimer,
      updateHomeSafeDetails,
    } = this.props;

    return (
      <div className="HomeSafePolicyHolderView">
        <div className="Container Container--small">
          <h2 className="HomeSafePolicyHolderView-planTitle">
            HomeSafe: {get(homeSafeSelectedPlan, 'information.title')}
          </h2>
          <a
            className="HomeSafePolicyHolderView-samplePolicyLink"
            href={homeSafeSelectedPlan.policy_doc_url}
            target="_blank"
            rel="noreferrer noopener"
            onClick={this.trackDocClick}
          >
            View Key Information Document
          </a>
          <h1 className="HomeSafePolicyHolderView-heading">
            What is the policy holder information?
          </h1>
          <form
            className="HomeSafePolicyHolderView-form"
            onSubmit={this.handleNext}
          >
            <FormGroup>
              <label>Contact info</label>
              <TextInput
                name="firstName"
                placeholder="First name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={homeSafeDetails.firstName || ''}
                onChange={this.handleChange}
              />
              <TextInput
                name="lastName"
                placeholder="Last name"
                required
                pattern="^[A-z ]{1,40}$"
                minLength="1"
                maxLength="40"
                value={homeSafeDetails.lastName || ''}
                onChange={this.handleChange}
              />
              <TelInput
                required
                className="HomeSafePolicyHolderView-telInput"
                defaultCountry="za"
                onlyCountries={['za']}
                onChange={this.handlePhoneChange}
                value={homeSafeDetails.phone}
              />
              <TextInput
                name="email"
                placeholder="Email"
                type="email"
                required
                value={homeSafeDetails.email || ''}
                onChange={this.handleChange}
              />
            </FormGroup>
            <label>Policyholder ID Number</label>
            <TextInput
              name="policyholderId"
              placeholder="ID Number"
              required
              pattern="\d{13}"
              minLength="13"
              maxLength="13"
              value={homeSafeDetails.policyholderId || ''}
              onChange={this.handleChange}
            />
            <label>Residence</label>
            <AddressForm
              streetAddress={homeSafeDetails.streetAddress}
              unit={homeSafeDetails.unit}
              city={homeSafeDetails.city}
              region={homeSafeDetails.region}
              postal={homeSafeDetails.postal}
              country={homeSafeDetails.country}
              onChange={updateHomeSafeDetails}
              ref={c => (this.residenceAddressInput = c)}
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              ref={c => (this.inputSubmit = c)}
            />
          </form>
          <PagerButtons
            className="HomeSafePolicyHolderView-cta"
            isInProgress={this.state.isInProgress}
            onBack={this.handleBack}
            onNext={() => this.inputSubmit.click()}
          />
          <PlanDisclaimer
            showBuiltOnLogo
            style={{ textAlign: 'center' }}
            text={disclaimer}
          />
        </div>
        <Modal
          className="HomeSafePolicyHolderView-rejectModal"
          title="No plans available"
          isActive={this.state.isRejectModalOpen}
          onDismiss={this.hideRejectModal}
        >
          <p>
            Looks like you live outside of South Africa. Unfortunately we do not
            have any plans available for you at the moment.
          </p>
          <Button
            onClick={this.hideRejectModal}
            style={{
              backgroundColor: 'rgb(86,191,211)',
            }}
          >
            Okay
          </Button>
        </Modal>
      </div>
    );
  }
}

HomeSafePolicyHolderView.propTypes = propTypes;
HomeSafePolicyHolderView.defaultProps = defaultProps;

export default HomeSafePolicyHolderView;
