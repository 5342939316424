import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BooleanTable from '../BooleanTable';
import PlanDisclaimer from 'shared/lib/components/PlanDisclaimer';
import Button from 'shared/lib/components/Button';
import every from 'lodash/every';
import isBoolean from 'lodash/isBoolean';
import map from 'lodash/map';
import * as analytics from 'shared/lib/analytics';
import './HomeSafeIntroSurveyView.css';

const propTypes = {
  homeSafeDetails: PropTypes.object,
};

const defaultProps = {
  homeSafeDetails: {},
};

class HomeSafeIntroSurveyView extends PureComponent {
  constructor(props) {
    super(props);
    this.questions = [
      {
        title: 'Have you had a break-in at this address?',
        key: 'plan_rating_questions.has_break_ins',
      },
      {
        title: 'Do you have neighbors on all borders?',
        key: 'plan_rating_questions.has_neighbors',
      },
      {
        title:
          'Do you have any active surveillance (video cameras, guards) in or surrounding your premises?',
        key: 'plan_rating_questions.has_surveillance',
      },
    ];
  }

  componentDidMount() {
    analytics.page('HomeSafe - Intro Survey');
  }

  handleChange = details => {
    this.props.updateHomeSafeDetails(details);
  };

  handleNext = () => {
    this.props.navTo('/residential-insurance/plans');
  };

  get isNextDisabled() {
    const reqKeys = map(this.questions, 'key');
    return !every(reqKeys, key => {
      return isBoolean(this.props.homeSafeDetails[key]);
    });
  }

  render() {
    const { disclaimer, homeSafeDetails } = this.props;

    return (
      <div className="HomeSafeIntroSurveyView Container ChubbTheme">
        <h1 className="HomeSafeIntroSurveyView-heading">
          Please answer the questions below:
        </h1>

        <BooleanTable
          rows={this.questions}
          values={homeSafeDetails}
          onChange={this.handleChange}
        />
        <Button
          className="HomeSafeIntroSurveyView-cta"
          disabled={this.isNextDisabled}
          onClick={this.handleNext}
        >
          Next <i className="far fa-chevron-right" />
        </Button>
        <PlanDisclaimer text={disclaimer} showBuiltOnLogo />
      </div>
    );
  }
}

HomeSafeIntroSurveyView.propTypes = propTypes;
HomeSafeIntroSurveyView.defaultProps = defaultProps;

export default HomeSafeIntroSurveyView;
