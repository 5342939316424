'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarPlans/RentalCarPlans.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

var _utils = require('shared/lib/utils');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarPlans.css');

var _logoChubb = require('./logo-chubb.png');

var _logoChubb2 = _interopRequireDefault(_logoChubb);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  isRentalCarInProgress: _propTypes2.default.bool,
  disclaimer: _propTypes2.default.string,
  plans: _propTypes2.default.array,
  selectPlan: _propTypes2.default.func,
  selectedPlan: _propTypes2.default.object
};
var defaultProps = {
  disclaimer: null,
  isRentalCarInProgress: false,
  selectedPlan: {}
};

var RentalCarPlans = function (_Component) {
  _inherits(RentalCarPlans, _Component);

  function RentalCarPlans(props) {
    _classCallCheck(this, RentalCarPlans);

    var _this = _possibleConstructorReturn(this, (RentalCarPlans.__proto__ || Object.getPrototypeOf(RentalCarPlans)).call(this, props));

    _this.state = {
      activeTab: _this.props.plans[0].information.title
    };
    return _this;
  }

  _createClass(RentalCarPlans, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Plans');
      analytics.track('Rental Car - Plans Page Viewed', {
        noninteraction: 1
      });
    }
  }, {
    key: 'classesForTab',
    value: function classesForTab(baseClass, tabName) {
      var className = '' + baseClass;

      if (this.state.activeTab === tabName) {
        className += ' is-active';
      }

      return className;
    }
  }, {
    key: 'selectTab',
    value: function selectTab(plan, i) {
      this.setState({
        activeTab: plan.information.title
      });
      analytics.track('Rental Car - Plan Tab Selected', {
        category: 'survey',
        label: plan.plan_id,
        value: i
      });
    }
  }, {
    key: 'handleNext',
    value: function handleNext(planId, i) {
      var _this2 = this;

      analytics.track('Rental Car - Plan Selected', {
        category: 'survey',
        label: planId,
        value: i
      });
      analytics.track('AddToCart');
      this.props.selectPlan(planId).then(function () {
        _this2.props.navTo((0, _utils.resolveUrlPath)('payment'));
      }).catch(this.props.onError);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentalCarPlans Container',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 162
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarPlans-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 163
        },
        __self: this
      }, 'Select the coverage option that best protects you and your drivers:'), _react2.default.createElement('div', {
        className: 'RentalCarPlans-plans',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 166
        },
        __self: this
      }, _react2.default.createElement('ul', {
        className: 'RentalCarPlans-tabNavs',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 167
        },
        __self: this
      }, this.tabNavsNode), _react2.default.createElement('div', {
        className: 'RentalCarPlans-wrapper',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 168
        },
        __self: this
      }, this.tabsNode), _react2.default.createElement('h3', {
        className: 'RentalCarPlans-policyProviderHeading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 169
        },
        __self: this
      }, 'Policies Underwritten By'), _react2.default.createElement('img', {
        className: 'RentalCarPlans-policyProviderLogo',
        src: _logoChubb2.default,
        alt: 'Chubb logo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 172
        },
        __self: this
      })), _react2.default.createElement(_PlanDisclaimer2.default, {
        style: {
          textAlign: 'center'
        },
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 178
        },
        __self: this
      }));
    }
  }, {
    key: 'tabNavsNode',
    get: function get() {
      var _this3 = this;

      return (0, _map2.default)(this.props.plans, function (plan, i) {
        var planTitle = plan.information.title;
        var className = 'RentalCarPlans-tabNav';

        if (_this3.state.activeTab === planTitle) {
          className += ' is-active';
        }

        return _react2.default.createElement('li', {
          key: planTitle,
          className: className,
          onClick: _this3.selectTab.bind(_this3, plan, i),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 81
          },
          __self: _this3
        }, planTitle);
      });
    }
  }, {
    key: 'tabsNode',
    get: function get() {
      var _this4 = this;

      return (0, _map2.default)(this.props.plans, function (plan, i) {
        var planTitle = plan.information.title;
        var className = 'RentalCarPlans-tab';

        if (_this4.state.activeTab === planTitle) {
          className += ' is-active';
        }

        var coverageSummary = 'Covers <strong>$100,000</strong> <br/> of vehicle damage<br/><br/>';

        if (i === 1) {
          coverageSummary = 'Covers <strong>$100,000</strong> of vehicle damage, plus theft of your personal property';
        } else if (i === 2) {
          coverageSummary = 'Covers <strong>$100,000</strong> of vehicle damage, and theft of your or your family’s personal property';
        }

        var priceComponents = plan.information.pricing_title.match(/\$(\d+)\.(\d+)/);
        return _react2.default.createElement('div', {
          key: planTitle,
          className: className,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 115
          },
          __self: _this4
        }, _react2.default.createElement('h2', {
          className: 'RentalCarPlans-tab-heading',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 116
          },
          __self: _this4
        }, planTitle), _react2.default.createElement('a', {
          href: plan.policy_doc_url,
          target: '_blank',
          rel: 'noopener noreferrer',
          className: 'RentalCarPlans-tab-policyLink',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 117
          },
          __self: _this4
        }, 'View Sample Policy'), _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-price',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 125
          },
          __self: _this4
        }, _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-price-dollars',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 126
          },
          __self: _this4
        }, '$', priceComponents[1]), _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-price-cents',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 129
          },
          __self: _this4
        }, '.', priceComponents[2])), _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-pricePeriod',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 133
          },
          __self: _this4
        }, 'per day'), _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-coverageSummary',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 134
          },
          __self: _this4
        }, _react2.default.createElement('div', {
          dangerouslySetInnerHTML: {
            __html: coverageSummary
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 135
          },
          __self: _this4
        })), _react2.default.createElement(_Button2.default, {
          className: 'RentalCarPlans-tab-cta',
          disabled: _this4.props.isRentalCarInProgress,
          isInProgress: _this4.props.isRentalCarInProgress && _this4.props.selectedPlan.plan_id === plan.plan_id,
          onClick: _this4.handleNext.bind(_this4, plan.plan_id, i),
          __source: {
            fileName: _jsxFileName,
            lineNumber: 137
          },
          __self: _this4
        }, 'Select Plan'), _react2.default.createElement('div', {
          className: 'RentalCarPlans-tab-features',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 148
          },
          __self: _this4
        }, _react2.default.createElement('div', {
          dangerouslySetInnerHTML: {
            __html: plan.information.summary_html
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 149
          },
          __self: _this4
        })));
      });
    }
  }]);

  return RentalCarPlans;
}(_react.Component);

RentalCarPlans.propTypes = propTypes;
RentalCarPlans.defaultProps = defaultProps;
exports.default = RentalCarPlans;