'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/GlobalModals/ModalComponent.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _sureComponents = require('sure-components');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

require('./ModalComponent.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  title: _propTypes2.default.string,
  body: _propTypes2.default.node,
  afterDismiss: _propTypes2.default.func,
  onDismiss: _propTypes2.default.func,
  className: _propTypes2.default.string
};

var ModalInfo = function (_Component) {
  _inherits(ModalInfo, _Component);

  function ModalInfo() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ModalInfo);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ModalInfo.__proto__ || Object.getPrototypeOf(ModalInfo)).call.apply(_ref, [this].concat(args))), _this), _this.handleDismiss = function () {
      _this.props.onDismiss();

      if ((0, _isFunction2.default)(_this.props.afterDismiss)) {
        _this.props.afterDismiss();
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ModalInfo, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          body = _props.body,
          rest = _objectWithoutProperties(_props, ['title', 'body']);

      return _react2.default.createElement(_sureComponents.Modal, {
        title: title || 'Info',
        isActive: true,
        onDismiss: this.handleDismiss,
        className: (0, _classnames2.default)('ModalComponent', this.props.className),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 28
        },
        __self: this
      }, _react2.default.cloneElement(body, Object.assign({}, rest, {
        isInModal: true
      })));
    }
  }]);

  return ModalInfo;
}(_react.Component);

ModalInfo.propTypes = propTypes;
exports.default = ModalInfo;