import ACTION_TYPES from '../../constants/actionTypes';
import omit from 'lodash/omit';

export default {
  activeGlobalModal(state = null, action) {
    switch (action.type) {
      case ACTION_TYPES.SHOW_GLOBAL_MODAL:
        return action.payload.id;
      case ACTION_TYPES.HIDE_GLOBAL_MODAL:
        return null;
      default:
        return state;
    }
  },

  globalModalProps(state = {}, action) {
    switch (action.type) {
      case ACTION_TYPES.SHOW_GLOBAL_MODAL:
        return omit(action.payload, 'id') || {};
      case ACTION_TYPES.HIDE_MODAL:
        return {};
      default:
        return state;
    }
  },

  isMenuOpen(state = false, action) {
    switch (action.type) {
      case ACTION_TYPES.SHOW_MENU:
        return true;
      case ACTION_TYPES.HIDE_MENU:
        return false;
      default:
        return state;
    }
  },

  ratesSettings(state = {}, action) {
    return state;
  },

  metadata(state = {}, action) {
    return state;
  },

  isEmbed(state = false, action) {
    return state;
  },

  hasPrepopulatedData(state = false, action) {
    return state;
  },
};
