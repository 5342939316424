'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/GlobalModals/ModalError.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _sureComponents = require('sure-components');

var _Button = require('shared/lib/components/Button');

var _Button2 = _interopRequireDefault(_Button);

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  error: _propTypes2.default.object,
  info: _propTypes2.default.object,
  onDismiss: _propTypes2.default.func
};

var ModalError = function (_PureComponent) {
  _inherits(ModalError, _PureComponent);

  function ModalError() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, ModalError);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ModalError.__proto__ || Object.getPrototypeOf(ModalError)).call.apply(_ref, [this].concat(args))), _this), _this.mapErrorFields = function (fields) {
      var errorElements = [];

      var recursiveMap = function recursiveMap(fields) {
        return (0, _map2.default)(fields, function (fieldErrorMsg, fieldKey) {
          if (typeof fieldErrorMsg !== 'string') {
            recursiveMap(fieldErrorMsg);
          } else {
            errorElements = [].concat(_toConsumableArray(errorElements), [_react2.default.createElement('li', {
              key: fieldKey,
              __source: {
                fileName: _jsxFileName,
                lineNumber: 23
              },
              __self: _this2
            }, fieldErrorMsg)]);
          }
        });
      };

      recursiveMap(fields);
      return errorElements;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ModalError, [{
    key: 'render',
    value: function render() {
      var onDismiss = this.props.onDismiss;
      return _react2.default.createElement(_sureComponents.Modal, {
        title: this.title,
        isActive: true,
        onDismiss: onDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 200
        },
        __self: this
      }, this.body, _react2.default.createElement(_Button2.default, {
        onClick: onDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 202
        },
        __self: this
      }, 'Ok'));
    }
  }, {
    key: 'title',
    get: function get() {
      var error = this.props.error;

      switch (error.code) {
        case 'no_offers_found':
        case 'rating_address_rejected':
          return 'Almost ready to go';

        case 'property_address_invalid':
        case 'ZERO_RESULTS':
        case 'physical_address_required':
          return 'Invalid address';

        case 'quote_request_rejected':
        case 'ineligible_for_plan':
          return 'Coverage unavailable';

        case 'service_already_active':
        case 'policy_holder_already_exists':
          return 'Service already active';

        case 'collectibles_policy_pending':
          return 'Policy pending approval';

        case 'pet_dob_ineligible':
          return 'Pet ineligible';

        case 'pet_already_on_quote':
          return 'Pet already added';

        case 'landlord_lease_address_ineligible':
          return 'Lease ineligible';

        case 'payment_method_rejected_by_service':
          return 'Payment error';

        case 'total_collection_value_is_above_limit':
          return 'Total value exceeds limit';

        case 'invalid_input':
          return 'Invalid input';

        case 'jewelry_exit_ramp':
          return 'Almost done...';

        default:
          return 'Something went wrong';
      }
    }
  }, {
    key: 'body',
    get: function get() {
      var error = this.props.error;

      switch (error.code) {
        case 'no_offers_found':
        case 'rating_address_rejected':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 74
            },
            __self: this
          }, 'This policy is not yet available in your location. We\'ll let you know as soon as it\'s ready.');

        case 'property_address_invalid':
        case 'ZERO_RESULTS':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 82
            },
            __self: this
          }, 'There was a problem verifying your address. Please check your address and try again.');

        case 'physical_address_required':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 89
            },
            __self: this
          }, 'In order to get insurance for your collection, you must enter the physical address where the collection is stored. This cannot be a P.O. Box. Please enter the collection address in order to proceed.');

        case 'quote_request_rejected':
        case 'ineligible_for_plan':
          return error.message ? _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 98
            },
            __self: this
          }, error.message) : _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 100
            },
            __self: this
          }, 'Due to one or more of your responses, we are unable to provide you insurance at this time. We apologize for the inconvenience.');

        case 'service_already_active':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 107
            },
            __self: this
          }, 'This email address already has a policy associated with it. Please', ' ', _react2.default.createElement('a', {
            href: '/login',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 109
            },
            __self: this
          }, 'log in'), ' to manage your existing policies.');

        case 'policy_holder_already_exists':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 113
            },
            __self: this
          }, 'You may not purchase this policy more than once');

        case 'collectibles_policy_pending':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 116
            },
            __self: this
          }, 'You currently have a policy of this type that is pending approval. You cannot add another policy of this type until your initial policy is approved.');

        case 'pet_dob_ineligible':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 124
            },
            __self: this
          }, 'Your pet must be at least 8 weeks old in order to get insurance. Please return when your pet is older than 8 weeks.');

        case 'pet_already_on_quote':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 130
            },
            __self: this
          }, error.message);

        case 'landlord_duplicate_tenant_name':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 133
            },
            __self: this
          }, error.message, ' has already been added as a tenant on this lease. Please enter a different name.');

        case 'landlord_lease_address_ineligible':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 140
            },
            __self: this
          }, 'This policy does not yet cover leases on properties outside the US. Please enter a valid US address.');

        case 'payment_method_rejected_by_service':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 147
            },
            __self: this
          }, 'There was a problem processing your payment:', _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 149
            },
            __self: this
          }), _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 150
            },
            __self: this
          }), ' ', error.message);

        case 'total_collection_value_is_above_limit':
          return _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 155
            },
            __self: this
          }, 'The total value of your collection is above the allowed limit.');

        case 'invalid_input':
          return _react2.default.createElement(_react.Fragment, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 159
            },
            __self: this
          }, _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 160
            },
            __self: this
          }, 'We could not accept your request.'), !!Object.keys(error.fields).length && _react2.default.createElement('ul', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 162
            },
            __self: this
          }, this.mapErrorFields(error.fields)));

        case 'jewelry_exit_ramp':
          return _react2.default.createElement(_react.Fragment, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 168
            },
            __self: this
          }, _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 169
            },
            __self: this
          }, 'Your policy is almost ready to be issued. First, our VIP service team needs to review the items on the policy.', _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 172
            },
            __self: this
          }), _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 173
            },
            __self: this
          }), 'Simply call 1-844-335-5441 and our VIP team will take care of the rest.', _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 176
            },
            __self: this
          }), _react2.default.createElement('br', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 177
            },
            __self: this
          }), _react2.default.createElement('small', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 178
            },
            __self: this
          }, 'Note: Your payment has not yet been processed.')));

        default:
          return _react2.default.createElement(_react.Fragment, {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 184
            },
            __self: this
          }, _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 185
            },
            __self: this
          }, 'Sorry, there\'s been an error. Please retry your submission.'), _react2.default.createElement('p', {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 186
            },
            __self: this
          }, 'If the error persists, email us at', ' ', _react2.default.createElement('a', {
            href: 'mailto:support@sureapp.com',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 188
            },
            __self: this
          }, 'support@sureapp.com'), ' and we\'ll help as soon as we can. Sorry for the inconvenience.'));
      }
    }
  }]);

  return ModalError;
}(_react.PureComponent);

ModalError.propTypes = propTypes;
exports.default = ModalError;