'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/RentalCarApp/views/RentalCarAdditionalDrivers/RentalCarAdditionalDrivers.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _sureComponents = require('sure-components');

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _isBoolean = require('lodash/isBoolean');

var _isBoolean2 = _interopRequireDefault(_isBoolean);

var _utils = require('shared/lib/utils');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./RentalCarAdditionalDrivers.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  disclaimer: _propTypes2.default.string,
  hasAdditionalDrivers: _propTypes2.default.bool,
  updateHasAdditionalDrivers: _propTypes2.default.func
};
var defaultProps = {
  disclaimer: null,
  hasAdditionalDrivers: null
};

var RentalCarAdditionalDrivers = function (_Component) {
  _inherits(RentalCarAdditionalDrivers, _Component);

  function RentalCarAdditionalDrivers() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, RentalCarAdditionalDrivers);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = RentalCarAdditionalDrivers.__proto__ || Object.getPrototypeOf(RentalCarAdditionalDrivers)).call.apply(_ref, [this].concat(args))), _this), _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      analytics.track('Rental Car - Next Button Clicked', {
        category: 'survey'
      });

      if (_this.props.hasAdditionalDrivers) {
        _this.props.navTo((0, _utils.resolveUrlPath)('add-drivers'));
      } else {
        _this.props.navTo((0, _utils.resolveUrlPath)('plans'));
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(RentalCarAdditionalDrivers, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Rental Car - Has Additional Drivers', {
        category: 'survey'
      });
    }
  }, {
    key: 'handleUpdate',
    value: function handleUpdate(value) {
      this.props.updateHasAdditionalDrivers(value);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: 'RentalCarAdditionalDrivers',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 50
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 51
        },
        __self: this
      }, _react2.default.createElement('h1', {
        className: 'RentalCarAdditionalDrivers-title',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 52
        },
        __self: this
      }, 'Would you like to add additional drivers to your rental insurance?'), _react2.default.createElement('form', {
        className: 'RentalCarAdditionalDrivers-form',
        autoComplete: 'off',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 55
        },
        __self: this
      }, _react2.default.createElement(_sureComponents.RadioButton, {
        name: 'has_additional_drivers',
        value: 'true',
        title: 'Yes',
        checked: this.props.hasAdditionalDrivers === true,
        onChange: this.handleUpdate.bind(this, true),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 60
        },
        __self: this
      }), _react2.default.createElement(_sureComponents.RadioButton, {
        name: 'has_additional_drivers',
        value: 'false',
        title: 'No',
        checked: this.props.hasAdditionalDrivers === false,
        onChange: this.handleUpdate.bind(this, false),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 67
        },
        __self: this
      }), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 74
        },
        __self: this
      })), _react2.default.createElement(_sureComponents.PagerButtons, {
        isNextDisabled: this.isNextDisabled,
        onNext: this.handleNext,
        onBack: this.props.goBack,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        text: this.props.disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 81
        },
        __self: this
      })));
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      return !(0, _isBoolean2.default)(this.props.hasAdditionalDrivers);
    }
  }]);

  return RentalCarAdditionalDrivers;
}(_react.Component);

RentalCarAdditionalDrivers.propTypes = propTypes;
RentalCarAdditionalDrivers.defaultProps = defaultProps;
exports.default = RentalCarAdditionalDrivers;