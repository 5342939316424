'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/GlobalModals/ModalCollectiblesAddToExisting.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _sureComponents = require('sure-components');

var _map = require('lodash/map');

var _map2 = _interopRequireDefault(_map);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  policies: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    id: _propTypes2.default.string,
    details: _propTypes2.default.shape({
      ownership_text: _propTypes2.default.string
    })
  })),
  onSelectExistingOwner: _propTypes2.default.func,
  onCreateNewOwner: _propTypes2.default.func,
  onDismiss: _propTypes2.default.func
};

var ModalCollectiblesAddToExisting = function (_PureComponent) {
  _inherits(ModalCollectiblesAddToExisting, _PureComponent);

  function ModalCollectiblesAddToExisting() {
    _classCallCheck(this, ModalCollectiblesAddToExisting);

    return _possibleConstructorReturn(this, (ModalCollectiblesAddToExisting.__proto__ || Object.getPrototypeOf(ModalCollectiblesAddToExisting)).apply(this, arguments));
  }

  _createClass(ModalCollectiblesAddToExisting, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          onDismiss = _props.onDismiss,
          onSelectExistingOwner = _props.onSelectExistingOwner,
          policies = _props.policies,
          onCreateNewOwner = _props.onCreateNewOwner;
      return _react2.default.createElement(_sureComponents.Modal, {
        title: 'Add to an existing collection?',
        isActive: true,
        onDismiss: onDismiss,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 30
        },
        __self: this
      }, _react2.default.createElement('p', {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 35
        },
        __self: this
      }, 'Who is this collection owned by?'), (0, _map2.default)(policies, function (policy) {
        return _react2.default.createElement(_sureComponents.RadioButton, {
          key: policy.id,
          name: 'owner',
          title: policy.details.ownership_text || policy.details.ownership_entity,
          value: policy.details.ownership_text || policy.details.ownership_entity,
          onChange: function onChange() {
            return onSelectExistingOwner(policy.id);
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 37
          },
          __self: _this2
        });
      }), _react2.default.createElement(_sureComponents.RadioButton, {
        key: 'new',
        name: 'owner',
        title: 'Create a new owner',
        value: 'new',
        onChange: function onChange() {
          return onCreateNewOwner();
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 49
        },
        __self: this
      }));
    }
  }]);

  return ModalCollectiblesAddToExisting;
}(_react.PureComponent);

ModalCollectiblesAddToExisting.propTypes = propTypes;
exports.default = ModalCollectiblesAddToExisting;