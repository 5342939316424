'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/QuoteLayout/QuoteLayout.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _IconCircle = require('../IconCircle');

var _IconCircle2 = _interopRequireDefault(_IconCircle);

var _BillingSummarySection = require('../BillingSummarySection');

var _BillingSummarySection2 = _interopRequireDefault(_BillingSummarySection);

var _PlanDisclaimer = require('../PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _PricingLayout = require('../PricingLayout');

var _PricingLayout2 = _interopRequireDefault(_PricingLayout);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

require('./QuoteLayout.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  currencyCode: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  hideBillingSummary: _propTypes2.default.bool,
  icon: _propTypes2.default.string,
  billingCycle: _propTypes2.default.string,
  paymentCadences: _propTypes2.default.object,
  policyStartDate: _reactMomentProptypes2.default.momentObj,
  policyDocUrl: _propTypes2.default.string,
  pricePerCycle: _propTypes2.default.number,
  quote: _propTypes2.default.object,
  selectedPaymentCadence: _propTypes2.default.string,
  title: _propTypes2.default.string,
  totals: _propTypes2.default.object
};
var defaultProps = {
  currencyCode: 'USD',
  disclaimer: null,
  hideBillingSummary: false,
  icon: null,
  billingCycle: null,
  paymentCadences: {},
  policyStartDate: null,
  policyDocUrl: null,
  pricePerCycle: null,
  quote: {},
  selectedPaymentCadence: null,
  title: 'Your Quote',
  totals: {}
};

var QuoteLayout = function (_PureComponent) {
  _inherits(QuoteLayout, _PureComponent);

  function QuoteLayout() {
    _classCallCheck(this, QuoteLayout);

    return _possibleConstructorReturn(this, (QuoteLayout.__proto__ || Object.getPrototypeOf(QuoteLayout)).apply(this, arguments));
  }

  _createClass(QuoteLayout, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          currencyCode = _props.currencyCode,
          disclaimer = _props.disclaimer,
          hideBillingSummary = _props.hideBillingSummary,
          icon = _props.icon,
          billingCycle = _props.billingCycle,
          paymentCadences = _props.paymentCadences,
          pricePerCycle = _props.pricePerCycle,
          policyStartDate = _props.policyStartDate,
          policyDocUrl = _props.policyDocUrl,
          quote = _props.quote,
          selectedPaymentCadence = _props.selectedPaymentCadence,
          onUpdatePaymentCadence = _props.onUpdatePaymentCadence,
          title = _props.title,
          totals = _props.totals,
          hideHr = _props.hideHr;
      return _react2.default.createElement('div', {
        className: 'QuoteLayout',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 77
        },
        __self: this
      }, icon && _react2.default.createElement(_IconCircle2.default, {
        className: 'QuoteLayout-icon',
        icon: icon,
        size: 80,
        color: 'medium',
        background: 'gray',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 79
        },
        __self: this
      }), policyDocUrl && _react2.default.createElement('a', {
        href: policyDocUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
        className: 'QuoteLayout-samplePolicyLink',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 88
        },
        __self: this
      }, 'View Sample Policy'), _react2.default.createElement(_PricingLayout2.default, {
        currencyCode: currencyCode,
        paymentCadences: paymentCadences,
        billingCycle: billingCycle,
        onUpdatePaymentCadence: onUpdatePaymentCadence,
        pricePerCycle: pricePerCycle,
        quote: quote,
        selectedPaymentCadence: selectedPaymentCadence,
        title: title,
        totals: totals,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 97
        },
        __self: this
      }), !hideHr && _react2.default.createElement('hr', {
        className: 'QuoteLayout-headingDivider',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 108
        },
        __self: this
      }), children, totals && !hideBillingSummary && _react2.default.createElement(_BillingSummarySection2.default, {
        totals: totals,
        currencyCode: currencyCode,
        billingCycle: billingCycle,
        policyStartDate: policyStartDate,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 112
        },
        __self: this
      }), disclaimer && _react2.default.createElement(_PlanDisclaimer2.default, {
        text: disclaimer,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 119
        },
        __self: this
      }));
    }
  }, {
    key: 'priceHeading',
    get: function get() {
      var grandtotal = this.props.totals.grandtotal;

      var formattedPrice = _currencyFormatter2.default.format(grandtotal, {
        code: this.props.currencyCode
      });

      if (this.props.billingCycle) {
        return formattedPrice + '/' + this.props.billingCycle;
      } else {
        return formattedPrice;
      }
    }
  }]);

  return QuoteLayout;
}(_react.PureComponent);

QuoteLayout.propTypes = propTypes;
QuoteLayout.defaultProps = defaultProps;
exports.default = QuoteLayout;