'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _zipObject = require('lodash/zipObject');

var _zipObject2 = _interopRequireDefault(_zipObject);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/*
 * App actions
 */


var ACTIONS = ['SIGN_OUT', 'CREATE_COLLECTIBLES_QUOTE', 'CREATE_COLLECTIBLES_QUOTE_SUCCESS', 'CREATE_COLLECTIBLES_QUOTE_FAILURE', 'GET_COLLECTIBLES_PLANS', 'GET_COLLECTIBLES_PLANS_SUCCESS', 'GET_COLLECTIBLES_PLANS_FAILURE', 'UPDATE_COLLECTIBLES_ADDRESS', 'UPDATE_COLLECTIBLES_ADDRESS_CONTEXT', 'UPDATE_COLLECTIBLES_CATEGORY_TYPE', 'SHOW_COLLECTIBLES_NOTIFICATION_BAR', 'HIDE_COLLECTIBLES_NOTIFICATION_BAR', 'UPDATE_COLLECTIBLES_SELECTED_AMOUNTS', 'SELECT_COLLECTIBLES_CHOICE', 'SAVE_COLLECTIBLES_RESPONSE', 'DELETE_COLLECTIBLES_RESPONSE', 'UPDATE_COLLECTIBLES_CURRENT_RESPONSE', 'COLLECTIBLES_SURVEY_NEXT', 'COLLECTIBLES_SURVEY_BACK', 'GET_COLLECTIBLES_CHECKOUT', 'GET_COLLECTIBLES_CHECKOUT_SUCCESS', 'GET_COLLECTIBLES_CHECKOUT_FAILURE', 'UPDATE_COLLECTION_DETAILS', 'CLEAR_COLLECTION_DETAILS', 'UPDATE_COLLECTIBLES_PLAN_DETAILS', 'UPDATE_COLLECTIBLES_PLAN_DETAILS_SUCCESS', 'UPDATE_COLLECTIBLES_PLAN_DETAILS_FAILURE', 'EDIT_COLLECTION', 'UPDATE_COLLECTIONS_IN_QUOTE', 'UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS', 'UPDATE_COLLECTIONS_IN_QUOTE_FAILURE', 'ADD_COLLECTION_TO_QUOTE', 'ADD_COLLECTION_TO_QUOTE_SUCCESS', 'ADD_COLLECTION_TO_QUOTE_FAILURE', 'REMOVE_COLLECTION_FROM_QUOTE', 'REMOVE_COLLECTION_FROM_QUOTE_SUCCESS', 'REMOVE_COLLECTION_FROM_QUOTE_FAILURE', 'UPDATE_COLLECTIBLES_START_DATE', 'UPDATE_COLLECTIBLES_PERSONAL_PROPERTY_AMOUNT', 'OPEN_EDIT_COLLECTIBLES_QUOTE_MODAL', 'JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID', 'DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL', 'SAVE_COLLECTIBLES_QUOTE_EDITS_AND_CLOSE_MODAL', 'ADD_COLLECTIBLES_PAYMENT_METHOD', 'ADD_COLLECTIBLES_PAYMENT_METHOD_SUCCESS', 'ADD_COLLECTIBLES_PAYMENT_METHOD_FAILURE', 'PURCHASE_COLLECTIBLES', 'PURCHASE_COLLECTIBLES_SUCCESS', 'PURCHASE_COLLECTIBLES_FAILURE', 'RESET_COLLECTIBLES_DATA'];
var ACTION_TYPES = (0, _zipObject2.default)(ACTIONS, ACTIONS);
exports.default = ACTION_TYPES;