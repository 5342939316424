'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistedCollectiblesReducerKeys = exports.collectiblesReducers = undefined;

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _filter = require('lodash/filter');

var _filter2 = _interopRequireDefault(_filter);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _findIndex = require('lodash/findIndex');

var _findIndex2 = _interopRequireDefault(_findIndex);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _keyBy = require('lodash/keyBy');

var _keyBy2 = _interopRequireDefault(_keyBy);

var _keys = require('lodash/keys');

var _keys2 = _interopRequireDefault(_keys);

var _last = require('lodash/last');

var _last2 = _interopRequireDefault(_last);

var _omit = require('lodash/omit');

var _omit2 = _interopRequireDefault(_omit);

var _without = require('lodash/without');

var _without2 = _interopRequireDefault(_without);

var _actionTypes = require('./actionTypes');

var _actionTypes2 = _interopRequireDefault(_actionTypes);

var _collectiblesFieldKeyDetails = require('./constants/collectiblesFieldKeyDetails');

var _collectiblesFieldKeyDetails2 = _interopRequireDefault(_collectiblesFieldKeyDetails);

var _utils = require('shared/lib/utils');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var collectiblesReducers = {
  /*
   * Quote
   * ========================================
   */
  collectiblesQuote: function collectiblesQuote() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE_SUCCESS:
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS_SUCCESS:
      case _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS:
      case _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_SUCCESS:
        return (0, _utils.deepOmitBy)(action.payload.quote, function (_ref) {
          var key = _ref.key;
          return key[0] === '_';
        });

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  collectiblesPolicyContext: function collectiblesPolicyContext() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SAVE_COLLECTIBLES_RESPONSE:
        var newState = (0, _cloneDeep2.default)(state);
        (0, _keys2.default)(action.response).forEach(function (field) {
          if (_collectiblesFieldKeyDetails2.default[field] && _collectiblesFieldKeyDetails2.default[field].type === 'policy') {
            newState[field] = action.response[field];
          }
        });
        return newState;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },

  /*
   * Plan
   * ========================================
   */
  collectiblesPlan: function collectiblesPlan() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS:
        return action.data.plans[0] || null;

      case _actionTypes2.default.GET_COLLECTIBLES_PLANS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return null;

      default:
        return state;
    }
  },

  /*
   * Addresses
   * ========================================
   */
  collectiblesPropertyAddress: function collectiblesPropertyAddress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_COLLECTIBLES_ADDRESS:
        return Object.assign({}, state, action.address);

      case _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID:
        return action.newPropertyAddress || state;

      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
        return action.propertyAddress || state;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
      case _actionTypes2.default.CLEAR_COLLECTION_DETAILS:
        return {};

      default:
        return state;
    }
  },
  collectiblesAddressContext: function collectiblesAddressContext() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];
    var newState = (0, _cloneDeep2.default)(state);
    var currentAddressContextIndex = (0, _findIndex2.default)(newState, {
      address: (0, _omit2.default)(action.collectiblesPropertyAddress, ['lat', 'lng'])
    });

    switch (action.type) {
      case _actionTypes2.default.SHOW_COLLECTIBLES_NOTIFICATION_BAR:
        if (action.collectiblesPropertyAddress) {
          if (currentAddressContextIndex === -1) {
            return [].concat(_toConsumableArray(newState), [{
              address: (0, _omit2.default)(action.collectiblesPropertyAddress, ['lat', 'lng']),
              hasSeenExclusionNotice: true
            }]);
          } else {
            newState[currentAddressContextIndex].hasSeenExclusionNotice = true;
          }
        }

        return newState;

      case _actionTypes2.default.SAVE_COLLECTIBLES_RESPONSE:
        (0, _keys2.default)(action.response).forEach(function (field) {
          if (_collectiblesFieldKeyDetails2.default[field] && _collectiblesFieldKeyDetails2.default[field].type === 'address') {
            if (currentAddressContextIndex === -1) {
              newState = [].concat(_toConsumableArray(newState), [_defineProperty({
                address: (0, _omit2.default)(action.collectiblesPropertyAddress, ['lat', 'lng'])
              }, field, action.response[field])]);
            } else {
              newState[currentAddressContextIndex] = Object.assign({}, newState[currentAddressContextIndex], _defineProperty({}, field, action.response[field]));
            }
          }
        });
        return newState;

      case _actionTypes2.default.UPDATE_COLLECTIBLES_ADDRESS_CONTEXT:
        if (action.isUpdatingAddress && !(0, _find2.default)(newState, {
          address: (0, _omit2.default)(action.updatedAddressContext.address, ['lat', 'lng'])
        })) {
          newState = [].concat(_toConsumableArray(newState), [{
            address: (0, _omit2.default)(action.updatedAddressContext.address, ['lat', 'lng'])
          }]);
        } else if (!action.isUpdatingAddress) {
          var addressIndexInContext = (0, _findIndex2.default)(newState, {
            address: (0, _omit2.default)(action.addressToUpdate, ['lat', 'lng'])
          });
          newState.splice(addressIndexInContext, 1, action.updatedAddressContext);
        }

        return newState;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return [];

      default:
        return state;
    }
  },

  /*
   * Collectibles category
   * ========================================
   */
  collectiblesSelectedCategory: function collectiblesSelectedCategory() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_COLLECTIBLES_CATEGORY_TYPE:
        return action.categoryCode;

      case _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID:
        return action.newSelectedCategory;

      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
        return action.selectedCategory || state;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
      case _actionTypes2.default.CLEAR_COLLECTION_DETAILS:
        return null;

      default:
        return state;
    }
  },

  /*
   * Exclusion notice
   * ========================================
   */
  collectiblesNotificationMessage: function collectiblesNotificationMessage() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.SHOW_COLLECTIBLES_NOTIFICATION_BAR:
        return action.notificationMessage;

      case _actionTypes2.default.HIDE_COLLECTIBLES_NOTIFICATION_BAR:
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return null;

      default:
        return state;
    }
  },

  /*
   * Survey flow
   * ========================================
   */
  collectiblesScreens: function collectiblesScreens() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS:
        return Object.assign({}, state, _defineProperty({}, action.category, action.data.dynamic_form.screens));

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  collectiblesScreenSequence: function collectiblesScreenSequence() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    var _ref3 = action.getState && action.getState() || {},
        collectiblesPropertyAddress = _ref3.collectiblesPropertyAddress,
        collectiblesAddressContext = _ref3.collectiblesAddressContext,
        collectiblesQuote = _ref3.collectiblesQuote,
        collectiblesSelectedCategory = _ref3.collectiblesSelectedCategory;

    var isNewAddress = !(0, _find2.default)(collectiblesAddressContext, {
      address: (0, _omit2.default)(collectiblesPropertyAddress, ['lat', 'lng'])
    }) || !(0, _find2.default)(collectiblesAddressContext, {
      address: (0, _omit2.default)(collectiblesPropertyAddress, ['lat', 'lng'])
    }).has_fire_alarm;

    var checkFieldKeyTypes = function checkFieldKeyTypes(_ref4) {
      var fields = _ref4.fields;
      return fields ? fields.every(function (field) {
        if (isNewAddress) {
          return _collectiblesFieldKeyDetails2.default[field.key_name] && _collectiblesFieldKeyDetails2.default[field.key_name].type !== 'policy';
        } else {
          return _collectiblesFieldKeyDetails2.default[field.key_name] && _collectiblesFieldKeyDetails2.default[field.key_name].type !== 'policy' && _collectiblesFieldKeyDetails2.default[field.key_name].type !== 'address';
        }
      }) : true;
    };

    if (action.type === _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS) {
      var dynamic_form = action.data.dynamic_form;
      var map = [{
        id: dynamic_form.start_screen_id,
        parentId: null
      }];
      var trail = void 0;

      if ((0, _isEmpty2.default)(collectiblesQuote.application.plan_applications)) {
        trail = (0, _utils.generateTrail)(dynamic_form.screens, dynamic_form.start_screen_id, null);
      } else {
        trail = (0, _utils.generateTrail)(dynamic_form.screens, dynamic_form.start_screen_id, null, false, checkFieldKeyTypes);
      }

      return Object.assign({}, state, _defineProperty({}, collectiblesSelectedCategory, map.concat(trail)));
    } else if (action.type === _actionTypes2.default.SELECT_COLLECTIBLES_CHOICE) {
      var _action$getState = action.getState(),
          collectiblesCurrentScreenId = _action$getState.collectiblesCurrentScreenId,
          collectiblesEditQuoteModalContext = _action$getState.collectiblesEditQuoteModalContext,
          collectiblesScreens = _action$getState.collectiblesScreens,
          _collectiblesQuote = _action$getState.collectiblesQuote,
          _collectiblesSelectedCategory = _action$getState.collectiblesSelectedCategory;

      var currentIndex = (0, _findIndex2.default)(state[_collectiblesSelectedCategory], {
        id: collectiblesCurrentScreenId
      });
      var trailToAdd = [];
      var parentId = (0, _get2.default)(state[_collectiblesSelectedCategory][currentIndex], 'id') || collectiblesCurrentScreenId;

      var _trail = void 0;

      if ((0, _isEmpty2.default)(_collectiblesQuote.application.plan_applications) || collectiblesEditQuoteModalContext) {
        _trail = (0, _utils.generateTrail)(collectiblesScreens[_collectiblesSelectedCategory], action.screen_id, parentId);
      } else {
        _trail = (0, _utils.generateTrail)(collectiblesScreens[_collectiblesSelectedCategory], action.screen_id, parentId, false, checkFieldKeyTypes);
      }

      var initialObj = {
        id: action.screen_id,
        parentId: parentId,
        first: true
      };
      var pniScreenId = (0, _utils.findPniScreenId)(collectiblesScreens[_collectiblesSelectedCategory]);

      if (action.screen_id !== pniScreenId) {
        trailToAdd.push(initialObj);
      }

      if (_trail.length) {
        trailToAdd = trailToAdd.concat(_trail);
      }

      var start = state[_collectiblesSelectedCategory].slice(0, currentIndex + 1);

      var end = state[_collectiblesSelectedCategory].slice(currentIndex + 1);

      var newMap = start.concat(trailToAdd).concat(end);
      return Object.assign({}, state, _defineProperty({}, _collectiblesSelectedCategory, newMap));
    } else if (action.type === _actionTypes2.default.COLLECTIBLES_SURVEY_BACK) {
      var _action$getState2 = action.getState(),
          _collectiblesCurrentScreenId = _action$getState2.collectiblesCurrentScreenId;

      var _currentIndex = (0, _findIndex2.default)(state[collectiblesSelectedCategory], {
        id: _collectiblesCurrentScreenId
      });

      if (_currentIndex !== 0) {
        var current = state[collectiblesSelectedCategory][_currentIndex];
        var _newMap = []; // Clear all step created from this parent

        if (current.first && current.parentId) {
          state[collectiblesSelectedCategory].forEach(function (step, idx) {
            if (step.parentId !== current.parentId) {
              _newMap.push(step);
            }
          });
          return Object.assign({}, state, _defineProperty({}, collectiblesSelectedCategory, _newMap));
        }

        return state;
      } else {
        return state;
      }
    } else if (action.type === _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL) {
      return action.screenSequence || state;
    } else if (action.type === _actionTypes2.default.RESET_COLLECTIBLES_DATA) {
      return {};
    } else {
      return state;
    }
  },
  collectiblesCurrentScreenId: function collectiblesCurrentScreenId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];
    var nextState = void 0;

    var _ref5 = action.getState && action.getState() || {},
        screenSequence = _ref5.collectiblesScreenSequence,
        category = _ref5.collectiblesSelectedCategory;

    if (action.type === _actionTypes2.default.COLLECTIBLES_SURVEY_NEXT) {
      var currentIndex = (0, _findIndex2.default)(screenSequence[category], {
        id: state
      });
      var nextIndex = currentIndex + 1;

      if (screenSequence[category][nextIndex]) {
        nextState = screenSequence[category][nextIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.COLLECTIBLES_SURVEY_BACK) {
      var _currentIndex2 = (0, _findIndex2.default)(screenSequence[category], {
        id: state
      });

      var prevIndex = _currentIndex2 - 1;

      if (_currentIndex2 !== 0) {
        nextState = screenSequence[category][prevIndex].id;
      } else {
        nextState = null;
      }
    } else if (action.type === _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS) {
      nextState = action.data.dynamic_form.start_screen_id;
    } else if (action.type === _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID) {
      nextState = action.newScreenId;
    } else if (action.type === _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL) {
      nextState = action.screenId || state;
    } else if (action.type === _actionTypes2.default.SAVE_COLLECTIBLES_QUOTE_EDITS_AND_CLOSE_MODAL) {
      nextState = (0, _last2.default)(screenSequence[category]).id;
    } else {
      nextState = state;
    }

    return nextState;
  },
  collectiblesPreEditContext: function collectiblesPreEditContext() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID:
        return {
          oldScreenId: action.oldScreenId || state.oldScreenId,
          oldResponses: (0, _cloneDeep2.default)(action.oldResponses) || state.oldResponses,
          oldScreenSequence: (0, _cloneDeep2.default)(action.oldScreenSequence) || state.oldScreenSequence,
          oldPropertyAddress: action.oldPropertyAddress || state.oldPropertyAddress,
          oldSelectedCategory: action.oldSelectedCategory || state.oldSelectedCategory
        };

      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
      case _actionTypes2.default.SAVE_COLLECTIBLES_QUOTE_EDITS_AND_CLOSE_MODAL:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return null;

      default:
        return state;
    }
  },
  collectiblesCurrentResponse: function collectiblesCurrentResponse() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_COLLECTIBLES_CURRENT_RESPONSE:
        return Object.assign({}, state, action.payload.response);

      case _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID:
      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
      case _actionTypes2.default.GET_COLLECTIBLES_ADDRESS:
      case _actionTypes2.default.SAVE_COLLECTIBLES_RESPONSE:
      case _actionTypes2.default.COLLECTIBLES_SURVEY_BACK:
      case _actionTypes2.default.COLLECTIBLES_SURVEY_NEXT:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  collectiblesResponses: function collectiblesResponses() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];
    var newState = (0, _cloneDeep2.default)(state);

    switch (action.type) {
      case _actionTypes2.default.SAVE_COLLECTIBLES_RESPONSE:
        Object.assign(newState, action.response);
        return newState;

      case _actionTypes2.default.DELETE_COLLECTIBLES_RESPONSE:
        return (0, _omit2.default)(state, action.payload.responseKeys);

      case _actionTypes2.default.JUMP_TO_COLLECTIBLES_SURVEY_SCREEN_ID:
        return action.newResponses || state;

      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
        return action.responses || state;

      case _actionTypes2.default.CLEAR_COLLECTION_DETAILS:
        return {
          owner_first_name: state.owner_first_name,
          owner_last_name: state.owner_last_name,
          owner_email: state.owner_email,
          owner_phone_number: state.owner_phone_number
        };

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  isEditingCollection: function isEditingCollection() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.EDIT_COLLECTION:
        return true;

      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
      case _actionTypes2.default.CLEAR_COLLECTION_DETAILS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return false;

      default:
        return state;
    }
  },
  collectionIdToEdit: function collectionIdToEdit() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.EDIT_COLLECTION:
        return action.collectionId;

      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
      case _actionTypes2.default.CLEAR_COLLECTION_DETAILS:
      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return '';

      default:
        return state;
    }
  },

  /*
   * Quote and checkout
   * ========================================
   */
  collectiblesEditQuoteModalContext: function collectiblesEditQuoteModalContext() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.OPEN_EDIT_COLLECTIBLES_QUOTE_MODAL:
        return action.editModalContext;

      case _actionTypes2.default.DISMISS_EDIT_COLLECTIBLES_QUOTE_MODAL:
      case _actionTypes2.default.SAVE_COLLECTIBLES_QUOTE_EDITS_AND_CLOSE_MODAL:
        return null;

      default:
        return state;
    }
  },
  collectiblesCollectionsByCollectionId: function collectiblesCollectionsByCollectionId() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS:
      case _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_SUCCESS:
        return (0, _keyBy2.default)(action.payload.quote.application.plan_applications[0].collections, function (collection) {
          return collection.reference_id;
        });

      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_FAILURE:
        return (0, _keyBy2.default)(action.collectiblesQuote.application.plan_applications[0].collections, function (collection) {
          return collection.reference_id;
        });

      case _actionTypes2.default.UPDATE_COLLECTIBLES_ADDRESS_CONTEXT:
        var groomedAddressToUpdate = {
          line1: action.addressToUpdate.streetAddress,
          line2: action.addressToUpdate.unit,
          city: action.addressToUpdate.city,
          state_or_region: action.addressToUpdate.region,
          postal: action.addressToUpdate.postal,
          country_code: action.addressToUpdate.country
        };
        var newAddress = action.updatedAddressContext.address;
        var newAddressContext = action.isUpdatingAddress ? (0, _find2.default)(action.getState().collectiblesAddressContext, {
          address: (0, _omit2.default)(newAddress, ['lat', 'lng'])
        }) || null : action.updatedAddressContext;
        var groomedAddressContext = Object.assign({}, (0, _omit2.default)(newAddressContext, 'hasSeenExclusionNotice'), {
          address: {
            line1: newAddress.streetAddress,
            line2: newAddress.unit,
            city: newAddress.city,
            state_or_region: newAddress.region,
            country_code: newAddress.country,
            postal: newAddress.postal
          }
        });
        var collectionsToUpdate = action.isUpdatingAddress ? [(0, _find2.default)(state, {
          reference_id: action.collectionId
        })] : (0, _filter2.default)(state, {
          address: groomedAddressToUpdate
        });
        var updatedCollections = collectionsToUpdate.map(function (collection) {
          return Object.assign({}, collection, groomedAddressContext);
        });
        var updatedCollectionAggregateObject = (0, _keyBy2.default)(updatedCollections, function (collection) {
          return collection.reference_id;
        });
        return Object.assign({}, state, updatedCollectionAggregateObject);

      case _actionTypes2.default.UPDATE_COLLECTION_DETAILS:
        var newState = (0, _cloneDeep2.default)(state);
        newState[action.collectionId] = Object.assign({}, newState[action.collectionId], action.detailsToUpdate);

        if (action.detailsToUpdate.has_burglar_alarm || action.detailsToUpdate.has_fire_alarm) {
          var _collectionsToUpdate = (0, _filter2.default)(newState, {
            address: newState[action.collectionId].address
          });

          var _updatedCollections = _collectionsToUpdate.map(function (collection) {
            return Object.assign({}, collection, {
              has_burglar_alarm: action.detailsToUpdate.has_burglar_alarm || collection.has_burglar_alarm,
              has_fire_alarm: action.detailsToUpdate.has_fire_alarm || collection.has_fire_alarm
            });
          });

          var _updatedCollectionAggregateObject = (0, _keyBy2.default)(_updatedCollections, function (collection) {
            return collection.reference_id;
          });

          newState = Object.assign({}, newState, _updatedCollectionAggregateObject);
        }

        return newState;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  collectiblesRates: function collectiblesRates() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
        return action.payload.quote.billing.plan_deductibles.option_sets.general || [];

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return [];

      default:
        return state;
    }
  },
  collectiblesQuoteTotal: function collectiblesQuoteTotal() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
        return {
          amount: {
            grandtotal: action.payload.quote.billing.grand_total
          },
          currency_code: action.payload.quote.billing.prices[0].currency_code
        };

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return null;

      default:
        return state;
    }
  },
  collectiblesPolicyStartDate: function collectiblesPolicyStartDate() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _momentTimezone2.default)().toISOString();
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.UPDATE_COLLECTIBLES_START_DATE:
        return action.payload.isoString;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return (0, _momentTimezone2.default)().toISOString();

      default:
        return state;
    }
  },
  collectiblesSelectedAmounts: function collectiblesSelectedAmounts() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
        return action.isInitialCalculation ? action.payload.quote.billing.plan_deductibles.defaults : state;

      case _actionTypes2.default.UPDATE_COLLECTIBLES_SELECTED_AMOUNTS:
        return Object.assign({}, state, action.data);

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return {};

      default:
        return state;
    }
  },
  collectiblesPolicyNumber: function collectiblesPolicyNumber() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_COLLECTIBLES_SUCCESS:
        return action.data.agreements[0].policy_number || null;

      case _actionTypes2.default.SIGN_OUT:
      case _actionTypes2.default.RESET_COLLECTIBLES_DATA:
        return null;

      default:
        return state;
    }
  },
  collectiblesPurchase: function collectiblesPurchase() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.PURCHASE_COLLECTIBLES_SUCCESS:
        return action.data.agreements[0] || null;

      case _actionTypes2.default.GET_COLLECTIBLES_PLANS:
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE:
      case _actionTypes2.default.SIGN_OUT:
        return null;

      default:
        return state;
    }
  },
  isCollectiblesInProgress: function isCollectiblesInProgress() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    switch (action.type) {
      case _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE:
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS:
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE:
      case _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT:
      case _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE:
      case _actionTypes2.default.GET_COLLECTIBLES_CATEGORIES:
      case _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD:
      case _actionTypes2.default.PURCHASE_COLLECTIBLES:
        return true;

      case _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE_SUCCESS:
      case _actionTypes2.default.CREATE_COLLECTIBLES_QUOTE_FAILURE:
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_PLANS_FAILURE:
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_SUCCESS:
      case _actionTypes2.default.ADD_COLLECTION_TO_QUOTE_FAILURE:
      case _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_SUCCESS:
      case _actionTypes2.default.REMOVE_COLLECTION_FROM_QUOTE_FAILURE:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_CHECKOUT_FAILURE:
      case _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIBLES_PLAN_DETAILS_FAILURE:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_SUCCESS:
      case _actionTypes2.default.UPDATE_COLLECTIONS_IN_QUOTE_FAILURE:
      case _actionTypes2.default.GET_COLLECTIBLES_CATEGORIES_SUCCESS:
      case _actionTypes2.default.GET_COLLECTIBLES_CATEGORIES_FAILURE:
      case _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD_SUCCESS:
      case _actionTypes2.default.ADD_COLLECTIBLES_PAYMENT_METHOD_FAILURE:
      case _actionTypes2.default.PURCHASE_COLLECTIBLES_SUCCESS:
      case _actionTypes2.default.PURCHASE_COLLECTIBLES_FAILURE:
        return false;

      default:
        return state;
    }
  }
};
var persistedCollectiblesReducerKeys = (0, _without2.default)(Object.keys(collectiblesReducers), 'isCollectiblesInProgress');
exports.collectiblesReducers = collectiblesReducers;
exports.persistedCollectiblesReducerKeys = persistedCollectiblesReducerKeys;