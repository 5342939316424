'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _jsxFileName = 'src/components/GooglePlacesSuggestInput/GooglePlacesSuggestInput.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _sureComponents = require('sure-components');

var _utils = require('shared/lib/utils');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  name: _propTypes2.default.string,
  placeholder: _propTypes2.default.string,
  value: _propTypes2.default.string,
  onChange: _propTypes2.default.func,
  onSuggestionSelect: _propTypes2.default.func
};
var defaultProps = {
  name: 'line1',
  placeholder: 'Address Line 1',
  value: '',
  onChange: function onChange() {},
  onSuggestionSelect: function onSuggestionSelect() {}
};

var GooglePlacesSuggestInput = function (_PureComponent) {
  _inherits(GooglePlacesSuggestInput, _PureComponent);

  function GooglePlacesSuggestInput() {
    _classCallCheck(this, GooglePlacesSuggestInput);

    return _possibleConstructorReturn(this, (GooglePlacesSuggestInput.__proto__ || Object.getPrototypeOf(GooglePlacesSuggestInput)).apply(this, arguments));
  }

  _createClass(GooglePlacesSuggestInput, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (window.google) {
        this.stopAutoFill();

        if (!window.Cypress) {
          this.initGoogleAutocomplete();
        }
      } else {
        console.warn('Google API library missing');
      }
    }
  }, {
    key: 'stopAutoFill',
    value: function stopAutoFill() {
      var _this2 = this;
      /**
       * XXX: Google auto complete change the autocomplete to off,
       * observing the input field to change it to line1 to stop the autocomplete
       */


      var autocompleteDOM = this.inputStreetAddress.getInputDOM();
      var observer = new MutationObserver(function () {
        observer.disconnect();

        if (_this2.inputStreetAddress) {
          autocompleteDOM.autocomplete = 'line1';
        }
      });
      observer.observe(autocompleteDOM, {
        attributes: true,
        attributeFilter: ['autocomplete']
      });
    }
  }, {
    key: 'initGoogleAutocomplete',
    value: function initGoogleAutocomplete() {
      var _this3 = this;

      this.autocomplete = new window.google.maps.places.Autocomplete(this.inputStreetAddress.getInputDOM(), {
        types: ['geocode']
      });
      this.autocomplete.addListener('place_changed', function () {
        var place = _this3.autocomplete.getPlace();

        if (place) {
          var addressComponents = place.address_components;
          var newState = (0, _utils.normalizeGooglePlaceAddressComponents)(addressComponents);

          _this3.props.onSuggestionSelect(Object.assign({}, newState, {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }));
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      var _props = this.props,
          name = _props.name,
          onChange = _props.onChange,
          placeholder = _props.placeholder,
          value = _props.value,
          otherProps = _objectWithoutProperties(_props, ['name', 'onChange', 'placeholder', 'value']);

      delete otherProps.onSuggestionSelect;
      return _react2.default.createElement(_sureComponents.TextInput, _extends({
        name: name,
        placeholder: placeholder,
        value: value,
        maxLength: '30',
        required: true,
        ref: function ref(c) {
          return _this4.inputStreetAddress = c;
        },
        onChange: onChange
      }, otherProps, {
        autoComplete: 'line1',
        autofill: 'off',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 81
        },
        __self: this
      }));
    }
  }]);

  return GooglePlacesSuggestInput;
}(_react.PureComponent);

GooglePlacesSuggestInput.propTypes = propTypes;
GooglePlacesSuggestInput.defaultProps = defaultProps;
exports.default = GooglePlacesSuggestInput;