'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/apps/CollectiblesApp/views/CollectiblesPolicyholderInfo/CollectiblesPolicyholderInfo.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _get2 = require('lodash/get');

var _get3 = _interopRequireDefault(_get2);

var _startCase = require('lodash/startCase');

var _startCase2 = _interopRequireDefault(_startCase);

var _PagerButtons = require('shared/lib/components/PagerButtons');

var _PagerButtons2 = _interopRequireDefault(_PagerButtons);

var _TextInput = require('shared/lib/components/TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

var _FormGroup = require('shared/lib/components/FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _CreditCardLogos = require('shared/lib/components/CreditCardLogos');

var _CreditCardLogos2 = _interopRequireDefault(_CreditCardLogos);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _PlanDisclaimer = require('shared/lib/components/PlanDisclaimer');

var _PlanDisclaimer2 = _interopRequireDefault(_PlanDisclaimer);

var _cleaveReact = require('cleave.js/dist/cleave-react');

var _cleaveReact2 = _interopRequireDefault(_cleaveReact);

var _analytics = require('shared/lib/analytics');

var analytics = _interopRequireWildcard(_analytics);

require('./CollectiblesPolicyholderInfo.css');

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  account: _propTypes2.default.object,
  activeModal: _propTypes2.default.string,
  categoryCode: _propTypes2.default.string,
  categorySlug: _propTypes2.default.string,
  disclaimer: _propTypes2.default.string,
  hasPrepopulatedData: _propTypes2.default.bool,
  isCollectiblesInProgress: _propTypes2.default.bool,
  isLoggedIn: _propTypes2.default.bool,
  resetCollectiblesData: _propTypes2.default.func,
  responses: _propTypes2.default.object,
  saveResponse: _propTypes2.default.func,
  shouldPreserveAddress: _propTypes2.default.bool
};
var defaultProps = {
  account: {},
  activeModal: null,
  disclaimer: null,
  hasPrepopulatedData: false,
  isCollectiblesInProgress: false,
  isLoggedIn: false,
  responses: {},
  shouldPreserveAddress: false
};

var CollectiblesPolicyholderInfo = function (_Component) {
  _inherits(CollectiblesPolicyholderInfo, _Component);

  function CollectiblesPolicyholderInfo() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollectiblesPolicyholderInfo);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollectiblesPolicyholderInfo.__proto__ || Object.getPrototypeOf(CollectiblesPolicyholderInfo)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isSubmitting: false
    }, _this.onCleaveInit = function (cleave) {
      if (!_this.props.isEmbed) {
        cleave.setRawValue((_this.props.responses.owner_phone_number || _this.props.account.phoneNumber || '').slice(-10));
      }
    }, _this.handleNext = function (e) {
      if (e) {
        e.preventDefault();
      }

      if (_this.props.categoryCode !== 'other' && _this.props.collectiblesSelectedCategory) {
        _this.props.navTo('address');
      } else {
        _this.props.navTo('category');
      }
    }, _this.handleInputChange = function (e) {
      if (e.target.id) {
        _this.props.saveResponse(_defineProperty({}, e.target.name, e.target.value));
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollectiblesPolicyholderInfo, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      analytics.page('Collectibles - Policyholder Information', {
        categoryCode: this.props.categoryCode
      });

      if (!this.props.isEmbed && !this.props.shouldPreserveAddress && !this.props.hasPrepopulatedData) {
        this.props.resetCollectiblesData();
      }

      if (this.props.isLoggedIn) {
        this.props.saveResponse({
          owner_first_name: this.props.account.firstName,
          owner_last_name: this.props.account.lastName,
          owner_phone_number: (this.props.account.phoneNumber || '').slice(-10),
          owner_email: this.props.account.email
        });
      }

      if (this.props.categoryCode && this.props.categoryCode !== 'other') {
        this.props.updateCollectiblesCategoryType(this.props.categoryCode);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement('div', {
        className: 'CollectiblesPolicyholderInfo',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 128
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'CollectiblesPolicyholderInfo-pane',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 129
        },
        __self: this
      }, _react2.default.createElement('div', {
        className: 'Container Container--small',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 130
        },
        __self: this
      }, _react2.default.createElement('h2', {
        className: 'CollectiblesPolicyholderInfo-pane-heading',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 131
        },
        __self: this
      }, 'Start your free quote'), _react2.default.createElement('form', {
        className: 'CollectiblesPolicyholderInfo-pane-form',
        onSubmit: this.handleNext,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 134
        },
        __self: this
      }, _react2.default.createElement(_FormGroup2.default, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 138
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: 'ownerFirstName',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 139
        },
        __self: this
      }, 'First Name'), _react2.default.createElement(_TextInput2.default, {
        name: 'owner_first_name',
        id: 'ownerFirstName',
        required: true,
        value: this.props.responses.owner_first_name || '',
        maxLength: '20',
        pattern: '[a-zA-Z ]+',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 142
        },
        __self: this
      }), _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: 'ownerLastName',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 151
        },
        __self: this
      }, 'Last Name'), _react2.default.createElement(_TextInput2.default, {
        name: 'owner_last_name',
        id: 'ownerLastName',
        required: true,
        value: this.props.responses.owner_last_name || '',
        maxLength: '20',
        pattern: '[a-zA-Z ]+',
        onChange: this.handleInputChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 154
        },
        __self: this
      }), _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: 'ownerEmail',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 163
        },
        __self: this
      }, 'Email Address'), _react2.default.createElement(_TextInput2.default, {
        type: 'email',
        pattern: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$',
        name: 'owner_email',
        id: 'ownerEmail',
        required: true,
        value: this.props.responses.owner_email || '',
        onChange: this.handleInputChange,
        'data-testid': 'owner_email',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 166
        },
        __self: this
      }), _react2.default.createElement('div', {
        className: 'Input--textNew',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 176
        },
        __self: this
      }, _react2.default.createElement('label', {
        className: 'FormGroup-label',
        htmlFor: 'ownerPhoneNumber',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 177
        },
        __self: this
      }, 'Phone Number'), _react2.default.createElement(_cleaveReact2.default, {
        className: 'Input-input',
        name: 'owner_phone_number',
        id: 'ownerPhoneNumber',
        required: true,
        options: {
          blocks: [3, 3, 4],
          delimiter: '-',
          numericOnly: true
        },
        value: this.props.responses.owner_phone_number,
        onChange: this.handleInputChange,
        onInit: this.onCleaveInit,
        'data-testid': 'input_owner_phone_number',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 180
        },
        __self: this
      }))), _react2.default.createElement('input', {
        type: 'submit',
        style: {
          display: 'none'
        },
        ref: function ref(c) {
          return _this2.inputSubmit = c;
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 197
        },
        __self: this
      })), _react2.default.createElement(_CreditCardLogos2.default, {
        className: 'CollectiblesPolicyholderInfo-creditCardLogos',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 203
        },
        __self: this
      }), _react2.default.createElement(_PlanDisclaimer2.default, {
        className: 'CollectiblesPolicyholderInfo-planDisclaimer',
        text: this.props.disclaimer,
        lineBreakOnSentence: true,
        showBuiltOnLogo: !!this.props.partnerInfo,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 204
        },
        __self: this
      }))), _react2.default.createElement(_PagerButtons2.default, {
        onNext: function onNext() {
          return _this2.inputSubmit.click();
        },
        isNextDisabled: this.state.isSubmitting || this.isNextDisabled,
        isBackHidden: true,
        isInProgress: this.props.isCollectiblesInProgress || this.state.isSubmitting,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 212
        },
        __self: this
      }));
    }
  }, {
    key: 'categoryTitle',
    get: function get() {
      if (this.props.categoryCode === 'jewelry') {
        return 'Jewelry & Watches';
      } else if (this.props.categorySlug !== 'other-insurance') {
        return (0, _startCase2.default)(this.props.categoryCode.replace('_', ' '));
      } else {
        return 'Collectibles';
      }
    }
  }, {
    key: 'isNextDisabled',
    get: function get() {
      var responses = this.props.responses;
      return ![responses.owner_first_name, responses.owner_last_name, responses.owner_email, (0, _get3.default)(responses, 'owner_phone_number', '').replace(/[^0-9.]+/g, '').length >= 10].every(function (val) {
        return val;
      }) || this.props.isCollectiblesInProgress;
    }
  }]);

  return CollectiblesPolicyholderInfo;
}(_react.Component);

CollectiblesPolicyholderInfo.propTypes = propTypes;
CollectiblesPolicyholderInfo.defaultProps = defaultProps;
exports.default = CollectiblesPolicyholderInfo;