'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _jsxFileName = 'src/components/CurrencyField/CurrencyField.js';

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _cleaveReact = require('cleave.js/dist/cleave-react');

var _cleaveReact2 = _interopRequireDefault(_cleaveReact);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _currencyFormatter = require('currency-formatter');

var _currencyFormatter2 = _interopRequireDefault(_currencyFormatter);

require('./CurrencyField.css');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var propTypes = {
  currencyCode: _propTypes2.default.string,
  maxValue: _propTypes2.default.number,
  minValue: _propTypes2.default.number
};
var defaultProps = {
  currencyCode: 'USD',
  maxValue: null,
  minValue: null
};

var CurrencyField = function (_PureComponent) {
  _inherits(CurrencyField, _PureComponent);

  function CurrencyField() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CurrencyField);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CurrencyField.__proto__ || Object.getPrototypeOf(CurrencyField)).call.apply(_ref, [this].concat(args))), _this), _this.cleaveOpts = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    }, _this.handleChange = function (e) {
      var _e$target = e.target,
          name = _e$target.name,
          value = _e$target.rawValue;
      var evt = {
        target: {
          name: name,
          value: value
        }
      };

      if (value !== '') {
        evt.target.value = parseInt(value, 10);
      }

      _this.props.onChange(evt);

      return false;
    }, _this.handleClear = function (e) {
      _this.cleave.setRawValue('');

      var mockEvent = {
        target: {
          name: _this.props.name,
          rawValue: '',
          value: ''
        }
      };

      _this.props.onChange(mockEvent);
    }, _this.handleInit = function (cleave) {
      _this.cleave = cleave;
      cleave.setRawValue(_this.props.value);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CurrencyField, [{
    key: 'validate',
    value: function validate() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        if (!_this2.props.maxValue) {
          resolve();
        } else if (_this2.props.value > _this2.props.maxValue) {
          reject({
            title: 'You qualify for Sure’s VIP service!',
            message: "Good news! You\u2019re one of the select few customers who qualify to work with our VIP service team. Don\u2019t worry, the process is easy \u2013 simply call or text 1-844-335-5441 and our VIP team will take care of the rest."
          });
        } else {
          resolve();
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', {
        className: (0, _classnames2.default)('CurrencyField CurrencyField--text', this.props.className),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 88
        },
        __self: this
      }, _react2.default.createElement('span', {
        className: 'CurrencyField-symbol',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 94
        },
        __self: this
      }, this.symbol), _react2.default.createElement(_cleaveReact2.default, {
        className: 'CurrencyField-input',
        name: this.props.name,
        placeholder: this.props.placeholder,
        required: this.props.required,
        options: this.cleaveOpts,
        onInit: this.handleInit,
        onChange: this.handleChange,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 95
        },
        __self: this
      }), _react2.default.createElement('i', {
        className: 'CurrencyField-clear material-icons',
        onClick: this.handleClear,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 104
        },
        __self: this
      }, 'clear'));
    }
  }, {
    key: 'symbol',
    get: function get() {
      return _currencyFormatter2.default.findCurrency(this.props.currencyCode).symbol;
    }
  }, {
    key: 'value',
    get: function get() {
      return this.input.value;
    },
    set: function set(value) {
      this.input.value = value;
    }
  }]);

  return CurrencyField;
}(_react.PureComponent);

CurrencyField.propTypes = propTypes;
CurrencyField.defaultProps = defaultProps;
exports.default = CurrencyField;