import React, { PureComponent } from 'react';
import logoChubb from './logo-chubb.png';
import { Link } from 'react-router';
import './TopNavBarChubb.css';

export default class TopNavBarChubb extends PureComponent {
  render() {
    return (
      <div className="Container">
        <div className="TopNavBarChubb">
          <Link to="/" className="TopNavBarChubb-logoLink">
            <img
              className="TopNavBarChubb-logo"
              src={logoChubb}
              alt="Chubb logo"
            />
          </Link>
          <ul className="TopNavBarChubb-navList">
            <li className="TopNavBarChubb-navListItem">
              <Link
                to="/vehicle-contents-insurance"
                className="TopNavBarChubb-navLink"
              >
                Vehicle Contents Insurance
              </Link>
            </li>
            <li className="TopNavBarChubb-navListItem">
              <Link
                to="/residential-insurance"
                className="TopNavBarChubb-navLink"
              >
                Residential Insurance
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
