'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defer = require('lodash/defer');

var _defer2 = _interopRequireDefault(_defer);

var _each = require('lodash/each');

var _each2 = _interopRequireDefault(_each);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Sets the "content" attribute on a (meta tag) element, if it exists.
 *
 * @param {String} selector The full selector to query for.
 * @param {String} attr The attribute to set.
 * @param {String} val  The attribute value.
 */


function setMetaContent(selector, val) {
  var el = document.querySelector(selector);

  if (el) {
    el.setAttribute('content', val);
  }
}

function _setProps(props) {
  if (props.title) {
    document.title = props.title;
    setMetaContent('meta[itemprop="name"]', props.title);
    setMetaContent('meta[name="twitter:title"]', props.title);
    setMetaContent('meta[property="og:title"]', props.title);
    setMetaContent('meta[property="og:site_name"]', props.title);
  }

  if (props.description) {
    setMetaContent('meta[name="description"]', props.description);
    setMetaContent('meta[itemprop="description"]', props.description);
    setMetaContent('meta[name="twitter:description"]', props.description);
    setMetaContent('meta[property="og:description"]', props.description);
  }

  if (props.image) {
    setMetaContent('meta[itemprop="image"]', props.image);
    setMetaContent('meta[name="twitter:image:src"]', props.image);
    setMetaContent('meta[name="twitter:card"]', props.image);
    setMetaContent('meta[property="og:image"]', props.image);
  }

  if (props.url) {
    var canonical = document.querySelector('link[rel="canonical"]');

    if (canonical !== null) {
      canonical.href = props.url;
    } else {
      var canonicalTag = document.createElement('link');
      canonicalTag.rel = 'canonical';
      canonicalTag.href = props.url;
      document.head.appendChild(canonicalTag);
    }

    setMetaContent('meta[property="og:url"]', props.url);
  }

  if (props.favicon) {
    (0, _each2.default)(document.querySelectorAll('link[rel*="icon"]'), function (el) {
      el.setAttribute('href', props.favicon);
    });
  }

  if (props.robots) {
    setMetaContent('meta[name="robots"]', props.robots);
  }

  window.prerenderReady = true;
}

var seo = {
  /*
   * Sets meta attributes for the page to improve SEO.
   * Also toggles the Prerender.io flag to ready.
   * Should only be called once on a page render. All successive calls will be a
   * noop.
   *
   * @param {Object} props The meta properties to set.
   * @param {Boolean} [props.fallback] If set, deprioritizes this set seo call
   * and allows for successfive calls to be run first.
   * @param {String} [props.title] The name or title of the page.
   * @param {String} [props.description] A short description for the page.
   * @param {String} [props.image] The image for the page used in OG, Twitter,
   * @param {String} [props.url] The canonical url.
   * @param {String} [props.favicon] The favicon to use.
   */
  setProps: function setProps() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (props.fallback) {
      _setProps(props);
    } else {
      (0, _defer2.default)(_setProps, props);
    }
  }
};
exports.default = seo;